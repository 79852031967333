import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
    addCompleteAttachment,
    completeProAPi,
    updateCompleteProObject,
    toggleSuccessModal,
} from "../../../store/actions/pro";
import ProBrowseModal from "../../Modals/ProBrowseModal";
import SaveModal from "../../Modals/SaveModal";
import { APP_CONSTANTS } from "../../../constants";
import utils from "../../../utils";
import { getExtension } from "../../../utils/icons";
import { deleteMediaFromS3Bucket } from "../../../utils/s3";
import videoIcon from "../../../Assets/images/file-video-regular.svg";

const ProComplete = () => {
    const dispatch = useDispatch();

    const { comp_pro_obj, success } = useSelector((state) => state?.pro);
    const user = JSON.parse(localStorage.getItem("user"));

    const handledFieldChanges = (field, value, custom, index) => {
        let proObjCopy = { ...comp_pro_obj };
        if (!custom) {
            proObjCopy[field] = value;
            dispatch(updateCompleteProObject(proObjCopy));
            return;
        }

        switch (field) {
            case "custom_title":
                if (proObjCopy.complete_attachments)
                    proObjCopy.complete_attachments[index]["custom_name"] = value;
                break;
            default:
        }
        dispatch(updateCompleteProObject(proObjCopy));
    };

    const handleClosePro = (event) => {
        let proObjCopy = { ...comp_pro_obj };
        proObjCopy["isClose"] = event.target.checked;
        dispatch(updateCompleteProObject(proObjCopy));
    };

    const handleSave = () => {
        if (
            comp_pro_obj?.status === "Completed" ||
            comp_pro_obj?.status === "Completed late"
        )
            return;
        let formData = setFormData({ ...comp_pro_obj });
        if (comp_pro_obj?._id) dispatch(completeProAPi(formData));
    };

    // const extractCustomTitles = (data) => {
    //     data.custom_names = [];
    //     data?.complete_attachments.filter((obj, index) => {
    //         if (Boolean(obj.custom_name) && !obj.destination)
    //             data.custom_names.push({ custom_name: obj.custom_name, index });
    //     });
    // };

    const setFormData = (data) => {
        let formData = new FormData();
        /*if (data?.complete_attachments?.length) {
                                                                                extractCustomTitles(data)
                                                                                data?.complete_attachments?.map((file) => {
                                                                                    formData.append("media", file)
                                                                                })
                                                                            }*/
        data.complete = true;
        // data.status = "Complete";
        formData.append("data", JSON.stringify(data));
        return formData;
    };

    const removeEvidence = async (obj, index) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            let proCopy = { ...comp_pro_obj };
            if (obj.key) await deleteMediaFromS3Bucket(obj.key);
            proCopy?.removeFiles
                ? proCopy.removeFiles.push(proCopy?.complete_attachments[index])
                : (proCopy.removeFiles = [proCopy?.complete_attachments[index]]);
            proCopy?.complete_attachments?.splice(index, 1);
            dispatch(updateCompleteProObject(proCopy));
        }
    };

    const openLinkInNewTab = (link) => {
        window.open(link);
    };

    const isAssignedUser = () => {
        let tempBool = false;
        if (user?._id === comp_pro_obj?.assigned_to?.value) {
            tempBool = true;
        }
        return tempBool;
    };

    return (
        <section className="reference pro-ref">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 mt-3">
                        <div className="px-2">
                            <dl className="row">
                                <dt className="col-sm-4">Source</dt>
                                <dd className="col-sm-8">{comp_pro_obj?.source?.label}</dd>
                            </dl>
                        </div>
                        {comp_pro_obj?.custom_source && (
                            <div className="px-2">
                                <dl className="row">
                                    <dt className="col-sm-4">Custom source</dt>
                                    <dd className="col-sm-8">{comp_pro_obj?.custom_source}</dd>
                                </dl>
                            </div>
                        )}
                        <div className="light--bg px-2">
                            <dl className="row">
                                <dt className="col-sm-4">Raised by</dt>
                                <dd className="col-sm-8">{comp_pro_obj?.raised_by?.label}</dd>
                            </dl>
                        </div>
                        <div className="px-2">
                            <dl className="row">
                                <dt className="col-sm-4">Assigned to</dt>
                                <dd className="col-sm-8">{comp_pro_obj?.assigned_to?.label}</dd>
                            </dl>
                        </div>
                        <div className="light--bg px-2">
                            <dl className="row align-items-center py-1">
                                <dt className="col-sm-4 text-truncate">Date raised</dt>
                                <dd className="col-sm-8">
                                    {comp_pro_obj?.date_raised
                                        ? moment(comp_pro_obj?.date_raised).format(
                                            APP_CONSTANTS.DATE_FORMAT
                                        )
                                        : ""}
                                </dd>
                            </dl>
                        </div>
                        <div className="px-2">
                            <dl className="row">
                                <dt className="col-sm-4">Status</dt>
                                <dd className="col-sm-8">{comp_pro_obj?.status}</dd>
                            </dl>
                        </div>
                        <div className="light--bg px-2">
                            <h2 className="fs-14 fw-600 color-04 my-3">Evidence</h2>
                            <div className="row evidence-scroll">
                                {comp_pro_obj?.attachments?.map((obj, index) => (
                                    <div className="col-sm-6 pl-0" key={index}>
                                        <i
                                            className="far fa-minus-circle"
                                            onClick={() => removeEvidence(obj, index)}
                                        />
                                        {obj.extension === "images" && obj?.path && (
                                            <img loading="lazy" src={`${obj?.path}`} alt="Evidence" />
                                        )}
                                        {obj.extension === "videos" && obj?.path && (
                                            // <video
                                            //     className="cursor"
                                            //     onClick={() => {
                                            //         openLinkInNewTab(obj?.path);
                                            //     }}
                                            //     width="49"
                                            //     src={`${obj?.path}#t=0.1`}
                                            //     alt="Evidence"
                                            // />
                                            <img
                                                className="cursor"
                                                loading="lazy"
                                                onClick={() => {
                                                    openLinkInNewTab(obj?.path);
                                                }}
                                                src={videoIcon}
                                                alt="Evidence"
                                            />
                                        )}
                                        {obj.extension === "files" && obj?.path && (
                                            <img
                                                loading="lazy"
                                                className="cursor"
                                                onClick={() => {
                                                    openLinkInNewTab(obj?.path);
                                                }}
                                                src={getExtension(obj?.path)}
                                                alt="Evidence"
                                            />
                                        )}
                                        <input
                                            className="thumb-text fs-12 fw-300 color-04 ml-3"
                                            value={obj?.custom_name}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="note px-2">
                            <dl className="row">
                                <dt className="col-sm-4">Action notes</dt>
                                <dd className="col-sm-8">{comp_pro_obj?.action_notes}</dd>
                            </dl>
                        </div>
                        <div className="light--bg px-2">
                            <dl className="row mb-0">
                                <dt className="col-sm-4">Due date</dt>
                                <dd className="col-sm-8">
                                    {comp_pro_obj?.due_date
                                        ? moment(comp_pro_obj?.due_date).format(
                                            APP_CONSTANTS.DATE_FORMAT
                                        )
                                        : ""}
                                </dd>
                            </dl>
                        </div>
                    </div>
                    {/* Column End */}
                    {(user?.permissions?.general?.system_manager || isAssignedUser()) && (
                        <div className="col-12 col-lg-6 mt-3">
                            <h2 className="fs-14 fw-600 color-04">Completion notes</h2>
                            <textarea
                                className="form-control"
                                id=""
                                rows="5"
                                value={comp_pro_obj?.completion_notes}
                                disabled={
                                    !utils.checkSubStatus()
                                        ? !utils.checkSubStatus()
                                        : comp_pro_obj?.status === "Completed" ||
                                        comp_pro_obj?.status === "Completed late"
                                }
                                onChange={(e) =>
                                    handledFieldChanges("completion_notes", e.target.value)
                                }
                            />
                            <div className="form-group row align-items-center light--bg add-evidence mb-0 mt-3">
                                <label className="col-sm-9 mb-0">Add attachment</label>
                                <div className="col-sm-3">
                                    <button
                                        type="button"
                                        className="btn-browse color-02 fs-12 fw-400 float-lg-right"
                                        data-toggle="modal"
                                        data-target="#proBrowseModal"
                                        disabled={
                                            !utils.checkSubStatus()
                                                ? !utils.checkSubStatus()
                                                : comp_pro_obj?.status === "Completed" ||
                                                comp_pro_obj?.status === "Completed late"
                                        }
                                    >
                                        <i className="far fa-folder-open" /> Browse
                                    </button>
                                </div>
                            </div>
                            <div className="row my-4 evidence-scroll">
                                {comp_pro_obj?.complete_attachments?.map((obj, index) => (
                                    <div className="col-sm-6 pl-0 mt-4" key={index}>
                                        <i
                                            className="far fa-minus-circle"
                                            onClick={() => removeEvidence(obj, index)}
                                        />
                                        {obj.extension === "images" && obj?.path && (
                                            <img loading="lazy" src={`${obj?.path}`} alt="Evidence" />
                                        )}
                                        {obj.extension === "videos" && obj?.path && (
                                            // <video
                                            //     className="cursor"
                                            //     onClick={() => {
                                            //         openLinkInNewTab(obj?.path);
                                            //     }}
                                            //     width="49"
                                            //     src={`${obj?.path}#t=0.1`}
                                            //     alt="Evidence"
                                            // />
                                            <img
                                                className="cursor"
                                                loading="lazy"
                                                onClick={() => {
                                                    openLinkInNewTab(obj?.path);
                                                }}
                                                src={videoIcon}
                                                alt="Evidence"
                                            />
                                        )}
                                        {obj.extension === "files" && obj?.path && (
                                            <img
                                                loading="lazy"
                                                className="cursor"
                                                onClick={() => {
                                                    openLinkInNewTab(obj?.path);
                                                }}
                                                src={getExtension(obj?.path)}
                                                alt="Evidence"
                                            />
                                        )}
                                        {
                                            <input
                                                className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                placeholder={"optional title"}
                                                value={obj?.custom_name}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "custom_title",
                                                        e.target.value,
                                                        true,
                                                        index
                                                    )
                                                }
                                                disabled={
                                                    !utils.checkSubStatus()
                                                        ? !utils.checkSubStatus()
                                                        : comp_pro_obj?.status === "Completed" ||
                                                        comp_pro_obj?.status === "Completed late"
                                                    // false
                                                }
                                            />
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className="mt-4">
                                <div className="my-5 d-flex">
                                    <label
                                        htmlFor="closeOutNotes"
                                        className="ml-5 order-2 color-03 fs-18 fw-500"
                                    >
                                        I want to close this out
                                    </label>
                                    <input
                                        disabled={
                                            !utils.checkSubStatus()
                                                ? !utils.checkSubStatus()
                                                : comp_pro_obj?.status === "Completed" ||
                                                comp_pro_obj?.status === "Completed late"
                                        }
                                        type="checkbox"
                                        id="closePro"
                                        checked={comp_pro_obj?.isClose}
                                        onChange={(e) => handleClosePro(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Column End */}
                </div>
            </div>
            {utils.checkSubscription() &&
                comp_pro_obj?.status !== "Completed" &&
                comp_pro_obj?.status !== "Completed late" && (
                    <div className="float-right mt-4">
                        <button className="btn-save mr-2" onClick={handleSave}>
                            <i className="far fa-save" /> Save
                        </button>
                    </div>
                )}
            <ProBrowseModal dispatch={dispatch} action={addCompleteAttachment} />
            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                            window.location.href = "/";
                        }}
                    ></div>
                    <SaveModal
                        id={"completePro"}
                        h2={APP_CONSTANTS.PRO_COMPLETED.h2}
                        h3={APP_CONSTANTS.PRO_COMPLETED.h3}
                        p={APP_CONSTANTS.PRO_COMPLETED.p}
                    />
                </>
            )}
        </section>
    );
};

export default ProComplete;
