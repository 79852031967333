import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import BackIcon from "../../Assets/images/back-arrow.png";
import moneyImg from "../../Assets/images/Money.png";
import request from "../../services/request";
import { STRIPE } from "../../Routes/Api";
import utils from "../../utils";
const CancelPlan = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const cancelSubsPlan = async () => {
        try {
            let result = await request.post(
                STRIPE.CANCEL_SUBS_PLAN,
                { subscription: state?.subscription, org: state?.org },
                true
            );
            if (result.status) {
                utils.resetOrgDataInSession(result.data);
                navigate("/private/accounts-summary");
            }
        } catch (e) { }
    };
    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-credit-card" /> Accounts summary
                        </h2>
                    </div>
                    <div className="col-12 col-lg-2">
                        <NavLink
                            to={"#"}
                            className="back-to-pre fs-14 fw-300 py-4 color-04 float-right"
                        >
                            <u>Back to previous page</u>
                            <img src={BackIcon} width="14px" alt="Back to Pre" />
                        </NavLink>
                    </div>
                </div>
                <div className="current-plan">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="cplan">
                                <h2 className="fs-18 fw-600 color-06">Cancel your plan</h2>
                                <h3>{state?.product?.name}</h3>
                                <p>
                                    £{utils.getFormattedValue(state?.subs?.plan?.amount)} per
                                    month
                                </p>
                            </div>
                            <div className="plans">
                                <p>We're sorry to see you go.</p>
                                <p>Your plan will be cancelled.</p>
                                <p className="mt-3">
                                    If you change your mind, you can renew your subscription.
                                </p>
                                <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mt-4">
                                    <button
                                        className="btn-update-plan d-lg-block mb-2 mr-2"
                                        onClick={cancelSubsPlan}
                                    >
                                        Cancel plan
                                    </button>
                                    <button
                                        className="btn-cancel-plan"
                                        onClick={() => navigate(-1)}
                                    >
                                        Go back
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-8">
                            <img
                                src={moneyImg}
                                className="img-fluid"
                                style={{ marginTop: "-100px" }}
                                alt="Money Icon"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CancelPlan;
