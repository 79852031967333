import React from "react";
import styled from "styled-components";
// import moment from "moment";
// import { APP_CONSTANTS } from "../../../constants";

const Datefield = styled.input`
  height: 32px;
  border: 1px solid #000000;
  padding: 0 0px 0 10px;
  margin-left: 0px;
  border-radius: 5px;
  width: 170px;

  &:hover {
    cursor: pointer;
  }
`;

const MonthField = (props) => {
    const { dateValue, label, onSet, filterData, raised, type } = props;
    return (
        <div className={type === "KPI" || type === "KPI_TO" ? "d-flex" : ""}>
            <div>
                <p
                    style={{ marginTop: 2, marginBottom: 0, marginRight: 5 }}
                    className={
                        type === "KPI" || type === "KPI_TO"
                            ? "float-left mt-1"
                            : "float-left"
                    }
                >
                    &nbsp;{label} &nbsp;{""}
                </p>
            </div>
            <Datefield
                className="float-left"
                id="month"
                type="month"
                // max={raised ? moment().format("MMM YYYY") : ""}
                value={dateValue}
                onChange={filterData}
            />
        </div>
    );
};

export default MonthField;
