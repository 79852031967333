import { STRIPE_CONST } from "../constants";
import request from "../../services/request";
import { STRIPE } from "../../Routes/Api";
export const setPlanObject = (payload) => {
    return {
        type: STRIPE_CONST.SET_SUBS_PAN_OBJECT,
        payload,
    };
};

export const sestDiscountObject = (payload) => {
    return {
        type: "discount",
        payload,
    };
};

export const getSubsPlanByIdApi = (id) => {
    return (dispatch) => {
        request.get(`${STRIPE.GET_SUB_PLAN_BY_ID}${id}`, false).then(
            (res) => {
                dispatch(setPlanObject(res?.data));
            },
            (error) => { }
        );
    };
};
