import React from 'react';

const CompV = () => {
    return (
        <>
            <h2 id="v">V</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading348">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse348" aria-expanded="true"
                                    aria-controls="collapse348">
                                Validation
                            </button>
                        </h2>
                    </div>
                    <div id="collapse348" className="collapse show" aria-labelledby="heading348"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To validate something, is to prove that it will work. Validation is providing evidence to prove a theory is valid.  Validation is written into a report and includes facts, data and trial results as evidence to prove the theory.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading349">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse349" aria-expanded="false"
                                    aria-controls="collapse349">
                                Values
                            </button>
                        </h2>
                    </div>
                    <div id="collapse349" className="collapse" aria-labelledby="heading349"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A moral or ethical principle, that a person, group of people, or company believes to be right. Companies define the values that they want to work to and normally translate these into vision statements.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading350">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse350" aria-expanded="false"
                                    aria-controls="collapse350">
                                Verification
                            </button>
                        </h2>
                    </div>
                    <div id="collapse350" className="collapse" aria-labelledby="heading350"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To verify something, is to check that it's working or that it has worked. Types of verification activities are; checks, monitoring, audits, assessments, tests.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading351">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse351" aria-expanded="false"
                                    aria-controls="collapse351">
                                Verified
                            </button>
                        </h2>
                    </div>
                    <div id="collapse351" className="collapse" aria-labelledby="heading351"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To verify something, is to check that it is working or that it has worked. Types of verifications are; checks, monitoring, audits, assessments, tests. Note, verification and validation is different. Examples of verifications are: action verification
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading352">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse352" aria-expanded="false"
                                    aria-controls="collapse352">
                                Version
                            </button>
                        </h2>
                    </div>
                    <div id="collapse352" className="collapse" aria-labelledby="heading352"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A version is usually a sequential number and each time a document is updated, the version number increases by one. That way we know which is the most up-to-date version of the document. When a document is updated, information may be added or taken away which changes the way in which the document is used, if the wrong version is used, then the procedure or record will be wrong. That's why it's important that we know that we're using the most up-to-date version.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading353">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse353" aria-expanded="false"
                                    aria-controls="collapse353">
                                Vision statements
                            </button>
                        </h2>
                    </div>
                    <div id="collapse353" className="collapse" aria-labelledby="heading353"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A descriptive statement that helps to explain what a company wants to achieve based on their values.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading354">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse354" aria-expanded="false"
                                    aria-controls="collapse354">
                                Visitor
                            </button>
                        </h2>
                    </div>
                    <div id="collapse354" className="collapse" aria-labelledby="heading354"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A person who doesn't work directly for the company, but attends site for a short period of time on a temporary basis.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompV;