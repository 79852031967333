import FeedBackModal from "../Modals/FeedBackModal";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";

const Footer = (props) => {
    const [show, setShow] = useState(false);

    function toggle() {
        setShow(!show);
    }

    const closefeedModal = () => {
        setShow(false);
    };

    return (
        <>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6 d-flex align-items-center">
                            <p className="mb-0 py-2">
                                ©2021 Techni-K |{" "}
                                <a
                                    href={"https://techni-k.co.uk/product-terms-and-conditions/"}
                                    target="_blank"
                                >
                                    Terms of service
                                </a>{" "}
                                |{" "}
                                <a
                                    href={"https://techni-k.co.uk/privacy-policy/"}
                                    target="_blank"
                                >
                                    Privacy policy
                                </a>{" "}
                                | <i className="fal fa-comment-alt-exclamation" />{" "}
                                <NavLink to={"#"} onClick={() => setShow(true)}>
                                    Send feedback
                                </NavLink>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {show && <FeedBackModal closeFeedModal={closefeedModal} />}
            {show && <div className="modal-backdrop fade show" />}
        </>
    );
};

export default Footer;
