import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BackIcon from "../../Assets/images/back-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { settingsChangeTabState } from "../../store/actions/tabState";
import ExportModal from "../Home/TodoLIst/ExportModal";
import AuditRoles from "./AuditRoles";
import GmpInspections from "./GmpInspections";
import GmpRoles from "./GmpRoles/GmpRoles";
import Reminder from "./Reminder/Reminder";
import AdditionalStandards from "./AdditionalStandards/AdditionalStandards";
import AzureSettings from "./AzureSettings/AzureSettings";

const Settings = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [planTwo, setPlanTwo] = useState(false);
    const [planThree, setPlanThree] = useState(false);
    const [planFour, setPlanFour] = useState(false);

    const tabState = useSelector((state) => state?.tabState);
    const handleChangeTab = (tabId) => {
        dispatch(settingsChangeTabState(tabId));
    };

    const org = JSON.parse(localStorage.getItem("org"));
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_2) {
            setPlanTwo(true);
        } else if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_3) {
            setPlanTwo(true);
            setPlanThree(true);
        } else if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_4) {
            setPlanTwo(true);
            setPlanThree(true);
            setPlanFour(true);
        }
    }, []);

    return (
        <div className="content-box">
            <section className="todo-list">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-10">
                            <h2 className="fs-18 fw-600 py-4">
                                <i className="far fa-cog" /> System settings
                            </h2>
                        </div>
                        <div className="col-12 col-lg-2" onClick={() => navigate(-1)}>
                            <NavLink
                                to={"#"}
                                className="back-to-pre fs-14 fw-300 py-4 color-04 float-right"
                            >
                                <u>Back to previous page</u>{" "}
                                <img src={BackIcon} width="14px" alt="Back to Pre" />
                            </NavLink>
                        </div>
                    </div>
                    <div className="tab-content">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item" onClick={() => handleChangeTab(1)}>
                                <a
                                    className={`nav-link ${tabState?.settingTab === 1 ? "active" : ""
                                        }`}
                                    role="tab"
                                >
                                    {org?.subscription?.price_id === process.env.REACT_APP_PLAN_4
                                        ? "System Audits"
                                        : "Topic Roles"}
                                </a>
                            </li>
                            {planTwo && (
                                <li className="nav-item" onClick={() => handleChangeTab(3)}>
                                    <a
                                        className={`nav-link ${tabState?.settingTab === 3 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        GMP Inspections
                                    </a>
                                </li>
                            )}
                            {planTwo && (
                                <li className="nav-item" onClick={() => handleChangeTab(2)}>
                                    <a
                                        className={`nav-link ${tabState?.settingTab === 2 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        GMP Roles
                                    </a>
                                </li>
                            )}
                            <li className="nav-item" onClick={() => handleChangeTab(4)}>
                                <a
                                    className={`nav-link ${tabState?.settingTab === 4 ? "active" : ""
                                        }`}
                                    role="tab"
                                >
                                    Reminders
                                </a>
                            </li>
                            {planFour && (
                                <li className="nav-item" onClick={() => handleChangeTab(5)}>
                                    <a
                                        className={`nav-link ${tabState?.settingTab === 5 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Additional Standards
                                    </a>
                                </li>
                            )}
                            {(user?.permissions?.general?.system_manager ||
                                user?.userType === "superAdmin") && (
                                    <li className="nav-item" onClick={() => handleChangeTab(6)}>
                                        <a
                                            className={`nav-link ${tabState?.settingTab === 6 ? "active" : ""
                                                }`}
                                            role="tab"
                                        >
                                            Azure Settings
                                        </a>
                                    </li>
                                )}
                        </ul>

                        <div className="tab-content tab-box">
                            {tabState?.settingTab === 1 && (
                                <div
                                    className={`tab-pane todo-section ${tabState?.settingTab === 1 ? "active" : ""
                                        }`}
                                    id="tabs-1"
                                    role="tabpanel"
                                >
                                    <AuditRoles />
                                </div>
                            )}
                            {tabState?.settingTab === 3 && (
                                <div
                                    className={`tab-pane todo-section ${tabState?.settingTab === 3 ? "active" : ""
                                        }`}
                                    id="tabs-3"
                                    role="tabpanel"
                                >
                                    <GmpInspections />
                                </div>
                            )}
                            {tabState?.settingTab === 2 && (
                                <div
                                    className={`tab-pane todo-section ${tabState?.settingTab === 2 ? "active" : ""
                                        }`}
                                    id="tabs-2"
                                    role="tabpanel"
                                >
                                    <GmpRoles />
                                </div>
                            )}
                            {tabState?.settingTab === 4 && (
                                <div
                                    className={`tab-pane todo-section ${tabState?.settingTab === 4 ? "active" : ""
                                        }`}
                                    id="tabs-4"
                                    role="tabpanel"
                                >
                                    <Reminder />
                                </div>
                            )}
                            {tabState?.settingTab === 5 && (
                                <div
                                    className={`tab-pane todo-section ${tabState?.settingTab === 5 ? "active" : ""
                                        }`}
                                    id="tabs-5"
                                    role="tabpanel"
                                >
                                    <AdditionalStandards />
                                </div>
                            )}
                            {tabState?.settingTab === 6 && (
                                <div
                                    className={`tab-pane todo-section ${tabState?.settingTab === 6 ? "active" : ""
                                        }`}
                                    id="tabs-6"
                                    role="tabpanel"
                                >
                                    <AzureSettings />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <ExportModal />
            </section>
        </div>
    );
};

export default Settings;
