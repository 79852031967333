import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PinImg from "../../Assets/images/Larry.png";
import { useSelector, useDispatch } from "react-redux";
import ErrorModal from "../Modals/ErrorModal";
import SaveModal from "../Modals/SaveModal";
import {
    editProfileApi,
    toggleErrorModal,
    toggleSuccessModal,
} from "../../store/actions/user";
import utils from "../../utils";

const Billing = () => {
    const dispatch = useDispatch();

    const { object, error } = useSelector((state) => state.user);
    const showSuccessModal = useSelector((state) => state?.user?.success);

    let navigate = useNavigate();
    const [objects, setObject] = useState({
        user: { user_name: object.user_name },
        error: { current: false, new: false, user_name: false },
    });
    const [isValid, setIsValid] = useState(true);

    const strongPasswordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()\-_+={}[\]|\\;:'"<>,./?]).{8,}$/;

    const isPasswordStrong = (password) => {
        return strongPasswordRegex.test(password);
    };

    const handleFieldChange = (field, value, custom) => {
        switch (field) {
            case "user_name":
                setObject((old) => {
                    return {
                        ...old,
                        user: {
                            ...old.user,
                            [field]: value,
                        },
                    };
                });
                break;
            case "currentPassword":
                setObject((old) => {
                    return {
                        ...old,
                        user: {
                            ...old.user,
                            [field]: value,
                        },
                    };
                });
                break;
            case "newPassword":
                setIsValid(isPasswordStrong(value));
                setObject((old) => {
                    return {
                        ...old,
                        user: {
                            ...old.user,
                            [field]: value,
                        },
                    };
                });
                break;
            default:
        }
    };

    const validateData = (data) => {
        let errorFlag = false;
        if (data?.currentPassword && !data?.newPassword) {
            errorFlag = true;
            setObject((old) => {
                return {
                    ...old,
                    error: {
                        ...old.error,
                        new: true,
                    },
                };
            });
        }
        if (!data?.currentPassword && data?.newPassword) {
            errorFlag = true;
            setObject((old) => {
                return {
                    ...old,
                    error: {
                        ...old.error,
                        current: true,
                    },
                };
            });
        }
        if (data?.user_name === "") {
            errorFlag = true;
            setObject((old) => {
                return {
                    ...old,
                    error: {
                        ...old.error,
                        user_name: true,
                    },
                };
            });
        }
        if (!isValid) {
            errorFlag = true;
        }
        return errorFlag;
    };

    const updateInformation = () => {
        setObject((old) => {
            return {
                ...old,
                error: {
                    ...old.error,
                    user_name: false,
                    new: false,
                    current: false,
                },
            };
        });
        if (validateData(objects?.user)) return;
        dispatch(editProfileApi(objects?.user));
    };

    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="accounts-billing profile_summary">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={objects?.user?.user_name}
                                    onChange={(e) =>
                                        handleFieldChange("user_name", e.target.value)
                                    }
                                    disabled={!utils.checkSubStatus()}
                                />
                                {objects.error.user_name && (
                                    <small className="text-danger">Username is required</small>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Current Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    value={objects?.user?.currentPassword}
                                    onChange={(e) =>
                                        handleFieldChange("currentPassword", e.target.value)
                                    }
                                    disabled={!utils.checkSubStatus()}
                                />
                                {objects.error.current && (
                                    <small className="text-danger">
                                        Current password is required
                                    </small>
                                )}
                            </div>
                            <div className="form-group">
                                <label>New Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    value={objects?.user?.newPassword}
                                    onChange={(e) =>
                                        handleFieldChange("newPassword", e.target.value)
                                    }
                                    disabled={!utils.checkSubStatus()}
                                />
                                {objects.error.new && (
                                    <p className="text-danger mb-2 fs-12">
                                        New Password is required
                                    </p>
                                )}
                                {!isValid && (
                                    <p className="text-danger mb-0 fs-12">
                                        Password should be strong. A strong password should consist
                                        of at least 8 characters, including a combination of
                                        uppercase and lowercase letters, at least one digit (0-9),
                                        and at least one special character.
                                    </p>
                                )}
                            </div>

                            <div className="justify-content-center d-flex">
                                <button
                                    className="btn-update-plan d-lg-block mb-2 mr-2"
                                    onClick={updateInformation}
                                    disabled={!utils.checkSubStatus()}
                                >
                                    Update
                                </button>
                                <button
                                    className="btn-cancel-plan"
                                    onClick={() => navigate(-1)}
                                    disabled={!utils.checkSubStatus()}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <img src={PinImg} className="img-fluid" alt="Billing" />
                        </div>
                    </div>
                </div>
            </div>
            {showSuccessModal &&
                objects.user.currentPassword &&
                objects.user.newPassword && (
                    <>
                        <div className="modal_backdrop"></div>
                        <SaveModal
                            h2={"Your Profile has been updated!"}
                            h3={
                                "In order for the changes to take place we’ll automatically log you out."
                            }
                        ></SaveModal>
                    </>
                )}
            {showSuccessModal &&
                !objects.user.currentPassword &&
                !objects.user.newPassword && (
                    <>
                        <div
                            className="modal_backdrop"
                            onClick={() => {
                                dispatch(toggleSuccessModal(false));
                            }}
                        ></div>
                        <SaveModal
                            h2={"Your Profile has been updated!"}
                            h3={"You have successfully updated your Username"}
                        ></SaveModal>
                    </>
                )}
            {error.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal({ show: false }));
                        }}
                    ></div>
                    <ErrorModal error={error}></ErrorModal>
                </>
            )}
        </section>
    );
};

export default Billing;
