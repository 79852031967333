import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";
import { APP_CONSTANTS } from "../../../constants";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const ArchiveReports = (props) => {
    const { data } = props;

    const downloadReport = (data) => {
        const link = document.createElement("a");
        link.href = data?.link;
        link.addEventListener("click", () => {
            // Detect download completion
            console.log("File download initiated.");
        });
        link.click();
    };

    const columns = [
        {
            name: "Report dates",
            selector: (row) =>
                `${moment(row?.fromDate).format(APP_CONSTANTS.DATE_FORMAT)} - ${moment(
                    row?.toDate
                ).format(APP_CONSTANTS.DATE_FORMAT)}`,
            cell: (row) =>
                `${moment(row?.fromDate).format(APP_CONSTANTS.DATE_FORMAT)} - ${moment(
                    row?.toDate
                ).format(APP_CONSTANTS.DATE_FORMAT)}`,
            sortable: true,
            width: "250px",
        },
        {
            name: "Sent",
            selector: (row) =>
                moment(row?.scheduleDate).format(APP_CONSTANTS.DATE_FORMAT),
            cell: (row) =>
                moment(row?.scheduleDate).format(APP_CONSTANTS.DATE_FORMAT),
            sortable: true,
        },
        {
            cell: (row) => (
                <a
                    className="archive-link user-name-link cursor"
                    onClick={() => downloadReport(row)}
                >
                    Download
                </a>
            ),
            width: "100px",
        },
    ];

    const customUniqueID = (row) => {
        return row?._id;
    };

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    return (
        <div className="mt-3">
            <DataTable
                columns={columns}
                data={data ? data : []}
                customStyles={customStyles}
                striped
                persistTableHead
                keyField={customUniqueID}
                pagination
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );
};

export default ArchiveReports;
