import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
// import { APP_CONSTANTS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
// import DateField from "../../Date/DateField";
import FilteredInputField from "./FilteredInputField";
import {
    filterRiskList,
    getAssesmentByUser,
} from "../../../store/actions/riskAssesment";
import { generateSequence } from "../../../utils/referenceId";
import MonthField from "./MonthField";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const RiskAssesment = () => {
    const dispatch = useDispatch();

    const { userRiskAssesment } = useSelector((state) => state.riskAssesment);

    const [filterDate, setFilterDate] = useState({ from: "" });
    const [filterStatus, setFilterStatus] = useState("none");
    const [filteredList, setFilteredList] = useState(userRiskAssesment);

    const handleRowSelection = ({ selectedRows }) => {
        dispatch(filterRiskList(selectedRows));
    };

    const statuses = [
        {
            label: "Choose a Topic",
            value: "none",
        },
        {
            label: "All",
            value: "all",
        },
        {
            label: "01 Senior management commitment",
            value: "01 Senior management commitment",
        },
        { label: "02 Document management", value: "02 Document management" },
        { label: "03 Continuous improvement", value: "03 Continuous improvement" },
        { label: "04 Contingency planning", value: "04 Contingency planning" },
        { label: "05 Product safety plan", value: "05 Product safety plan" },
        { label: "06 Contamination control", value: "06 Contamination control" },
        { label: "07 Product development", value: "07 Product development" },
        { label: "08 Supplier management", value: "08 Supplier management" },
        { label: "09 Testing and inspection", value: "09 Testing and inspection" },
        { label: "10 Maintenance", value: "10 Maintenance" },
        { label: "11 Product defence", value: "11 Product defence" },
        {
            label: "12 Training and competency",
            value: "12 Training and competency",
        },
        { label: "13 Personnel", value: "13 Personnel" },
        { label: "14 Site standards", value: "14 Site standards" },
        { label: "15 Risk-based facilities", value: "15 Risk-based facilities" },
        { label: "16 Hygiene", value: "16 Hygiene" },
        { label: "17 Process control", value: "17 Process control" },
        {
            label: "18 Intake, storage and distribution",
            value: "18 Intake, storage and distribution",
        },
    ];

    const dateFilter = (field, value) => {
        // let to = null;
        let from = null;
        if (field === "from") {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        }
        // else {
        //     setFilterDate((old) => {
        //         return { ...old, [field]: value };
        //     });
        // }
    };

    const dateFilteredData = () => {
        let tempArr = userRiskAssesment;

        if (filterDate.from && filterDate.from !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(item?.month).format("MMMM YYYY") ==
                    moment(filterDate.from).format("MMMM YYYY")
                ) {
                    return item;
                }
            });
        }

        // if (filterDate.to && filterDate.to !== "") {
        //     tempArr = tempArr.filter((item) => {
        //         if (
        //             moment(item?.month).format("yyyy-mm") <=
        //             moment(filterDate.to).format("yyyy-mm")
        //         ) {
        //             return item;
        //         }
        //     });
        // }

        if (filterStatus !== "none") {
            if (filterStatus === "all") {
                tempArr = tempArr;
            } else {
                tempArr = tempArr?.filter((item) => {
                    if (item?.topic?.label === filterStatus) {
                        return item;
                    }
                });
            }
        }
        setFilteredList(tempArr);
        dispatch(filterRiskList(tempArr));
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        // const handleClear = () => {
        //     if (filterText) {
        //         setFilterText("");
        //     }
        // };

        return (
            <>
                <div style={{ width: "100%" }}>
                    <MonthField
                        filterData={(e) => dateFilter("from", e.target.value)}
                        // raised={true}
                        dateValue={filterDate?.from}
                        label={"Filter:"}
                    />
                    {/* <MonthField
                        filterData={(e) => dateFilter("to", e.target.value)}
                        dateValue={filterDate?.to}
                        label={"To:"}
                    /> */}
                    <FilteredInputField
                        list={statuses}
                        value={filterStatus}
                        action={setFilterStatus}
                    />
                </div>
            </>
        );
    }, [filteredList, filterDate]);

    useEffect(() => {
        if (!userRiskAssesment?.length) dispatch(getAssesmentByUser());
    }, []);

    useEffect(() => {
        // setFilteredList(userRiskAssesment);
        dateFilteredData();
    }, [filterDate, filterStatus]);

    useEffect(() => {
        setFilteredList(userRiskAssesment);
    }, [userRiskAssesment]);

    // useEffect(() => {
    //     dispatch(getAssesmentByUser());
    // }, []);

    const columns = [
        {
            name: "Reference",
            selector: (row) => `${row?.type}-${generateSequence(row?.id)}`,
            sortable: true,
            cell: (row) => `${row?.type}-${generateSequence(row?.id)}`,
            width: "150px",
        },
        {
            name: "Topic",
            selector: (row) => row?.topic?.label,
            sortable: true,
            cell: (row) => row?.topic?.label,
            width: "250px",
        },
        {
            name: "Month",
            selector: (row) => moment(row?.month).format("MMM YYYY"),
            sortable: true,
            cell: (row) => moment(row?.month).format("MMM YYYY"),
        },
        {
            name: "Risk Rating",
            selector: (row) => row?.riskRating,
            sortable: true,
            cell: (row) => row?.riskRating,
        },
        {
            name: "Critical NCs",
            selector: (row) => row?.noOfCritical,
            sortable: true,
            cell: (row) => row?.noOfCritical,
        },
        {
            name: "Major NCs",
            selector: (row) => row?.noOfMajor,
            sortable: true,
            cell: (row) => row?.noOfMajor,
        },
        {
            name: "Minor NCs",
            selector: (row) => row?.noOfMinor,
            sortable: true,
            cell: (row) => row?.noOfMinor,
        },
        {
            name: "Total NC Points",
            selector: (row) => (row?.ncPoints ? row?.ncPoints : 0),
            sortable: true,
            cell: (row) => (row?.ncPoints ? row?.ncPoints : 0),
        },

        {
            name: "RA Score",
            selector: (row) => row?.assessmentScore,
            sortable: true,
            cell: (row) => row?.assessmentScore,
        },
        {
            name: "Resulting Frequency",
            selector: (row) =>
                parseInt(row?.frequency / 30) === 12
                    ? "Annual"
                    : `${parseInt(row?.frequency / 30)}-monthly`,
            sortable: true,
            cell: (row) =>
                parseInt(row?.frequency / 30) === 12
                    ? "Annual"
                    : `${parseInt(row?.frequency / 30)}-monthly`,
            width: "200px",
        },
    ];

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataRisk = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataRisk);
    };

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="table-responsive prog_info">
            <DataTable
                columns={columns}
                data={filteredList?.length > 0 ? filteredList : []}
                selectableRows
                customStyles={customStyles}
                striped
                onSelectedRowsChange={handleRowSelection}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />
        </div>
    );
};

export default RiskAssesment;
