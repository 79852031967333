import React from 'react';

const CompC = () => {
    return (
        <>
            <h2 id="c">C</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading40">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse40" aria-expanded="true"
                                    aria-controls="collapse40">
                                CapEx
                            </button>
                        </h2>
                    </div>
                    <div id="collapse40" className="collapse show" aria-labelledby="heading40"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Capital expenditure. An accounting term. Typically, CaPex is used to purchase physical assets.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading41">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse41" aria-expanded="false"
                                    aria-controls="collapse41">
                                Carrier
                            </button>
                        </h2>
                    </div>
                    <div id="collapse41" className="collapse" aria-labelledby="heading41"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A company that's used to transport materials, such as couriers or by post.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading42">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse42" aria-expanded="false"
                                    aria-controls="collapse42">
                                CCP
                            </button>
                        </h2>
                    </div>
                    <div id="collapse42" className="collapse" aria-labelledby="heading42"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A critical control point. A control that eradicates contamination or reduces it to an acceptable level.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading43">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse43" aria-expanded="false"
                                    aria-controls="collapse43">
                                Certification
                            </button>
                        </h2>
                    </div>
                    <div id="collapse43" className="collapse" aria-labelledby="heading43"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When the site has been approved by an organisation. This typically involves an audit against a set standard. On successful completion, the site will receive a certificate to prove that they've been approved. Examples; certification audit, certification standard
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading44">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse44" aria-expanded="false"
                                    aria-controls="collapse44">
                                Certification audit
                            </button>
                        </h2>
                    </div>
                    <div id="collapse44" className="collapse" aria-labelledby="heading44"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where an audit is carried out to prove that the site is compliant to the certification standard.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading45">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse45" aria-expanded="false"
                                    aria-controls="collapse45">
                                Certification body
                            </button>
                        </h2>
                    </div>
                    <div id="collapse45" className="collapse" aria-labelledby="heading45"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Audits business to provide certification to the standard. Certification bodies are approved by the scheme owner.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading46">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse46" aria-expanded="false"
                                    aria-controls="collapse46">
                                Certification logo
                            </button>
                        </h2>
                    </div>
                    <div id="collapse46" className="collapse" aria-labelledby="heading46"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When a site has been approved against a particular certification standard, they can use the certification organisations logo. If they do use the logo, they must follow the rules of use provided by the certification organisation.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading47">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse47" aria-expanded="false"
                                    aria-controls="collapse47">
                                Certification standard
                            </button>
                        </h2>
                    </div>
                    <div id="collapse47" className="collapse" aria-labelledby="heading47"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A set of rules that the site must adhere to, in order to gain approval from a particular certification organisation. Typically, this involves passing a certification audit.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading48">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse48" aria-expanded="false"
                                    aria-controls="collapse48">
                                Chain of custody
                            </button>
                        </h2>
                    </div>
                    <div id="collapse48" className="collapse" aria-labelledby="heading48"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A collective term that covers all physical parts of the process, which are required to get a product to the consumer. This includes farm level, processing, storage and logistics, plus businesses who don't take physical possession of the product but are responsible for it, or the sale of it - such as agents and brokers.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading49">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse49" aria-expanded="false"
                                    aria-controls="collapse49">
                                Channels
                            </button>
                        </h2>
                    </div>
                    <div id="collapse49" className="collapse" aria-labelledby="heading49"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When reporting information it's important that it's clear how information is fed up and down the ranks.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading50">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse50" aria-expanded="false"
                                    aria-controls="collapse50">
                                CIC
                            </button>
                        </h2>
                    </div>
                    <div id="collapse50" className="collapse" aria-labelledby="heading50"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Cleaning Instruction Card. A term used to describe a cleaning procedure. A CIC is used to train cleaners to clean a piece of equipment or a surface to a set standard.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading51">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse51" aria-expanded="false"
                                    aria-controls="collapse51">
                                CIP
                            </button>
                        </h2>
                    </div>
                    <div id="collapse51" className="collapse" aria-labelledby="heading51"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Clean In Place. A cleaning system used to clean large pieces of equipment. A CIP can be manual or automated. Typically used to clean pipework and large vessels.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading52">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse52" aria-expanded="false"
                                    aria-controls="collapse52">
                                Closed out
                            </button>
                        </h2>
                    </div>
                    <div id="collapse52" className="collapse" aria-labelledby="heading52"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The term 'closed out' is used when referring to corrective actions or preventive actions. It means that the action has gone through all the steps required to complete it. Further guidance; closed out within the timescales, closed out effectively
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading53">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse53" aria-expanded="false"
                                    aria-controls="collapse53">
                                Closed out effectively
                            </button>
                        </h2>
                    </div>
                    <div id="collapse53" className="collapse" aria-labelledby="heading53"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            For an action to be 'closed out effectively', it must be completed by the agreed deadlineand evidence must be provided to show what has been done. This evidence then needs to be verified by someone who is independent to the action, to make sure it has been completed correctly and also thoroughly.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading54">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse54" aria-expanded="false"
                                    aria-controls="collapse54">
                                Closed-out within the timescales
                            </button>
                        </h2>
                    </div>
                    <div id="collapse54" className="collapse" aria-labelledby="heading54"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When actions are set, a timescale for completion must be agreed with the person that's going to complete it. That person must then complete the action, by the date agreed. Once the action has been completed, it's then 'closed- out'. And, to be compliant it must be 'closed-out' by the agreed date; which is within the timescale agreed.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading55">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse55" aria-expanded="false"
                                    aria-controls="collapse55">
                                Commitment Policy
                            </button>
                        </h2>
                    </div>
                    <div id="collapse55" className="collapse" aria-labelledby="heading55"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A policy which details the business' commitment to producing safe, legal, authentic products to the quality agreed. It's a pledge made by the management that run that particular site or office. This policy is also known as a Quality Policy, Documented Policy, Food Safety and Quality Policy,
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading56">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse56" aria-expanded="false"
                                    aria-controls="collapse56">
                                Communicated
                            </button>
                        </h2>
                    </div>
                    <div id="collapse56" className="collapse" aria-labelledby="heading56"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The word 'communicated' is used to describe how information is passed from one person to another. Information can be 'communicated' through toolbox talks, staff briefings, on noticeboards, in written form like letters or emails, one-to-one meetings or training sessions. There are no right or wrong ways of communicating, as long as the communication meets two important points: 1) The person receiving the information understands it. 2) There's evidence that the information was passed on. When auditing communication, you must assess whether the type of communication chosen is suitable for the information being passed on, so that the person receiving it, can understand it. And, you must be able to assess who it was 'communicated' to and that they've signed to say that they understood it. Information can be provided on noticeboards, but this should only be used as a form of reminder, rather than the only communication provided, because you don't know if anyone has read it and you can't prove (provide evidence) that they've read it.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading57">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse57" aria-expanded="false"
                                    aria-controls="collapse57">
                                Communicating to relevant personnel
                            </button>
                        </h2>
                    </div>
                    <div id="collapse57" className="collapse" aria-labelledby="heading57"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When being asked to communicate to relevant personnel, this means those that need to know about it, need to be told. For example, if you assign an action to someone, the person you have assigned it to, would be a relevant person. It's common sense that you would need to tell them about the action, so that they know they need to do it. When determining who is 'relevant' think about who needs to know, either directly because they need to do something, or indirectly because the action will affect them.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading58">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse58" aria-expanded="false"
                                    aria-controls="collapse58">
                                Communication
                            </button>
                        </h2>
                    </div>
                    <div id="collapse58" className="collapse" aria-labelledby="heading58"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The word 'communication' is used to describe how information is passed from one person to another. Information can be communicated through toolbox talks, staff briefings, on noticeboards, in written form like letters or emails, one-to-one meetings or training sessions. There are no right or wrong ways of communicating, as long as the 'communication' meets two important points: 1) The person receiving the information understands it.  2) There's evidence that the information was passed on. When auditing 'communication,' you must assess whether the type of 'communication' chosen is suitable for the information being passed on, so that the person receiving it, can understand it. And, you must be able to assess who it was communicated to and that they've signed to say that they understood it. Information can be provided on noticeboards, but this should only be used as a form of reminder, rather than the only 'communication' provided, because you don't know if anyone has read it and you can't prove (provide evidence) that they've read it.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading59">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse59" aria-expanded="false"
                                    aria-controls="collapse59">
                                Communication channel
                            </button>
                        </h2>
                    </div>
                    <div id="collapse59" className="collapse" aria-labelledby="heading59"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A set method of communication. The purpose of the communication channel, is for staff to be able to communicate up to their managers and for managers to be able to communicate down to their teams.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading60">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse60" aria-expanded="false"
                                    aria-controls="collapse60">
                                Communication media
                            </button>
                        </h2>
                    </div>
                    <div id="collapse60" className="collapse" aria-labelledby="heading60"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The term media when used in a communication context, are the materials (in physical or digital form) that transfer the communciation, or information. Such as newspapers, social media, radio.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading61">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse61" aria-expanded="false"
                                    aria-controls="collapse61">
                                Communication / Communicating
                            </button>
                        </h2>
                    </div>
                    <div id="collapse61" className="collapse" aria-labelledby="heading61"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Transferring information from one person to another. See also; methods of communication, communicating to relevant personnel
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading62">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse62" aria-expanded="false"
                                    aria-controls="collapse62">
                                Competency
                            </button>
                        </h2>
                    </div>
                    <div id="collapse62" className="collapse" aria-labelledby="heading62"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Competency or to be competent, means that you have the required experience, skills or knowledge to be able to complete a task to the required standard.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading63">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse63" aria-expanded="false"
                                    aria-controls="collapse63">
                                Competency frequency
                            </button>
                        </h2>
                    </div>
                    <div id="collapse63" className="collapse" aria-labelledby="heading63"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The frequency at which competency to complete a specific task, or understand a specific subject - must be checked. Also known as retraining frequency.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading64">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse64" aria-expanded="false"
                                    aria-controls="collapse64">
                                Complaint investigation
                            </button>
                        </h2>
                    </div>
                    <div id="collapse64" className="collapse" aria-labelledby="heading64"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When a complaint is received, an investigation takes place to establish what went wrong. Depending on the results of the investigation, corrective actions and preventive actions may be required.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading65">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse65" aria-expanded="false"
                                    aria-controls="collapse65">
                                Complaints
                            </button>
                        </h2>
                    </div>
                    <div id="collapse65" className="collapse" aria-labelledby="heading65"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When the product or service doesn't meet the expectations of the person or company who receives it, they may make a 'complaint'. Further guidance; customer complaint, consumer complaint, complaint investigation
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading66">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse66" aria-expanded="false"
                                    aria-controls="collapse66">
                                Compliance
                            </button>
                        </h2>
                    </div>
                    <div id="collapse66" className="collapse" aria-labelledby="heading66"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that meets the required standard. Also known as; conformance, conformity
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading67">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse67" aria-expanded="false"
                                    aria-controls="collapse67">
                                Condition
                            </button>
                        </h2>
                    </div>
                    <div id="collapse67" className="collapse" aria-labelledby="heading67"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Documents must be maintained in good condition, which means that they must be stored so that they don't get damaged. Documents which are kept in damp conditions for example may not be legible when they are retrieved.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading68">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse68" aria-expanded="false"
                                    aria-controls="collapse68">
                                Condition based maintenance
                            </button>
                        </h2>
                    </div>
                    <div id="collapse68" className="collapse" aria-labelledby="heading68"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Condition based maintenance is system which highlights when maintenance is needed, by assessing leading indicators - which show that the equipment is about to fail.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading69">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse69" aria-expanded="false"
                                    aria-controls="collapse69">
                                Confidential reporting system
                            </button>
                        </h2>
                    </div>
                    <div id="collapse69" className="collapse" aria-labelledby="heading69"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A 'confidential reporting system' is a system which allows personnel to formally raise their concerns, without anyone knowing that they've done it. This is also known as whistleblowing.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading70">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse70" aria-expanded="false"
                                    aria-controls="collapse70">
                                Conflict of interest
                            </button>
                        </h2>
                    </div>
                    <div id="collapse70" className="collapse" aria-labelledby="heading70"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where a person has an interest in an outcome and therefore is likely to be bias about the outcome.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading71">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse71" aria-expanded="false"
                                    aria-controls="collapse71">
                                Conformance
                            </button>
                        </h2>
                    </div>
                    <div id="collapse71" className="collapse" aria-labelledby="heading71"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that meets the required standard. Also known as; compliance, conformity
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading72">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse72" aria-expanded="false"
                                    aria-controls="collapse72">
                                Conformity
                            </button>
                        </h2>
                    </div>
                    <div id="collapse72" className="collapse" aria-labelledby="heading72"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that meets the required standard. Also known as; compliance, conformance
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading73">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse73" aria-expanded="false"
                                    aria-controls="collapse73">
                                Consumer
                            </button>
                        </h2>
                    </div>
                    <div id="collapse73" className="collapse" aria-labelledby="heading73"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An individual who buys a retail product. The consumer buys the product to consume or use.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading74">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse74" aria-expanded="false"
                                    aria-controls="collapse74">
                                Consumer complaint
                            </button>
                        </h2>
                    </div>
                    <div id="collapse74" className="collapse" aria-labelledby="heading74"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A consumer complaint is one which comes from the person who has purchased the product. Generally, they have purchased the product for personal use, in a retail store and they'll use the product at home. Variations; A consumer complaint is different to a customer compliant.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading75">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse75" aria-expanded="false"
                                    aria-controls="collapse75">
                                Consumer packaging
                            </button>
                        </h2>
                    </div>
                    <div id="collapse75" className="collapse" aria-labelledby="heading75"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Any packaging that is provided to the consumer. This may include single use items such as wooden forks.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading76">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse76" aria-expanded="false"
                                    aria-controls="collapse76">
                                Consumer product
                            </button>
                        </h2>
                    </div>
                    <div id="collapse76" className="collapse" aria-labelledby="heading76"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The finished product with all its packaging and component parts that the consumer receives.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading77">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse77" aria-expanded="false"
                                    aria-controls="collapse77">
                                Contractor
                            </button>
                        </h2>
                    </div>
                    <div id="collapse77" className="collapse" aria-labelledby="heading77"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An external company which provides specialist labour to complete specific tasks.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading78">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse78" aria-expanded="false"
                                    aria-controls="collapse78">
                                Contractor
                            </button>
                        </h2>
                    </div>
                    <div id="collapse78" className="collapse" aria-labelledby="heading78"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A person who works indirectly for the company, but isn't directly employed by them. A contractor typically attends site to complete a specialist service.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading79">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse79" aria-expanded="false"
                                    aria-controls="collapse79">
                                Corrections
                            </button>
                        </h2>
                    </div>
                    <div id="collapse79" className="collapse" aria-labelledby="heading79"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where a document has to be corrected, it's important that the the original information is still visible. To correct hand written entries - one line should be put through it, the correct entry written and then the correction initialed to show who did it. Tippex or correction fluid must not be used, as this makes it difficult to read the original entry. The original entry must be legible to prevent it looking like it's been fraudulently adapted.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading80">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse80" aria-expanded="false"
                                    aria-controls="collapse80">
                                Country of sale
                            </button>
                        </h2>
                    </div>
                    <div id="collapse80" className="collapse" aria-labelledby="heading80"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The country where the product is to be sold. Where the product will be sold in multiple countries, the legislation from all countries must be applied. In some cases, the country of sale may not be known. This is only acceptable where the finished product is not sold direct to the consumer. In this situation the specification which accompanies the product, must detail clearly which legislation the finished product does comply with, so that the customer can make an informed decision.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading81">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse81" aria-expanded="false"
                                    aria-controls="collapse81">
                                Critical limits
                            </button>
                        </h2>
                    </div>
                    <div id="collapse81" className="collapse" aria-labelledby="heading81"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Used when assessing monitoring results of Product Safety Control Points, to establish if the product is safe or not. The limits is the range between what the minimum criteria that ensures product safety, and what the maximum criteria that ensures product safety. The monitoring can only be objective.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading82">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse82" aria-expanded="false"
                                    aria-controls="collapse82">
                                Cross-check
                            </button>
                        </h2>
                    </div>
                    <div id="collapse82" className="collapse" aria-labelledby="heading82"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Cross-checking is a key auditor skill.  Where the same information is provided in more than one place, it's important that it is the same. Mistakes can happen when transferring information. Or, when one document with that information on, is updated, the updates to the other documents get forgotten. This means whenever you are auditing, you should be looking for where information is repeated and checking that it matches everywhere it's stated. This is called cross-checking.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading83">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse83" aria-expanded="false"
                                    aria-controls="collapse83">
                                Cross-docking
                            </button>
                        </h2>
                    </div>
                    <div id="collapse83" className="collapse" aria-labelledby="heading83"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Cross-docking is where a material is transported via a location, at which it needs to be processed to make the next part of the journey.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading84">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse84" aria-expanded="false"
                                    aria-controls="collapse84">
                                Culture
                            </button>
                        </h2>
                    </div>
                    <div id="collapse84" className="collapse" aria-labelledby="heading84"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Culture' is a set of beliefs, attitudes and expectations believed by those that work on site. A company's 'culture' will affect how people on site behave, the decisions that they make and the actions that they take. The 'culture' is documented using a set of vision statements and values that the company wants to work to.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading85">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse85" aria-expanded="false"
                                    aria-controls="collapse85">
                                Culture action plan
                            </button>
                        </h2>
                    </div>
                    <div id="collapse85" className="collapse" aria-labelledby="heading85"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An action plan which is created to ensure drive improvement in the sites culture, so that they meet their values and vision statements.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading86">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse86" aria-expanded="false"
                                    aria-controls="collapse86">
                                Current
                            </button>
                        </h2>
                    </div>
                    <div id="collapse86" className="collapse" aria-labelledby="heading86"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To establish what actions need to be taken to improve culture, you need to know what the culture is like at the moment. This is typically done using a culture survey.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading87">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse87" aria-expanded="false"
                                    aria-controls="collapse87">
                                Current version
                            </button>
                        </h2>
                    </div>
                    <div id="collapse87" className="collapse" aria-labelledby="heading87"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A version is usually a sequential number and each time a document is updated, the version number increases by one. That way you know which is the most up-to-date version of the document. When a document is updated, information may be added or taken away which changes the way in which the document is used, if the wrong version is used. The result of this means the procedure or record will be wrong. That's why it's important that you know that you're using the most up-to-date version - the 'current version'.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading88">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse88" aria-expanded="false"
                                    aria-controls="collapse88">
                                Customer
                            </button>
                        </h2>
                    </div>
                    <div id="collapse88" className="collapse" aria-labelledby="heading88"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A company who buys a wholesale material, who sells it on, or uses it to process another material, which they then sell.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading89">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse89" aria-expanded="false"
                                    aria-controls="collapse89">
                                Customer brand
                            </button>
                        </h2>
                    </div>
                    <div id="collapse89" className="collapse" aria-labelledby="heading89"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Product which is packed under a brand which is owned by the customer.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading90">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse90" aria-expanded="false"
                                    aria-controls="collapse90">
                                Customer complaint
                            </button>
                        </h2>
                    </div>
                    <div id="collapse90" className="collapse" aria-labelledby="heading90"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A customer complaint is a business to business (B2B) complaint. It typically, is raised when the product or service does not meet the agreed specification. Therefore, it is generally objectivein it's view. Variations; A customer complaint is different from a consumer complaint.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading91">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse91" aria-expanded="false"
                                    aria-controls="collapse91">
                                Customer feedback
                            </button>
                        </h2>
                    </div>
                    <div id="collapse91" className="collapse" aria-labelledby="heading91"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Customers may provide feedback about the product or service your company provides. This feedback isn't a complaint, but rather it's designed to be used for improvement purposes.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
              

            </div>
        </>
    );
};

export default CompC;