import React from "react";
import Frequency from "../../Assets/images/frequency.png";

const FrequencyModal = (props) => {
    const { id, h2, h3, calculate, toggleFrequencyModal } = props;

    const onCloseFrequencyModal = () => {
        toggleFrequencyModal(false);
    };

    return (
        <div className="frequency_modal">
            <div
                className="modal fade show"
                id={`${"FrequencyModal"}-${id}`}
                tabIndex="-1"
                aria-labelledby="FrequencyModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body custom_modal_content_padding">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <img src={Frequency} alt="Frequency" width="100%" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h2 className="color-06 fs-24 fw-600 mb-5">{h2}</h2>
                                    <h3 className="color-05 fs-18 fw-600 mb-5">{h3}</h3>
                                    <div className="mb-5">
                                        {calculate?.map((data) => (
                                            <>
                                                <div className="d-flex">
                                                    <h3 className="color-05 fs-18 fw-600 mb-3 mr-3">
                                                        {`${data?.label} - ${data?.value}`}
                                                    </h3>
                                                </div>
                                            </>
                                        ))}
                                    </div>

                                    <div className="text-center">
                                        <button
                                            type="button"
                                            className="cancel mr-2"
                                            onClick={onCloseFrequencyModal}
                                        >
                                            <i className="fal fa-times-circle" /> Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default FrequencyModal;
