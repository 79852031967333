import { STRIPE_CONST } from "../constants";

const reducer = (state = {}, { type, payload }) => {
    switch (type) {
        case STRIPE_CONST.SET_SUBS_PAN_OBJECT:
            return {
                ...state,
                object: payload,
            };
            break;
        case "discount":
            return {
                ...state,
                discountObj: payload,
            };
            break;
        default:
            return state;
    }
};

export default reducer;
