import React from "react";
import DataTable from "react-data-table-component";
import { changeTabState } from "../../../../store/actions/tabState";
import { useDispatch } from "react-redux";
import { editScheduledReportsObject } from "../../../../store/actions/reportScheduler";
import utils from "../../../../utils";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const ScheduledReportsData = (props) => {
    const { data, showModal } = props;
    const dispatch = useDispatch();

    const editReports = (field, data) => {
        dispatch(editScheduledReportsObject(data));
        dispatch(changeTabState(12));
    };

    const columns = [
        {
            name: "Report name",
            selector: (row) => row?.reportName,
            cell: (row) => row?.reportName,
            width: "350px",
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => row?.status,
            cell: (row) => row?.status,
            sortable: true,
        },
        {
            cell: (row) => (
                <a
                    className="archive-link user-name-link cursor"
                    onClick={() => editReports("archive", row)}
                >
                    View archive
                </a>
            ),
            width: "150px",
        },
        {
            cell: (row) => (
                <a
                    className="archive-link user-name-link cursor"
                    onClick={() => editReports("edit", row)}
                >
                    Edit
                </a>
            ),
            width: "100px",
        },
        {
            cell: (row) =>
                row?.status === "Draft" && (
                    <a
                        className="archive-link user-name-link cursor"
                        onClick={() => {
                            if (!utils.checkSubStatus()) {
                                return;
                            } else {
                                showModal(true, row?._id);
                            }
                        }}
                    >
                        Schedule
                    </a>
                ),
            width: "100px",
        },
    ];

    const customUniqueID = (row) => {
        return row?._id;
    };

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    return (
        <div className="mt-3">
            <DataTable
                columns={columns}
                data={data ? data : []}
                customStyles={customStyles}
                striped
                persistTableHead
                keyField={customUniqueID}
                pagination
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );
};

export default ScheduledReportsData;
