import React, { useEffect, useState } from "react";
import TodoListTlb from "../Home/TodoLIst/TodoListTlb";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { changeTabState } from "../../store/actions/tabState";
import { APP_CONSTANTS } from "../../constants";

const MultisiteHome = () => {
    const dispatch = useDispatch();

    const tabState = useSelector((state) => state?.tabState);
    const { object } = useSelector((state) => state.user);
    const { list, filteredList } = useSelector((state) => state.todoList);

    const [csvExportHeader, setCsvExportHeader] = useState(
        APP_CONSTANTS.MULTISITE_TODOLIST_EXPORT_HEADERS
    );
    const [csvReport, setCsvReport] = useState([]);

    const org = JSON.parse(localStorage.getItem("org"));

    const arrangeDataForExport = () => {
        let todoListCsvExportData = [];
        if (filteredList?.length)
            todoListCsvExportData = JSON.parse(JSON.stringify(filteredList));
        else if (list?.length) {
            todoListCsvExportData = JSON.parse(JSON.stringify(list));
        }
        todoListCsvExportData = todoListCsvExportData.map((obj) => {
            return APP_CONSTANTS.MULTISITE_TODOLIST_EXPORT_DATA_SET(obj);
        });
        setCsvReport(todoListCsvExportData);
    };

    const handleChangeTab = (tabId) => {
        dispatch(changeTabState(tabId));
    };

    useEffect(() => {
        dispatch(changeTabState(1));
    }, []);

    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-6 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-home" /> {` Welcome ${object?.user_name}`}
                        </h2>
                    </div>
                </div>
                <div className="tab-content">
                    <CSVLink
                        data={csvReport}
                        headers={csvExportHeader}
                        filename={"multisiteTodoList.csv"}
                        className={"btn btn-export float-right mt-0"}
                        asyncOnClick={true}
                        onClick={(event, done) => {
                            arrangeDataForExport();
                            done();
                        }}
                    >
                        <i className="far fa-file-export" /> Export
                    </CSVLink>
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" onClick={() => handleChangeTab(1)}>
                            <a
                                className={`nav-link ${tabState?.tab === 1 ? "active" : ""}`}
                                role="tab"
                            >
                                My to-do list
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content tab-box">
                        {tabState?.tab === 1 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 1 ? "active" : ""
                                    }`}
                                id="tabs-1"
                                role="tabpanel"
                            >
                                <TodoListTlb type="multisite"  />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MultisiteHome;
