import pdfThumb from "../Assets/images/pdf.png";
import docThumb from "../Assets/images/docs.png";
import xlsxThumb from "../Assets/images/xlsx.png";
import pptThumb from "../Assets/images/ppt.png";
import FilesThumb from "../Assets/images/filesthumb.jpg";

export const getExtension = (url) => {
        
    const ext = url.split(/[#?]/)[0].split('.').pop().trim();
    if (ext === 'docx' || ext === 'doc') {
        return docThumb
    }
   else if (ext === 'xlsx' || ext === "xls" || ext === 'csv') {
        return xlsxThumb
    }
    else if (ext === 'pdf') {
        return pdfThumb
    }
    else if (ext === 'ppt' || ext === 'pptx') {
        return pptThumb
    }    
    else {
        return FilesThumb
    }
}