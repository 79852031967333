export const USER = {
        SIGN_UP: "users/signUp",
        SIGN_IN: "users/signIn",
        AZURE_SIGN_IN: "users/azure-signIn",
        SIGN_OUT: "users/signOut",
        CREATE_USER: "users/create-user",
        EDIT_PROFILE: "users/edit-profile",
        RESEND_PASSWORD: "users/resend-password/",
        UPDATE_USER: "users/update-user",
        ADD_USER: "users/add",
        GET_USER_BY_ID: "users/getById",
        FORGOT_PASSWORD: "users/forgotPassword",
        RESET_PASSWORD: "users/resetPassword",
        VALIDATE_OTP: "users/validateOtp",
        GET_ALL_USERS: "users/getAll/",
        GET_MULTISITE_USERS: "users/getAllMultiUsers/",
        GET_ALL_USERS_FOR_DROPDOWN: "users/get-all-users-for-dropdown/",
        GET_USERS_BY_ROLE: "users/get-user-by-roles-for-dropdown/",
        GET_USERS_FOR_REASSIGNMENT: "users/get-auditor-for-reassignment/",
        CHECK_USER_AVAILABILITY: "users/check-user-availability",
};

export const NC = {
        CREATE_NC: "nc/create-nc",
        UPDATE_NC: "nc/update-nc",
        Delete_NC: "nc/delete-nc",
        SEND_NC: "nc/send-nc",
        GET_NC_BY_ID: "nc/get-nc-by-id/",
        GET_NC_LIST_BY_USER: "nc/get-nc-list-by-user/",
        GET_NC_LOGS: "nc/get-nc-logs/",
};

export const PRO = {
        CREATE_PRO: "pro/create-pro",
        UPDATE_PRO: "pro/update-pro",
        Delete_PRO: "pro/delete-pro",
        COMPLETE_PRO: "pro/complete-pro",
        SEND_PRO: "pro/send-pro",
        GET_PRO_BY_ID: "pro/get-pro-by-id/",
};

export const TODO_LIST = {
        GET_TODO_LIST_BY_USER_ID: "todo/get-to-do-list-y-user-id/",
        GET_MULTISITE_TODO_LIST: "todo/multisite-todolist/",
};

export const TOPIC = {
        GET_TOPICS_LIST: "topic/get-all-topics/",
        GET_TOPICS_DROPDOWN: "topic/get-topics-for-dropdown/",
        UPDATE_TOPIC: "topic/update-topic",
};

export const ACTIONS = {
        GET_ORG_ACTIONS: "action/get-org-actioners",
        CREATE_CA_ACTION: "action/create-ca-action",
        DELETE_CA_ACTION: "action/delete-ca-pa",
        CREATE_PA_ACTION: "action/create-pa-action",
        GET_CA_ACTION: "action/get-ca-action/",
        GET_PA_ACTION: "action/get-pa-action/",
        GET_CA_ACTION_FOR_ACTIONER: "action/get-ca-action-for-actioner/",
        GET_PA_ACTION_FOR_ACTIONER: "action/get-pa-action-for-actioner/",
        GET_CA_ACTION_FOR_VERIFIER: "action/get-ca-action-for-verifier/",
        GET_PA_ACTION_FOR_VERIFIER: "action/get-pa-action-for-verifier/",
        ADD_VERIFIER_EVIDENCE: "action/add-verifier-evidence",
        ADD_ACTIONER_EVIDENCE: "action/add-actioner-evidence",
        SENT_CA_ACTIONS: "action/sent-ca-action",
        SENT_PA_ACTIONS: "action/sent-pa-action",
        SENT_COMPLETE_ACTIONS: "action/sent-complete-action",
        SENT_VERIFIER_RESULT: "action/sent-verifier-result",
        GET_CA_ACTION_LOGS_NC_ID: "action/get-ca-action-logs/",
        GET_PA_ACTION_LOGS_NC_ID: "action/get-pa-action-logs/",
        GET_CA_PA_ACTION_LOGS_NC_ID: "action/get-ca-pa-action-logs/",
        GET_RCA_LOGS: "rca/get-rca-logs/",
};

export const RCA = {
        CREATE_RCA: "rca/create-rca",
        UPDATE_RCA: "rca/update-rca",
        GET_RCA_ID: "rca/get-rca-by-id/",
        SEND_RCA: "rca/send-rca",
};

export const ORG = {
        CREATE_ORG: "org/create-org",
        UPDATE_ORG: "org/update-org",
        GET_ORG_BY_ID: "org/get-org-by-id/",
        GET_ALL_SUBS_ORGS: "org/get-all-subs-orgs/",
        FEED_BACK: "org/feedback",
        CHECK_ORG_AVAILABILITY: "org/check-org-availability",
};

export const STRIPE = {
        GET_SUB_PLAN_BY_ID: "stripe/get-sub-plan-by-id/",
        GET_SUB_BY_ORG_ID: "stripe/get-sub-by-org-id/",
        ADD_NEW_CARD: "stripe/add-new-card",
        GET_ACTIVE_PANS: "stripe/get-active-plans",
        SWITCH_SUBS_PLAN: "stripe/switch-subscription",
        SUBSCRIBE_SUBS_PLAN: "stripe/subscribe-subscription",
        CANCEL_SUBS_PLAN: "stripe/cancel-subs-plan",
        GET_TAX_OBJECTS: "stripe/get-tax-objects",
        CHECK_COUPON: "stripe/check-coupon/",
        BACS_PAYMENT: "misc/add-bacs-payment",
        REACTIVATE_PLAN: "stripe/re-subs-plan",
};

export const GMP = {
        GET_GMP_TEMPLATES: "gmptemp/get-gmptemplates",
        CREATE_GMP: "gmp/create-gmp",
        GET_GMP_LIST: "gmp/get-inspections",
        GMP_GUIDE: "gmp/get-gmp-questions",
        GMP_QUESTIONS: "gmp/get-gmp-byid",
        ADD_GMP_ROLES: "gmp/add-gmp-roles",
        GET_GMP_INSPECTIONS_BY_USER: "gmp/get-inspections-by-user",
        ANSWER_GMP_QUESTION: "gmp/post-answer-on-gmp",
        COMPLETE_ITEM: "gmp/complete-gmp",
        CLEAR_ITEM: "gmp/reset-gmp",
        GET_PARENT_GMP: "gmp/get-parent-gmps",
        GENERATE_GMP: "gmp/generate-gmp",
        REASSIGN_INSPECTOR: "gmp/reassign-gmp",
        GMP_INSPECTORS_LIST: "gmp/get-gmp-inspectors",
        SEND_GMP_TO_INSPECTORS: "gmp/send-gmp",
};

export const REMINDER = {
        GET_REMINDER_LIST: "misc/my-reminders",
        EDIT_REMINDER: "misc/edit-reminder",
};

export const STANDARDS = {
        GET_STANDARDS_LIST: "org/get-standards-by-org",
        ADD_REMOVE_STANDARDS: "org/update-standard",
};

export const AUDIT = {
        GENERATE_AUDIT: "sa/generate-system-audit",
        GET_AUDIT_BY_USER: "sa/get-audits-by-user",
        SEND_SA_TO_LEAD_AUDITORS: "sa/send-audit",
        REASSIGN_AUDITOR: "sa/reassign-audit",
        ANSWER_SA_QUESTION: "sa/post-answer-on-sa",
        CLEAR_ITEM: "sa/reset-sa",
        COMPLETE_ITEM: "sa/complete-sa",
        AUDIT_QUESTIONS: "sa/get-sa-byid",
};

export const RISK_ASSESMENT = {
        GET_RISK_ASSESMENT: "ra/get-ra-by-org",
};

export const STANDARDS_LOG = {
        GET_STANDARDS_LOGS: "org/get-standard-logs-by-org",
};

export const STRIPE_VALIDATION = {
        VALIDATION_SESSION: "stripe/validate-session",
};

export const SITES_LIST = {
        GET_SITES_LIST: "ms/get-sites-list",
        POST_MULTI_SITE: "ms/post-site",
        GET_MULTI_SITE_LIST: "ms/get-multisites-list",
        CREATE_MULTI_SITE: "ms/upgrade-site",
        CHECK_TO_UPGRADE: "ms/check-to-upgrade",
        UPDATE_MULTI_SITE: "ms/edit-multisite",
        GET_SITES: "ms/get-sites-by-user",
};

export const AZURE = {
        AZURE_ORG: "org/azure-orgs",
};

export const REPORTS = {
        CREATE_NC_REPORTS: "reports/create-nc-report",
        CREATE_GMP_REPORTS: "reports/create-gmp-report",
        CREATE_SA_REPORTS: "reports/create-sa-report",
        CREATE_KPI_REPORTS: "reports/create-kpi-report",
};

export const AUTHENTICATION = {
        VALIDATE_TOKEN: "misc/validate-token",
};

export const TRENDING = {
        GMP_SCORE: "trend/gmp-score",
        GMP_COMPLETION: "trend/gmp-trending",
        SA_COMPLETION: "trend/sa-trending",
        REPEAT_NCS: "trend/nc-trending",
        EXPORT_GMP_SCORE: "trend/gmp-score-export",
        EXPORT_GMP_COMPLETION: "trend/gmp-completion-export",
        EXPORT_SA_COMPLETION: "trend/sa-trending-export",
        EXPORT_REPEAT_NCS: "trend/nc-trending-export",
};

export const SCHEDULED_REPORTS = {
        FREQUENCY: "sch/frequency-menu",
        CREATE_SCHEDULE: "sch/create-schedule",
        SCHEDULED_REPORTS_API: "sch/fetch-schedules-list",
        DRAFT_SCHEDULE: "sch/start-schedule",
        ARCHIVE_REPORTS: "sch/fetch-reports-list",
};

export const UPLOAD_SHEET = {
        UPLOAD_SPREADSHEET: "sa/upload-questions",
        UPLOAD_SPREADSHEET_CC: "sa/upload-cc-questions",
        UPLOAD_AMENDMENTS: "misc/upload-ammendments",
        UPLOAD_GMP_SPREADSHEET: "gmp/upload-questions",
}