import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import request from "../../services/request";
import { ORG, STRIPE } from "../../Routes/Api";
import session from "../../services/session";
import utils from "../../utils";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { setOrgData } from "../../store/actions/user";
import { useDispatch } from "react-redux";

const Summary = () => {
    let navigate = useNavigate();
    const elements = useElements();
    const stripe = useStripe();
    const location = useLocation();
    const dispatch = useDispatch();

    const [object, setObject] = useState({
        org: {},
        subs: {},
        product: {},
        payment_method: {},
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [cardValue, setCardValue] = useState(false);
    const [cardErr, setCardErr] = useState(false);
    const [card, setCard] = useState({});

    const CARD_ELEMENT_OPTIONS = {
        iconStyle: "solid",
        hidePostalCode: true,
    };
    const org = JSON.parse(localStorage.getItem("org"));

    const onClickPayment = (field, value) => {
        switch (field) {
            case "card":
                setCard((prevState) => ({
                    type: "card",
                }));
                break;
            case "bacs":
                setCardErr(false);
                setErrorMessage("");
                setCard((prevState) => ({
                    type: "bacs",
                }));
                break;
            default:
        }
    };

    const getOrgData = async () => {
        try {
            let result = await request.get(
                `${ORG.GET_ORG_BY_ID}${session.getOrgId()}`,
                true
            );
            if (result?.status) {
                utils.resetOrgDataInSession(result?.data);
                dispatch(setOrgData(result?.data));
                setObject((old) => {
                    return {
                        ...old,
                        org: result?.data,
                    };
                });
            }
        } catch (e) { }
    };

    const getSubByOrgId = async () => {
        try {
            let result = await request.get(
                `${STRIPE.GET_SUB_BY_ORG_ID}${session.getOrgId()}`,
                true
            );
            if (result?.status)
                setObject((old) => {
                    return {
                        ...old,
                        subs: result?.data?.subs,
                        product: result?.data?.product,
                        payment_method: result?.data?.payment_method,
                        invoices: result?.data?.invoices,
                        tenantId: result?.data?.tenantId,
                    };
                });
        } catch (e) { }
    };

    const addNewCard = async () => {
        setObject((old) => {
            return {
                ...old,
                new_card: { default: false },
            };
        });
    };

    const saveNewCard = async () => {
        setLoading(true);

        if (!cardValue) {
            setCardErr(true);
            setLoading(false);
        } else {
            setCardErr(false);
            const cardElement = elements?.getElement(CardElement);
            const { paymentMethod, err } = await stripe?.createPaymentMethod({
                type: "card",
                card: cardElement,
            });
            const response = await request.post(
                "stripe/add-new-card-intent",
                { pm_id: paymentMethod.id, ...object?.org },
                true
                // false
            );
            if (response?.data?.status !== "active") {
                const { setupIntent, error } = await stripe?.confirmCardSetup(
                    response?.data?.client_secret,
                    { payment_method: paymentMethod?.id }
                );
                if (setupIntent?.status === "succeeded") {
                    setErrorMessage("");
                    let data = { pm_id: paymentMethod.id, ...object?.org };
                    let result = await request.post(STRIPE.ADD_NEW_CARD, data, true);

                    if (result?.status) {
                        setLoading(false);
                        window.location.reload();
                    }
                } else {
                    setLoading(false);
                    setErrorMessage(error?.message);
                }
            }
        }
    };

    const bacsPayment = () => {
        setLoading(true);
        request
            .post(
                STRIPE.BACS_PAYMENT,
                { customer: org?.stripe?.customer_id },
                true,
                false
            )
            .then(
                (res) => {
                    if (res?.id) {
                        window?.open(res?.url, "_blank", "width=500, height=500");
                        setLoading(false);
                    }
                },
                (error) => {
                    setLoading(false);
                }
            );
    };

    const cancelNewCard = () => {
        let copy = { ...object };
        delete copy.new_card;
        setCard({});
        // setErrorMessage("");
        setCardErr(false);
        setObject(copy);
    };

    const UpdateOrgData = () => {
        let state = { org: object?.org };
        navigate(`/private/accounts-billing`, { state });
    };

    const switchSubscription = () => {
        let find = object?.payment_method?.data?.find(
            (ob) => ob?.id === object?.org?.stripe?.payment_method_id
        );
        let state = {
            stripe: object?.org?.stripe,
            subscription: object?.org?.subscription,
            orgId: object?.org?._id,
            org: object?.org,
            product: { name: object?.product?.name },
            payment_method: find || null,
            subs: {
                currency: object?.subs?.plan?.currency,
                amount: object?.subs?.plan?.amount,
                ended_at: object?.subs?.ended_at,
                current_period_end: object?.subs?.current_period_end,
                cancel_at: object?.subs?.cancel_at,
                status: object?.subs?.status,
            },
        };
        navigate(`/private/switch-plan`, { state });
    };

    const cancelSubsPlan = async () => {
        let state = {
            org: object?.org?._id,
            subscription: object?.org?.subscription,
            subs: object?.subs,
            product: object?.product,
        };
        navigate("/private/cancel-plan", { state });
    };

    useEffect(() => {
        getOrgData();
        getSubByOrgId();
    }, []);

    useEffect(() => {
        if (location?.search === "?close=true") {
            window.close();
            window.opener.location.reload();
        }
    }, []);

    return (
        <section className="summary account-summary">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                        <h2 className="fs-18 fw-600 color-06">
                            {object?.org?.subscription?.status === "canceled" ||
                                object?.org?.subscription?.status === "Canceled"
                                ? "Plan history"
                                : "Current plan"}
                        </h2>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-8">
                                <h2 className="fs-16 fw-600 color-07">
                                    {object?.product?.name || ""}
                                </h2>
                                <h3 className="fs-16 fw-600 color-05">
                                    {object?.subs?.plan?.amount &&
                                        `£${utils.getFormattedValue(object?.subs?.plan?.amount)}` +
                                        " per month"}
                                </h3>
                                <p className="fs-14 fw-600 color-05">
                                    {object?.subs?.status === "canceled" ||
                                        object?.subs?.status === "incomplete_expired" ||
                                        object?.subs?.status === "incomplete" ? (
                                        "Your subscription has been Cancelled"
                                    ) : object?.org?.isCancelled &&
                                        object?.org?.subscription?.status === "Active" ? (
                                        object?.subs?.cancel_at &&
                                        `Your subscription will be cancelled on ${utils?.convertStripeDate(
                                            object?.subs?.cancel_at
                                        )}`
                                    ) : (
                                        <>
                                            Your plan renews on{" "}
                                            {object?.subs?.current_period_end &&
                                                `${utils.convertStripeDate(
                                                    object?.subs?.current_period_end
                                                )}`}
                                        </>
                                    )}
                                </p>
                            </div>
                            <div className="col-12 col-lg-4">
                                <button
                                    className="btn-update-plan d-lg-block mb-2 mr-2"
                                    onClick={switchSubscription}
                                >
                                    {object?.org?.subscription?.status === "canceled" ||
                                        object?.org?.subscription?.status === "Canceled"
                                        ? "Add plan"
                                        : "Update plan"}
                                </button>
                                {object?.subs?.status !== "canceled" &&
                                    !object?.org?.isCancelled && (
                                        <button
                                            className="btn-cancel-plan"
                                            onClick={cancelSubsPlan}
                                        >
                                            Cancel plan
                                        </button>
                                    )}
                            </div>
                        </div>
                        {/* Current Plan End */}
                        <div className="payment-method mt-4">
                            <h2 className="fs-18 fw-400 color-06">Payment method</h2>
                            {object?.payment_method?.data?.map((method, index) => (
                                <div key={index}>
                                    {method?.card && (
                                        <p className="fs-16 fw-600 color-05">
                                            <i className="fab fa-cc-visa" /> ****{" "}
                                            {`${method.card.last4}`} Expires{" "}
                                            {`${method?.card?.exp_month}`}/
                                            {`${method?.card?.exp_year}`}
                                        </p>
                                    )}
                                    {method?.bacs_debit && (
                                        <p className="fs-16 fw-600 color-05">
                                            Direct debit account: **** {`${method.bacs_debit.last4}`}{" "}
                                            Sort code {`${method.bacs_debit.sort_code}`}
                                        </p>
                                    )}
                                </div>
                            ))}
                            <span
                                className="fs-14 fw-400 color-08 cursor"
                                onClick={addNewCard}
                            >
                                <i className="far fa-plus-circle" /> Add payment method
                            </span>

                            {object?.new_card && (
                                <div className="mt-3">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="custom-control custom-radio">
                                                <input
                                                    type="radio"
                                                    id={"delete"}
                                                    name="customRadio1"
                                                    className="custom-control-input"
                                                />
                                                <label
                                                    className="custom-control-label color-05 fs-14 fw-400"
                                                    htmlFor={"delete"}
                                                    onClick={() => onClickPayment("card", true)}
                                                >
                                                    Card
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input
                                                    type="radio"
                                                    id={"toMultiSite"}
                                                    name="customRadio1"
                                                    className="custom-control-input"
                                                />
                                                <label
                                                    className="custom-control-label color-05 fs-14 fw-400"
                                                    htmlFor={"toMultiSite"}
                                                    onClick={() => onClickPayment("bacs", true)}
                                                >
                                                    Bacs direct debit
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {card?.type === "card" && (
                                        <div className="new-card-info mt-3">
                                            <div className="form-group">
                                                <label>Card information*</label>
                                                <div className="form-row">
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderStyle: "solid",
                                                            border: "1px solid #ced4da",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        <CardElement
                                                            options={CARD_ELEMENT_OPTIONS}
                                                            onChange={(e) => {
                                                                setCardValue(e.complete);
                                                            }}
                                                        />
                                                    </div>
                                                    {errorMessage && (
                                                        <small className="text-danger">
                                                            {errorMessage}
                                                        </small>
                                                    )}
                                                    {cardErr && (
                                                        <div className="err_feedback">
                                                            please fill required fields.
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <button
                                                    className="btn-cancel-plan mb-2 mr-2"
                                                    onClick={cancelNewCard}
                                                    disabled={loading}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="btn-update-plan"
                                                    onClick={saveNewCard}
                                                    disabled={loading}
                                                >
                                                    Add{" "}
                                                    {loading && (
                                                        <div
                                                            className="spinner-border spinner-border-sm"
                                                            role="status"
                                                            style={{ marginLeft: "2px" }}
                                                        ></div>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    {card?.type === "bacs" && (
                                        <div className="mt-3">
                                            <button
                                                className="btn-cancel-plan mb-2 mr-2"
                                                onClick={cancelNewCard}
                                                disabled={loading}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn-update-plan"
                                                disabled={loading}
                                                onClick={bacsPayment}
                                            >
                                                Proceed to Payment{" "}
                                                {loading && (
                                                    <div
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        style={{ marginLeft: "2px" }}
                                                    ></div>
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {/*  Payment Method End  */}
                        <div className="billing-info mt-4">
                            <h2 className="fs-18 fw-400 color-06">Billing information</h2>
                            <p>
                                {object?.org?.business_name} <br /> {object?.org?.address_1}{" "}
                                <br /> {object?.org?.address_2} <br /> {object?.org?.city}
                                {object?.org?.county ? <br /> : null} {object?.org?.county}{" "}
                                <br /> {object?.org?.post_code} <br />{" "}
                                {object?.org?.country?.label}
                            </p>
                            <span
                                className="fs-14 fw-400 color-08 cursor"
                                onClick={UpdateOrgData}
                            >
                                <i className="far fa-pencil" /> Update information
                            </span>
                        </div>
                        {/*  Billing Information End  */}
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 mt-5 mt-md-0">
                        <div className="billing-history">
                            <h2 className="fs-18 fw-400 color-06">Billing history</h2>
                            {object?.invoices?.data?.map((obj, index) => (
                                <dl className="row mt-4" key={index}>
                                    <dt className="col-sm-4 fs-16 fw-600 color-07">
                                        {utils.convertStripeDate(obj.created)}
                                        <a
                                            href={obj?.hosted_invoice_url || "#"}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="far fa-external-link color-07 pl-2" />
                                        </a>
                                    </dt>
                                    <dd className="col-sm-8">
                                        <div className="price">{`£${utils.getFormattedValue(
                                            obj.amount_paid
                                        )}`}</div>
                                    </dd>
                                </dl>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Summary;
