import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import session from "../../services/session";
import { useNavigate } from "react-router-dom";
import Searchbar from "../Home/TodoLIst/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import {
    filterSubscriptionsList,
    getSubscriptionsListApi,
} from "../../store/actions/subscriptions";
import { setOrgData } from "../../store/actions/user";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const Subscriptions = (props) => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);

    const { subsList } = useSelector((state) => state.subscriptions);

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                subsList?.filter((item) => {
                    if (
                        item?.business_name
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.site?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item?.subscription?.name
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase())
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div>
                <Searchbar
                    onFilter={(e) => filterData(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            </div>
        );
    }, [filterText, filteredList]);

    const handleRowSelection = ({ selectedRows }) => {
        dispatch(filterSubscriptionsList(selectedRows));
        // handleFilterListData(selectedRows);
    };

    const redirectToOrg = async (org) => {
        try {
            /*let result = await request.get(ORG.GET_ORG_BY_ID`${orgId}`,true)
                                                            if(result.status)*/
            session.setOrgId(org._id);
            session.setOrg(org);
            dispatch(setOrgData(org));
            navigate("/private/home");
        } catch (e) { }
    };

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataSubscription = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataSubscription);
    };

    const columns = [
        {
            name: "Business name",
            selector: (row) => row.business_name,
            sortable: true,
            cell: (row) => (
                <a
                    className="color-05 user-name-link cursor"
                    onClick={() => redirectToOrg(row)}
                >
                    {row.business_name}
                </a>
            ),
        },
        {
            name: "Site",
            selector: (row) => row.site,
            sortable: true,
            cell: (row) => row.site,
        },
        {
            name: "Subscription",
            selector: (row) => row?.subscription?.name,
            sortable: true,
            cell: (row) => row?.subscription?.name,
        },
        {
            name: "Status",
            selector: (row) =>
                row?.subscription?.status === "Canceled" ||
                    row?.subscription?.status === "canceled"
                    ? "Cancelled"
                    : row?.subscription?.status,
            sortable: true,
            cell: (row) =>
                row?.subscription?.status === "Canceled" ||
                    row?.subscription?.status === "canceled"
                    ? "Cancelled"
                    : row?.subscription?.status,
        },
    ];

    const getAllOrg = () => {
        dispatch(getSubscriptionsListApi());
    };

    useEffect(() => {
        getAllOrg();
    }, []);

    useEffect(() => {
        setFilteredList(subsList);
    }, [subsList]);

    return (
        <div className="table-responsive">
            <DataTable
                columns={columns}
                data={filteredList}
                selectableRows
                customStyles={customStyles}
                striped
                onSelectedRowsChange={handleRowSelection}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />
        </div>
    );
};

export default Subscriptions;
