import { createStore, applyMiddleware, combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

import loggerMiddleware from "redux-logger";
import thunkMiddleware from "redux-thunk";

import user from "./reducers/user";
import nc from "./reducers/nc";
import pro from "./reducers/pro";
import todoList from "./reducers/todoList";
import topic from "./reducers/topic";
import tabState from "./reducers/tabState";
import action from "./reducers/action";
import rca from "./reducers/rca";
import plan from "./reducers/plan";
import navigate from "./reducers/navigate";
import gmpTemplate from "./reducers/gmpTemplate";
import gmp from "./reducers/gmp";
import reminder from "./reducers/reminder";
import audit from "./reducers/audit";
import riskAssesment from "./reducers/riskAssesment";
import additionalStandards from "./reducers/additionalStandards";
import subscriptions from "./reducers/subscriptions";
import sites from "./reducers/sites";
import kpi from "./reducers/kpi";
import reportScheduler from "./reducers/reportScheduler";

const CLEAR_ON_LOGOUT = "CLEAR_ON_LOGOUT";

export const clearOnLogout = () => {
  return {
    type: CLEAR_ON_LOGOUT,
  };
};

const appReducer = combineReducers({
  user,
  nc,
  pro,
  todoList,
  topic,
  tabState,
  action,
  rca,
  plan,
  navigate,
  gmpTemplate,
  gmp,
  reminder,
  audit,
  riskAssesment,
  additionalStandards,
  subscriptions,
  sites,
  kpi,
  reportScheduler,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

const rootReducer = (state, action) => {
  if (action.type === CLEAR_ON_LOGOUT) {
    // set state to initial state
    state = undefined;
  }
  return appReducer(state, action);
};

const store = createStore(
  persistedReducer,
  applyMiddleware(thunkMiddleware, loggerMiddleware)
);

const persistor = persistStore(store);

export { store, persistor };
