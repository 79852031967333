import { GMP_CONSTANTS } from "../constants";
import { APP_CONSTANTS } from "../../constants";
import request from "../../services/request";
import { GMP } from "../../Routes/Api";
import session from "../../services/session";
import { changeTabState } from "./tabState";

export const createGMP = (payload) => {
  return {
    type: GMP_CONSTANTS.CREATE_GMP,
    payload,
  };
};

export const createGMPSuccess = (payload) => {
  return {
    type: GMP_CONSTANTS.CREATE_GMP_SUCCESS,
    payload,
  };
};

export const updateGMPSuccess = (payload) => {
  return {
    type: GMP_CONSTANTS.UPDATE_GMP_SUCCESS,
    payload,
  };
};

export const updateGMPObject = (payload) => {
  return {
    type: GMP_CONSTANTS.UPDATE_GMP_OBJECT,
    payload,
  };
};

export const toggleSuccessModal = (payload) => {
  return {
    type: GMP_CONSTANTS.TOGGLE_GMP_SUCCESS_MODAL,
    payload,
  };
};

export const GMPResponseError = (payload) => {
  return {
    type: GMP_CONSTANTS.GMP_RESPONSE_ERROR,
    payload,
  };
};

export const toggleErrorModal = (payload) => {
  return {
    type: GMP_CONSTANTS.TOGGLE_GMP_ERROR_MODAL,
    payload,
  };
};

const getGMPListSuccess = (payload) => {
  return {
    type: GMP_CONSTANTS.GET_GMP_LIST_SUCCESS,
    payload,
  };
};

const getUserGMPListSuccess = (payload) => {
  return {
    type: GMP_CONSTANTS.GET_GMP_USER_LIST_SUCCESS,
    payload,
  };
};

export const pushGmpToList = (payload) => {
  return {
    type: GMP_CONSTANTS.PUSH_GMP_IN_LIST,
    payload,
  };
};

export const clearGMPObject = (payload) => {
  return {
    type: GMP_CONSTANTS.CLEAR_GMP_OBJECT,
    payload,
  };
};

export const getGMPQuestionsSuccess = (payload) => {
  return {
    type: GMP_CONSTANTS.GMP_QUESTIONS_SUCCESS,
    payload,
  };
};

export const addGMPRoleSuccess = (payload) => {
  return {
    type: GMP_CONSTANTS.ADD_GMP_ROLE_SUCCESS,
    payload,
  };
};

export const addGMPRoleError = (payload) => {
  return {
    type: GMP_CONSTANTS.ADD_GMP_ROLE_ERROR,
    payload,
  };
};

export const updateQuestions = (payload) => {
  return {
    type: GMP_CONSTANTS.UPDATE_QUESTIONS,
    payload,
  };
};

export const filterGmpList = (payload) => {
  return {
    type: GMP_CONSTANTS.FILTER_GMP_LIST,
    payload,
  };
};

export const emptyFilterGmpList = (payload) => {
  return {
    type: GMP_CONSTANTS.EMPTY_FILTER_GMP_LIST,
    payload,
  };
};

export const gmpQuestionCurrentIndex = (payload) => {
  return {
    type: GMP_CONSTANTS.GMP_QUESTION_CURRENT_INDEX,
    payload,
  };
};

// Create GMP API
export const createGMPAPi = (data, from) => {
  return (dispatch) => {
    request.post(GMP.CREATE_GMP, data, true, false).then(
      (res) => {
        dispatch(createGMPSuccess(res?.data));
        dispatch(toggleSuccessModal(true));
        setTimeout(() => {
          dispatch(toggleSuccessModal(false));
          // dispatch(clearGMPObject({ ...GMP_CONSTANTS.GET_DEFAULT_GMP_OBJ() }));
        }, APP_CONSTANTS.MODAL_SHOW_TIME);
      },
      (error) => {
        dispatch(GMPResponseError(error));
        setTimeout(() => {
          dispatch(toggleErrorModal(false));
        }, APP_CONSTANTS.MODAL_SHOW_TIME);
      }
    );
  };
};

// Update GMP API
export const updateGMPAPi = (data, from) => {
  return (dispatch) => {
    request.post(GMP.CREATE_GMP, data, true, false).then(
      (res) => {
        dispatch(updateGMPSuccess(res?.data));
        dispatch(toggleSuccessModal(true));
        setTimeout(() => {
          dispatch(toggleSuccessModal(false));
          // dispatch(clearGMPObject({ ...GMP_CONSTANTS.GET_DEFAULT_GMP_OBJ() }));
        }, APP_CONSTANTS.MODAL_SHOW_TIME);
      },
      (error) => {
        dispatch(GMPResponseError(error));
        setTimeout(() => {
          dispatch(toggleErrorModal(false));
        }, APP_CONSTANTS.MODAL_SHOW_TIME);
      }
    );
  };
};

// Get GMP List API
export const getGMPListAPi = (orgId = session.getOrgId()) => {
  return (dispatch) => {
    request.get(`${GMP.GET_GMP_LIST}/${orgId}`, true).then(
      (res) => {
        dispatch(getGMPListSuccess(res?.data));
      },
      (error) => { }
    );
  };
};

// Add GMP Roles API
export const addGmpRoles = (data) => {
  return (dispatch) => {
    request.put(GMP.ADD_GMP_ROLES, data, true, false).then(
      (res) => {
        dispatch(addGMPRoleSuccess(true));
        dispatch(clearGMPObject({ ...GMP_CONSTANTS.GET_DEFAULT_GMP_OBJ() }));
      },
      (error) => { }
    );
  };
};

// Get GMP Inspections by User
export const getGMPInspectionsByUser = (orgId = session.getOrgId()) => {
  return (dispatch) => {
    request
      .get(`${GMP.GET_GMP_INSPECTIONS_BY_USER}/${orgId}`, true, false)
      .then(
        (res) => {
          dispatch(getUserGMPListSuccess(res?.data));
        },
        (error) => { }
      );
  };
};

// Answer GMP Questions
export const answerGmpQuestion = async (data) => {
  await request.post(GMP.ANSWER_GMP_QUESTION, data, true, false).then(
    (res) => {
      if (res?.status) {
        return res;
      }
    },
    (error) => {
      return error;
    }
  );
};

// Generate GMPs
export const generateGMPs = (data) => {
  return (dispatch) => {
    request.post(GMP.GENERATE_GMP, data, true, false).then(
      (res) => {
        dispatch(createGMPSuccess(res?.data));
        dispatch(toggleSuccessModal(true));

        setTimeout(() => {
          dispatch(toggleSuccessModal(false));
          dispatch(changeTabState(1));
        }, APP_CONSTANTS.MODAL_SHOW_TIME);
      },
      (error) => {
        dispatch(GMPResponseError(error));
        setTimeout(() => {
          dispatch(toggleErrorModal(false));
        }, APP_CONSTANTS.MODAL_SHOW_TIME);
      }
    );
  };
};
