import { KPI_CONST } from "../constants";

export const currentKpiList = (payload) => {
    return {
        type: KPI_CONST.CURRENT_KPI_LIST,
        payload,
    };
};
export const currentChartType = (payload) => {
    return {
        type: KPI_CONST.CURRENT_CHART_TYPE,
        payload,
    }
}
