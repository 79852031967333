import React from 'react';

const CompU = () => {
    return (
        <>
            <h2 id="u">U</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading345">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse345" aria-expanded="true"
                                    aria-controls="collapse345">
                                Unacceptable parameters
                            </button>
                        </h2>
                    </div>
                    <div id="collapse345" className="collapse show" aria-labelledby="heading345"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When a product or service is measured and the results sit within the unacceptable range of the parameters.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading346">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse346" aria-expanded="false"
                                    aria-controls="collapse346">
                                Up-to-date
                            </button>
                        </h2>
                    </div>
                    <div id="collapse346" className="collapse" aria-labelledby="heading346"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An up-to-date document is one which is; a) the current version and b) contains information that is compliant to current standards. It means that when a standard is updated, the document is updated in line with this and that all old documents are removed from circulation, so only the most up-to-date version is in use.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading347">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse347" aria-expanded="false"
                                    aria-controls="collapse347">
                                Use by
                            </button>
                        </h2>
                    </div>
                    <div id="collapse347" className="collapse" aria-labelledby="heading347"
                         data-parent="#accordionGlossary">
                        <div className="card-body">

                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompU;