import React from 'react';

const CompS = () => {
    return (
        <>
            <h2 id="s">S</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading295">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse295" aria-expanded="true"
                                    aria-controls="collapse295">
                                Safe
                            </button>
                        </h2>
                    </div>
                    <div id="collapse295" className="collapse show" aria-labelledby="heading295"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Product which will not cause the consumer harm.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading296">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse296" aria-expanded="false"
                                    aria-controls="collapse296">
                                Scale interval
                            </button>
                        </h2>
                    </div>
                    <div id="collapse296" className="collapse" aria-labelledby="heading296"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            This is a term used to describe the increments of measurement on a weighing scale, as shown on the display. For example, an interval of 0.01g means that the display shows the weight increasing by increments of 0.1g (0.1, 0.2, 0.3 etc.).
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading297">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse297" aria-expanded="false"
                                    aria-controls="collapse297">
                                Scheduled
                            </button>
                        </h2>
                    </div>
                    <div id="collapse297" className="collapse" aria-labelledby="heading297"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When something is 'scheduled' it means it's planned in advance, and booked with those that need to attend them. This is to prove that it will happen. Dates must be documented, or booked in a calendar (such as an online calendar).
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading298">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse298" aria-expanded="false"
                                    aria-controls="collapse298">
                                Scheduling
                            </button>
                        </h2>
                    </div>
                    <div id="collapse298" className="collapse" aria-labelledby="heading298"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To plan and agree date(s) for activities and meetings.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading299">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse299" aria-expanded="false"
                                    aria-controls="collapse299">
                                Schematic
                            </button>
                        </h2>
                    </div>
                    <div id="collapse299" className="collapse" aria-labelledby="heading299"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A detailed diagram that shows the layout of pipework and the connections between the pipes and equipment. The term is typically used to show the layout of the water ring main and pipework, drains or CIP to show the pipework of the clean in place system.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading300">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse300" aria-expanded="false"
                                    aria-controls="collapse300">
                                Scheme owner
                            </button>
                        </h2>
                    </div>
                    <div id="collapse300" className="collapse" aria-labelledby="heading300"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A business which sets a certification standard, using a written set of rules.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading301">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse301" aria-expanded="false"
                                    aria-controls="collapse301">
                                Secondary packaging
                            </button>
                        </h2>
                    </div>
                    <div id="collapse301" className="collapse" aria-labelledby="heading301"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Secondary packaging is the second layer of packaging, which is applied to the primary packaging and therefore doesn't come into contact with the product. Such as a cardboard sleeve or label.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading302">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse302" aria-expanded="false"
                                    aria-controls="collapse302">
                                Secure
                            </button>
                        </h2>
                    </div>
                    <div id="collapse302" className="collapse" aria-labelledby="heading302"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To make something secure, is to stop unauthorised personnel accessing it. See also; product security, security measures, document security
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading303">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse303" aria-expanded="false"
                                    aria-controls="collapse303">
                                Security
                            </button>
                        </h2>
                    </div>
                    <div id="collapse303" className="collapse" aria-labelledby="heading303"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To protect something from harm. See also; product security, security measures, document security
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading304">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse304" aria-expanded="false"
                                    aria-controls="collapse304">
                                Self-care
                            </button>
                        </h2>
                    </div>
                    <div id="collapse304" className="collapse" aria-labelledby="heading304"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Self-care is the practice of looking after your own health. In the food industry the aim of self-care is to protect the product from infection which may cause food poisoning of the consumer of the final product.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading305">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse305" aria-expanded="false"
                                    aria-controls="collapse305">
                                Senior management commitment
                            </button>
                        </h2>
                    </div>
                    <div id="collapse305" className="collapse" aria-labelledby="heading305"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A behaviour, where senior management are dedicated to effective compliance to the standard, product safety, legality, authenticity and the quality agreed with the customer.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading306">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse306" aria-expanded="false"
                                    aria-controls="collapse306">
                                Senior management / management
                            </button>
                        </h2>
                    </div>
                    <div id="collapse306" className="collapse" aria-labelledby="heading306"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            There is no given defintion which explains the difference between management and senior management. Management who are deemed to be senior will differ from busines to business, depending on the specific business structure.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading307">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse307" aria-expanded="false"
                                    aria-controls="collapse307">
                                Severity
                            </button>
                        </h2>
                    </div>
                    <div id="collapse307" className="collapse" aria-labelledby="heading307"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Severity is used in hazard analysis, to determine the seriousness that a hazard will have on the consumer.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading308">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse308" aria-expanded="false"
                                    aria-controls="collapse308">
                                Shelf life protocol
                            </button>
                        </h2>
                    </div>
                    <div id="collapse308" className="collapse" aria-labelledby="heading308"
                         data-parent="#accordionGlossary">
                        <div className="card-body">

                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading309">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse309" aria-expanded="false"
                                    aria-controls="collapse309">
                                Signed
                            </button>
                        </h2>
                    </div>
                    <div id="collapse309" className="collapse" aria-labelledby="heading309"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Signing a document means that a commitment is being made. It means that the person signing is saying 'I agree to this and so I am putting my name to it'. A signature can be done by physically signing with a pen, or it can be a digital signature. Either way, it must be traceable back to the person who signed it. And only traceable back to that one person, which means a digital signature must be unique to one person.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading310">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse310" aria-expanded="false"
                                    aria-controls="collapse310">
                                Significant
                            </button>
                        </h2>
                    </div>
                    <div id="collapse310" className="collapse" aria-labelledby="heading310"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that has a direct impact and requires action. Further guidance; significant changes to the standard, significant risk
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading311">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse311" aria-expanded="false"
                                    aria-controls="collapse311">
                                Significant changes to the standard
                            </button>
                        </h2>
                    </div>
                    <div id="collapse311" className="collapse" aria-labelledby="heading311"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Every change to the standard requires a review, however the size and scale of the review required will depend on the change. The simplest form of review following a change to the standard, would be where one person assesses the change, to determine if action is required or not. Where no action is required, that would be the end of the review. But, where the change to the standard has an impact on the current site systems, this is deemed to be significant and therefore, action is required. A review would then need to be carried out with all relevant personnel. As an auditor, you need to establish; a) if changes to the standards have occurred, b) if these changes have been properly considered in a review with relevant personnel, c) if the changes have been actioned and d) where no action has been taken, there a solid justification.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading312">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse312" aria-expanded="false"
                                    aria-controls="collapse312">
                                Signposted
                            </button>
                        </h2>
                    </div>
                    <div id="collapse312" className="collapse" aria-labelledby="heading312"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To direct someone to the required information, by referencing the document where the information is located.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading313">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse313" aria-expanded="false"
                                    aria-controls="collapse313">
                                Site
                            </button>
                        </h2>
                    </div>
                    <div id="collapse313" className="collapse" aria-labelledby="heading313"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The physical location where business is conducted. This is the address that appears on the accreditation certificate.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading314">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse314" aria-expanded="false"
                                    aria-controls="collapse314">
                                Site takes ownership locally
                            </button>
                        </h2>
                    </div>
                    <div id="collapse314" className="collapse" aria-labelledby="heading314"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where objectives are set by group (central or head office) the site must still be held responsible for the achievement of the objectives. It is not acceptable to disregard responsibility because the objectives were set by group. The site must take ownership of the objectives, to make sure that they are achieved at local site level.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading315">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse315" aria-expanded="false"
                                    aria-controls="collapse315">
                                SMART
                            </button>
                        </h2>
                    </div>
                    <div id="collapse315" className="collapse" aria-labelledby="heading315"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            SMART is an acronym for; Specific, Measurable, Assigned, Realistic, Timebound. SMART is commonly used as a way of making sure that actions are defined properly. Specific - means that the action must be detailed enough, so that there are no misunderstanding about what needs to be done. As an auditor, you should be able to read and then understand the action, if not, then it's not specific enough. Measurable - means that it's clear when the action has been successful. This isn't just that the action has been done, but the action must include a way of measuring whether it has been successful. Assigned - an action must be given to someone to do, and they must know that they need to do it. Realistic - there is no point in setting goals which cannot be achieved. When setting actions, the team needs to be realistic about what will be needed to achieve the action. This should take into account any resources that would need to be provided and these should be documented. Timebound - there must be a date set to when the action must be completed by, and the date in which the measurement of the action is achieved.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading316">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse316" aria-expanded="false"
                                    aria-controls="collapse316">
                                SME
                            </button>
                        </h2>
                    </div>
                    <div id="collapse316" className="collapse" aria-labelledby="heading316"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Subject Matter Expert. A person who is skilled and knowledgeable about a particular subject.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading317">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse317" aria-expanded="false"
                                    aria-controls="collapse317">
                                Snowball effect
                            </button>
                        </h2>
                    </div>
                    <div id="collapse317" className="collapse" aria-labelledby="heading317"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where an action prompts another action - or a reaction. This means the result gets bigger and bigger. The results can be negative or positive, but always in the same direction. So for example, one positive action prompts another positive action, therefore the results get more and more positive. Or on the reverse, one negative action prompts another negative action, therefore the results get more and more negative.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading318">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse318" aria-expanded="false"
                                    aria-controls="collapse318">
                                Soft skills
                            </button>
                        </h2>
                    </div>
                    <div id="collapse318" className="collapse" aria-labelledby="heading318"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Skills which affect the way you behave. For example, communication styles, listening skills, attitude, self-control, empathy.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading319">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse319" aria-expanded="false"
                                    aria-controls="collapse319">
                                SOP
                            </button>
                        </h2>
                    </div>
                    <div id="collapse319" className="collapse" aria-labelledby="heading319"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Standard Operating Procedure. This type of document is typically used to provide step-by-step instructions on how to use a piece of equipment.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading320">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse320" aria-expanded="false"
                                    aria-controls="collapse320">
                                Specification
                            </button>
                        </h2>
                    </div>
                    <div id="collapse320" className="collapse" aria-labelledby="heading320"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A specification is a written agreement between two parties.  Therefore, a material specification is an agreement between your company and the supplier. And a finished product specification, is an agreement between your company and the customer. It contains details about what an acceptable product or service is, and what's not acceptable. It includes parameters for safety, quality, authenticity and legality.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading321">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse321" aria-expanded="false"
                                    aria-controls="collapse321">
                                Staff
                            </button>
                        </h2>
                    </div>
                    <div id="collapse321" className="collapse" aria-labelledby="heading321"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A permenant employee, provided by and managed by the company. See also; agency staff, contractor
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading322">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse322" aria-expanded="false"
                                    aria-controls="collapse322">
                                Standard
                            </button>
                        </h2>
                    </div>
                    <div id="collapse322" className="collapse" aria-labelledby="heading322"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A set of rules that must be adhered to. Standards can come in different forms such as; certification standard, customer standard, parameters, specification.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading323">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse323" aria-expanded="false"
                                    aria-controls="collapse323">
                                Standard deviation
                            </button>
                        </h2>
                    </div>
                    <div id="collapse323" className="collapse" aria-labelledby="heading323"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            This is the term used to determine how wide (or narrow) a range of results are. A process with a small standard deviation, is in control. The wider the standard deviation, the more out of control the process is. Standard deviation can be calculated using the STDEV function in excel.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading324">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse324" aria-expanded="false"
                                    aria-controls="collapse324">
                                Standing water
                            </button>
                        </h2>
                    </div>
                    <div id="collapse324" className="collapse" aria-labelledby="heading324"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Standing water can be a lake or reservoir. Or it can be a puddle of water. Standing water typically attracts pests.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading325">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse325" aria-expanded="false"
                                    aria-controls="collapse325">
                                Study
                            </button>
                        </h2>
                    </div>
                    <div id="collapse325" className="collapse" aria-labelledby="heading325"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A skill that is gained through theorietical learning of a subject. It is not to learn the subject practically, which be experience.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading326">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse326" aria-expanded="false"
                                    aria-controls="collapse326">
                                Subject matter expert
                            </button>
                        </h2>
                    </div>
                    <div id="collapse326" className="collapse" aria-labelledby="heading326"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Someone who is highly knowledgable in a particular subject. See also; subject matter training
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading327">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse327" aria-expanded="false"
                                    aria-controls="collapse327">
                                Subject matter training
                            </button>
                        </h2>
                    </div>
                    <div id="collapse327" className="collapse" aria-labelledby="heading327"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To provide training to a high level, so that the person becomes an expert in that subject.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading328">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse328" aria-expanded="false"
                                    aria-controls="collapse328">
                                Subjective
                            </button>
                        </h2>
                    </div>
                    <div id="collapse328" className="collapse" aria-labelledby="heading328"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When something is subjective, it's based on a persons own opinion or views. It's not based on facts. Variations; [objective*
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading329">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse329" aria-expanded="false"
                                    aria-controls="collapse329">
                                Subjective
                            </button>
                        </h2>
                    </div>
                    <div id="collapse329" className="collapse" aria-labelledby="heading329"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where something is open to interpretation, so it's influenced by a person's opinion or feelings. Subjective assessment means that the result has to be interpreted by the individual. Subjective assessments shouldn't be used to assess critical limits, as they safety can't be open to interpretation.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading330">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse330" aria-expanded="false"
                                    aria-controls="collapse330">
                                Substandard
                            </button>
                        </h2>
                    </div>
                    <div id="collapse330" className="collapse" aria-labelledby="heading330"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Product that doesn't meet the agreed specification.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading331">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse331" aria-expanded="false"
                                    aria-controls="collapse331">
                                Supply-chain
                            </button>
                        </h2>
                    </div>
                    <div id="collapse331" className="collapse" aria-labelledby="heading331"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A collective term that covers all physical parts of the process, which are required to get a product to the consumer. It includes farm level, processing, storage and logistics.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading332">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse332" aria-expanded="false"
                                    aria-controls="collapse332">
                                Survey
                            </button>
                        </h2>
                    </div>
                    <div id="collapse332" className="collapse" aria-labelledby="heading332"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An exercise which is carried out to establish what the staff on site think about whether the company is meetings it's set values and vision statements, which are aligned with the companies target culture. This is normally carried out using a questionnaire, but there is no right or wrong way of doing it. The only aim is to understand what staff think about the sites culture.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading333">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse333" aria-expanded="false"
                                    aria-controls="collapse333">
                                Swarf
                            </button>
                        </h2>
                    </div>
                    <div id="collapse333" className="collapse" aria-labelledby="heading333"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Fine metal fillings or shavings which come from engineering practises, such as welding or grinding.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompS;