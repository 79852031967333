import React, {useEffect, useState} from 'react';
import StripeWidget from "./StripeWidget";
import DirectDebit from "../../Assets/images/direct-debit.png";
import {useLocation, useNavigate} from "react-router-dom";
import request from "../../services/request";
import { ORG } from "../../Routes/Api";
import CryptoJS from "crypto-js"

const StripeConfirm = () => {
    const {state} = useLocation();
    let navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [object, setObject] = useState({})
    const handleSubmit = async () => {

        try {
            setLoader(() => {
                return true
            })
            let data = arrangeDataForRequest()
            let result = await request.post(ORG.CREATE_ORG, data, false, true)
            if (result.status) {
                setLoader(() => {
                    return false
                })
                navigate('/stripe/thank-you', {state: {first_name: object?.user.first_name}})
            }
                

        } catch (e) {
            setLoader(() => {
                return false
            })
            setObject((old) => {
                return {
                    ...old,
                    error: {
                        show: true,
                        message: e.message,
                        stripe_error: e.data
                    }
                }
            })
        }
    }
    const redirectToCheckoutPage = () => {
        let state = {...object}
        navigate(`/stripe/check-out/${object?.price_id}`, {state})
    }
    const arrangeDataForRequest = () => {
        if (object.payment_method === "card")
            object.card = {...object.card, ...object.org, default: true}
        if (object.payment_method === "direct_bank")
            object.bank = {...object.bank, ...object.org, default: true}
        let formData = new FormData()
        
        let encData = CryptoJS.AES.encrypt(JSON.stringify({...object}), process.env.REACT_APP_CRYPTO_PRIVATE_KEY)

        formData.append("data", encData)
        return formData
    }
    useEffect(() => {
        setObject(state)
    }, [state])

    return (
        <>
                        <section className="checkout">
                <div className="container-fluid">
            <section className="checkout-form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <StripeWidget/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label>Payment method</label>
                                <div className="form-row">
                                    <div className="col-12 col-md-12 col-lg-6 mb-2">
                                        <button className="stripe-card"><i className="far fa-credit-card"/> Card
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <button className="stripe-account stripe-active"><i
                                            className="far fa-university"/> Bacs direct debit
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-row">
                                    <div className="col-11 mb-2">
                                        <label>Confirm your direct debit details</label>
                                    </div>
                                    <div className="col-1">
                                        <a onClick={redirectToCheckoutPage}>Edit</a>
                                    </div>
                                </div>
                            </div>
                            <dl className="row ml-3">
                                <dt className="col-sm-4">Email</dt>
                                <dd className="col-sm-8">{object?.org?.work_email}</dd>

                                <dt className="col-sm-4">Account name</dt>
                                <dd className="col-sm-8">{object?.bank?.name_on_account}</dd>

                                <dt className="col-sm-4">Sort code</dt>
                                <dd className="col-sm-8">{object?.bank?.sort_code}</dd>

                                <dt className="col-sm-4 text-truncate">Account number</dt>
                                <dd className="col-sm-8">{object?.bank?.account_number}</dd>
                            </dl>
                            <p>You will receive two days advance notice for any amount to be debited under this
                                instruction. Payments will show as 'Techni-K' on your bank statement. After you complete
                                the setup, an email confirmation will be sent to {object?.org?.work_email} within three business
                                        days.</p>
                                        {object?.error?.show &&
                                        <div className="float-right" style={{width: '210px'}}><p
                                            className="warning-msg"><i
                                            className="fal fa-exclamation-triangle color-04"/> {object?.error?.message}
                                        </p>
                                        </div>}
                            <div className="justify-content-center d-flex">
                                <button className="btn-start-trial d-lg-block mb-2 mr-2" onClick={handleSubmit}>Set up
                                            your direct debit payments
                                {loader && <span className="spinner-border spinner-border-sm spinner-position" role="status" aria-hidden="true" />}
                                </button>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 col-md-12 col-lg-6">
                                    <p className="mb-0">Your payments are protected by the</p>
                                    <a href={"#"}>Direct Debit Guarantee</a>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <img src={DirectDebit} className="img-fluid float-lg-right" alt="Direct Debit"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                </div>
            </section>
        </>
    );
};

export default StripeConfirm;