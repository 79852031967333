import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
    updateNCObject,
    createNcAPi,
    addAttachment,
    updateNcAPi,
    sendNcApi,
    createAndSendApi,
    toggleSuccessModal,
    toggleSendModal,
    toggleErrorModal,
} from "../../store/actions/nc";
import { changeTabState } from "../../store/actions/tabState";
import { getUsersByRoleApi } from "../../store/actions/user";
import { APP_CONSTANTS } from "../../constants";
import { getTopicDropdownApi } from "../../store/actions/topic";
import BrowseModal from "../Modals/BrowseModal";
import ErrorModal from "../Modals/ErrorModal";
import SaveModal from "../Modals/SaveModal";
import moment from "moment";
import NotificationSendModal from "../Modals/NotificationSendModal";
import NotificationErrorModal from "../Modals/NotificationErrorModal";
import utils from "../../utils";
import { deleteMediaFromS3Bucket } from "../../utils/s3";
import { getExtension } from "../../utils/icons";
import videoIcon from "../../Assets/images/file-video-regular.svg";

const CreateNc = () => {
    const dispatch = useDispatch();
    const btnRef = useRef(null);
    const currentUrl = window.location.href;

    const [validationError, setValidationError] = useState({});
    const { object, success, error, send, disabled } = useSelector(
        (state) => state?.nc
    );
    const topicOptions = useSelector((state) => state?.topic?.dropdown);
    const { usersByRole } = useSelector((state) => state?.user);

    const sourceOptions = APP_CONSTANTS.SOURCE_OPTIONS;
    const complaintTypeOptions = APP_CONSTANTS.COMPLAINT_TYPES;
    const legalTypeOptions = APP_CONSTANTS.LEGAL_TYPES;
    const qualityTypeOptions = APP_CONSTANTS.QUALITY_TYPES;
    const foreignBodyTypeOptions = APP_CONSTANTS.FOREIGN_BODY_TYPES;
    const microbialTypeOptions = APP_CONSTANTS.MICROBIAL_TYPES;
    const ncTypeOptions = APP_CONSTANTS.NC_TYPE;
    const productTestAndInspectionFailureTypeOptions =
        APP_CONSTANTS.PRODUCT_TEST_AND_INSPECTION_FAILURE_YPE;

    const handledFieldChanges = (field, value, custom, index) => {
        if (value && value !== "") {
            setValidationErrorObj(field, false);
        } else {
            setValidationErrorObj(field, true);
        }

        let ncObjCopy = { ...object };
        if (!custom) ncObjCopy[field] = value;

        if (
            ncObjCopy["source"]?.value === 11 ||
            ncObjCopy["source"]?.value === 12
        ) {
            ncObjCopy["isAutomatic"] = true;
        } else {
            ncObjCopy["isAutomatic"] = false;
        }

        switch (field) {
            case "topic":
                if (ncObjCopy[field]?.value !== value?.value)
                    ncObjCopy["newTopic"] = true;
                ncObjCopy[field] = value;
                break;
            case "custom_title":
                if (ncObjCopy.evidence)
                    ncObjCopy.evidence[index]["custom_name"] = value;
                break;
            default:
        }
        dispatch(updateNCObject(ncObjCopy));
    };

    const handleSave = () => {
        btnRef.current.disabled = true;
        if (validateObject()) return;
        else {
            let formData = setFormData({ ...object, btn: "save" });
            if (object?._id) {
                dispatch(
                    updateNcAPi(
                        formData,
                        currentUrl?.includes("program-info")
                            ? "editNcFromProgrammeInfo"
                            : "createNc"
                    )
                );
            } else {
                dispatch(createNcAPi(formData));
            }
        }
    };

    const handleSend = () => {
        btnRef.current.disabled = true;
        if (validateObject()) return;
        else {
            let formData = setFormData({ ...object });
            if (object?._id) {
                dispatch(sendNcApi({ object: object, _id: object._id, update: true }));
            } else {
                dispatch(createAndSendApi(formData));
            }
        }
    };

    const extractCustomTitles = (data) => {
        data.custom_names = [];
        data?.evidence.map((obj, index) => {
            if (Boolean(obj.custom_name) && !obj.destination)
                data.custom_names.push({ custom_name: obj.custom_name, index });
        });
    };

    const setFormData = (data) => {
        let formData = new FormData();
        /*if (data?.evidence?.length) {
                                            extractCustomTitles(data)
                                            data?.evidence?.map((file) => {
                                                formData.append("media", file)
                                            })
                                        }*/
        formData.append("data", JSON.stringify(data));
        return formData;
    };

    const removeEvidence = async (obj, index) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            try {
                let ncCopy = { ...object };
                if (obj.key) await deleteMediaFromS3Bucket(obj.key);
                ncCopy?.removeFiles
                    ? ncCopy.removeFiles.push(ncCopy?.evidence[index])
                    : (ncCopy.removeFiles = [ncCopy?.evidence[index]]);
                ncCopy?.evidence?.splice(index, 1);
                dispatch(updateNCObject(ncCopy));
            } catch (e) { }
        }
    };

    useEffect(() => {
        dispatch(getTopicDropdownApi());
        if (!object?.questions) {
            object.questions = APP_CONSTANTS.GRADING_QUESTIONS_ARRAY;
            dispatch(updateNCObject(object));
        }
    }, []);

    useEffect(() => {
        if (!Object.keys(usersByRole).length) dispatch(getUsersByRoleApi());
        // else if (usersByRole?.usersCanCreateNc)
        //     utils.getRaisedByUser(
        //         dispatch,
        //         updateNCObject,
        //         object,
        //         "raised_by",
        //         usersByRole.usersCanCreateNc
        //     );
    }, [usersByRole]);

    useEffect(() => {
        if (send)
            setTimeout(() => {
                dispatch(changeTabState(1));
            }, APP_CONSTANTS.MODAL_SHOW_TIME);
    }, [send]);

    const handleQuestionArrayChanges = (level, ans) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            let arrayQuesCopy = JSON.parse(JSON.stringify(object.questions));
            if (level === "firQ")
                arrayQuesCopy.forEach((obj, index) => {
                    if (obj?.checked) {
                        obj?.options?.forEach((op) => {
                            if (op.parentId === ans.parentId && op.id === ans.id) {
                                op.checked = true;
                                if (op.A) {
                                    setValidationErrorObj("firQ", false);
                                    object["grade"] = op.A;
                                }
                            } else op.checked = false;
                        });
                    }
                });
            if (level === "secQ")
                arrayQuesCopy.forEach((obj, index) => {
                    if (obj?.checked) {
                        obj?.options?.forEach((op) => {
                            if (op?.checked && op?.child?.length) {
                                op.child.forEach((ch) => {
                                    if (ch.parentId === ans.parentId) {
                                        ch.checked = true;
                                        if (ch?.checked && ch?.options?.length)
                                            ch.options?.forEach((gc) => {
                                                if (gc.id === ans.id) {
                                                    gc.checked = true;
                                                    if (gc.A) {
                                                        setValidationErrorObj("secQ", false);
                                                        object["grade"] = gc.A;
                                                    }
                                                }
                                            });
                                        else {
                                            ch.checked = true;
                                            if (ch.A) {
                                                setValidationErrorObj("secQ", false);
                                                object["grade"] = ch.A;
                                            }
                                        }
                                    } else ch.checked = false;
                                });
                            }
                        });
                    }
                });
            object.questions = arrayQuesCopy;
            dispatch(updateNCObject(object));
        }
        /*        if(arrayQuesCopy[object.parentId]?.options[object.id])
                                                arrayQuesCopy[object.parentId].options[object.id].checked = true
                                    
                                            setQuestionArray(arrayQuesCopy)*/
    };

    const validateObject = () => {
        let errorFlag = false;
        setValidationError({});
        if (object?.source) {
            if (
                (object?.source?.value === 1 || object?.source?.value === 10) &&
                !object.audit
            ) {
                setValidationErrorObj("audit", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (object?.source?.value === 11 && !object.gmp_text) {
                setValidationErrorObj("gmp_text", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (object?.source?.value === 12 && !object.audit_text) {
                setValidationErrorObj("audit_text", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (object?.source?.value === 2 && !object?.complaint_type) {
                setValidationErrorObj("complaint_type", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (
                object?.source?.value === 2 &&
                object?.complaint_type?.value === 3 &&
                !object?.foreign_body_type
            ) {
                setValidationErrorObj("foreign_body_type", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (
                object?.source?.value === 2 &&
                object?.complaint_type?.value === 3 &&
                object?.foreign_body_type?.value === 12 &&
                !object.custom_foreign_body
            ) {
                setValidationErrorObj("custom_foreign_body", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (object?.source?.value === 4 && !object?.nc_product_type) {
                setValidationErrorObj("nc_product_type", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (
                object?.source?.value === 2 &&
                object?.complaint_type?.value === 1 &&
                !object?.legal_type
            ) {
                setValidationErrorObj("legal_type", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (
                object?.source?.value === 2 &&
                object?.complaint_type?.value === 2 &&
                !object?.quality_type
            ) {
                setValidationErrorObj("quality_type", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (
                object?.source?.value === 2 &&
                object?.complaint_type?.value === 4 &&
                !object?.micro_type
            ) {
                setValidationErrorObj("micro_type", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }

            if (
                object?.source?.value === 2 &&
                object?.complaint_type?.value === 1 &&
                object?.legal_type?.value === 9 &&
                !object.custom_legal
            ) {
                setValidationErrorObj("custom_legal", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }

            if (
                object?.source?.value === 2 &&
                object?.complaint_type?.value === 2 &&
                object?.quality_type?.value === 18 &&
                !object.custom_quality
            ) {
                setValidationErrorObj("custom_quality", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }

            if (
                object?.source?.value === 2 &&
                object?.complaint_type?.value === 4 &&
                object?.micro_type?.value === 3 &&
                !object.custom_micro
            ) {
                setValidationErrorObj("custom_micro", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }

            if (
                object?.source?.value === 7 &&
                !object?.product_test_and_inspect_failure_type
            ) {
                setValidationErrorObj("product_test_and_inspect_failure_type", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
        } else {
            setValidationErrorObj("source", true);
            errorFlag = true;
            btnRef.current.disabled = false;
        }
        if (!object?.raised_by) {
            setValidationErrorObj("raised_by", true);
            errorFlag = true;
            btnRef.current.disabled = false;
        }
        if (!object?.date_raised) {
            setValidationErrorObj("date_raised", true);
            errorFlag = true;
            btnRef.current.disabled = false;
        }
        if (!object?.topic) {
            setValidationErrorObj("topic", true);
            errorFlag = true;
            btnRef.current.disabled = false;
        }
        if (!object?.grade) {
            setValidationErrorObj("firQ", true);
            setValidationErrorObj("secQ", true);
            errorFlag = true;
            btnRef.current.disabled = false;
        }
        return errorFlag;
    };

    const setValidationErrorObj = (key, flag) => {
        setValidationError((old) => {
            return {
                ...old,
                [key]: flag,
            };
        });
    };

    const checkCurrentUrl = () => {
        if (currentUrl?.includes("program-info")) {
            dispatch(toggleSuccessModal(false));
            dispatch(changeTabState(3));
        } else {
            dispatch(toggleSuccessModal(false));
            window.location.href = "/";
        }
    };

    const openLinkInNewTab = (link) => {
        window.open(link);
    };

    useEffect(() => {
        if (error && btnRef?.current) {
            btnRef.current.disabled = false;
        }
    }, [error]);

    return (
        <>
            <section className="home-nc create_nc">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6 pr-lg-5 mt-4">
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Source</label>
                                <div className="col-sm-6">
                                    <Select
                                        options={sourceOptions}
                                        value={object?.source}
                                        onChange={(data) => {
                                            handledFieldChanges("source", data);
                                        }}
                                        isDisabled={!utils.checkSubStatus()}
                                    />
                                    {validationError.source && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            {(object?.source?.value === 1 ||
                                object?.source?.value === 10) && (
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Audit</label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="date-selection"
                                                value={object.audit}
                                                onChange={(e) => {
                                                    handledFieldChanges("audit", e.target.value);
                                                }}
                                                disabled={!utils.checkSubStatus()}
                                            />
                                            {validationError.audit && (
                                                <small className="text-danger">
                                                    please select required field.
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                )}
                            {object?.source?.value === 11 && (
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                        GMP inspection
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="date-selection"
                                            value={object.gmp_text}
                                            onChange={(e) => {
                                                handledFieldChanges("gmp_text", e.target.value);
                                            }}
                                            disabled={!utils.checkSubStatus()}
                                        />
                                        {validationError.gmp_text && (
                                            <small className="text-danger">
                                                please select required field.
                                            </small>
                                        )}
                                    </div>
                                </div>
                            )}
                            {object?.source?.value === 12 && (
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                        System audit
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="date-selection"
                                            value={object.audit_text}
                                            onChange={(e) => {
                                                handledFieldChanges("audit_text", e.target.value);
                                            }}
                                            disabled={!utils.checkSubStatus()}
                                        />
                                        {validationError.audit_text && (
                                            <small className="text-danger">
                                                please select required field.
                                            </small>
                                        )}
                                    </div>
                                </div>
                            )}
                            {object?.source?.value === 2 && (
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                        Complaint type
                                    </label>
                                    <div className="col-sm-6">
                                        <Select
                                            options={complaintTypeOptions}
                                            value={object?.complaint_type}
                                            onChange={(data) => {
                                                handledFieldChanges("complaint_type", data);
                                            }}
                                            isDisabled={!utils.checkSubStatus()}
                                        />
                                        {validationError.complaint_type && (
                                            <small className="text-danger">
                                                please select required field.
                                            </small>
                                        )}
                                    </div>
                                </div>
                            )}
                            {object?.source?.value === 2 &&
                                object?.complaint_type?.value === 1 && (
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">
                                            Legal type
                                        </label>
                                        <div className="col-sm-6">
                                            <Select
                                                options={legalTypeOptions}
                                                value={object?.legal_type}
                                                onChange={(data) => {
                                                    handledFieldChanges("legal_type", data);
                                                }}
                                                isDisabled={!utils.checkSubStatus()}
                                            />
                                            {validationError.legal_type && (
                                                <small className="text-danger">
                                                    please select required field.
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                )}
                            {object?.source?.value === 2 &&
                                object?.complaint_type?.value === 1 &&
                                object?.legal_type?.value === 9 && (
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">
                                            Please provide the type of legal
                                        </label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="date-selection"
                                                value={object.custom_legal}
                                                onChange={(e) => {
                                                    handledFieldChanges("custom_legal", e.target.value);
                                                }}
                                                disabled={!utils.checkSubStatus()}
                                            />
                                            {validationError.custom_legal && (
                                                <small className="text-danger">
                                                    please select required field.
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                )}
                            {object?.source?.value === 2 &&
                                object?.complaint_type?.value === 2 && (
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">
                                            Quality type
                                        </label>
                                        <div className="col-sm-6">
                                            <Select
                                                options={qualityTypeOptions}
                                                value={object?.quality_type}
                                                onChange={(data) => {
                                                    handledFieldChanges("quality_type", data);
                                                }}
                                                isDisabled={!utils.checkSubStatus()}
                                            />
                                            {validationError.quality_type && (
                                                <small className="text-danger">
                                                    please select required field.
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                )}
                            {object?.source?.value === 2 &&
                                object?.complaint_type?.value === 2 &&
                                object?.quality_type?.value === 18 && (
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">
                                            Please provide the type of quality
                                        </label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="date-selection"
                                                value={object.custom_quality}
                                                onChange={(e) => {
                                                    handledFieldChanges("custom_quality", e.target.value);
                                                }}
                                                disabled={!utils.checkSubStatus()}
                                            />
                                            {validationError.custom_quality && (
                                                <small className="text-danger">
                                                    please select required field.
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                )}
                            {object?.source?.value === 2 &&
                                object?.complaint_type?.value === 3 && (
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">
                                            Foreign body type
                                        </label>
                                        <div className="col-sm-6">
                                            <Select
                                                options={foreignBodyTypeOptions}
                                                value={object?.foreign_body_type}
                                                onChange={(data) => {
                                                    handledFieldChanges("foreign_body_type", data);
                                                }}
                                                isDisabled={!utils.checkSubStatus()}
                                            />
                                            {validationError.foreign_body_type && (
                                                <small className="text-danger">
                                                    please select required field.
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                )}
                            {object?.source?.value === 2 &&
                                object?.complaint_type?.value === 3 &&
                                object?.foreign_body_type?.value === 16 && (
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">
                                            Please provide the type of foreign body
                                        </label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="date-selection"
                                                value={object.custom_foreign_body}
                                                onChange={(e) => {
                                                    handledFieldChanges(
                                                        "custom_foreign_body",
                                                        e.target.value
                                                    );
                                                }}
                                                disabled={!utils.checkSubStatus()}
                                            />
                                            {validationError.custom_foreign_body && (
                                                <small className="text-danger">
                                                    please select required field.
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                )}
                            {object?.source?.value === 2 &&
                                object?.complaint_type?.value === 4 && (
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">
                                            Microbial type
                                        </label>
                                        <div className="col-sm-6">
                                            <Select
                                                options={microbialTypeOptions}
                                                value={object?.micro_type}
                                                onChange={(data) => {
                                                    handledFieldChanges("micro_type", data);
                                                }}
                                                isDisabled={!utils.checkSubStatus()}
                                            />
                                            {validationError.micro_type && (
                                                <small className="text-danger">
                                                    please select required field.
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                )}
                            {object?.source?.value === 2 &&
                                object?.complaint_type?.value === 4 &&
                                object?.micro_type?.value === 3 && (
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">
                                            Please provide the type of microbial
                                        </label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="date-selection"
                                                value={object.custom_micro}
                                                onChange={(e) => {
                                                    handledFieldChanges("custom_micro", e.target.value);
                                                }}
                                                disabled={!utils.checkSubStatus()}
                                            />
                                            {validationError.custom_micro && (
                                                <small className="text-danger">
                                                    please select required field.
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                )}
                            {object?.source?.value === 4 && (
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                        NC Product type
                                    </label>
                                    <div className="col-sm-6">
                                        <Select
                                            options={ncTypeOptions}
                                            value={object?.nc_product_type}
                                            onChange={(data) => {
                                                handledFieldChanges("nc_product_type", data);
                                            }}
                                            isDisabled={!utils.checkSubStatus()}
                                        />
                                        {validationError.nc_product_type && (
                                            <small className="text-danger">
                                                please select required field.
                                            </small>
                                        )}
                                    </div>
                                </div>
                            )}
                            {object?.source?.value === 7 && (
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                        Product testing and inspection failure type
                                    </label>
                                    <div className="col-sm-6">
                                        <Select
                                            options={productTestAndInspectionFailureTypeOptions}
                                            value={object?.product_test_and_inspect_failure_type}
                                            onChange={(data) => {
                                                handledFieldChanges(
                                                    "product_test_and_inspect_failure_type",
                                                    data
                                                );
                                            }}
                                            isDisabled={!utils.checkSubStatus()}
                                        />
                                        {validationError.product_test_and_inspect_failure_type && (
                                            <small className="text-danger">
                                                please select required field.
                                            </small>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="form-group row light-bg">
                                <label className="col-sm-3 col-form-label">Raised by</label>
                                <div className="col-sm-6">
                                    <Select
                                        options={usersByRole?.usersCanCreateNc}
                                        value={object?.raised_by}
                                        onChange={(data) => {
                                            handledFieldChanges("raised_by", data);
                                        }}
                                        isDisabled={!utils.checkSubStatus()}
                                    />
                                    {validationError.raised_by && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Date raised</label>
                                <div className="col-sm-6">
                                    <input
                                        type="date"
                                        className="date-selection"
                                        max={moment().format(APP_CONSTANTS.DATE_FORMAT_1)}
                                        value={moment(object?.date_raised).format(
                                            APP_CONSTANTS.DATE_FORMAT_1
                                        )}
                                        onChange={(e) => {
                                            handledFieldChanges("date_raised", e.target.value);
                                        }}
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    {validationError.date_raised && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row light-bg">
                                <label className="col-sm-3 col-form-label">Topic</label>
                                <div className="col-sm-6">
                                    <Select
                                        options={topicOptions}
                                        value={object?.topic}
                                        onChange={(data) => {
                                            handledFieldChanges("topic", data, true);
                                        }}
                                        isDisabled={!utils.checkSubStatus()}
                                    />
                                    {validationError.topic && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mt-4">
                            <div className="form-group">
                                <label>Non-conformance notes</label>
                                <textarea
                                    className="form-control"
                                    id=""
                                    rows="5"
                                    value={object?.non_conformance_notes}
                                    onChange={(e) => {
                                        handledFieldChanges(
                                            "non_conformance_notes",
                                            e.target.value
                                        );
                                    }}
                                    disabled={!utils.checkSubStatus()}
                                />
                            </div>
                            <div className="form-group row light-bg add-evidence mb-0">
                                <label className="col-sm-9">Add evidence</label>
                                <div className="col-sm-3">
                                    <button
                                        type="button"
                                        className="btn-browse color-03 fs-12 fw-300 float-lg-right"
                                        data-toggle="modal"
                                        data-target="#browseModal"
                                        disabled={!utils.checkSubStatus()}
                                    >
                                        <i className="far fa-folder-open" /> Browse
                                    </button>
                                </div>
                            </div>
                            <div className="row my-4 evidence-scroll">
                                {object?.evidence?.map((obj, index) => (
                                    <div className="col-sm-6 pl-0" key={index}>
                                        <i
                                            className="far fa-minus-circle"
                                            onClick={() => removeEvidence(obj, index)}
                                        />
                                        {obj.extension === "images" && obj?.path && (
                                            <img loading="lazy" src={`${obj?.path}`} alt="Evidence" />
                                        )}
                                        {obj.extension === "videos" && obj?.path && (
                                            // <video
                                            //     className="cursor"
                                            //     onClick={() => {
                                            //         openLinkInNewTab(obj?.path);
                                            //     }}
                                            //     width="49"
                                            //     src={`${obj?.path}#t=0.1`}
                                            //     alt="Evidence"
                                            // />
                                            <img
                                                className="cursor"
                                                loading="lazy"
                                                onClick={() => {
                                                    openLinkInNewTab(obj?.path);
                                                }}
                                                src={videoIcon}
                                                alt="Evidence"
                                            />
                                        )}
                                        {obj.extension === "files" && obj?.path && (
                                            <img
                                                loading="lazy"
                                                className="cursor"
                                                onClick={() => {
                                                    openLinkInNewTab(obj?.path);
                                                }}
                                                src={getExtension(obj?.path)}
                                                alt="Evidence"
                                            />
                                        )}
                                        <input
                                            className="thumb-text fs-12 fw-300 color-04 ml-3"
                                            value={obj?.custom_name}
                                            placeholder="optional title"
                                            onChange={(e) =>
                                                handledFieldChanges(
                                                    "custom_title",
                                                    e.target.value,
                                                    true,
                                                    index
                                                )
                                            }
                                            disabled={!utils.checkSubStatus()}
                                        />
                                    </div>
                                ))}
                            </div>
                            {/*<div  dangerouslySetInnerHTML={{__html:htmlToRender}}>
                            </div>*/}
                            {object?.questions?.map((obj, index) => (
                                <div key={index}>
                                    {<h4 className="fs-14 fw-600 color-04 mb-3">{obj.Q}</h4>}
                                    {obj?.options?.map((op, index) => (
                                        <div className="custom-control custom-radio" key={index}>
                                            <input
                                                type="radio"
                                                id={op.Q}
                                                name="customRadio0"
                                                className="custom-control-input"
                                                defaultChecked={op?.checked}
                                                disabled={!utils.checkSubStatus()}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={op.Q}
                                                onClick={() => handleQuestionArrayChanges("firQ", op)}
                                            >
                                                {op.Q}
                                            </label>
                                        </div>
                                    ))}

                                    {obj?.options?.map((op, index) => (
                                        <div key={index}>
                                            {op?.checked &&
                                                op?.child?.map((ch, index) => (
                                                    <div key={index}>
                                                        {
                                                            <h4 className="fs-14 fw-600 color-04 mb-3 mt-3">
                                                                {ch?.Q}
                                                            </h4>
                                                        }
                                                        {ch?.options?.map((op2, index) => (
                                                            <div
                                                                className="custom-control custom-radio"
                                                                key={index}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    id={op2.Q}
                                                                    name="customRadio1"
                                                                    className="custom-control-input"
                                                                    defaultChecked={op2?.checked}
                                                                    disabled={!utils.checkSubStatus()}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={op2.Q}
                                                                    onClick={() =>
                                                                        handleQuestionArrayChanges("secQ", op2)
                                                                    }
                                                                >
                                                                    {op2.Q}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                                </div>
                            ))}
                            {validationError.firQ && (
                                <small className="text-danger">
                                    please select required field.
                                </small>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {utils.checkSubscription() && (
                <div>
                    <div className="float-right my-2">
                        <button
                            className="updated-save-btn mr-1"
                            onClick={handleSave}
                            ref={btnRef}
                            disabled={
                                btnRef?.current?.disabled ? btnRef?.current?.disabled : disabled
                            }
                        >
                            <i className="far fa-save" /> Save
                        </button>
                        <button
                            className="btn-send"
                            onClick={handleSend}
                            ref={btnRef}
                            disabled={
                                btnRef?.current?.disabled ? btnRef?.current?.disabled : disabled
                            }
                        >
                            <i className="far fa-share" /> Send
                        </button>
                    </div>
                </div>
            )}
            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            checkCurrentUrl();
                        }}
                    ></div>
                    <SaveModal
                        id={"nc"}
                        h2={APP_CONSTANTS.NC_CREATE_MESSAGE.h2}
                        h3={APP_CONSTANTS.NC_CREATE_MESSAGE.h3}
                        p={APP_CONSTANTS.NC_CREATE_MESSAGE.p}
                    />
                </>
            )}
            {error?.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal(false));
                        }}
                    ></div>
                    <ErrorModal error={error} />
                </>
            )}
            {error?.sentError && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSendModal({ error: { sentError: false } }));
                            dispatch(toggleErrorModal(false));
                        }}
                    ></div>
                    <NotificationErrorModal
                        error={error}
                        h2={"Error while sending email"}
                    />
                </>
            )}
            {send && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSendModal({ send: false }));
                            window.location.href = "/";
                        }}
                    ></div>
                    <NotificationSendModal
                        h2={APP_CONSTANTS.NC_SEND_MESSAGE.h2}
                        h3={APP_CONSTANTS.NC_SEND_MESSAGE.h3}
                        p={APP_CONSTANTS.NC_SEND_MESSAGE.p}
                    />
                </>
            )}
            <BrowseModal dispatch={dispatch} action={addAttachment} />
        </>
    );
};

export default CreateNc;
