import { SCHEDULED_REPORTS_CONSTANTS } from "../constants";

const reducer = (state = { list: [], editReports: {} }, { type, payload }) => {
    switch (type) {
        case SCHEDULED_REPORTS_CONSTANTS.GET_SCHEDULED_REPORTS_SUCCESS:
            return { ...state, list: payload };
        case SCHEDULED_REPORTS_CONSTANTS.EDIT_SCHEDULED_REPORTS_OBJECT:
            return { ...state, editReports: payload };
        default:
            return state;
    }
};

export default reducer;
