import React, { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment";
import NcView from "./NcView";
import {
    createRcaAPi,
    getRcaByIdAPi,
    updateRca,
    updateRcaAPi,
    toggleSuccessModal,
} from "../../../store/actions/rca";
import { useDispatch, useSelector } from "react-redux";
import { APP_CONSTANTS } from "../../../constants";
import RcaBrowseModal from "../../Modals/RcaBrowseModal";
import { addAttachment } from "../../../store/actions/rca";
import SaveModal from "../../Modals/SaveModal";
import NotificationSendModal from "../../Modals/NotificationSendModal";
import utils from "../../../utils";
import { getExtension } from "../../../utils/icons";
import { deleteMediaFromS3Bucket } from "../../../utils/s3";
import { generateSequence } from "../../../utils/referenceId";
import videoIcon from "../../../Assets/images/file-video-regular.svg";

const Rca = () => {
    const dispatch = useDispatch();

    const { object, success, send } = useSelector((state) => state?.rca);
    const { _id, org } = useSelector((state) => state?.nc?.object);

    const [saveClicked, setSaveClicked] = useState(false);
    const [completeClicked, setCompleteClicked] = useState(false);

    const handleFieldChanges = (field, value, custom, index) => {
        let ncObjCopy = { ...object };
        if (!custom) ncObjCopy[field] = value;
        dispatch(updateRca(ncObjCopy));
    };

    const handleEvidence = (field, value, custom, index) => {
        let objectCopy = { ...object };
        objectCopy.evidence[index].custom_name = value;
        dispatch(updateRca(objectCopy));
    };

    const handleSave = () => {
        if (!saveClicked) {
            setSaveClicked(true);
            let formData = setFormData({ ...object });
            if (object?._id) {
                dispatch(updateRcaAPi(formData));
            } else {
                dispatch(createRcaAPi(formData));
            }
        }
        setSaveClicked(false);
    };

    const handleSend = () => {
        if (!completeClicked) {
            setCompleteClicked(true);
            let formData = setFormData({ ...object, status: "Completed" });
            dispatch(updateRcaAPi(formData));
        }
        setCompleteClicked(false);
        // if (!object?._id) {
        //     dispatch(createAndSendRcaAPi(formData));
        // } else {
        //     dispatch(sendRcaAPi({ _id: object?._id, nc: _id }));
        // }
    };

    // const extractCustomTitles = (data) => {
    //     data.custom_names = [];
    //     data?.evidence.map((obj, index) => {
    //         if (Boolean(obj.custom_name) && !obj.destination)
    //             data.custom_names.push({ custom_name: obj.custom_name, index });
    //     });
    // };

    const removeEvidence = async (obj, index) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            if (
                object?.status !== "Completed" &&
                object?.status !== "Completed late"
            ) {
                let rcaCopy = { ...object };
                if (obj.key) await deleteMediaFromS3Bucket(obj.key);
                rcaCopy?.removeFiles
                    ? rcaCopy.removeFiles.push(rcaCopy?.evidence[index])
                    : (rcaCopy.removeFiles = [rcaCopy?.evidence[index]]);
                rcaCopy?.evidence?.splice(index, 1);
                dispatch(updateRca(rcaCopy));
            }
        }
    };

    const setFormData = (data) => {
        let formData = new FormData();
        /*if(data?.evidence?.length){
                                                                    extractCustomTitles(data)
                                                                    data?.evidence?.map((file)=>{
                                                                        formData.append("media", file)
                                                                    })
                                                                }*/
        data.nc = _id;
        formData.append("data", JSON.stringify(data));
        return formData;
    };

    const openLinkInNewTab = (link) => {
        window.open(link);
    };

    useEffect(() => {
        dispatch(getRcaByIdAPi(_id, org));
    }, [_id]);

    return (
        <section className="reference">
            <div className="container-fluid">
                <div className="row">
                    <NcView />
                    {/* Column End */}
                    <div className="col-12 col-lg-6 mt-3">
                        <h2 className="fs-16 fw-600 color-04">
                            Root cause analysis notes{" "}
                            <span className="float-right">
                                Reference:RCA-{generateSequence(object?.id || 0)}
                            </span>
                        </h2>
                        <textarea
                            className="form-control"
                            id=""
                            rows="5"
                            value={object?.root_cause_analysis_notes}
                            onChange={(e) =>
                                handleFieldChanges("root_cause_analysis_notes", e.target.value)
                            }
                            disabled={
                                !utils.checkSubStatus()
                                    ? !utils.checkSubStatus()
                                    : object?.status === "Completed" ||
                                    object?.status === "Completed late"
                            }
                        />
                        <div className="mt-4">
                            <div>
                                <div className="form-group row light--bg py-2">
                                    <label className="col-sm-2 col-form-label pr-0">
                                        RCA due date
                                    </label>
                                    <div className="col-sm-5">
                                        <input
                                            type="date"
                                            className="date-selection"
                                            min={moment().format("YYYY-MM-DD")}
                                            value={
                                                object?.due_date
                                                    ? moment(object?.due_date).format("YYYY-MM-DD")
                                                    : ""
                                            }
                                            onChange={(e) =>
                                                handleFieldChanges("due_date", e.target.value)
                                            }
                                            disabled={
                                                !utils.checkSubStatus()
                                                    ? !utils.checkSubStatus()
                                                    : object?.status === "Completed" ||
                                                    object?.status === "Completed late"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Status</label>
                                <div className="col-sm-5">
                                    <Select
                                        options={APP_CONSTANTS.CA_STATUS}
                                        isDisabled={
                                            !utils.checkSubStatus() ? !utils.checkSubStatus() : true
                                        }
                                        value={
                                            object?.status
                                                ? {
                                                    label: object?.status,
                                                }
                                                : ""
                                        }
                                        onChange={(data) =>
                                            handleFieldChanges("status", data.label)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row light-bg add-evidence mb-0">
                            <label className="col-sm-9">Add RCA evidence</label>
                            <div className="col-sm-3">
                                <button
                                    type="button"
                                    className="btn-browse color-03 fs-12 fw-300 float-lg-right"
                                    data-toggle="modal"
                                    data-target="#browseModal"
                                    disabled={
                                        !utils.checkSubStatus()
                                            ? !utils.checkSubStatus()
                                            : object?.status === "Completed" ||
                                            object?.status === "Completed late"
                                    }
                                >
                                    <i className="far fa-folder-open" /> Browse
                                </button>
                            </div>
                        </div>
                        <div className="row my-4 evidence-scroll">
                            {object?.evidence?.map((obj, index) => (
                                <div className="col-sm-6 pl-0" key={index}>
                                    <i
                                        className="far fa-minus-circle"
                                        onClick={() => removeEvidence(obj, index)}
                                    />
                                    {obj.extension === "images" && obj?.path && (
                                        <img loading="lazy" src={`${obj?.path}`} alt="Evidence" />
                                    )}
                                    {obj.extension === "videos" && obj?.path && (
                                        // <video
                                        //     className="cursor"
                                        //     onClick={() => {
                                        //         openLinkInNewTab(obj?.path);
                                        //     }}
                                        //     width="49"
                                        //     src={`${obj?.path}#t=0.1`}
                                        //     alt="Evidence"
                                        // />
                                        <img
                                            className="cursor"
                                            loading="lazy"
                                            onClick={() => {
                                                openLinkInNewTab(obj?.path);
                                            }}
                                            src={videoIcon}
                                            alt="Evidence"
                                        />
                                    )}
                                    {obj.extension === "files" && obj?.path && (
                                        <img
                                            loading="lazy"
                                            className="cursor"
                                            onClick={() => {
                                                openLinkInNewTab(obj?.path);
                                            }}
                                            src={getExtension(obj?.path)}
                                            alt="Evidence"
                                        />
                                    )}
                                    <input
                                        className="thumb-text fs-12 fw-300 color-04 ml-3"
                                        value={obj?.custom_name}
                                        placeholder="optional title"
                                        onChange={(e) =>
                                            handleEvidence(
                                                "custom_title",
                                                e.target.value,
                                                true,
                                                index
                                            )
                                        }
                                        disabled={
                                            !utils.checkSubStatus()
                                                ? !utils.checkSubStatus()
                                                : object?.status === "Completed" ||
                                                object?.status === "Completed late"
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                        {/* <dl className="row mt-4">
                            <dt className="col-sm-9">Complete action?</dt>
                            <dd className="col-sm-3">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`RcaComplete-No`}
                                                checked={object?.status === "In progress"}
                                                onClick={(e) =>
                                                    handleFieldChanges("status", "In progress", false)
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04"
                                                htmlFor={`RcaComplete-No`}
                                            >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`RcaComplete-Yes`}
                                                checked={object?.status === "Completed"}
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04"
                                                htmlFor={`RcaComplete-Yes`}
                                                onClick={(e) =>
                                                    handleFieldChanges("status", "Completed", false)
                                                }
                                            >
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl> */}
                    </div>
                    {/* Column End */}
                </div>
            </div>
            {utils.checkSubscription() &&
                object?.status !== "Completed" &&
                object?.status !== "Completed late" && (
                    <div className="float-right mt-4">
                        <button
                            className="btn-save mr-2"
                            onClick={handleSave}
                            disabled={saveClicked}
                        >
                            <i className="far fa-save" /> Save
                        </button>
                        <button
                            className="btn-complete-rca px-3"
                            onClick={handleSend}
                            disabled={completeClicked}
                        >
                            <i className="far fa-share" /> Complete
                        </button>
                    </div>
                )}
            <RcaBrowseModal dispatch={dispatch} action={addAttachment} />
            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal({ success: false }));
                            window.location.href = "/";
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.RCA_CREATE_MESSAGE.h2}
                        h3={APP_CONSTANTS.RCA_CREATE_MESSAGE.h3}
                    />
                </>
            )}
            {send && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal({ send: false }));
                            window.location.href = "/";
                        }}
                    ></div>

                    <NotificationSendModal
                        h2={APP_CONSTANTS.RCA_SEND_MESSAGE.h2}
                        h3={APP_CONSTANTS.RCA_SEND_MESSAGE.h3}
                        p={APP_CONSTANTS.RCA_SEND_MESSAGE.p}
                    />
                </>
            )}
        </section>
    );
};

export default Rca;
