import React from 'react';
import ThumbsUp from "../../../Assets/images/thumbs-up.png";

const ExportModal = () => {
    return (
        <div className="export-modal">
            <div className="modal fade" id="exportModal" tabIndex="-1" aria-labelledby="exportModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="img-box">
                                    <img src={ThumbsUp} alt="Export" width={"200px"}/>
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center text-lg-center mb-4">Your download's started!</h2>
                                    <h3 className="color-05 fs-18 fw-600 mb-4">Your downloads automatically started simply open the file and away you go!</h3>
                                    <p className="color-05 fs-12 fw-300">
                                        This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExportModal;