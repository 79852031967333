import { ACTION_CONSTANTS } from "../constants";

const reducer = (
    state = {
        ca_actions: [],
        pa_actions: [],
        selectedAction: {},
        error: {},
        success: false,
        send: false,
        nc_ca_actions: [ACTION_CONSTANTS.GET_DEFAULT_CA_ACTION_OBJ()],
        nc_pa_actions: [ACTION_CONSTANTS.GET_DEFAULT_PA_ACTION_OBJ()],
        filteredListActions: [],
    },
    { type, payload }
) => {
    switch (type) {
        case ACTION_CONSTANTS.CREATE_CA_ACTION_SUCCESS:
            return { ...state, ca_actions: payload, success: true };
        case ACTION_CONSTANTS.CREATE_PA_ACTION_SUCCESS:
            return { ...state, pa_actions: payload, success: true };
        case ACTION_CONSTANTS.UPDATE_CA_ACTION:
            return { ...state, ca_actions: payload };
        case ACTION_CONSTANTS.GET_ACTIONS_LIST_SUCCESS:
            return {
                ...state,
                list: payload,
            };
        case ACTION_CONSTANTS.UPDATE_PA_ACTION:
            return { ...state, pa_actions: payload };
        case ACTION_CONSTANTS.SELECTED_ACTION:
            return { ...state, selectedAction: payload };
        case ACTION_CONSTANTS.ADD_ACTIONER_EVIDENCE:
            if (state?.selectedAction?.type === "CA") {
                var object = state?.ca_actions?.find(
                    (obj) => obj?._id === state?.selectedAction?._id
                );
                if (object?.actioner_evidence?.length && payload?.attachment?.length) {
                    object.actioner_evidence = [
                        ...object?.actioner_evidence,
                        ...payload?.attachment,
                    ];
                    object.change = true;
                } else if (payload?.attachment?.length) {
                    object.actioner_evidence = payload?.attachment;
                    object.change = true;
                }
            } else if (state?.selectedAction?.type === "PA") {
                var object = state?.pa_actions?.find(
                    (obj) => obj?._id === state?.selectedAction?._id
                );
                if (object?.actioner_evidence?.length && payload?.attachment?.length) {
                    object.actioner_evidence = [
                        ...object?.actioner_evidence,
                        ...payload?.attachment,
                    ];
                    object.change = true;
                } else if (payload?.attachment?.length) {
                    object.actioner_evidence = payload?.attachment;
                    object.change = true;
                }
            }
            if (object?.custom_names?.length && payload?.custom_names?.length) {
                object.custom_names = [object?.custom_names, ...payload?.custom_names];
                object.change = true;
            } else if (payload?.custom_names?.length) {
                object.custom_names = payload?.custom_names;
                object.change = true;
            }
            return state;
        case ACTION_CONSTANTS.ADD_VERIFIER_EVIDENCE:
            if (state?.selectedAction?.type === "CA") {
                var object = state?.ca_actions?.find(
                    (obj) => obj?._id === state?.selectedAction?._id
                );
                if (object?.verifier_evidence?.length && payload?.attachment?.length) {
                    object.verifier_evidence = [
                        ...object?.verifier_evidence,
                        ...payload?.attachment,
                    ];
                    object.change = true;
                } else if (payload?.attachment?.length) {
                    object.verifier_evidence = payload?.attachment;
                    object.change = true;
                }
            } else if (state?.selectedAction?.type === "PA") {
                var object = state?.pa_actions?.find(
                    (obj) => obj?._id === state?.selectedAction?._id
                );
                if (object?.verifier_evidence?.length && payload?.attachment?.length) {
                    object.verifier_evidence = [
                        ...object?.verifier_evidence,
                        ...payload?.attachment,
                    ];
                    object.change = true;
                } else if (payload?.attachment?.length) {
                    object.verifier_evidence = payload?.attachment;
                    object.change = true;
                }
            }
            if (object?.custom_names?.length && payload?.custom_names?.length) {
                object.custom_names = [object?.custom_names, ...payload?.custom_names];
                object.change = true;
            } else if (payload?.custom_names?.length) {
                object.custom_names = payload?.custom_names;
                object.change = true;
            }
            return state;
        case ACTION_CONSTANTS.TOGGLE_ACTION_ERROR_MODAL:
            return { ...state, error: payload };
        case ACTION_CONSTANTS.TOGGLE_ACTION_SUCCESS_MODAL:
            return { ...state, success: payload };
        case ACTION_CONSTANTS.TOGGLE_ACTION_EMAIL_SUCCESS_MODAL:
            return { ...state, ...payload };
        case ACTION_CONSTANTS.UPDATE_NC_CA_ACTION:
            return { ...state, nc_ca_actions: payload };
        case ACTION_CONSTANTS.UPDATE_NC_PA_ACTION:
            return { ...state, nc_pa_actions: payload };
        case ACTION_CONSTANTS.FILTERED_ACTION_LIST:
            return {
                ...state,
                filteredListActions: payload,
            };
        default:
            return state;
    }
};

export default reducer;
