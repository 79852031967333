import React from "react";
import DataTable from "react-data-table-component";
import { generateSequence } from "../../../../utils/referenceId";
import moment from "moment";
import { APP_CONSTANTS } from "../../../../constants";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const SaDataTable = (props) => {
    const { data } = props;

    const org = JSON.parse(localStorage.getItem("org"));

    const redirectToQuestions = (row) => {
        window.open(`/private/system-audit/${org?._id}/${row?._id}`);
    };

    const columns = [
        {
            name: "Reference",
            selector: (row) => `${row?.type}-${generateSequence(row?.id)}`,
            sortable: true,
            width: "130px",
            cell: (row) => (
                <a
                    className="edit-link user-name-link cursor"
                    onClick={() => redirectToQuestions(row)}
                >
                    {`${row?.type}-${generateSequence(row?.id)}`}
                </a>
            ),
        },
        {
            name: "Topic",
            selector: (row) => row?.parentSA?.name?.replace(/[0-9]/g, ""),
            sortable: true,
            width: "250px",
            cell: (row) => (
                <div className="text-left">
                    {row?.parentSA?.name?.replace(/[0-9]/g, "")}
                </div>
            ),
        },
        {
            name: "Created date",
            selector: (row) => row?.date_raised,
            cell: (row) =>
                row?.date_raised &&
                moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT),
            sortable: true,
        },
        {
            name: "Auditor",
            selector: (row) => row?.lead_auditor?.label,
            sortable: true,
            cell: (row) => (
                <div className="text-left">{row?.lead_auditor?.label}</div>
            ),
        },
        {
            name: "Accountable Manager",
            selector: (row) => row?.accountable_manager?.label,
            sortable: true,
            cell: (row) => (
                <div className="text-left">{row?.accountable_manager?.label}</div>
            ),
        },
        {
            name: "Verifier",
            selector: (row) => row?.verifier?.label,
            sortable: true,
            cell: (row) => <div className="text-left">{row?.verifier?.label}</div>,
        },

        {
            name: "Frequency",
            selector: (row) =>
                parseInt(row?.parentSA?.frequency / 30) === 12
                    ? "Annual"
                    : `${parseInt(row?.parentSA?.frequency / 30)}-monthly`,
            sortable: true,
            cell: (row) =>
                parseInt(row?.parentSA?.frequency / 30) === 12
                    ? "Annual"
                    : `${parseInt(row?.parentSA?.frequency / 30)}-monthly`,
        },
        {
            name: "Status",
            selector: (row) => row?.status,
            cell: (row) => (
                <div
                    data-toggle="tooltip"
                    title={row?.status}
                    className="py-1 text-left"
                >
                    {row?.status}
                </div>
            ),
            sortable: true,
        },
    ];

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="mt-3">
            <DataTable
                columns={columns}
                data={data ? data : []}
                customStyles={customStyles}
                striped
                persistTableHead
                keyField={customUniqueID}
                pagination
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );
};

export default SaDataTable;
