import React, { useEffect, useState } from "react";
import Searchbar from "../../Home/TodoLIst/Searchbar";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getStandardsListApi } from "../../../store/actions/additionalStandards";
import StandardModal from "../../Modals/StandardModal";
import session from "../../../services/session";
import request from "../../../services/request";
import { STANDARDS } from "../../../Routes/Api";
import SaveModal from "../../Modals/SaveModal";
import { APP_CONSTANTS } from "../../../constants";
import ErrorModal from "../../Modals/ErrorModal";
import utils from "../../../utils";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "25px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const AdditionalStandards = () => {
    const dispatch = useDispatch();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [standardModal, setStandardModal] = useState(false);
    const [standardData, setStandardData] = useState({});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const { list } = useSelector((state) => state?.additionalStandards);
    const org = session.getOrgId();

    const toggleStandardModal = (flag) => {
        setStandardModal(flag);
    };

    const removeStandards = (data, mode) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            let obj = {
                orgId: org,
                value: data?.value,
                mode: mode,
            };
            setStandardData(obj);
            toggleStandardModal(true);
        }
    };

    const addStandards = (data, mode) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            let obj = {
                orgId: org,
                value: data?.value,
                mode: mode,
            };
            request.put(STANDARDS.ADD_REMOVE_STANDARDS, obj, true, false).then(
                (res) => {
                    if (res) {
                        setSuccess(true);
                        setTimeout(() => {
                            setSuccess(false);
                        }, APP_CONSTANTS.MODAL_SHOW_TIME);
                    }
                },
                (error) => {
                    setError(true);
                    setErrorMsg(error?.message);
                    setTimeout(() => {
                        setError(false);
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            );
        }
    };

    const columns = [
        {
            name: "Additional Standards",
            selector: (row) => row?.label,
            sortable: true,
            cell: (row) => row?.label,
        },
        {
            width: "100px",
            cell: (row) =>
                row?.hasOwnProperty("enable") ? (
                    <a
                        className="color-05 user-name-link cursor"
                        onClick={() => removeStandards(row, "remove")}
                    >
                        Remove
                    </a>
                ) : (
                    <a
                        className="color-05 user-name-link cursor"
                        onClick={() => addStandards(row, "add")}
                    >
                        Add
                    </a>
                ),
        },
    ];

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                list?.filter((item) => {
                    if (item?.label?.toLowerCase().includes(searchValue?.toLowerCase())) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div className="w-100">
                <div className="row">
                    <div className="col-md-8">
                        <h5>Your Additional Standards</h5>
                    </div>
                    <div className="col-md-4">
                        <Searchbar
                            onFilter={(e) => filterData(e.target.value)}
                            onClear={handleClear}
                            filterText={filterText}
                        />
                    </div>
                </div>
            </div>
        );
    }, [filterText, filteredList]);

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataStandards = filteredList?.map((item) => {
            if (row?.value !== item?.value) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataStandards);
    };

    useEffect(() => {
        setFilteredList([...list]);
    }, [list]);

    useEffect(() => {
        dispatch(getStandardsListApi());
    }, []);

    useEffect(() => {
        dispatch(getStandardsListApi());
    }, [standardModal, success, error]);

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="gmp_inspections_wrapper additional_standard">
            <DataTable
                columns={columns}
                data={filteredList}
                customStyles={customStyles}
                striped
                persistTableHead
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />

            {standardModal && (
                <StandardModal
                    toggleStandardModal={toggleStandardModal}
                    standardData={standardData}
                    id={"standardModal"}
                />
            )}

            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setSuccess(false);
                        }}
                    ></div>
                    <SaveModal
                        id="add_additional_standards"
                        h2={APP_CONSTANTS.ADD_ADDITIONAL_STANDARDS.h2}
                        h3={APP_CONSTANTS.ADD_ADDITIONAL_STANDARDS.h3}
                        p={APP_CONSTANTS.ADD_ADDITIONAL_STANDARDS.p}
                    />
                </>
            )}
            {error && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setError(false);
                        }}
                    ></div>
                    <ErrorModal errorMsg={errorMsg} type="add_standards" />
                </>
            )}
        </div>
    );
};

export default AdditionalStandards;
