import { PRO_CONSTANTS } from "../constants";

const reducer = (
    state = {
        object: PRO_CONSTANTS.GET_DEFAULT_PRO_OBJ(),
        comp_pro_obj: {},
        list: [],
        error: {},
        success: false,
        send: false,
        disabled: false,
    },
    { type, payload }
) => {
    switch (type) {
        case PRO_CONSTANTS.CREATE_PRO:
            return {
                ...state,
                disabled: true,
            };
        case PRO_CONSTANTS.CREATE_PRO_SUCCESS:
            return {
                ...state,
                object: payload,
                success: true,
                disabled: false,
            };
        case PRO_CONSTANTS.UPDATE_PRO_OBJECT:
            return {
                ...state,
                object: payload,
            };
        case PRO_CONSTANTS.ADD_ATTACHMENT:
            if (state.object?.attachments?.length && payload?.attachment?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        attachments: [...state.object?.attachments, ...payload?.attachment],
                    },
                };
            else if (payload?.attachment?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        attachments: payload?.attachment,
                    },
                };
            if (state.object?.custom_names?.length && payload?.custom_names?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        custom_names: [
                            ...state.object?.custom_names,
                            ...payload?.custom_names,
                        ],
                    },
                };
            else if (payload?.custom_names?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        custom_names: payload?.custom_names,
                    },
                };
            return state;
        case PRO_CONSTANTS.COMPLETE_PRO_SUCCESS:
            return {
                ...state,
                comp_pro_obj: payload,
                success: true,
            };
        case PRO_CONSTANTS.UPDATE_COMPLETE_PRO_OBJECT:
            return {
                ...state,
                comp_pro_obj: payload,
            };
        case PRO_CONSTANTS.ADD_COMPLETE_ATTACHMENT:
            if (
                state.comp_pro_obj?.complete_attachments?.length &&
                payload?.attachment?.length
            )
                state = {
                    ...state,
                    comp_pro_obj: {
                        ...state.comp_pro_obj,
                        complete_attachments: [
                            ...state.comp_pro_obj?.complete_attachments,
                            ...payload?.attachment,
                        ],
                    },
                };
            else if (payload?.attachment?.length)
                state = {
                    ...state,
                    comp_pro_obj: {
                        ...state.comp_pro_obj,
                        complete_attachments: payload?.attachment,
                    },
                };
            if (
                state.comp_pro_obj?.custom_names?.length &&
                payload?.custom_names?.length
            )
                state = {
                    ...state,
                    comp_pro_obj: {
                        ...state.comp_pro_obj,
                        custom_names: [
                            ...state.comp_pro_obj?.custom_names,
                            ...payload?.custom_names,
                        ],
                    },
                };
            else if (payload?.custom_names?.length)
                state = {
                    ...state,
                    comp_pro_obj: {
                        ...state.comp_pro_obj,
                        custom_names: payload?.custom_names,
                    },
                };
            return state;
        case PRO_CONSTANTS.TOGGLE_PRO_SUCCESS_MODAL:
            return {
                ...state,
                success: payload,
            };
        case PRO_CONSTANTS.PRO_RESPONSE_ERROR:
            return {
                ...state,
                disabled: false,
                error: { ...payload, show: true },
            };
        case PRO_CONSTANTS.TOGGLE_PRO_ERROR_MODAL:
            return {
                ...state,
                disabled: false,
                error: { show: payload },
            };
        case PRO_CONSTANTS.TOGGLE_PRO_SEND_MODAL:
            return {
                ...state,
                ...payload,
            };
        case PRO_CONSTANTS.CLEAR_PRO_OBJECT:
            return {
                ...state,
                object: payload,
            };
        default:
            return state;
    }
};

export default reducer;
