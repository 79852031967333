import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { APP_CONSTANTS } from "../../constants";
import utils from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
    filteredListForActions,
    getActionsByIdAPi,
} from "../../store/actions/action";
import { updateNCObject } from "../../store/actions/nc";
import { updateProObject } from "../../store/actions/pro";
import { changeTabState } from "../../store/actions/tabState";
import { useNavigate } from "react-router-dom";
import Searchbar from "../Home/TodoLIst/Searchbar";
import DateField from "../Date/DateField";
import { generateSequence } from "../../utils/referenceId";
import FilteredInputField from "./FilteredInputField";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const Actions = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [filterDate, setFilterDate] = useState({ from: "", to: "" });
    const [filterStatus, setFilterStatus] = useState("none");

    const { list } = useSelector((state) => state.action);

    const user = JSON.parse(localStorage.getItem("user"));

    const statuses = [
        {
            label: "Choose a Status",
            value: "none",
        },
        {
            label: "All",
            value: "all",
        },
        {
            label: "Draft",
            value: "Draft",
        },
        {
            label: "In progress",
            value: "In progress",
        },
        {
            label: "Overdue",
            value: "Overdue",
        },
        {
            label: "Completed",
            value: "Completed",
        },
        {
            label: "Verified",
            value: "Verified",
        },
        {
            label: "Completed late",
            value: "Completed late",
        },
        {
            label: "Completed late and verified",
            value: "Completed late and verified",
        },
        {
            label: "Completed and verified late",
            value: "Completed and verified late",
        },
    ];

    const redirectReference = (row) => {
        if (row?.type === "CA") {
            let state = { id: row?.nc, nc: row };
            if (row?.status === "Draft") {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/2`, {
                    state,
                });
            } else if (row?.actioner?.value === user?._id) {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/5`, {
                    state,
                });
            } else if (row?.verifier?.value === user?._id) {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/6`, {
                    state,
                });
            } else {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/1`, {
                    state,
                });
            }
        } else if (row?.type === "PA") {
            let state = { id: row?.nc, nc: row };
            if (row?.status === "Draft") {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/4`, {
                    state,
                });
            } else if (row?.actioner?.value === user?._id) {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/5`, {
                    state,
                });
            } else if (row?.verifier?.value === user?._id) {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/6`, {
                    state,
                });
            } else {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/1`, {
                    state,
                });
            }
        } else if (row?.type === "RCA") {
            let state = { id: row?.nc, nc: row };
            if (row?.nc?.status === "Closed" || row?.nc?.status === "Closed late") {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/1`, {
                    state,
                });
            } else if (
                !utils.checkPermissions(APP_CONSTANTS.PERMISSION_CONST.CREATE_RCA)
            ) {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/1`, {
                    state,
                });
            } else {
                navigate(`/private/reference/${row?.org}/${row?.nc?._id}/3`, {
                    state,
                });
            }
        } else if (row?.type === "PRO") {
            if (
                (user?.permissions?.general?.system_manager &&
                    row?.status === "Draft") ||
                (user?._id === row?.raised_by?.value && row?.status === "Draft")
            ) {
                editSelectedObject(row);
            } else {
                let state = { id: row?._id, pro: row };
                navigate(`/private/pro-reference/${row?.org}/${row?._id}`, { state });
            }
        }
    };

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                list?.filter((item) => {
                    if (
                        `${item?.type?.toLowerCase()}-${generateSequence(item?.id) || 0
                            }`.includes(searchValue?.toLowerCase()) ||
                        item?.nc?.source?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.source?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.nc?.accountable_manager?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.nc?.verifier?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item?.preventive_action_notes
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.corrective_action_notes
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.action_notes
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.root_cause_analysis_notes
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase())
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const dateFilter = (field, value) => {
        let to = null;
        let from = null;
        if (field === "from") {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        } else {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        }
    };

    const dateFilteredData = () => {
        let tempArr = list;

        if (filterDate.from && filterDate.from !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(
                        item?.date_raised ? item?.date_raised : item?.createdAt
                    ).format(APP_CONSTANTS.DATE_FORMAT_1) >=
                    moment(filterDate.from).format(APP_CONSTANTS.DATE_FORMAT_1)
                ) {
                    return item;
                }
            });
        }

        if (filterDate.to && filterDate.to !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(
                        item?.date_raised ? item?.date_raised : item?.createdAt
                    ).format(APP_CONSTANTS.DATE_FORMAT_1) <=
                    moment(filterDate.to).format(APP_CONSTANTS.DATE_FORMAT_1)
                ) {
                    return item;
                }
            });
        }

        if (filterStatus !== "none") {
            if (filterStatus === "all") {
                tempArr = tempArr;
            } else {
                tempArr = tempArr?.filter((item) => {
                    if (item?.status === filterStatus) {
                        return item;
                    }
                });
            }
        }

        setFilteredList(tempArr);
        dispatch(filteredListForActions(tempArr));
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };

        return (
            <div style={{ width: "100%" }} className="mt-2 mt-md-0 mb-2 mb-md-0">
                <DateField
                    filterData={(e) => dateFilter("from", e.target.value)}
                    raised={true}
                    dateValue={filterDate?.from}
                    label={"From:"}
                />
                <DateField
                    filterData={(e) => dateFilter("to", e.target.value)}
                    dateValue={filterDate?.to}
                    label={"To:"}
                />
                <FilteredInputField
                    list={statuses}
                    value={filterStatus}
                    action={setFilterStatus}
                    type="action"
                />

                <Searchbar
                    onFilter={(e) => filterData(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            </div>
        );
    }, [filterText, filteredList, filterDate]);

    useEffect(() => {
        if (!list?.length) dispatch(getActionsByIdAPi());
    }, []);

    useEffect(() => {
        dateFilteredData();
    }, [filterDate, filterStatus]);

    useEffect(() => {
        setFilteredList(filteredList?.length ? filteredList : list);
    }, [list]);

    useEffect(() => {
        dispatch(filteredListForActions(filteredList));
    }, [filteredList]);

    const editSelectedObject = (data) => {
        if (data.type === "NC") {
            dispatch(updateNCObject({ ...data }));
            dispatch(changeTabState(2));
        } else {
            dispatch(updateProObject({ ...data }));
            dispatch(changeTabState(6));
        }
    };

    // useEffect(() => {
    //     if (!list?.length) dispatch(getActionsByIdAPi());
    // }, []);

    // useEffect(() => {
    //     dispatch(getActionsByIdAPi());
    // }, []);

    const columns = [
        {
            name: "Reference",
            selector: (row) => `${row?.type}-${generateSequence(row?.id)}`,
            cell: (row) => (
                <a
                    className="color-05 user-name-link cursor"
                    onClick={() => redirectReference(row)}
                >
                    {`${row?.type}-${generateSequence(row?.id)}`}
                </a>
            ),
            sortable: true,
            width: "130px",
        },
        {
            name: "Source Type",
            selector: (row) => row?.nc?.source?.label || row?.source?.label,
            sortable: true,
            cell: (row) => row?.nc?.source?.label || row?.source?.label,
            width: "250px",
        },
        {
            name: "Date Raised",
            selector: (row) =>
                row?.createdAt
                    ? moment(row?.createdAt).format(APP_CONSTANTS.DATE_FORMAT)
                    : "",
            sortable: true,
            cell: (row) =>
                row?.createdAt
                    ? moment(row?.createdAt).format(APP_CONSTANTS.DATE_FORMAT)
                    : "",
        },
        {
            name: "Due By",
            selector: (row) =>
                row?.due_date
                    ? moment(row?.due_date).format(APP_CONSTANTS.DATE_FORMAT)
                    : "",
            sortable: true,
            cell: (row) =>
                row?.due_date
                    ? moment(row?.due_date).format(APP_CONSTANTS.DATE_FORMAT)
                    : "",
        },
        {
            name: "Accountable Manager",
            selector: (row) => row?.nc?.accountable_manager?.label,
            sortable: true,
            cell: (row) => row?.nc?.accountable_manager?.label,
        },

        {
            name: "Verifier",
            selector: (row) => row?.nc?.verifier?.label,
            sortable: true,
            cell: (row) => row?.nc?.verifier?.label,
        },
        {
            name: "Status",
            selector: (row) => row?.status,
            cell: (row) => (
                <div data-toggle="tooltip" title={row?.status} className="py-1">
                    {row?.status}
                </div>
            ),
            sortable: true,
        },
        {
            name: "Notes",
            selector: (row) =>
                row?.preventive_action_notes ||
                row?.corrective_action_notes ||
                row?.action_notes ||
                row?.root_cause_analysis_notes ||
                "",
            sortable: true,
            cell: (row) => (
                <div
                    className="py-2"
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {row?.preventive_action_notes ||
                        row?.corrective_action_notes ||
                        row?.action_notes ||
                        row?.root_cause_analysis_notes ||
                        ""}
                </div>
            ),
            maxWidth: "300px",
        },
        {
            name: "Priority",
            selector: (row) => priorityColorSort(row?.status, row?.type),
            cell: (row, index) => {
                switch (row?.type) {
                    case "CA":
                        if (
                            row?.status === "Draft" ||
                            row?.status === "Completed and verified late"
                        ) {
                            return <span className="dot dot-orange ml-3" />;
                        } else if (
                            row?.status === "In progress" ||
                            row?.status === "Completed" ||
                            row?.status === "Verified"
                        ) {
                            return <span className="dot dot-green ml-3" />;
                        } else if (
                            row?.status === "Overdue" ||
                            row?.status === "Completed late" ||
                            row?.status === "Completed late and verified"
                        ) {
                            return <span className="dot dot-red ml-3" />;
                        }
                        break;
                    case "PA":
                        if (
                            row?.status === "Draft" ||
                            row?.status === "Completed and verified late"
                        ) {
                            return <span className="dot dot-orange ml-3" />;
                        } else if (
                            row?.status === "In progress" ||
                            row?.status === "Completed" ||
                            row?.status === "Verified"
                        ) {
                            return <span className="dot dot-green ml-3" />;
                        } else if (
                            row?.status === "Overdue" ||
                            row?.status === "Completed late" ||
                            row?.status === "Completed late and verified"
                        ) {
                            return <span className="dot dot-red ml-3" />;
                        }
                        break;
                    case "PRO":
                        if (row?.status === "Draft") {
                            return <span className="dot dot-orange ml-3" />;
                        } else if (
                            row?.status === "In progress" ||
                            row?.status === "Completed"
                        ) {
                            return <span className="dot dot-green ml-3" />;
                        } else if (
                            row?.status === "Overdue" ||
                            row?.status === "Completed late"
                        ) {
                            return <span className="dot dot-red ml-3" />;
                        }
                        break;
                    case "RCA":
                        if (row?.status === "In progress" || row?.status === "Completed") {
                            return <span className="dot dot-green ml-3" />;
                        } else if (
                            row?.status === "Overdue" ||
                            row?.status === "Completed late"
                        ) {
                            return <span className="dot dot-red ml-3" />;
                        } else if (row?.status === "Sent") {
                            return <span className="dot dot-orange ml-3" />;
                        }
                        break;
                    default:
                        break;
                }
            },
            sortable: true,
        },

        {
            name: "Edit",
            width: "100px",
            cell: (row, index) => {
                return utils.checkSubscription() &&
                    row?.type === "PRO" &&
                    row?.status === "Draft" ? (
                    <div
                        onClick={() => {
                            editSelectedObject(row);
                        }}
                    >
                        {utils.checkPermissions("editNC") ? (
                            <a className="edit-link">Edit</a>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    ""
                );
            },
        },
    ];

    const priorityColorSort = (status, type) => {
        switch (type) {
            case "CA":
                if (status === "Draft" || status === "Completed and verified late") {
                    return "Orange";
                } else if (
                    status === "In progress" ||
                    status === "Completed" ||
                    status === "Verified"
                ) {
                    return "Green";
                } else if (
                    status === "Overdue" ||
                    status === "Completed late" ||
                    status === "Completed late and verified"
                ) {
                    return "Red";
                }
                break;
            case "PA":
                if (status === "Draft" || status === "Completed and verified late") {
                    return "Orange";
                } else if (
                    status === "In progress" ||
                    status === "Completed" ||
                    status === "Verified"
                ) {
                    return "Green";
                } else if (
                    status === "Overdue" ||
                    status === "Completed late" ||
                    status === "Completed late and verified"
                ) {
                    return "Red";
                }
                break;
            case "PRO":
                if (status === "Draft") {
                    return "Orange";
                } else if (status === "In progress" || status === "Completed") {
                    return "Green";
                } else if (status === "Overdue" || status === "Completed late") {
                    return "Red";
                }
                break;
            case "RCA":
                if (status === "In progress" || status === "Completed") {
                    return "Green";
                } else if (status === "Overdue" || status === "Completed late") {
                    return "Red";
                } else if (status === "Sent") {
                    return "Orange";
                }
                break;
            default:
                break;
        }
    };

    const handleRowSelection = ({ selectedRows }) => {
        dispatch(filteredListForActions(selectedRows));
    };

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataActions = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataActions);
    };

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="table-responsive">
            <DataTable
                columns={columns}
                data={filteredList}
                selectableRows
                customStyles={customStyles}
                striped
                onSelectedRowsChange={handleRowSelection}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />
        </div>
    );
};

export default Actions;
