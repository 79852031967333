import React from "react";
import DataTable from "react-data-table-component";
import { generateSequence } from "../../../../utils/referenceId";
import moment from "moment";
import { APP_CONSTANTS } from "../../../../constants";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const GmpDataTable = (props) => {
    const { data } = props;

    const org = JSON.parse(localStorage.getItem("org"));

    const redirectToQuestions = (row) => {
        window.open(`/private/gmp-inspection/${org?._id}/${row?._id}`);
    };

    const columns = [
        {
            name: "Reference",
            selector: (row) =>
                `${row?.gmpInspection?.type ? row?.gmpInspection?.type : row?.type}-${row?.gmpInspection?.id
                    ? generateSequence(row?.gmpInspection?.id)
                    : generateSequence(row?.id)
                }`,
            sortable: true,
            cell: (row) => (
                <a
                    className="edit-link user-name-link cursor"
                    onClick={() => redirectToQuestions(row)}
                >
                    {`${row?.gmpInspection?.type ? row?.gmpInspection?.type : row?.type
                        }-${row?.gmpInspection?.id
                            ? generateSequence(row?.gmpInspection?.id)
                            : generateSequence(row?.id)
                        }`}
                </a>
            ),
            width: "130px",
        },
        {
            name: "Inspection",
            selector: (row) =>
                `${row?.parentGmp?.gmpName ? row?.parentGmp?.gmpName : row?.gmpName}`,
            cell: (row) => (
                <div className="text-left">
                    {row?.parentGmp?.gmpName ? row?.parentGmp?.gmpName : row?.gmpName}
                </div>
            ),
            sortable: true,
        },
        {
            name: "Created Date",
            selector: (row) =>
                row?.date_raised &&
                moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT),
            sortable: true,
            cell: (row) =>
                row?.date_raised &&
                moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT),
        },
        {
            name: "Inspector",
            selector: (row) =>
                row?.gmpInspection?.inspectors
                    ? row?.gmpInspection?.inspectors
                    : row?.inspectors,
            cell: (row) => (
                <div className="text-left">
                    {(row?.gmpInspection?.inspectors
                        ? row?.gmpInspection?.inspectors
                        : row?.inspectors
                    )?.map((data, index) => (
                        <div key={index}>{data?.label}</div>
                    ))}
                </div>
            ),
        },
        {
            name: "Accountable Manager",
            selector: (row) =>
                row?.gmpInspection?.accountable_manager?.label
                    ? row?.gmpInspection?.accountable_manager?.label
                    : row?.accountable_manager?.label,
            sortable: true,
            cell: (row) => (
                <div className="text-left">
                    {row?.gmpInspection?.accountable_manager?.label
                        ? row?.gmpInspection?.accountable_manager?.label
                        : row?.accountable_manager?.label}
                </div>
            ),
        },
        {
            name: "Verifier",
            selector: (row) =>
                row?.gmpInspection?.verifier?.label
                    ? row?.gmpInspection?.verifier?.label
                    : row?.verifier?.label,
            sortable: true,
            cell: (row) => (
                <div className="text-left">
                    {row?.gmpInspection?.verifier?.label
                        ? row?.gmpInspection?.verifier?.label
                        : row?.verifier?.label}
                </div>
            ),
        },
        {
            name: "Status",
            selector: (row) =>
                row?.gmpInspection?.status ? row?.gmpInspection?.status : row?.status,
            sortable: true,
            cell: (row) =>
                row?.gmpInspection?.status ? (
                    <div
                        data-toggle="tooltip"
                        title={row?.status}
                        className="py-1 text-left"
                    >
                        {row?.gmpInspection?.status}
                    </div>
                ) : (
                    <div
                        data-toggle="tooltip"
                        title={row?.status}
                        className="py-1 text-left"
                    >
                        {row?.status}
                    </div>
                ),
        },
    ];

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="mt-3">
            <DataTable
                columns={columns}
                data={data ? data : []}
                customStyles={customStyles}
                striped
                persistTableHead
                keyField={customUniqueID}
                pagination
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );
};

export default GmpDataTable;
