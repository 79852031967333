import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import NcView from "./NcView";
import ActionerBrowseModal from "../../Modals/ActionerBrowsModal";
import {
    createCaActionAPi,
    getCaActionForActionerAPi,
    updateCaAction,
    updatePaAction,
    createPaActionAPi,
    getPaActionForActionerAPi,
    addActionerEvidence,
    setSelectedAction,
    sentCompleteActionsApi,
    createAndSendCAAndPAActionsnApi,
    navigateHome,
    toggleEmailSuccessModal,
    toggleSuccessModal,
    toggleErrorModal,
} from "../../../store/actions/action";
import SaveModal from "../../Modals/SaveModal";
import { APP_CONSTANTS } from "../../../constants";
import ErrorModal from "../../Modals/ErrorModal";
import NotificationSendModal from "../../Modals/NotificationSendModal";
import utils from "../../../utils";
import { getExtension } from "../../../utils/icons";
import { deleteMediaFromS3Bucket } from "../../../utils/s3";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { generateSequence } from "../../../utils/referenceId";
import videoIcon from "../../../Assets/images/file-video-regular.svg";

const Actioner = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const btnRef = useRef(null);
    const { ncId, orgId } = useParams();

    const nc = useSelector((state) => state?.nc);
    const object = useSelector((state) => state?.action);
    const { nav } = useSelector((state) => state?.navigate);
    const { send, success, error } = useSelector((state) => state?.action);
    const { user } = useSelector((state) => state);

    const [noModal, setNoModal] = useState(false);

    let userId = user.object._id;

    if (nav === true) {
        navigate(`/private/home`);
        dispatch(navigateHome(false));
    }

    let ca_actions = object?.ca_actions?.filter(
        (item) => item?.actioner?.value === userId
    );

    let pa_actions = object?.pa_actions?.filter(
        (item) => item?.actioner?.value === userId
    );

    const handleCaFieldChanges = (field, value, custom, index) => {
        let actionCopy = [...ca_actions];
        let currentOj = actionCopy[index];
        currentOj["change"] = true;
        if (!custom) {
            currentOj[field] = value;
            dispatch(updateCaAction(actionCopy));
            return;
        }

        switch (field) {
            case "completed":
                currentOj[field] = value;
                if (value) currentOj["completed"] = true;
                else currentOj["status"] = "In progress";

            case "custom_title":
                if (currentOj.attachment) {
                    currentOj["attachment"][value.index]["custom_name"] = value;
                }

            default:
        }
        dispatch(updateCaAction(actionCopy));
    };

    const handleCaActionerEvidence = (field, value, custom, index, ind) => {
        let actionCopy = [...ca_actions];
        actionCopy[index].actioner_evidence[ind].custom_name = value;
        dispatch(updateCaAction(actionCopy));
    };

    const handlePaFieldChanges = (field, value, custom, index) => {
        let actionCopy = [...pa_actions];
        let currentOj = actionCopy[index];
        currentOj["change"] = true;
        if (!custom) {
            currentOj[field] = value;
            dispatch(updatePaAction(actionCopy));
            return;
        }
        switch (field) {
            case "completed":
                currentOj[field] = value;
                if (value) currentOj["completed"] = true;
                else currentOj["status"] = "In progress";
        }
        dispatch(updatePaAction(actionCopy));
    };

    const handlePaActionerEvidence = (field, value, custom, index, ind) => {
        let actionCopy = [...pa_actions];
        actionCopy[index].actioner_evidence[ind].custom_name = value;
        dispatch(updatePaAction(actionCopy));
    };

    const saveActions = async () => {
        btnRef.current.disabled = true;
        if (pa_actions?.length) {
            validatePaActions();
            dispatch(
                createPaActionAPi({
                    nc: nc?.object?._id,
                    pa_actions: pa_actions,
                    actioner: true,
                    org: nc?.object?.org,
                })
            );
        }
        if (ca_actions?.length) {
            validateCaActions();
            dispatch(
                createCaActionAPi({
                    nc: nc?.object?._id,
                    ca_actions: ca_actions,
                    actioner: true,
                    org: nc?.object?.org,
                })
            );
        }
    };

    const sendActions = () => {
        btnRef.current.disabled = true;
        if (pa_actions?.length) validatePaActions();
        if (ca_actions?.length) validateCaActions();

        ca_actions?.map((item) => {
            if (item?.completed) {
                item.status = "Completed";
            }
            return item;
        });

        pa_actions?.map((item) => {
            if (item?.completed) {
                item.status = "Completed";
            }
            return item;
        });

        let caIndex = ca_actions?.findIndex(
            (action) => action.status === "Completed"
        );
        let paIndex = pa_actions?.findIndex(
            (action) => action.status === "Completed"
        );
        if (caIndex >= 0 || paIndex >= 0) {
            dispatch(
                createAndSendCAAndPAActionsnApi(
                    nc?.object?._id,
                    ca_actions,
                    pa_actions,
                    sentCompleteActionsApi,
                    { nc: nc?.object?._id },
                    nc?.object?.org,
                    "actioner"
                )
            );
        } else {
            setNoModal(true);
            btnRef.current.disabled = false;
            setTimeout(() => {
                setNoModal(false);
            }, APP_CONSTANTS.MODAL_SHOW_TIME);
        }
        /*dispatch(sentCompleteActionsApi({nc:object?._id}))*/
    };

    const validateCaActions = () => {
        let actionCopy = [...ca_actions];
        actionCopy.forEach((obj, index) => {
            if (obj?._id && obj.change) obj.update = true;
        });
        dispatch(updateCaAction(actionCopy));
    };

    const validatePaActions = () => {
        let actionCopy = [...pa_actions];
        actionCopy.forEach((obj, index) => {
            if (obj?._id && obj.change) obj.update = true;
        });
        dispatch(updatePaAction(actionCopy));
    };

    const removeCaEvidence = async (obj, caIndex, fileIndex) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            let actionCopy = [...ca_actions];
            let select = actionCopy[caIndex];
            if (obj.key) await deleteMediaFromS3Bucket(obj.key);
            select?.removeFiles
                ? select.removeFiles.push(select?.actioner_evidence[fileIndex])
                : (select.removeFiles = [select?.actioner_evidence[fileIndex]]);
            select?.actioner_evidence?.splice(fileIndex, 1);
            actionCopy[caIndex] = select;
            dispatch(updateCaAction(actionCopy));
        }
    };

    const removePaEvidence = async (obj, paIndex, fileIndex) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            let actionCopy = [...pa_actions];
            let select = actionCopy[paIndex];
            if (obj.key) await deleteMediaFromS3Bucket(obj.key);
            select?.removeFiles
                ? select.removeFiles.push(select?.actioner_evidence[fileIndex])
                : (select.removeFiles = [select?.actioner_evidence[fileIndex]]);
            select?.actioner_evidence?.splice(fileIndex, 1);
            actionCopy[paIndex] = select;
            dispatch(updatePaAction(actionCopy));
        }
    };

    const openLinkInNewTab = (link) => {
        window.open(link);
    };

    useEffect(() => {
        dispatch(updatePaAction([]));
        dispatch(updateCaAction([]));

        if (ncId) {
            dispatch(getCaActionForActionerAPi({ nc: ncId, org: orgId }));
            dispatch(getPaActionForActionerAPi({ nc: ncId, org: orgId }));
        }
    }, []);

    useEffect(() => {
        if (error && btnRef?.current) {
            btnRef.current.disabled = false;
        }
    }, [error]);

    return (
        <section className="reference">
            <div className="container-fluid">
                <div className="row">
                    <NcView />
                    {/* Column End */}
                    <div className="col-12 col-lg-6 mt-3">
                        {ca_actions?.length > 0 &&
                            ca_actions?.map((obj, index) => (
                                <>
                                    <h2 className="fs-16 fw-600 color-04">
                                        Corrective action{" "}
                                        <span className="float-right">
                                            Reference:{" "}
                                            {`${obj?.type}-${generateSequence(obj?.id || 0)}`}
                                        </span>
                                    </h2>
                                    <p className="action-note">{obj?.corrective_action_notes}</p>

                                    <h2 className="fs-16 fw-600 color-04">Verifier Notes</h2>
                                    <p className="action-note">{obj?.verifier_notes}</p>

                                    <div className="light--bg px-2">
                                        <dl className="row align-items-center py-1">
                                            <dt className="col-sm-4">Due date</dt>
                                            <dd className="col-sm-8">
                                                {obj?.due_date
                                                    ? moment(obj?.due_date).format(
                                                        APP_CONSTANTS.DATE_FORMAT
                                                    )
                                                    : ""}
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="px-2">
                                        <dl className="row">
                                            <dt className="col-sm-4">Status</dt>
                                            <dd className="col-sm-8">{obj?.status}</dd>
                                        </dl>
                                    </div>
                                    <h2 className="fs-16 fw-600 color-04">
                                        Corrective action notes
                                    </h2>
                                    <textarea
                                        className="form-control"
                                        id=""
                                        rows="5"
                                        value={obj?.actioner_notes ? obj?.actioner_notes : ""}
                                        onChange={(e) =>
                                            handleCaFieldChanges(
                                                "actioner_notes",
                                                e.target.value,
                                                false,
                                                index
                                            )
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <div className="form-group row light-bg add-evidence mb-0 mt-3">
                                        <label className="col-sm-9">Add evidence</label>
                                        <div className="col-sm-3">
                                            <button
                                                type="button"
                                                className="btn-browse color-03 fs-12 fw-300 float-lg-right"
                                                data-toggle="modal"
                                                data-target="#actionerBrowseModal"
                                                onClick={() => dispatch(setSelectedAction(obj))}
                                                disabled={!utils.checkSubStatus()}
                                            >
                                                <i className="far fa-folder-open" /> Browse
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row my-4 evidence-scroll">
                                        {obj?.actioner_evidence?.map((obj, ind) => (
                                            <div className="col-sm-6 pl-0" key={ind}>
                                                <i
                                                    className="far fa-minus-circle"
                                                    onClick={() => removeCaEvidence(obj, index, ind)}
                                                />
                                                {obj.extension === "images" && obj?.path && (
                                                    <img
                                                        loading="lazy"
                                                        src={`${obj?.path}`}
                                                        onClick={() => {
                                                            openLinkInNewTab(obj?.path);
                                                        }}
                                                        alt="Evidence"
                                                    />
                                                )}
                                                {obj.extension === "videos" && obj?.path && (
                                                    // <video
                                                    //     className="cursor"
                                                    //     onClick={() => {
                                                    //         openLinkInNewTab(obj?.path);
                                                    //     }}
                                                    //     width="49"
                                                    //     src={`${obj?.path}#t=0.1`}
                                                    //     alt="Evidence"
                                                    // />
                                                    <img
                                                        className="cursor"
                                                        loading="lazy"
                                                        onClick={() => {
                                                            openLinkInNewTab(obj?.path);
                                                        }}
                                                        src={videoIcon}
                                                        alt="Evidence"
                                                    />
                                                )}
                                                {obj.extension === "files" && obj?.path && (
                                                    <img
                                                        loading="lazy"
                                                        className="cursor"
                                                        onClick={() => {
                                                            openLinkInNewTab(obj?.path);
                                                        }}
                                                        src={getExtension(obj?.path)}
                                                        alt="Evidence"
                                                    />
                                                )}
                                                <input
                                                    className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                    value={obj?.custom_name}
                                                    placeholder="optional title"
                                                    onChange={(e) =>
                                                        handleCaActionerEvidence(
                                                            "custom_title",
                                                            e.target.value,
                                                            true,
                                                            index,
                                                            ind
                                                        )
                                                    }
                                                    disabled={!utils.checkSubStatus()}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <dl className="row mt-4">
                                        <dt className="col-sm-9">Complete action?</dt>
                                        <dd className="col-sm-3">
                                            <div className="row">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            checked={!obj?.completed}
                                                            id={`ca-${index}`}
                                                            // checked={obj?.status === "In progress"}
                                                            onClick={(e) =>
                                                                handleCaFieldChanges(
                                                                    "completed",
                                                                    false,
                                                                    true,
                                                                    index
                                                                )
                                                            }
                                                            disabled={!utils.checkSubStatus()}
                                                        />
                                                        <label
                                                            className="custom-control-label cursor fs-14 fw-400 color-04"
                                                            htmlFor={`ca-${index}`}
                                                        >
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            checked={obj?.completed}
                                                            id={`ca--${index}`}
                                                            // checked={obj?.status === "Completed"}
                                                            onClick={(e) =>
                                                                handleCaFieldChanges(
                                                                    "completed",
                                                                    true,
                                                                    true,
                                                                    index
                                                                )
                                                            }
                                                            disabled={!utils.checkSubStatus()}
                                                        />
                                                        <label
                                                            className="custom-control-label cursor fs-14 fw-400 color-04"
                                                            htmlFor={`ca--${index}`}
                                                        >
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </>
                            ))}
                        {pa_actions?.length > 0 &&
                            pa_actions?.map((obj, index) => (
                                <>
                                    <div className="divider mb-4"></div>
                                    <h2 className="fs-16 fw-600 color-04">
                                        Preventive action{" "}
                                        <span className="float-right">
                                            Reference:{" "}
                                            {`${obj?.type}-${generateSequence(obj?.id || 0)}`}
                                        </span>
                                    </h2>
                                    <p className="action-note">{obj?.preventive_action_notes}</p>
                                    <h2 className="fs-16 fw-600 color-04">Verifier Notes</h2>
                                    <p className="action-note">{obj?.verifier_notes}</p>

                                    <div className="light--bg px-2">
                                        <dl className="row align-items-center py-1">
                                            <dt className="col-sm-4">Due date</dt>
                                            <dd className="col-sm-8">
                                                {obj?.due_date
                                                    ? moment(obj?.due_date).format(
                                                        APP_CONSTANTS.DATE_FORMAT
                                                    )
                                                    : ""}
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="px-2">
                                        <dl className="row">
                                            <dt className="col-sm-4">Status</dt>
                                            <dd className="col-sm-8">{obj?.status}</dd>
                                        </dl>
                                    </div>
                                    <h2 className="fs-16 fw-600 color-04">
                                        Preventive action notes
                                    </h2>
                                    <textarea
                                        className="form-control"
                                        id=""
                                        rows="5"
                                        value={obj?.actioner_notes ? obj?.actioner_notes : ""}
                                        onChange={(e) =>
                                            handlePaFieldChanges(
                                                "actioner_notes",
                                                e.target.value,
                                                false,
                                                index
                                            )
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <div className="form-group row light-bg add-evidence mb-0 mt-3">
                                        <label className="col-sm-9">Add evidence</label>
                                        <div className="col-sm-3">
                                            <button
                                                type="button"
                                                className="btn-browse color-03 fs-12 fw-300 float-lg-right"
                                                data-toggle="modal"
                                                data-target="#actionerBrowseModal"
                                                onClick={() => dispatch(setSelectedAction(obj))}
                                                disabled={!utils.checkSubStatus()}
                                            >
                                                <i className="far fa-folder-open" /> Browse
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row my-4 evidence-scroll">
                                        {obj?.actioner_evidence?.map((obj, ind) => (
                                            <div className="col-sm-6 pl-0" key={ind}>
                                                <i
                                                    className="far fa-minus-circle"
                                                    onClick={() => removePaEvidence(obj, index, ind)}
                                                />
                                                {obj.extension === "images" && obj?.path && (
                                                    <img
                                                        loading="lazy"
                                                        src={`${obj?.path}`}
                                                        onClick={() => {
                                                            openLinkInNewTab(obj?.path);
                                                        }}
                                                        alt="Evidence"
                                                    />
                                                )}
                                                {obj.extension === "videos" && obj?.path && (
                                                    // <video
                                                    //     className="cursor"
                                                    //     onClick={() => {
                                                    //         openLinkInNewTab(obj?.path);
                                                    //     }}
                                                    //     width="49"
                                                    //     src={`${obj?.path}#t=0.1`}
                                                    //     alt="Evidence"
                                                    // />
                                                    <img
                                                        className="cursor"
                                                        loading="lazy"
                                                        onClick={() => {
                                                            openLinkInNewTab(obj?.path);
                                                        }}
                                                        src={videoIcon}
                                                        alt="Evidence"
                                                    />
                                                )}
                                                {obj.extension === "files" && obj?.path && (
                                                    <img
                                                        loading="lazy"
                                                        className="cursor"
                                                        onClick={() => {
                                                            openLinkInNewTab(obj?.path);
                                                        }}
                                                        src={getExtension(obj?.path)}
                                                        alt="Evidence"
                                                    />
                                                )}
                                                <input
                                                    className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                    value={obj?.custom_name}
                                                    placeholder="optional title"
                                                    onChange={(e) =>
                                                        handlePaActionerEvidence(
                                                            "custom_title",
                                                            e.target.value,
                                                            true,
                                                            index,
                                                            ind
                                                        )
                                                    }
                                                    disabled={!utils.checkSubStatus()}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <dl className="row mt-4">
                                        <dt className="col-sm-9">Complete action?</dt>
                                        <dd className="col-sm-3">
                                            <div className="row">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            id={`pa-${index}`}
                                                            checked={!obj?.completed}
                                                            // checked={obj?.status === "In progress"}
                                                            onClick={(e) =>
                                                                handlePaFieldChanges(
                                                                    "completed",
                                                                    false,
                                                                    true,
                                                                    index
                                                                )
                                                            }
                                                            disabled={!utils.checkSubStatus()}
                                                        />
                                                        <label
                                                            className="custom-control-label cursor fs-14 fw-400 color-04"
                                                            htmlFor={`pa-${index}`}
                                                        >
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            id={`pa--${index}`}
                                                            checked={obj?.completed}
                                                            // checked={obj?.status === "Completed"}
                                                            onClick={(e) =>
                                                                handlePaFieldChanges(
                                                                    "completed",
                                                                    true,
                                                                    true,
                                                                    index
                                                                )
                                                            }
                                                            disabled={!utils.checkSubStatus()}
                                                        />
                                                        <label
                                                            className="custom-control-label cursor fs-14 fw-400 color-04"
                                                            htmlFor={`pa--${index}`}
                                                        >
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </>
                            ))}
                    </div>
                    {/* Column End */}
                </div>
            </div>
            {utils.checkSubscription() &&
                (ca_actions?.length > 0 || pa_actions?.length > 0) && (
                    <div className="float-right mt-4">
                        <button
                            className="btn-save mr-2"
                            onClick={saveActions}
                            ref={btnRef}
                            disabled={btnRef?.current?.disabled}
                        >
                            <i className="far fa-save" /> Save
                        </button>
                        <button
                            className="btn-send"
                            onClick={sendActions}
                            ref={btnRef}
                            disabled={btnRef?.current?.disabled}
                        >
                            <i className="far fa-share" /> Send
                        </button>
                    </div>
                )}
            <ActionerBrowseModal dispatch={dispatch} action={addActionerEvidence} />
            {success && !error?.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                            window.location.href = "/";
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.ACTION_SAVE_MESSAGE.h2}
                        h3={APP_CONSTANTS.ACTION_SAVE_MESSAGE.h3}
                        p={APP_CONSTANTS.ACTION_SAVE_MESSAGE.p}
                    />
                </>
            )}
            {error?.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal({ show: false, error: {} }));
                        }}
                    ></div>{" "}
                    <ErrorModal error={error.error} />
                </>
            )}
            {send && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleEmailSuccessModal({ send: false }));
                            window.location.href = "/";
                        }}
                    ></div>
                    <NotificationSendModal
                        h2={APP_CONSTANTS.ACTION_COMPLETE_Email.h2}
                        h3={APP_CONSTANTS.ACTION_COMPLETE_Email.h3}
                        p={APP_CONSTANTS.ACTION_COMPLETE_Email.p}
                    />
                </>
            )}
            {noModal && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setNoModal(false);
                        }}
                    ></div>
                    <ErrorModal
                        h2={APP_CONSTANTS.CA_NOT_COMPLETED_MESSAGE_.h2}
                        h3={APP_CONSTANTS.CA_NOT_COMPLETED_MESSAGE_.h3}
                        type={"noModal"}
                    />
                </>
            )}
            {/* {(success || error?.show || send || error?.sentError) && (
                <div className="modal-backdrop fade show"></div>
            )} */}
        </section>
    );
};

export default Actioner;
