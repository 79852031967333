import React from 'react';

const CompR = () => {
    return (
        <>
            <h2 id="r">R</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading270">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse270" aria-expanded="true"
                                    aria-controls="collapse270">
                                Readily available
                            </button>
                        </h2>
                    </div>
                    <div id="collapse270" className="collapse show" aria-labelledby="heading270"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When auditing you should be able to access the information you need, whether it be documents, records, or speaking to the right person. This information should be provided to you within a reasonable amount of time. What's 'reasonable' will depend on the operation and the situation. Think about what would be expected in an emergency situation and whether the offered time frame would be acceptable.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading271">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse271" aria-expanded="false"
                                    aria-controls="collapse271">
                                Ready-to-cook
                            </button>
                        </h2>
                    </div>
                    <div id="collapse271" className="collapse" aria-labelledby="heading271"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A product which is not safe to eat and has to be cooked, before it can be eaten.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading272">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse272" aria-expanded="false"
                                    aria-controls="collapse272">
                                Ready-to-eat
                            </button>
                        </h2>
                    </div>
                    <div id="collapse272" className="collapse" aria-labelledby="heading272"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A product which is safe to eat and is designed to be eaten cold.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading273">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse273" aria-expanded="false"
                                    aria-controls="collapse273">
                                Ready-to-heat
                            </button>
                        </h2>
                    </div>
                    <div id="collapse273" className="collapse" aria-labelledby="heading273"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A product which is safe to eat, but is designed to be eaten hot.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading274">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse274" aria-expanded="false"
                                    aria-controls="collapse274">
                                Recall
                            </button>
                        </h2>
                    </div>
                    <div id="collapse274" className="collapse" aria-labelledby="heading274"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A recall is when a product has been made available for sale to consumers and it's not safe, legal or authentic. This means, the product must be removed from sale and consumers are notified that they must not use the product and take it back to where they bought it from. Recalls are not typically carried out if the quality of the product is not right, however a withdrawal may be carried out instead.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading275">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse275" aria-expanded="false"
                                    aria-controls="collapse275">
                                Record
                            </button>
                        </h2>
                    </div>
                    <div id="collapse275" className="collapse" aria-labelledby="heading275"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A template that holds data.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading276">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse276" aria-expanded="false"
                                    aria-controls="collapse276">
                                Records
                            </button>
                        </h2>
                    </div>
                    <div id="collapse276" className="collapse" aria-labelledby="heading276"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            If something isn't written down, either on paper or digitally a the computer, in an auditor's eyes it didn't happen. This means where we need to prove that we have met the standard, we have to record it.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading277">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse277" aria-expanded="false"
                                    aria-controls="collapse277">
                                Recycling
                            </button>
                        </h2>
                    </div>
                    <div id="collapse277" className="collapse" aria-labelledby="heading277"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Waste material which can be reused or reworked. Typically used when referring to packaging waste.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading278">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse278" aria-expanded="false"
                                    aria-controls="collapse278">
                                Regulations
                            </button>
                        </h2>
                    </div>
                    <div id="collapse278" className="collapse" aria-labelledby="heading278"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Rules applied by law. Regulations must be considered for the Country of sale and the Processing Country. Also known as;  legislation
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading279">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse279" aria-expanded="false"
                                    aria-controls="collapse279">
                                Relevant
                            </button>
                        </h2>
                    </div>
                    <div id="collapse279" className="collapse" aria-labelledby="heading279"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something which is connected to the subject or matter in question. See also; communicating to relevant personnel, relevant documents
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading280">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse280" aria-expanded="false"
                                    aria-controls="collapse280">
                                Relevant documents
                            </button>
                        </h2>
                    </div>
                    <div id="collapse280" className="collapse" aria-labelledby="heading280"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Documents which provide evidence to the requirement that you are auditing.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading281">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse281" aria-expanded="false"
                                    aria-controls="collapse281">
                                Remedial action
                            </button>
                        </h2>
                    </div>
                    <div id="collapse281" className="collapse" aria-labelledby="heading281"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Action taken to correct the process and prevent it the product from reaching the reject limits and becoming out-of-specification.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading282">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse282" aria-expanded="false"
                                    aria-controls="collapse282">
                                Rescinded
                            </button>
                        </h2>
                    </div>
                    <div id="collapse282" className="collapse" aria-labelledby="heading282"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To rescind a document, is to take it out of circulation and archive it, because it is no longer needed.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading283">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse283" aria-expanded="false"
                                    aria-controls="collapse283">
                                Responsible
                            </button>
                        </h2>
                    </div>
                    <div id="collapse283" className="collapse" aria-labelledby="heading283"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A person who completes actions. 'Responsibility' is action-based, whereas 'accountability' is management-based.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading284">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse284" aria-expanded="false"
                                    aria-controls="collapse284">
                                Restricted
                            </button>
                        </h2>
                    </div>
                    <div id="collapse284" className="collapse" aria-labelledby="heading284"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where documents, setttings and areas of the site are limited to authorised personnel, for security purposes. See also; restricted access to documents, restricted access to settings, restricted access to areas
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading285">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse285" aria-expanded="false"
                                    aria-controls="collapse285">
                                Restricted access to areas
                            </button>
                        </h2>
                    </div>
                    <div id="collapse285" className="collapse" aria-labelledby="heading285"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            It's important that only people who are allowed to go into certain areas of the site, are able to access them. If people who are not authorised to go into these areas, are able to get in, they may have access to materials or chemicals that could be used to contaminate the product maliciously.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading286">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse286" aria-expanded="false"
                                    aria-controls="collapse286">
                                Restricted access to documents
                            </button>
                        </h2>
                    </div>
                    <div id="collapse286" className="collapse" aria-labelledby="heading286"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            It's important that only people who are allowed to amend documents, have access to them. If people who are not authorised to amend documents are able to get to them to adapt them, they may change them so that they are not compliant. They may do this by accident or maliciously.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading287">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse287" aria-expanded="false"
                                    aria-controls="collapse287">
                                Restricted access to settings
                            </button>
                        </h2>
                    </div>
                    <div id="collapse287" className="collapse" aria-labelledby="heading287"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            It's important that only people who are allowed to amend settings, have access to them. If people who are not authorised to amend settings are able to get to them to change them, they may change them so that they are not compliant. They may do this by accident or maliciously. In the worst case, where the settings manage product safety, changes to the settings may cause unsafe product to be produced.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading288">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse288" aria-expanded="false"
                                    aria-controls="collapse288">
                                Retention
                            </button>
                        </h2>
                    </div>
                    <div id="collapse288" className="collapse" aria-labelledby="heading288"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            How long a record is kept for before it's deleted or binned.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading289">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse289" aria-expanded="false"
                                    aria-controls="collapse289">
                                Retraining frequency
                            </button>
                        </h2>
                    </div>
                    <div id="collapse289" className="collapse" aria-labelledby="heading289"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The frequency at which competency to complete a specific task, or understand a specific subject - must be checked. Also known as compency frequency.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading290">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse290" aria-expanded="false"
                                    aria-controls="collapse290">
                                Retrieved
                            </button>
                        </h2>
                    </div>
                    <div id="collapse290" className="collapse" aria-labelledby="heading290"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To find the documents that you're looking for.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading291">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse291" aria-expanded="false"
                                    aria-controls="collapse291">
                                Review actions
                            </button>
                        </h2>
                    </div>
                    <div id="collapse291" className="collapse" aria-labelledby="heading291"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When a review takes place, it should assess if set tasks have been completed and if the targets for the tasks have been met. Where tasks have not been completed, or where targets have not been met, then actions will need to be put in place to ensure that they are achieved. These actions which are an output of the review, are called review actions.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading292">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse292" aria-expanded="false"
                                    aria-controls="collapse292">
                                Review / Reviewing / Reviewed
                            </button>
                        </h2>
                    </div>
                    <div id="collapse292" className="collapse" aria-labelledby="heading292"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When a document review takes place, it's not just a case of updating the date on the document, but it includes a number of activities. The document must be checked to see if it's still relevant, or if any changes need to be made. Relevant personnel must be consulted to establish if they require any changes. Where needed, changes must be carried out and the updated document must be approved, before finally it's published with the current date. When auditing a review, look at the amendments for the document to make sure that the review has been recorded. The amendments should detail enough information to prove that a thorough review was carried out, such as who was involved and what was considered.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading293">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse293" aria-expanded="false"
                                    aria-controls="collapse293">
                                Rework
                            </button>
                        </h2>
                    </div>
                    <div id="collapse293" className="collapse" aria-labelledby="heading293"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Material that's not waste, which can be reused or reworked.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading294">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse294" aria-expanded="false"
                                    aria-controls="collapse294">
                                Role
                            </button>
                        </h2>
                    </div>
                    <div id="collapse294" className="collapse" aria-labelledby="heading294"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The job that someone does. It's important that the repsonsible for the role, knows that they need to do it. This is typically done by getting the person to sign a job description, or it's written into a procedure which they are trained to.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompR;