import React, { useEffect, useState } from "react";
import request from "../../services/request";
import { AUTHENTICATION } from "../../Routes/Api";
import CryptoJS from "crypto-js";
import crypto from "crypto";

const DownloadReport = () => {
    const key = process.env.REACT_APP_CRYPTO_FILE_PRIVATE_KEY;

    const [fileUrl, setFileUrl] = useState(null);
    const [email, setEmail] = useState(null);
    const [error, setError] = useState({
        show: false,
        message: "",
    });
    const [downloadStarted, setDownloadStarted] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));

    const decrypt = (text) => {
        let iv = Buffer.from(text.iv, "hex");
        let encryptedText = Buffer.from(text.encryptedData, "hex");
        let decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(key), iv);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
    };

    const getQueryParams = (url) => {
        const queryParams = {};
        const urlParts = url.split("?");
        if (urlParts.length > 1) {
            const queryString = urlParts[1];
            const paramPairs = queryString.split("&");
            paramPairs.forEach((pair) => {
                const [key, value] = pair.split("=");
                queryParams[key] = decodeURIComponent(value);
            });
        }
        return queryParams;
    };

    useEffect(() => {
        setDownloadStarted(true);
        const inputString = new URL(window?.location?.href);
        const email = inputString?.search?.split("id=");
        const queryParams = getQueryParams(window?.location?.href);
        const iv = queryParams.iv;
        const urlValue = queryParams.url;

        let decryptedData = decrypt({
            iv: iv,
            encryptedData: urlValue,
        });
        setFileUrl(decryptedData);
        if (email?.length === 2) {
            const extractedString = email[1];
            let data = CryptoJS.AES.decrypt(
                extractedString,
                process.env.REACT_APP_CRYPTO_PRIVATE_KEY
            );
            const deCryptedEmail = data.toString(CryptoJS.enc.Utf8);
            setEmail(deCryptedEmail);
        }
    }, []);

    useEffect(() => {
        if (fileUrl && email) {
            if (email === user?.email || email?.includes(user?.email)) {
                request.get(`${AUTHENTICATION.VALIDATE_TOKEN}`, true, false).then(
                    (res) => {
                        const link = document.createElement("a");
                        link.href = fileUrl;
                        link.addEventListener("click", () => {
                            console.log("File download initiated.");
                        });
                        link.click();
                        setDownloadStarted(false);
                    },
                    (error) => { }
                );
            } else {
                setError({
                    show: true,
                    message: "To download the report you must log in to audIT.app, using the email address shown on the email.",
                });
            }
        }
    }, [fileUrl]);

    return (
        <div className="download-reports">
            {!downloadStarted ? (
                <p className="fs-24 fw-600 text-center">
                    Download started. You can now close this tab.
                </p>
            ) : error?.show ? (
                <p className="fs-24 fw-600 text-center">{error?.message}</p>
            ) : (
                <p className="fs-24 fw-600 text-center">Preparing your download...</p>
            )}
        </div>
    );
};

export default DownloadReport;
