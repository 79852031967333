// import {HOST} from './urls';
import session from '../services/session';
const HOST = process.env.REACT_APP_BACKEND_URL
const GMP_HOST = process.env.REACT_APP_BACKEND_URL_GMP

class Api {
    static headers(multipart = false) {
        if (multipart) {
            return {
                // 'Content-Type': 'multipart/form-data',
            };
        } else {
            return {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            };
        }
    }

    static get(route, sendAuthToken = false) {
        return this.xhr(route, null, 'GET', sendAuthToken, false, true);
    }

    static put(route, params, sendAuthToken = false, multipart = false) {
        return this.xhr(route, params, 'PUT', sendAuthToken, false, true);
    }

    static post(route, params, sendAuthToken = false, multipart = false, main=true) {
        // console.log('Route:::', route, 'Data:::', params);
        return this.xhr(route, params, 'POST', sendAuthToken, multipart, main);
    }

    static delete(route, params, sendAuthToken = false) {
        return this.xhr(route, params, 'DELETE', sendAuthToken, false, true);
    }

    static async logout() {
        await session.removeToken();
        await session.removeUser()
        window.location.href = `/`
        // RootNavigation.resetActions('Welcome');
    }

    static async xhr(route, params, verb, sendAuthToken, multipart, main) {
        // console.log(route, params, verb, sendAuthToken, multipart);
        let tokens = await session.getToken();
        const url = `${main ? HOST : GMP_HOST}/${route}`;
        let options = Object.assign(
            { method: verb },
            params ? { body: multipart ? params : JSON.stringify(params) } : null,
        );

        options.headers = Api.headers(multipart);
        if (tokens && sendAuthToken) {
            options.headers.Authorization = 'Bearer ' + tokens;
        }
        // console.log('url::', url);
        // console.log('Options::', options);

        return fetch(url, options)
            .then((resp) => {
                let json = resp.json();
                // console.log("Response::", json);
                // console.log('Resp::', resp.ok, resp.status);
                if (resp.ok) {
                    return json;
                }
                return json.then((err) => {


                    if (resp.status == 401) {
                        Api.logout();
                    }
                    throw err;
                });
            })
            .then((res) => {
                return res;
            });
    }
}
export default Api;
