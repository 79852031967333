import React, { useEffect, useState } from "react";
import { APP_CONSTANTS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersApi, getUsersByRoleApi } from "../../../store/actions/user";
import request from "../../../services/request";
import { GMP, SCHEDULED_REPORTS } from "../../../Routes/Api";
import FilterAction from "./Filters/FilterAction";
import Select from "react-select";
import ScheduledReportsData from "./ScheduledReportsData/ScheduledReportsData";
import SaveModal from "../../Modals/SaveModal";
import ErrorModal from "../../Modals/ErrorModal";
import { getScheduledReportsApi } from "../../../store/actions/reportScheduler";
import DraftScheduleModal from "../../Modals/DraftScheduleModal";
import utils from "../../../utils";

const ScheduledReports = () => {
    const startDay = [
        {
            label: "Monday",
            value: "monday",
        },
        {
            label: "Tuesday",
            value: "tuesday",
        },
        {
            label: "Wednesday",
            value: "wednesday",
        },
        {
            label: "Thursday",
            value: "thursday",
        },
        {
            label: "Friday",
            value: "friday",
        },
        {
            label: "Saturday",
            value: "saturday",
        },
        {
            label: "Sunday",
            value: "sunday",
        },
    ];

    const dispatch = useDispatch();

    const { usersByRole } = useSelector((state) => state?.user);
    const scheduleReportList = useSelector(
        (state) => state?.reportScheduler?.list
    );
    const users = useSelector((state) => state?.user?.list);

    const [mainReports, setMainReports] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [parentGMP, setParentGMP] = useState([]);
    const [frequency, setFrequency] = useState([]);
    const [reportBuilder, setReportBuilder] = useState({
        type: [],
        source: APP_CONSTANTS?.KPI_SOURCE_OPTIONS?.map((item) => item?.label),
        roles: [],
        grade: ["Critical", "Major", "Minor"],
        topic: APP_CONSTANTS?.TOPIC_OPTIONS?.map((item) => item?.label),
        area: [],
        frequency: {},
        startDay: startDay[0],
    });
    const [currentMenu, setCurrentMenu] = useState(null);
    const [outside, setOutside] = useState([]);
    const [reportDetails, setReportDetails] = useState({
        reportName: "",
        recipients: [],
        cc: [],
    });
    const [error, setError] = useState({
        reportType: false,
        report_name: false,
        recipients: false,
    });
    const [modal, setModal] = useState({
        save: false,
        schedule: false,
        error: false,
    });
    const [errorMessage, setErrorMessage] = useState(null);
    const [disable, setDisable] = useState(false);
    const [nextScheduleDate, setNextScheduleDate] = useState(null);
    const [errorValidation, setErrorValidation] = useState({
        recipients: false,
        cc: false,
        recipientsMessage: null,
        ccMessage: null,
    });
    const [duplicateError, setDuplicateError] = useState({
        recipients: false,
        cc: false,
        recipientsMessage: null,
        ccMessage: null,
    });
    const [draftScheduleModal, setDraftScheduleModal] = useState(false);
    const [recordId, setRecordId] = useState(null);
    const [successDraftScheduleModal, setSuccessDraftScheduleModal] =
        useState(false);
    const [emailValidationError, setEmailValidationError] = useState({
        recipients: false,
        cc: false,
        recipientsMessage: null,
        ccMessage: null,
    });

    const org = JSON.parse(localStorage.getItem("org"));
    let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const showDraftSchedulModal = (flag, id) => {
        setDraftScheduleModal(flag);
        if (flag) {
            setRecordId(id);
        }
    };

    const toggleSuccessDraftScheduledModal = (flag) => {
        setSuccessDraftScheduleModal(flag);
        setTimeout(() => {
            setSuccessDraftScheduleModal(false);
        }, APP_CONSTANTS.MODAL_SHOW_TIME);
    };

    const onChangeMenu = (event, field) => {
        switch (event?.menu ? event?.menu : field) {
            case "type":
                let tempTypes = reportBuilder?.type;
                if (
                    tempTypes[0] === event?.value &&
                    (event?.value === "GMP" ||
                        event?.value === "SA" ||
                        event?.value === "NC" ||
                        event?.value === "AC")
                ) {
                    tempTypes = [];
                } else if (event?.value === "AC") {
                    tempTypes = [];
                    tempTypes?.push("AC");
                    tempTypes?.push("CA");
                    tempTypes?.push("PA");
                    tempTypes?.push("PRO");
                    tempTypes?.push("RCA");
                } else if (
                    event?.value === "CA" ||
                    event?.value === "PA" ||
                    event?.value === "PRO" ||
                    event?.value === "RCA"
                ) {
                    const excludedValues = ["NC", "GMP", "SA"];
                    const filteredArray = tempTypes?.filter(
                        (item) => !excludedValues.includes(item)
                    );
                    tempTypes = filteredArray;

                    let typeIndex = tempTypes?.indexOf(event?.value);
                    if (typeIndex >= 0) {
                        tempTypes?.splice(typeIndex, 1);
                    } else {
                        tempTypes?.push(event?.value);
                    }

                    if (tempTypes?.length === 4 && !tempTypes?.includes("AC")) {
                        tempTypes?.unshift("AC");
                    } else {
                        let mainIndex = tempTypes?.indexOf("AC");
                        if (mainIndex !== -1) {
                            tempTypes?.splice(mainIndex, 1);
                        }
                    }
                } else {
                    tempTypes = [];
                    tempTypes?.push(event?.value);
                }
                let tempRoless = reportBuilder?.roles;
                let rolesArr = userRoles;

                if (event?.value === "GMP") {
                    tempRoless = tempRoless?.filter((item) => !item.startsWith("aud"));
                    rolesArr = rolesArr?.filter((item) => !item.value?.startsWith("aud"));

                    tempRoless?.push("ins");
                    let ins = { label: "Inspector", value: "ins", child: [] };
                    usersByRole?.gmp_inspectors?.map((item) => {
                        ins?.child?.push({
                            label: item?.label,
                            value: "ins" + item?.value,
                        });
                        tempRoless?.push("ins" + item?.value);
                    });
                    rolesArr?.push(ins);
                } else if (event?.value === "SA") {
                    tempRoless = tempRoless?.filter((item) => !item.startsWith("ins"));
                    rolesArr = rolesArr?.filter((item) => !item.value?.startsWith("ins"));

                    tempRoless?.push("aud");
                    let aud = { label: "Auditor", value: "aud", child: [] };
                    usersByRole?.auditor?.map((item) => {
                        aud?.child?.push({
                            label: item?.label,
                            value: "aud" + item?.value,
                        });
                        tempRoless?.push("aud" + item?.value);
                    });
                    rolesArr?.push(aud);
                } else {
                    tempRoless = tempRoless?.filter((item) => !item.startsWith("aud"));
                    rolesArr = rolesArr?.filter((item) => !item.value?.startsWith("aud"));
                    tempRoless = tempRoless?.filter((item) => !item.startsWith("ins"));
                    rolesArr = rolesArr?.filter((item) => !item.value?.startsWith("ins"));
                }
                setReportBuilder((old) => {
                    return {
                        ...old,
                        type: tempTypes,
                        roles: tempRoless,
                    };
                });

                setUserRoles(rolesArr);
                if (tempTypes?.length <= 0) {
                    setError((old) => {
                        return {
                            ...old,
                            reportType: true,
                        };
                    });
                } else {
                    setError((old) => {
                        return {
                            ...old,
                            reportType: false,
                        };
                    });
                }
                break;
            case "source":
                let tempSources = reportBuilder?.source;

                let findIndex = tempSources?.indexOf(event?.value);
                if (findIndex >= 0) {
                    tempSources?.splice(findIndex, 1);
                } else {
                    tempSources?.push(event?.value);
                }

                setReportBuilder((old) => {
                    return {
                        ...old,
                        source: tempSources,
                    };
                });
                break;
            case "roles":
                let tempRoles = reportBuilder?.roles;
                if (
                    event?.value === "acc" ||
                    event?.value === "act" ||
                    event?.value === "ver" ||
                    event?.value === "ins" ||
                    event?.value === "aud"
                ) {
                    if (tempRoles?.includes(event.value)) {
                        tempRoles = tempRoles?.filter(
                            (item) => !item.startsWith(event.value)
                        );
                    } else {
                        tempRoles?.push(event?.value);
                        userRoles?.map((item) => {
                            if (item?.value === event.value) {
                                item?.child?.map((ch) => {
                                    tempRoles?.push(ch?.value);
                                });
                            }
                        });
                    }
                } else {
                    const prefix = event?.value.substring(0, 3);
                    let prefixIndex = tempRoles?.indexOf(prefix);
                    if (prefixIndex >= 0) {
                        tempRoles?.splice(prefixIndex, 1);
                    }
                    let rolesIndex = tempRoles?.indexOf(event?.value);
                    if (rolesIndex >= 0) {
                        tempRoles?.splice(rolesIndex, 1);
                    } else {
                        tempRoles?.push(event?.value);
                        const groupedValues = {};

                        tempRoles.forEach((value) => {
                            const prefix = value.substring(0, 3); // Adjust the substring length based on your prefixes
                            if (!groupedValues.hasOwnProperty(prefix)) {
                                groupedValues[prefix] = [];
                            }
                            groupedValues[prefix].push(value);
                        });

                        const { act, acc, ver, ins, aud } = groupedValues;
                        if (acc?.length === usersByRole?.accountable_manager?.length) {
                            tempRoles?.push("acc");
                        }
                        if (act?.length === usersByRole?.actioner?.length) {
                            tempRoles?.push("act");
                        }
                        if (ver?.length === usersByRole?.verifier?.length) {
                            tempRoles?.push("ver");
                        }
                        if (ins?.length === usersByRole?.gmp_inspectors?.length) {
                            tempRoles?.push("ins");
                        }
                        if (aud?.length === usersByRole?.auditor?.length) {
                            tempRoles?.push("aud");
                        }
                    }
                }

                let uniqueChars = tempRoles?.filter((c, index) => {
                    return tempRoles.indexOf(c) === index;
                });
                setReportBuilder((old) => {
                    return {
                        ...old,
                        roles: uniqueChars,
                    };
                });
                break;
            case "grade":
                let tempGrades = reportBuilder?.grade;
                let gradeIndex = tempGrades?.indexOf(event?.value);
                if (gradeIndex >= 0) {
                    tempGrades?.splice(gradeIndex, 1);
                } else {
                    tempGrades?.push(event?.value);
                }
                setReportBuilder((old) => {
                    return {
                        ...old,
                        grade: tempGrades,
                    };
                });
                break;
            case "topic":
                let tempTopics = reportBuilder?.topic;
                let topicIndex = tempTopics?.indexOf(event?.value);
                if (topicIndex >= 0) {
                    tempTopics?.splice(topicIndex, 1);
                } else {
                    tempTopics?.push(event?.value);
                }
                setReportBuilder((old) => {
                    return {
                        ...old,
                        topic: tempTopics,
                    };
                });
                break;
            case "area":
                let tempAreas = reportBuilder?.area;
                let areaIndex = tempAreas?.indexOf(event?.value);
                if (areaIndex >= 0) {
                    tempAreas?.splice(areaIndex, 1);
                } else {
                    tempAreas?.push(event?.value);
                }
                setReportBuilder((old) => {
                    return {
                        ...old,
                        area: tempAreas,
                    };
                });
                break;
            case "frequency":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        frequency: event,
                    };
                });
                break;
            case "startDay":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        startDay: event,
                    };
                });
                break;
            default:
        }
    };

    const deSelectAll = (menu) => {
        switch (menu) {
            case "roles":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        roles: [],
                    };
                });
                break;
            case "source":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        source: [],
                    };
                });
                break;
            case "topic":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        topic: [],
                    };
                });
                break;
            case "grade":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        grade: [],
                    };
                });
                break;
            case "area":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        area: [],
                    };
                });
                break;
            default:
        }
    };

    const selectAll = (menu) => {
        switch (menu) {
            case "roles":
                let defaultRoles = ["acc", "act", "ver"];

                usersByRole?.accountable_manager?.map((item) => {
                    defaultRoles?.push("acc" + item?.value);
                });
                usersByRole?.actioner?.map((item) => {
                    defaultRoles?.push("act" + item?.value);
                });
                usersByRole?.verifier?.map((item) => {
                    defaultRoles?.push("ver" + item?.value);
                });

                if (reportBuilder?.type[0] === "GMP") {
                    defaultRoles.push("ins");

                    usersByRole?.gmp_inspectors?.map((item) => {
                        defaultRoles?.push("ins" + item?.value);
                    });
                } else if (reportBuilder?.type[0] === "SA") {
                    defaultRoles.push("aud");

                    usersByRole?.gmp_inspectors?.map((item) => {
                        defaultRoles?.push("aud" + item?.value);
                    });
                }

                setReportBuilder((old) => {
                    return {
                        ...old,
                        roles: defaultRoles,
                    };
                });
                break;
            case "source":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        source: APP_CONSTANTS?.KPI_SOURCE_OPTIONS?.map(
                            (item) => item?.label
                        ),
                    };
                });
                break;
            case "topic":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        topic: APP_CONSTANTS?.TOPIC_OPTIONS?.map((item) => item?.label),
                    };
                });
                break;
            case "grade":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        grade: ["Critical", "Major", "Minor"],
                    };
                });
                break;
            case "area":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        area: parentGMP?.map((item) => item?.label),
                    };
                });
                break;
            default:
        }
    };

    const onChangeReportDetails = (field, value) => {
        switch (field) {
            case "report_name":
                setReportDetails((old) => {
                    return {
                        ...old,
                        reportName: value,
                    };
                });
                if (value && value !== "") {
                    setError((old) => {
                        return {
                            ...old,
                            report_name: false,
                        };
                    });
                } else {
                    setError((old) => {
                        return {
                            ...old,
                            report_name: true,
                        };
                    });
                }
                break;
            case "recipients":
                let recipientsCheck = false;
                let recipientArray = value.split(",");
                let cleanedRecipientArray = recipientArray.map(function (email) {
                    return email.toLowerCase().trim();
                });
                if (hasDuplicate(cleanedRecipientArray)) {
                    setDuplicateError((old) => {
                        return {
                            ...old,
                            recipients: true,
                            recipientsMessage: "You've already added this email.",
                        };
                    });
                } else {
                    setDuplicateError((old) => {
                        return {
                            ...old,
                            recipients: false,
                            recipientsMessage: null,
                        };
                    });
                }
                setReportDetails((old) => {
                    return {
                        ...old,
                        recipients: cleanedRecipientArray,
                    };
                });
                if (value && value !== "") {
                    setError((old) => {
                        return {
                            ...old,
                            recipients: false,
                        };
                    });
                } else {
                    setError((old) => {
                        return {
                            ...old,
                            recipients: true,
                        };
                    });
                }
                let isValidateRecipients = false;
                cleanedRecipientArray?.forEach((email) => {
                    if (!emailRegex.test(email)) {
                        recipientsCheck = true;
                    } else if (!validateEmail(email)) {
                        isValidateRecipients = true;
                    }
                });

                setErrorValidation((old) => {
                    return {
                        ...old,
                        recipients: recipientsCheck,
                        recipientsMessage: "Please enter the valid email address.",
                    };
                });

                if (isValidateRecipients) {
                    setEmailValidationError((old) => {
                        return {
                            ...old,
                            recipients: true,
                            recipientsMessage:
                                "Email doesn't exist. Please create a profile for this user.",
                        };
                    });
                    return;
                } else {
                    setEmailValidationError((old) => {
                        return {
                            ...old,
                            recipients: false,
                            recipientsMessage: null,
                        };
                    });
                }
                if (compareArrays(cleanedRecipientArray, reportDetails.cc)) {
                    setEmailValidationError((old) => {
                        return {
                            ...old,
                            recipients: false,
                            recipientsMessage: null,
                        };
                    });
                    setErrorValidation((old) => {
                        return {
                            ...old,
                            recipients: true,
                            recipientsMessage:
                                "You can't enter the same email address in recipients and cc.",
                        };
                    });
                    return;
                }
                break;
            case "cc":
                let ccCheck = false;
                let ccArray = value.split(",");
                let cleanedCcArray = ccArray.map(function (email) {
                    return email.toLowerCase().trim();
                });
                if (hasDuplicate(cleanedCcArray)) {
                    setDuplicateError((old) => {
                        return {
                            ...old,
                            cc: true,
                            ccMessage: "You've already added this email.",
                        };
                    });
                } else {
                    setDuplicateError((old) => {
                        return {
                            ...old,
                            cc: false,
                            ccMessage: null,
                        };
                    });
                }
                setReportDetails((old) => {
                    return {
                        ...old,
                        cc: cleanedCcArray,
                    };
                });
                let isValidateCc = false;
                cleanedCcArray?.forEach((email) => {
                    if (!emailRegex.test(email)) {
                        ccCheck = true;
                    } else if (!validateEmail(email)) {
                        isValidateCc = true;
                    }
                });
                if (value && value !== "") {
                    setErrorValidation((old) => {
                        return {
                            ...old,
                            cc: ccCheck,
                            ccMessage: "Please enter the valid email address.",
                        };
                    });
                } else {
                    setErrorValidation((old) => {
                        return {
                            ...old,
                            cc: false,
                            ccMessage: null,
                        };
                    });
                    setEmailValidationError((old) => {
                        return {
                            ...old,
                            cc: false,
                            ccMessage: null,
                        };
                    });
                }
                if (isValidateCc) {
                    setEmailValidationError((old) => {
                        return {
                            ...old,
                            cc: true,
                            ccMessage:
                                "Email doesn't exist. Please create a profile for this user.",
                        };
                    });
                    return;
                } else {
                    setEmailValidationError((old) => {
                        return {
                            ...old,
                            cc: false,
                            ccMessage: null,
                        };
                    });
                }
                if (compareArrays(reportDetails.recipients, cleanedCcArray)) {
                    setEmailValidationError((old) => {
                        return {
                            ...old,
                            cc: false,
                            ccMessage: null,
                        };
                    });
                    setErrorValidation((old) => {
                        return {
                            ...old,
                            cc: true,
                            ccMessage:
                                "You can't enter the same email address in recipients and cc.",
                        };
                    });
                    return;
                }
                break;
            default:
                break;
        }
    };

    const hasDuplicate = (arr) => {
        const valueMap = {};
        for (let i = 0; i < arr?.length; i++) {
            const currentValue = arr[i];
            if (valueMap[currentValue]) {
                return true;
            }
            valueMap[currentValue] = true;
        }
        return false;
    };

    const validateEmail = (email) => {
        let data = users?.map((user) => user?.email)?.indexOf(email);
        if (data >= 0) {
            return true;
        } else {
            return false;
        }
    };

    const compareArrays = (recipient, cc) => {
        const set = new Set(recipient);
        for (const element of cc) {
            if (set.has(element)) {
                return true;
            }
        }
        return false;
    };

    const scheduleReportApi = (type) => {
        setDisable(true);
        if (errorHandling()) return;

        let status;
        if (type === "save") {
            status = "Draft";
        } else {
            status = "Active";
        }

        let obj = {
            ...reportBuilder,
            ...reportDetails,
            status: status,
            org: org?._id,
        };
        request.post(SCHEDULED_REPORTS.CREATE_SCHEDULE, obj, true, false).then(
            (res) => {
                setNextScheduleDate(res?.data?.nextSchedule);
                if (type === "save") {
                    setModal((old) => {
                        return {
                            ...old,
                            save: true,
                        };
                    });
                    setTimeout(() => {
                        setModal((old) => {
                            return {
                                ...old,
                                save: false,
                            };
                        });
                        setDisable(false);
                        setReportDetails({
                            reportName: "",
                            recipients: [],
                            cc: [],
                        });
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                } else {
                    setModal((old) => {
                        return {
                            ...old,
                            schedule: true,
                        };
                    });
                    setTimeout(() => {
                        setModal((old) => {
                            return {
                                ...old,
                                schedule: false,
                            };
                        });
                        setDisable(false);
                        setReportDetails({
                            reportName: "",
                            recipients: [],
                            cc: [],
                        });
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            },
            (err) => {
                setModal((old) => {
                    return {
                        ...old,
                        error: true,
                    };
                });
                setErrorMessage(err?.message);
                setTimeout(() => {
                    setModal((old) => {
                        return {
                            ...old,
                            error: false,
                        };
                    });
                    setDisable(false);
                    setErrorMessage(null);
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };

    useEffect(() => {
        let tempObj = {
            label: "Others",
            value: "Others",
        };
        let arr = [];
        request.get(`${GMP.GET_PARENT_GMP}/${org?._id}`, true).then(
            (res) => {
                if (res?.status) {
                    arr = [...res?.data, tempObj];
                    setParentGMP(arr);
                    setReportBuilder((old) => {
                        return {
                            ...old,
                            area: arr?.map((item) => item?.label),
                        };
                    });
                }
            },
            (error) => { }
        );
    }, []);

    useEffect(() => {
        request
            .get(
                `${SCHEDULED_REPORTS.FREQUENCY}/${org?.subscription?.price_id}`,
                true
            )
            .then(
                (res) => {
                    setFrequency(res?.data);
                    setReportBuilder((old) => {
                        return {
                            ...old,
                            frequency: res?.data[0],
                        };
                    });
                },
                (error) => { }
            );
    }, []);

    useEffect(() => {
        if (!Object.keys(usersByRole).length) dispatch(getUsersByRoleApi());

        let defaultRoles = ["acc", "act", "ver"];

        let rolesArr = [];
        let acc = { label: "Accountable manager", value: "acc", child: [] };
        let act = { label: "Actioner", value: "act", child: [] };
        let ver = { label: "Verifier", value: "ver", child: [] };

        usersByRole?.accountable_manager?.map((item) => {
            acc?.child?.push({ label: item?.label, value: "acc" + item?.value });
            defaultRoles?.push("acc" + item?.value);
        });
        usersByRole?.actioner?.map((item) => {
            act?.child?.push({ label: item?.label, value: "act" + item?.value });
            defaultRoles?.push("act" + item?.value);
        });
        usersByRole?.verifier?.map((item) => {
            ver?.child?.push({ label: item?.label, value: "ver" + item?.value });
            defaultRoles?.push("ver" + item?.value);
        });

        rolesArr?.push(acc);
        rolesArr?.push(act);
        rolesArr?.push(ver);

        let tempReport = JSON.parse(JSON.stringify(APP_CONSTANTS.mainReport));

        let gmp = {
            label: "GMP inspections",
            value: "GMP",
            isSelected: false,
        };

        let sa = {
            label: "System audits",
            value: "SA",
            isSelected: false,
        };

        if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_2) {
            tempReport.push(gmp);
        } else if (
            org?.subscription?.price_id === process.env.REACT_APP_PLAN_3 ||
            org?.subscription?.price_id === process.env.REACT_APP_PLAN_4
        ) {
            tempReport.push(gmp);
            tempReport.push(sa);
        }
        setMainReports(tempReport);

        setReportBuilder((old) => {
            return {
                ...old,
                roles: defaultRoles,
            };
        });
        setUserRoles(rolesArr);
    }, [usersByRole]);

    useEffect(() => {
        dispatch(getScheduledReportsApi());
    }, [modal, successDraftScheduleModal]);

    useEffect(() => {
        dispatch(getAllUsersApi());
    }, []);

    const outSideClick = (src) => {
        let tempArr = outside;
        if (tempArr[tempArr.length - 1] === "parent" && src === "parent") {
            tempArr[0] = src;
        }
        tempArr.push(src);
        setOutside(tempArr);
        if (tempArr[0] !== "child") {
            setCurrentMenu(null);
            setOutside([]);
        }
    };

    const errorHandling = () => {
        let flag = false;
        if (reportBuilder?.type?.length <= 0) {
            setError((old) => {
                return {
                    ...old,
                    reportType: true,
                };
            });
            flag = true;
            setDisable(false);
        }
        if (reportDetails?.reportName === "") {
            setError((old) => {
                return {
                    ...old,
                    report_name: true,
                };
            });
            flag = true;
            setDisable(false);
        }
        if (reportDetails?.recipients?.length <= 0) {
            setError((old) => {
                return {
                    ...old,
                    recipients: true,
                };
            });
            flag = true;
            setDisable(false);
        }
        if (errorValidation?.recipients || errorValidation?.cc) {
            flag = true;
            setDisable(false);
        }
        if (duplicateError?.recipients || duplicateError?.cc) {
            flag = true;
            setDisable(false);
        }
        if (emailValidationError?.recipients || emailValidationError?.cc) {
            flag = true;
            setDisable(false);
        }
        return flag;
    };

    return (
        <div
            className="prog_info kpi scheduled-reports"
            onClick={() => {
                outSideClick("parent");
            }}
        >
            <h5 className="fs-18 color-05">
                <i className="far fa-cubes"></i> Report scheduler
            </h5>
            <div className="custom-grid-container">
                <div
                    onClick={() => {
                        outSideClick("child");
                    }}
                >
                    <FilterAction
                        options={mainReports}
                        menu="type"
                        data={reportBuilder?.type}
                        onChange={onChangeMenu}
                        label="What's in this report?"
                        deSelectAll={deSelectAll}
                        setCurrentMenu={setCurrentMenu}
                        currentMenu={currentMenu}
                    />
                    {error?.reportType && (
                        <small className="fs-12 text-danger">
                            Please select report type.
                        </small>
                    )}
                </div>
                {((reportBuilder?.type?.includes("NC") &&
                    reportBuilder?.source?.includes("GMP inspection")) ||
                    (reportBuilder?.type?.includes("CA") &&
                        reportBuilder?.source?.includes("GMP inspection")) ||
                    reportBuilder?.type?.includes("GMP")) &&
                    reportBuilder?.type?.length > 0 && (
                        <div
                            onClick={() => {
                                outSideClick("child");
                            }}
                        >
                            <FilterAction
                                options={parentGMP?.map((item) => {
                                    return { label: item?.label, value: item?.label };
                                })}
                                menu="area"
                                data={reportBuilder?.area}
                                onChange={onChangeMenu}
                                label="Area"
                                deSelectAll={deSelectAll}
                                setCurrentMenu={setCurrentMenu}
                                currentMenu={currentMenu}
                                selectAll={selectAll}
                            />
                        </div>
                    )}
                {!reportBuilder?.type?.includes("GMP") &&
                    !reportBuilder?.type?.includes("SA") &&
                    reportBuilder?.type?.length > 0 && (
                        <div
                            onClick={() => {
                                outSideClick("child");
                            }}
                        >
                            <FilterAction
                                options={APP_CONSTANTS?.KPI_SOURCE_OPTIONS}
                                menu="source"
                                data={reportBuilder?.source}
                                onChange={onChangeMenu}
                                label="Source"
                                deSelectAll={deSelectAll}
                                setCurrentMenu={setCurrentMenu}
                                currentMenu={currentMenu}
                                selectAll={selectAll}
                            />
                        </div>
                    )}
                {reportBuilder?.type?.length > 0 && (
                    <div>
                        <label className="color-04 fs-14 mb-1">Frequency</label>
                        <div>
                            <Select
                                className="w-100"
                                classNamePrefix="react-select-scheduled"
                                options={frequency}
                                value={reportBuilder?.frequency}
                                onChange={(data) => {
                                    onChangeMenu(data, "frequency");
                                }}
                                isDisabled={!utils.checkSubStatus()}
                            />
                        </div>
                    </div>
                )}
                {reportBuilder?.type?.length > 0 &&
                    reportBuilder?.frequency?.value === "Weekly" && (
                        <div>
                            <label className="color-04 fs-14 mb-1">Start day</label>
                            <div>
                                <Select
                                    className="w-100"
                                    classNamePrefix="react-select-scheduled"
                                    options={startDay}
                                    value={reportBuilder?.startDay}
                                    onChange={(data) => {
                                        onChangeMenu(data, "startDay");
                                    }}
                                    isDisabled={!utils.checkSubStatus()}
                                />
                            </div>
                        </div>
                    )}
                {reportBuilder?.type?.length > 0 && (
                    <div
                        onClick={() => {
                            outSideClick("child");
                        }}
                    >
                        <FilterAction
                            options={userRoles}
                            menu="roles"
                            data={reportBuilder?.roles}
                            onChange={onChangeMenu}
                            label="Roles"
                            deSelectAll={deSelectAll}
                            setCurrentMenu={setCurrentMenu}
                            currentMenu={currentMenu}
                            selectAll={selectAll}
                        />
                    </div>
                )}
                {(reportBuilder?.type?.includes("NC") ||
                    reportBuilder?.type?.includes("PA") ||
                    reportBuilder?.type?.includes("CA") ||
                    reportBuilder?.type?.includes("RCA")) &&
                    reportBuilder?.type?.length > 0 && (
                        <div
                            onClick={() => {
                                outSideClick("child");
                            }}
                        >
                            <FilterAction
                                options={APP_CONSTANTS?.grade}
                                menu="grade"
                                data={reportBuilder?.grade}
                                onChange={onChangeMenu}
                                label="Grade"
                                deSelectAll={deSelectAll}
                                setCurrentMenu={setCurrentMenu}
                                currentMenu={currentMenu}
                                selectAll={selectAll}
                            />
                        </div>
                    )}
                {(reportBuilder?.type?.includes("NC") ||
                    reportBuilder?.type?.includes("SA") ||
                    reportBuilder?.type?.includes("PA") ||
                    reportBuilder?.type?.includes("CA") ||
                    reportBuilder?.type?.includes("RCA")) &&
                    reportBuilder?.type?.length > 0 && (
                        <div
                            onClick={() => {
                                outSideClick("child");
                            }}
                        >
                            <FilterAction
                                options={APP_CONSTANTS?.TOPIC_OPTIONS}
                                menu="topic"
                                data={reportBuilder?.topic}
                                onChange={onChangeMenu}
                                label="Topic"
                                deSelectAll={deSelectAll}
                                setCurrentMenu={setCurrentMenu}
                                currentMenu={currentMenu}
                                selectAll={selectAll}
                            />
                        </div>
                    )}
            </div>
            <div className="my-4 report-name">
                <div className="row">
                    <div className="col-md-6 col-lg-3 mb-3">
                        <label className="color-04 fs-14 mb-1">Report name</label>
                        <input
                            type="text"
                            className="w-100 fs-14 fw-400 color-04"
                            value={reportDetails?.reportName}
                            onChange={(e) => {
                                onChangeReportDetails("report_name", e.target.value);
                            }}
                            disabled={!utils.checkSubStatus()}
                        />
                        <small className="fs-12 color-03">
                            This is only used for reference.
                        </small>
                        {error?.report_name && (
                            <p className="fs-12 text-danger mb-0">
                                Please write report name.
                            </p>
                        )}
                    </div>
                    <div className="col-md-6 col-lg-3 mb-3">
                        <div data-toggle="tooltip" title={reportDetails?.recipients}>
                            <label className="color-04 fs-14 mb-1">Recipients</label>
                            <input
                                type="email"
                                className="w-100 w-100 fs-14 fw-400 color-04"
                                value={reportDetails?.recipients}
                                onChange={(e) => {
                                    onChangeReportDetails("recipients", e.target.value);
                                }}
                                disabled={!utils.checkSubStatus()}
                            />
                        </div>
                        <small className="fs-12 color-03">
                            To add multiple email addresses just put a comma between each
                            email address.
                        </small>
                        {error?.recipients ? (
                            <p className="fs-12 text-danger mb-0">
                                Please enter the valid email address.
                            </p>
                        ) : errorValidation?.recipients ? (
                            <p className="fs-12 text-danger mb-0">
                                {errorValidation?.recipientsMessage}
                            </p>
                        ) : emailValidationError?.recipients ? (
                            <p className="fs-12 text-danger mb-0">
                                {emailValidationError?.recipientsMessage}
                            </p>
                        ) : null}
                        {duplicateError?.recipients && (
                            <p className="fs-12 text-danger mb-0">
                                {duplicateError?.recipientsMessage}
                            </p>
                        )}
                    </div>
                    <div className="col-md-6 col-lg-3 mb-3">
                        <div data-toggle="tooltip" title={reportDetails?.cc}>
                            <label className="color-04 fs-14 mb-1">CC'd</label>
                            <input
                                type="email"
                                className="w-100 w-100 fs-14 fw-400 color-04"
                                value={reportDetails?.cc}
                                onChange={(e) => {
                                    onChangeReportDetails("cc", e.target.value);
                                }}
                                disabled={!utils.checkSubStatus()}
                            />
                        </div>
                        {errorValidation?.cc ? (
                            <p className="fs-12 text-danger mb-0">
                                {errorValidation?.ccMessage}
                            </p>
                        ) : emailValidationError?.cc ? (
                            <p className="fs-12 text-danger mb-0">
                                {emailValidationError?.ccMessage}
                            </p>
                        ) : null}
                        {duplicateError?.cc && (
                            <p className="fs-12 text-danger mb-0">
                                {duplicateError?.ccMessage}
                            </p>
                        )}
                    </div>
                    <div className="col-md-6 col-lg-3 mb-3">
                        <div className="d-flex" style={{ paddingTop: "1.7rem" }}>
                            <button
                                className="btn-save"
                                onClick={() => {
                                    scheduleReportApi("save");
                                }}
                                disabled={
                                    !utils.checkSubStatus() ? !utils.checkSubStatus() : disable
                                }
                            >
                                <i className="far fa-save" /> Save
                            </button>
                            <button
                                className="btn-schedule"
                                onClick={() => {
                                    scheduleReportApi("schedule");
                                }}
                                disabled={
                                    !utils.checkSubStatus() ? !utils.checkSubStatus() : disable
                                }
                            >
                                <i className="far fa-calendar-check" /> Schedule
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="completion-gmp">
                <p className="mb-0 ml-2 color-05 fw-600">
                    <i className="far fa-calendar" /> Scheduled reports
                </p>
                <ScheduledReportsData
                    data={scheduleReportList}
                    showModal={showDraftSchedulModal}
                />
            </div>

            {modal?.save && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setModal((old) => {
                                return {
                                    ...old,
                                    save: false,
                                };
                            });
                            setDisable(false);
                            setReportDetails({
                                reportName: "",
                                recipients: [],
                                cc: [],
                            });
                        }}
                    ></div>
                    <SaveModal
                        id={"save"}
                        h2={APP_CONSTANTS.REPORT_SCHEDULER_SAVE.h2}
                        h3={APP_CONSTANTS.REPORT_SCHEDULER_SAVE.h3}
                        p={APP_CONSTANTS.REPORT_SCHEDULER_SAVE.p}
                    />
                </>
            )}

            {modal?.schedule && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setModal((old) => {
                                return {
                                    ...old,
                                    schedule: false,
                                };
                            });
                            setDisable(false);
                            setReportDetails({
                                reportName: "",
                                recipients: [],
                                cc: [],
                            });
                        }}
                    ></div>
                    <SaveModal
                        id={"schedule"}
                        h2={APP_CONSTANTS.REPORT_SCHEDULER_SCHEDULE.h2}
                        h2_sub={APP_CONSTANTS.REPORT_SCHEDULER_SCHEDULE.h2_sub}
                        h3={APP_CONSTANTS.REPORT_SCHEDULER_SCHEDULE.h3}
                        p={APP_CONSTANTS.REPORT_SCHEDULER_SCHEDULE.p}
                        nextSchedule={nextScheduleDate}
                    />
                </>
            )}

            {modal?.error && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setModal((old) => {
                                return {
                                    ...old,
                                    error: false,
                                };
                            });
                            setDisable(false);
                        }}
                    ></div>
                    <ErrorModal id={"error"} message={errorMessage} />
                </>
            )}

            {draftScheduleModal && (
                <DraftScheduleModal
                    id={"draft"}
                    h2={APP_CONSTANTS.DRAFT_SCHEDULER_UPDATE.h2}
                    setDraftScheduleModal={setDraftScheduleModal}
                    reportId={recordId}
                    toggle={toggleSuccessDraftScheduledModal}
                    date={setNextScheduleDate}
                />
            )}

            {successDraftScheduleModal && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setSuccessDraftScheduleModal(false);
                        }}
                    ></div>
                    <SaveModal
                        id={"schedule"}
                        h2={APP_CONSTANTS.REPORT_SCHEDULER_SCHEDULE.h2}
                        h2_sub={APP_CONSTANTS.REPORT_SCHEDULER_SCHEDULE.h2_sub}
                        h3={APP_CONSTANTS.REPORT_SCHEDULER_SCHEDULE.h3}
                        p={APP_CONSTANTS.REPORT_SCHEDULER_SCHEDULE.p}
                        nextSchedule={nextScheduleDate}
                    />
                </>
            )}
        </div>
    );
};

export default ScheduledReports;
