import React, { useEffect, useState } from "react";
import Searchbar from "../Home/TodoLIst/Searchbar";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import GmpInspectionModal from "./GmpInspectionModal";
import SaveModal from "../Modals/SaveModal";
import { APP_CONSTANTS } from "../../constants";
import ErrorModal from "../Modals/ErrorModal";
import {
    getGMPListAPi,
    toggleErrorModal,
    toggleSuccessModal,
    updateGMPObject,
} from "../../store/actions/gmp";
import FrequencyModal from "../Modals/FrequencyModal";
import utils from "../../utils";
import moment from "moment";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "25px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const GmpInspections = () => {
    const dispatch = useDispatch();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [toggleModal, setToggleModal] = useState(false);
    const [toggleAddGmp, setToggleAddGmp] = useState(false);
    const [frequencyModal, setFrequencyModal] = useState(false);

    const { success, error, modalToggle, list } = useSelector(
        (state) => state?.gmp
    );

    const toggleEditGmpModal = (flag) => {
        setToggleModal(flag);
    };

    const toggleAddGmpModal = (flag) => {
        setToggleAddGmp(flag);
    };

    const toggleFrequencyModal = (flag) => {
        setFrequencyModal(flag);
    };

    const columns = [
        {
            name: "GMP inspection",
            selector: (row) => row?.gmpName,
            width: "250px",
            cell: (row) => row?.gmpName,
            sortable: true,
        },
        {
            name: "Frequency",
            selector: (row) => row?.frequency,
            sortable: true,
            cell: (row) => row?.frequency,
        },
        {
            name: "Master inspection",
            selector: (row) => row?.template,
            sortable: true,
            cell: (row) => row?.template,
        },
        {
            name: "Notifications enabled",
            selector: (row) => (row?.isNotification ? "Yes" : "No"),
            sortable: true,
            cell: (row) => (row?.isNotification ? "Yes" : "No"),
        },
        {
            name: "Inspection paused",
            selector: (row) => (row?.isPause ? "Yes" : "No"),
            sortable: true,
            cell: (row) => (row?.isPause ? "Yes" : "No"),
        },
        {
            name: "Next Inspection",
            selector: (row) => moment(row?.nextInspection).format(APP_CONSTANTS?.DATE_FORMAT),
            sortable: true,
            cell: (row) => moment(row?.nextInspection).format(APP_CONSTANTS?.DATE_FORMAT),
        },
        {
            name: "Edit",
            width: "100px",
            cell: (row) =>
                utils.checkSubStatus() && (
                    <a
                        className="color-05 user-name-link cursor"
                        onClick={() => editGmp(row)}
                    >
                        Edit
                    </a>
                ),
        },
    ];

    const editGmp = (data) => {
        toggleEditGmpModal(true);
        dispatch(updateGMPObject(data));
    };

    const addGmp = () => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            toggleAddGmpModal(modalToggle);
        }
    };

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                list?.filter((item) => {
                    if (
                        item?.gmpName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        JSON.stringify(item?.frequency)
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.template?.toLowerCase().includes(searchValue?.toLowerCase())
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div style={{ width: "100%" }} className="mt-2 mt-sm-0 mb-2 mb-sm-0">
                <div className="row">
                    <div className="col-sm-6">
                        <button
                            className="add_gmp_ins_btn float-left mb-2"
                            onClick={addGmp}
                        >
                            <i className="far fa-plus-circle" /> Add GMP inspection
                        </button>
                    </div>
                    <div className="col-sm-6">
                        <Searchbar
                            onFilter={(e) => filterData(e.target.value)}
                            onClear={handleClear}
                            filterText={filterText}
                            type="gmp_inspections"
                        />
                    </div>
                </div>
            </div>
        );
    }, [filterText, filteredList]);

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataGmpIns = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataGmpIns);
    };

    useEffect(() => {
        dispatch(getGMPListAPi());
    }, [success]);

    useEffect(() => {
        setFilteredList([...list]);
    }, [list]);

    // function sortByKey(array, key, order) {
    //     if (key === "template") {
    //         return array.sort(function (a, b) {
    //             var x = parseInt(a[key].replace(/^\D+/g, ""));
    //             var y = parseInt(b[key].replace(/^\D+/g, ""));
    //             return order === "asc"
    //                 ? x < y
    //                     ? -1
    //                     : x > y
    //                         ? 1
    //                         : 0
    //                 : x > y
    //                     ? -1
    //                     : x < y
    //                         ? 1
    //                         : 0;
    //         });
    //     }
    //     return array.sort(function (a, b) {
    //         var x = a[key];
    //         var y = b[key];
    //         return order === "asc"
    //             ? x < y
    //                 ? -1
    //                 : x > y
    //                     ? 1
    //                     : 0
    //             : x > y
    //                 ? -1
    //                 : x < y
    //                     ? 1
    //                     : 0;
    //     });
    // }

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="gmp_inspections_wrapper">
            <DataTable
                columns={columns}
                data={filteredList?.length > 0 ? filteredList : []}
                customStyles={customStyles}
                striped
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            // sortFunction={(rows, field, direction) => {
            //     let resp;
            //     const str = `${field}`;
            //     const tempField = str.split(".").pop();
            //     resp = sortByKey(rows, tempField, `${direction}`);
            //     return resp;
            // }}
            />
            {toggleModal && (
                <GmpInspectionModal
                    toggleEditGmpModal={toggleEditGmpModal}
                    toggleFrequencyModal={toggleFrequencyModal}
                    type="edit"
                />
            )}
            {toggleAddGmp && (
                <GmpInspectionModal
                    toggleAddGmpModal={toggleAddGmpModal}
                    toggleFrequencyModal={toggleFrequencyModal}
                    type="add"
                />
            )}
            {frequencyModal && (
                <FrequencyModal
                    id={"frequency"}
                    h2={APP_CONSTANTS.FREQUENCY_MODAL.h2}
                    h3={APP_CONSTANTS.FREQUENCY_MODAL.h3}
                    calculate={APP_CONSTANTS.FREQUENCY_MODAL.Calculate}
                    toggleFrequencyModal={toggleFrequencyModal}
                />
            )}
            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                        }}
                    ></div>
                    <SaveModal
                        id={"gmp"}
                        h2={APP_CONSTANTS.CREATE_GMP_MESSAGE.h2}
                        h3={APP_CONSTANTS.CREATE_GMP_MESSAGE.h3}
                        p={APP_CONSTANTS.CREATE_GMP_MESSAGE.p}
                    />
                </>
            )}
            {error?.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal(false));
                        }}
                    ></div>
                    <ErrorModal error={error} />
                </>
            )}
        </div>
    );
};

export default GmpInspections;
