import React from 'react';

const CompE = () => {
    return (
        <>
            <h2 id="e">E</h2>
            <div className="accordion" id="accordionGlossary">
            <div className="card">
                <div className="card-header" id="heading102">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse102" aria-expanded="true"
                                aria-controls="collapse102">
                            Empathy
                        </button>
                    </h2>
                </div>
                <div id="collapse102" className="collapse show" aria-labelledby="heading102"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        The ability to be able to understand someone elses feelings.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading103">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse103" aria-expanded="false"
                                aria-controls="collapse103">
                            Enclosed product area
                        </button>
                    </h2>
                </div>
                <div id="collapse103" className="collapse" aria-labelledby="heading103"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        An area on site where materials are not open to the environment. Materials can be enclosed because it's inside equipment or because it's in packaging. Where it's in equipment, the equipment needs to be fully sealed in order for it to be classed as enclosed.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading104">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse104" aria-expanded="false"
                                aria-controls="collapse104">
                            Environment
                        </button>
                    </h2>
                </div>
                <div id="collapse104" className="collapse" aria-labelledby="heading104"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        Environment is a physical place. The storage or warehouse environment is the area where the material is stored. The factory environment is the facility in which the product is made. See also; environmental.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading105">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse105" aria-expanded="false"
                                    aria-controls="collapse105">
                                Environmental
                            </button>
                        </h2>
                    </div>
                    <div id="collapse105" className="collapse" aria-labelledby="heading105"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Environmental refers to the conditions of the environment. Therefore, the conditions in which surrounds the material. The environmental conditions for storage would include temperature. Manufacturing environmental conditions would also include the microbial load of the surfaces and the air in the area.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading106">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse106" aria-expanded="false"
                                    aria-controls="collapse106">
                                Environmental monitoring
                            </button>
                        </h2>
                    </div>
                    <div id="collapse106" className="collapse" aria-labelledby="heading106"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where environmental conditions are important to the material, such as temperature or even the cleanliness of the area - monitoring is required to check that the conditions are within specification.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading107">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse107" aria-expanded="false"
                                    aria-controls="collapse107">
                                EPD
                            </button>
                        </h2>
                    </div>
                    <div id="collapse107" className="collapse" aria-labelledby="heading107"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Existing product development
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading108">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse108" aria-expanded="false"
                                    aria-controls="collapse108">
                                Evidence
                            </button>
                        </h2>
                    </div>
                    <div id="collapse108" className="collapse" aria-labelledby="heading108"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Evidence proves that something is true or correct. You must be able to 'see' evidence, either in hard-copy form or digitally. Evidence is not someone's word or opinion.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading109">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse109" aria-expanded="false"
                                    aria-controls="collapse109">
                                Experience
                            </button>
                        </h2>
                    </div>
                    <div id="collapse109" className="collapse" aria-labelledby="heading109"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A skill that is gained through practically doing something.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading110">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse110" aria-expanded="false"
                                    aria-controls="collapse110">
                                Exposed areas of the body
                            </button>
                        </h2>
                    </div>
                    <div id="collapse110" className="collapse" aria-labelledby="heading110"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Somewhere on the body that is visible because it isn't covered by clothing or PPE. This term is typically used when talking about jewellery. 'Jewellery isn't permitted in exposed areas of the body' means that you're not allowed to wear jewellery that can be seen - as this means it could contaminate the product. If it can't be seen this implies that it's covered by clothing (e.g socks) and therefore isn't unlikely to be able to contaminate the product.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompE;