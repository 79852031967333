import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { APP_CONSTANTS } from "../../../constants";
import { getExtension } from "../../../utils/icons";
import videoIcon from "../../../Assets/images/file-video-regular.svg";

const NcView = () => {
  const uniqueReferences = new Set();
  const { object } = useSelector((state) => state?.nc);

  const openLinkInNewTab = (link) => {
    window.open(link);
  };

  let array = [];

  if (object?.source) {
    array.push(
      <dl className="row">
        <dt className="col-sm-4">Source</dt>
        <dd className="col-sm-8">{object?.source?.label}</dd>
      </dl>
    );
  }
  if (object?.audit) {
    array.push(
      <dl className="row">
        <dt className="col-sm-4">Audit</dt>
        <dd className="col-sm-8">{object?.audit}</dd>
      </dl>
    );
  }
  if (object?.gmp_text) {
    array.push(
      <dl className="row">
        <dt className="col-sm-4">GMP inspection</dt>
        <dd className="col-sm-8">{object?.gmp_text}</dd>
      </dl>
    );
  }
  if (object?.audit_text) {
    array.push(
      <dl className="row">
        <dt className="col-sm-4">System audit</dt>
        <dd className="col-sm-8">{object?.audit_text}</dd>
      </dl>
    );
  }
  if (object?.complaint_type) {
    array.push(
      <dl className="row align-items-center py-1">
        <dt className="col-sm-4">Complaint type</dt>
        <dd className="col-sm-8">{object?.complaint_type?.label}</dd>
      </dl>
    );
  }
  if (object?.complaint_type) {
    if (
      object?.complaint_type?.label === "Legal" &&
      object?.legal_type?.label !== "Other"
    ) {
      array.push(
        <dl className="row align-items-center py-1">
          <dt className="col-sm-4">Legal type</dt>
          <dd className="col-sm-8">{object?.legal_type?.label}</dd>
        </dl>
      );
    } else if (
      object?.complaint_type?.label === "Legal" &&
      object?.legal_type?.label === "Other" &&
      object?.custom_legal
    ) {
      array.push(
        <dl className="row align-items-center py-1">
          <dt className="col-sm-4">Custom Legal type</dt>
          <dd className="col-sm-8">{object?.custom_legal}</dd>
        </dl>
      );
    } else if (
      object?.complaint_type?.label === "Quality" &&
      object?.quality_type?.label !== "Other"
    ) {
      array.push(
        <dl className="row align-items-center py-1">
          <dt className="col-sm-4">Quality type</dt>
          <dd className="col-sm-8">{object?.quality_type?.label}</dd>
        </dl>
      );
    } else if (
      object?.complaint_type?.label === "Quality" &&
      object?.quality_type?.label === "Other" &&
      object?.custom_quality
    ) {
      array.push(
        <dl className="row align-items-center py-1">
          <dt className="col-sm-4">Custom Quality type</dt>
          <dd className="col-sm-8">{object?.custom_quality}</dd>
        </dl>
      );
    } else if (
      object?.complaint_type?.label === "Foreign body" &&
      object?.foreign_body_type?.label !== "Other"
    ) {
      array.push(
        <dl className="row align-items-center py-1">
          <dt className="col-sm-4">Foreign body type</dt>
          <dd className="col-sm-8">{object?.foreign_body_type?.label}</dd>
        </dl>
      );
    } else if (
      object?.complaint_type?.label === "Foreign body" &&
      object?.foreign_body_type?.label === "Other" &&
      object?.custom_foreign_body
    ) {
      array.push(
        <dl className="row align-items-center py-1">
          <dt className="col-sm-4">Custom Foreign body type</dt>
          <dd className="col-sm-8">{object?.custom_foreign_body}</dd>
        </dl>
      );
    } else if (
      object?.complaint_type?.label === "Microbial" &&
      object?.micro_type?.label !== "Other"
    ) {
      array.push(
        <dl className="row align-items-center py-1">
          <dt className="col-sm-4">Microbial type</dt>
          <dd className="col-sm-8">{object?.micro_type?.label}</dd>
        </dl>
      );
    } else if (
      object?.complaint_type?.label === "Microbial" &&
      object?.micro_type?.label === "Other" &&
      object?.custom_micro
    ) {
      array.push(
        <dl className="row align-items-center py-1">
          <dt className="col-sm-4">Custom Microbial type</dt>
          <dd className="col-sm-8">{object?.custom_micro}</dd>
        </dl>
      );
    }
  }
  if (object?.nc_product_type) {
    array.push(
      <dl className="row">
        <dt className="col-sm-4">NC product type</dt>
        <dd className="col-sm-8">{object?.nc_product_type?.label}</dd>
      </dl>
    );
  }
  if (object?.product_test_and_inspect_failure_type) {
    array.push(
      <dl className="row align-items-center py-1">
        <dt className="col-sm-4">Product test and inspection failure</dt>
        <dd className="col-sm-8">
          {object?.product_test_and_inspect_failure_type?.label}
        </dd>
      </dl>
    );
  }
  if (object?.topic) {
    array.push(
      <dl className="row align-items-center py-1">
        <dt className="col-sm-4">Topic</dt>
        <dd className="col-sm-8">{object?.topic?.label}</dd>
      </dl>
    );
  }
  if (object?.raised_by) {
    array.push(
      <dl className="row">
        <dt className="col-sm-4">Raised by</dt>
        <dd className="col-sm-8">{object?.raised_by?.label}</dd>
      </dl>
    );
  }
  if (object?.date_raised) {
    array.push(
      <dl className="row align-items-center py-1">
        <dt className="col-sm-4 text-truncate">Date raised</dt>
        <dd className="col-sm-8">
          {moment(object?.date_raised).format(APP_CONSTANTS.DATE_FORMAT)}
        </dd>
      </dl>
    );
  }
  if (object?.accountable_manager) {
    array.push(
      <dl className="row">
        <dt className="col-sm-4">Accountable manager</dt>
        <dd className="col-sm-8">{object?.accountable_manager?.label}</dd>
      </dl>
    );
  }
  if (object?.actioner) {
    array.push(
      <dl className="row">
        <dt className="col-sm-4">Delegated to</dt>
        <dd className="col-sm-8">{object?.actioner?.label}</dd>
      </dl>
    );
  }
  if (object?.verifier) {
    array.push(
      <dl className="row align-items-center py-1">
        <dt className="col-sm-4">Verifier</dt>
        <dd className="col-sm-8">{object?.verifier?.label}</dd>
      </dl>
    );
  }
  if (object?.status) {
    array.push(
      <dl className="row">
        <dt className="col-sm-4">Status</dt>
        <dd className="col-sm-8">{object?.status}</dd>
      </dl>
    );
  }
  if (object?.reference?.length > 0 && object?.isAutomatic) {
    array.push(
      <dl className="row">
        <dt className="col-sm-4">Reference</dt>
        <dd className="col-sm-8">
          {object?.reference
            ?.map((ref, index) => {
              if (ref && !uniqueReferences.has(ref.ref)) {
                uniqueReferences.add(ref.ref);
                return ref.ref;
              }
              return null;
            })
            .filter((ref) => ref !== null)
            .join(", ")}
        </dd>
      </dl>
    );
  }
  if (object) {
    array.push(
      <div className="note">
        <h2>Non-conformance notes</h2>
        {object?.question && <h2>Question Subtitle:</h2>}
        <p>{object?.question_subtitle}</p>
        {object?.question && <h2>Question:</h2>}
        {object?.question?.split("\n").map((item, index) => {
          return (
            <p key={index} className="mb-0">
              {item}
            </p>
          );
        })}

        {object?.question && <h2 className="mt-3">Notes:</h2>}
        <p>{object?.non_conformance_notes}</p>
        {object?.closeout_notes && object?.closeout_notes !== "false" && (
          <h2>Close Out Notes:</h2>
        )}
        {object?.closeout_notes && object?.closeout_notes !== "false" && (
          <p>{object?.closeout_notes}</p>
        )}
      </div>
    );
  }
  if (object?.evidence) {
    array.push(
      <div className="ref-evidence">
        <h2 className="fs-14 fw-600 color-04 my-3">Evidence</h2>
        <div className="row evidence-scroll">
          {object?.evidence?.map((ev, ia) => (
            <div className="col-sm-6" key={ia}>
              {ev.extension === "images" && ev?.path && (
                <img
                  className="cursor"
                  loading="lazy"
                  src={`${ev?.path}`}
                  onClick={() => {
                    openLinkInNewTab(ev?.path);
                  }}
                  alt="Evidence"
                />
              )}
              {ev.extension === "videos" && ev?.path && (
                // <video
                //   className="cursor"
                //   onClick={() => {
                //     openLinkInNewTab(ev?.path);
                //   }}
                //   width="49"
                //   src={`${ev?.path}#t=0.1`}
                //   alt="Evidence"
                // />
                <img
                  className="cursor"
                  loading="lazy"
                  onClick={() => {
                    openLinkInNewTab(ev?.path);
                  }}
                  src={videoIcon}
                  alt="Evidence"
                />
              )}
              {ev.extension === "files" && ev?.path && (
                <img
                  className="cursor"
                  loading="lazy"
                  onClick={() => {
                    openLinkInNewTab(ev?.path);
                  }}
                  src={getExtension(ev?.path)}
                  alt="Evidence"
                />
              )}
              <span>{ev?.custom_name || ev?.originalname || ev?.title}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
  if (object?.closeOutEvidence?.length > 0) {
    array.push(
      <div className="ref-evidence">
        <h2 className="fs-14 fw-600 color-04 my-3">Close Out Evidence</h2>
        <div className="row evidence-scroll">
          {object?.closeOutEvidence?.map((ev, ia) => (
            <div className="col-sm-6" key={ia}>
              {ev.extension === "images" && ev?.path && (
                <img
                  className="cursor"
                  loading="lazy"
                  src={`${ev?.path}`}
                  onClick={() => {
                    openLinkInNewTab(ev?.path);
                  }}
                  alt="Evidence"
                />
              )}
              {ev.extension === "videos" && ev?.path && (
                // <video
                //   className="cursor"
                //   onClick={() => {
                //     openLinkInNewTab(ev?.path);
                //   }}
                //   width="49"
                //   src={`${ev?.path}#t=0.1`}
                //   alt="Evidence"
                // />
                <img
                  className="cursor"
                  loading="lazy"
                  onClick={() => {
                    openLinkInNewTab(ev?.path);
                  }}
                  src={videoIcon}
                  alt="Evidence"
                />
              )}
              {ev.extension === "files" && ev?.path && (
                <img
                  className="cursor"
                  loading="lazy"
                  onClick={() => {
                    openLinkInNewTab(ev?.path);
                  }}
                  src={getExtension(ev?.path)}
                  alt="Evidence"
                />
              )}
              <span>{ev?.custom_name || ev?.originalname || ev?.title}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
  if (object?.grade) {
    array.push(
      <dl className="row mb-0">
        <dt className="col-sm-4">Grading result</dt>
        <dd className="col-sm-8">{object?.grade}</dd>
      </dl>
    );
  }

  return (
    <>
      <div className="col-12 col-lg-6 mt-3">
        {array.map((item, index) => {
          if (index % 2 === 0) {
            return <div className="px-2">{item}</div>;
          } else {
            return <div className="light--bg px-2">{item}</div>;
          }
        })}
        {/* <div className="px-2">
                    <dl className="row">
                        <dt className="col-sm-4">Source</dt>
                        <dd className="col-sm-8">{object?.source?.label}</dd>
                    </dl>
                </div>
                {object?.audit && (
                    <div className="px-2">
                        <dl className="row">
                            <dt className="col-sm-4">Audit</dt>
                            <dd className="col-sm-8">{object?.audit}</dd>
                        </dl>
                    </div>
                )}
                {object?.gmp_text && (
                    <div className="px-2">
                        <dl className="row">
                            <dt className="col-sm-4">GMP inspection</dt>
                            <dd className="col-sm-8">{object?.gmp_text}</dd>
                        </dl>
                    </div>
                )}
                {object?.complaint_type && (
                    <div className="light--bg px-2">
                        <dl className="row align-items-center py-1">
                            <dt className="col-sm-4">Complaint type</dt>
                            <dd className="col-sm-8">{object?.complaint_type?.label}</dd>
                        </dl>
                    </div>
                )}
                {object?.custom_foreign_body && (
                    <div className="px-2">
                        <dl className="row">
                            <dt className="col-sm-4">Custom foreign body</dt>
                            <dd className="col-sm-8">{object?.custom_foreign_body?.label}</dd>
                        </dl>
                    </div>
                )}
                {object?.nc_product_type && (
                    <div className="px-2">
                        <dl className="row">
                            <dt className="col-sm-4">NC product type</dt>
                            <dd className="col-sm-8">{object?.nc_product_type?.label}</dd>
                        </dl>
                    </div>
                )}
                {object?.product_test_and_inspect_failure_type && (
                    <div className="light--bg px-2">
                        <dl className="row align-items-center py-1">
                            <dt className="col-sm-4">Product test and inspection failure</dt>
                            <dd className="col-sm-8">
                                {object?.product_test_and_inspect_failure_type?.label}
                            </dd>
                        </dl>
                    </div>
                )}
                <div className="light--bg px-2">
                    <dl className="row align-items-center py-1">
                        <dt className="col-sm-4">Topic</dt>
                        <dd className="col-sm-8">{object?.topic?.label}</dd>
                    </dl>
                </div>
                <div className="px-2">
                    <dl className="row">
                        <dt className="col-sm-4">Raised by</dt>
                        <dd className="col-sm-8">{object?.raised_by?.label}</dd>
                    </dl>
                </div>
                <div className="light--bg px-2">
                    <dl className="row align-items-center py-1">
                        <dt className="col-sm-4 text-truncate">Date raised</dt>
                        <dd className="col-sm-8">
                            {moment(object?.date_raised).format(APP_CONSTANTS.DATE_FORMAT)}
                        </dd>
                    </dl>
                </div>
                <div className="px-2">
                    <dl className="row">
                        <dt className="col-sm-4">Accountable manager</dt>
                        <dd className="col-sm-8">{object?.accountable_manager?.label}</dd>
                    </dl>
                </div>
                <div className="px-2">
                    <dl className="row">
                        <dt className="col-sm-4">Delegated to</dt>
                        <dd className="col-sm-8">{object?.actioner?.label}</dd>
                    </dl>
                </div>
                <div className="light--bg px-2">
                    <dl className="row align-items-center py-1">
                        <dt className="col-sm-4">Verifier</dt>
                        <dd className="col-sm-8">{object?.verifier?.label}</dd>
                    </dl>
                </div>
                <div className="px-2">
                    <dl className="row">
                        <dt className="col-sm-4">Status</dt>
                        <dd className="col-sm-8">{object?.status}</dd>
                    </dl>
                </div>
                <div className="note light--bg p-2">
                    <h2>Non-conformance notes</h2>
                    {object?.question && <h2>Question Subtitle:</h2>}
                    <p>{object?.question_subtitle}</p>
                    {object?.question && <h2>Question:</h2>}
                    {object?.question?.split("\n").map((item, index) => {
                        return (
                            <p key={index} className="mb-0">
                                {item}
                            </p>
                        );
                    })}

                    {object?.question && <h2 className="mt-3">Notes:</h2>}
                    <p>{object?.non_conformance_notes}</p>
                    {(object?.closeout_notes && object?.closeout_notes !== "false") && <h2>Close Out Notes:</h2>}
                    {(object?.closeout_notes && object?.closeout_notes !== "false") && <p>{object?.closeout_notes}</p>}
                </div>
                <div className="ref-evidence">
                    <h2 className="fs-14 fw-600 color-04 my-3">Evidence</h2>
                    <div className="row evidence-scroll">
                        {object?.evidence?.map((ev, ia) => (
                            <div className="col-sm-6" key={ia}>
                                {ev.extension === "images" && ev?.path && (
                                    <img
                                        src={`${ev?.path}`}
                                        onClick={() => {
                                            openLinkInNewTab(ev?.path);
                                        }}
                                        alt="Evidence"
                                    />
                                )}
                                {ev.extension === "videos" && ev?.path && (
                                    <video
                                        className="cursor"
                                        onClick={() => {
                                            openLinkInNewTab(ev?.path);
                                        }}
                                        width="49"
                                        src={`${ev?.path}#t=0.1`}
                                        alt="Evidence"
                                    />
                                )}
                                {ev.extension === "files" && ev?.path && (
                                    <img
                                        className="cursor"
                                        onClick={() => {
                                            openLinkInNewTab(ev?.path);
                                        }}
                                        src={getExtension(ev?.path)}
                                        alt="Evidence"
                                    />
                                )}
                                <span>{ev?.custom_name || ev?.originalname}</span>
                            </div>
                        ))}
                    </div>
                </div>
                {object?.closeOutEvidence?.length > 0 && (
                    <div className="ref-evidence">
                        <h2 className="fs-14 fw-600 color-04 my-3">Close Out Evidence</h2>
                        <div className="row evidence-scroll">
                            {object?.closeOutEvidence?.map((ev, ia) => (
                                <div className="col-sm-6" key={ia}>
                                    {ev.extension === "images" && ev?.path && (
                                        <img
                                            src={`${ev?.path}`}
                                            onClick={() => {
                                                openLinkInNewTab(ev?.path);
                                            }}
                                            alt="Evidence"
                                        />
                                    )}
                                    {ev.extension === "videos" && ev?.path && (
                                        <video
                                            className="cursor"
                                            onClick={() => {
                                                openLinkInNewTab(ev?.path);
                                            }}
                                            width="49"
                                            src={`${ev?.path}#t=0.1`}
                                            alt="Evidence"
                                        />
                                    )}
                                    {ev.extension === "files" && ev?.path && (
                                        <img
                                            className="cursor"
                                            onClick={() => {
                                                openLinkInNewTab(ev?.path);
                                            }}
                                            src={getExtension(ev?.path)}
                                            alt="Evidence"
                                        />
                                    )}
                                    <span>{ev?.custom_name || ev?.originalname}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="ref-result mt-3 light--bg py-2">
                    <dl className="row mb-0">
                        <dt className="col-sm-4">Grading result</dt>
                        <dd className="col-sm-8">{object?.grade}</dd>
                    </dl>
                </div> */}
      </div>
    </>
  );
};

export default NcView;
