import { TAB_STATE } from "../constants";

const reducer = (state = { tab: 1, settingTab: 1 }, { type, payload }) => {
    switch (type) {
        case TAB_STATE.CHANGE_TAB:
            return { tab: payload, settingTab: 1 };

        case TAB_STATE.SETTINGS_CHANGE_TAB:
            return { settingTab: payload, tab: 1 };
        default:
            return state;
    }
};

export default reducer;
