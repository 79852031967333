import React, { useState } from "react";
import request from "../../services/request";
import { SCHEDULED_REPORTS } from "../../Routes/Api";
import { APP_CONSTANTS } from "../../constants";

const DraftScheduleModal = (props) => {
    const { id, h2, setDraftScheduleModal, reportId, toggle, date } = props;

    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [response, setResponse] = useState({
        success: false,
        error: false,
        successMessage: null,
        errorMessage: null,
    });

    const updateScheduleStatus = () => {
        setLoading(true);
        setDisable(true);
        request
            .put(`${SCHEDULED_REPORTS.DRAFT_SCHEDULE}/${reportId}`, {}, true, false)
            .then(
                (res) => {
                    date(res?.data?.nextSchedule);
                    setLoading(false);
                    setDraftScheduleModal(false);
                    toggle(true);
                    // setResponse({ success: true, successMessage: res?.message });
                    // setTimeout(() => {
                    //     setDisable(false);
                    //     setDraftScheduleModal(false);
                    //     setResponse({ success: true, successMessage: res?.message });
                    // }, APP_CONSTANTS.MODAL_SHOW_TIME);
                },
                (error) => {
                    setLoading(false);
                    setResponse({ error: true, errorMessage: error?.message });
                    setTimeout(() => {
                        setDisable(false);
                        setResponse({ error: false, errorMessage: null });
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            );
    };

    return (
        <div className="save-modal draft_schedule">
            <div
                className="modal fade show"
                id={`${"SaveModal"}-${id}`}
                tabIndex="-1"
                aria-labelledby="SaveModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body px-5 py-4">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center text-lg-left">
                                    <i
                                        className="far fa-calendar-check"
                                        style={{ fontSize: "200px", color: "#707070" }}
                                    ></i>
                                </div>
                                <div className="col-12 col-lg-8 px-4 pt-5 pt-lg-0">
                                    <h2 className="color-03 fs-24 fw-600 text-center mt-3 mb-5">
                                        {h2}
                                    </h2>
                                    {response?.success && (
                                        <div className="alert alert-success mt-0 mb-0" role="alert">
                                            {response?.successMessage}
                                        </div>
                                    )}
                                    {response?.error && (
                                        <div className="alert alert-danger mt-0 mb-0" role="alert">
                                            {response?.errorMessage}
                                        </div>
                                    )}
                                    <div className="text-center mt-2">
                                        <button
                                            className="cancel mr-2 py-2 px-4 fs-16"
                                            onClick={() => setDraftScheduleModal(false)}
                                            disabled={disable}
                                        >
                                            No, Cancel
                                        </button>
                                        <button
                                            className="add_remove py-2 px-4 fs-16"
                                            onClick={updateScheduleStatus}
                                            disabled={disable}
                                        >
                                            Yes, Schedule {" "}
                                            {loading && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    style={{ marginBottom: "2px" }}
                                                ></div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default DraftScheduleModal;
