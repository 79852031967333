import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createUserAPi,
    toggleErrorModal,
    toggleSuccessModal,
    updateCreateUserObject,
} from "../../store/actions/user";
import { changeTabState } from "../../store/actions/tabState";
import SaveModal from "../Modals/SaveModal";
import ErrorModal from "../Modals/ErrorModal";
import { APP_CONSTANTS } from "../../constants";
import { USER_ACTION_CONSTANTS } from "../../store/constants";
import utils from "../../utils";

const CreateUser = () => {
    const dispatch = useDispatch();
    const { error } = useSelector((state) => state?.user);
    const showSuccessModal = useSelector((state) => state?.user?.success);
    const [createUser, setCreateUser] = useState(
        USER_ACTION_CONSTANTS.CREATE_OBJECT()
    );

    const handledFieldChanges = (field, value, custom) => {
        let userObjCopy = createUser;
        if (!custom) {
            userObjCopy[field] = value;

            dispatch(updateCreateUserObject(userObjCopy));
            return;
        }
        switch (field) {
            case "disable_user":
                if (!userObjCopy[field] || userObjCopy[field] === "on")
                    userObjCopy[field] = true;
                else userObjCopy[field] = !userObjCopy[field];
                break;
            case "proactive_action":
                userObjCopy["permissions"]["default_user_roles"][field] =
                    !userObjCopy["permissions"]["default_user_roles"];
                break;
            case "corrective_action":
                userObjCopy["permissions"]["default_user_roles"][field] =
                    !userObjCopy["permissions"]["default_user_roles"][field];
                break;
            case "system_manager":
                userObjCopy["permissions"]["general"][field] =
                    !userObjCopy["permissions"]["general"][field];
                break;
            case "accounts":
                userObjCopy["permissions"]["general"][field] =
                    !userObjCopy["permissions"]["general"][field];
                break;
            case "root_cause_analysis":
                userObjCopy["permissions"]["actions"][field] =
                    !userObjCopy["permissions"]["actions"][field];
                break;
            case "verifier":
                userObjCopy["permissions"]["actions"][field] =
                    !userObjCopy["permissions"]["actions"][field];
                break;
            case "identifier":
                userObjCopy["permissions"]["actions"][field] =
                    !userObjCopy["permissions"]["actions"][field];
                break;
            case "account_manager":
                userObjCopy["permissions"]["system_audits"][field] =
                    !userObjCopy["permissions"]["system_audits"][field];
                break;
            case "auditor":
                userObjCopy["permissions"]["system_audits"][field] =
                    !userObjCopy["permissions"]["system_audits"][field];
                break;
            case "accountable_manager":
                userObjCopy["permissions"]["gmp_inspections"][field] =
                    !userObjCopy["permissions"]["gmp_inspections"][field];
                break;
            case "inspector":
                userObjCopy["permissions"]["gmp_inspections"][field] =
                    !userObjCopy["permissions"]["gmp_inspections"][field];
                break;
            default:
        }
        setCreateUser(userObjCopy);
        // dispatch(updateCreateUserObject(userObjCopy));
    };

    const handleSave = () => {
        dispatch(createUserAPi(createUser));
    };

    const handleCancel = () => {
        dispatch(
            updateCreateUserObject(
                JSON.parse(JSON.stringify(USER_ACTION_CONSTANTS.CREATE_OBJECT()))
            )
        );
        dispatch(changeTabState(1));
    };

    // useEffect(() => {
    //     // dispatch(
    //     //     updateCreateUserObject(
    //     //         JSON.parse(JSON.stringify(USER_ACTION_CONSTANTS.CREATE_OBJECT()))
    //     //     )
    //     // );
    // }, [createUser]);

    return (
        <section className="ce-user">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 mt-4">
                        <div className="form-group row pr-0 mr-0">
                            <label className="col-12 col-lg-2 col-form-label fs-14 fw-600 color-05">
                                Name
                            </label>
                            <div className="col-12 col-lg-10">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id=""
                                    placeholder="Name"
                                    value={createUser?.user_name}
                                    onChange={(e) =>
                                        handledFieldChanges("user_name", e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row light-bg pr-0 mr-0">
                            <label className="col-2 col-lg-2 col-form-label fs-14 fw-600 color-05">
                                Email
                            </label>
                            <div className="col-12 col-lg-10">
                                <input
                                    required
                                    type="email"
                                    className="form-control form-control-sm"
                                    id=""
                                    placeholder="name@yourbusiness.com"
                                    value={createUser?.email}
                                    onChange={(e) =>
                                        handledFieldChanges(
                                            "email",
                                            e.target.value.toLocaleLowerCase()
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-12 col-lg-6 mt-4 light-bg"
                        style={{ padding: "10px 15px" }}
                    >
                        <h2 className="fs-14 fw-600 color-05">Default user roles</h2>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck1"
                                        disabled={true}
                                        defaultChecked={
                                            createUser?.permissions?.default_user_roles
                                                .proactive_action
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck1"
                                    >
                                        Proactive actions
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="custom-control custom-checkbox mr-3 mr-lg-0">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck2"
                                        disabled={true}
                                        defaultChecked={
                                            createUser?.permissions?.default_user_roles
                                                .corrective_action
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck2"
                                    >
                                        Non-conformance actions
                                    </label>
                                </div>
                            </div>
                        </div>
                        <small className="float-right mt-3 color-04 fs-11 fw-400 mr-3 mr-lg-0">
                            FYI - Everyone gets these default roles, so they can't be changed.
                        </small>
                    </div>
                </div>
                {/*  Row End  */}
                <div className="row mt-5 ce-select-options">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 className="fs-14 fw-600 color-04">General</h2>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck3"
                                        defaultChecked={
                                            createUser?.permissions?.general.system_manager
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges(
                                                "system_manager",
                                                e.target.value,
                                                true
                                            )
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck3"
                                    >
                                        System manager
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck4"
                                        defaultChecked={createUser?.permissions?.general.accounts}
                                        onChange={(e) =>
                                            handledFieldChanges("accounts", e.target.value, true)
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck4"
                                    >
                                        Accounts
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-4 mt-lg-0">
                                <h2 className="fs-14 fw-600 color-04">
                                    Non-conformance actions
                                </h2>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck12"
                                        defaultChecked={createUser?.permissions?.actions.identifier}
                                        onChange={(e) =>
                                            handledFieldChanges("identifier", e.target.value, true)
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck12"
                                    >
                                        Identifier
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck5"
                                        defaultChecked={
                                            createUser?.permissions?.actions.root_cause_analysis
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges(
                                                "root_cause_analysis",
                                                e.target.value,
                                                true
                                            )
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck5"
                                    >
                                        Root cause analysis
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck6"
                                        defaultChecked={createUser?.permissions?.actions.verifier}
                                        onChange={(e) =>
                                            handledFieldChanges("verifier", e.target.value, true)
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck6"
                                    >
                                        Verifier
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4 mt-lg-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 className="fs-14 fw-600 color-04">System audits</h2>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck7"
                                        defaultChecked={
                                            createUser?.permissions?.system_audits.account_manager
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges(
                                                "account_manager",
                                                e.target.value,
                                                true
                                            )
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck7"
                                    >
                                        Accountable manager
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck8"
                                        defaultChecked={
                                            createUser?.permissions?.system_audits.auditor
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges("auditor", e.target.value, true)
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck8"
                                    >
                                        Auditor
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-4 mt-lg-0">
                                <h2 className="fs-14 fw-600 color-04">GMP inspections</h2>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck9"
                                        defaultChecked={
                                            createUser?.permissions?.gmp_inspections
                                                .accountable_manager
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges(
                                                "accountable_manager",
                                                e.target.value,
                                                true
                                            )
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck9"
                                    >
                                        Accountable manager
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck10"
                                        defaultChecked={
                                            createUser?.permissions?.gmp_inspections.inspector
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges("inspector", e.target.value, true)
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck10"
                                    >
                                        Inspector
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Row End  */}
                <div className="row">
                    <div
                        className="col-12 col-lg-6 offset-lg-6 mt-4 light-bg"
                        style={{ padding: "10px 15px" }}
                    >
                        <h2 className="fs-14 fw-600 color-05">Disable this user</h2>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <p className="fs-11 fw-400 color-04">
                                    If you disable this user, they will no longer be able to login
                                    to audIT.app, receive notifications or be allocated actions.
                                </p>
                            </div>
                            <div className="col-12 col-lg-3 offset-lg-3">
                                <div className="custom-control custom-checkbox mr-3 mr-lg-0">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck11"
                                        defaultChecked={createUser?.disable_user}
                                        onChange={(e) =>
                                            handledFieldChanges("disable_user", e.target.value, true)
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck11"
                                    >
                                        Disable user{" "}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {utils.checkSubscription() && (
                <div className="float-right mt-4">
                    <button className="btn-send mr-2" onClick={handleCancel}>
                        <i className="fal fa-window-close" /> Cancel
                    </button>
                    <button className="btn-save mr-1" onClick={handleSave}>
                        <i className="far fa-save" /> Save
                    </button>
                </div>
            )}
            {showSuccessModal && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                            window.location.href = "/private/users";
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.CREATE_USER_MESSAGE.h2}
                        h3={APP_CONSTANTS.CREATE_USER_MESSAGE.h3}
                        p={APP_CONSTANTS.CREATE_USER_MESSAGE.p}
                    ></SaveModal>
                </>
            )}
            {error.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal({ show: false }));
                        }}
                    ></div>
                    <ErrorModal error={error}></ErrorModal>
                </>
            )}
        </section>
    );
};

export default CreateUser;
