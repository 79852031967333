import { GMP_TEMPLATE } from "../constants";

const reducer = (
  state = { list: [], error: {}, success: {} },
  { type, payload }
) => {
  switch (type) {
    case GMP_TEMPLATE.GET_GMP_TEMPLATE:
      return payload;
      break;
    case GMP_TEMPLATE.GET_GMP_TEMPLATE_SUCCESS:
      return { list: payload };
      break;
    case GMP_TEMPLATE.GET_GMP_TEMPLATE_ERROR:
      return payload;
      break;
    default:
      return state;
  }
};

export default reducer;
