import React, { useCallback, useEffect, useRef, useState } from "react";
import request from "../../services/request";
import { ORG, STRIPE, USER } from "../../Routes/Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StripeWidget from "./StripeWidget";
import Select from "react-select";
import countries from "../../countries/counties";
import CryptoJS from "crypto-js";
import { APP_CONSTANTS } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
    getSubsPlanByIdApi,
    setPlanObject,
    sestDiscountObject,
} from "../../store/actions/plan";
import utils from "../../utils";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

const coreStandard = APP_CONSTANTS.CORE_STANDARDS;
const CheckoutForm = () => {
    const cardElement = useRef();
    const stripe = useStripe();
    const elements = useElements();
    const { state } = useLocation();
    let navigate = useNavigate();
    const params = useParams();
    let dispatch = useDispatch();
    let timer;
    const waitTime = 3000;
    const planObj = useSelector((state) => state?.plan?.object);
    const { discountObj } = useSelector((state) => state?.plan);
    const [coupon, setCoupon] = useState();
    const [couponData, setCouponData] = useState();
    const [standardErr, setStandardErr] = useState(false);
    const [cardValue, setCardValue] = useState(false);
    const [cardErr, setCardErr] = useState(false);
    const [object, setObject] = useState({
        payment_method: "card",
        user: { userType: "admin" },
        org: {
            agreed_with_terms_and_cond: false,
            country: { label: "United Kingdom", value: "GB" },
        },
        bank: {},
        card: {},
        coupon: null,
        verifyButton: true,
        discountError: false,
        show_custom_error: {},
        stripe: {},
    });
    let discount = 0;
    let amount = planObj?.payAbleAmount;

    if (discountObj && discountObj.status) {
        discount = (planObj?.payAbleAmount * discountObj.data.percent_off) / 100;
        amount = planObj?.payAbleAmount - discount;
    }

    useEffect(() => {
        if (couponData && couponData.status) {
            discount = (planObj?.payAbleAmount * couponData.data.percent_off) / 100;
        }
    }, [coupon, couponData]);

    const handleCouponField = (field, value, custom) => {
        if (value || value !== "") {
            setObject((old) => {
                return {
                    ...old,
                    discountError: false,
                    verifyButton: false,
                };
            });
        } else {
            setObject((old) => {
                return {
                    ...old,
                    discountError: false,
                    verifyButton: true,
                };
            });
        }

        if (!custom) {
            return setCoupon((old) => {
                return value;
            });
        }
    };

    const checkCoupon = async (value) => {
        setObject((old) => {
            return {
                ...old,
                discountLoader: true,
                verifyButton: true,
                discountError: false,
            };
        });

        await request
            .get(`${STRIPE.CHECK_COUPON}${value}`, false)
            .then((res) => {
                if (res.status) {
                    setCouponData((old) => {
                        return res;
                    });
                    dispatch(sestDiscountObject(res));
                    return setObject((old) => {
                        return {
                            ...old,
                            coupon: value,
                            discountLoader: false,
                            verifyButton: false,
                            discountError: false,
                        };
                    });
                } else {
                    setCouponData((old) => {
                        return res;
                    });
                    dispatch(sestDiscountObject(res));

                    return setObject((old) => {
                        return {
                            ...old,
                            discountLoader: false,
                            verifyButton: false,
                            discountError: true,
                        };
                    });
                }
            })
            .catch((err) => {
                setCouponData((old) => {
                    return err;
                });
                dispatch(sestDiscountObject(err));

                return setObject((old) => {
                    return {
                        ...old,
                        discountLoader: false,
                        verifyButton: false,
                        discountError: true,
                    };
                });
            });
    };

    const handleOrgFieldChange = (field, value, custom) => {
        if (field === "core_standards" && value) {
            setStandardErr(false);
        }
        let objCopy = { ...object.org };
        if (!custom) {
            return setObject((old) => {
                return {
                    ...old,
                    org: {
                        ...old.org,
                        [field]: value,
                    },
                };
            });
        }
        switch (field) {
            case "business_name":
                objCopy[field] = value;
                checkOrgAvailability(value, objCopy["site"]);
                break;
            case "site":
                objCopy[field] = value;
                checkOrgAvailability(objCopy["business_name"], value);
                break;
            case "work_email":
                objCopy[field] = value;
                handleUserFieldChange("email", value.toLowerCase());
                checkUserAvailability(value);
                break;
            case "address_1":
                objCopy[field] = value;
                objCopy["billing_address"] = value;
                break;
            case "city":
                objCopy[field] = value;
                break;
            case "country":
                objCopy[field] = value;
                break;
            case "post_code":
                objCopy[field] = value;
                break;
            default:
                return;
        }
        return setObject((old) => {
            return {
                ...old,
                org: objCopy,
            };
        });
    };

    const handleUserFieldChange = (field, value, custom) => {
        let objCopy = { ...object.user };
        if (!custom) {
            objCopy[field] = value;
            return setObject((old) => {
                return {
                    ...old,
                    user: objCopy,
                };
            });
        }
        switch (field) {
            case "first_name":
                objCopy[field] = value;
                objCopy["user_name"] = `${objCopy.first_name} ${objCopy.last_name}`;
                break;
            case "last_name":
                objCopy[field] = value;
                objCopy["user_name"] = `${objCopy?.first_name} ${objCopy?.last_name}`;
                break;
        }
        return setObject((old) => {
            return {
                ...old,
                user: objCopy,
            };
        });
    };

    const handleCardFieldChange = (field, value, custom) => {
        if (!custom) {
            return setObject((old) => {
                return {
                    ...old,
                    card: {
                        ...old.card,
                        [field]: value,
                    },
                };
            });
        }
        switch (field) {
            case "expiry":
                if (value?.length === 2 && !value?.includes("/")) value += "/";
                setObject((old) => {
                    return {
                        ...old,
                        card: {
                            ...old.card,
                            [field]: value,
                        },
                    };
                });
                break;
            default:
                return;
        }
    };

    const handleBankFieldChange = (field, value, custom) => {
        if (!custom) {
            return setObject((old) => {
                return {
                    ...old,
                    bank: {
                        ...old.bank,
                        [field]: value,
                    },
                };
            });
        }
    };

    const handlePaymentSource = (field, value, custom) => {
        if (!custom) {
            return setObject((old) => {
                return {
                    ...old,
                    [field]: value,
                };
            });
        }
        switch (field) {
            case "payment_method":
                setObject((old) => {
                    return {
                        ...old,
                        [field]: value,
                    };
                });
                handleOrgFieldChange("payment_method", value);
                break;
            default:
                return;
        }
    };

    const getSubsPlanById = async (id) => {
        dispatch(getSubsPlanByIdApi(id));
    };

    const handleSubmit = async () => {
        try {
            let data;
            if (!cardValue && object.payment_method === "card") {
                setObject((old) => {
                    return {
                        ...old,
                        loader: false,
                    };
                });
                return;
            }
            if (object.payment_method === "card") {
                const cardElement = elements.getElement(CardElement);
                const { paymentMethod, err } = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                });
                const obj = {
                    price_id: object.price_id,
                    coupon: object.coupon,
                    paymentMethod: paymentMethod?.id,
                    user: object?.user,
                    site: object?.org?.site,
                    business_name: object?.org?.business_name,
                    appliedTax: planObj?.appliedTax,
                };
                if (err) {
                    return;
                }

                const response = await request.post(
                    "stripe/pay-via-card",
                    obj,
                    false,
                    false
                );

                if (response?.data?.error) {
                    setObject((old) => {
                        return {
                            ...old,
                            loader: false,
                            error: {
                                show: true,
                                message: response?.data?.error?.message,
                                stripe_error: {},
                            },
                        };
                    });
                    return;
                }
                if (response.data.status !== "active") {
                    const { paymentIntent, error } = await stripe.confirmCardPayment(
                        response.data.latest_invoice.payment_intent.client_secret,
                        { payment_method: paymentMethod.id }
                    );

                    if (paymentIntent?.status === "succeeded") {
                        data = arrangeDataForRequest({
                            customer: response?.data?.customer,
                            payment_method: paymentMethod.id,
                            sub: response.data.id,
                        });
                    } else if (error) {
                        setObject((old) => {
                            return {
                                ...old,
                                loader: false,
                                error: {
                                    show: true,
                                    message: "Payment Failed against your card",
                                    stripe_error: {},
                                },
                            };
                        });
                        return;
                    } else {
                        setObject((old) => {
                            return {
                                ...old,
                                loader: false,
                                error: {
                                    show: true,
                                    message: "Payment Failed against your card",
                                    stripe_error: {},
                                },
                            };
                        });
                        return;
                    }
                } else {
                    data = arrangeDataForRequest({
                        customer: response?.data?.customer,
                        payment_method: paymentMethod.id,
                        sub: response.data.id,
                    });
                }
            } else {
                data = arrangeDataForRequest();
            }
            let result = await request.post(ORG.CREATE_ORG, data, false, true);
            if (result.status) {
                setObject((old) => {
                    return {
                        ...old,
                        loader: false,
                    };
                });
                if (object?.payment_method === "bacs_debit") {
                    window?.open(result?.data, "_self");
                } else {
                    navigate("/stripe/thank-you", {
                        state: { first_name: object?.user.first_name },
                        couponData: couponData,
                        location: object,
                        discount: { msg: "asdasdas" },
                    });
                }
            }
        } catch (e) {
            setObject((old) => {
                return {
                    ...old,
                    loader: false,
                    error: {
                        show: true,
                        message: e.message,
                        stripe_error: e.data,
                    },
                };
            });
        }
    };

    const handleSubmitCheck = (e) => {
        if (standardErr) {
            console.log("will not add..");
        } else {
            setObject((old) => {
                return {
                    ...old,
                    loader: true,
                };
            });

            handleSubmit();
        }
    };

    const validateForm = async (event) => {
        event.preventDefault();
        let err = false;

        if (!cardValue && object.payment_method === "card") {
            setCardErr(true);
        } else {
            setCardErr(false);
        }

        if (
            object?.org?.core_standards === undefined ||
            object?.org?.core_standards === null
        ) {
            setStandardErr(true);
        }
        event.stopPropagation();
        var forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        Array.prototype.filter.call(forms, function (form) {
            if (
                form.checkValidity() === false ||
                !object?.org?.core_standards ||
                object?.org?.core_standards === undefined ||
                object?.org?.core_standards === null ||
                err
            )
                form.classList.add("was-validated");
            else if (
                !object.show_custom_error ||
                !(
                    object.show_custom_error.site &&
                    object.show_custom_error.business &&
                    object.show_custom_error.email
                )
            ) {
                handleSubmitCheck();
            }
        });
    };

    const arrangeDataForRequest = (extraData) => {
        object.org.subscription = { name: planObj?.product?.name };
        object.payAbleAmount = planObj.payAbleAmount;
        object.appliedTax = planObj.appliedTax;

        if (object.payment_method === "card") {
            object.sub = extraData.sub;
            object.stripe.payment_method_id = extraData?.payment_method;
            object.stripe.customer_id = extraData.customer;
            object.stripe.payment_method_type = "card";
            object.card = { ...object.card, ...object.org, default: true };
        }
        if (object.payment_method === "bacs_debit") {
            object.stripe.payment_method_id = "";
            object.stripe.customer_id = "";
            object.stripe.payment_method_type = "bacs_debit";
            object.sub = "";

            object.bank = { ...object.bank, ...object.org, default: true };
        }
        let formData = new FormData();
        let encData = CryptoJS.AES.encrypt(
            JSON.stringify({ ...object }),
            process.env.REACT_APP_CRYPTO_PRIVATE_KEY
        );
        formData.append("data", encData);
        return formData;
    };

    const checkOrgAvailability = (business_name, site) => {
        try {
            if (!business_name || !site) return;
            clearTimeout(timer);
            // Wait for X ms and then process the request
            timer = setTimeout(() => {
                checkOrgAvailabilityAPI({ business_name, site });
            }, waitTime);
        } catch (e) { }
    };

    const checkUserAvailability = (email) => {
        try {
            if (!email) return;
            clearTimeout(timer);
            // Wait for X ms and then process the request
            timer = setTimeout(() => {
                checkUserAvailabilityAPI({ email });
            }, waitTime);
        } catch (e) { }
    };

    const checkOrgAvailabilityAPI = useCallback(async (data) => {
        try {
            let result = await request.post(ORG.CHECK_ORG_AVAILABILITY, data, false);
            if (result.status)
                setObject((old) => {
                    return {
                        ...old,
                        show_custom_error: {
                            ...old.show_custom_error,
                            business: true,
                            site: true,
                        },
                    };
                });
        } catch (e) {
            setObject((old) => {
                return {
                    ...old,
                    show_custom_error: {
                        ...old.show_custom_error,
                        business: false,
                        site: false,
                    },
                };
            });
        }
    });

    const checkUserAvailabilityAPI = useCallback(async (data) => {
        try {
            let result = await request.post(
                USER.CHECK_USER_AVAILABILITY,
                data,
                false
            );
            if (result.status)
                setObject((old) => {
                    return {
                        ...old,
                        show_custom_error: {
                            ...old.show_custom_error,
                            email: true,
                        },
                    };
                });
        } catch (e) {
            setObject((old) => {
                return {
                    ...old,
                    show_custom_error: {
                        ...old.show_custom_error,
                        email: false,
                    },
                };
            });
        }
    });

    useEffect(() => {
        if (state?.org && state?.bank) setObject({ ...state });
    }, [state?.org, state?.bank]);

    useEffect(() => {
        getSubsPlanById(params?.planId);
        setObject((old) => {
            return {
                ...old,
                price_id: params?.planId || null,
            };
        });
    }, [params.planId]);

    useEffect(() => {
        if (planObj?.tax?.length) {
            var { tax, payAbleAmount } = utils.handleTaxCalculations(
                planObj?.plan?.amount,
                planObj?.tax
            );
        } else {
            var payAbleAmount = planObj?.plan?.amount;
            var tax = null;
        }
        dispatch(
            setPlanObject({
                ...planObj,
                payAbleAmount: payAbleAmount,
                appliedTax: tax,
            })
        );
    }, [planObj?.tax]);

    const CARD_ELEMENT_OPTIONS = {
        iconStyle: "solid",
        hidePostalCode: true,
    };

    return (
        <>
            <section className="checkout">
                <div className="container-fluid">
                    <section className="checkout-form">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <StripeWidget />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <form
                                        className="needs-validation"
                                        onSubmit={validateForm}
                                        noValidate
                                    >
                                        <div className="form-group">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <label htmlFor="validationCustom01">
                                                        First Name*
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        required
                                                        value={object?.user?.first_name}
                                                        onChange={(e) =>
                                                            handleUserFieldChange(
                                                                "first_name",
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please fill required field.
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <label htmlFor="validationCustom02">Last Name*</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        required
                                                        value={object?.user?.last_name}
                                                        onChange={(e) =>
                                                            handleUserFieldChange(
                                                                "last_name",
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please fill required field.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="validationCustom03">Business name*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                required
                                                value={object?.org?.business_name}
                                                onChange={(e) =>
                                                    handleOrgFieldChange(
                                                        "business_name",
                                                        e.target.value,
                                                        true
                                                    )
                                                }
                                            />
                                            <div className="invalid-feedback">
                                                Please fill required field.
                                            </div>
                                            {object?.show_custom_error?.business && (
                                                <div className="combination-error">
                                                    <small className="text-danger">
                                                        Oops! - It looks like you're already using
                                                        audIT.app, the combination of business name and site
                                                        is already registered.
                                                    </small>
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="validationCustom04">Site*</label>
                                            <input
                                                type="text"
                                                size="30"
                                                className="form-control"
                                                id="validationCustom04"
                                                required
                                                value={object?.org?.site}
                                                onChange={(e) =>
                                                    handleOrgFieldChange("site", e.target.value, true)
                                                }
                                            />
                                            <div className="invalid-feedback">
                                                Please fill required field.
                                            </div>
                                            {object?.show_custom_error?.site && (
                                                <div className="combination-error">
                                                    <small className="text-danger">
                                                        Oops! - It looks like you're already using
                                                        audIT.app, the combination of business name and site
                                                        is already registered.
                                                    </small>
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="validationCustom05">Core Standard*</label>
                                            <Select
                                                className=""
                                                id="validationCustom05"
                                                required
                                                value={object?.org?.core_standards}
                                                options={coreStandard}
                                                onChange={(data) =>
                                                    handleOrgFieldChange("core_standards", data)
                                                }
                                            />
                                            {standardErr && (
                                                <div className="err_feedback">
                                                    Please fill required field.
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="validationCustom06">Work email*</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="validationCustom06"
                                                required
                                                value={object?.org?.work_email}
                                                onChange={(e) =>
                                                    handleOrgFieldChange(
                                                        "work_email",
                                                        e.target.value,
                                                        true
                                                    )
                                                }
                                            />
                                            <div className="invalid-feedback">
                                                Please fill required field.
                                            </div>
                                            {object?.show_custom_error?.email && (
                                                <div className="combination-error">
                                                    <small className="text-danger">
                                                        Oops! - the work email is not available.
                                                    </small>
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>Payment method*</label>
                                            <div className="form-row">
                                                <div className="col-12 col-md-12 col-lg-6 mb-2">
                                                    <button
                                                        type={"button"}
                                                        className={`stripe-card ${object.payment_method === "card"
                                                                ? "stripe-active"
                                                                : ""
                                                            }`}
                                                        onClick={() =>
                                                            handlePaymentSource(
                                                                "payment_method",
                                                                "card",
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <i className="far fa-credit-card" /> Card
                                                    </button>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-6">
                                                    <button
                                                        type={"button"}
                                                        className={`stripe-account ${object.payment_method === "bacs_debit"
                                                                ? "stripe-active"
                                                                : ""
                                                            }`}
                                                        onClick={() =>
                                                            handlePaymentSource(
                                                                "payment_method",
                                                                "bacs_debit",
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <i className="far fa-university" /> Bacs direct
                                                        debit
                                                    </button>
                                                </div>
                                            </div>
                                            {object?.payment_method === "card" && (
                                                <div className="form-group">
                                                    <label>Card information*</label>
                                                    <div className="form-row">
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                borderStyle: "solid",
                                                                border: "1px solid #ced4da",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            <CardElement
                                                                options={CARD_ELEMENT_OPTIONS}
                                                                onChange={(e) => {
                                                                    setCardValue(e.complete);
                                                                }}
                                                            />
                                                        </div>
                                                        {(cardErr && !cardValue && object.payment_method === "card") && (
                                                            <div className="err_feedback">
                                                                Please fill required field. Where a valid
                                                                coupon has been provided, payment will not be
                                                                taken. You will only be charged once the trial
                                                                period has ended. You can cancel at any time.
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="validationCustom14">
                                                Billing address*
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <div className="invalid-feedback">
                                                Please fill required field.
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom15"
                                                required
                                                value={object?.org?.address_1}
                                                onChange={(e) =>
                                                    handleOrgFieldChange(
                                                        "address_1",
                                                        e.target.value,
                                                        true
                                                    )
                                                }
                                            />
                                            <label htmlFor="validationCustom15">Address 01*</label>
                                        </div>
                                        <div className="form-group">
                                            <div className="invalid-feedback">
                                                Please fill required field.
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom16"
                                                required
                                                value={object?.org?.address_2}
                                                onChange={(e) =>
                                                    handleOrgFieldChange("address_2", e.target.value)
                                                }
                                            />
                                            <label htmlFor="validationCustom16">Address 02*</label>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="invalid-feedback">
                                                        Please fill required field.
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom17"
                                                        required
                                                        value={object?.org?.city}
                                                        onChange={(e) =>
                                                            handleOrgFieldChange("city", e.target.value, true)
                                                        }
                                                    />
                                                    <label htmlFor="validationCustom17">Town/City*</label>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom18"
                                                        value={object?.org?.county}
                                                        onChange={(e) =>
                                                            handleOrgFieldChange("county", e.target.value)
                                                        }
                                                    />
                                                    <label htmlFor="validationCustom18">
                                                        County/State/Province
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="invalid-feedback">
                                                        Please fill required field.
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom19"
                                                        required
                                                        value={object?.org?.post_code}
                                                        onChange={(e) =>
                                                            handleOrgFieldChange(
                                                                "post_code",
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor="validationCustom19">Postcode*</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Select
                                                className=""
                                                id="validationCustom20"
                                                required
                                                value={object?.org?.country}
                                                options={countries}
                                                onChange={(data) =>
                                                    handleOrgFieldChange("country", data)
                                                }
                                            />
                                            <div className="invalid-feedback">
                                                Please fill required field.
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div
                                                        className="invalid-feedback"
                                                        id="validationCustom19"
                                                    >
                                                        Please fill required field.
                                                    </div>

                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={coupon}
                                                        placeholder="Discount Coupon"
                                                        onChange={(e) =>
                                                            handleCouponField("coupon", e.target.value, false)
                                                        }
                                                    />
                                                </div>
                                                <button
                                                    type="button"
                                                    disabled={object?.verifyButton}
                                                    style={{
                                                        backgroundColor: "green",
                                                        color: "white",
                                                        width: 100,
                                                        borderRadius: 5,
                                                    }}
                                                    onClick={() => checkCoupon(coupon)}
                                                >
                                                    Verify
                                                    {object?.discountLoader && (
                                                        <span
                                                            className="spinner-border spinner-border-sm spinner-position"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                </button>
                                                {object?.discountError && (
                                                    <div style={{ color: "red" }}>
                                                        &nbsp; Coupon is Invalid
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="exampleCheck1"
                                                required
                                                onClick={() =>
                                                    handleOrgFieldChange(
                                                        "agreed_with_terms_and_cond",
                                                        !object?.org?.agreed_with_terms_and_cond
                                                    )
                                                }
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="exampleCheck1"
                                            >
                                                I have read and agree to the website{" "}
                                                <a
                                                    className="fs-16"
                                                    href={
                                                        "https://techni-k.co.uk/product-terms-and-conditions/"
                                                    }
                                                    target="_blank"
                                                >
                                                    terms and conditions
                                                </a>{" "}
                                                *
                                            </label>
                                        </div>
                                        {planObj?.plan?.trial_period_days > 0 &&
                                            !!planObj?.payAbleAmount && (
                                                <p>
                                                    After your trial ends, you will be charged £
                                                    {utils.getFormattedValue(planObj?.payAbleAmount)} per
                                                    month starting{" "}
                                                    {moment(new Date(), "DD-MM-YYYY")
                                                        .add(planObj?.plan?.trial_period_days, "days")
                                                        .format("MMMM-DD-YYYY")}
                                                    . You can always cancel before then.
                                                </p>
                                            )}
                                        {object?.error?.show && (
                                            <div className="float-right" style={{ width: "210px" }}>
                                                <p className="warning-msg">
                                                    <i className="fal fa-exclamation-triangle color-04" />{" "}
                                                    {object?.error?.message}
                                                </p>
                                            </div>
                                        )}
                                        <div className="justify-content-center d-flex align-items-center">
                                            {planObj?.plan?.trial_period_days > 0 ? (
                                                <button
                                                    className="btn-start-trial d-lg-block mb-2 mr-2"
                                                    type="submit"
                                                    disabled={standardErr ? standardErr : object?.loader}
                                                >
                                                    Start trial
                                                    {object?.loader && (
                                                        <span
                                                            className="spinner-border spinner-border-sm spinner-position"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                </button>
                                            ) : object.payment_method === "bacs_debit" ? (
                                                <button
                                                    className="btn-start-trial d-lg-block mb-2 mr-2"
                                                    type="submit"
                                                    disabled={object?.loader}
                                                >
                                                    Proceed to Payment
                                                    {object?.loader && (
                                                        <span
                                                            className="spinner-border spinner-border-sm spinner-position"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                </button>
                                            ) : (
                                                <>
                                                    <button
                                                        className="btn-start-trial d-lg-block mr-2"
                                                        type="submit"
                                                        disabled={object?.loader}
                                                    >
                                                        Create subscription
                                                        {object?.loader && (
                                                            <span
                                                                className="spinner-border spinner-border-sm spinner-position"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                    </button>
                                                    <span>
                                                        {cardErr && !cardValue && (
                                                            <span className="err_feedback">
                                                                Please provide a payment method
                                                            </span>
                                                        )}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    );
};

export default CheckoutForm;
