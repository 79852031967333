import request from "../../services/request";
import { STANDARDS } from "../../Routes/Api";
import session from "../../services/session";
import { STANDARDS_CONSTANTS } from "../constants";

export const getReminderList = (payload) => {
    return {
        type: STANDARDS_CONSTANTS.GET_STANDARDS_LIST,
        payload,
    };
};

export const getStandardsListSuccess = (payload) => {
    return {
        type: STANDARDS_CONSTANTS.GET_STANDARDS_LIST_SUCCESS,
        payload,
    };
};

export const getStandardsListError = (payload) => {
    return {
        type: STANDARDS_CONSTANTS.GET_STANDARDS_LIST_ERROR,
        payload,
    };
};

export const getLogStandardsListSuccess = (payload) => {
    return {
        type: STANDARDS_CONSTANTS.GET_LOG_STANDARDS_LIST_SUCCESS,
        payload,
    };
};

// get additional standards list api
export const getStandardsListApi = (org = session.getOrgId()) => {
    return (dispatch) => {
        request.get(`${STANDARDS.GET_STANDARDS_LIST}/${org}`, true).then(
            (res) => {
                dispatch(getStandardsListSuccess(res?.data));
            },
            (error) => { }
        );
    };
};
