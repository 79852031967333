import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BackIcon from "../../Assets/images/back-arrow.png";
import PinImg from "../../Assets/images/Larry.png";
import request from "../../services/request";
import { ORG } from "../../Routes/Api";
import countries from "../../countries/counties";
import Select from "react-select";
import { APP_CONSTANTS } from "../../constants";
import session from "../../services/session";
import SaveModalTwo from "../Modals/SaveModalTwo";
import ErrorModal from "../Modals/ErrorModal";

const coreStandard = APP_CONSTANTS.CORE_STANDARDS;

const Billing = () => {
    /*const { state } = useLocation();*/
    let navigate = useNavigate();
    const [object, setObject] = useState({});
    const [error, setError] = useState({
        show: false,
        message: null,
    });
    const [emptyError, setEmptyError] = useState({
        business_name: false,
        site: false,
        work_email: false,
        address_1: false,
        address_2: false,
        city: false,
        county: false,
        post_code: false,
    });

    const getOrgData = async () => {
        try {
            let result = await request.get(
                `${ORG.GET_ORG_BY_ID}${session.getOrgId()}`,
                true
            );
            if (result.status) setData(result.data);
        } catch (e) { }
    };

    const updateOrg = () => {
        if (errorHandling()) return;

        request
            .put(ORG.UPDATE_ORG, { ...object?.org }, true)
            .then((result) => {
                if (result.status) {
                    setData(result.data, "update");
                }
            })
            .catch((e) => {
                setError({ show: true, message: e?.message });
                setTimeout(() => {
                    setError({ show: false, message: null });
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            });
    };

    const errorHandling = () => {
        let flag = false;
        if (object?.org?.business_name === "") {
            setEmptyError((old) => {
                return {
                    ...old,
                    business_name: true,
                };
            });
            flag = true;
        }
        if (object?.org?.site === "") {
            setEmptyError((old) => {
                return {
                    ...old,
                    site: true,
                };
            });
            flag = true;
        }
        if (object?.org?.work_email === "") {
            setEmptyError((old) => {
                return {
                    ...old,
                    work_email: true,
                };
            });
            flag = true;
        }
        if (object?.org?.address_1 === "") {
            setEmptyError((old) => {
                return {
                    ...old,
                    address_1: true,
                };
            });
            flag = true;
        }
        if (object?.org?.address_2 === "") {
            setEmptyError((old) => {
                return {
                    ...old,
                    address_2: true,
                };
            });
            flag = true;
        }
        if (object?.org?.city === "") {
            setEmptyError((old) => {
                return {
                    ...old,
                    city: true,
                };
            });
            flag = true;
        }
        if (object?.org?.county === "") {
            setEmptyError((old) => {
                return {
                    ...old,
                    county: true,
                };
            });
            flag = true;
        }
        if (object?.org?.post_code === "") {
            setEmptyError((old) => {
                return {
                    ...old,
                    post_code: true,
                };
            });
            flag = true;
        }
        return flag;
    };

    const setData = (data, action) => {
        if (action === "update") {
            setObject({ org: data, success: true });
            session.setOrg(data);
            setTimeout(() => {
                setObject((old) => {
                    return {
                        ...old,
                        success: false,
                    };
                });
            }, APP_CONSTANTS.MODAL_SHOW_TIME);
        } else {
            setObject({ org: data });
        }
    };

    // useEffect(() => {
    //     const findIndex = object?.org?.core_standards?.findIndex((item) => {
    //         return item.default === true;
    //     });
    //     setDefaultStandard(object?.org?.core_standards[findIndex]);
    // }, [object]);

    useEffect(() => {
        getOrgData();
    }, []);

    const handleFieldChange = (field, value, custom) => {
        if (value && value !== "") {
            setEmptyError((old) => {
                return {
                    ...old,
                    [field] : false,
                }
            })
        } else {
            setEmptyError((old) => {
                return {
                    ...old,
                    [field] : true,
                }
            })
        }
        
        if (field === "core_standards") {
            let tempArray = object?.org?.core_standards;
            tempArray[0] = value;

            return setObject((old) => {
                return {
                    ...old,
                    org: {
                        ...old.org,
                        [field]: tempArray,
                    },
                };
            });
        }

        if (!custom) {
            return setObject((old) => {
                return {
                    ...old,
                    org: {
                        ...old.org,
                        [field]: value,
                    },
                };
            });
        }
    };

    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-credit-card" /> Accounts summary - Billing
                        </h2>
                    </div>
                    <div className="col-12 col-lg-2" onClick={() => navigate(-1)}>
                        <NavLink
                            to={"#"}
                            className="back-to-pre fs-14 fw-300 py-4 color-04 float-right"
                        >
                            <u>Back to previous page</u>
                            <img src={BackIcon} width="14px" alt="Back to Pre" />
                        </NavLink>
                    </div>
                </div>
                <div className="accounts-billing">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <h2 className="fs-18 fw-400 color-06">
                                Update your billing information
                            </h2>
                            <div className="form-group">
                                <label>Business name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={object?.org?.business_name}
                                    onChange={(e) =>
                                        handleFieldChange("business_name", e.target.value)
                                    }
                                />
                                {emptyError?.business_name && (
                                    <div className="text-danger fs-12">
                                        Please fill required field.
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Site</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={object?.org?.site}
                                    onChange={(e) => handleFieldChange("site", e.target.value)}
                                />
                                {emptyError?.site && (
                                    <div className="text-danger fs-12">
                                        Please fill required field.
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Core Standard</label>
                                <Select
                                    className=""
                                    value={object?.org?.core_standards}
                                    options={coreStandard}
                                    onChange={(data) =>
                                        handleFieldChange("core_standards", {
                                            ...data,
                                            default: true,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>Work email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={object?.org?.work_email}
                                    onChange={(e) =>
                                        handleFieldChange("work_email", e.target.value)
                                    }
                                />
                                {emptyError?.work_email && (
                                    <div className="text-danger fs-12">
                                        Please fill required field.
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={object?.org?.address_1}
                                    onChange={(e) =>
                                        handleFieldChange("address_1", e.target.value)
                                    }
                                />
                                <label>Address 01</label>
                                {emptyError?.address_1 && (
                                    <div className="text-danger fs-12">
                                        Please fill required field.
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={object?.org?.address_2}
                                    onChange={(e) =>
                                        handleFieldChange("address_2", e.target.value)
                                    }
                                />
                                <label>Address 02</label>
                                {emptyError?.address_2 && (
                                    <div className="text-danger fs-12">
                                        Please fill required field.
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <div className="form-row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={object?.org?.city}
                                            onChange={(e) =>
                                                handleFieldChange("city", e.target.value)
                                            }
                                        />
                                        <label>Town/City</label>
                                        {emptyError?.city && (
                                            <div className="text-danger fs-12">
                                                Please fill required field.
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={object?.org?.county}
                                            onChange={(e) =>
                                                handleFieldChange("county", e.target.value)
                                            }
                                        />
                                        <label>County</label>
                                        {emptyError?.county && (
                                            <div className="text-danger fs-12">
                                                Please fill required field.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={object?.org?.post_code}
                                            onChange={(e) =>
                                                handleFieldChange("post_code", e.target.value)
                                            }
                                        />
                                        <label>Postcode</label>
                                        {emptyError?.post_code && (
                                            <div className="text-danger fs-12">
                                                Please fill required field.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <Select
                                    className=""
                                    options={countries}
                                    value={object?.org?.country}
                                    onChange={(data) => {
                                        handleFieldChange("country", data);
                                    }}
                                ></Select>
                            </div>
                            <div className="justify-content-center d-flex">
                                <button
                                    className="btn-update-plan d-lg-block mb-2 mr-2"
                                    onClick={updateOrg}
                                >
                                    Update
                                </button>
                                <button
                                    className="btn-cancel-plan"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <img src={PinImg} className="img-fluid" alt="Billing" />
                        </div>
                    </div>
                </div>
            </div>
            {object?.success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setObject((old) => {
                                return {
                                    ...old,
                                    success: false,
                                };
                            });
                        }}
                    ></div>
                    <SaveModalTwo
                        h2={APP_CONSTANTS.UPDATE_ORG_INFO.h2}
                        h3={APP_CONSTANTS.UPDATE_ORG_INFO.h3}
                        p={APP_CONSTANTS.UPDATE_ORG_INFO.p}
                        type="billing"
                    />
                </>
            )}
            {error?.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setError({ show: false, message: null });
                        }}
                    ></div>
                    <ErrorModal message={error?.message} type={"error-org"} />
                </>
            )}
        </section>
    );
};

export default Billing;
