import { APP_CONSTANTS } from "../../constants";
import { AUDIT } from "../../Routes/Api";
import request from "../../services/request";
import { AUDIT_CONSTANTS } from "../constants";
import { changeTabState } from "./tabState";
import session from "../../services/session";

export const createAuditSuccess = (payload) => {
    return {
        type: AUDIT_CONSTANTS.CREATE_AUDIT_SUCCESS,
        payload,
    };
};

export const toggleSuccessModal = (payload) => {
    return {
        type: AUDIT_CONSTANTS.TOGGLE_AUDIT_SUCCESS_MODAL,
        payload,
    };
};

export const GMPResponseError = (payload) => {
    return {
        type: AUDIT_CONSTANTS.AUDIT_RESPONSE_ERROR,
        payload,
    };
};

export const toggleErrorModal = (payload) => {
    return {
        type: AUDIT_CONSTANTS.TOGGLE_AUDIT_ERROR_MODAL,
        payload,
    };
};

const getUserAuditListSuccess = (payload) => {
    return {
        type: AUDIT_CONSTANTS.GET_AUDIT_USER_LIST_SUCCESS,
        payload,
    };
};

export const pushSAToList = (payload) => {
    return {
        type: AUDIT_CONSTANTS.PUSH_SA_IN_LIST,
        payload,
    };
};

export const updateQuestions = (payload) => {
    return {
        type: AUDIT_CONSTANTS.UPDATE_SA_QUESTIONS,
        payload,
    };
};

export const getSAQuestionsSuccess = (payload) => {
    return {
        type: AUDIT_CONSTANTS.SA_QUESTIONS_SUCCESS,
        payload,
    };
};

export const filterSaList = (payload) => {
    return {
        type: AUDIT_CONSTANTS.FILTER_SA_LIST,
        payload,
    };
};

export const updateSAObject = (payload) => {
    return {
        type: AUDIT_CONSTANTS.UPDATE_SA_OBJECT,
        payload,
    };
};

export const emptyFilterSaList = (payload) => {
    return {
        type: AUDIT_CONSTANTS.EMPTY_FILTER_SA_LIST,
        payload,
    };
};

export const saQuestionCurrentIndex = (payload) => {
    return {
        type: AUDIT_CONSTANTS.SA_QUESTION_CURRENT_INDEX,
        payload,
    };
};

// Generate Audit
export const generateAudit = (data) => {
    return (dispatch) => {
        request.post(AUDIT.GENERATE_AUDIT, data, true, false).then(
            (res) => {
                dispatch(createAuditSuccess(res?.data));
                dispatch(toggleSuccessModal(true));

                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    dispatch(changeTabState(1));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(GMPResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

// Get Audit by User
export const getAuditByUser = (orgId = session.getOrgId()) => {
    return (dispatch) => {
        request.get(`${AUDIT.GET_AUDIT_BY_USER}/${orgId}`, true, false).then(
            (res) => {
                dispatch(getUserAuditListSuccess(res?.data));
            },
            (error) => { }
        );
    };
};

// Answer SA Questions
export const answerSAQuestion = async (data) => {
    await request.post(AUDIT.ANSWER_SA_QUESTION, data, true, false).then(
        (res) => {
            if (res?.status) {
                return res;
            }
        },
        (error) => {
            return error;
        }
    );
};
