import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { APP_CONSTANTS } from "../../constants";
import {
    toggleErrorModal,
    toggleSendModal,
    toggleSuccessModal,
    updateProObject,
} from "../../store/actions/pro";
import { useDispatch, useSelector } from "react-redux";
import {
    createProAPi,
    addAttachment,
    updateProAPi,
    sendProApi,
    createAndSendApi,
} from "../../store/actions/pro";
import ProBrowseModal from "../Modals/ProBrowseModal";
import ErrorModal from "../Modals/ErrorModal";
import SaveModal from "../Modals/SaveModal";
import moment from "moment";
import NotificationSendModal from "../Modals/NotificationSendModal";
import { getAllUsersForDropDownApi } from "../../store/actions/user";
import NotificationErrorModal from "../Modals/NotificationErrorModal";
import utils from "../../utils";
import { getExtension } from "../../utils/icons";
import { deleteMediaFromS3Bucket } from "../../utils/s3";
import videoIcon from "../../Assets/images/file-video-regular.svg";

const CreatePro = () => {
    const dispatch = useDispatch();
    const btnRef = useRef(null);

    const [validationError, setValidationError] = useState({});

    const { object, success, error, send, disabled } = useSelector(
        (state) => state?.pro
    );
    const usersForDropDown = useSelector((state) => state?.user?.userDropdown);
    const sourceOptions = APP_CONSTANTS.PRO_SOURCE_OPTIONS;

    const handledFieldChanges = (field, value, custom, index) => {
        if (value && value !== "") {
            setValidationErrorObj(field, false);
        } else {
            setValidationErrorObj(field, true);
        }
        let proObjCopy = { ...object };
        if (!custom) {
            proObjCopy[field] = value;

            dispatch(updateProObject(proObjCopy));
            return;
        }
        switch (field) {
            case "custom_title":
                if (proObjCopy.attachments)
                    proObjCopy.attachments[index]["custom_name"] = value;
                break;
            case "due_date":
                if (
                    proObjCopy?.date_raised &&
                    new Date(proObjCopy?.date_raised).getTime() >
                    new Date(value).getTime()
                )
                    delete proObjCopy.date_raised;
                proObjCopy.due_date = value;
                break;
            case "date_raised":
                if (
                    proObjCopy?.due_date &&
                    new Date(proObjCopy?.due_date).getTime() < new Date(value).getTime()
                )
                    delete proObjCopy.due_date;
                proObjCopy.date_raised = value;
                break;
            case "source":
                if (value.value !== 6 && proObjCopy.custom_source)
                    delete proObjCopy.custom_source;
                proObjCopy.source = value;
                break;

            default:
        }

        dispatch(updateProObject(proObjCopy));
    };

    const handleSave = () => {
        btnRef.current.disabled = true;
        if (validateObject()) return;
        else {
            if (object?.status === "Complete") return;
            let formData = setFormData({ ...object });
            if (object?._id) dispatch(updateProAPi(formData));
            else dispatch(createProAPi(formData));
        }
    };

    const handleSend = () => {
        btnRef.current.disabled = true;
        if (validateObject()) return;
        else {
            if (object?.status === "Complete") return;
            if (!object?._id) {
                let formData = setFormData({ ...object });
                dispatch(createAndSendApi(formData));
            } else dispatch(sendProApi({ ...object, status: "In progress" }));
        }
    };

    const extractCustomTitles = (data) => {
        data.custom_names = [];
        data?.attachments.filter((obj, index) => {
            if (Boolean(obj.custom_name) && !obj.destination)
                data.custom_names.push({ custom_name: obj.custom_name, index });
        });
    };

    const setFormData = (data) => {
        let formData = new FormData();
        /*if (data?.attachments?.length) {
                                                                extractCustomTitles(data)
                                                                data?.attachments?.map((file) => {
                                                                    formData.append("media", file)
                                                                })
                                                            }*/
        formData.append("data", JSON.stringify(data));
        return formData;
    };

    const removeEvidence = async (obj, index) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            let proCopy = { ...object };
            if (obj.key) await deleteMediaFromS3Bucket(obj.key);
            proCopy?.removeFiles
                ? proCopy.removeFiles.push(proCopy?.attachments[index])
                : (proCopy.removeFiles = [proCopy?.attachments[index]]);
            proCopy?.attachments?.splice(index, 1);
            dispatch(updateProObject(proCopy));
        }
    };

    useEffect(() => {
        if (!usersForDropDown?.length) dispatch(getAllUsersForDropDownApi());
        else
            utils.getCurrentUserForDropDown(
                dispatch,
                updateProObject,
                object,
                "raised_by"
            );
    }, [usersForDropDown]);

    const validateObject = () => {
        let errorFlag = false;
        setValidationError({});
        if (object?.source) {
            if (object?.source?.value === 6 && !object?.custom_source) {
                setValidationErrorObj("custom_source", true);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
        } else {
            setValidationErrorObj("source", true);
            errorFlag = true;
            btnRef.current.disabled = false;
        }
        if (!object?.raised_by) {
            setValidationErrorObj("raised_by", true);
            errorFlag = true;
            btnRef.current.disabled = false;
        }
        if (!object?.date_raised) {
            setValidationErrorObj("data_raised", true);
            errorFlag = true;
            btnRef.current.disabled = false;
        }
        if (!object?.assigned_to) {
            setValidationErrorObj("assigned_to", true);
            errorFlag = true;
            btnRef.current.disabled = false;
        }
        if (!object.due_date) {
            setValidationErrorObj("due_date", true);
            errorFlag = true;
            btnRef.current.disabled = false;
        }
        return errorFlag;
    };

    const setValidationErrorObj = (key, flag) => {
        setValidationError((old) => {
            return {
                ...old,
                [key]: flag,
            };
        });
    };

    const openLinkInNewTab = (link) => {
        window.open(link);
    };

    useEffect(() => {
        if (error && btnRef?.current) {
            btnRef.current.disabled = false;
        }
    }, [error]);

    return (
        <>
            <section className="home-nc">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6 pr-lg-5 mt-4">
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Source</label>
                                <div className="col-sm-6">
                                    <Select
                                        options={sourceOptions}
                                        value={object?.source}
                                        onChange={(data) => {
                                            handledFieldChanges("source", data, true);
                                        }}
                                        isDisabled={!utils.checkSubStatus()}
                                    />
                                    {validationError.source && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            {object?.source?.value === 6 && (
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                        Custom Source
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="date-selection"
                                            options={sourceOptions}
                                            value={object?.custom_source}
                                            onChange={(e) => {
                                                handledFieldChanges("custom_source", e.target.value);
                                            }}
                                            disabled={!utils.checkSubStatus()}
                                        />
                                        {validationError.custom_source && (
                                            <small className="text-danger">
                                                please select required field.
                                            </small>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="form-group row light-bg">
                                <label className="col-sm-3 col-form-label">Raised by</label>
                                <div className="col-sm-6">
                                    <Select
                                        options={usersForDropDown}
                                        value={object?.raised_by}
                                        onChange={(data) => {
                                            handledFieldChanges("raised_by", data);
                                        }}
                                        isDisabled={!utils.checkSubStatus()}
                                    />
                                    {validationError.raised_by && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Date raised</label>
                                <div className="col-sm-6">
                                    <input
                                        type="date"
                                        className="date-selection"
                                        value={moment(object?.date_raised).format(
                                            APP_CONSTANTS.DATE_FORMAT_1
                                        )}
                                        onChange={(e) => {
                                            handledFieldChanges("date_raised", e.target.value, true);
                                        }}
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    {validationError.date_raised && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row light-bg">
                                <label className="col-sm-3 col-form-label">Assign to</label>
                                <div className="col-sm-6">
                                    <Select
                                        options={usersForDropDown}
                                        value={object?.assigned_to}
                                        onChange={(data) => {
                                            handledFieldChanges("assigned_to", data);
                                        }}
                                        isDisabled={!utils.checkSubStatus()}
                                    />
                                    {validationError.assigned_to && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Due Date</label>
                                <div className="col-sm-6">
                                    <input
                                        type="date"
                                        className="date-selection"
                                        min={moment().format("YYYY-MM-DD")}
                                        value={
                                            object.due_date
                                                ? moment(object.due_date).format("YYYY-MM-DD")
                                                : ""
                                        }
                                        onChange={(e) => {
                                            handledFieldChanges("due_date", e.target.value, true);
                                        }}
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    {validationError.due_date && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mt-4">
                            <div className="form-group">
                                <label>Action notes</label>
                                <textarea
                                    className="form-control"
                                    id=""
                                    rows="4"
                                    value={object?.action_notes}
                                    onChange={(e) => {
                                        handledFieldChanges("action_notes", e.target.value);
                                    }}
                                    disabled={!utils.checkSubStatus()}
                                />
                            </div>
                            <div className="form-group row light-bg add-evidence mb-0">
                                <label className="col-sm-9">Add attachment</label>
                                <div className="col-sm-3">
                                    <button
                                        type="button"
                                        className="btn-browse color-03 fs-12 fw-300 float-lg-right"
                                        data-toggle="modal"
                                        data-target="#proBrowseModal"
                                        disabled={!utils.checkSubStatus()}
                                    >
                                        <i className="far fa-folder-open" /> Browse
                                    </button>
                                </div>
                            </div>
                            <div className="row my-4 evidence-scroll">
                                {object?.attachments?.map((obj, index) => (
                                    <div className="col-sm-6 pl-0 mt-4" key={index}>
                                        <i
                                            className="far fa-minus-circle"
                                            onClick={() => removeEvidence(obj, index)}
                                        />
                                        {obj.extension === "images" && obj?.path && (
                                            <img loading="lazy" src={`${obj?.path}`} alt="Evidence" />
                                        )}
                                        {obj.extension === "videos" && obj?.path && (
                                            // <video
                                            //     className="cursor"
                                            //     onClick={() => {
                                            //         openLinkInNewTab(obj?.path);
                                            //     }}
                                            //     width="49"
                                            //     src={`${obj?.path}#t=0.1`}
                                            //     alt="Evidence"
                                            // />
                                            <img
                                                className="cursor"
                                                loading="lazy"
                                                onClick={() => {
                                                    openLinkInNewTab(obj?.path);
                                                }}
                                                src={videoIcon}
                                                alt="Evidence"
                                            />
                                        )}
                                        {obj.extension === "files" && obj?.path && (
                                            <img
                                                loading="lazy"
                                                className="cursor"
                                                onClick={() => {
                                                    openLinkInNewTab(obj?.path);
                                                }}
                                                src={getExtension(obj?.path)}
                                                alt="Evidence"
                                            />
                                        )}
                                        {
                                            <input
                                                className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                placeholder={"optional title"}
                                                value={obj?.custom_name}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "custom_title",
                                                        e.target.value,
                                                        true,
                                                        index
                                                    )
                                                }
                                                disabled={
                                                    !utils.checkSubStatus()
                                                        ? !utils.checkSubStatus()
                                                        : false
                                                }
                                            />
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {utils.checkSubscription() && (
                <div>
                    <div className="float-right my-2">
                        <button
                            className="updated-save-btn mr-1"
                            onClick={handleSave}
                            ref={btnRef}
                            disabled={
                                btnRef?.current?.disabled ? btnRef?.current?.disabled : disabled
                            }
                        >
                            <i className="far fa-save" /> Save
                        </button>
                        <button
                            className="btn-send"
                            onClick={handleSend}
                            ref={btnRef}
                            disabled={
                                btnRef?.current?.disabled ? btnRef?.current?.disabled : disabled
                            }
                        >
                            <i className="far fa-share" /> Send
                        </button>
                    </div>
                </div>
            )}
            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                            window.location.href = "/";
                        }}
                    ></div>
                    <SaveModal
                        id={"pro"}
                        h2={APP_CONSTANTS.PRO_CREATE_MESSAGE.h2}
                        h3={APP_CONSTANTS.PRO_CREATE_MESSAGE.h3}
                        p={APP_CONSTANTS.PRO_CREATE_MESSAGE.p}
                    />
                </>
            )}
            {error.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal(false));
                        }}
                    ></div>
                    <ErrorModal error={error} />
                </>
            )}
            {send && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSendModal({ send: false }));
                            window.location.href = "/";
                        }}
                    ></div>
                    <NotificationSendModal
                        h2={APP_CONSTANTS.PRO_SEND_MESSAGE.h2}
                        h3={APP_CONSTANTS.PRO_SEND_MESSAGE.h3}
                        p={APP_CONSTANTS.PRO_SEND_MESSAGE.p}
                    />
                </>
            )}
            {error?.sentError && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal(false));
                            dispatch(toggleSendModal({ error: { sentError: false } }));
                        }}
                    ></div>

                    <NotificationErrorModal
                        error={error}
                        h2={"Error while sending email"}
                    />
                </>
            )}
            <ProBrowseModal dispatch={dispatch} action={addAttachment} />
        </>
    );
};

export default CreatePro;
