import React from "react";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../Assets/images/back-arrow.png";
import Summary from "./Summary";

const Accounts = () => {
    let navigate = useNavigate();
    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-credit-card" /> Accounts summary
                        </h2>
                    </div>
                    <div className="col-12 col-lg-2">
                        <span
                            onClick={() => navigate(-1)}
                            className="back-to-pre fs-14 fw-300 py-4 color-04 float-right cursor"
                        >
                            <u>Back to previous page</u>
                            <img src={BackIcon} width="14px" alt="Back to Pre" />
                        </span>
                    </div>
                </div>
                <div className="accounts">
                    <Summary />
                </div>
            </div>
        </section>
    );
};

export default Accounts;
