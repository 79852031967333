import { TODO_CONSTANTS } from "../constants";

const reducer = (
    state = { list: [], filteredList: [], error: {}, success: {} },
    { type, payload }
) => {
    switch (type) {
        case TODO_CONSTANTS.GET_TODO:
            return payload;
            break;
        case TODO_CONSTANTS.GET_TODO_SUCCESS:
            return { list: payload };
            break;
        case TODO_CONSTANTS.GET_TODO_ERROR:
            return payload;
            break;
        case TODO_CONSTANTS.FILTER_TODO_LIST:
            return { ...state, filteredList: payload };
            break;
        default:
            return state;
    }
};

export default reducer;
