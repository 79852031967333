import { TODO_CONSTANTS } from "../constants";
import request from "../../services/request";
import { TODO_LIST } from "../../Routes/Api";
import session from "../../services/session";

export const getTodoList = (payload) => {
    return {
        type: TODO_CONSTANTS.GET_TODO,
        payload,
    };
};

export const getTodoListSuccess = (payload) => {
    return {
        type: TODO_CONSTANTS.GET_TODO_SUCCESS,
        payload,
    };
};

export const getTodoListError = (payload) => {
    return {
        type: TODO_CONSTANTS.GET_TODO_ERROR,
        payload,
    };
};

export const filterTodoList = (payload) => {
    return {
        type: TODO_CONSTANTS.FILTER_TODO_LIST,
        payload,
    };
};

export const getTodoListApi = (
    id = session.getUserId(),
    orgId = session.getOrgId()
) => {
    return (dispatch) => {
        request
            .get(`${TODO_LIST.GET_TODO_LIST_BY_USER_ID}${orgId}/${id}`, true)
            .then(
                (res) => {
                    dispatch(getTodoListSuccess(res?.data));
                    /*history.push(from);*/
                },
                (error) => {
                    dispatch(getTodoListError(error));
                }
            );
    };
};

export const getMultisiteTodoListApi = (id = session.getUserId()) => {
    return (dispatch) => {
        const { isAzure } = session.getUser();
        request
            .get(
                `${TODO_LIST.GET_MULTISITE_TODO_LIST}${id}?q=${isAzure ? "azure" : "ms"
                }`,
                true
            )
            .then(
                (res) => {
                    dispatch(getTodoListSuccess(res?.data));
                    /*history.push(from);*/
                },
                (error) => {
                    dispatch(getTodoListError(error));
                }
            );
    };
};
