import React, { useState } from "react";
import errorIcon from "../../Assets/images/error 1x.png";
import Select from "react-select";

const RemoveAdminModal = (props) => {
    const { id, removeAdmin, adminIndex, closeModal, sites } = props;

    const [error, setError] = useState(false);
    const [obj, setObj] = useState({});

    const removeMultisiteAdmin = (field, value) => {
        switch (field) {
            case "delete":
                setObj((prevState) => ({
                    type: "delete",
                }));
                setError(false);
                break;
            case "toMultiSite":
                setObj((prevState) => ({
                    type: "toMultiSite",
                }));
                setError(false);
                break;
            case "toSingleSite":
                setObj((prevState) => ({
                    type: "toSingleSite",
                }));
                setError(false);
                break;
            default:
        }
    };

    const onSiteSelect = (data) => {
        setObj((prevState) => ({
            ...prevState,
            site: data,
        }));
    };

    const onUpdate = () => {
        if (!obj?.type) {
            setError(true);
        } else {
            removeAdmin(adminIndex, obj);
            closeModal(false);
        }
    };

    return (
        <div className="save-modal removeAdminModal">
            <div
                className="modal fade show"
                id={`${"clearModal"}-${id}`}
                tabIndex="-1"
                aria-labelledby="clearModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center mb-4">
                                    <img
                                        src={errorIcon}
                                        alt="Warning"
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 mb-4">
                                        What do you want to do?
                                    </h2>
                                    <h3 className="color-05 fs-18 fw-600 mb-4">
                                        Select one of the options below.
                                    </h3>
                                    <div className="mr-5">
                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                id={"delete"}
                                                name="customRadio1"
                                                className="custom-control-input"
                                            />
                                            <label
                                                className="custom-control-label color-05 fs-14 fw-400"
                                                htmlFor={"delete"}
                                                onClick={() => removeMultisiteAdmin("delete", true)}
                                            >
                                                Completely remove this user
                                            </label>
                                        </div>

                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                id={"toMultiSite"}
                                                name="customRadio1"
                                                className="custom-control-input"
                                            />
                                            <label
                                                className="custom-control-label color-05 fs-14 fw-400"
                                                htmlFor={"toMultiSite"}
                                                onClick={() =>
                                                    removeMultisiteAdmin("toMultiSite", true)
                                                }
                                            >
                                                Change them to a multi-site user
                                            </label>
                                        </div>

                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                id={"toSingleSite"}
                                                name="customRadio1"
                                                className="custom-control-input"
                                            />
                                            <label
                                                className="custom-control-label color-05 fs-14 fw-400"
                                                htmlFor={"toSingleSite"}
                                                onClick={() =>
                                                    removeMultisiteAdmin("toSingleSite", true)
                                                }
                                            >
                                                Change them to a single site user
                                            </label>
                                        </div>
                                        {error && (
                                            <small className="text-danger">
                                                Please select atleast one option
                                            </small>
                                        )}
                                        {obj?.type === "toSingleSite" ? (
                                        <div className="my-3">
                                            <Select
                                                placeholder="Add a site..."
                                                options={sites}
                                                onChange={(data) => {
                                                    onSiteSelect(data);
                                                }}
                                            />
                                            {obj?.type === "toSingleSite" && !obj?.site?.value && (
                                                <small className="text-danger">
                                                    Please select site
                                                </small>
                                            )}
                                        </div>
                                    ) : null}
                                    </div>
                                    <div className="float-right mt-4">
                                        <button
                                            className="btn-no mr-2 p-2 fs-16"
                                            onClick={() => closeModal(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn-yes p-2 fs-16"
                                            onClick={() => onUpdate(adminIndex, obj)}
                                            disabled={
                                                obj?.type === "toSingleSite" && !obj?.site?.value
                                            }
                                        >
                                            Update user
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default RemoveAdminModal;
