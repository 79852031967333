import React from 'react';

const CompB = () => {
    return (
        <>
            <h2 id="b">B</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading27">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse27" aria-expanded="true"
                                    aria-controls="collapse27">
                                Back-up / Backed-up
                            </button>
                        </h2>
                    </div>
                    <div id="collapse27" className="collapse show" aria-labelledby="heading27"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To prevent digital documents from loss they must be backed-up. Loss of digital documents may be caused for many reasons, for example ; if they're deleted by accident, if they get corrupted by mistake or due to cyber crime. A back-up system takes a copy of all digital documents at a set frequency. The key aspects of a back-up are; a) how often the back-up happens, b) how long each back-up is kept for and c) if the back-up is readily available. For example if the back-up happens daily and only one copy is kept, meaning it is overwritten every day - this will not provide enough back-up to allow the issue to realised, before the back-up overwrites the files that are needed with the files that are missing or not wanted.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading28">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse28" aria-expanded="false"
                                    aria-controls="collapse28">
                                Batch
                            </button>
                        </h2>
                    </div>
                    <div id="collapse28" className="collapse" aria-labelledby="heading28"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A defined quantity of material.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading29">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse29" aria-expanded="false"
                                    aria-controls="collapse29">
                                Bill of materials
                            </button>
                        </h2>
                    </div>
                    <div id="collapse29" className="collapse" aria-labelledby="heading29"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A document which is used predominantly  for accounting and stock management, that can also contain all the information needed to produce a product to the standard agreed with the customer, including the formulation.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading30">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse30" aria-expanded="false"
                                    aria-controls="collapse30">
                                Bolt-on
                            </button>
                        </h2>
                    </div>
                    <div id="collapse30" className="collapse" aria-labelledby="heading30"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To add something. Typically this term is used when adding an additional module to the main certificate.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading31">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse31" aria-expanded="false"
                                    aria-controls="collapse31">
                                BOM
                            </button>
                        </h2>
                    </div>
                    <div id="collapse31" className="collapse" aria-labelledby="heading31"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Bill of materials. The BOM is a document which lists the materials and quantities that are required to process a product.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading32">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse32" aria-expanded="false"
                                    aria-controls="collapse32">
                                Brand
                            </button>
                        </h2>
                    </div>
                    <div id="collapse32" className="collapse" aria-labelledby="heading32"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            See also; customer brand, own brand
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading33">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse33" aria-expanded="false"
                                    aria-controls="collapse33">
                                BRCGS
                            </button>
                        </h2>
                    </div>
                    <div id="collapse33" className="collapse" aria-labelledby="heading33"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            BRC Global Standards, a GFSI recognised standard.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading34">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse34" aria-expanded="false"
                                    aria-controls="collapse34">
                                Brief
                            </button>
                        </h2>
                    </div>
                    <div id="collapse34" className="collapse" aria-labelledby="heading34"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A project, or product brief outlines the aims, expectations and limitations of the product to be developed. Typically, it includes information such as minimum order quantities, price, launch dates, target market, required product attributes etc.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading35">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse35" aria-expanded="false"
                                    aria-controls="collapse35">
                                Buddy
                            </button>
                        </h2>
                    </div>
                    <div id="collapse35" className="collapse" aria-labelledby="heading35"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A competent member of staff who supervises a new starter until they have completed their training.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                <div className="card-header" id="heading36">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse36" aria-expanded="false"
                                aria-controls="collapse36">
                            Business
                        </button>
                    </h2>
                </div>
                <div id="collapse36" className="collapse" aria-labelledby="heading36"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        The owner of the site. The business may own more than one site and they may also have a group function. Also known as the company.
                    </div>
                </div>
            </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading37">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse37" aria-expanded="false"
                                    aria-controls="collapse37">
                                Business performance reviews
                            </button>
                        </h2>
                    </div>
                    <div id="collapse37" className="collapse" aria-labelledby="heading37"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A structure of meetings where the business objectives from the business plan are reviewed. The structure includes business review meetings, performance review meetings, management meetings and one-to-one meetings.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading38">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse38" aria-expanded="false"
                                    aria-controls="collapse38">
                                Business review meetings
                            </button>
                        </h2>
                    </div>
                    <div id="collapse38" className="collapse" aria-labelledby="heading38"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Meetings held by management at least once per year (ideally 3 quarterly meetings with an end-of-year review), to check the results of business performance against the business plan.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading39">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse39" aria-expanded="false"
                                    aria-controls="collapse39">
                                By-product
                            </button>
                        </h2>
                    </div>
                    <div id="collapse39" className="collapse" aria-labelledby="heading39"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Materials whcih are made as a result of processing the product, but are not required. Typically, sold as an material for further processing.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
            </div>
        </>
    );
};

export default CompB;