import React, { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment";
import NcView from "./NcView";
import {
    createCaActionAPi,
    getCaActionAPi,
    createAndSendCaActionsApi,
    navigateHome,
    toggleEmailSuccessModal,
    toggleSuccessModal,
    toggleErrorModal,
    updateNcCaAction,
} from "../../../store/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { APP_CONSTANTS } from "../../../constants";
import {
    getAllUsersForDropDownApi,
    getUsersByRoleApi,
} from "../../../store/actions/user";
import SaveModal from "../../Modals/SaveModal";
import ErrorModal from "../../Modals/ErrorModal";
import NotificationSendModal from "../../Modals/NotificationSendModal";
import { getNcyIdAPi } from "../../../store/actions/nc";
import { ACTION_CONSTANTS } from "../../../store/constants";
import { ACTIONS } from "../../../Routes/Api";
import utils from "../../../utils";
import request from "../../../services/request";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { generateSequence } from "../../../utils/referenceId";

const Ca = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const btnRef = useRef(null);

    const { nc_ca_actions, success, send, error } = useSelector(
        (state) => state?.action
    );
    const { object } = useSelector((state) => state?.nc);
    const { nav } = useSelector((state) => state?.navigate);
    const { userDropdown, usersByRole } = useSelector((state) => state?.user);
    const [render, setRender] = useState(false);
    const [showError, setShowError] = useState(false);
    const [validationError, setValidationError] = useState([
        {
            corrective_action_notes: false,
            actioner: false,
            due_date: false,
        },
    ]);

    const actionaersArray = userDropdown?.filter((item) => {
        return item._id !== object?.verifier?.value;
    });

    let submit = true;
    const handleFieldChanges = (field, value, index, custom) => {
        if (value && value !== "") {
            setValidationErrorObj(field, false, index);
        } else {
            setValidationErrorObj(field, true, index);
        }

        let actionCopy = [...nc_ca_actions];
        let currentOj = actionCopy[index];
        if (!custom) {
            currentOj[field] = value;
            dispatch(updateNcCaAction(actionCopy));
            return;
        }

        switch (field) {
            case "due_date":
                currentOj[field] = value;
                currentOj["dateChange"] = true;
                break;
            default:
                return;
        }
        dispatch(updateNcCaAction(actionCopy));
    };

    const addMoreCaAction = (ind) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            if (ind === nc_ca_actions?.length - 1) {
                let actionCopy = [...nc_ca_actions];
                actionCopy.push(
                    ACTION_CONSTANTS.GET_DEFAULT_CA_ACTION_OBJ(object?._id)
                );
                const temp = validationError;
                temp.push({
                    corrective_action_notes: false,
                    actioner: false,
                    due_date: false,
                });
                setValidationError(temp);
                dispatch(updateNcCaAction(actionCopy));
                setShowError(() => {
                    return false;
                });
            }
        }
    };

    const deleteActions = async (id, index) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            if (id && nc_ca_actions?.length > 1 && index >= 0) {
                const data = { actionId: id, type: "CA" };
                request.delete(ACTIONS.DELETE_CA_ACTION, data, true).then((res) => {
                    const temp = validationError;
                    temp.splice(index, 1);
                    setValidationError(temp);
                    nc_ca_actions?.splice(index, 1);
                    dispatch(updateNcCaAction(nc_ca_actions));
                    // setRender(() => {
                    //     return !render;
                    // });
                });
                setShowError(() => {
                    return false;
                });
            } else if (index >= 0 && !id) {
                const temp = validationError;
                temp.splice(index, 1);
                setValidationError(temp);
                nc_ca_actions.splice(index, 1);
                dispatch(updateNcCaAction(nc_ca_actions));
                setShowError(() => {
                    return false;
                });
            } else {
                setShowError(() => {
                    return true;
                });
            }
        }
    };

    const saveActions = async () => {
        let filterNcCaActions = nc_ca_actions?.filter(
            (item) =>
                item?.status === "Draft" ||
                item?.status === "In progress" ||
                item?.status === "Overdue"
        );
        btnRef.current.disabled = true;
        if (validateActions()) return;
        else {
            if (submit) {
                dispatch(
                    createCaActionAPi({ nc: object?._id, ca_actions: filterNcCaActions })
                );
            }
        }
    };

    const validateActions = () => {
        let actionCopy = [...nc_ca_actions];
        let errorFlag = false;
        setValidationError({});
        actionCopy.forEach((obj, index) => {
            if (!obj?._id) obj.create = true;
            else obj.update = true;

            if (!obj?.corrective_action_notes) {
                submit = false;
                obj.noteError = true;
                setValidationErrorObj("corrective_action_notes", true, index);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (!obj?.actioner) {
                submit = false;
                obj.actionError = true;
                setValidationErrorObj("actioner", true, index);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (!obj?.due_date) {
                submit = false;
                obj.dateError = true;
                setValidationErrorObj("due_date", true, index);
                errorFlag = true;
                btnRef.current.disabled = false;
            }
            if (!obj?.status) {
                submit = false;
                obj.statusError = true;
            }
        });
        dispatch(updateNcCaAction(actionCopy));
    };

    const sendCaActions = () => {
        let filterNcCaActions = nc_ca_actions?.filter(
            (item) =>
                item?.status === "Draft" ||
                item?.status === "In progress" ||
                item?.status === "Overdue"
        );
        btnRef.current.disabled = true;
        if (validateActions()) return;
        else {
            if (submit) {
                // if (ca_actions[0]?.history_logs[0]?.to) {
                //     ca_actions[0].history_logs[0].to = ca_actions[0]?.actioner?._id
                // }
                dispatch(
                    createAndSendCaActionsApi({
                        nc: object?._id,
                        ca_actions: filterNcCaActions,
                    })
                );
            }
        }

        /*let data = {
                                    nc: object?._id,
                                    ca_actions: ca_actions
                                }
                                dispatch(sentCaActionsApi(data))*/
    };

    const setValidationErrorObj = (key, flag, index) => {
        const temp = validationError;
        const tempObj = {
            ...temp[index],
            [key]: flag,
        };
        temp[index] = tempObj;
        setValidationError(temp);
    };

    if (nav === true) {
        navigate(`/private/home`);
        dispatch(navigateHome(false));
    }

    useEffect(() => {
        // if (!userDropdown?.length)
        dispatch(getAllUsersForDropDownApi());
        if (!usersByRole?.length) dispatch(getUsersByRoleApi());
        if (
            nc_ca_actions[0] &&
            !nc_ca_actions[0]?._id &&
            !object?.ca_actions?.length
        ) {
            let actionCopy = [...nc_ca_actions];
            actionCopy[0]["nc"] = object?._id;
            dispatch(updateNcCaAction(actionCopy));
            dispatch(getCaActionAPi({ nc: object?._id, org: object?.org }));
        } else {
            if (nc_ca_actions[0]?.nc !== object?._id)
                dispatch(
                    updateNcCaAction([
                        ACTION_CONSTANTS.GET_DEFAULT_CA_ACTION_OBJ(
                            object?._id,
                            object?.org
                        ),
                    ])
                );
            dispatch(getCaActionAPi({ nc: object?._id, org: object?.org }));
        }
    }, [object, render]);

    useEffect(() => {
        if (nc_ca_actions[0]?._id && !object?.ca_actions?.length && object?._id)
            dispatch(getNcyIdAPi(object?._id, object?.org));
    }, [nc_ca_actions, render]);

    useEffect(() => {
        dispatch(getCaActionAPi({ nc: object?._id, org: object?.org }));
    }, [render]);

    useEffect(() => {
        if (error && btnRef?.current) {
            btnRef.current.disabled = false;
        }
    }, [error]);

    return (
        <section className="reference">
            <div className="container-fluid">
                <div className="row">
                    <NcView />
                    {/* Column End */}
                    <div className="col-12 col-lg-6 mt-3">
                        {nc_ca_actions?.length > 0 &&
                            nc_ca_actions?.map((obj, index) => (
                                <>
                                    {index !== 0 && <div className="divider mb-3"></div>}
                                    <div className="" key={index}>
                                        <h2 className="fs-16 fw-600 color-04">
                                            Corrective action notes{" "}
                                            <span className="float-right">
                                                Reference: {`CA-${generateSequence(obj?.id || 0)}`}
                                            </span>
                                        </h2>
                                        <textarea
                                            className="form-control"
                                            id=""
                                            rows="5"
                                            value={
                                                obj?.corrective_action_notes
                                                    ? obj?.corrective_action_notes
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                handleFieldChanges(
                                                    "corrective_action_notes",
                                                    e.target.value,
                                                    index
                                                );
                                            }}
                                            disabled={!utils.checkSubStatus()}
                                        />
                                        {validationError[index]?.corrective_action_notes && (
                                            <small className="text-danger">
                                                You must enter the Corrective Action notes
                                            </small>
                                        )}
                                        <div className="mt-4">
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">
                                                    Actioner
                                                </label>
                                                <div className="col-sm-5">
                                                    <Select
                                                        options={actionaersArray}
                                                        value={obj?.actioner ? obj?.actioner : ""}
                                                        onChange={(data) => {
                                                            handleFieldChanges("actioner", data, index);
                                                        }}
                                                        isDisabled={!utils.checkSubStatus()}
                                                    />
                                                    {validationError[index]?.actioner && (
                                                        <small className="text-danger">
                                                            please select required field.
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-group row light--bg py-2">
                                                    <label className="col-sm-2 col-form-label">
                                                        Due date
                                                    </label>
                                                    <div className="col-sm-5">
                                                        <input
                                                            type="date"
                                                            className="date-selection"
                                                            min={moment().format("YYYY-MM-DD")}
                                                            value={
                                                                obj?.due_date
                                                                    ? moment(obj?.due_date).format("YYYY-MM-DD")
                                                                    : ""
                                                            }
                                                            onChange={(e) =>
                                                                handleFieldChanges(
                                                                    "due_date",
                                                                    e.target.value,
                                                                    index,
                                                                    true
                                                                )
                                                            }
                                                            disabled={!utils.checkSubStatus()}
                                                        />
                                                        {validationError[index]?.due_date && (
                                                            <small className="text-danger">
                                                                please select required field.
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">
                                                    Status
                                                </label>
                                                <div className="col-sm-5">
                                                    <Select
                                                        options={APP_CONSTANTS.CA_STATUS}
                                                        isDisabled={
                                                            !utils.checkSubStatus()
                                                                ? !utils.checkSubStatus()
                                                                : true
                                                        }
                                                        value={
                                                            obj?.status
                                                                ? {
                                                                    label:
                                                                        obj?.status === "Completed" ||
                                                                            obj?.status === "Completed late"
                                                                            ? "Awaiting verification"
                                                                            : obj?.status,
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(data) =>
                                                            handleFieldChanges("status", data.label, index)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <dl
                                        className="row mt-4"
                                        onClick={() => {
                                            addMoreCaAction(index);
                                        }}
                                    >
                                        <dt className="col-sm-9 cursor">
                                            <i className="far fa-plus-circle" /> Add more corrective
                                            actions
                                        </dt>
                                    </dl>
                                    <dl
                                        className="row mt-4"
                                        onClick={() => {
                                            deleteActions(obj._id, index);
                                        }}
                                    >
                                        {nc_ca_actions?.length > 1 &&
                                            ((obj?._id && obj?.status === "Draft") || !obj?._id) && (
                                                <dt className="col-sm-9 cursor">
                                                    <i className="far fa-minus-circle" /> Delete
                                                    corrective actions
                                                </dt>
                                            )}
                                        {showError && obj?._id && (
                                            <small className="text-danger">
                                                This is the only CA added in NC. Add more to delete it.
                                            </small>
                                        )}
                                    </dl>
                                </>
                            ))}
                    </div>
                    {/* Column End */}
                </div>
            </div>
            {utils.checkSubscription() && (
                <div className="float-right mt-4">
                    <button
                        className="btn-save mr-2"
                        onClick={saveActions}
                        ref={btnRef}
                        disabled={btnRef?.current?.disabled}
                    >
                        <i className="far fa-save" /> Save
                    </button>
                    <button
                        className="btn-send"
                        onClick={sendCaActions}
                        ref={btnRef}
                        disabled={btnRef?.current?.disabled}
                    >
                        <i className="far fa-share" /> Send
                    </button>
                </div>
            )}
            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                            window.location.href = "/";
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.CA_CREATE_MESSAGE.h2}
                        h3={APP_CONSTANTS.CA_CREATE_MESSAGE.h3}
                        p={APP_CONSTANTS.CA_CREATE_MESSAGE.p}
                    />
                </>
            )}
            {error?.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal({ show: false, error: {} }));
                        }}
                    ></div>
                    <ErrorModal error={error.error} />
                </>
            )}
            {send && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleEmailSuccessModal({ send: false }));
                            window.location.href = "/";
                        }}
                    ></div>
                    <NotificationSendModal h2={APP_CONSTANTS.ACTION_SEND.h2} />
                </>
            )}
        </section>
    );
};

export default Ca;
