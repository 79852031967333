import moment from "moment";
import session from "../services/session";
import { APP_CONSTANTS } from "../constants";

const getRemainDays = (fromDate, toDate) => {
    var now = moment(fromDate);
    var end = moment(toDate); // another date
    var duration = moment.duration(end.diff(now));
    var days = duration.asDays();
    duration.subtract(moment.duration(days, "days"));
    var hours = duration.hours();
    duration.subtract(moment.duration(hours, "hours"));
    var minutes = duration.minutes();
    duration.subtract(moment.duration(minutes, "minutes"));
    var seconds = duration.seconds();
    return {
        days: parseInt(days.toFixed(0)),
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    };
};

const checkPermissions = (perm, object, role) => {
    let user = session.getUser();
    if (!Boolean(user)) return false;
    if (user?.userType === "admin" || user?.userType === "superAdmin")
        return true;

    let { permissions, userType } = user;
    let flag = false;

    switch (perm) {
        case APP_CONSTANTS.PERMISSION_CONST.MAKE_ACTION:
            if (permissions.system_audits.account_manage) flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.CREATE_NC:
            if (
                permissions?.general?.system_manager ||
                permissions?.actions?.identifier
            )
                flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.EDIT_NC:
            if (
                permissions?.general?.system_manager ||
                userType === "admin" ||
                userType === "superAdmin"
            )
                flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.DELEGATE_NC:
            if (
                permissions?.general?.system_manager ||
                permissions?.system_audits?.account_manager
            )
                flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.CREATE_PRO:
            if (
                permissions?.general?.system_manager ||
                permissions?.system_audits.account_manager ||
                permissions?.system_audits.auditor ||
                permissions?.gmp_inspections.inspector ||
                permissions?.actions.root_cause_analysis ||
                permissions?.actions.identifier ||
                permissions?.default_user_roles?.proactive_action ||
                permissions?.default_user_roles?.corrective_action
            )
                flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.CREATE_CA:
            if (
                permissions?.general?.system_manager ||
                permissions?.default_user_roles?.corrective_action ||
                permissions?.default_user_roles?.proactive_action
            ) {
                flag = true;
            }
            // if (permissions?.default_user_roles.corrective_action)

            break;
        case APP_CONSTANTS.PERMISSION_CONST.CREATE_RCA:
            if (
                permissions?.general?.system_manager ||
                permissions?.actions?.root_cause_analysis
            )
                flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.CREATE_PA:
            // // if (permissions?.general?.system_manager || permissions?.system_audits?.account_manager
            //     // || permissions?.actions?.root_cause_analysis || permissions?.actions.identifier)
            // if (permissions?.default_user_roles.proactive_action)
            if (
                permissions?.general?.system_manager ||
                permissions?.default_user_roles?.corrective_action ||
                permissions?.default_user_roles?.proactive_action
            ) {
                flag = true;
            }
            break;
        case APP_CONSTANTS.PERMISSION_CONST.COMPLETE_PRO:
            if (
                permissions?.general?.system_manager ||
                permissions?.system_audits?.account_manager ||
                permissions?.system_audits?.auditor ||
                permissions?.gmp_inspections?.inspector ||
                permissions?.actions?.root_cause_analysis ||
                permissions?.actions.identifier
            )
                flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.COMPLETE_CA:
            if (
                permissions?.general?.system_manager ||
                permissions?.system_audits?.account_manager ||
                permissions?.actions.verifier ||
                permissions?.actions.identifier
            )
                flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.COMPLETE_RCA:
            if (
                permissions?.general?.system_manager ||
                permissions?.actions?.root_cause_analysis
            )
                flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.COMPLETE_PA:
            if (
                permissions?.general?.system_manager ||
                permissions?.actions?.root_cause_analysis ||
                permissions?.actions.verifier
            )
                flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.VIEW_NC_SUMMARY:
            if (
                permissions?.general?.system_manager ||
                permissions?.system_audits?.account_manager ||
                permissions?.system_audits?.auditor ||
                permissions?.gmp_inspections?.inspector ||
                permissions?.actions?.root_cause_analysis ||
                permissions?.actions.identifier
            )
                flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.CREATE_USER:
            if (permissions?.general?.system_manager) flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.ACCOUNTS:
            if (permissions?.general?.accounts) flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.SETTING_BUTTON:
            if (permissions?.general?.system_manager) flag = true;
            break;
        case APP_CONSTANTS.PERMISSION_CONST.ACTIONER_TAB:
            // if (
            //     permissions?.default_user_roles.corrective_action ||
            //     permissions?.default_user_roles.proactive_action
            // )
            //     flag = true;
            if (permissions?.general?.system_manager) {
                flag = true;
            }
            break;
        case APP_CONSTANTS.PERMISSION_CONST.VERIFIER_TAB:
            if (permissions?.actions.verifier) flag = true;
            break;

        case APP_CONSTANTS.PERMISSION_CONST.RESEND_PASSWORD:
            if (
                permissions?.general?.system_manager ||
                userType === "admin" ||
                userType === "superAdmin"
            )
                flag = true;
            break;
        default:
            return flag;
    }
    return flag;
};

const checkSuperAdmin = () => {
    let user = session.getUser();
    return user?.userType === "superAdmin" ? true : false;
};

const compareUserIdInObject = (obj, field) => {
    let user = session.getUser();
    if (!Boolean(user)) return false;
    switch (field) {
        case "auditor":
            if (obj?.auditor?.value === user?._id) return true;
            break;
        case "accountable_manager":
            if (obj?.accountable_manager?.value === user?._id) return true;
            break;

        default:
            return true;
    }
};

const getTwoDecimalValue = (num) => (Math.round(num * 100) / 100).toFixed(2);
const convertStripeValue = (value) => value / 100;

const getFormattedValue = (number) => {
    let conv = convertStripeValue(number);
    let dec = getTwoDecimalValue(conv);
    return dec;
};

const convertStripeDate = (date, format = "DD MMMM YYYY") => {
    try {
        let changeDate = date * 1000;
        let convertDate = moment(changeDate).format(format);
        return convertDate;
    } catch (e) { }
};

const checkSubscription = () => {
    let org = session.getOrg();
    if (
        org?.subscription?.status === "Canceled" ||
        org?.subscription?.status === "canceled" ||
        org?.subscription?.status === "Due"
    )
        return false;
    else return true;
};

const managerAndAdmin = () => { };

const checkCanceledSubscription = () =>
    session.getOrg()?.subscription?.status === "Canceled" || session.getOrg()?.subscription?.status === "canceled" ;

const checkSubStatus = () => {
    let org = session.getOrg();
    if (
        org?.subscription?.status === "Canceled" ||
        org?.subscription?.status === "canceled" ||
        org?.subscription?.status === "Due"
    )
        return false;
    else return true;
};
const checkSubsPubLicRoutes = () =>
    session.getOrg()?.subscription?.status === "Active" ||
        session.getOrg()?.subscription?.status === "Trail"
        ? true
        : false;

const checkSubsPrivateRoutes = (object) =>
    session.getOrg()?.subscription?.status === "Active" ||
        session.getOrg()?.subscription?.status === "Trail" ||
        object?.userType === "superAdmin"
        ? true
        : false;

const resetOrgDataInSession = (data) => session.setOrg(data);

const calculateTotalAmountWithTax = (amount, tax, percentage) => {
    let taxAbleAmount = getTaxAbleAmount(amount, percentage);
    let payAbleAmount = (taxAbleAmount + convertStripeValue(amount)) * 100;
    return {
        taxAbleAmount,
        payAbleAmount,
    };
};

const handleTaxCalculations = (amount, taxObjects, origin = "GB") => {
    let payAbleAmount = amount;
    let tax = findApplicableTax(taxObjects, origin);
    if (tax) {
        tax.taxAbleAmount = getTaxAbleAmount(amount, tax.percentage);
        payAbleAmount = (tax.taxAbleAmount + convertStripeValue(amount)) * 100;
    }
    return {
        tax,
        payAbleAmount,
    };
};

const findApplicableTax = (taxes, origin) =>
    taxes.find((data) => data.country === origin && data.active);

const getTaxAbleAmount = (amount, percentage) =>
    ((amount / 100) * percentage) / 100;

const returnStringAccordingToDays = (value) => {
    var year, months, week, days;

    year = value >= 365 ? Math.floor(value / 365) : 0;
    value = year ? value - year * 365 : value;

    months = value >= 30 ? Math.floor((value % 365) / 30) : 0;
    value = months ? value - months * 30 : value;

    week = value >= 7 ? Math.floor((value % 365) / 7) : 0;
    value = week ? value - week * 7 : value;

    days = value < 7 ? Math.floor((value % 365) % 7) : 0;

    if (year > 1) return `year after`;
    else if (months > 1) return `month after`;
    else if (days > 1) return `day after`;
    /*if(days<7)
              return `${days} ${days>1?"days":"day"} after`
                    if(days>7 && days<8)
                        return `week after`
                    if(days>27 && days<32)
                        return `month after`*/
};

const getCurrentUserForDropDown = (dispatch, action, object, key) => {
    let user = session.getUser();
    if (user) {
        object[key] = {
            label: user.user_name,
            value: user._id,
            user: user._id,
        };

        if (dispatch) {
            dispatch(action(object));
        } else {
            action(object);
        }
    }
};

const getRaisedByUser = (dispatch, action, object, key, users) => {
    let us = users.find((obj) => obj.value == session.getUser()?._id);
    if (us) {
        object[key] = {
            label: us.label,
            value: us.value,
            user: us.value,
        };
        dispatch(action(object));
    }
};

const checkObjectStatus = (object, status, condition = "EQ") => {
    if (condition === "EQ") return object.status === status;
    else return object.status !== status;
};

const checkNcAccepted = (object) => {
    if (
        object.status === "Accepted" ||
        object.status === "In progress" ||
        object?.status === "Overdue"
    )
        return true;
    else return false;
};

const checkFieldShowConditionsForNcAcceptPage = (field, nc) => {
    try {
        let user = session.getUser();
        if (!Boolean(user)) return false;
        let { permissions } = user;
        switch (field) {
            case "action":
                if (nc.actioner.value === user?._id || nc.auto_actions === false)
                    return true;
                break;
            default:
                return false;
        }
    } catch (e) { }
};

const checkNcPermissions = (permission, ncObj, caActions, paActions) => {
    try {
        let flag = false;
        let user = session.getUser();
        if (!Boolean(user)) return false;
        if (user?.userType === "admin" || user?.userType === "superAdmin")
            return true;

        switch (permission) {
            case APP_CONSTANTS.PERMISSION_CONST.ACTIONER_TAB:
                if (!caActions.length && !paActions.length) return false;
                if (caActions)
                    flag = caActions.some(
                        (obj) => obj.actioner && obj.actioner.value == user._id
                    );
                if (paActions && !flag)
                    flag = paActions.some(
                        (obj) => obj.actioner && obj.actioner.value == user._id
                    );
                return flag;
            case APP_CONSTANTS.PERMISSION_CONST.VERIFIER_TAB:
                if (
                    ncObj?.verifier?.value == user?._id &&
                    (caActions?.length || paActions?.length)
                )
                    return true;
            default:
                return false;
        }
    } catch (e) {
        return false;
    }
};

export default {
    getRemainDays,
    checkPermissions,
    getFormattedValue,
    getTwoDecimalValue,
    convertStripeValue,
    convertStripeDate,
    checkSubsPubLicRoutes,
    checkSubsPrivateRoutes,
    checkSubscription,
    checkCanceledSubscription,
    resetOrgDataInSession,
    calculateTotalAmountWithTax,
    handleTaxCalculations,
    returnStringAccordingToDays,
    checkSuperAdmin,
    getCurrentUserForDropDown,
    checkObjectStatus,
    checkNcAccepted,
    chFiShCoFoAcAccP: checkFieldShowConditionsForNcAcceptPage,
    checkNcPermissions,
    getRaisedByUser,
    checkSubStatus,
};
