import React, { useEffect, useState } from "react";
import { changeTabState } from "../../store/actions/tabState";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { APP_CONSTANTS } from "../../constants";
import Subscriptions from "./Subscriptions";
import CreateMultisite from "./CreateMultisite/CreateMultisite";
import MultiSite from "./MultiSite/MultiSite";
import EditMultisite from "./EditMultisite/EditMultisite";
import session from "../../services/session";
import UploadSpreadsheet from "./UploadSpreadsheet/UploadSpreadsheet";

const SuperAdmin = () => {
    const dispatch = useDispatch();

    const tabState = useSelector((state) => state?.tabState);
    const { subsList, filteredSubscriptionsList } = useSelector(
        (state) => state?.subscriptions
    );
    const { multiSitesList, filteredMultisteList } = useSelector(
        (state) => state?.sites
    );

    const [csvExportHeader, setCsvExportHeader] = useState();
    const [fileName, setFileName] = useState();
    const [csvReport, setCsvReport] = useState([]);

    const arrangeDataForExport = () => {
        switch (tabState?.tab) {
            case 1:
                let listCSvExportSubscriptions = [];
                if (filteredSubscriptionsList?.length)
                    listCSvExportSubscriptions = JSON.parse(
                        JSON.stringify(filteredSubscriptionsList)
                    );
                else if (subsList?.length) {
                    listCSvExportSubscriptions = JSON.parse(JSON.stringify(subsList));
                }
                listCSvExportSubscriptions = listCSvExportSubscriptions.map((obj) => {
                    return APP_CONSTANTS.SUBSCRIPTIONS_EXPORT_DATA_SET(obj);
                });
                setCsvReport(listCSvExportSubscriptions);
                break;
            case 2:
                let listCSvExportMultisites = [];
                if (filteredMultisteList?.length)
                    listCSvExportMultisites = JSON.parse(
                        JSON.stringify(filteredMultisteList)
                    );
                else if (multiSitesList?.length) {
                    listCSvExportMultisites = JSON.parse(JSON.stringify(multiSitesList));
                }
                listCSvExportMultisites = listCSvExportMultisites.map((obj) => {
                    return APP_CONSTANTS.MULTI_SITES_LIST_EXPORT_DATA_SET(obj);
                });
                setCsvReport(listCSvExportMultisites);
                break;
            default:
        }
    };

    const handleChangeTab = (tabId) => {
        dispatch(changeTabState(tabId));
    };

    useEffect(() => {
        dispatch(changeTabState(1));
    }, []);

    useEffect(() => {
        switch (tabState.tab) {
            case 1:
                setFileName(() => {
                    return "subscriptions.csv";
                });
                setCsvExportHeader(() => {
                    return APP_CONSTANTS.SUBS_EXPORT_HEADERS;
                });
                break;
            case 2:
                setFileName(() => {
                    return "multiSiteList.csv";
                });
                setCsvExportHeader(() => {
                    return APP_CONSTANTS.MULTI_SITES_LIST_EXPORT_HEADERS;
                });
                break;
            default:
        }
    }, [tabState]);

    useEffect(() => {
        session.removeOrgId();
        session.removeOrg();
    }, []);

    return (
        <section className="todo-list pt-5">
            <div className="container-fluid">
                <div className="tab-content">
                    {(tabState?.tab === 1 || tabState?.tab === 2) && (
                        <CSVLink
                            data={csvReport}
                            headers={csvExportHeader}
                            filename={fileName}
                            className={"btn btn-export float-right mt-0"}
                            asyncOnClick={true}
                            onClick={(event, done) => {
                                arrangeDataForExport();
                                done();
                            }}
                        >
                            <i className="far fa-file-export" /> Export
                        </CSVLink>
                    )}
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" onClick={() => handleChangeTab(1)}>
                            <a
                                className={`nav-link ${tabState?.tab === 1 ? "active" : ""}`}
                                role="tab"
                            >
                                Subscriptions
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => handleChangeTab(2)}>
                            <a
                                className={`nav-link ${tabState?.tab === 2 ? "active" : ""}`}
                                role="tab"
                            >
                                Multi-sites
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => handleChangeTab(3)}>
                            <a
                                className={`nav-link ${tabState?.tab === 3 ? "active" : ""}`}
                                role="tab"
                            >
                                Create multi-site
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => handleChangeTab(5)}>
                            <a
                                className={`nav-link ${tabState?.tab === 5 ? "active" : ""}`}
                                role="tab"
                            >
                                Upload spreadsheet
                            </a>
                        </li>
                        {tabState?.tab === 4 && (
                            <li className="nav-item" onClick={() => handleChangeTab(4)}>
                                <a
                                    className={`nav-link ${tabState?.tab === 4 ? "active" : ""}`}
                                    role="tab"
                                >
                                    Edit multi-site
                                </a>
                            </li>
                        )}
                    </ul>

                    <div className="tab-content tab-box">
                        {tabState?.tab === 1 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 1 ? "active" : ""
                                    }`}
                                id="tabs-1"
                                role="tabpanel"
                            >
                                <Subscriptions />
                            </div>
                        )}
                        {tabState?.tab === 2 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 2 ? "active" : ""
                                    }`}
                                id="tabs-2"
                                role="tabpanel"
                            >
                                <MultiSite />
                            </div>
                        )}
                        {tabState?.tab === 3 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 3 ? "active" : ""
                                    }`}
                                id="tabs-3"
                                role="tabpanel"
                            >
                                <CreateMultisite />
                            </div>
                        )}
                        {tabState?.tab === 5 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 5 ? "active" : ""
                                    }`}
                                id="tabs-5"
                                role="tabpanel"
                            >
                                <UploadSpreadsheet />
                            </div>
                        )}
                        {tabState?.tab === 4 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 4 ? "active" : ""
                                    }`}
                                id="tabs-4"
                                role="tabpanel"
                            >
                                <EditMultisite />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SuperAdmin;
