import React from 'react';

const CompK = () => {
    return (
        <>
            <h2 id="k">K</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading165">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse165" aria-expanded="true"
                                    aria-controls="collapse165">
                                Kick-plate
                            </button>
                        </h2>
                    </div>
                    <div id="collapse165" className="collapse show" aria-labelledby="heading165"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Typically made of a sheet of metal, a kick-plate stops debris from your shoes from being kicked over the edge of steps or walkways. Also known as back plates.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading166">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse166" aria-expanded="false"
                                    aria-controls="collapse166">
                                Knowledge
                            </button>
                        </h2>
                    </div>
                    <div id="collapse166" className="collapse" aria-labelledby="heading166"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Understanding of a subject or task. This skill can be gained through experience or study.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading167">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse167" aria-expanded="false"
                                    aria-controls="collapse167">
                                KPI
                            </button>
                        </h2>
                    </div>
                    <div id="collapse167" className="collapse" aria-labelledby="heading167"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A key performance indicator. These are a set of criteria with targets, that are measured and monitored for performance.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompK;