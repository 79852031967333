import { SUBSCRIPTIONS_CONSTANT } from "../constants";
import request from "../../services/request";
import { ORG } from "../../Routes/Api";

export const getSubscriptionsList = (payload) => {
    return {
        type: SUBSCRIPTIONS_CONSTANT.GET_SUBSCRIPTIONS,
        payload,
    };
};
export const getSubscriptionsListSuccess = (payload) => {
    return {
        type: SUBSCRIPTIONS_CONSTANT.GET_SUBSCRIPTIONS_SUCCESS,
        payload,
    };
};
export const getSubscriptionsListError = (payload) => {
    return {
        type: SUBSCRIPTIONS_CONSTANT.GET_SUBSCRIPTIONS_ERROR,
        payload,
    };
};
export const filterSubscriptionsList = (payload) => {
    return {
        type: SUBSCRIPTIONS_CONSTANT.FILTER_SUBSCRIPTIONS_LIST,
        payload,
    };
};

export const getSubscriptionsListApi = () => {
    return (dispatch) => {
        request.get(ORG.GET_ALL_SUBS_ORGS, true).then(
            (res) => {
                dispatch(getSubscriptionsListSuccess(res?.data));
            },
            (error) => {
                dispatch(getSubscriptionsListError(error));
            }
        );
    };
};
