import { TOPIC_CONSTANTS } from "../constants";
import request from "../../services/request";
import { TOPIC } from "../../Routes/Api";
import { APP_CONSTANTS } from "../../constants";
import session from "../../services/session";


export const getTopicList = (payload) => {
    return {
        type: TOPIC_CONSTANTS.GET_TOPICS_LIST,
        payload,
    };
};

export const getTopicListSuccess = (payload) => {
    return {
        type: TOPIC_CONSTANTS.GET_TOPICS_LIST_SUCCESS,
        payload,
    };
};

export const getTopicListError = (payload) => {
    return {
        type: TOPIC_CONSTANTS.GET_TOPICS_LIST_ERROR,
        payload,
    };
};

export const getTopicDropdown = (payload) => {
    return {
        type: TOPIC_CONSTANTS.GET_TOPICS_DROPDOWN,
        payload,
    };
};

export const getTopicDropdownSuccess = (payload) => {
    return {
        type: TOPIC_CONSTANTS.GET_TOPICS_DROPDOWN_SUCCESS,
        payload,
    };
};
export const getTopicDropdownError = (payload) => {
    return {
        type: TOPIC_CONSTANTS.GET_TOPICS_DROPDOWN_ERROR,
        payload,
    };
};

export const updateTopicObject = (payload) => {
    return {
        type: TOPIC_CONSTANTS.UPDATE_TOPIC_OBJECT,
        payload,
    };
};

export const updateTopicSuccess = (payload) => {
    return {
        type: TOPIC_CONSTANTS.UPDATE_TOPIC_SUCCESS,
        payload,
    };
};

export const updateTopicError = (payload) => {
    return {
        type: TOPIC_CONSTANTS.UPDATE_TOPIC_ERROR,
        payload,
    };
};

export const toggleTopicSuccessMessage = (payload) => {
    return {
        type: TOPIC_CONSTANTS.TOGGLE_TOPIC_SUCCESS_MESSAGE,
        payload,
    };
};

export const toggleTopicErrorMessage = (payload) => {
    return {
        type: TOPIC_CONSTANTS.TOGGLE_TOPIC_ERROR_MESSAGE,
        payload,
    };
};

export const getTopicListApi = (org = session.getOrgId()) => {
    return (dispatch) => {
        request.get(`${TOPIC.GET_TOPICS_LIST}${org}`, true).then(
            (res) => {
                dispatch(getTopicListSuccess(res?.data));
                /*history.push(from);*/
            },
            (error) => { }
        );
    };
};

export const getTopicDropdownApi = (org = session.getOrgId()) => {
    return (dispatch) => {
        request.get(`${TOPIC.GET_TOPICS_DROPDOWN}${org}`, true).then(
            (res) => {
                dispatch(getTopicDropdownSuccess(res?.data));
                /*history.push(from);*/
            },
            (error) => { }
        );
    };
};

export const updateTopicApi = (data) => {
    return (dispatch) => {
        request.put(TOPIC.UPDATE_TOPIC, data, true).then(
            (res) => {
                dispatch(updateTopicSuccess(res?.data));
                dispatch(getTopicListApi());
                /*history.push(from);*/
                setTimeout(() => {
                    dispatch(toggleTopicSuccessMessage(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(updateTopicError(error));
                setTimeout(() => {
                    dispatch(toggleTopicErrorMessage(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};
