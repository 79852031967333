import { NC_CONSTANTS } from "../constants";

const reducer = (
    state = {
        object: { ...NC_CONSTANTS.GET_DEFAULT_NC_OBJ() },
        list: [],
        filteredList: [],
        error: {},
        success: false,
        send: false,
        disabled: false,
        loader: false,
    },
    { type, payload }
) => {
    switch (type) {
        case NC_CONSTANTS.CREATE_NC:
            return {
                ...state,
                disabled: true,
            };
        case NC_CONSTANTS.CREATE_NC_SUCCESS:
            return {
                ...state,
                object: payload,
                disabled: false,
                success: true,
            };
        case NC_CONSTANTS.UPDATE_NC_SUCCESS:
            return {
                ...state,
                object: payload,
                success: true,
            };
        case NC_CONSTANTS.UPDATE_NC_OBJECT:
            return {
                ...state,
                object: payload,
            };
        case NC_CONSTANTS.GET_NC_LIST_SUCCESS:
            return {
                ...state,
                list: payload,
            };
        case NC_CONSTANTS.ADD_ATTACHMENT:
            if (state.object?.evidence?.length && payload?.attachment?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        evidence: [...state.object?.evidence, ...payload?.attachment],
                    },
                };
            else if (payload?.attachment?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        evidence: payload?.attachment,
                    },
                };
            if (state.object?.custom_names?.length && payload?.custom_names?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        custom_names: [
                            ...state.object?.custom_names,
                            ...payload?.custom_names,
                        ],
                    },
                };
            else if (payload?.custom_names?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        custom_names: payload?.custom_names,
                    },
                };
            return state;
        case NC_CONSTANTS.TOGGLE_NC_SUCCESS_MODAL:
            return {
                ...state,
                success: payload,
            };
        case NC_CONSTANTS.TOGGLE_NC_ERROR_MODAL:
            return {
                ...state,
                disabled: false,
                error: { show: payload },
            };
        case NC_CONSTANTS.NC_RESPONSE_ERROR:
            return {
                ...state,
                disabled: false,
                error: { ...payload, show: true },
            };
        case NC_CONSTANTS.TOGGLE_NC_SEND_MODAL:
            return {
                ...state,
                ...payload,
            };
        case NC_CONSTANTS.CLEAR_NC_OBJECT:
            return {
                ...state,
                object: payload,
            };
        case NC_CONSTANTS.FILTER_NC_LIST:
            return { ...state, filteredList: payload };

        case NC_CONSTANTS.NC_LOADER:
            return {
                ...state,
                loader: payload,
            };
        case NC_CONSTANTS.EMPTY_LIST:
            return {
                ...state,
                list: payload,
            };
        default:
            return state;
    }
};

export default reducer;
