import { SITES_CONSTANT } from "../constants";

const reducer = (
    state = {
        multiSitesList: [],
        sitesList: [],
        filteredMultisteList: [],
        editMultisite: {},
        error: {},
        success: {},
        toggle: {},
        sites: [],
    },
    { type, payload }
) => {
    switch (type) {
        case SITES_CONSTANT.GET_SITES_LIST_SUCCESS:
            return { ...state, sitesList: payload };
        case SITES_CONSTANT.TOGGLE_ERROR_MODAL:
            return {
                ...state,
                error: payload,
            };
        case SITES_CONSTANT.TOGGLE_MODAL:
            return {
                ...state,
                toggle: payload,
            };
        case SITES_CONSTANT.GET_MULTI_SITE_SUCCESS:
            return {
                ...state,
                multiSitesList: payload,
            };
        case SITES_CONSTANT.SUCCESS_MODAL:
            return {
                ...state,
                success: payload,
            };
        case SITES_CONSTANT.EDIT_MULTI_SITE:
            return {
                ...state,
                editMultisite: payload,
            };
        case SITES_CONSTANT.GET_SITES_SUCCESS:
            return {
                ...state,
                sites: payload,
            };
        case SITES_CONSTANT.FILTER_MULTISITE_LIST:
            return { ...state, filteredMultisteList: payload };
        default:
            return state;
    }
};

export default reducer;
