import React, { useEffect, useState } from "react";
import Searchbar from "../Home/TodoLIst/Searchbar";
import DataTable from "react-data-table-component";
import AuditModal from "./AuditModal";
import { useDispatch, useSelector } from "react-redux";
import { getTopicListApi, updateTopicObject } from "../../store/actions/topic";
import { getUsersByRoleApi } from "../../store/actions/user";
import utils from "../../utils";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "25px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const AuditRoles = () => {
    const dispatch = useDispatch();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [toggleModal, setToggleModal] = useState(false);
    const [auditShow, setAuditShow] = useState(false);

    const { list, error } = useSelector((state) => state?.topic);
    const org = JSON.parse(localStorage.getItem("org"));

    const toggleEditTopicModal = (flag) => {
        setToggleModal(flag);
    };

    let columns = [
        {
            name: auditShow ? "Audit" : "Topic",
            selector: (row) => row?.name,
            width: "250px",
            cell: (row) => row?.name,
        },
        {
            name: "Lead auditor",
            selector: (row) => row?.lead_auditor?.label,
            sortable: true,
            cell: (row) => row?.lead_auditor?.label,
        },
        {
            name: "Excluded auditors",
            selector: (row) =>
                row?.excluded_auditor?.map((obj, ind) =>
                    ind !== row?.excluded_auditor?.length - 1 ? (
                        <div key={ind} style={{ paddingBottom: "5px" }}>
                            {obj?.label}
                        </div>
                    ) : (
                        <div key={ind}>{obj?.label}</div>
                    )
                ),
            cell: (row) => (
                <div className="py-1">
                    {row?.excluded_auditor?.map((obj, ind) =>
                        ind !== row?.excluded_auditor?.length - 1 ? (
                            <div key={ind} style={{ paddingBottom: "5px" }}>
                                {obj?.label}
                            </div>
                        ) : (
                            <div key={ind}>{obj?.label}</div>
                        )
                    )}
                </div>
            ),
        },
        {
            name: "Accountable manager",
            selector: (row) => row?.accountable_manager?.label,
            sortable: true,
            cell: (row) => row?.accountable_manager?.label,
        },
        {
            name: "Verifier",
            selector: (row) => row?.verifier?.label,
            sortable: true,
            cell: (row) => row?.verifier?.label,
        },
        {
            name: "Edit",
            width: "100px",
            cell: (row) =>
                utils.checkSubStatus() && (
                    <a
                        className="color-05 user-name-link cursor"
                        onClick={() => editTopic(row)}
                    >
                        Edit
                    </a>
                ),
        },
    ];

    auditShow &&
        columns.splice(
            columns.length - 1,
            0,
            {
                name: "Frequency",
                selector: (row) => parseInt(row?.frequency / 30),
                sortable: true,
                cell: (row) => parseInt(row?.frequency / 30) + " months",
            },
            {
                name: "Paused",
                selector: (row) => (row?.pauseAudit === true ? "Yes" : "No"),
                sortable: true,
                cell: (row) => (row?.pauseAudit === true ? "Yes" : "No"),
            }
        );

    const checkExcludedAuditors = (search, data) => {
        let tempBool = false;
        data?.forEach((item) => {
            if (item?.label?.toLowerCase().includes(search?.toLowerCase())) {
                tempBool = true;
            }
        });
        return tempBool;
    };

    const editTopic = (data) => {
        toggleEditTopicModal(true);
        let deepCopy = JSON.parse(JSON.stringify(data));
        dispatch(updateTopicObject(deepCopy));
    };

    const handleChange = ({ selectedRows }) => {
        console.log("Selected Rows: ", selectedRows);
    };

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                list?.filter((item) => {
                    if (
                        item?.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item.lead_auditor?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        checkExcludedAuditors(searchValue, item?.excluded_auditor) ||
                        item?.accountable_manager?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item.verifier?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase())
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div>
                <Searchbar
                    onFilter={(e) => filterData(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                    type="topicRoles"
                />
            </div>
        );
    }, [filterText, filteredList]);

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataAuditRoles = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataAuditRoles);
    };

    useEffect(() => {
        dispatch(getTopicListApi());
        dispatch(getUsersByRoleApi());
    }, []);

    useEffect(() => {
        setFilteredList([...list]);
    }, [list]);

    useEffect(() => {
        if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_3) {
            setAuditShow(true);
        } else if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_4) {
            setAuditShow(true);
        }
    }, []);

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <>
            <DataTable
                columns={columns}
                data={filteredList?.length > 0 ? filteredList : []}
                customStyles={customStyles}
                striped
                onSelectedRowsChange={handleChange}
                subHeader
                selectableRows
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />
            {toggleModal && (
                <AuditModal toggleEditTopicModal={toggleEditTopicModal} />
            )}
        </>
    );
};

export default AuditRoles;
