import { GMP_CONSTANTS } from "../constants";

const reducer = (
  state = {
    object: { ...GMP_CONSTANTS.GET_DEFAULT_GMP_OBJ() },
    list: [],
    filteredGmpList: [],
    error: {},
    questions: [],
    userGMPs: [],
    success: false,
    send: false,
    roleSuccess: false,
    current_index: null,
    // send: false,
    // disabled: false,
    modalToggle: true,
  },
  { type, payload }
) => {
  switch (type) {
    case GMP_CONSTANTS.CREATE_GMP:
      return {
        ...state,
        disabled: true,
      };

    case GMP_CONSTANTS.CREATE_GMP_SUCCESS:
      if (payload?.parentGmp) {
        let Arr = state?.userGMPs;
        Arr.push(payload);
        return {
          ...state,
          // object: payload,
          disabled: false,
          userGMPs: Arr,
          success: true,
          modalToggle: false,
        };
      } else {
        return {
          ...state,
          object: payload,
          disabled: false,
          success: true,
          modalToggle: false,
          send: false,
        };
      }

    case GMP_CONSTANTS.UPDATE_GMP_SUCCESS:
      return {
        ...state,
        object: payload,
        success: true,
      };

    case GMP_CONSTANTS.ADD_GMP_ROLE_SUCCESS:
      return {
        ...state,
        roleSuccess: payload,
      };

    case GMP_CONSTANTS.ADD_GMP_ROLE_ERROR:
      return {
        ...state,
        success: false,
      };

    case GMP_CONSTANTS.UPDATE_GMP_OBJECT:
      return {
        ...state,
        object: payload,
      };

    case GMP_CONSTANTS.GET_GMP_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
        roleSuccess: false,
      };

    case GMP_CONSTANTS.GET_GMP_USER_LIST_SUCCESS:
      return {
        ...state,
        userGMPs: payload,
        roleSuccess: false,
      };

    case GMP_CONSTANTS.PUSH_GMP_IN_LIST:
      let gmpArr = [payload];
      return {
        ...state,
        userGMPs: gmpArr,
      };

    case GMP_CONSTANTS.GMP_QUESTIONS_SUCCESS:
      const Index = state?.userGMPs.findIndex((item) => {
        return item._id === payload._id;
      });
      let tempGmp = state?.userGMPs;
      tempGmp[Index] = payload;
      return {
        ...state,
        userGMPs: tempGmp,
      };

    case GMP_CONSTANTS.TOGGLE_GMP_SUCCESS_MODAL:
      return {
        ...state,
        success: payload,
        send: payload,
        modalToggle: true,
      };

    case GMP_CONSTANTS.TOGGLE_GMP_ERROR_MODAL:
      return {
        ...state,
        disabled: false,
        error: { show: payload },
      };

    case GMP_CONSTANTS.GMP_RESPONSE_ERROR:
      return {
        ...state,
        disabled: false,
        error: { ...payload, show: true },
      };

    case GMP_CONSTANTS.CLEAR_GMP_OBJECT:
      return {
        ...state,
        object: payload,
      };

    case GMP_CONSTANTS.UPDATE_QUESTIONS:
      const findIndex = state?.userGMPs.findIndex((item) => {
        return item._id === payload.gmpId;
      });
      let tempArr = state?.userGMPs;
      tempArr[findIndex].gmpQuestions = payload?.questions
        ? payload?.questions
        : payload?.questionData;
      return {
        ...state,
        userGMPs: tempArr,
      };

    case GMP_CONSTANTS.FILTER_GMP_LIST:
      return { ...state, filteredGmpList: payload };

    case GMP_CONSTANTS.EMPTY_FILTER_GMP_LIST:
      return {
        ...state,
        filteredGmpList: [],
      };

    case GMP_CONSTANTS.GMP_QUESTION_CURRENT_INDEX:
      return { ...state, current_index: payload };

    default:
      return state;
  }
};

export default reducer;
