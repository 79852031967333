import { ACTION_CONSTANTS, NAVIGATE } from "../constants";
import request from "../../services/request";
import session from "../../services/session";
import { ACTIONS } from "../../Routes/Api";
import { APP_CONSTANTS } from "../../constants";

export const createCaActionSuccess = (payload) => {
    return {
        type: ACTION_CONSTANTS.CREATE_CA_ACTION_SUCCESS,
        payload,
    };
};

export const filteredListForActions = (payload) => {
    return {
        type: ACTION_CONSTANTS.FILTERED_ACTION_LIST,
        payload,
    };
};

export const createPaActionSuccess = (payload) => {
    return {
        type: ACTION_CONSTANTS.CREATE_PA_ACTION_SUCCESS,
        payload,
    };
};

export const updateCaAction = (payload) => {
    return {
        type: ACTION_CONSTANTS.UPDATE_CA_ACTION,
        payload,
    };
};

export const updatePaAction = (payload) => {
    return {
        type: ACTION_CONSTANTS.UPDATE_PA_ACTION,
        payload,
    };
};

export const addActionerEvidence = (payload) => {
    return {
        type: ACTION_CONSTANTS.ADD_ACTIONER_EVIDENCE,
        payload,
    };
};

export const addVerifierEvidence = (payload) => {
    return {
        type: ACTION_CONSTANTS.ADD_VERIFIER_EVIDENCE,
        payload,
    };
};

export const setSelectedAction = (payload) => {
    return {
        type: ACTION_CONSTANTS.SELECTED_ACTION,
        payload,
    };
};

export const toggleSuccessModal = (payload) => {
    return {
        type: ACTION_CONSTANTS.TOGGLE_ACTION_SUCCESS_MODAL,
        payload,
    };
};

export const toggleEmailSuccessModal = (payload) => {
    return {
        type: ACTION_CONSTANTS.TOGGLE_ACTION_EMAIL_SUCCESS_MODAL,
        payload,
    };
};

export const toggleErrorModal = (payload) => {
    return {
        type: ACTION_CONSTANTS.TOGGLE_ACTION_ERROR_MODAL,
        payload,
    };
};

const getActionsListSuccess = (payload) => {
    return {
        type: ACTION_CONSTANTS.GET_ACTIONS_LIST_SUCCESS,
        payload,
    };
};

export const navigateHome = (payload) => {
    return {
        type: NAVIGATE.NAV_HOME,
        payload,
    };
};

export const updateNcPaAction = (payload) => {
    return {
        type: ACTION_CONSTANTS.UPDATE_NC_PA_ACTION,
        payload,
    };
};

export const updateNcCaAction = (payload) => {
    return {
        type: ACTION_CONSTANTS.UPDATE_NC_CA_ACTION,
        payload,
    };
};

export const createCaActionAPi = (data, from) => {
    return (dispatch) => {
        request.post(ACTIONS.CREATE_CA_ACTION, data, true).then(
            (res) => {
                dispatch(createCaActionSuccess(res?.data));
                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    dispatch(navigateHome(true));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: {} }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const createAndSendCaActionsApi = (data, from) => {
    return (dispatch) => {
        request.post(ACTIONS.CREATE_CA_ACTION, data, true).then(
            (res) => {
                dispatch(updateCaAction(res?.data));
                dispatch(
                    sentCaActionsApi({
                        nc: data?.nc,
                        ca_actions: res?.data,
                    })
                );
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: {} }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const sentCaActionsApi = (data, from) => {
    return (dispatch) => {
        request.post(ACTIONS.SENT_CA_ACTIONS, data, true).then(
            (res) => {
                if (res?.data?.length) dispatch(updateCaAction(res?.data));
                dispatch(toggleEmailSuccessModal({ send: true }));
                setTimeout(() => {
                    dispatch(toggleEmailSuccessModal({ send: false }));
                    dispatch(navigateHome(true));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: error }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const getCaActionAPi = (data, from) => {
    return (dispatch) => {
        request.get(`${ACTIONS.GET_CA_ACTION}${data.org}/${data.nc}`, true).then(
            (res) => {
                if (res?.data?.length) {
                    dispatch(updateNcCaAction(res?.data));
                }
            },
            (error) => {
                /*dispatch(toggleErrorModal(error));
                setTimeout(()=>{
                    dispatch(toggleErrorModal({show:false,error:error}))
                },APP_CONSTANTS.MODAL_SHOW_TIME)*/
            }
        );
    };
};

export const getCaActionForActionerAPi = (data, from) => {
    return (dispatch) => {
        request
            .get(`${ACTIONS.GET_CA_ACTION_FOR_ACTIONER}${data.org}/${data.nc}`, true)
            .then(
                (res) => {
                    if (res?.data) dispatch(updateCaAction(res?.data));
                },
                (error) => {
                    /*dispatch(toggleErrorModal(error));
                    setTimeout(()=>{
                        dispatch(toggleErrorModal({show:false,error:error}))
                    },APP_CONSTANTS.MODAL_SHOW_TIME)*/
                }
            );
    };
};

export const getActionsByIdAPi = (
    src,
    userId = session.getUserId(),
    orgId = session.getOrgId()
) => {
    return (dispatch) => {
        request.get(`${ACTIONS.GET_ORG_ACTIONS}/${orgId}/${userId}?q=${src}`, true).then(
            (res) => {
                dispatch(getActionsListSuccess(res?.data));
            },
            (error) => { }
        );
    };
};

export const getCaActionForVerifierAPi = (data, from) => {
    return (dispatch) => {
        request
            .get(`${ACTIONS.GET_CA_ACTION_FOR_VERIFIER}${data.org}/${data.nc}`, true)
            .then(
                (res) => {
                    if (res?.data) dispatch(updateCaAction(res?.data));
                },
                (error) => {
                    /*dispatch(toggleErrorModal(error));
                    setTimeout(()=>{
                        dispatch(toggleErrorModal({show:false,error:error}))
                    },APP_CONSTANTS.MODAL_SHOW_TIME)*/
                }
            );
    };
};

export const createPaActionAPi = (data, from) => {
    return (dispatch) => {
        request.post(ACTIONS.CREATE_PA_ACTION, data, true).then(
            (res) => {
                dispatch(createPaActionSuccess(res?.data));
                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    dispatch(navigateHome(true));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: {} }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const createAndSendPaActionsApi = (data, from) => {
    return (dispatch) => {
        request.post(ACTIONS.CREATE_PA_ACTION, data, true).then(
            (res) => {
                dispatch(updatePaAction(res?.data));
                dispatch(
                    sentPaActionsApi({
                        nc: data?.nc,
                        pa_actions: res?.data,
                    })
                );
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: {} }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const createAndSendCAAndPAActionsnApi = (
    nc,
    ca_actions,
    pa_actions,
    anonymous_function,
    anonymous_object,
    org,
    tab
) => {
    return async (dispatch) => {
        try {
            if (pa_actions?.length) {
                let result = await request.post(
                    ACTIONS.CREATE_PA_ACTION,
                    { nc, pa_actions, [tab]: true, org },
                    true
                );
                if (result.data) dispatch(updatePaAction(result.data));
            }
            if (ca_actions?.length) {
                let result = await request.post(
                    ACTIONS.CREATE_CA_ACTION,
                    { nc, ca_actions, [tab]: true, org },
                    true
                );
                if (result.data) dispatch(updateCaAction(result.data));
            }

            dispatch(anonymous_function(anonymous_object));
        } catch (e) {
            dispatch(
                toggleErrorModal({
                    show: true,
                    error: { message: "Error While sending actions to the verifier" },
                })
            );
            setTimeout(() => {
                dispatch(toggleErrorModal({ show: false, error: {} }));
            }, APP_CONSTANTS.MODAL_SHOW_TIME);
        }
    };
};

export const sentPaActionsApi = (data, from) => {
    return (dispatch) => {
        request.post(ACTIONS.SENT_PA_ACTIONS, data, true).then(
            (res) => {
                if (res?.data?.length) dispatch(updatePaAction(res?.data));
                dispatch(toggleEmailSuccessModal({ send: true }));
                setTimeout(() => {
                    dispatch(toggleEmailSuccessModal({ send: false }));
                    dispatch(navigateHome(true));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: error }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const getPaActionAPi = (data, from) => {
    return (dispatch) => {
        request.get(`${ACTIONS.GET_PA_ACTION}${data.org}/${data.nc}`, true).then(
            (res) => {
                if (res?.data?.length) {
                    dispatch(updateNcPaAction(res?.data));
                }
                /*setTimeout(()=>{
                    dispatch(toggleSuccessModal(false))
                },APP_CONSTANTS.MODAL_SHOW_TIME)*/
            },
            (error) => {
                /*dispatch(toggleErrorModal({show:false,error:error}))*/
            }
        );
    };
};

export const getPaActionForActionerAPi = (data, from) => {
    return (dispatch) => {
        request
            .get(`${ACTIONS.GET_PA_ACTION_FOR_ACTIONER}${data.org}/${data.nc}`, true)
            .then(
                (res) => {
                    if (res?.data) dispatch(updatePaAction(res?.data));
                    /*setTimeout(()=>{
                        dispatch(toggleSuccessModal(false))
                    },APP_CONSTANTS.MODAL_SHOW_TIME)*/
                },
                (error) => {
                    /*dispatch(toggleErrorModal({show:false,error:error}))*/
                }
            );
    };
};

export const getPaActionForVerifierAPi = (data, from) => {
    return (dispatch) => {
        request
            .get(`${ACTIONS.GET_PA_ACTION_FOR_VERIFIER}${data.org}/${data.nc}`, true)
            .then(
                (res) => {
                    if (res?.data) dispatch(updatePaAction(res?.data));
                    /*setTimeout(()=>{
                        dispatch(toggleSuccessModal(false))
                    },APP_CONSTANTS.MODAL_SHOW_TIME)*/
                },
                (error) => {
                    /*dispatch(toggleErrorModal({show:false,error:error}))*/
                }
            );
    };
};

export const sentCompleteActionsApi = (data, from) => {
    return (dispatch) => {
        request.post(ACTIONS.SENT_COMPLETE_ACTIONS, data, true).then(
            (res) => {
                dispatch(toggleEmailSuccessModal({ send: true }));
                setTimeout(() => {
                    dispatch(toggleEmailSuccessModal({ send: false }));
                    dispatch(navigateHome(true));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: error }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const sentVerifierResultApi = (data, from) => {
    return (dispatch) => {
        request.post(ACTIONS.SENT_VERIFIER_RESULT, data, true).then(
            (res) => {
                dispatch(toggleEmailSuccessModal({ send: true }));
                setTimeout(() => {
                    dispatch(toggleEmailSuccessModal({ send: false }));
                    dispatch(navigateHome(true));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: error }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};
