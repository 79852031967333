import React from "react";
import styled from "styled-components";
import moment from "moment";
import { APP_CONSTANTS } from "../../../../constants";

const Datefield = styled.input`
  height: 32px;
  border: 1px solid #000000;
  padding: 0 0px 0 10px;
  margin-left: 0px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
`;

const DateField = (props) => {
    const { filterData, raised, dateValue, label, type } = props;

    return (
        <div className={type === "repeat_nc" ? "d-flex mr-2" : ""}>
            <div>
                <p
                    style={{ marginTop: 3, marginBottom: 0, marginRight: 3 }}
                    className="float-left"
                >
                    &nbsp;{label} &nbsp;{""}
                </p>
            </div>
            <Datefield
                className="float-left"
                id="date"
                type="date"
                max={raised ? moment().format(APP_CONSTANTS.DATE_FORMAT_1) : ""}
                value={moment(dateValue).format(APP_CONSTANTS.DATE_FORMAT_1)}
                onChange={filterData}
            />
        </div>
    );
};

export default DateField;
