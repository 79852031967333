import React from "react";
import ReactApexChart from "react-apexcharts";

const ColumnChart = (props) => {
    const { data } = props;

    const options = {
        chart: {
            type: "bar",
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            categories: data?.categories,
            crosshairs: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        legend: {
            position: "bottom",
            itemMargin: {
                horizontal: 10,
                vertical: 20,
            },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const seriesName = w.globals.seriesNames[seriesIndex];
                const value = series[seriesIndex][dataPointIndex];

                return (
                    '<div class="arrow_box">' +
                    "<div>" +
                    seriesName +
                    "</div>" +
                    "<div>" +
                    "Count" +
                    ": " +
                    value +
                    "</div>" +
                    "</div>"
                );
            },
        },
    };

    const series = data?.series;

    return (
        <div id="chart" className="stacked-column-chart">
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={450}
            />
        </div>
    );
};

export default ColumnChart;
