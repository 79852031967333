import React, { useState } from "react";
import request from "../../services/request";
import { TRENDING } from "../../Routes/Api";
import { APP_CONSTANTS } from "../../constants";
import moment from "moment";

const GmpCompletionExportModal = (props) => {
    const { org, closeModal, h3, data, fieldData } = props;

    const [error, setError] = useState({
        show: false,
        message: "",
    });
    const [success, setSuccess] = useState({
        show: false,
        message: "",
    });
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [btnType, setBtnType] = useState("");

    const createReports = (type) => {
        let obj = {
            data: data,
            type: type,
            orgData: org,
            currentDate: moment(new Date()).format("DD/MM/YYYY"),
            inspectors: fieldData?.filter((item) => item !== "All"),
        };
        setBtnType(type);
        setLoading(true);
        setDisable(true);
        request.post(TRENDING.EXPORT_GMP_COMPLETION, obj, true).then(
            (res) => {
                setError({ show: false, message: "" });
                setSuccess({ show: true, message: res?.message });
                setLoading(false);
                handleDownload(res?.data);
                setTimeout(() => {
                    setSuccess({ show: false, message: "" });
                    closeModal(false);
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (err) => {
                setError({ show: true, message: err?.message });
                setLoading(false);
                setDisable(false);
                setTimeout(() => {
                    setError({ show: false, message: "" });
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };

    const handleDownload = async (data) => {
        const link = document.createElement("a");
        link.href = data?.Location;
        link.addEventListener("click", () => {
            // Detect download completion
            console.log("File download initiated.");
        });
        link.click();
    };

    return (
        <div className="create-report">
            <div
                className="modal fade show"
                id="gmpCompletionExportModal"
                tabIndex="-1"
                aria-labelledby="gmpCompletionExportModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <div className="row align-items-center">
                                <div className="col-sm-12 col-lg-4 text-center text-lg-left">
                                    <i
                                        className="far fa-cloud-download pb-4 pb-lg-0"
                                        style={{ fontSize: "150px", color: "#808080" }}
                                    ></i>
                                </div>
                                <div className="col-sm-12 col-lg-8 text-center text-lg-left">
                                    <h2 className="color-03 fs-24 fw-600 mb-4">
                                        Choose your preferred format
                                    </h2>
                                    <h3 className="color-03 fs-18 fw-600 mb-4">{h3}</h3>
                                    {error?.show && (
                                        <div
                                            className="alert alert-danger mt-0"
                                            role="alert"
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            {error?.message}
                                        </div>
                                    )}
                                    {success?.show && (
                                        <div
                                            className="alert alert-success mt-0"
                                            role="alert"
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            {success?.message}
                                        </div>
                                    )}
                                    <div className="mt-4 text-center">
                                        <button
                                            className="btn-yes px-4 py-2 mr-3"
                                            style={{ fontSize: "18px" }}
                                            onClick={() => {
                                                createReports("excel");
                                            }}
                                            disabled={disable}
                                        >
                                            <i className="fal fa-file-excel" /> Download Excel{" "}
                                            {loading && btnType === "excel" && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    style={{ marginBottom: "2px" }}
                                                ></div>
                                            )}
                                        </button>
                                        <button
                                            className="btn-yes px-4 py-2"
                                            style={{ fontSize: "18px" }}
                                            onClick={() => {
                                                createReports("pdf");
                                            }}
                                            disabled={disable}
                                        >
                                            <i className="fal fa-file-pdf" /> Download PDF{" "}
                                            {loading && btnType === "pdf" && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    style={{ marginBottom: "2px" }}
                                                ></div>
                                            )}
                                        </button>
                                    </div>
                                    <div className="mt-3 text-center">
                                        <button
                                            className="btn-no px-4 py-2"
                                            style={{ fontSize: "18px" }}
                                            onClick={() => closeModal(false)}
                                            disabled={disable}
                                        >
                                            <i className="far fa-ban" /> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default GmpCompletionExportModal;
