import AWS from "aws-sdk";
import S3 from "aws-sdk/clients/s3";
import Axios from "axios";

const s3 = new AWS.S3({
    params: { Bucket: "myauditapp" },
    region: "us-east-1",
});

export const uploadMediaOns3 = async (business, file, options = {}) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("business", business);

        const result = await Axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/media/upload-file`,
            formData,
            {
                headers: {
                    enctype: "multipart/form-data",
                },
            }
        );
        return result?.data || null;
    } catch (e) {
        return e;
    }
};

export const deleteMediaFromS3Bucket = async (key) => {
    let obj = {
        key: key,
    };
    try {
        const result = await Axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/media/delete-file`,
            obj,
            obj
        );
        return result?.data;
    } catch (e) {
        return e;
    }
};
