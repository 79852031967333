import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import NcView from "./NcView";
import {
    getAllUsersForDropDownApi,
    getUsersByRoleApi,
} from "../../../store/actions/user";
import {
    updateNcAPi,
    updateAndSendNcAPi,
    toggleSuccessModal,
    toggleSendModal,
} from "../../../store/actions/nc";
import SaveModal from "../../Modals/SaveModal";
import { APP_CONSTANTS } from "../../../constants";
import NotificationErrorModal from "../../Modals/NotificationErrorModal";
import NotificationSendModal from "../../Modals/NotificationSendModal";
import utils from "../../../utils";
import { useNavigate } from "react-router-dom";
import { getCaActionAPi, getPaActionAPi } from "../../../store/actions/action";
import { getExtension } from "../../../utils/icons";
import moment from "moment";
import { generateSequence } from "../../../utils/referenceId";
import videoIcon from "../../../Assets/images/file-video-regular.svg";

const Nc = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { object, success, error, send, ca_actions, pa_actions } = useSelector(
        (state) => state.nc
    );
    const { usersByRole, userDropdown } = useSelector((state) => state.user);
    const [validError, setValidError] = useState({ show: false });
    const [localObject, setLocalObject] = useState({});

    const rolesArray = usersByRole?.accountable_manager?.filter((item) => {
        return (
            item.value !== object?.verifier?.value &&
            item.value !== object?.accountable_manager?.value
        );
    });

    const delegatesList = userDropdown?.filter((item) => {
        return (
            item._id !== object?.verifier?.value &&
            item.value !== object?.accountable_manager?.value
        );
    });
    const user = JSON.parse(localStorage.getItem("user"));

    const openLinkInNewTab = (link) => {
        window.open(link);
    };

    const handleFiledChanges = (field, value, custom) => {
        let objectCopy = { ...localObject };
        if (!custom) {
            objectCopy[field] = value;
            setLocalObject(objectCopy);
            // dispatch(updateNCObject(objectCopy))
            return;
        }
        switch (field) {
            case "ac_rej_by":
                objectCopy[field] = [value];
                if (value.accepted) {
                    objectCopy.accepted = true;
                    objectCopy.rejected = false;
                    delete objectCopy.newAuditor;
                    objectCopy.status = "Accepted";
                } else {
                    objectCopy.rejected = true;
                    objectCopy.accepted = false;
                    delete objectCopy.delegate;
                    delete objectCopy.actioner;
                    delete objectCopy.auto_actions;
                    objectCopy.status = "Sent";
                }
                break;
            case "delegate":
                objectCopy.delegate = value;
                if (!objectCopy.delegate) delete objectCopy.actioner;
                if (objectCopy.delegate) objectCopy.auto_actions = false;
                break;
            case "newAuditor":
                objectCopy[field] = value;
                if (value.value !== objectCopy?.accountable_manager?.value) {
                    // objectCopy.status = "Reassign";
                    objectCopy.auto_actions = false;
                } 
                // else objectCopy.status = "Sent";
                break;
            default:
                return;
        }
        setLocalObject(objectCopy);
        // dispatch(updateNCObject(objectCopy))
    };

    const handleSave = () => {
        let objCopy = { ...localObject };
        if (!validateData(objCopy)) return;
        let formData = setFormData(objCopy);
        if (localObject?._id) dispatch(updateNcAPi(formData));
    };

    const handleSend = () => {
        let objCopy = { ...localObject };
        if (!validateData(objCopy)) return;
        let formData = setFormData(objCopy);
        dispatch(updateAndSendNcAPi(formData, objCopy));
    };

    const setFormData = (data) => {
        let formData = new FormData();
        formData.append("data", JSON.stringify(data));
        return formData;
    };

    const validateData = (data) => {
        let result = true;
        if (data.accepted) data.accepted_by_manager = true;
        else if (data.rejected) data.rejected_by_manager = true;
        if (
            !data.accepted &&
            !data.rejected &&
            !data.accepted_by_manager &&
            !data.rejected_by_manager
        ) {
            setValidError({ show: true });
            result = false;
        } else if (data.rejected && !data.newAuditor) {
            setValidError({ show: true });
            result = false;
        } else if (
            data.accepted &&
            !data.delegate &&
            !data.hasOwnProperty("auto_actions")
        ) {
            setValidError({ show: true });
            result = false;
        } else if (data.accepted && data.delegate && !data.actioner) {
            setValidError({ show: true });
            result = false;
        }
        setTimeout(() => {
            setValidError({ show: false });
        }, 3000);
        return result;
    };

    /*const getUsersForReassignment = async()=>{
                                try {
                                    let result = await request.get(`${USER.GET_USERS_FOR_REASSIGNMENT}${object._id}`,true)
                                    if(result.data)
                                        setAuditors(result.data)
                                }catch (e) {
                                    console.log('error',e)
                                }
                            }*/

    useEffect(() => {
        if (!Object.keys(usersByRole).length) dispatch(getUsersByRoleApi());
        dispatch(getAllUsersForDropDownApi());
    }, []);

    useEffect(() => {
        if (object?._id) setLocalObject({ ...object });
    }, [object]);

    useEffect(() => {
        if (send && localObject?.rejected_by_manager && localObject?.actioner)
            setTimeout(() => {
                navigate(`/private/home`);
            }, APP_CONSTANTS.MODAL_SHOW_TIME);
    }, [send]);

    useEffect(() => {
        if (
            (!ca_actions?.length && object?._id) ||
            (ca_actions?.length && ca_actions[0]?.nc !== object?._id)
        ) {
            dispatch(getCaActionAPi({ nc: object?._id, org: object?.org }));
        }
        if (
            (!pa_actions?.length && object?._id) ||
            (pa_actions?.length && pa_actions[0]?.nc !== object?._id)
        ) {
            dispatch(getPaActionAPi({ nc: object?._id, org: object?.org }));
        }
    }, [object]);

    const isAccountableManager = () => {
        let tempBool = false;
        if (object?.accountable_manager?.value === user?._id) {
            tempBool = true;
        }
        return tempBool;
    };

    return (
        <section className="reference">
            <div className="container-fluid">
                <div className="row">
                    <NcView />
                    {/* Column End */}
                    {(user?.permissions?.general?.system_manager ||
                        user?.userType === "superAdmin" ||
                        isAccountableManager() ||
                        utils.chFiShCoFoAcAccP("action", object)) && (
                            <div className="col-12 col-lg-6 mt-3">
                                {!localObject.accepted_by_manager &&
                                    localObject?.status !== "Closed" && (
                                        <h2 className="fs-16 fw-600 color-04">NC acceptance</h2>
                                    )}
                                <dl className="row light--bg">
                                    {!localObject.accepted_by_manager &&
                                        localObject?.status !== "Closed" && (
                                            <>
                                                <dt className="col-sm-9">
                                                    Do you accept accountability for this NC?
                                                </dt>
                                                <dd className="col-sm-3">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    className="custom-control-input "
                                                                    type="checkbox"
                                                                    id="customCheck1"
                                                                    checked={localObject?.rejected}
                                                                    onClick={() =>
                                                                        handleFiledChanges(
                                                                            "ac_rej_by",
                                                                            {
                                                                                user: localObject?.auditor?.value,
                                                                                rejected: true,
                                                                            },
                                                                            true
                                                                        )
                                                                    }
                                                                    disabled={!utils.checkSubStatus()}
                                                                />
                                                                <label
                                                                    className="custom-control-label cursor fs-14 fw-400 color-04"
                                                                    htmlFor="customCheck1"
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    className="custom-control-input"
                                                                    type="checkbox"
                                                                    id="customCheck2"
                                                                    checked={localObject?.accepted}
                                                                    onClick={() =>
                                                                        handleFiledChanges(
                                                                            "ac_rej_by",
                                                                            {
                                                                                user: localObject?.auditor?.value,
                                                                                accepted: true,
                                                                            },
                                                                            true
                                                                        )
                                                                    }
                                                                    disabled={!utils.checkSubStatus()}
                                                                />
                                                                <label
                                                                    className="custom-control-label cursor fs-14 fw-400 color-04"
                                                                    htmlFor="customCheck2"
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </>
                                        )}
                                    {localObject?.rejected && (
                                        <div className="col-12 bg-tr">
                                            <div className="form-group row">
                                                <label className="col-12 col-lg-6 col-form-label fs-14 fw-600 color-04">
                                                    Reassign this NC to
                                                </label>
                                                <div className="col-12 col-lg-6">
                                                    <Select
                                                        options={rolesArray}
                                                        value={localObject?.newAuditor}
                                                        onChange={(data) =>
                                                            handleFiledChanges("newAuditor", data)
                                                        }
                                                        isDisabled={!utils.checkSubStatus()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </dl>
                                {localObject?.accepted && (
                                    <dl className="row">
                                        <dt className="col-sm-9">
                                            Do you want to delegate responsibility for this NC?
                                        </dt>
                                        <dd className="col-sm-3">
                                            <div className="row">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            id="customCheck3"
                                                            checked={localObject?.delegate === 0}
                                                            onClick={() =>
                                                                handleFiledChanges("delegate", 0, true)
                                                            }
                                                            disabled={!utils.checkSubStatus()}
                                                        />
                                                        <label
                                                            className="custom-control-label cursor fs-14 fw-400 color-04"
                                                            htmlFor="customCheck3"
                                                        >
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            id="customCheck4"
                                                            checked={localObject?.delegate === 1}
                                                            onClick={() =>
                                                                handleFiledChanges("delegate", 1, true)
                                                            }
                                                            disabled={!utils.checkSubStatus()}
                                                        />
                                                        <label
                                                            className="custom-control-label cursor fs-14 fw-400 color-04"
                                                            htmlFor="customCheck4"
                                                        >
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                        {localObject?.delegate === 1 && (
                                            <div className="col-12 light--bg mt-2">
                                                <div className="form-group row">
                                                    <label className="col-12 col-lg-6 col-form-label fs-14 fw-600 color-04">
                                                        Delegate to
                                                    </label>
                                                    <div className="col-12 col-lg-6">
                                                        <Select
                                                            options={delegatesList}
                                                            onChange={(data) =>
                                                                handleFiledChanges("actioner", data)
                                                            }
                                                            isDisabled={!utils.checkSubStatus()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </dl>
                                )}
                                {(object?.auto_actions === false ||
                                    localObject?.delegate === 0) && (
                                        <dl className="row light--bg">
                                            <dt className="col-sm-9">
                                                Are you ready to create the actions for this NC?
                                            </dt>
                                            <dd className="col-sm-3">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                id="customCheck5"
                                                                checked={
                                                                    localObject?.hasOwnProperty("auto_actions") &&
                                                                    !localObject?.auto_actions
                                                                }
                                                                onClick={() =>
                                                                    handleFiledChanges("auto_actions", false)
                                                                }
                                                                disabled={!utils.checkSubStatus()}
                                                            />
                                                            <label
                                                                className="custom-control-label cursor fs-14 fw-400 color-04"
                                                                htmlFor="customCheck5"
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                id="customCheck6"
                                                                checked={
                                                                    localObject?.hasOwnProperty("auto_actions") &&
                                                                    localObject?.auto_actions
                                                                }
                                                                onClick={() =>
                                                                    handleFiledChanges("auto_actions", true)
                                                                }
                                                                disabled={!utils.checkSubStatus()}
                                                            />
                                                            <label
                                                                className="custom-control-label cursor fs-14 fw-400 color-04"
                                                                htmlFor="customCheck6"
                                                            >
                                                                Yes
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </dd>
                                        </dl>
                                    )}
                                {object?.ca_actions?.length > 0 &&
                                    object?.ca_actions
                                        ?.sort((a, b) => {
                                            return a.id - b.id;
                                        })
                                        ?.map((obj, index) => (
                                            <>
                                                <h2 className="fs-16 fw-600 color-04">
                                                    Corrective action{" "}
                                                    <span className="float-right">
                                                        Reference:{" "}
                                                        {`${obj?.type}-${generateSequence(obj?.id)}`}
                                                    </span>
                                                </h2>

                                                <p className="action-note">
                                                    {obj?.corrective_action_notes}
                                                </p>
                                                <h2 className="fs-16 fw-600 color-04">
                                                    Actioner Name <span className="float-right"></span>
                                                </h2>
                                                <p className="action-note">{obj?.actioner?.label}</p>
                                                <h2 className="fs-16 fw-600 color-04">
                                                    Actioner Notes <span className="float-right"></span>
                                                </h2>
                                                <p className="action-note">{obj?.actioner_notes}</p>
                                                <h2 className="fs-16 fw-600 color-04">
                                                    Actioner Evidence <span className="float-right"></span>
                                                </h2>
                                                <div className="row my-4 evidence-scroll">
                                                    {obj?.actioner_evidence?.map((obj, ind) => (
                                                        <div
                                                            style={{ marginLeft: 20 }}
                                                            className="col-sm-6 pl-0"
                                                            key={ind}
                                                        >
                                                            {obj.extension === "images" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    src={`${obj?.path}`}
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "videos" && obj?.path && (
                                                                // <video
                                                                //     className="cursor"
                                                                //     onClick={() => {
                                                                //         openLinkInNewTab(obj?.path);
                                                                //     }}
                                                                //     width="49"
                                                                //     src={`${obj?.path}#t=0.1`}
                                                                //     alt="Evidence"
                                                                // />
                                                                <img
                                                                    className="cursor"
                                                                    loading="lazy"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={videoIcon}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "files" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    className="cursor"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={getExtension(obj?.path)}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            <p>{obj?.custom_name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="light--bg px-2">
                                                    <dl className="row align-items-center py-1">
                                                        <dt className="col-sm-4">Due date</dt>
                                                        <dd className="col-sm-8">
                                                            {obj?.due_date
                                                                ? moment(obj?.due_date).format(
                                                                    APP_CONSTANTS.DATE_FORMAT
                                                                )
                                                                : ""}
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="px-2">
                                                    <dl className="row">
                                                        <dt className="col-sm-4">Status</dt>
                                                        <dd className="col-sm-8">
                                                            {obj?.status === "Completed" ||
                                                                obj?.status === "Completed late"
                                                                ? "Awaiting verification"
                                                                : obj?.status}
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <h2 className="fs-16 fw-600 color-04">
                                                    Verifier Notes <span className="float-right"></span>
                                                </h2>
                                                <p className="action-note">{obj?.verifier_notes}</p>
                                                <h2 className="fs-16 fw-600 color-04">
                                                    Verifier Evidence <span className="float-right"></span>
                                                </h2>
                                                <div className="row my-4 evidence-scroll">
                                                    {obj?.verifier_evidence?.map((obj, ind) => (
                                                        <div
                                                            style={{ marginLeft: 20 }}
                                                            className="col-sm-6 pl-0"
                                                            key={ind}
                                                        >
                                                            {obj.extension === "images" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    src={`${obj?.path}`}
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "videos" && obj?.path && (
                                                                // <video
                                                                //     className="cursor"
                                                                //     onClick={() => {
                                                                //         openLinkInNewTab(obj?.path);
                                                                //     }}
                                                                //     width="49"
                                                                //     src={`${obj?.path}#t=0.1`}
                                                                //     alt="Evidence"
                                                                // />
                                                                <img
                                                                    className="cursor"
                                                                    loading="lazy"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={videoIcon}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "files" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    className="cursor"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={getExtension(obj?.path)}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            <p>{obj?.custom_name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="divider mb-4"></div>
                                            </>
                                        ))}
                                {object?.pa_actions?.length > 0 &&
                                    object?.pa_actions
                                        ?.sort((a, b) => {
                                            return a.id - b.id;
                                        })
                                        ?.map((obj, index) => (
                                            <>
                                                <h2 className="fs-16 fw-600 color-04">
                                                    Preventive action{" "}
                                                    <span className="float-right">
                                                        Reference:{" "}
                                                        {`${obj?.type}-${generateSequence(obj?.id)}`}
                                                    </span>
                                                </h2>
                                                <p className="action-note">
                                                    {obj?.preventive_action_notes}
                                                </p>

                                                <h2 className="fs-16 fw-600 color-04">
                                                    Actioner Name <span className="float-right"></span>
                                                </h2>
                                                <p className="action-note">{obj?.actioner?.label}</p>
                                                <h2 className="fs-16 fw-600 color-04">
                                                    Actioner Notes <span className="float-right"></span>
                                                </h2>
                                                <p className="action-note">{obj?.actioner_notes}</p>
                                                <h2 className="fs-16 fw-600 color-04">
                                                    Actioner Evidence <span className="float-right"></span>
                                                </h2>
                                                <div className="row my-4 evidence-scroll">
                                                    {obj?.actioner_evidence?.map((obj, ind) => (
                                                        <div
                                                            style={{ marginLeft: 20 }}
                                                            className="col-sm-6 pl-0"
                                                            key={ind}
                                                        >
                                                            {obj.extension === "images" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    src={`${obj?.path}`}
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "videos" && obj?.path && (
                                                                // <video
                                                                //     className="cursor"
                                                                //     onClick={() => {
                                                                //         openLinkInNewTab(obj?.path);
                                                                //     }}
                                                                //     width="49"
                                                                //     src={`${obj?.path}#t=0.1`}
                                                                //     alt="Evidence"
                                                                // />
                                                                <img
                                                                    className="cursor"
                                                                    loading="lazy"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={videoIcon}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "files" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    className="cursor"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={getExtension(obj?.path)}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            <p>{obj?.custom_name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="light--bg px-2">
                                                    <dl className="row align-items-center py-1">
                                                        <dt className="col-sm-4">Due date</dt>
                                                        <dd className="col-sm-8">
                                                            {obj?.due_date
                                                                ? moment(obj?.due_date).format(
                                                                    APP_CONSTANTS.DATE_FORMAT
                                                                )
                                                                : ""}
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="px-2">
                                                    <dl className="row">
                                                        <dt className="col-sm-4">Status</dt>
                                                        <dd className="col-sm-8">
                                                            {obj?.status === "Completed" ||
                                                                obj?.status === "Completed late"
                                                                ? "Awaiting verification"
                                                                : obj?.status}
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <h2 className="fs-16 fw-600 color-04">
                                                    Verifier Notes <span className="float-right"></span>
                                                </h2>
                                                <p className="action-note">{obj?.verifier_notes}</p>
                                                <h2 className="fs-16 fw-600 color-04">
                                                    Verifier Evidence <span className="float-right"></span>
                                                </h2>
                                                <div className="row my-4 evidence-scroll">
                                                    {obj?.verifier_evidence?.map((obj, ind) => (
                                                        <div
                                                            style={{ marginLeft: 20 }}
                                                            className="col-sm-6 pl-0"
                                                            key={ind}
                                                        >
                                                            {obj.extension === "images" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    src={`${obj?.path}`}
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "videos" && obj?.path && (
                                                                // <video
                                                                //     className="cursor"
                                                                //     onClick={() => {
                                                                //         openLinkInNewTab(obj?.path);
                                                                //     }}
                                                                //     width="49"
                                                                //     src={`${obj?.path}#t=0.1`}
                                                                //     alt="Evidence"
                                                                // />
                                                                <img
                                                                    className="cursor"
                                                                    loading="lazy"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={videoIcon}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "files" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    className="cursor"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={getExtension(obj?.path)}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            <p>{obj?.custom_name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="divider mb-4"></div>
                                            </>
                                        ))}

                                {object?.root_cause_analysis?.length > 0 &&
                                    object?.root_cause_analysis[0]?.status !== "Draft" &&
                                    object?.root_cause_analysis?.map((obj, index) => (
                                        <>
                                            <h2 className="fs-16 fw-600 color-04">
                                                RCA{" "}
                                                <span className="float-right">
                                                    Reference: {`${obj?.type}-${generateSequence(obj?.id)}`}
                                                </span>
                                            </h2>
                                            <p className="action-note">
                                                {obj?.root_cause_analysis_notes}
                                            </p>

                                            <h2 className="fs-16 fw-600 color-04">
                                                RCA Evidence <span className="float-right"></span>
                                            </h2>
                                            <div className="row my-4 evidence-scroll">
                                                {obj?.evidence?.map((obj, ind) => (
                                                    <div
                                                        style={{ marginLeft: 20 }}
                                                        className="col-sm-6 pl-0"
                                                        key={ind}
                                                    >
                                                        {obj.extension === "images" && obj?.path && (
                                                            <img
                                                                loading="lazy"
                                                                src={`${obj?.path}`}
                                                                onClick={() => {
                                                                    openLinkInNewTab(obj?.path);
                                                                }}
                                                                alt="Evidence"
                                                            />
                                                        )}
                                                        {obj.extension === "videos" && obj?.path && (
                                                            // <video
                                                            //     className="cursor"
                                                            //     onClick={() => {
                                                            //         openLinkInNewTab(obj?.path);
                                                            //     }}
                                                            //     width="49"
                                                            //     src={`${obj?.path}#t=0.1`}
                                                            //     alt="Evidence"
                                                            // />
                                                            <img
                                                                className="cursor"
                                                                loading="lazy"
                                                                onClick={() => {
                                                                    openLinkInNewTab(obj?.path);
                                                                }}
                                                                src={videoIcon}
                                                                alt="Evidence"
                                                            />
                                                        )}
                                                        {obj.extension === "files" && obj?.path && (
                                                            <img
                                                                loading="lazy"
                                                                className="cursor"
                                                                onClick={() => {
                                                                    openLinkInNewTab(obj?.path);
                                                                }}
                                                                src={getExtension(obj?.path)}
                                                                alt="Evidence"
                                                            />
                                                        )}
                                                        <p>{obj?.custom_name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="light--bg px-2">
                                                <dl className="row align-items-center py-1">
                                                    <dt className="col-sm-4">Due date</dt>
                                                    <dd className="col-sm-8">
                                                        {obj?.due_date
                                                            ? moment(obj?.due_date).format(
                                                                APP_CONSTANTS.DATE_FORMAT
                                                            )
                                                            : ""}
                                                    </dd>
                                                </dl>
                                            </div>
                                            <div className="px-2">
                                                <dl className="row">
                                                    <dt className="col-sm-4">Status</dt>
                                                    <dd className="col-sm-8">{obj?.status}</dd>
                                                </dl>
                                            </div>

                                            <div className="row my-4 evidence-scroll">
                                                {obj?.verifier_evidence?.map((obj, ind) => (
                                                    <div className="col-sm-6 pl-0" key={ind}>
                                                        {obj.extension === "images" && obj?.path && (
                                                            <img
                                                                loading="lazy"
                                                                src={`${obj?.path}`}
                                                                onClick={() => {
                                                                    openLinkInNewTab(obj?.path);
                                                                }}
                                                                alt="Evidence"
                                                            />
                                                        )}
                                                        {obj.extension === "videos" && obj?.path && (
                                                            // <video
                                                            //     className="cursor"
                                                            //     onClick={() => {
                                                            //         openLinkInNewTab(obj?.path);
                                                            //     }}
                                                            //     width="49"
                                                            //     src={`${obj?.path}#t=0.1`}
                                                            //     alt="Evidence"
                                                            // />
                                                            <img
                                                                className="cursor"
                                                                loading="lazy"
                                                                onClick={() => {
                                                                    openLinkInNewTab(obj?.path);
                                                                }}
                                                                src={videoIcon}
                                                                alt="Evidence"
                                                            />
                                                        )}
                                                        {obj.extension === "files" && obj?.path && (
                                                            <img
                                                                loading="lazy"
                                                                className="cursor"
                                                                onClick={() => {
                                                                    openLinkInNewTab(obj?.path);
                                                                }}
                                                                src={getExtension(obj?.path)}
                                                                alt="Evidence"
                                                            />
                                                        )}
                                                        <p>{obj?.custom_name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    ))}
                                {validError.show && (
                                    <div className="float-right" style={{ width: "210px" }}>
                                        <p className="warning-msg">
                                            <i className="fal fa-exclamation-triangle color-04" /> Oops!
                                            Look's like you forgot this one.
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}

                    {/* Column End */}
                </div>
            </div>
            {utils.checkSubscription() &&
                (object?.status === "Sent" ||
                    object?.status === "Draft" ||
                    object?.status === "Delegated" ||
                    object?.status === "Reassigned" ||
                    (object?.status === "Accepted" && !object?.auto_actions)) && (
                    <div className="float-right mt-4">
                        <button className="btn-save mr-2" onClick={handleSave}>
                            <i className="far fa-save" /> Save
                        </button>
                        <button className="btn-send mr-1" onClick={handleSend}>
                            <i className="far fa-share" /> Send
                        </button>
                    </div>
                )}
            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                        }}
                    ></div>
                    <SaveModal
                        h2={
                            object?.status === "Accepted" && object?.auto_actions
                                ? APP_CONSTANTS.NC_ACCEPT_MESSAGE.h2
                                : !object?.auto_actions
                                    ? "Your NC has been saved and will remain in your to do list"
                                    : APP_CONSTANTS.NC_REASSIGN_MESSAGE.h2
                        }
                        h3={
                            localObject?.status === "Accepted" && object?.auto_actions
                                ? APP_CONSTANTS.NC_ACCEPT_MESSAGE.h3
                                : !object?.auto_actions
                                    ? "Don't forget to come back and create and send actions when you're ready."
                                    : APP_CONSTANTS.NC_REASSIGN_MESSAGE.h3
                        }
                        p={APP_CONSTANTS.NC_REASSIGN_MESSAGE.p}
                    />
                </>
            )}
            {error?.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSendModal({ error: { sentError: false } }));
                        }}
                    ></div>
                    <NotificationErrorModal
                        error={error}
                        h2={"Error while sending email"}
                    />
                </>
            )}
            {send && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSendModal({ send: false }));
                            window.location.href = "/private/home";
                        }}
                    ></div>
                    <NotificationSendModal
                        h2={
                            !object?.auto_actions
                                ? "Your NC has been saved and will remain in your to do list"
                                : APP_CONSTANTS.NC_SEND_MESSAGE.h2
                        }
                        h3={
                            !object?.auto_actions
                                ? "Don't forget to come back and create and send actions when you're ready."
                                : APP_CONSTANTS.NC_SEND_MESSAGE.h3
                        }
                        p={APP_CONSTANTS.NC_SEND_MESSAGE.p}
                    />
                </>
            )}
        </section>
    );
};

export default Nc;
