import React, { useState } from "react";
import FeedbackImg from "../../Assets/images/feedback.png";
import request from "../../services/request";
import { ORG } from "../../Routes/Api";
import session from "../../services/session";
import FeedbackSuccess from "./FeedbackSuccess";
import { APP_CONSTANTS } from "../../constants";

const FeedBackModal = (props) => {
    const [feedModalSuccess, setFeedModalSuccess] = useState(false);
    const [object, setObject] = useState({});
    const handleFieldChange = (field, value) => {
        setObject((old) => {
            return {
                ...old,
                [field]: value,
            };
        });
    };
    const submitFeedBack = async (event) => {
        try {
            let data = {
                ...object,
                org: session.getOrgId(),
                user: session.getUserId(),
            };
            let result = await request.post(ORG.FEED_BACK, data, true);
            if (result.status) {
                setObject({});
                setFeedModalSuccess(true);
                setTimeout(() => {
                    setFeedModalSuccess(false);
                    props.closeFeedModal();
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        } catch (e) { }
    };
    const validateForm = (event) => {
        event.preventDefault();
        event.stopPropagation();
        var forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
            if (form.checkValidity() === false) form.classList.add("was-validated");
            else submitFeedBack();
        });
    };
    return (
        <>
            <div className="feedback-modal">
                <div
                    className="modal fade show"
                    id="feedbackModal"
                    tabIndex="-1"
                    aria-labelledby="feedbackModalLabel"
                    aria-hidden="true"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-md-8 col-lg-8">
                                        <h2>We just love feedback!</h2>
                                        <h3>
                                            No really, we do. We believe that honest feedback is the
                                            best way to improve.
                                        </h3>
                                        <p>
                                            Just fill in the form below and let us know what you're
                                            thinking.
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <img
                                            src={FeedbackImg}
                                            className="img-fluid"
                                            alt="Feedback"
                                        />
                                    </div>
                                </div>
                                <form
                                    className="needs-validation"
                                    onSubmit={validateForm}
                                    noValidate
                                >
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="firstname">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                required
                                                id="firstname"
                                                onChange={(e) =>
                                                    handleFieldChange("first_name", e.target.value)
                                                }
                                            />
                                            <div className="invalid-feedback">
                                                please fill required fields.
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="lastname">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                required
                                                id="lastname"
                                                onChange={(e) =>
                                                    handleFieldChange("last_name", e.target.value)
                                                }
                                            />
                                            <div className="invalid-feedback">
                                                please fill required fields.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Work email</label>
                                        <input
                                            type="email"
                                            className="form-control form-control-sm"
                                            required
                                            id="email"
                                            onChange={(e) =>
                                                handleFieldChange("email", e.target.value)
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            please fill required fields.
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="yourfeedback">Your feedback</label>
                                        <textarea
                                            className="form-control"
                                            required
                                            id="yourfeedback"
                                            rows="3"
                                            onChange={(e) =>
                                                handleFieldChange("feedback", e.target.value)
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            please fill required fields.
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn-cancel"
                                            onClick={props.closeFeedModal}
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit" className="feedback-btn">
                                            Send feedback
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {feedModalSuccess && (
                <FeedbackSuccess feedModalSuccess={feedModalSuccess} />
            )}
        </>
    );
};

export default FeedBackModal;
