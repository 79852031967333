import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Modals/ErrorModal";
import {
    getSitesListApi,
    multiSitePost,
    SuccessModal,
    toggleErrorModal,
} from "../../../store/actions/sites";
import CreateMultiSiteModal from "../../Modals/CreateMultiSiteModal";
import SaveModal from "../../Modals/SaveModal";
import { APP_CONSTANTS } from "../../../constants";
import { changeTabState } from "../../../store/actions/tabState";

const CreateMultisite = () => {
    const dispatch = useDispatch();
    const saveRef = useRef(null);

    const { sitesList, error, toggle, success } = useSelector(
        (state) => state.sites
    );
    const [sites, setSites] = useState([]);
    const [object, setObject] = useState({
        multisiteName: "",
        sites: [],
        admins: [{}],
    });
    const [disableAdmins, setDisableAdmins] = useState(true);
    const [emailValidations, setEmailValidations] = useState([
        {
            error: false,
            index: null,
            message: "",
        },
    ]);
    const [nameValidation, setNameValidation] = useState([
        {
            error: false,
            index: null,
            message: "",
        },
    ]);
    const [validationError, setValidationError] = useState({});
    const [toggleModal, setToggleModal] = useState(false);

    const ValidateEmail = (mail) => {
        if (
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                mail
            )
        ) {
            return true;
        }
        return false;
    };
    const checkEmail = (email, index) => {
        let flag = false;
        let tempObj = {
            error: false,
            index: null,
            message: "",
        };

        const tempArr = emailValidations;
        const findIndex = object?.admins?.map((user) => user.email).indexOf(email);
        if (!ValidateEmail(email)) {
            tempObj.error = true;
            tempObj.index = index;
            tempObj.message = "Email format is not valid";

            tempArr[index] = tempObj;
            setEmailValidations(tempArr);
            flag = true;
        } else if (findIndex >= 0) {
            tempObj.error = true;
            tempObj.index = index;
            tempObj.message = "Email is already added in the list of admins";

            tempArr[index] = tempObj;
            setEmailValidations(tempArr);

            flag = true;
        } else {
            tempArr[index] = tempObj;
            setEmailValidations(tempArr);
            flag = false;
        }
        setDisableAdmins(flag);
        return flag;
    };

    const handleFieldChanges = (field, value, index) => {
        saveRef.current.disabled = false;
        const copyObj = { ...object };
        if (value && value !== "") {
            setValidationErrorObj(field, false);
        } else {
            setValidationErrorObj(field, true);
        }
        let obj;
        switch (field) {
            case "multiSiteName":
                copyObj.multisiteName = value;
                break;

            case "sitesList":
                copyObj.sites[index] = value;
                break;

            case "admin_name":
                let tmpName = nameValidation;
                tmpName[index] = {
                    error: false,
                    index: index,
                    message: "",
                };
                setNameValidation(tmpName);
                obj = copyObj?.admins[index];
                obj.user_name = value;

                copyObj.admins[index] = obj;
                break;

            case "admin_email":
                let email = value.toLowerCase().trim();
                if (checkEmail(email, index)) {
                    delete copyObj?.admins[index]["email"];
                    return;
                }
                obj = copyObj?.admins[index];
                obj.email = email;

                copyObj.admins[index] = obj;
                break;
            default:
        }
        setObject(copyObj);
    };

    const handleSave = () => {
        saveRef.current.disabled = true;
        if (validateObject()) {
            return;
        } else {
            dispatch(multiSitePost(object));
        }
    };

    const validateObject = () => {
        let errorFlag = false;

        if (!object?.multisiteName) {
            setValidationErrorObj("multiSiteName", true);
            errorFlag = true;
        }
        if (validateSites()) {
            setValidationErrorObj("sitesList", true);
            errorFlag = true;
        }
        if (!object?.sites?.length) {
            setValidationErrorObj("sitesList", true);
            errorFlag = true;
        }
        if (validateAdmins()) {
            setValidationErrorObj("admin", true);
            errorFlag = true;
        }
        return errorFlag;
    };

    const validateSites = () => {
        let flag = false;
        object?.sites?.map((site, index) => {
            if (!site?.value) {
                flag = true;
            }
        });
        return flag;
    };

    const validateAdmins = () => {
        let flag = false;
        let tmpEmail = emailValidations;
        let tmpName = nameValidation;
        object?.admins?.map((admin, index) => {
            if (!admin?.user_name) {
                tmpName[index] = {
                    error: true,
                    index: index,
                    message: "Please enter admin name",
                };
                flag = true;
            } else {
                tmpName[index] = {
                    error: false,
                    index: index,
                    message: "",
                };
            }

            if (!admin?.email) {
                tmpEmail[index] = {
                    error: true,
                    index: index,
                    message: "Please enter admin email",
                };
                flag = true;
            } else {
                tmpEmail[index] = {
                    error: false,
                    index: index,
                    message: "",
                };
            }
        });
        setNameValidation(tmpName);
        setEmailValidations(tmpEmail);
        return flag;
    };

    const setValidationErrorObj = (key, flag) => {
        setValidationError((old) => {
            return {
                ...old,
                [key]: flag,
            };
        });
    };

    const addAnotherSite = () => {
        let objCopy = { ...object };
        if (objCopy?.sites[objCopy.sites.length - 1]?.value && sitesList?.length) {
            objCopy?.sites?.push({});
            setObject(objCopy);
        } else {
            return objCopy;
        }
    };

    const addAnotherAdmin = () => {
        setDisableAdmins(true);
        let objCopy = { ...object };
        if (
            objCopy?.admins[objCopy.admins.length - 1]?.user_name &&
            objCopy?.admins[objCopy.admins.length - 1]?.email
        ) {
            objCopy?.admins?.push({ user_name: "", email: "" });
            setObject(objCopy);
        } else {
            return;
        }
    };

    const removeSites = (index) => {
        if (object?.sites?.length > 1) {
            setValidationError((old) => {
                return {
                    ...old,
                    ["sitesList"]: false,
                };
            });
        }
        let objCopy = { ...object };
        objCopy?.sites?.splice(index, 1);
        setObject(objCopy);
    };

    const removeAdmin = (index) => {
        setDisableAdmins(false);
        let objCopy = { ...object };
        objCopy?.admins?.splice(index, 1);
        setObject(objCopy);
    };

    useEffect(() => {
        if (!sitesList.length) {
            dispatch(getSitesListApi());
        }
        let tempSites = sitesList?.filter(function (site) {
            return !object?.sites?.find(function (e) {
                return e?.value === site?.value;
            });
        });
        setSites(tempSites);
    }, [sitesList, object]);

    useEffect(() => {
        saveRef.current.disabled = false;
    }, [object]);

    useEffect(() => {
        if (error) {
            saveRef.current.disabled = false;
        }
    }, [error]);

    useEffect(() => {
        if (toggle && saveRef?.current) {
            saveRef.current.disabled = false;
        }
    }, [toggle]);

    return (
        <>
            <section className="home-nc multi-site">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 pr-lg-5 mt-4">
                            <div className="form-group row align-items-center">
                                <label className="col-lg-3 col-form-label">
                                    Multi-site name
                                </label>
                                <div className="col-lg-9">
                                    <input
                                        type="text"
                                        className="date-selection"
                                        placeholder="Add a multi-site name"
                                        onChange={(e) => {
                                            handleFieldChanges("multiSiteName", e.target.value);
                                        }}
                                    />
                                    {validationError?.multiSiteName && (
                                        <small className="text-danger">
                                            Please enter multi-site name
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 pr-lg-5">
                            <h5 className="color-04">Sites</h5>
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label">Add sites</label>
                                <div className="col-lg-9">
                                    {!object?.sites?.length && (
                                        <div className="mb-2">
                                            <Select
                                                placeholder="Add a site..."
                                                options={sitesList}
                                                onChange={(data) => {
                                                    handleFieldChanges("sitesList", data, 0);
                                                }}
                                            />
                                        </div>
                                    )}
                                    {object?.sites?.length
                                        ? object?.sites?.map((site, index) => (
                                            <div className="d-flex" key={index}>
                                                <div className="mb-2 w-100">
                                                    <Select
                                                        placeholder="Add a site..."
                                                        options={sites}
                                                        value={site ? site : sitesList}
                                                        onChange={(data) => {
                                                            handleFieldChanges("sitesList", data, index);
                                                        }}
                                                    />
                                                </div>
                                                {object?.sites?.length > 1 && (
                                                    <div
                                                        className="pt-2 pl-3"
                                                        onClick={() => removeSites(index)}
                                                    >
                                                        <i className="fal fa-minus-circle cursor"></i>
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                        : null}

                                    {validationError?.sitesList &&
                                        (object?.sites?.length > 1 ? (
                                            <div>
                                                <small className="text-danger">
                                                    Sites can't be left blank - either select a site or
                                                    remove this field
                                                </small>
                                            </div>
                                        ) : (
                                            <div>
                                                <small className="text-danger">
                                                    Site can't be left blank
                                                </small>
                                            </div>
                                        ))}

                                    <button
                                        disabled={!object?.sites?.length}
                                        to={"#"}
                                        className="color-05 fs-14 fw-300"
                                        style={{ background: "none", border: "none" }}
                                        onClick={() => {
                                            addAnotherSite();
                                        }}
                                    >
                                        <i className="fal fa-plus-circle"></i> Add another site
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <h5 className="color-04">Admins</h5>
                            {object?.admins?.length
                                ? object?.admins?.map((admin, index) => (
                                    <div key={index}>
                                        <div className="form-group row align-items-center mb-4">
                                            <label className="col-lg-3 col-form-label">Name</label>
                                            <div className="col-11 col-lg-7">
                                                <input
                                                    type="text"
                                                    className="date-selection"
                                                    placeholder="e.g. John Smith"
                                                    value={admin?.user_name}
                                                    onChange={(e) => {
                                                        handleFieldChanges(
                                                            "admin_name",
                                                            e.target.value,
                                                            index
                                                        );
                                                    }}
                                                />
                                                {nameValidation[index]?.error ? (
                                                    <small className="text-danger">
                                                        {nameValidation[index]?.message}
                                                    </small>
                                                ) : null}
                                            </div>
                                            {object?.admins?.length > 1 && (
                                                <div
                                                    className="col-1 col-lg-2 text-right text-lg-left"
                                                    onClick={() => removeAdmin(index)}
                                                >
                                                    <i className="fal fa-minus-circle cursor"></i>
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-lg-3 col-form-label">Email</label>
                                            <div className="col-lg-7">
                                                <input
                                                    type="email"
                                                    className="date-selection"
                                                    placeholder="e.g. john.smith@adamsapples.co.uk"
                                                    value={admin?.email}
                                                    onChange={(e) => {
                                                        handleFieldChanges(
                                                            "admin_email",
                                                            e.target.value,
                                                            index
                                                        );
                                                    }}
                                                />
                                                {emailValidations[index]?.error ? (
                                                    <small className="text-danger">
                                                        {emailValidations[index]?.message}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : null}

                            <div className="row">
                                <div className="col-lg-9 offset-lg-3">
                                    <button
                                        disabled={disableAdmins}
                                        to={"#"}
                                        className="color-05 fs-14 fw-300"
                                        style={{ background: "none", border: "none" }}
                                        onClick={() => {
                                            addAnotherAdmin();
                                        }}
                                    >
                                        <i className="fal fa-plus-circle"></i> Add another admin
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <div className="float-right my-3">
                    <button
                        className="btn-cancel mr-1"
                        onClick={() => {
                            dispatch(changeTabState(2));
                        }}
                    >
                        <i className="far fa-ban"></i> Cancel
                    </button>

                    <button
                        className="btn-save"
                        onClick={handleSave}
                        ref={saveRef}
                        disabled={saveRef?.current?.disabled}
                    >
                        <i className="far fa-save" /> Save
                    </button>
                </div>
            </div>
            {toggle?.show && (
                <>
                    <div className="modal_backdrop"></div>
                    <CreateMultiSiteModal
                        id="createMultisiteModal"
                        message={toggle?.data}
                        data={object}
                        type="add"
                    />
                </>
            )}
            {error.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal({ show: false, error: {} }));
                        }}
                    ></div>
                    <ErrorModal error={error} type="create_multi_site" />
                </>
            )}
            {success?.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(SuccessModal({ show: false, data: {} }));
                            dispatch(changeTabState(2));
                        }}
                    ></div>
                    <SaveModal
                        id="successMultisite"
                        h2={APP_CONSTANTS.SUCCESS_MULTISITE_MESSAGE.h2}
                        h3={APP_CONSTANTS.SUCCESS_MULTISITE_MESSAGE.h3}
                        p={APP_CONSTANTS.SUCCESS_MULTISITE_MESSAGE.p}
                    />
                </>
            )}
        </>
    );
};

export default CreateMultisite;
