import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { APP_CONSTANTS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Searchbar from "../../Home/TodoLIst/Searchbar";
import DateField from "../../Date/DateField";
import FilteredInputField from "./FilteredInputField";
import {
    emptyFilterSaList,
    filterSaList,
    getAuditByUser,
    updateSAObject,
} from "../../../store/actions/audit";
import SendSAModal from "../../Modals/SendSAModal";
import ReassignSAModal from "../../Modals/ReassignSAModal";
import { generateSequence } from "../../../utils/referenceId";
import utils from "../../../utils";
import { changeTabState } from "../../../store/actions/tabState";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const SystemAudits = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { userSA } = useSelector((state) => state.audit);

    const [filterText, setFilterText] = useState("");
    const [filterDate, setFilterDate] = useState({ from: "", to: "" });
    const [filterStatus, setFilterStatus] = useState("none");
    const [filteredList, setFilteredList] = useState(userSA);
    const [search, setSearch] = useState("");
    const [reassignToggle, setReassignToggle] = useState(false);
    const [sendSAToggle, setSendSAToggle] = useState(false);
    const [SAId, setSAId] = useState("");
    const [reassignSAData, setReassignSAData] = useState({});

    const org = JSON.parse(localStorage.getItem("org"));
    const user = JSON.parse(localStorage.getItem("user"));

    const toggleReassignModal = (flag) => {
        setReassignToggle(flag);
    };

    const toggleSendSAModal = (flag) => {
        setSendSAToggle(flag);
    };

    const handleRowSelection = ({ selectedRows }) => {
        dispatch(filterSaList(selectedRows));
    };

    const statuses = [
        {
            label: "Choose a Status",
            value: "none",
        },
        {
            label: "All",
            value: "all",
        },
        {
            label: "Draft",
            value: "Draft",
        },
        {
            label: "Due",
            value: "Due",
        },
        {
            label: "In progress",
            value: "In progress",
        },
        {
            label: "Completed",
            value: "Completed",
        },
        {
            label: "Completed late",
            value: "Completed late",
        },
        {
            label: "Incomplete late",
            value: "Incomplete late",
        },
        {
            label: "Overdue",
            value: "Overdue",
        },
        {
            label: "Missed",
            value: "Missed",
        },
    ];

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                userSA?.filter((item) => {
                    if (
                        `${item?.type?.toLowerCase()}-${generateSequence(item?.id) || 0
                            }`.includes(searchValue?.toLowerCase()) ||
                        item?.parentSA?.name
                            ?.replace(/[0-9]/g, "")
                            ?.toLowerCase()
                            ?.includes(searchValue?.toLowerCase()) ||
                        item?.accountable_manager?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.lead_auditor?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.verifier?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.status?.toLowerCase().includes(searchValue?.toLowerCase())
                        // JSON.stringify(item?.parentSA?.frequency).includes(
                        //     searchValue?.toLowerCase()
                        // )
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const dateFilter = (field, value) => {
        let to = null;
        let from = null;
        if (field === "from") {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        } else {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        }
    };

    const dateFilteredData = () => {
        let tempArr = userSA;

        if (filterDate.from && filterDate.from !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(item?.date_raised).format(APP_CONSTANTS.DATE_FORMAT_1) >=
                    moment(filterDate.from).format(APP_CONSTANTS.DATE_FORMAT_1)
                ) {
                    return item;
                }
            });
        }

        if (filterDate.to && filterDate.to !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(item?.date_raised).format(APP_CONSTANTS.DATE_FORMAT_1) <=
                    moment(filterDate.to).format(APP_CONSTANTS.DATE_FORMAT_1)
                ) {
                    return item;
                }
            });
        }

        if (filterStatus !== "none") {
            if (filterStatus === "all") {
                tempArr = tempArr;
            } else {
                tempArr = tempArr?.filter((item) => {
                    if (item?.status === filterStatus) {
                        return item;
                    }
                });
            }
        }
        setFilteredList(tempArr);
        dispatch(filterSaList(tempArr));
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText("");
            }
        };

        return (
            <>
                <div style={{ width: "100%" }}>
                    <DateField
                        filterData={(e) => dateFilter("from", e.target.value)}
                        raised={true}
                        dateValue={filterDate?.from}
                        label={"From:"}
                    />
                    <DateField
                        filterData={(e) => dateFilter("to", e.target.value)}
                        dateValue={filterDate?.to}
                        label={"To:"}
                    />
                    <FilteredInputField
                        list={statuses}
                        value={filterStatus}
                        action={setFilterStatus}
                    />
                    <Searchbar
                        onFilter={(e) => {
                            filterData(e.target.value);
                            setSearch(e.target.value);
                        }}
                        onClear={handleClear}
                        filterText={filterText}
                        type="gmp_inspection_info"
                    />
                </div>
            </>
        );
    }, [filterText, filteredList, filterDate]);

    // useEffect(() => {
    //     if (!userSA?.length) dispatch(getAuditByUser());
    // }, []);

    useEffect(() => {
        // setFilteredList(userSA);
        dateFilteredData();
    }, [filterDate, filterStatus]);

    useEffect(() => {
        dispatch(filterSaList(filteredList));
    }, [filteredList]);

    useEffect(() => {
        setFilteredList(userSA);
    }, [userSA]);

    // useEffect(() => {
    //     dispatch(getAuditByUser());
    // }, []);

    useEffect(() => {
        dispatch(getAuditByUser());
    }, [reassignToggle, sendSAToggle]);

    useEffect(() => {
        dispatch(emptyFilterSaList());
    }, []);

    const redirectToQuestions = (data) => {
        if (
            (user?.permissions?.general?.system_manager ||
                user?._id === data?.raised_by?.value) &&
            data?.status === "Draft"
        ) {
            dispatch(updateSAObject({ ...data }));
            dispatch(changeTabState(13));
        } else {
            navigate(`/private/system-audit/${org?._id}/${data?._id}`);
        }
    };

    const reassignAuditor = (data) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setSAId(data?._id);
            setReassignSAData(data);
            toggleReassignModal(true);
        }
    };

    const isLeadAuditor = (user, lead_auditor) => {
        let tempBool = false;
        if (user === lead_auditor?.value) {
            tempBool = true;
        }
        return tempBool;
    };

    const sendSaToLeadAuditor = (data) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setSAId(data?._id);
            toggleSendSAModal(true);
        }
    };

    const columns = [
        {
            name: "Reference",
            selector: (row) => `${row?.type}-${generateSequence(row?.id)}`,
            sortable: true,
            width: "130px",
            cell: (row) => (
                <a
                    className="edit-link user-name-link cursor"
                    onClick={() => redirectToQuestions(row)}
                >
                    {`${row?.type}-${generateSequence(row?.id)}`}
                </a>
            ),
        },
        {
            name: "Topic",
            selector: (row) => row?.parentSA?.name?.replace(/[0-9]/g, ""),
            sortable: true,
            width: "250px",
            cell: (row) => (
                <a
                    className="edit-link user-name-link cursor"
                    onClick={() => redirectToQuestions(row)}
                >
                    {row?.parentSA?.name?.replace(/[0-9]/g, "")}
                </a>
            ),
        },
        {
            name: "Accountable Manager",
            selector: (row) => row?.accountable_manager?.label,
            sortable: true,
            cell: (row) => row?.accountable_manager?.label,
        },
        {
            name: "Auditor",
            selector: (row) => row?.lead_auditor?.label,
            sortable: true,
            cell: (row) => row?.lead_auditor?.label,
        },
        {
            name: "Verifier",
            selector: (row) => row?.verifier?.label,
            sortable: true,
            cell: (row) => row?.verifier?.label,
        },
        {
            name: "Status",
            selector: (row) => row?.status,
            cell: (row) => (
                <div data-toggle="tooltip" title={row?.status} className="py-1">
                    {row?.status}
                </div>
            ),
            sortable: true,
        },
        {
            name: "Frequency",
            selector: (row) =>
                row?.isAutomatic
                    ? parseInt(row?.frequency / 30) === 12
                        ? "Annual"
                        : `${parseInt(row?.frequency / 30)}-monthly`
                    : "Manually created",
            sortable: true,
            cell: (row) =>
                row?.isAutomatic
                    ? parseInt(row?.frequency / 30) === 12
                        ? "Annual"
                        : `${parseInt(row?.frequency / 30)}-monthly`
                    : "Manually created",
        },
        {
            name: "Last Audit",
            selector: (row) => row?.lastActDate,
            cell: (row) =>
                (row?.status === "Completed" || row?.status === "Completed late") &&
                moment(row?.lastActDate).format(APP_CONSTANTS.DATE_FORMAT),
            sortable: true,
        },
        {
            name: "Next Audit Due",
            selector: (row) =>
                row?.isAutomatic
                    ? moment(row?.next_audit).format(APP_CONSTANTS.DATE_FORMAT)
                    : "Manually created",
            cell: (row) =>
                row?.isAutomatic
                    ? moment(row?.next_audit).format(APP_CONSTANTS.DATE_FORMAT)
                    : "Manually created",
            sortable: true,
        },
        {
            name: "Priority",
            selector: (row) => priorityColorSort(row?.status),
            cell: (row, index) => {
                if (
                    row?.status === "Draft" ||
                    row?.status === "Due" ||
                    row?.status === "Completed late"
                ) {
                    return <span className="dot dot-orange ml-3" />;
                } else if (
                    row?.status === "In progress" ||
                    row?.status === "Completed"
                ) {
                    return <span className="dot dot-green ml-3" />;
                } else if (
                    row?.status === "Overdue" ||
                    row?.status === "Incomplete late" ||
                    row?.status === "Missed"
                ) {
                    return <span className="dot dot-red ml-3" />;
                }
            },
            sortable: true,
        },
        {
            name: "Reassign",
            width: "100px",
            cell: (row) =>
                isLeadAuditor(user?._id, row?.lead_auditor) ||
                    user?.permissions?.general?.system_manager ? (
                    row?.status === "Due" ? (
                        <a
                            className="edit-link user-name-link cursor"
                            onClick={() => reassignAuditor(row)}
                        >
                            Reassign
                        </a>
                    ) : null
                ) : null,
        },
        {
            name: "Send",
            width: "100px",
            cell: (row) =>
                row?.type === "SA" ? (
                    row?.status === "Draft" ? (
                        <a
                            className="edit-link user-name-link cursor"
                            onClick={() => sendSaToLeadAuditor(row)}
                        >
                            Send
                        </a>
                    ) : null
                ) : null,
        },
    ];

    const priorityColorSort = (status) => {
        if (status === "Draft" || status === "Due" || status === "Completed late") {
            return "Orange";
        } else if (status === "In progress" || status === "Completed") {
            return "Green";
        } else if (
            status === "Overdue" ||
            status === "Incomplete late" ||
            status === "Missed"
        ) {
            return "Red";
        }
    };

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataSA = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataSA);
    };

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="table-responsive prog_info">
            <DataTable
                columns={columns}
                data={filteredList?.length > 0 ? filteredList : []}
                selectableRows
                customStyles={customStyles}
                striped
                onSelectedRowsChange={handleRowSelection}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />

            {reassignToggle && (
                <ReassignSAModal
                    toggleReassignModal={toggleReassignModal}
                    saId={SAId}
                    reassignSAData={reassignSAData}
                />
            )}

            {sendSAToggle && (
                <SendSAModal
                    toggleSendSAModal={toggleSendSAModal}
                    id={"send_SA_to_leadAuditor"}
                    saId={SAId}
                />
            )}
        </div>
    );
};

export default SystemAudits;
