import React from "react";
import ThumbsDown from "../../Assets/images/thumbs-down.png";

const ErrorModal = (props) => {
    return (
        <div className="error-modal custom_modal_backdrop">
            <div
                className="modal fade show "
                id="errorModal"
                tabIndex="-1"
                aria-labelledby="errorModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-lg-4 pl-0">
                                    <img src={ThumbsDown} alt="Export" width={"200px"} />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center text-lg-center mb-4">
                                        {props?.type === "noModal" ? (
                                            <>{props?.h2}</>
                                        ) : (
                                            <>Oops... Error while saving!</>
                                        )}
                                    </h2>
                                    <h3 className="color-05 fs-18 fw-600 text-md-center text-lg-center mb-4">
                                        {props?.type === "gmpQuestionComplete" ? (
                                            <>
                                                You can't complete the GMP because some of the
                                                question(s) with answer NO don't have notes
                                            </>
                                        ) : props?.type === "SAQuestionComplete" ? (
                                            <>
                                                You can't complete the System Audit because some of the
                                                question(s) with answers YES & NO don't have notes
                                            </>
                                        ) : props?.type === "add_standards" ? (
                                            <>{props?.errorMsg}</>
                                        ) : props?.type === "create_multi_site" ? (
                                            <>{props?.error?.error?.message}</>
                                        ) : props.type === "error-org" ? (
                                            <>{props?.message}</>
                                        ) : props?.type === "noModal" ? (
                                            <>{props?.h3}</>
                                        ) : props?.id === "error" ? (
                                            <>{props?.message}</>
                                        ) : (
                                            <>
                                                {props?.message
                                                    ? props?.message
                                                    : props?.error?.message}
                                            </>
                                        )}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;
