import React from "react";
import ThumbsDown from "../../Assets/images/thumbs-down.png";

const NotificationErrorModal = (props) => {
    const { id, h2, h3, error } = props;

    return (
        <div className="send-modal custom_modal_backdrop">
            <div
                className="modal fade show"
                id={`${"sendModal"}-${id}`}
                tabIndex="-1"
                aria-labelledby="proSaveModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-lg-4 pl-0">
                                    <img
                                        src={ThumbsDown}
                                        alt="Save Notification Pro"
                                        width={"200px"}
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center text-lg-center mb-4">
                                        {h2}
                                    </h2>
                                    <h3 className="color-05 fs-18 fw-600 mb-4">
                                        {props?.message ? props?.message : props?.error?.message}
                                    </h3>
                                    <p className="color-05 fs-12 fw-300"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationErrorModal;
