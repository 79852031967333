import { SUBSCRIPTIONS_CONSTANT } from "../constants";

const reducer = (
    state = {
        subsList: [],
        filteredSubscriptionsList: [],
        error: {},
        success: {},
    },
    { type, payload }
) => {
    switch (type) {
        case SUBSCRIPTIONS_CONSTANT.GET_SUBSCRIPTIONS:
            return payload;
            break;
        case SUBSCRIPTIONS_CONSTANT.GET_SUBSCRIPTIONS_SUCCESS:
            return { subsList: payload };
            break;
        case SUBSCRIPTIONS_CONSTANT.GET_SUBSCRIPTIONS_ERROR:
            return payload;
            break;
        case SUBSCRIPTIONS_CONSTANT.FILTER_SUBSCRIPTIONS_LIST:
            return { ...state, filteredSubscriptionsList: payload };
            break;
        default:
            return state;
    }
};

export default reducer;
