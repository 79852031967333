import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import LoginGroup from "../../../Assets/images/group-shot.png";
import { getGmpTemplateApi } from "../../../store/actions/gmpTemplate";
import {
    clearGMPObject,
    addGmpRoles,
    updateGMPObject,
} from "../../../store/actions/gmp";

const GmpRolesModal = (props) => {
    const { toggleGmpRoleModal } = props;
    const dispatch = useDispatch();

    const [validError, setValidError] = useState({
        error1: {
            show: false,
            message: "",
            id: 1,
        },
        error2: {
            show: false,
            message: "",
            id: 1,
        },
    });
    const [inspErrors, setInspectError] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [disableExcludedInspector, setDisableExcludedInspector] =
        useState(false);
    const [obj, setObj] = useState({ attachment: [{ custom_name: "" }] });
    const [exInsp, setExInsp] = useState([]);
    const [insp, setInsp] = useState([]);
    const [emptyField, setEmptyField] = useState(true);
    const [lock, setLock] = useState(false);
    const [validationError, setValidationError] = useState({});

    const { object, roleSuccess, error } = useSelector((state) => state?.gmp);
    const { gmp_inspectors, gmp_acc_managers, verifier } = useSelector(
        (state) => state?.user?.usersByRole
    );

    const increaseFieldChange = (field, value, custom, index) => {
        let objCopy = { ...object };
        if (!custom) objCopy[field] = value;

        switch (field) {
            case "add_inspector":
                objCopy["inspectors"].push({});
                break;
            case "excludeInspector":
                objCopy["excluded_inspectors"].push({});
                break;
            default:
        }
        setObj(objCopy);
    };

    const handledFieldChanges = (field, value, index) => {
        if (value && value !== "") {
            setValidationErrorObj(field, false);
        } else {
            setValidationErrorObj(field, true);
        }
        let gmpObjCopy = { ...object };
        switch (field) {
            case "accountable_manager":
                handleFieldValidations(
                    "verifier",
                    value,
                    2,
                    "Accountable manager and Verifier can't be the same person.",
                    gmpObjCopy,
                    "accountable_manager"
                );

                handleFieldValidations(
                    "inspectors",
                    value,
                    3,
                    "Accountable manager and Inspector can't be the same person.",
                    gmpObjCopy
                );
                gmpObjCopy[field] = value;
                break;

            case "verifier":
                handleFieldValidations(
                    "accountable_manager",
                    value,
                    4,
                    "Accountable manager and Verifier can't be the same person.",
                    gmpObjCopy,
                    "verifier"
                );
                gmpObjCopy[field] = value;
                break;

            case "inspector":
                handleFieldValidations(
                    "accountable_manager",
                    value,
                    5,
                    "Accountable manager and Inspector can't be the same person.",
                    gmpObjCopy
                );
                let checkIns = gmpObjCopy.inspectors
                    .map((item) => item?.value)
                    .indexOf(value?.value);
                if (checkIns >= 0) {
                    setInspectError(true);
                    break;
                } else {
                    setInspectError(false);
                    gmpObjCopy.inspectors[index] = value;
                }
                let ind = gmpObjCopy.excluded_inspectors
                    .map((item) => item?.value)
                    .indexOf(value?.value);
                if (ind >= 0) {
                    gmpObjCopy.excluded_inspectors.splice(ind, 1);
                }
                break;

            case "exclude_inspector":
                let checkExc = gmpObjCopy.excluded_inspectors
                    .map((item) => item?.value)
                    .indexOf(value?.value);
                if (checkExc >= 0) {
                    setInspectError(true);
                    break;
                } else {
                    setInspectError(false);
                    gmpObjCopy.excluded_inspectors[index] = value;
                }
                let findIndex = gmpObjCopy.inspectors
                    .map((item) => item?.value)
                    .indexOf(value?.value);

                if (findIndex >= 0) {
                    gmpObjCopy.inspectors.splice(findIndex, 1);
                }
                break;

            default:
        }
        dispatch(updateGMPObject(gmpObjCopy));
    };

    // Validation for Empty Fields
    const validateObject = () => {
        let errorFlag = false;
        setValidationError({});
        if (
            Object.keys(object?.inspectors[0]).length === 0 ||
            !object?.inspectors?.length
        ) {
            setValidationErrorObj("inspector", true);
            errorFlag = true;
        }
        if (!object?.accountable_manager) {
            setValidationErrorObj("accountable_manager", true);
            errorFlag = true;
        }
        if (!object?.verifier) {
            setValidationErrorObj("verifier", true);
            errorFlag = true;
        }
        return errorFlag;
    };

    const setValidationErrorObj = (key, flag) => {
        setValidationError((old) => {
            return {
                ...old,
                [key]: flag,
            };
        });
    };

    // onClick Save
    const handleSave = () => {
        if (validateObject()) return;
        else {
            if (!lock) {
                let gmpObjCopy = { ...object };
                gmpObjCopy?.inspectors?.filter((item) => {
                    return item?.value;
                });
                gmpObjCopy?.excluded_inspectors?.filter((item) => {
                    return item?.value;
                });
                dispatch(addGmpRoles(gmpObjCopy));
                setLock(true);
            }
            setTimeout(() => {
                setLock(false);
            }, 2000);
        }
    };

    useEffect(() => {
        let tempArr = gmp_inspectors?.filter(function (cv) {
            return !object?.inspectors?.find(function (e) {
                return e.value === cv.value;
            });
        });
        setExInsp(tempArr);

        let tempArr1 = gmp_inspectors?.filter(function (cv) {
            return !object?.excluded_inspectors?.find(function (e) {
                return e.value === cv.value;
            });
        });
        setInsp(tempArr1);
        checkLastValue();
        if (object?.inspectors?.length < 1) {
            object?.inspectors?.push({});
        }

        if (object?.excluded_inspectors?.length < 1) {
            object?.excluded_inspectors?.push({});
        }
    }, [object, obj]);

    useEffect(() => {
        let tempArr = gmp_inspectors?.filter(function (cv) {
            return !object?.inspectors?.find(function (e) {
                return e.value === cv.value;
            });
        });
        setExInsp(tempArr);
        let tempArr1 = gmp_inspectors?.filter(function (cv) {
            return !object?.excluded_inspectors?.find(function (e) {
                return e.value === cv.value;
            });
        });
        setInsp(tempArr1);
    }, []);

    useEffect(() => {
        if (roleSuccess)
            setTimeout(() => {
                toggleGmpRoleModal(false);
            });
    }, [roleSuccess]);

    const handleFieldValidations = (
        field,
        value,
        id,
        message,
        object,
        source
    ) => {
        if (
            (field === "verifier" && source === "accountable_manager") ||
            (source === "verifier" && field === "accountable_manager")
        ) {
            if (object[field]?.value === value.value) {
                setValidError((old) => {
                    return {
                        ...old,
                        error1: { show: true, message: message, id: id },
                    };
                });
            } else {
                setValidError((old) => {
                    return {
                        ...old,
                        error1: { show: false, message: "", id: id },
                    };
                });
            }
        } else if (field === "inspectors") {
            if (object?.inspectors?.length) {
                const findIdex = object?.inspectors
                    ?.map((item) => item.value)
                    .indexOf(value.value);
                if (findIdex >= 0) {
                    setValidError((old) => {
                        return {
                            ...old,
                            error2: { show: true, message: message, id: id },
                        };
                    });
                } else {
                    setValidError((old) => {
                        return {
                            ...old,
                            error2: { show: false, message: "", id: id },
                        };
                    });
                }
            }
        } else if (field === "accountable_manager") {
            if (object?.accountable_manager?.value) {
                if (object[field]?.value === value?.value) {
                    setValidError((old) => {
                        return {
                            ...old,
                            error2: { show: true, message: message, id: id },
                        };
                    });
                } else {
                    setValidError((old) => {
                        return {
                            ...old,
                            error2: { show: false, message: "", id: id },
                        };
                    });
                }
            }
        }
    };

    const closeModal = (flag) => {
        dispatch(clearGMPObject({}));
        toggleGmpRoleModal(flag);
    };

    // remove selected Inspector and Excluded Inspector
    const removeInspectors = (index, type) => {
        let gmpObjCopy = { ...object };
        switch (type) {
            case "inspector":
                gmpObjCopy?.inspectors.splice(index, 1);

                break;
            case "excluded_inspector":
                gmpObjCopy?.excluded_inspectors.splice(index, 1);
                break;
            default:
        }
        handleFieldValidations(
            "accountable_manager",
            gmpObjCopy?.accountable_manager?.value,
            5,
            "Accountable manager and Inspector can't be the same person.",
            gmpObjCopy
        );
        dispatch(updateGMPObject(gmpObjCopy));
    };

    useEffect(() => {
        dispatch(getGmpTemplateApi());
    }, []);

    const checkLastValue = () => {
        if (!object?.inspectors) {
            setIsDisable(() => {
                return true;
            });
        } else if (!object?.inspectors[object?.inspectors?.length - 1]?.label) {
            setIsDisable(() => {
                return true;
            });
        } else {
            setIsDisable(() => {
                return false;
            });
        }

        if (!object?.excluded_inspectors) {
            setDisableExcludedInspector(() => {
                return true;
            });
        } else if (
            !object?.excluded_inspectors[object?.excluded_inspectors?.length - 1]
                ?.label
        ) {
            setDisableExcludedInspector(() => {
                return true;
            });
        } else {
            setDisableExcludedInspector(() => {
                return false;
            });
        }
    };

    return (
        <section className="audit-roles-modal gmp_inspection_modal modal-dialog-scrollable">
            <div
                className="modal fade show"
                style={{ display: "block" }}
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-wd modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body px-4 py-5">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center">
                                    <div>
                                        <img
                                            src={LoginGroup}
                                            className="gmp_role_modal_img"
                                            width={230}
                                            alt="avatar"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="mb-4">
                                        <h2 className="fs-18 fw-600 color-06 mb-0">
                                            <span className="color-04">Editing:</span>{" "}
                                            {object?.gmpName}
                                        </h2>
                                        <p className="mb-0 font-weight-light color-04">
                                            Let's assign some of your awesome team to this GMP.
                                        </p>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group row mb-4">
                                                <label className="col-12 col-lg-5 col-form-label">
                                                    Inspectors <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-12 col-lg-7">
                                                    {object?.inspectors?.map((obj, index) => (
                                                        <div className="d-flex">
                                                            <div key={index} className="mb-2 w-100">
                                                                <Select
                                                                    className="fs-14"
                                                                    options={insp}
                                                                    value={obj ? obj : insp}
                                                                    onChange={(data) =>
                                                                        handledFieldChanges(
                                                                            "inspector",
                                                                            data,
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            {obj?.value && (
                                                                <div
                                                                    className="pt-2 pl-3"
                                                                    onClick={() =>
                                                                        removeInspectors(index, "inspector")
                                                                    }
                                                                >
                                                                    <i className="fal fa-minus-circle cursor"></i>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}

                                                    {validationError.inspector && (
                                                        <small className="text-danger">
                                                            please select required field.
                                                        </small>
                                                    )}

                                                    <button
                                                        disabled={isDisable}
                                                        to={"#"}
                                                        className="color-05 fs-14 fw-300"
                                                        style={{ background: "none", border: "none" }}
                                                        onClick={() => {
                                                            increaseFieldChange("add_inspector");
                                                        }}
                                                    >
                                                        <i className="fal fa-plus-circle" /> Include another
                                                        inspector
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group row mb-4 light-bg">
                                                <label className="col-12 col-lg-5 col-form-label">
                                                    Excluded inspectors
                                                </label>
                                                <div className="col-12 col-lg-7">
                                                    {object?.excluded_inspectors?.map((obj, index) => (
                                                        <div className="d-flex">
                                                            <div key={index} className="mb-2 w-100">
                                                                <Select
                                                                    className="fs-14"
                                                                    options={exInsp}
                                                                    value={obj ? obj : exInsp}
                                                                    onChange={(data) =>
                                                                        handledFieldChanges(
                                                                            "exclude_inspector",
                                                                            data,
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            {obj?.value && (
                                                                <div
                                                                    className="pt-2 pl-3"
                                                                    onClick={() =>
                                                                        removeInspectors(
                                                                            index,
                                                                            "excluded_inspector"
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fal fa-minus-circle cursor"></i>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}

                                                    <button
                                                        disabled={disableExcludedInspector}
                                                        to={"#"}
                                                        className="color-05 fs-14 fw-300"
                                                        style={{ background: "none", border: "none" }}
                                                        onClick={() => {
                                                            increaseFieldChange("excludeInspector");
                                                        }}
                                                    >
                                                        <i className="fal fa-plus-circle"></i> Exclude
                                                        another inspector
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group row mb-4">
                                                <label className="col-12 col-lg-5 col-form-label">
                                                    Accountable manager{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-12 col-lg-7">
                                                    <Select
                                                        className="fs-14"
                                                        options={gmp_acc_managers}
                                                        value={object?.accountable_manager}
                                                        onChange={(data) => {
                                                            handledFieldChanges("accountable_manager", data);
                                                        }}
                                                    />
                                                    {validationError.accountable_manager && (
                                                        <small className="text-danger">
                                                            please select required field.
                                                        </small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group row mb-4 light-bg">
                                                <label className="col-12 col-lg-5 col-form-label">
                                                    Verifier <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-12 col-lg-7">
                                                    <Select
                                                        className="fs-14"
                                                        options={verifier}
                                                        value={object?.verifier}
                                                        onChange={(data) =>
                                                            handledFieldChanges("verifier", data)
                                                        }
                                                    />

                                                    {validationError.verifier && (
                                                        <small className="text-danger">
                                                            please select required field.
                                                        </small>
                                                    )}

                                                    {validError?.error1?.show && (
                                                        <div
                                                            className="error-note fs-12 fw-400 mt-3"
                                                            style={{ color: "#FF0000" }}
                                                        >
                                                            <i className="fal fa-exclamation-triangle" />{" "}
                                                            {validError.error1.message}
                                                        </div>
                                                    )}

                                                    {validError?.error2?.show && (
                                                        <div
                                                            className="error-note fs-12 fw-400 mt-3"
                                                            style={{ color: "#FF0000" }}
                                                        >
                                                            <i className="fal fa-exclamation-triangle" />{" "}
                                                            {validError.error2.message}
                                                        </div>
                                                    )}

                                                    {inspErrors && (
                                                        <div
                                                            className="error-note fs-12 fw-400 mt-3"
                                                            style={{ color: "#FF0000" }}
                                                        >
                                                            <i className="fal fa-exclamation-triangle" />
                                                            It is already included in the list
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="float-right mt-5">
                                        <button
                                            type="button"
                                            className="cancel mr-2"
                                            onClick={() => closeModal(false)}
                                        >
                                            <i className="far fa-ban" /> Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-send"
                                            disabled={
                                                validError?.error1?.show || validError?.error2?.show
                                                // emptyField
                                            }
                                            onClick={handleSave}
                                        >
                                            <i className="far fa-save" /> Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </section>
    );
};

export default GmpRolesModal;
