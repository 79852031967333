import Logo from "../../Assets/images/Logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import utils from "../../utils";
import { APP_CONSTANTS } from "../../constants";
import { useEffect } from "react";

const Header = () => {
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <div className="header">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="d-flex flex-grow-1">
                        <span className="w-100 d-lg-none d-block" />
                        <Link className="navbar-brand d-none d-lg-inline-block" to={"/"}>
                            <img src={Logo} alt="logo" />
                        </Link>

                        <Link
                            className="navbar-brand-two mx-auto d-lg-none d-inline-block"
                            to={"/"}
                        >
                            <img src={Logo} alt="logo" />
                        </Link>

                        <div className="w-100 text-right">
                            <button
                                className="navbar-toggler custom-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#myNavbar"
                            >
                                <span className="navbar-toggler-icon" />
                            </button>
                        </div>
                    </div>
                    {location?.pathname !== "/private/super-admin" && (
                        <div
                            className="collapse navbar-collapse flex-grow-1 text-right"
                            id="myNavbar"
                        >
                            <ul className="navbar-nav ml-auto flex-nowrap">
                                {utils.checkSuperAdmin() && (
                                    <li className="nav-item">
                                        <NavLink
                                            to={"super-admin"}
                                            className={`nav-link m-2 menu-item fs-14 fw-600 ${location.pathname === "/private/super-admin"
                                                    ? "active-tab"
                                                    : ""
                                                }`}
                                        >
                                            Subscriptions
                                        </NavLink>
                                    </li>
                                )}
                                {user?.loginType === "multi" && (
                                    <li className="nav-item">
                                        <NavLink
                                            to={"sites"}
                                            className={`nav-link m-2 menu-item fs-14 fw-600 ${location.pathname === "/private/sites"
                                                    ? "active-tab"
                                                    : ""
                                                }`}
                                        >
                                            Sites
                                        </NavLink>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <NavLink
                                        to={"home"}
                                        className={`nav-link m-2 menu-item fs-14 fw-600 ${location.pathname === "/private/home" ? "active-tab" : ""
                                            }`}
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to={"program-info/1"}
                                        className={`nav-link m-2 menu-item fs-14 fw-600 ${location.pathname === "/private/program-info/1"
                                                ? "active-tab"
                                                : ""
                                            }`}
                                    >
                                        Programme Information
                                    </NavLink>
                                </li>
                                {utils.checkPermissions(
                                    APP_CONSTANTS.PERMISSION_CONST.CREATE_USER
                                ) && (
                                        <li className="nav-item">
                                            <NavLink
                                                to={"users"}
                                                className={`nav-link m-2 menu-item fs-14 fw-600 ${location.pathname === "/private/users"
                                                        ? "active-tab"
                                                        : ""
                                                    }`}
                                            >
                                                Users
                                            </NavLink>
                                        </li>
                                    )}
                                <li className="nav-item">
                                    <NavLink
                                        to={"glossary"}
                                        className={`nav-link m-2 menu-item fs-14 fw-600 ${location.pathname === "/private/glossary"
                                                ? "active-tab"
                                                : ""
                                            }`}
                                    >
                                        Glossary
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href={"https://techni-k.co.uk/knowledge-base/audit-app/"}
                                        target="_blank"
                                        className={`nav-link m-2 menu-item fs-14 fw-600 ${location.pathname === "/private/help" ? "active-tab" : ""
                                            }`}
                                    >
                                        Help
                                    </a>
                                </li>
                                {utils.checkPermissions(
                                    APP_CONSTANTS.PERMISSION_CONST.ACCOUNTS
                                ) && (
                                        <li className="nav-item">
                                            <NavLink
                                                to={"accounts-summary"}
                                                className={`nav-link m-2 menu-item fs-14 fw-600 ${location.pathname === "/private/accounts-summary"
                                                        ? "active-tab"
                                                        : ""
                                                    }`}
                                            >
                                                Accounts
                                            </NavLink>
                                        </li>
                                    )}
                            </ul>
                        </div>
                    )}
                </nav>
            </div>
        </div>
    );
};

export default Header;
