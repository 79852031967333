import React from "react";
import errorIcon from "../../Assets/images/error 1x.png";

const EditUserPermissionModal = (props) => {
    const { h2, h3, closeModal, type } = props;

    return (
        <div className="error-modal custom_modal_backdrop">
            <div
                className="modal fade show "
                id="editUserPermissionModal"
                tabIndex="-1"
                aria-labelledby="editUserPermissionModal"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div
                                className={
                                    type === "multiSiteAdmin" ? "row align-items-center" : "row"
                                }
                            >
                                <div className="col-12 col-lg-4 text-center pb-4 pb-lg-0">
                                    <img src={errorIcon} alt="error" />
                                </div>
                                <div className="col-12 col-lg-8">
                                    <h2 className="color-03 fs-24 fw-600 text-center mb-4">
                                        {h2}
                                    </h2>
                                    {type === "editUser" || type === "azureModal" ? (
                                        <h3 className="color-03 fs-16 fw-600 text-center mb-4">
                                            {h3}
                                        </h3>
                                    ) : null}

                                    <div
                                        className={
                                            type === "multiSiteAdmin"
                                                ? "float-right mt-5"
                                                : "float-right mt-3"
                                        }
                                    >
                                        <button
                                            className="btn-yes px-4 fs-16"
                                            onClick={() => {
                                                closeModal(false);
                                            }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditUserPermissionModal;
