import React, { useState } from "react";
import Filter from "./Filter/Filter";
import ColumnChart from "./Charts/ColumnChart";
import request from "../../../services/request";
import { GMP, TRENDING } from "../../../Routes/Api";
import gmpCompletionIcon from "../../../Assets/images/chart-simple-solid.svg";
import CompletionSA from "./CompletionSA/CompletionSA";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUsersByRoleApi } from "../../../store/actions/user";
import RepeatNonConformances from "./RepeatNonConformances/RepeatNonConformances";
import DateField from "./RepeatNonConformances/DateField";
import moment from "moment";
import { APP_CONSTANTS } from "../../../constants";
import Select from "react-select";
import LineChart from "./Charts/LineChart";
import GmpInspScoreExportModal from "../../Modals/GmpInspScoreExportModal";
import GmpCompletionExportModal from "../../Modals/GmpCompletionExportModal";
import RepeatNonConformancesExportModal from "../../Modals/RepeatNonConformancesExportModal";
import SaCompletionExportModal from "../../Modals/SaCompletionExportModal";

const Trending = () => {
    const dispatch = useDispatch();

    let currentDate = new Date();
    let threeMonths = currentDate.setMonth(currentDate.getMonth() - 3);

    const [currentMenu, setCurrentMenu] = useState(null);
    const [outside, setOutside] = useState([]);
    const [gmpLoader, setGmpLoader] = useState(false);
    const [saLoader, setSaLoader] = useState(false);
    const [repeatNcLoader, setRepeatNcLoader] = useState(false);
    const [gmpScoreLoader, setGmpScoreLoader] = useState(false);
    const [gmpCompletionData, setGmpCompletionData] = useState({});
    const [saCompletionData, setSaCompletionData] = useState({});
    const [repeatNcData, setRepeatNcData] = useState([]);
    const [gmpScoreData, setGmpScoreData] = useState([]);
    const [area, setArea] = useState([]);
    const [areaData, setAreaData] = useState(area[0]);
    const [insData, setInsData] = useState([]);
    const [audData, setAudData] = useState([]);
    const [filterDate, setFilterDate] = useState({
        from: threeMonths,
        to: new Date(),
    });
    const [gmpInspScore, setGmpInspScore] = useState(false);
    const [gmpCompletion, setGmpCompletion] = useState(false);
    const [repeatNonConformances, setRepeatNonConformances] = useState(false);
    const [saCompletion, setSaCompletion] = useState(false);

    const { usersByRole } = useSelector((state) => state?.user);

    const org = JSON.parse(localStorage.getItem("org"));

    let newObj = {
        label: "All",
        value: "All",
    };

    let inspectors;
    let auditors;

    if (usersByRole?.gmp_inspectors?.length > 0) {
        inspectors = [newObj, ...usersByRole?.gmp_inspectors];
    }

    if (usersByRole?.auditor?.length > 0) {
        auditors = [newObj, ...usersByRole?.auditor];
    }

    const onChangeValueIns = (value) => {
        if (value?.value === "All") {
            if (insData?.includes(value?.value)) {
                setInsData([]);
            } else {
                setInsData(inspectors?.map((item) => item?.value));
            }
        } else {
            let tempArr = JSON.parse(JSON.stringify(insData));
            const findIndex = tempArr?.indexOf(value?.value);
            if (findIndex >= 0) {
                tempArr = tempArr?.filter(
                    (item) => item !== value?.value && item !== "All"
                );
                setInsData(tempArr);
            } else {
                tempArr.push(value?.value);
                if (tempArr.length === usersByRole?.gmp_inspectors?.length) {
                    tempArr.push("All");
                }
                setInsData(tempArr);
            }
        }
    };

    const onChangeValueSa = (value) => {
        if (value?.value === "All") {
            if (audData?.includes(value?.value)) {
                setAudData([]);
            } else {
                setAudData(auditors?.map((item) => item?.value));
            }
        } else {
            let tempArr = JSON.parse(JSON.stringify(audData));
            const findIndex = tempArr?.indexOf(value?.value);
            if (findIndex >= 0) {
                tempArr = tempArr?.filter(
                    (item) => item !== value?.value && item !== "All"
                );
                setAudData(tempArr);
            } else {
                tempArr.push(value?.value);
                if (tempArr.length === usersByRole?.auditor?.length) {
                    tempArr.push("All");
                }
                setAudData(tempArr);
            }
        }
    };

    const handleArea = (data) => {
        setAreaData(data);
    };

    const dateFilter = (field, value) => {
        let to = null;
        let from = null;
        if (field === "from") {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        } else {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        }
    };

    const gmpScoreAPI = () => {
        setGmpScoreLoader(true);
        let obj = {
            org: org?._id,
            gmps: areaData?.value,
        };
        request.post(TRENDING.GMP_SCORE, obj, true).then(
            (res) => {
                setGmpScoreData(res?.data);
                setGmpScoreLoader(false);
            },
            (error) => {
                setGmpScoreLoader(false);
            }
        );
    };

    const gmpCompletionAPI = (data) => {
        setGmpLoader(true);
        let obj = {
            org: org?._id,
            inspectors: data,
        };
        request.post(TRENDING.GMP_COMPLETION, obj, true).then(
            (res) => {
                setGmpCompletionData(res?.data);
                setGmpLoader(false);
            },
            (error) => {
                setGmpLoader(false);
            }
        );
    };

    const saCompletionAPI = (data) => {
        setSaLoader(true);
        let obj = {
            org: org?._id,
            auditors: data,
        };
        request.post(TRENDING.SA_COMPLETION, obj, true).then(
            (res) => {
                setSaCompletionData(res?.data);
                setSaLoader(false);
            },
            (error) => {
                setSaLoader(false);
            }
        );
    };

    const repeatNcsApi = () => {
        setRepeatNcLoader(true);
        let obj = {
            org: org?._id,
            startDate: moment(filterDate?.from).format(APP_CONSTANTS.DATE_FORMAT_1),
            endDate: moment(filterDate?.to).format(APP_CONSTANTS.DATE_FORMAT_1),
        };
        request.post(TRENDING.REPEAT_NCS, obj, true).then(
            (res) => {
                setRepeatNcData(res?.data);
                setRepeatNcLoader(false);
            },
            (error) => {
                setRepeatNcLoader(false);
            }
        );
    };

    const outSideClick = (src) => {
        let tempArr = outside;
        if (tempArr[tempArr.length - 1] === "parent" && src === "parent") {
            tempArr[0] = src;
        }
        tempArr.push(src);
        setOutside(tempArr);
        if (tempArr[0] !== "child") {
            setCurrentMenu(null);
            setOutside([]);
        }
    };

    const exportModal = (field) => {
        if (field === "gmp_inspections_score") {
            setGmpInspScore(true);
        } else if (field === "completion_of_gmp_schedule") {
            setGmpCompletion(true);
        } else if (field === "repeat_non_conformances") {
            setRepeatNonConformances(true);
        } else if (field === "completion_of_sa") {
            setSaCompletion(true);
        }
    };

    useEffect(() => {
        dispatch(getUsersByRoleApi());
    }, []);

    useEffect(() => {
        request.get(`${GMP.GET_PARENT_GMP}/${org?._id}`, true).then(
            (res) => {
                setArea(res?.data);
                setAreaData(res?.data[0]);
            },
            (error) => { }
        );
    }, []);

    useEffect(() => {
        setInsData(inspectors?.map((item) => item?.value));
        setAudData(auditors?.map((item) => item?.value));
    }, [usersByRole]);

    useEffect(() => {
        gmpScoreAPI();
    }, [areaData]);

    useEffect(() => {
        if (insData?.length >= 0) {
            gmpCompletionAPI(insData);
        }
    }, [insData]);

    useEffect(() => {
        if (audData?.length >= 0) {
            saCompletionAPI(audData);
        }
    }, [audData]);

    useEffect(() => {
        repeatNcsApi();
    }, [filterDate]);

    return (
        <div
            className="trending"
            onClick={() => {
                outSideClick("parent");
            }}
        >
            <div className="gmp-inspections-score position-relative mb-5">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 color-05 fw-600">
                        <i className="far fa-chart-line" /> GMP inspections score
                    </p>
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <label className="color-04 fs-14 mr-2 mb-0">By area:</label>
                            <div className="" style={{ width: "200px" }}>
                                <Select
                                    className="w-100"
                                    classNamePrefix="react-select-trending"
                                    options={area}
                                    value={areaData}
                                    onChange={(data) => {
                                        handleArea(data);
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                className="btn btn-export mt-0 ml-3"
                                onClick={() => exportModal("gmp_inspections_score")}
                            >
                                <i className="far fa-file-export" /> Export
                            </button>
                        </div>
                    </div>
                </div>
                <div className={gmpScoreLoader ? "p-5" : ""}>
                    {gmpScoreLoader ? (
                        <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                            style={{
                                marginBottom: "2px",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                            }}
                        ></div>
                    ) : gmpScoreData?.data?.length > 0 ? (
                        <div className="charts">
                            <LineChart data={gmpScoreData} />
                        </div>
                    ) : (
                        <div className="text-center py-5">
                            <h5>Data is not available</h5>
                        </div>
                    )}
                </div>
            </div>
            <div className="completion-gmp position-relative mb-5">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <img src={gmpCompletionIcon} alt="chart-simple" width={15} />
                        <p className="mb-0 ml-2 color-05 fw-600">
                            Completion of GMP to schedule
                        </p>
                    </div>

                    <div
                        className="d-flex align-items-center"
                        onClick={() => {
                            outSideClick("child");
                        }}
                    >
                        <Filter
                            menu="gmp-completion"
                            setCurrentMenu={setCurrentMenu}
                            currentMenu={currentMenu}
                            label={"By inspector:"}
                            data={insData}
                            list={inspectors}
                            onChangeValue={onChangeValueIns}
                        />
                        <button
                            className="btn btn-export mt-0"
                            onClick={() => exportModal("completion_of_gmp_schedule")}
                        >
                            <i className="far fa-file-export" /> Export
                        </button>
                    </div>
                </div>
                <div className={gmpLoader ? "p-5" : ""}>
                    {gmpLoader ? (
                        <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                            style={{
                                marginBottom: "2px",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                            }}
                        ></div>
                    ) : gmpCompletionData?.categories?.length > 0 ? (
                        <div className="charts">
                            <ColumnChart data={gmpCompletionData} />
                        </div>
                    ) : (
                        <div className="text-center py-5">
                            <h5>Data is not available</h5>
                        </div>
                    )}
                </div>
            </div>
            <div className="completion-sa position-relative mb-5">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 color-05 fw-600">
                        <i className="far fa-calendar-minus" /> Completion of system audits
                    </p>
                    <div
                        className="d-flex align-items-center"
                        onClick={() => {
                            outSideClick("child");
                        }}
                    >
                        <Filter
                            menu="sa-completion"
                            setCurrentMenu={setCurrentMenu}
                            currentMenu={currentMenu}
                            label={"By auditor:"}
                            data={audData}
                            list={auditors}
                            onChangeValue={onChangeValueSa}
                        />
                        <button
                            className="btn btn-export mt-0"
                            onClick={() => exportModal("completion_of_sa")}
                        >
                            <i className="far fa-file-export" /> Export
                        </button>
                    </div>
                </div>
                <div className={saLoader ? "p-5" : ""}>
                    {saLoader ? (
                        <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                            style={{
                                marginBottom: "2px",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                            }}
                        ></div>
                    ) : saCompletionData?.topics?.length > 0 ? (
                        <CompletionSA data={saCompletionData} />
                    ) : (
                        <div className="text-center py-5">
                            <h5>Data is not available</h5>
                        </div>
                    )}
                </div>
            </div>
            <div className="repeat-nc position-relative">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 color-05 fw-600">
                        <i className="far fa-repeat-alt" /> Repeat non-conformances
                    </p>
                    <div
                        className="d-flex align-items-center"
                        onClick={() => {
                            outSideClick("child");
                        }}
                    >
                        <DateField
                            filterData={(e) => dateFilter("from", e.target.value)}
                            raised={true}
                            dateValue={filterDate?.from}
                            label={"From:"}
                            type={"repeat_nc"}
                        />
                        <DateField
                            filterData={(e) => dateFilter("to", e.target.value)}
                            raised={true}
                            dateValue={filterDate?.to}
                            label={"To:"}
                            type={"repeat_nc"}
                        />
                        <button
                            className="btn btn-export mt-0 ml-3"
                            onClick={() => exportModal("repeat_non_conformances")}
                        >
                            <i className="far fa-file-export" /> Export
                        </button>
                    </div>
                </div>
                <div className={repeatNcLoader ? "p-5" : ""}>
                    {repeatNcLoader ? (
                        <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                            style={{
                                marginBottom: "2px",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                            }}
                        ></div>
                    ) : repeatNcData?.length > 0 ? (
                        <RepeatNonConformances
                            data={repeatNcData}
                            setData={setRepeatNcData}
                        />
                    ) : (
                        <div className="text-center py-5">
                            <h5>Data is not available</h5>
                        </div>
                    )}
                </div>
            </div>
            {gmpInspScore && (
                <GmpInspScoreExportModal
                    closeModal={setGmpInspScore}
                    org={org}
                    h3={
                        "You can download this GMP inspections score report as either a PDF or an Excel file. So which will it be?"
                    }
                    data={gmpScoreData}
                    fieldData={areaData}
                />
            )}
            {gmpCompletion && (
                <GmpCompletionExportModal
                    closeModal={setGmpCompletion}
                    org={org}
                    h3={
                        "You can download this Completion of GMP to schedule report as either a PDF or an Excel file. So which will it be?"
                    }
                    data={gmpCompletionData}
                    fieldData={insData}
                />
            )}
            {saCompletion && (
                <SaCompletionExportModal
                    closeModal={setSaCompletion}
                    org={org}
                    h3={
                        "You can download this Completion of system audits report as either a PDF or an Excel file. So which will it be?"
                    }
                    data={saCompletionData}
                    fieldData={audData}
                />
            )}
            {repeatNonConformances && (
                <RepeatNonConformancesExportModal
                    closeModal={setRepeatNonConformances}
                    org={org}
                    h3={
                        "You can download this Repeat non-conformances report as either a PDF or an Excel file. So which will it be?"
                    }
                    data={repeatNcData}
                />
            )}
        </div>
    );
};

export default Trending;
