import React, { useEffect, useState } from "react";

const Filter = (props) => {
    const {
        menu,
        setCurrentMenu,
        currentMenu,
        label,
        data,
        list,
        onChangeValue,
    } = props;

    const [parentWidth, setParentWidth] = useState(0);

    const toggle = () => {
        if (currentMenu === menu) {
            setCurrentMenu(null);
        } else {
            setCurrentMenu(menu);
        }
    };

    const reportName = (menu) => {
        switch (menu) {
            case "gmp-completion":
                if (data?.length === list?.length) {
                    return "All";
                } else if (data?.length) {
                    return byInspector(data);
                } else {
                    return "Select...";
                }
                break;
            case "sa-completion":
                if (data?.length === list?.length) {
                    return "All";
                } else if (data?.length) {
                    return byAuditor(data);
                } else {
                    return "Select...";
                }
                break;
            default:
        }
    };

    const byInspector = (data) => {
        let insName = [];
        list?.map((item) => {
            if (data?.includes(item?.value)) {
                insName.push(item?.label);
            }
        });
        return insName.join(", ");
    };

    const byAuditor = (data) => {
        let audName = [];
        list?.map((item) => {
            if (data?.includes(item?.value)) {
                audName.push(item?.label);
            }
        });
        return audName.join(", ");
    };

    useEffect(() => {
        const parentDiv = document.getElementById("parent");
        setParentWidth(parentDiv.clientWidth);

        const handleResize = () => {
            setParentWidth(parentDiv.clientWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const childStyle = {
        width: `${parentWidth - 50}px`,
    };

    return (
        <div className="filter-action completion-gmp-filter d-flex align-items-center mr-3">
            <label className="color-04 fs-14 mb-0 mr-2">{label}</label>
            <div className="dropdown" style={{ width: "200px" }}>
                <button
                    className="report-dropdown"
                    onClick={() => toggle()}
                    id="parent"
                >
                    {reportName(menu)?.length >= 7 ? (
                        <div
                            id="child"
                            style={{
                                ...childStyle,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={reportName(menu)}
                        >
                            {reportName(menu).split(",")[0]}
                        </div>
                    ) : (
                        <>{reportName(menu)}</>
                    )}
                </button>
                <ul
                    className={
                        menu === currentMenu
                            ? "dropdown-menu d-block mt-0 w-100 pl-2"
                            : "dropdown-menu d-none"
                    }
                    style={{ maxHeight: "250px", overflow: "auto", minWidth: "auto" }}
                >
                    {menu === currentMenu && (
                        <>
                            {list?.length > 0 &&
                                list?.map((item, index) => (
                                    <li key={index}>
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={item?.value}
                                                checked={data?.includes(item?.value)}
                                                onChange={(e) => onChangeValue(item)}
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04"
                                                htmlFor={item?.value}
                                            >
                                                {item?.label}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                        </>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default Filter;
