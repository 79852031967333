import React, { useEffect, useState } from "react";
import Searchbar from "../../Home/TodoLIst/Searchbar";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getReminderListApi } from "../../../store/actions/reminder";
import EditReminderModal from "../../Modals/EditReminderModal";
import utils from "../../../utils";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "25px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const Reminder = () => {
    const dispatch = useDispatch();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [reminderModal, setReminderModal] = useState(false);
    const [reminderData, setReminderData] = useState({});

    const { list } = useSelector((state) => state?.reminder);

    const toggleReminderModal = (flag) => {
        setReminderModal(flag);
    };

    const editReminder = (data) => {
        setReminderData(data);
        toggleReminderModal(true);
    };

    const columns = [
        {
            name: "Email a reminder to:",
            selector: (row) => row?.to,
            sortable: true,
            cell: (row) => row?.to,
        },
        {
            name: "If:",
            selector: (row) => row?.if,
            sortable: true,
            cell: (row) => row?.if,
        },
        {
            name: "When:",
            selector: (row) => row?.when,
            sortable: true,
            cell: (row) => row?.when,
        },
        {
            name: "Enable/Disable reminder",
            selector: (row) => row?.enabled,
            sortable: true,
            cell: (row) => (row?.enabled ? "Enabled" : "Disabled"),
        },
        {
            name: "Number of days",
            selector: (row) => row?.days,
            sortable: true,
            cell: (row) => row?.days,
        },
        {
            name: "Trigger:",
            selector: (row) => row?.trigger,
            cell: (row) => row?.trigger,
        },
        {
            name: "Edit",
            width: "100px",
            cell: (row) =>
                utils.checkSubStatus() && (
                    <a
                        className="color-05 user-name-link cursor"
                        onClick={() => editReminder(row)}
                    >
                        Edit
                    </a>
                ),
        },
    ];

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                list?.filter((item) => {
                    if (
                        item?.to?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item?.if?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item?.when?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item?.trigger?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        JSON.stringify(item?.days)
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase())
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div style={{ width: "100%" }}>
                <Searchbar
                    onFilter={(e) => filterData(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                    type="reminder"
                />
            </div>
        );
    }, [filterText, filteredList]);

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataReminder = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataReminder);
    };

    useEffect(() => {
        setFilteredList([...list]);
    }, [list]);

    useEffect(() => {
        dispatch(getReminderListApi());
    }, []);

    useEffect(() => {
        dispatch(getReminderListApi());
    }, [reminderModal]);

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="gmp_inspections_wrapper">
            <DataTable
                columns={columns}
                data={filteredList}
                customStyles={customStyles}
                striped
                persistTableHead
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />

            {reminderModal && (
                <EditReminderModal
                    toggleReminderModal={toggleReminderModal}
                    reminderData={reminderData}
                />
            )}
        </div>
    );
};

export default Reminder;
