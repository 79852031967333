import React from 'react';

const CompZ = () => {
    return (
        <>
            <h2 id="z">Z</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading362">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse362" aria-expanded="true"
                                    aria-controls="collapse362">
                                Zone of indecision
                            </button>
                        </h2>
                    </div>
                    <div id="collapse362" className="collapse show" aria-labelledby="heading362"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A known variability in performance of an in-line checkweigher.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading363">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse363" aria-expanded="false"
                                    aria-controls="collapse363">

                            </button>
                        </h2>
                    </div>
                    <div id="collapse363" className="collapse" aria-labelledby="heading363"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Materials which doesn’t meet the specification or isn’t needed for the order. Surplus material may be in work in progress, in finished format or even packed. Surplus materials can be reworked as good product, before they’re considered as ‘waste’.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
            </div>
        </>
    );
};

export default CompZ;