import React from "react";
import DataTable from "react-data-table-component";
import { generateSequence } from "../../../../utils/referenceId";
import moment from "moment";
import { APP_CONSTANTS } from "../../../../constants";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const NcDataTable = (props) => {
    const { data } = props;

    var user = JSON.parse(localStorage.getItem("user"));

    const redirectReference = (row) => {
        let state = { id: row._id, nc: row };
        if (row?.type === "NC") {
            window.open(`/private/reference/${row.org}/${row._id}/1`, "_blank");
        } else if (row?.type === "CA") {
            if (row?.actioner?.value === user?._id) {
                window.open(`/private/reference/${row?.org}/${row?.nc?._id}/5`);
            } else if (row?.verifier?.value === user?._id) {
                window.open(`/private/reference/${row?.org}/${row?.nc?._id}/6`);
            } else {
                window.open(`/private/reference/${row?.org}/${row?.nc?._id}/1`);
            }
        } else if (row?.type === "PA") {
            if (row?.actioner?.value === user?._id) {
                window.open(`/private/reference/${row?.org}/${row?.nc?._id}/5`, {
                    state,
                });
            } else if (row?.verifier?.value === user?._id) {
                window.open(`/private/reference/${row?.org}/${row?.nc?._id}/6`, {
                    state,
                });
            } else {
                window.open(`/private/reference/${row?.org}/${row?.nc?._id}/1`, {
                    state,
                });
            }
        } else if (row?.type === "RCA") {
            window.open(`/private/reference/${row?.org}/${row?.nc?._id}/3`);
        } else if (row?.type === "PRO") {
            window.open(`/private/pro-reference/${row?.org}/${row?._id}`);
        }
    };

    const columns = [
        {
            name: "Reference",
            selector: (row) => `${row?.type}-${generateSequence(row?.id)}`,
            cell: (row) => {
                if (row?.status === "Draft") {
                    return (
                        <div className="color-02">
                            {`${row?.type}-${generateSequence(row?.id)}`}
                        </div>
                    );
                } else {
                    return (
                        <a
                            className="edit-link user-name-link cursor"
                            onClick={() => redirectReference(row)}
                        >
                            {`${row?.type}-${generateSequence(row?.id)}`}
                        </a>
                    );
                }
            },

            sortable: true,
            width: "130px",
        },
        {
            name: "Source",
            selector: (row) =>
                row?.source?.label === "System audit"
                    ? row?.topic?.label
                    : row?.source?.label === "GMP inspection"
                        ? row?.gmp_text
                        : row?.source?.label
                            ? row?.source?.label
                            : row?.nc?.source?.label,
            sortable: true,
            cell: (row) => (
                <div className="text-left">
                    {row?.source?.label === "System audit"
                        ? row?.topic?.label
                        : row?.source?.label === "GMP inspection"
                            ? row?.gmp_text
                            : row?.source?.label
                                ? row?.source?.label
                                : row?.nc?.source?.label}
                </div>
            ),
            width: "250px",
        },
        {
            name: "Date raised",
            selector: (row) =>
                row?.date_raised
                    ? moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT)
                    : moment(row?.createdAt).format(APP_CONSTANTS.DATE_FORMAT),
            sortable: true,
            cell: (row) =>
                row?.date_raised
                    ? moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT)
                    : moment(row?.createdAt).format(APP_CONSTANTS.DATE_FORMAT),
            width: "130px",
        },
        {
            name: "Actioner",
            selector: (row) =>
                row?.actioner?.label ? row?.actioner?.label : row?.assigned_to?.label,
            sortable: true,
            cell: (row) => (
                <div className="text-left">
                    {row?.actioner?.label
                        ? row?.actioner?.label
                        : row?.assigned_to?.label}
                </div>
            ),
        },
        {
            name: "Accountable manager",
            selector: (row) =>
                row?.accountable_manager?.label
                    ? row?.accountable_manager?.label
                    : row?.nc?.accountable_manager?.label,
            sortable: true,
            cell: (row) => (
                <div className="text-left">
                    {row?.accountable_manager?.label
                        ? row?.accountable_manager?.label
                        : row?.nc?.accountable_manager?.label}
                </div>
            ),
        },
        {
            name: "Verifier",
            selector: (row) =>
                row?.verifier?.label ? row?.verifier?.label : row?.nc?.verifier?.label,
            sortable: true,
            cell: (row) => (
                <div className="text-left">
                    {row?.verifier?.label
                        ? row?.verifier?.label
                        : row?.nc?.verifier?.label}
                </div>
            ),
        },
        {
            name: "Grade",
            selector: (row) => (row?.grade ? row?.grade : row?.nc?.grade),
            sortable: true,
            cell: (row) => (row?.grade ? row?.grade : row?.nc?.grade),
            width: "100px",
        },
        {
            name: "Topic",
            selector: (row) =>
                row?.topic?.label ? row?.topic?.label : row?.nc?.topic?.label,
            width: "300px",
            cell: (row) => (
                <div className="text-left">
                    {row?.topic?.label ? row?.topic?.label : row?.nc?.topic?.label}
                </div>
            ),
        },
        {
            name: "Status",
            selector: (row) => row?.status,
            cell: (row) => (
                <div
                    data-toggle="tooltip"
                    title={row?.status}
                    className="py-1 text-left"
                >
                    {row?.status}
                </div>
            ),
            sortable: true,
        },
    ];

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="mt-3">
            <DataTable
                columns={columns}
                data={data ? data : []}
                customStyles={customStyles}
                striped
                persistTableHead
                keyField={customUniqueID}
                pagination
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );
};

export default NcDataTable;
