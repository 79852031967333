import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BackIcon from "../../Assets/images/back-arrow.png";
import ExportModal from "../Home/TodoLIst/ExportModal";
import CreateUser from "./CreateUser";
import { useDispatch, useSelector } from "react-redux";
import { changeTabState } from "../../store/actions/tabState";
import Overview from "./Overview";
import EditUser from "./EditUser";
import utils from "../../utils";
import { APP_CONSTANTS } from "../../constants";
import { CSVLink } from "react-csv";

const Users = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [csvExportHeader, setCsvExportHeader] = useState(
        APP_CONSTANTS.USER_EXPORT_HEADERS
    );
    const [csvReport, setCsvReport] = useState([]);
    const [object, setObject] = useState({ list: [], filteredList: [] });
    const tabState = useSelector((state) => state?.tabState);

    const handleChangeTab = (tabId) => {
        dispatch(changeTabState(tabId));
    };

    const arrangeDataForExport = () => {
        let todoListCsvExportData = [];
        if (object?.filteredList?.length)
            todoListCsvExportData = JSON.parse(JSON.stringify(object?.filteredList));
        else if (object?.list?.length)
            todoListCsvExportData = JSON.parse(JSON.stringify(object?.list));
        todoListCsvExportData = todoListCsvExportData.map((obj) => {
            return APP_CONSTANTS.USER_EXPORT_DATA_SET(obj);
        });
        setCsvReport(todoListCsvExportData);
    };

    const handleFilterList = useCallback((data) => {
        setObject((old) => {
            return {
                ...old,
                filteredList: [...data],
            };
        });
    });

    const setList = useCallback((list) => {
        setObject((old) => {
            return {
                ...old,
                list: list,
            };
        });
    });

    useEffect(() => {
        dispatch(changeTabState(1));
    }, []);

    return (
        <div className="content-box pb-5">
            <section className="todo-list">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-10">
                            <h2 className="fs-18 fw-600 py-4">
                                <i className="far fa-users" /> Users
                            </h2>
                        </div>
                        <div className="col-12 col-lg-2">
                            <NavLink
                                to={"#"}
                                className="back-to-pre fs-14 fw-300 py-4 color-04 float-right"
                                onClick={() => navigate(-1)}
                            >
                                <u>Back to previous page</u>{" "}
                                <img src={BackIcon} width="14px" alt="Back to Pre" />
                            </NavLink>
                        </div>
                    </div>
                    <div className="tab-content">
                        {tabState?.tab === 1 && (
                            <CSVLink
                                data={csvReport}
                                headers={csvExportHeader}
                                filename={"users.csv"}
                                className={"btn btn-export float-right mt-0"}
                                asyncOnClick={true}
                                onClick={(event, done) => {
                                    arrangeDataForExport();
                                    done();
                                }}
                            >
                                <i className="far fa-file-export" /> Export
                            </CSVLink>
                        )}

                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item" onClick={() => handleChangeTab(1)}>
                                <a
                                    className={`nav-link ${tabState?.tab === 1 ? "active" : ""}`}
                                    role="tab"
                                >
                                    Overview
                                </a>
                            </li>
                            {utils.checkSubStatus() &&
                                utils.checkPermissions(
                                    APP_CONSTANTS.PERMISSION_CONST.CREATE_USER
                                ) && (
                                    <li className="nav-item" onClick={() => handleChangeTab(2)}>
                                        <a
                                            className={`nav-link ${tabState?.tab === 2 ? "active" : ""
                                                }`}
                                            role="tab"
                                        >
                                            Create user
                                        </a>
                                    </li>
                                )}
                            {tabState?.tab === 3 && (
                                <li className="nav-item" onClick={() => handleChangeTab(3)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 3 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Edit user
                                    </a>
                                </li>
                            )}
                        </ul>

                        <div className="tab-content tab-box">
                            {tabState?.tab === 1 && (
                                <div
                                    className={`tab-pane todo-section ${tabState?.tab === 1 ? "active" : ""
                                        }`}
                                    id="tabs-1"
                                    role="tabpanel"
                                >
                                    <Overview
                                        handleFilterListData={handleFilterList}
                                        setListData={setList}
                                    />
                                </div>
                            )}
                            {tabState?.tab === 2 && (
                                <div
                                    className={`tab-pane todo-section ${tabState?.tab === 2 ? "active" : ""
                                        }`}
                                    id="tabs-2"
                                    role="tabpanel"
                                >
                                    <CreateUser />
                                </div>
                            )}
                            {tabState?.tab === 3 && (
                                <div
                                    className={`tab-pane todo-section ${tabState?.tab === 3 ? "active" : ""
                                        }`}
                                    id="tabs-3"
                                    role="tabpanel"
                                >
                                    <EditUser />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <ExportModal />
            </section>
        </div>
    );
};

export default Users;
