import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BackIcon from "../../../Assets/images/back-arrow.png";
import utils from "../../../utils";
import ExportModal from "../TodoLIst/ExportModal";
import { changeTabState } from "../../../store/actions/tabState";
import { getNcyIdAPi } from "../../../store/actions/nc";
import { useDispatch, useSelector } from "react-redux";
import Nc from "./Nc";
import Ca from "./Ca";
import Rca from "./Rca";
import Pa from "./Pa";
import Actioner from "./Actioner";
import Verifier from "./Verifier";
import { useParams } from "react-router";
import HistoryLog from "../../Modals/HistoryLog";
import request from "../../../services/request";
import { ACTIONS, NC } from "../../../Routes/Api";
import { CSVLink } from "react-csv";
import { APP_CONSTANTS } from "../../../constants";
import { updateCaAction, updatePaAction } from "../../../store/actions/action";
import { ACTION_CONSTANTS, R_C_A } from "../../../store/constants";
import { updateRca } from "../../../store/actions/rca";
import { generateSequence } from "../../../utils/referenceId";

const Reference = (props) => {
    let params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tabState = useSelector((state) => state?.tabState);
    const { object } = useSelector((state) => state?.nc);
    const { ca_actions, pa_actions, nc_ca_actions, nc_pa_actions } = useSelector(
        (state) => state?.action
    );
    const { user } = useSelector((state) => state);

    const rcaObject = useSelector((state) => state?.rca?.object);
    const [logArray, setLogArray] = useState([]);
    const [csvExportHeader, setCsvExportHeader] = useState(
        APP_CONSTANTS.NC_EXPORT_HEADERS
    );
    const [csvReport, setCsvReport] = useState([]);
    const [exportFile, setExportFile] = useState(`NC-${object?.id}.csv`);
    let accManager = false;
    let isActioner = false;
    let userId = user.object._id;

    if (object?.accountable_manager?.value === userId) {
        accManager = true;
    }

    if (object?.actioner?.value === userId) {
        isActioner = true;
    }

    const checkActioner = () => {
        const actionsArr = object?.ca_actions?.filter((item) => {
            return item?.actioner?.value === userId;
        });
        const paactionsArr = object?.pa_actions?.filter((item) => {
            return item?.actioner?.value === userId;
        });
        if (actionsArr?.length || paactionsArr?.length) {
            return true;
        }
    };

    const checkVerifier = () => {
        const caVerifierArr = object?.ca_actions?.filter((item) => {
            return item?.verifier?.value === userId;
        });
        const paVerifierArr = object?.pa_actions?.filter((item) => {
            return item?.verifier?.value === userId;
        });
        if (caVerifierArr?.length || paVerifierArr?.length) {
            return true;
        }
    };

    const handleChangeTab = (tabId) => {
        dispatch(changeTabState(tabId));
    };

    const handleHistoryLog = () => {
        if (tabState?.tab === 1) {
            if (object?.history_logs) {
                getNcLogs();
            }
        } else if (tabState.tab === 2) {
            if (nc_ca_actions?.length) getCaActionsLog();
        } else if (tabState.tab === 4) {
            if (nc_pa_actions?.length) getPaActionsLog();
        } else if (tabState.tab === 5 || tabState.tab === 6) {
            if (nc_ca_actions?.length || nc_pa_actions?.length) {
                getCaPaActionsLog();
            }
        } else if (tabState.tab === 3) {
            getRcaLogs();
        }
    };

    const getCaActionsLog = async (array) => {
        try {
            let result = await request.get(
                `${ACTIONS.GET_CA_ACTION_LOGS_NC_ID}${object?._id}`,
                true
            );
            if (result?.data?.length) setLogArray(result?.data);
        } catch (e) {
            console.log("error", e);
        }
    };

    const getPaActionsLog = async (array) => {
        try {
            let result = await request.get(
                `${ACTIONS.GET_PA_ACTION_LOGS_NC_ID}${object?._id}`,
                true
            );
            if (result?.data?.length) setLogArray(result?.data);
        } catch (e) {
            console.log("error", e);
        }
    };

    const getCaPaActionsLog = async (array) => {
        try {
            let result = await request.get(
                `${ACTIONS.GET_CA_PA_ACTION_LOGS_NC_ID}${object?._id}`,
                true
            );
            if (result?.data?.length) setLogArray(result?.data);
        } catch (e) {
            console.log("error", e);
        }
    };

    const getNcLogs = async () => {
        try {
            let result = await request.get(`${NC.GET_NC_LOGS}${object?._id}`, true);
            if (result?.data?.length) setLogArray(result?.data);
            if (object?.status === "Closed" || object?.status === "Closed late") {
                let caResult = await request.get(
                    `${ACTIONS.GET_CA_ACTION_LOGS_NC_ID}${object?._id}`,
                    true
                );
                if (caResult?.data?.length) {
                    setLogArray((logArray) => [...logArray, ...caResult?.data]);
                }
                let paResult = await request.get(
                    `${ACTIONS.GET_PA_ACTION_LOGS_NC_ID}${object?._id}`,
                    true
                );
                if (paResult?.data?.length) {
                    setLogArray((logArray) => [...logArray, ...paResult?.data]);
                }
                let rcaResult = await request.get(
                    `${ACTIONS.GET_RCA_LOGS}${object?._id}`,
                    true
                );
                if (rcaResult?.data?.length) {
                    setLogArray((logArray) => [...logArray, ...rcaResult?.data]);
                }
            }
        } catch (e) {
            console.log("error", e);
        }
    };

    const getRcaLogs = async () => {
        try {
            let result = await request.get(
                `${ACTIONS.GET_RCA_LOGS}${object?._id}`,
                true
            );
            if (result?.data?.length) setLogArray(result?.data);
        } catch (e) {
            console.log("error", e);
        }
    };

    const arrangeDataForExport = () => {
        let obj = {};
        if (tabState.tab === 1) {
            setCsvExportHeader(APP_CONSTANTS.NC_EXPORT_HEADERS);
            setExportFile(`NC-${generateSequence(object?.id)}.csv`);
            obj = APP_CONSTANTS.NC_EXPORT_DATA_SET(
                JSON.parse(JSON.stringify(object))
            );
            setCsvReport([obj]);
        } else if (tabState.tab === 2) {
            setCsvExportHeader(APP_CONSTANTS.ACTION_EXPORT_HEADERS);
            setExportFile(`NC-${generateSequence(object?.id)}-CA.csv`);
            obj = JSON.parse(JSON.stringify(nc_ca_actions));
            let data = obj.reduce((data, ob) => {
                data.push(APP_CONSTANTS.ACTION_EXPORT_DATA_SET(ob));
                return data;
            }, []);
            setCsvReport(data);
        } else if (tabState.tab === 4) {
            setCsvExportHeader(APP_CONSTANTS.ACTION_EXPORT_HEADERS);
            setExportFile(`NC-${generateSequence(object?.id)}-PA.csv`);
            obj = JSON.parse(JSON.stringify(nc_pa_actions));
            let data = obj.reduce((data, ob) => {
                data.push(APP_CONSTANTS.ACTION_EXPORT_DATA_SET(ob));
                return data;
            }, []);
            setCsvReport(data);
        } else if (tabState.tab === 3) {
            setCsvExportHeader(APP_CONSTANTS.RCA_EXPORT_HEADERS);
            setExportFile(`NC-${generateSequence(object?.id)}-RCA.csv`);
            obj = APP_CONSTANTS.RCA_EXPORT_DATA_SET(
                JSON.parse(JSON.stringify(rcaObject))
            );
            setCsvReport([obj]);
        }
    };

    useEffect(() => {
        if (params?.ncId && params?.orgId)
            dispatch(getNcyIdAPi(params?.ncId, params?.orgId));
        if (
            params?.ncId &&
            ca_actions?.length &&
            params?.ncId !== ca_actions[0]?.nc
        )
            dispatch(
                updateCaAction([
                    ACTION_CONSTANTS.GET_DEFAULT_CA_ACTION_OBJ(
                        params?.ncId,
                        params.orgId
                    ),
                ])
            );
        if (
            params?.ncId &&
            pa_actions?.length &&
            params?.ncId !== pa_actions[0]?.nc
        )
            dispatch(
                updatePaAction([
                    ACTION_CONSTANTS.GET_DEFAULT_PA_ACTION_OBJ(
                        params?.ncId,
                        params.orgId
                    ),
                ])
            );
        if (params?.ncId && rcaObject?.nc && params?.ncId !== rcaObject?.nc)
            dispatch(updateRca({ ...R_C_A.GET_RCA_DEFAULT_OBJ() }));

        dispatch(changeTabState(params?.tabId ? parseInt(params?.tabId) : 1));
    }, []);

    useEffect(() => {
        setLogArray([]);
    }, [tabState]);

    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-6 col-lg-10">
                        {object._id && (
                            <h2 className="fs-18 fw-600 py-4">
                                <i className="far fa-bullhorn" /> Reference:{" "}
                                {`${object?.type}-${generateSequence(object?.id)}`}
                            </h2>
                        )}
                    </div>
                    <div className="col-6 col-lg-2">
                        <NavLink
                            to={"#"}
                            className="back-to-pre fs-14 fw-300 py-4 color-04 float-right"
                            onClick={() => navigate(-1)}
                        >
                            <u>Back to previous page</u>{" "}
                            <img src={BackIcon} width="14px" alt="Back to Pre" />
                        </NavLink>
                    </div>
                </div>
                <div className="tab-content">
                    {(tabState?.tab === 1 ||
                        tabState?.tab === 2 ||
                        tabState?.tab === 3 ||
                        tabState?.tab === 4) && (
                            <CSVLink
                                data={csvReport}
                                headers={csvExportHeader}
                                filename={exportFile}
                                className={"btn btn-export float-right mt-0"}
                                asyncOnClick={true}
                                onClick={(event, done) => {
                                    arrangeDataForExport();
                                    done();
                                }}
                            >
                                <i className="far fa-file-export" /> Export
                            </CSVLink>
                        )}

                    <button
                        className="btn btn-history float-right mt-0 mr-1"
                        data-toggle="modal"
                        data-target="#historyLogsModal"
                        onClick={handleHistoryLog}
                    >
                        <i className="far fa-history" /> History
                    </button>

                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" onClick={() => handleChangeTab(1)}>
                            <a
                                className={`nav-link ${tabState?.tab === 1 ? "active" : ""}`}
                                role="tab"
                            >
                                {utils.checkNcAccepted(object) ? "NC" : "NC"}
                            </a>
                        </li>
                        {(utils.checkPermissions(
                            APP_CONSTANTS.PERMISSION_CONST.CREATE_CA
                        ) ||
                            accManager ||
                            isActioner) &&
                            utils.checkNcAccepted(object) &&
                            object?.auto_actions && (
                                <li className="nav-item" onClick={() => handleChangeTab(2)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 2 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        CA
                                    </a>
                                </li>
                            )}
                        {(utils.checkPermissions(
                            APP_CONSTANTS.PERMISSION_CONST.CREATE_RCA
                        ) ||
                            accManager ||
                            isActioner) &&
                            utils.checkNcAccepted(object) &&
                            object?.auto_actions &&
                            object?.grade !== "Minor" && (
                                <li className="nav-item" onClick={() => handleChangeTab(3)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 3 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        RCA
                                    </a>
                                </li>
                            )}
                        {(utils.checkPermissions(
                            APP_CONSTANTS.PERMISSION_CONST.CREATE_PA
                        ) ||
                            accManager ||
                            isActioner) &&
                            utils.checkNcAccepted(object) &&
                            object?.auto_actions &&
                            object?.grade !== "Minor" && (
                                <li className="nav-item" onClick={() => handleChangeTab(4)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 4 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        PA
                                    </a>
                                </li>
                            )}
                        {utils.checkNcAccepted(object) &&
                            object?.auto_actions &&
                            (checkActioner() ||
                                utils.checkPermissions(
                                    APP_CONSTANTS.PERMISSION_CONST.ACTIONER_TAB
                                ) ||
                                accManager ||
                                isActioner) && (
                                <li className="nav-item" onClick={() => handleChangeTab(5)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 5 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Actioner
                                    </a>
                                </li>
                            )}
                        {utils.checkNcAccepted(object) &&
                            object?.auto_actions &&
                            (checkVerifier() ||
                                utils.checkPermissions(
                                    APP_CONSTANTS.PERMISSION_CONST.ACTIONER_TAB
                                ) ||
                                accManager ||
                                isActioner) && (
                                <li className="nav-item" onClick={() => handleChangeTab(6)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 6 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Verifier
                                    </a>
                                </li>
                            )}
                    </ul>

                    <div className="tab-content tab-box">
                        {tabState?.tab === 1 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 1 ? "active" : ""
                                    }`}
                                id="tabs-1"
                                role="tabpanel"
                            >
                                <Nc />
                            </div>
                        )}
                        {tabState?.tab === 2 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 2 ? "active" : ""
                                    }`}
                                id="tabs-2"
                                role="tabpanel"
                            >
                                <Ca />
                            </div>
                        )}
                        {tabState?.tab === 3 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 3 ? "active" : ""
                                    }`}
                                id="tabs-3"
                                role="tabpanel"
                            >
                                <Rca />
                            </div>
                        )}
                        {tabState?.tab === 4 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 4 ? "active" : ""
                                    }`}
                                id="tabs-4"
                                role="tabpanel"
                            >
                                <Pa />
                            </div>
                        )}
                        {tabState?.tab === 5 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 5 ? "active" : ""
                                    }`}
                                id="tabs-5"
                                role="tabpanel"
                            >
                                <Actioner />
                            </div>
                        )}
                        {tabState?.tab === 6 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 6 ? "active" : ""
                                    }`}
                                id="tabs-6"
                                role="tabpanel"
                            >
                                <Verifier />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ExportModal />
            <HistoryLog logArray={logArray} />
        </section>
    );
};

export default Reference;
