const FilteredInputField = (props) => {
    const { list, action, value, type } = props;

    return (
        <div
            className={`gmp_status_filter ${type === "nc" || type === "action" ? "nc-action" : ""
                }`}
        >
            <label className="float-left ml-4 mr-2 my-1">Show:</label>
            <select
                className="float-left fs-14 custom_select"
                onChange={(e) => {
                    action(e.target.value);
                }}
            >
                {list?.map((val, index) => {
                    if (val.value === "none") {
                        return (
                            <option
                                selected
                                disabled
                                value={val?.value}
                                className="fw-400 fs-14"
                                key={index}
                            >
                                {val?.label}
                            </option>
                        );
                    } else {
                        return (
                            <option value={val?.value} className="fw-400 fs-14" key={index}>
                                {val?.label}
                            </option>
                        );
                    }
                })}
            </select>
        </div>
    );
};

export default FilteredInputField;
