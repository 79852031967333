import React from 'react';

const CompJ = () => {
    return (
        <>
            <h2 id="j">J</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading162">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse123" aria-expanded="true"
                                    aria-controls="collapse123">
                                Jewellery
                            </button>
                        </h2>
                    </div>
                    <div id="collapse123" className="collapse show" aria-labelledby="heading123"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Rings, bands, bracelets, necklaces, watches or any other decorative item or device worn on or in the body. See also; exposed areas of the body
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading163">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse163" aria-expanded="false"
                                    aria-controls="collapse163">
                                Job descriptions
                            </button>
                        </h2>
                    </div>
                    <div id="collapse163" className="collapse" aria-labelledby="heading163"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A document which details the responsibilities for a role. They can also includes the qualifications, experience and behaviours that the person should have to.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading164">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse164" aria-expanded="false"
                                    aria-controls="collapse164">
                                Justification / Justify
                            </button>
                        </h2>
                    </div>
                    <div id="collapse164" className="collapse" aria-labelledby="heading164"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To provide a reasonable explanation as to why something is true.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompJ;