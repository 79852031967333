import React from 'react';

const CompL = () => {
    return (
        <>
            <h2 id="l">L</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading168">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse168" aria-expanded="true"
                                    aria-controls="collapse168">
                                Lagging indicator
                            </button>
                        </h2>
                    </div>
                    <div id="collapse168" className="collapse show" aria-labelledby="heading168"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A signal that something has happened. A lagging indicator means that failure has already occurred and therefore, preventive action is required to stop it happening again in the future.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading169">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse169" aria-expanded="false"
                                    aria-controls="collapse169">
                                Leading indicator
                            </button>
                        </h2>
                    </div>
                    <div id="collapse169" className="collapse" aria-labelledby="heading169"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A signal that something is about to happen. A leading indicator allows premeptive action to be taken to stop failure.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading170">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse170" aria-expanded="false"
                                    aria-controls="collapse170">
                                Legal
                            </button>
                        </h2>
                    </div>
                    <div id="collapse170" className="collapse" aria-labelledby="heading170"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Product which meets the applicable legislation.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading171">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse171" aria-expanded="false"
                                    aria-controls="collapse171">
                                Legality
                            </button>
                        </h2>
                    </div>
                    <div id="collapse171" className="collapse" aria-labelledby="heading171"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Compliant to the applicable legislation.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading172">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse172" aria-expanded="false"
                                    aria-controls="collapse172">
                                Legible
                            </button>
                        </h2>
                    </div>
                    <div id="collapse172" className="collapse" aria-labelledby="heading172"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Writing that can be read, so that it can be understood. Hand written writing must be clear enough so it can be read. Printed writing must not be smudged or so faint that it can't be read.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading173">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse173" aria-expanded="false"
                                    aria-controls="collapse173">
                                Legislation
                            </button>
                        </h2>
                    </div>
                    <div id="collapse173" className="collapse" aria-labelledby="heading173"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Rules applied by law. Legislation must be considered for the Country of sale and the Processing Country. Also known as;  regulations
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading174">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse174" aria-expanded="false"
                                    aria-controls="collapse174">
                                Life
                            </button>
                        </h2>
                    </div>
                    <div id="collapse174" className="collapse" aria-labelledby="heading174"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Life in the food industry refers to how long the product lasts for. See also; shelf life, best before, use by, plus the life of the product
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading175">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse175" aria-expanded="false"
                                    aria-controls="collapse175">
                                Life cycle
                            </button>
                        </h2>
                    </div>
                    <div id="collapse175" className="collapse" aria-labelledby="heading175"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Every product has a life cycle, it is new when it's first launched and then eventually it will become old, out of date or out of fashion - which is the end of its life cycle.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading176">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse176" aria-expanded="false"
                                    aria-controls="collapse176">
                                Likelihood
                            </button>
                        </h2>
                    </div>
                    <div id="collapse176" className="collapse" aria-labelledby="heading176"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Likelihood assesses the information available to determine how likely an event, or a set of circumstances are to happen.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading177">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse177" aria-expanded="false"
                                    aria-controls="collapse177">
                                Local
                            </button>
                        </h2>
                    </div>
                    <div id="collapse177" className="collapse" aria-labelledby="heading177"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The term local is used when referring to something that happens at a particular premises, site or office. Not at another location. For example, local team, or local operation - is referring to the team and the process at that site.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading178">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse178" aria-expanded="false"
                                    aria-controls="collapse178">
                                Log
                            </button>
                        </h2>
                    </div>
                    <div id="collapse178" className="collapse" aria-labelledby="heading178"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Every document which has document control, is listed on the document control log. The document control log sometimes is known as the document index. Your site may have a different name for it; what it's called doesn't matter, what matters is that it works. The document control log must list all documents on site that have document control.

                            Not every document must have document control. The way to understand whether a document must have document control is this; if the document contains information that is required to meet your certification standard, or your customer requirements, then it must have document control.

                            The document control log must detail all the aspects of document control that are on the document. For example, if your document control is date, author, approved by, version, document reference and document name - then these aspects must be on the document control log. Each time a document is updated, the document control on the document must be updated. And the document control log must be updated too. The document control log must also detail what has been changed on the document. This is called 'amendments'.

                            When you are auditing a document, the document log must show that the document you have is the most up-to-date version. So the information on the document and on the document control log, must match.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading179">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse179" aria-expanded="false"
                                    aria-controls="collapse179">
                                Low-risk product
                            </button>
                        </h2>
                    </div>
                    <div id="collapse179" className="collapse" aria-labelledby="heading179"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Materials which don't support pathogenic growth.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading180">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse180" aria-expanded="false"
                                    aria-controls="collapse180">
                                Low-risk product area
                            </button>
                        </h2>
                    </div>
                    <div id="collapse180" className="collapse" aria-labelledby="heading180"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An area on site where low-risk materials are open to the environment. Low-risk materials can be in any open form; ingredients, work-in-progress materials or finished product. See also; low-risk product.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompL;