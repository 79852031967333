import React from 'react';

const CompA = () => {
    return (
        <>
            <h2 id="a">A</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading01">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse01" aria-expanded="true"
                                    aria-controls="collapse01">
                                Acceptable limits
                            </button>
                        </h2>
                    </div>

                    <div id="collapse01" className="collapse show" aria-labelledby="heading01"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Used when assessing monitoring results, to establish if they're acceptable or not. The limits is the range between what the minimum that's acceptable is, and what the maximum that's acceptable is. The monitoring can be subjective or objective. Also known as target +/- or tolerances.
                        </div>
                    </div>
                </div>
                {/* Card End */}
                <div className="card">
                    <div className="card-header" id="heading02">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse02" aria-expanded="false"
                                    aria-controls="collapse02">
                                Acceptable parameters
                            </button>
                        </h2>
                    </div>
                    <div id="collapse02" className="collapse" aria-labelledby="heading02"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            If a product or service is measured and the results sit within the acceptable parameters, the product is known as 'in-specification'. This means it is correct and meets the agreed specification.
                        </div>
                    </div>
                </div>
                {/* Card End */}
                <div className="card">
                    <div className="card-header" id="heading03">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse03" aria-expanded="false"
                                    aria-controls="collapse03">
                                Accepted by site
                            </button>
                        </h2>
                    </div>
                    <div id="collapse03" className="collapse" aria-labelledby="heading03"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where documentation is provided by group (central or head office) the site must still be held responsible for the implementation of it. It's not acceptable to disregard responsibility because it's owned by group. It must be 'accepted by site' and owned by site on a day-to-day basis.
                        </div>
                    </div>
                </div>
            {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading04">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse04" aria-expanded="false"
                                    aria-controls="collapse04">
                                Accountable
                            </button>
                        </h2>
                    </div>
                    <div id="collapse04" className="collapse" aria-labelledby="heading04"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A person who ensures that actions are completed. 'Accountability' isn't action-based, but rather management-based.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading05">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse05" aria-expanded="false"
                                    aria-controls="collapse05">
                                Accreditation body
                            </button>
                        </h2>
                    </div>
                    <div id="collapse05" className="collapse" aria-labelledby="heading05"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Audits the certification body, to provide certification to the ‘accreditiation’ standard.  For certification bodies this is typically to ISO17065 or ISO17021.  Acceditation bodies are national, in the UK the accreditation body is UKAS.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading06">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse06" aria-expanded="false"
                                    aria-controls="collapse06">
                                Action
                            </button>
                        </h2>
                    </div>
                    <div id="collapse06" className="collapse" aria-labelledby="heading06"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An 'action' is the process of doing a task to achieve a required outcome. There are two types of action, a corrective action and a preventive action. Actions can come management reviews, internal audits, non-conforming product, complaints, HACCP reviews. Actions may be singular or may be part of an action plan. All actions must be SMART. Actions must be closed-out effectively. Further guidance; corrective action, preventive action
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading07">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse07" aria-expanded="false"
                                    aria-controls="collapse07">
                                Action verification
                            </button>
                        </h2>
                    </div>
                    <div id="collapse07" className="collapse" aria-labelledby="heading07"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When verifying corrective actions and preventive actions, the person is checking to make sure correct action has been taken and also, that it's been completed thoroughly. Verification involves 3 parts: 1) Looking at evidence that proves that the action has been completed, 2) checking that the correct action has been taken and 3) looking at the quality of the work completed, to establish if it's been done thoroughly. For example, if the action is to clean a piece of equipment, the 3 parts of verification would involve: 1) Looking at that equipment, or looking at a photograph of that equipment, 2) making sure that the right piece of equipment has been cleaned and 3) making sure it's been cleaned to the required standard.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading08">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse08" aria-expanded="false"
                                    aria-controls="collapse08">
                                Active(ly) listening
                            </button>
                        </h2>
                    </div>
                    <div id="collapse08" className="collapse" aria-labelledby="heading08"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To focus only on what is being said.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading09">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse09" aria-expanded="false"
                                    aria-controls="collapse09">
                                Additional measures
                            </button>
                        </h2>
                    </div>
                    <div id="collapse09" className="collapse" aria-labelledby="heading09"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When objectives aren't met, this isn't acceptable. The reasons as to why the objectives were not met must be established through root cause analysis. Once the root cause of the problem has been found, action must be put in place to make sure that next time the objectives are met. This is called applying 'additional measures'. For example, the site may have set a complaints objective to reduce complaints by 10% year-on-year. The root cause analysis may find that this objective wasn't met, because the complaints data wasn't available regularly throughout the year. So the team were unaware that they wouldn't meet the objective. This was due to lack of resource to manipulate and report the data. Therefore, 'additional measures' would be to automate the complaints data or put resource in place to manage it. That way, the information would be available for the following years and would make it possible for management to know if they were going to meet the objective.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading10">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse10" aria-expanded="false"
                                    aria-controls="collapse10">
                                Adulteration
                            </button>
                        </h2>
                    </div>
                    <div id="collapse10" className="collapse" aria-labelledby="heading10"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To adulterate a product, means to add something to it that shouldn't be there. This is normally done, to dilute the expensive materials in the product, with cheaper materials.  This is a form of fraud.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading11">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse11" aria-expanded="false"
                                    aria-controls="collapse11">
                                Advisory board
                            </button>
                        </h2>
                    </div>
                    <div id="collapse11" className="collapse" aria-labelledby="heading11"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A group of industry leaders who review performance and provide direction to certification scheme owners.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading12">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse12" aria-expanded="false"
                                    aria-controls="collapse12">
                                Agency staff
                            </button>
                        </h2>
                    </div>
                    <div id="collapse12" className="collapse" aria-labelledby="heading12"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A temporary employee provided by and managed by an external source.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading13">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse13" aria-expanded="false"
                                    aria-controls="collapse13">
                                Agency staff
                            </button>
                        </h2>
                    </div>
                    <div id="collapse13" className="collapse" aria-labelledby="heading13"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Agency staff are people who work for the site, but they aren't permanent. Sometimes also known as temporary staff.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading14">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse14" aria-expanded="false"
                                    aria-controls="collapse14">
                                Agreement
                            </button>
                        </h2>
                    </div>
                    <div id="collapse14" className="collapse" aria-labelledby="heading14"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A formal and legal 'agreement' between two parties. Sometimes referred to as a contract. The 'agreement' should be documented, signed and dated by both parties to show that it is in fact agreed on both sides.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading15">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse15" aria-expanded="false"
                                    aria-controls="collapse15">
                                Alibi labelling
                            </button>
                        </h2>
                    </div>
                    <div id="collapse15" className="collapse" aria-labelledby="heading15"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Allergen warning statements, which are used to remove the need for good allergen practices.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading16">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse16" aria-expanded="false"
                                    aria-controls="collapse16">
                                Ambient high-care product
                            </button>
                        </h2>
                    </div>
                    <div id="collapse16" className="collapse" aria-labelledby="heading16"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An ambient high-care material is an ambient food which is ready to eat and supports pathogenic growth.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading17">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse17" aria-expanded="false"
                                    aria-controls="collapse17">
                                Ambient high-care product area
                            </button>
                        </h2>
                    </div>
                    <div id="collapse17" className="collapse" aria-labelledby="heading17"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An area on site where ambient high-care material is open to the environment. Ambient high-care material can be in any open form; ingredients, work-in-progress materials or finished product. See also; ambient high-care product.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading18">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse18" aria-expanded="false"
                                    aria-controls="collapse18">
                                Animal feed
                            </button>
                        </h2>
                    </div>
                    <div id="collapse18" className="collapse" aria-labelledby="heading18"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Materials which can be processed into animal feed, including pet food.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading19">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse19" aria-expanded="false"
                                    aria-controls="collapse19">
                                Applicable legislation
                            </button>
                        </h2>
                    </div>
                    <div id="collapse19" className="collapse" aria-labelledby="heading19"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The site must establish which pieces of legislation apply to the product or service. Legislation must be applied from both the Processing Country and also the Country of sale.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading20">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse20" aria-expanded="false"
                                    aria-controls="collapse20">
                                Area
                            </button>
                        </h2>
                    </div>
                    <div id="collapse20" className="collapse" aria-labelledby="heading20"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A physical location on site. See also; open food area, open food contact packaging area, open non-food contact packaging area, enclosed product area, high-risk product area.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading21">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse21" aria-expanded="false"
                                    aria-controls="collapse21">
                                Areas for improvement
                            </button>
                        </h2>
                    </div>
                    <div id="collapse21" className="collapse" aria-labelledby="heading21"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Once a culture survey has been completed, the management team must review this to work out if the company is meeting the values and vision statements that it set for culture. Where there are weaknesses, gaps or problems identified, the team must highlight these as areas that need improving. Which means, that they need to implement actions to improve. This is the start of the culture plan.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading22">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse22" aria-expanded="false"
                                    aria-controls="collapse22">
                                At-line measurement
                            </button>
                        </h2>
                    </div>
                    <div id="collapse22" className="collapse" aria-labelledby="heading22"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where measurement is taken manually and therefore the frequency of the check is less than in-line or on-line measurements. The material is taken off the line and tested by the line, so that the results are available quickly and therefore corrective action can be taken quickly once the result is received. For example, weighing material or carrying out a colour check.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading23">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse23" aria-expanded="false"
                                    aria-controls="collapse06">
                                At-risk zone
                            </button>
                        </h2>
                    </div>
                    <div id="collapse23" className="collapse" aria-labelledby="heading23"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A defined area which is segregated to prevent the spread of contamination from within the zone.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading24">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse24" aria-expanded="false"
                                    aria-controls="collapse24">
                                Authentic
                            </button>
                        </h2>
                    </div>
                    <div id="collapse24" className="collapse" aria-labelledby="heading24"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A product that is exactly what it says it is. Where a product has claims, it must meet those claims. 'It does exactly what it says on the tin.'
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading25">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse25" aria-expanded="false"
                                    aria-controls="collapse25">
                                Authority
                            </button>
                        </h2>
                    </div>
                    <div id="collapse25" className="collapse" aria-labelledby="heading25"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            e.g. local authority
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading26">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse26" aria-expanded="false"
                                    aria-controls="collapse26">
                                Awareness training
                            </button>
                        </h2>
                    </div>
                    <div id="collapse26" className="collapse" aria-labelledby="heading26"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To provide training to a basic level.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
            </div>
        </>
    );
};

export default CompA;