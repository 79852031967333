import React, { useEffect, useState } from "react";
import { APP_CONSTANTS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import Charts from "./Charts/Charts";
import FilterAction from "./Filters/FilterAction";
import { getNcListAPi } from "../../../store/actions/nc";
import NcDataTable from "./Tables/NcDataTable";
import GmpDataTable from "./Tables/GmpDataTable";
import { getGMPInspectionsByUser } from "../../../store/actions/gmp";
import { getAuditByUser } from "../../../store/actions/audit";
import SaDataTable from "./Tables/SaDataTable";
import DateField from "../../Date/DateField";
import moment from "moment";
import { getUsersByRoleApi } from "../../../store/actions/user";
import { getActionsByIdAPi } from "../../../store/actions/action";
import request from "../../../services/request";
import { GMP } from "../../../Routes/Api";
import { currentKpiList } from "../../../store/actions/kpi";

const Kpi = () => {
    const dispatch = useDispatch();

    const { list } = useSelector((state) => state?.nc);
    const { userGMPs } = useSelector((state) => state?.gmp);
    const { userSA } = useSelector((state) => state?.audit);
    const { usersByRole } = useSelector((state) => state?.user);
    const actionList = useSelector((state) => state?.action);

    const [mainReports, setMainReports] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [parentGMP, setParentGMP] = useState([]);
    const [reportBuilder, setReportBuilder] = useState({
        type: [],
        source: APP_CONSTANTS?.KPI_SOURCE_OPTIONS?.map((item) => item?.label),
        startDate: "",
        endDate: "",
        roles: [],
        grade: ["Critical", "Major", "Minor"],
        topic: APP_CONSTANTS?.TOPIC_OPTIONS?.map((item) => item?.label),
        area: [],
    });
    const [dataList, setDataList] = useState([]);
    const [currentMenu, setCurrentMenu] = useState(null);
    const [outside, setOutside] = useState([]);
    const [loader, setLoader] = useState(false);

    const org = JSON.parse(localStorage.getItem("org"));

    const dateFilter = (field, value) => {
        let to = null;
        let from = null;
        if (field === "from") {
            setReportBuilder((old) => {
                return { ...old, startDate: value };
            });
        } else {
            setReportBuilder((old) => {
                return { ...old, endDate: value };
            });
        }
    };

    const dateFilteredData = (tempArr) => {
        if (reportBuilder?.startDate && reportBuilder?.startDate !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(
                        item?.date_raised ? item?.date_raised : item?.createdAt
                    ).format(APP_CONSTANTS.DATE_FORMAT_1) >=
                    moment(reportBuilder?.startDate).format(APP_CONSTANTS.DATE_FORMAT_1)
                ) {
                    return item;
                }
            });
        }
        if (reportBuilder?.endDate && reportBuilder?.endDate !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(
                        item?.date_raised ? item?.date_raised : item?.createdAt
                    ).format(APP_CONSTANTS.DATE_FORMAT_1) <=
                    moment(reportBuilder?.endDate).format(APP_CONSTANTS.DATE_FORMAT_1)
                ) {
                    return item;
                }
            });
        }
        return tempArr;
    };

    const onChangeMenu = (event) => {
        setLoader(true);
        switch (event?.menu) {
            case "type":
                let tempTypes = reportBuilder?.type;
                if (
                    tempTypes[0] === event?.value &&
                    (event?.value === "GMP" ||
                        event?.value === "SA" ||
                        event?.value === "NC" ||
                        event?.value === "AC")
                ) {
                    tempTypes = [];
                } else if (event?.value === "AC") {
                    tempTypes = [];
                    tempTypes?.push("AC");
                    tempTypes?.push("CA");
                    tempTypes?.push("PA");
                    tempTypes?.push("PRO");
                    tempTypes?.push("RCA");
                } else if (
                    event?.value === "CA" ||
                    event?.value === "PA" ||
                    event?.value === "PRO" ||
                    event?.value === "RCA"
                ) {
                    const excludedValues = ["NC", "GMP", "SA"];
                    const filteredArray = tempTypes?.filter(
                        (item) => !excludedValues.includes(item)
                    );
                    tempTypes = filteredArray;

                    let typeIndex = tempTypes?.indexOf(event?.value);
                    if (typeIndex >= 0) {
                        tempTypes?.splice(typeIndex, 1);
                    } else {
                        tempTypes?.push(event?.value);
                    }

                    if (tempTypes?.length === 4 && !tempTypes?.includes("AC")) {
                        tempTypes?.unshift("AC");
                    } else {
                        let mainIndex = tempTypes?.indexOf("AC");
                        if (mainIndex !== -1) {
                            tempTypes?.splice(mainIndex, 1);
                        }
                    }
                } else {
                    tempTypes = [];
                    tempTypes?.push(event?.value);
                }
                let tempRoless = reportBuilder?.roles;
                let rolesArr = userRoles;

                if (event?.value === "GMP") {
                    tempRoless = tempRoless?.filter((item) => !item.startsWith("aud"));
                    rolesArr = rolesArr?.filter((item) => !item.value?.startsWith("aud"));

                    tempRoless?.push("ins");
                    let ins = { label: "Inspector", value: "ins", child: [] };
                    usersByRole?.gmp_inspectors?.map((item) => {
                        ins?.child?.push({
                            label: item?.label,
                            value: "ins" + item?.value,
                        });
                        tempRoless?.push("ins" + item?.value);
                    });
                    rolesArr?.push(ins);
                } else if (event?.value === "SA") {
                    tempRoless = tempRoless?.filter((item) => !item.startsWith("ins"));
                    rolesArr = rolesArr?.filter((item) => !item.value?.startsWith("ins"));

                    tempRoless?.push("aud");
                    let aud = { label: "Auditor", value: "aud", child: [] };
                    usersByRole?.auditor?.map((item) => {
                        aud?.child?.push({
                            label: item?.label,
                            value: "aud" + item?.value,
                        });
                        tempRoless?.push("aud" + item?.value);
                    });
                    rolesArr?.push(aud);
                } else {
                    tempRoless = tempRoless?.filter((item) => !item.startsWith("aud"));
                    rolesArr = rolesArr?.filter((item) => !item.value?.startsWith("aud"));
                    tempRoless = tempRoless?.filter((item) => !item.startsWith("ins"));
                    rolesArr = rolesArr?.filter((item) => !item.value?.startsWith("ins"));
                }
                setReportBuilder((old) => {
                    return {
                        ...old,
                        type: tempTypes,
                        roles: tempRoless,
                    };
                });

                setUserRoles(rolesArr);
                break;
            case "source":
                let tempSources = reportBuilder?.source;

                let findIndex = tempSources?.indexOf(event?.value);
                if (findIndex >= 0) {
                    tempSources?.splice(findIndex, 1);
                } else {
                    tempSources?.push(event?.value);
                }

                setReportBuilder((old) => {
                    return {
                        ...old,
                        source: tempSources,
                    };
                });
                break;
            case "roles":
                let tempRoles = reportBuilder?.roles;
                if (
                    event?.value === "acc" ||
                    event?.value === "act" ||
                    event?.value === "ver" ||
                    event?.value === "ins" ||
                    event?.value === "aud"
                ) {
                    if (tempRoles?.includes(event.value)) {
                        tempRoles = tempRoles?.filter(
                            (item) => !item.startsWith(event.value)
                        );
                    } else {
                        tempRoles?.push(event?.value);
                        userRoles?.map((item) => {
                            if (item?.value === event.value) {
                                item?.child?.map((ch) => {
                                    tempRoles?.push(ch?.value);
                                });
                            }
                        });
                    }
                } else {
                    const prefix = event?.value.substring(0, 3);
                    let prefixIndex = tempRoles?.indexOf(prefix);
                    if (prefixIndex >= 0) {
                        tempRoles?.splice(prefixIndex, 1);
                    }
                    let rolesIndex = tempRoles?.indexOf(event?.value);
                    if (rolesIndex >= 0) {
                        tempRoles?.splice(rolesIndex, 1);
                    } else {
                        tempRoles?.push(event?.value);
                        const groupedValues = {};

                        tempRoles.forEach((value) => {
                            const prefix = value.substring(0, 3); // Adjust the substring length based on your prefixes
                            if (!groupedValues.hasOwnProperty(prefix)) {
                                groupedValues[prefix] = [];
                            }
                            groupedValues[prefix].push(value);
                        });

                        const { act, acc, ver, ins, aud } = groupedValues;
                        if (acc?.length === usersByRole?.accountable_manager?.length) {
                            tempRoles?.push("acc");
                        }
                        if (act?.length === usersByRole?.actioner?.length) {
                            tempRoles?.push("act");
                        }
                        if (ver?.length === usersByRole?.verifier?.length) {
                            tempRoles?.push("ver");
                        }
                        if (ins?.length === usersByRole?.gmp_inspectors?.length) {
                            tempRoles?.push("ins");
                        }
                        if (aud?.length === usersByRole?.auditor?.length) {
                            tempRoles?.push("aud");
                        }
                    }
                }

                let uniqueChars = tempRoles?.filter((c, index) => {
                    return tempRoles.indexOf(c) === index;
                });
                setReportBuilder((old) => {
                    return {
                        ...old,
                        roles: uniqueChars,
                    };
                });
                break;
            case "grade":
                let tempGrades = reportBuilder?.grade;
                let gradeIndex = tempGrades?.indexOf(event?.value);
                if (gradeIndex >= 0) {
                    tempGrades?.splice(gradeIndex, 1);
                } else {
                    tempGrades?.push(event?.value);
                }
                setReportBuilder((old) => {
                    return {
                        ...old,
                        grade: tempGrades,
                    };
                });
                break;
            case "topic":
                let tempTopics = reportBuilder?.topic;
                let topicIndex = tempTopics?.indexOf(event?.value);
                if (topicIndex >= 0) {
                    tempTopics?.splice(topicIndex, 1);
                } else {
                    tempTopics?.push(event?.value);
                }
                setReportBuilder((old) => {
                    return {
                        ...old,
                        topic: tempTopics,
                    };
                });
                break;
            case "area":
                let tempAreas = reportBuilder?.area;
                let areaIndex = tempAreas?.indexOf(event?.value);
                if (areaIndex >= 0) {
                    tempAreas?.splice(areaIndex, 1);
                } else {
                    tempAreas?.push(event?.value);
                }
                setReportBuilder((old) => {
                    return {
                        ...old,
                        area: tempAreas,
                    };
                });
                break;
            default:
        }
    };

    const deSelectAll = (menu) => {
        switch (menu) {
            case "roles":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        roles: [],
                    };
                });
                break;
            case "source":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        source: [],
                    };
                });
                break;
            case "topic":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        topic: [],
                    };
                });
                break;
            case "grade":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        grade: [],
                    };
                });
                break;
            case "area":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        area: [],
                    };
                });
                break;
            default:
        }
    };

    const selectAll = (menu) => {
        switch (menu) {
            case "roles":
                let defaultRoles = ["acc", "act", "ver"];

                usersByRole?.accountable_manager?.map((item) => {
                    defaultRoles?.push("acc" + item?.value);
                });
                usersByRole?.actioner?.map((item) => {
                    defaultRoles?.push("act" + item?.value);
                });
                usersByRole?.verifier?.map((item) => {
                    defaultRoles?.push("ver" + item?.value);
                });

                if (reportBuilder?.type[0] === "GMP") {
                    defaultRoles.push("ins");

                    usersByRole?.gmp_inspectors?.map((item) => {
                        defaultRoles?.push("ins" + item?.value);
                    });
                } else if (reportBuilder?.type[0] === "SA") {
                    defaultRoles.push("aud");

                    usersByRole?.gmp_inspectors?.map((item) => {
                        defaultRoles?.push("aud" + item?.value);
                    });
                }

                setReportBuilder((old) => {
                    return {
                        ...old,
                        roles: defaultRoles,
                    };
                });
                break;
            case "source":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        source: APP_CONSTANTS?.KPI_SOURCE_OPTIONS?.map(
                            (item) => item?.label
                        ),
                    };
                });
                break;
            case "topic":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        topic: APP_CONSTANTS?.TOPIC_OPTIONS?.map((item) => item?.label),
                    };
                });
                break;
            case "grade":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        grade: ["Critical", "Major", "Minor"],
                    };
                });
                break;
            case "area":
                setReportBuilder((old) => {
                    return {
                        ...old,
                        area: parentGMP?.map((item) => item?.label),
                    };
                });
                break;
            default:
        }
    };

    useEffect(() => {
        if (reportBuilder?.type[0] === "NC") {
            dispatch(getNcListAPi());
        } else if (reportBuilder?.type[0] === "GMP") {
            dispatch(getGMPInspectionsByUser());
        } else if (reportBuilder?.type[0] === "SA") {
            dispatch(getAuditByUser());
        } else if (
            reportBuilder?.type?.includes("CA") ||
            reportBuilder?.type?.includes("PA") ||
            reportBuilder?.type?.includes("PRO") ||
            reportBuilder?.type?.includes("RCA")
        ) {
            dispatch(getActionsByIdAPi("kpi"));
        }
    }, [reportBuilder]);

    useEffect(() => {
        dispatch(getUsersByRoleApi());

        let tempObj = {
            label: "Others",
            value: "Others",
        };
        let arr = [];
        request.get(`${GMP.GET_PARENT_GMP}/${org?._id}`, true).then(
            (res) => {
                if (res?.status) {
                    arr = [...res?.data, tempObj];
                    setParentGMP(arr);
                    setReportBuilder((old) => {
                        return {
                            ...old,
                            area: arr?.map((item) => item?.label),
                        };
                    });
                }
            },
            (error) => { }
        );
    }, []);

    useEffect(() => {
        let tempArr = [];
        if (reportBuilder?.type[0] === "NC") {
            if (list?.length) {
                tempArr = filterNCSource(list, reportBuilder?.source);
                tempArr = dateFilteredData(tempArr);
                tempArr = rolesFilter(tempArr, reportBuilder?.roles);
                tempArr = filterGrade(tempArr, reportBuilder?.grade);
                tempArr = filterTopic(tempArr, reportBuilder?.topic);
                tempArr = filterArea(tempArr, reportBuilder?.area);
                setDataList(tempArr);
                dispatch(currentKpiList(tempArr));
                setLoader(false);
            } else {
                setLoader(false);
                setDataList([]);
            }
        } else if (reportBuilder?.type[0] === "GMP") {
            if (userGMPs?.length) {
                tempArr = dateFilteredData(userGMPs);
                tempArr = rolesFilter(tempArr, reportBuilder?.roles);
                tempArr = filterArea(tempArr, reportBuilder?.area);
                setDataList(tempArr);
                dispatch(currentKpiList(tempArr));
                setLoader(false);
            } else {
                setLoader(false);
                setDataList([]);
            }
        } else if (reportBuilder?.type[0] === "SA") {
            if (userSA?.length) {
                tempArr = dateFilteredData(userSA);
                tempArr = rolesFilter(tempArr, reportBuilder?.roles);
                tempArr = filterTopic(tempArr, reportBuilder?.topic);
                setDataList(tempArr);
                dispatch(currentKpiList(tempArr));
                setLoader(false);
            } else {
                setLoader(false);
                setDataList([]);
            }
        } else if (
            reportBuilder?.type?.includes("CA") ||
            reportBuilder?.type?.includes("PA") ||
            reportBuilder?.type?.includes("PRO") ||
            reportBuilder?.type?.includes("RCA")
        ) {
            if (actionList?.list?.length) {
                tempArr = filterActions(actionList?.list, reportBuilder?.type);
                tempArr = filterNCSource(tempArr, reportBuilder?.source);
                tempArr = dateFilteredData(tempArr);
                tempArr = rolesFilter(tempArr, reportBuilder?.roles);
                tempArr = filterGrade(tempArr, reportBuilder?.grade);
                tempArr = filterTopic(tempArr, reportBuilder?.topic);
                tempArr = filterArea(tempArr, reportBuilder?.area);
                setDataList(tempArr);
                dispatch(currentKpiList(tempArr));
                setLoader(false);
            } else {
                setLoader(false);
                setDataList([]);
            }
        }
    }, [list, userGMPs, userSA, actionList?.list]);

    function filterActions(A, B) {
        let arr = [];
        for (const objA of A) {
            if (B?.includes(objA?.type)) {
                arr?.push(objA);
            }
        }
        return arr;
    }

    function filterNCSource(A, B) {
        let arr = [];
        for (const objA of A) {
            if (
                B?.includes(objA?.source?.label) ||
                B?.includes(objA?.nc?.source?.label)
            ) {
                arr?.push(objA);
            }
        }
        return arr;
    }

    function rolesFilter(A, B) {
        const groupedValues = {};

        B.forEach((value) => {
            const prefix = value.substring(0, 3); // Adjust the substring length based on your prefixes

            const userId = value.substring(3);
            if (!groupedValues.hasOwnProperty(prefix)) {
                groupedValues[prefix] = [];
            }
            groupedValues[prefix].push(userId);
        });

        const { act, acc, ver, ins, aud } = groupedValues;
        let arr = [];
        for (const objA of A) {
            if (
                acc?.includes(objA?.accountable_manager?.value) ||
                acc?.includes(objA?.nc?.accountable_manager?.value)
            ) {
                arr?.push(objA);
            } else if (
                act?.includes(objA?.actioner?.value) ||
                act?.includes(objA?.nc?.actioner?.value) ||
                act?.includes(objA?.assigned_to?.value)
            ) {
                arr?.push(objA);
            } else if (
                ver?.includes(objA?.verifier?.value) ||
                ver?.includes(objA?.nc?.verifier?.value)
            ) {
                arr?.push(objA);
            } else if (
                objA?.type === "GMP" &&
                hasCommonInspector(
                    objA?.inspectors?.map((item) => item.value),
                    ins
                )
            ) {
                arr?.push(objA);
            } else if (
                objA?.type === "SA" &&
                aud?.includes(objA?.lead_auditor?.value)
            ) {
                arr?.push(objA);
            }
        }
        return arr;
    }

    const hasCommonInspector = (array1, array2) => {
        for (let i = 0; i < array1?.length; i++) {
            for (let j = 0; j < array2?.length; j++) {
                if (array1[i] === array2[j]) {
                    return true;
                }
            }
        }
        return false;
    };

    function filterGrade(A, B) {
        let arr = [];
        for (const objA of A) {
            if (
                B?.includes(objA?.grade) ||
                B?.includes(objA?.nc?.grade) ||
                objA?.type === "PRO"
            ) {
                arr?.push(objA);
            }
        }
        return arr;
    }

    function filterArea(A, B) {
        let arr = [];
        for (const objA of A) {
            if (
                B?.includes(objA?.parentGmp?.gmpName) ||
                (B?.includes(objA?.nc?.gmp_text) && objA?.type === "CA") ||
                (B?.includes(objA?.gmp_text) && objA?.type === "NC") ||
                objA?.type === "PRO" ||
                objA?.type === "PA" ||
                objA?.type === "RCA" ||
                (objA?.source?.label !== "GMP inspection" && objA?.type === "NC") ||
                (objA?.nc?.source?.label !== "GMP inspection" && objA?.type === "CA")
            ) {
                arr?.push(objA);
            } else if (
                B?.includes("Others") &&
                objA?.type === "NC" &&
                !objA?.reference?.length
            ) {
                arr?.push(objA);
            } else if (
                B?.includes("Others") &&
                objA?.type === "CA" &&
                !objA?.nc?.reference?.length
            ) {
                arr?.push(objA);
            }
        }
        return arr;
    }

    function filterTopic(A, B) {
        let arr = [];
        for (const objA of A) {
            if (
                B?.includes(objA?.topic?.label) ||
                B?.includes(objA?.nc?.topic?.label) ||
                B?.includes(objA?.parentSA?.name) ||
                objA?.type === "PRO" ||
                (objA?.source?.label === "GMP inspection" && !objA?.topic?.label) ||
                (objA?.source?.label === "GMP inspection" && objA?.gmpId) ||
                (objA?.nc?.source?.label === "GMP inspection" &&
                    !objA?.nc?.topic?.label) ||
                (objA?.nc?.source?.label === "GMP inspection" && objA?.nc?.gmpId)
            ) {
                arr?.push(objA);
            }
        }
        return arr;
    }

    useEffect(() => {
        let defaultRoles = ["acc", "act", "ver"];

        let rolesArr = [];
        let acc = { label: "Accountable manager", value: "acc", child: [] };
        let act = { label: "Actioner", value: "act", child: [] };
        let ver = { label: "Verifier", value: "ver", child: [] };

        usersByRole?.accountable_manager?.map((item) => {
            acc?.child?.push({ label: item?.label, value: "acc" + item?.value });
            defaultRoles?.push("acc" + item?.value);
        });
        usersByRole?.actioner?.map((item) => {
            act?.child?.push({ label: item?.label, value: "act" + item?.value });
            defaultRoles?.push("act" + item?.value);
        });
        usersByRole?.verifier?.map((item) => {
            ver?.child?.push({ label: item?.label, value: "ver" + item?.value });
            defaultRoles?.push("ver" + item?.value);
        });

        rolesArr?.push(acc);
        rolesArr?.push(act);
        rolesArr?.push(ver);

        let tempReport = JSON.parse(JSON.stringify(APP_CONSTANTS.mainReport));

        let gmp = {
            label: "GMP inspections",
            value: "GMP",
            isSelected: false,
        };

        let sa = {
            label: "System audits",
            value: "SA",
            isSelected: false,
        };

        if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_2) {
            tempReport.push(gmp);
        } else if (
            org?.subscription?.price_id === process.env.REACT_APP_PLAN_3 ||
            org?.subscription?.price_id === process.env.REACT_APP_PLAN_4
        ) {
            tempReport.push(gmp);
            tempReport.push(sa);
        }
        setMainReports(tempReport);

        setReportBuilder((old) => {
            return {
                ...old,
                roles: defaultRoles,
            };
        });
        setUserRoles(rolesArr);
    }, [usersByRole]);

    const outSideClick = (src) => {
        let tempArr = outside;
        if (tempArr[tempArr.length - 1] === "parent" && src === "parent") {
            tempArr[0] = src;
        }
        tempArr.push(src);
        setOutside(tempArr);
        if (tempArr[0] !== "child") {
            setCurrentMenu(null);
            setOutside([]);
        }
    };

    return (
        <div
            className="prog_info kpi"
            onClick={() => {
                outSideClick("parent");
            }}
        >
            <h5 className="fs-18 color-05">
                <i className="far fa-cubes"></i> Report builder
            </h5>
            <div className="custom-grid-container">
                <div
                    onClick={() => {
                        outSideClick("child");
                    }}
                >
                    <FilterAction
                        options={mainReports}
                        menu="type"
                        data={reportBuilder?.type}
                        onChange={onChangeMenu}
                        label="What's in this report?"
                        deSelectAll={deSelectAll}
                        setCurrentMenu={setCurrentMenu}
                        currentMenu={currentMenu}
                    />
                </div>
                {((reportBuilder?.type?.includes("NC") &&
                    reportBuilder?.source?.includes("GMP inspection")) ||
                    (reportBuilder?.type?.includes("CA") &&
                        reportBuilder?.source?.includes("GMP inspection")) ||
                    reportBuilder?.type?.includes("GMP")) &&
                    reportBuilder?.type?.length > 0 && (
                        <div
                            onClick={() => {
                                outSideClick("child");
                            }}
                        >
                            <FilterAction
                                options={parentGMP?.map((item) => {
                                    return { label: item?.label, value: item?.label };
                                })}
                                menu="area"
                                data={reportBuilder?.area}
                                onChange={onChangeMenu}
                                label="Area"
                                deSelectAll={deSelectAll}
                                setCurrentMenu={setCurrentMenu}
                                currentMenu={currentMenu}
                                selectAll={selectAll}
                            />
                        </div>
                    )}
                {!reportBuilder?.type?.includes("GMP") &&
                    !reportBuilder?.type?.includes("SA") &&
                    reportBuilder?.type?.length > 0 && (
                        <div
                            onClick={() => {
                                outSideClick("child");
                            }}
                        >
                            <FilterAction
                                options={APP_CONSTANTS?.KPI_SOURCE_OPTIONS}
                                menu="source"
                                data={reportBuilder?.source}
                                onChange={onChangeMenu}
                                label="Source"
                                deSelectAll={deSelectAll}
                                setCurrentMenu={setCurrentMenu}
                                currentMenu={currentMenu}
                                selectAll={selectAll}
                            />
                        </div>
                    )}
                {reportBuilder?.type?.length > 0 && (
                    <div
                        onClick={() => {
                            outSideClick("child");
                        }}
                    >
                        <label className="color-04 fs-14 mb-1">Date Range</label>
                        <div className="d-flex">
                            <DateField
                                filterData={(e) => dateFilter("from", e.target.value)}
                                raised={true}
                                dateValue={reportBuilder?.startDate}
                                label={"From:"}
                                type="KPI"
                            />
                            <DateField
                                filterData={(e) => dateFilter("to", e.target.value)}
                                dateValue={reportBuilder?.endDate}
                                label={"To:"}
                                type="KPI_TO"
                            />
                        </div>
                    </div>
                )}
                {reportBuilder?.type?.length > 0 && (
                    <div
                        onClick={() => {
                            outSideClick("child");
                        }}
                    >
                        <FilterAction
                            options={userRoles}
                            menu="roles"
                            data={reportBuilder?.roles}
                            onChange={onChangeMenu}
                            label="Roles"
                            deSelectAll={deSelectAll}
                            setCurrentMenu={setCurrentMenu}
                            currentMenu={currentMenu}
                            selectAll={selectAll}
                        />
                    </div>
                )}
                {(reportBuilder?.type?.includes("NC") ||
                    reportBuilder?.type?.includes("PA") ||
                    reportBuilder?.type?.includes("CA") ||
                    reportBuilder?.type?.includes("RCA")) &&
                    reportBuilder?.type?.length > 0 && (
                        <div
                            onClick={() => {
                                outSideClick("child");
                            }}
                        >
                            <FilterAction
                                options={APP_CONSTANTS?.grade}
                                menu="grade"
                                data={reportBuilder?.grade}
                                onChange={onChangeMenu}
                                label="Grade"
                                deSelectAll={deSelectAll}
                                setCurrentMenu={setCurrentMenu}
                                currentMenu={currentMenu}
                                selectAll={selectAll}
                            />
                        </div>
                    )}
                {(reportBuilder?.type?.includes("NC") ||
                    reportBuilder?.type?.includes("SA") ||
                    reportBuilder?.type?.includes("PA") ||
                    reportBuilder?.type?.includes("CA") ||
                    reportBuilder?.type?.includes("RCA")) &&
                    reportBuilder?.type?.length > 0 && (
                        <div
                            onClick={() => {
                                outSideClick("child");
                            }}
                        >
                            <FilterAction
                                options={APP_CONSTANTS?.TOPIC_OPTIONS}
                                menu="topic"
                                data={reportBuilder?.topic}
                                onChange={onChangeMenu}
                                label="Topic"
                                deSelectAll={deSelectAll}
                                setCurrentMenu={setCurrentMenu}
                                currentMenu={currentMenu}
                                selectAll={selectAll}
                            />
                        </div>
                    )}
            </div>

            <div className="pt-5 text-center">
                {loader ? (
                    <div className="spinner-border spinner-border-md" role="status"></div>
                ) : !loader && !dataList?.length ? (
                    <h5>Data is not available</h5>
                ) : (
                    <>
                        {(reportBuilder?.type[0] === "NC" ||
                            reportBuilder?.type?.includes("CA") ||
                            reportBuilder?.type?.includes("PA") ||
                            reportBuilder?.type?.includes("PRO") ||
                            reportBuilder?.type?.includes("RCA")) &&
                            dataList?.length > 0 && <Charts data={dataList} />}
                        {reportBuilder?.type[0] === "GMP" && dataList?.length > 0 && (
                            <Charts data={dataList} />
                        )}
                        {reportBuilder?.type[0] === "SA" && dataList?.length > 0 && (
                            <Charts data={dataList} />
                        )}
                        {(reportBuilder?.type[0] === "NC" ||
                            reportBuilder?.type?.includes("CA") ||
                            reportBuilder?.type?.includes("PA") ||
                            reportBuilder?.type?.includes("PRO") ||
                            reportBuilder?.type?.includes("RCA")) &&
                            dataList?.length > 0 && <NcDataTable data={dataList} />}
                        {reportBuilder?.type[0] === "GMP" && dataList?.length > 0 && (
                            <GmpDataTable data={dataList} />
                        )}
                        {reportBuilder?.type[0] === "SA" && dataList?.length > 0 && (
                            <SaDataTable data={dataList} />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Kpi;
