import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import NcView from "./NcView";
import ActionerBrowseModal from "../../Modals/ActionerBrowsModal";
import {
    createCaActionAPi,
    getCaActionForVerifierAPi,
    updateCaAction,
    updatePaAction,
    createPaActionAPi,
    getPaActionForVerifierAPi,
    setSelectedAction,
    addVerifierEvidence,
    sentVerifierResultApi,
    createAndSendCAAndPAActionsnApi,
    navigateHome,
    toggleEmailSuccessModal,
    toggleSuccessModal,
    toggleErrorModal,
} from "../../../store/actions/action";
import NotificationSendModal from "../../Modals/NotificationSendModal";
import { APP_CONSTANTS } from "../../../constants";
import ErrorModal from "../../Modals/ErrorModal";
import SaveModal from "../../Modals/SaveModal";
import utils from "../../../utils";
import { getExtension } from "../../../utils/icons";
import { useNavigate } from "react-router-dom";
import { deleteMediaFromS3Bucket } from "../../../utils/s3";
import { useRef } from "react";
import { generateSequence } from "../../../utils/referenceId";
import videoIcon from "../../../Assets/images/file-video-regular.svg";

const Verifier = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const btnRef = useRef(null);

    const user = JSON.parse(localStorage.getItem("user"));

    const { nav } = useSelector((state) => state?.navigate);
    const { object } = useSelector((state) => state?.nc);
    const { ca_actions, pa_actions, send, error, success } = useSelector(
        (state) => state?.action
    );

    const [rejectedStatus, setRejectedStatus] = useState(false);

    const handleCaFieldChanges = (field, value, custom, index) => {
        let actionCopy = [...ca_actions];
        let currentOj = actionCopy[index];
        currentOj["change"] = true;

        if (field === "status") {
            if (value === "Rejected") {
                currentOj["rejected"] = true;
                currentOj["verified"] = false;
            } else {
                currentOj["rejected"] = false;
                currentOj["verified"] = true;
            }
            dispatch(updateCaAction(actionCopy));
            return;
        }

        if (!custom) {
            currentOj[field] = value;
            dispatch(updateCaAction(actionCopy));
            return;
        }
    };

    const handleCaVerifierEvidence = (field, value, custom, index, ind) => {
        let actionCopy = [...ca_actions];
        actionCopy[index].verifier_evidence[ind].custom_name = value;
        dispatch(updateCaAction(actionCopy));
    };

    if (nav === true) {
        navigate(`/private/home`);
        dispatch(navigateHome(false));
    }

    const handlePaFieldChanges = (field, value, custom, index) => {
        let actionCopy = [...pa_actions];
        let currentOj = actionCopy[index];
        currentOj["change"] = true;

        if (field === "status") {
            if (value === "Rejected") {
                currentOj["rejected"] = true;
                currentOj["verified"] = false;
            } else {
                currentOj["rejected"] = false;
                currentOj["verified"] = true;
            }
            dispatch(updatePaAction(actionCopy));
            return;
        }

        if (!custom) {
            currentOj[field] = value;
            dispatch(updatePaAction(actionCopy));
            return;
        }
    };

    const handlePaVerifierEvidence = (field, value, custom, index, ind) => {
        let actionCopy = [...pa_actions];
        actionCopy[index].verifier_evidence[ind].custom_name = value;
        dispatch(updatePaAction(actionCopy));
    };

    const saveActions = async () => {
        btnRef.current.disabled = true;
        if (pa_actions?.length) {
            validatePaActions();
            dispatch(
                createPaActionAPi({
                    nc: object?._id,
                    pa_actions: pa_actions,
                    verifier: true,
                    org: object?.org,
                })
            );
        }
        if (ca_actions?.length) {
            validateCaActions();
            dispatch(
                createCaActionAPi({
                    nc: object?._id,
                    ca_actions: ca_actions,
                    verifier: true,
                    org: object?.org,
                })
            );
        }

        let actions_list = [...ca_actions, ...pa_actions];
        checkVerifiedStatus(actions_list);
    };

    const checkVerifiedStatus = (actions) => {
        let status = actions?.filter((action) => action?.status === "Verified");
        if (status?.length >= 1) {
            setRejectedStatus(false);
        } else {
            setRejectedStatus(true);
        }
    };

    const sendVerifierResult = async () => {
        btnRef.current.disabled = true;
        if (pa_actions?.length) validatePaActions();
        if (ca_actions?.length) validateCaActions();

        ca_actions?.map((item) => {
            if (item?.verified) {
                item.status = "Verified";
            } else if (item?.rejected) {
                item.status = "Rejected";
                item.completed = false;
            }
            return item;
        });

        pa_actions?.map((item) => {
            if (item?.verified) {
                item.status = "Verified";
            } else if (item?.rejected) {
                item.status = "Rejected";
                item.completed = false;
            }
            return item;
        });

        let actions_list = [...ca_actions, ...pa_actions];
        checkVerifiedStatus(actions_list);

        dispatch(
            createAndSendCAAndPAActionsnApi(
                object?._id,
                ca_actions,
                pa_actions,
                sentVerifierResultApi,
                { nc: object?._id },
                object?.org,
                "verifier"
            )
        );
        /*dispatch(sentVerifierResultApi({nc: object?._id}))*/
    };

    const validateCaActions = () => {
        let actionCopy = [...ca_actions];
        actionCopy.forEach((obj, index) => {
            if (obj?._id && obj.change) obj.update = true;
        });
        dispatch(updateCaAction(actionCopy));
    };

    const validatePaActions = () => {
        let actionCopy = [...pa_actions];
        actionCopy.forEach((obj, index) => {
            if (obj?._id && obj.change) obj.update = true;
        });
        dispatch(updatePaAction(actionCopy));
    };

    const removeCaEvidence = async (obj, caIndex, fileIndex) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            let actionCopy = [...ca_actions];
            let select = actionCopy[caIndex];
            if (obj.key) await deleteMediaFromS3Bucket(obj.key);
            select?.removeFiles
                ? select.removeFiles.push(select?.verifier_evidence[fileIndex])
                : (select.removeFiles = [select?.verifier_evidence[fileIndex]]);
            select?.verifier_evidence?.splice(fileIndex, 1);
            select.change = true;
            dispatch(updateCaAction(actionCopy));
        }
    };

    const removePaEvidence = async (obj, caIndex, fileIndex) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            let actionCopy = [...pa_actions];
            let select = actionCopy[caIndex];
            if (obj.key) await deleteMediaFromS3Bucket(obj.key);
            select?.removeFiles
                ? select.removeFiles.push(select?.verifier_evidence[fileIndex])
                : (select.removeFiles = [select?.verifier_evidence[fileIndex]]);
            select?.verifier_evidence?.splice(fileIndex, 1);
            select.change = true;
            dispatch(updatePaAction(actionCopy));
        }
    };

    const openLinkInNewTab = (link) => {
        window.open(link);
    };

    const checkVerifier = () => {
        if (user?._id === object?.verifier?.user) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        dispatch(updatePaAction([]));
        dispatch(updateCaAction([]));
        if (object?._id) {
            dispatch(
                getCaActionForVerifierAPi({ nc: object?._id, org: object?.org })
            );
            dispatch(
                getPaActionForVerifierAPi({ nc: object?._id, org: object?.org })
            );
        }
    }, [object]);

    useEffect(() => {
        if (error && btnRef?.current) {
            btnRef.current.disabled = false;
        }
    }, [error]);

    return (
        <section className="reference">
            <div className="container-fluid">
                <div className="row">
                    <NcView />
                    {/* Column End */}
                    <div className="col-12 col-lg-6 mt-3">
                        {ca_actions?.map(
                            (obj, index) =>
                                obj?._id && (
                                    <>
                                        <h2 className="fs-16 fw-600 color-04">
                                            Corrective action{" "}
                                            <span className="float-right">
                                                Reference:{" "}
                                                {`${obj?.type}-${generateSequence(obj?.id || 0)}`}
                                            </span>
                                        </h2>
                                        <p className="action-note">
                                            {obj?.corrective_action_notes}
                                        </p>
                                        <h2 className="fs-16 fw-600 color-04">Actioner notes</h2>
                                        <p className="action-note">{obj?.actioner_notes}</p>
                                        <div className="light--bg px-2">
                                            <dl className="row align-items-center py-1">
                                                <dt className="col-sm-4">Due date</dt>
                                                <dd className="col-sm-8">
                                                    {obj?.due_date
                                                        ? moment(obj?.due_date).format(
                                                            APP_CONSTANTS.DATE_FORMAT
                                                        )
                                                        : ""}
                                                </dd>
                                            </dl>
                                        </div>
                                        <div className="px-2">
                                            <dl className="row">
                                                <dt className="col-sm-4">Status</dt>
                                                <dd className="col-sm-8">{obj?.status}</dd>
                                            </dl>
                                        </div>
                                        <h2 className="fs-16 fw-600 color-04">Actioner Evidence</h2>
                                        <div className="row my-4 evidence-scroll">
                                            {obj?.actioner_evidence?.map((obj, ind) => (
                                                <div className="col-sm-6 pl-0" key={ind}>
                                                    {obj.extension === "images" && obj?.path && (
                                                        <img
                                                            loading="lazy"
                                                            src={`${obj?.path}`}
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    {obj.extension === "videos" && obj?.path && (
                                                        // <video
                                                        //     className="cursor"
                                                        //     onClick={() => {
                                                        //         openLinkInNewTab(obj?.path);
                                                        //     }}
                                                        //     width="49"
                                                        //     src={`${obj?.path}#t=0.1`}
                                                        //     alt="Evidence"
                                                        // />
                                                        <img
                                                            className="cursor"
                                                            loading="lazy"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            src={videoIcon}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    {obj.extension === "files" && obj?.path && (
                                                        <img
                                                            loading="lazy"
                                                            className="cursor"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            src={getExtension(obj?.path)}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    <input
                                                        className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                        value={obj?.custom_name}
                                                        placeholder="optional title"
                                                        // onChange={(e) =>
                                                        //     handleCaFieldChanges(
                                                        //         "custom_title",
                                                        //         e.target.value,
                                                        //         true,
                                                        //         index
                                                        //     )
                                                        // }
                                                        disabled={!utils.checkSubStatus()}
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        <h2 className="fs-16 fw-600 color-04">
                                            Verification notes
                                        </h2>
                                        <textarea
                                            className="form-control"
                                            id=""
                                            rows="5"
                                            value={obj?.verifier_notes}
                                            onChange={(e) =>
                                                handleCaFieldChanges(
                                                    "verifier_notes",
                                                    e.target.value,
                                                    false,
                                                    index
                                                )
                                            }
                                            disabled={!utils.checkSubStatus()}
                                        />
                                        <div className="form-group row light-bg add-evidence mb-0 mt-3">
                                            <label className="col-sm-9">Add evidence</label>
                                            <div className="col-sm-3">
                                                <button
                                                    type="button"
                                                    className="btn-browse color-03 fs-12 fw-300 float-lg-right"
                                                    data-toggle="modal"
                                                    data-target="#actionerBrowseModal"
                                                    onClick={() => dispatch(setSelectedAction(obj))}
                                                    disabled={!utils.checkSubStatus()}
                                                >
                                                    <i className="far fa-folder-open" /> Browse
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row my-4 evidence-scroll">
                                            {obj?.verifier_evidence?.map((obj, ind) => (
                                                <div className="col-sm-6 pl-0" key={ind}>
                                                    <i
                                                        className="far fa-minus-circle"
                                                        onClick={() => removeCaEvidence(obj, index, ind)}
                                                    />
                                                    {obj.extension === "images" && obj?.path && (
                                                        <img
                                                            loading="lazy"
                                                            src={`${obj?.path}`}
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    {obj.extension === "videos" && obj?.path && (
                                                        // <video
                                                        //     className="cursor"
                                                        //     onClick={() => {
                                                        //         openLinkInNewTab(obj?.path);
                                                        //     }}
                                                        //     width="49"
                                                        //     src={`${obj?.path}#t=0.1`}
                                                        //     alt="Evidence"
                                                        // />
                                                        <img
                                                            className="cursor"
                                                            loading="lazy"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            src={videoIcon}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    {obj.extension === "files" && obj?.path && (
                                                        <img
                                                            loading="lazy"
                                                            className="cursor"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            src={getExtension(obj?.path)}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    <input
                                                        className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                        value={obj?.custom_name}
                                                        placeholder="optional title"
                                                        onChange={(e) =>
                                                            handleCaVerifierEvidence(
                                                                "custom_title",
                                                                e.target.value,
                                                                true,
                                                                index,
                                                                ind
                                                            )
                                                        }
                                                        disabled={!utils.checkSubStatus()}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <dl className="row mt-4">
                                            <dt className="col-sm-9">Complete action?</dt>
                                            <dd className="col-sm-3">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                id={`ca-${index}`}
                                                                checked={obj?.rejected}
                                                                // checked={obj?.status === "Rejected"}
                                                                onClick={(e) =>
                                                                    handleCaFieldChanges(
                                                                        "status",
                                                                        "Rejected",
                                                                        false,
                                                                        index
                                                                    )
                                                                }
                                                                disabled={!utils.checkSubStatus()}
                                                            />
                                                            <label
                                                                className="custom-control-label cursor fs-14 fw-400 color-04"
                                                                htmlFor={`ca-${index}`}
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                id={`ca--${index}`}
                                                                checked={obj?.verified}
                                                                // checked={obj?.status === "Verified"}
                                                                disabled={!utils.checkSubStatus()}
                                                            />
                                                            <label
                                                                className="custom-control-label cursor fs-14 fw-400 color-04"
                                                                htmlFor={`ca--${index}`}
                                                                onClick={(e) =>
                                                                    handleCaFieldChanges(
                                                                        "status",
                                                                        "Verified",
                                                                        false,
                                                                        index
                                                                    )
                                                                }
                                                                disabled={!utils.checkSubStatus()}
                                                            >
                                                                Yes
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </dd>
                                        </dl>
                                    </>
                                )
                        )}
                        {pa_actions?.map(
                            (obj, index) =>
                                obj?._id && (
                                    <>
                                        <div className="divider mb-4"></div>
                                        <h2 className="fs-16 fw-600 color-04">
                                            Preventive action{" "}
                                            <span className="float-right">
                                                Reference:{" "}
                                                {`${obj?.type}-${generateSequence(obj?.id || 0)}`}
                                            </span>
                                        </h2>
                                        <p className="action-note">
                                            {obj?.preventive_action_notes}
                                        </p>
                                        <h2 className="fs-16 fw-600 color-04">Actioner notes</h2>
                                        <p className="action-note">{obj?.actioner_notes}</p>
                                        <div className="light--bg px-2">
                                            <dl className="row align-items-center py-1">
                                                <dt className="col-sm-4">Due date</dt>
                                                <dd className="col-sm-8">
                                                    {obj?.due_date
                                                        ? moment(obj?.due_date).format(
                                                            APP_CONSTANTS.DATE_FORMAT
                                                        )
                                                        : ""}
                                                </dd>
                                            </dl>
                                        </div>
                                        <div className="px-2">
                                            <dl className="row">
                                                <dt className="col-sm-4">Status</dt>
                                                <dd className="col-sm-8">{obj?.status}</dd>
                                            </dl>
                                        </div>

                                        <h2 className="fs-16 fw-600 color-04">Actioner Evidence</h2>
                                        <div className="row my-4 evidence-scroll">
                                            {obj?.actioner_evidence?.map((obj, ind) => (
                                                <div className="col-sm-6 pl-0" key={ind}>
                                                    {obj.extension === "images" && obj?.path && (
                                                        <img
                                                            loading="lazy"
                                                            src={`${obj?.path}`}
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    {obj.extension === "videos" && obj?.path && (
                                                        // <video
                                                        //     className="cursor"
                                                        //     onClick={() => {
                                                        //         openLinkInNewTab(obj?.path);
                                                        //     }}
                                                        //     width="49"
                                                        //     src={`${obj?.path}#t=0.1`}
                                                        //     alt="Evidence"
                                                        // />
                                                        <img
                                                            className="cursor"
                                                            loading="lazy"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            src={videoIcon}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    {obj.extension === "files" && obj?.path && (
                                                        <img
                                                            loading="lazy"
                                                            className="cursor"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            src={getExtension(obj?.path)}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    <input
                                                        className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                        value={obj?.custom_name}
                                                        placeholder="optional title"
                                                        // onChange={(e) =>
                                                        //     handlePaFieldChanges(
                                                        //         "custom_title",
                                                        //         e.target.value,
                                                        //         true,
                                                        //         index
                                                        //     )
                                                        // }
                                                        disabled={!utils.checkSubStatus()}
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        <h2 className="fs-16 fw-600 color-04">
                                            Verification notes
                                        </h2>
                                        <textarea
                                            className="form-control"
                                            id=""
                                            rows="5"
                                            value={obj?.verifier_notes}
                                            onChange={(e) =>
                                                handlePaFieldChanges(
                                                    "verifier_notes",
                                                    e.target.value,
                                                    false,
                                                    index
                                                )
                                            }
                                            disabled={!utils.checkSubStatus()}
                                        />
                                        <div className="form-group row light-bg add-evidence mb-0 mt-3">
                                            <label className="col-sm-9">Add evidence</label>
                                            <div className="col-sm-3">
                                                <button
                                                    type="button"
                                                    className="btn-browse color-03 fs-12 fw-300 float-lg-right"
                                                    data-toggle="modal"
                                                    data-target="#actionerBrowseModal"
                                                    onClick={() => dispatch(setSelectedAction(obj))}
                                                    disabled={!utils.checkSubStatus()}
                                                >
                                                    <i className="far fa-folder-open" /> Browse
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row my-4 evidence-scroll">
                                            {obj?.verifier_evidence?.map((obj, ind) => (
                                                <div className="col-sm-6 pl-0">
                                                    <i
                                                        className="far fa-minus-circle"
                                                        onClick={() => removePaEvidence(obj, index, ind)}
                                                    />
                                                    {obj.extension === "images" && obj?.path && (
                                                        <img
                                                            loading="lazy"
                                                            src={`${obj?.path}`}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    {obj.extension === "videos" && obj?.path && (
                                                        // <video
                                                        //     className="cursor"
                                                        //     onClick={() => {
                                                        //         openLinkInNewTab(obj?.path);
                                                        //     }}
                                                        //     width="49"
                                                        //     src={`${obj?.path}#t=0.1`}
                                                        //     alt="Evidence"
                                                        // />
                                                        <img
                                                            className="cursor"
                                                            loading="lazy"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            src={videoIcon}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    {obj.extension === "files" && obj?.path && (
                                                        <img
                                                            loading="lazy"
                                                            className="cursor"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            src={getExtension(obj?.path)}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    <input
                                                        className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                        value={obj?.custom_name}
                                                        placeholder="optional title"
                                                        onChange={(e) =>
                                                            handlePaVerifierEvidence(
                                                                "custom_title",
                                                                e.target.value,
                                                                true,
                                                                index,
                                                                ind
                                                            )
                                                        }
                                                        disabled={!utils.checkSubStatus()}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <dl className="row mt-4">
                                            <dt className="col-sm-9">Complete action?</dt>
                                            <dd className="col-sm-3">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                id={`pa-${index}`}
                                                                checked={obj?.rejected}
                                                                // checked={obj?.status === "Rejected"}
                                                                onClick={(e) =>
                                                                    handlePaFieldChanges(
                                                                        "status",
                                                                        "Rejected",
                                                                        false,
                                                                        index
                                                                    )
                                                                }
                                                                disabled={!utils.checkSubStatus()}
                                                            />
                                                            <label
                                                                className="custom-control-label cursor fs-14 fw-400 color-04"
                                                                htmlFor={`pa-${index}`}
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                id={`pa--${index}`}
                                                                checked={obj?.verified}
                                                                // checked={obj?.status === "Verified"}
                                                                onClick={(e) =>
                                                                    handlePaFieldChanges(
                                                                        "status",
                                                                        "Verified",
                                                                        false,
                                                                        index
                                                                    )
                                                                }
                                                                disabled={!utils.checkSubStatus()}
                                                            />
                                                            <label
                                                                className="custom-control-label cursor fs-14 fw-400 color-04"
                                                                htmlFor={`pa--${index}`}
                                                            >
                                                                Yes
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </dd>
                                        </dl>
                                    </>
                                )
                        )}
                    </div>
                    {/* Column End */}
                </div>
            </div>
            {utils.checkSubscription() &&
                checkVerifier() &&
                (ca_actions?.length > 0 || pa_actions?.length > 0) && (
                    <div className="float-right mt-4">
                        <button
                            className="btn-save mr-2"
                            onClick={saveActions}
                            ref={btnRef}
                            disabled={btnRef?.current?.disabled}
                        >
                            <i className="far fa-save" /> Save
                        </button>
                        <button
                            className="btn-send"
                            onClick={sendVerifierResult}
                            ref={btnRef}
                            disabled={btnRef?.current?.disabled}
                        >
                            <i className="far fa-share" /> Send
                        </button>
                    </div>
                )}
            <ActionerBrowseModal dispatch={dispatch} action={addVerifierEvidence} />
            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                            window.location.href = "/";
                        }}
                    ></div>
                    <SaveModal
                        h2={
                            // rejectedStatus
                            //     ? "You have rejected verifying the actions"
                            //     :
                            APP_CONSTANTS.VERIFIER_ACTION_UPDATE_MESSAGE.h2
                        }
                        h3={"We've saved your changes to the system."}
                    />
                </>
            )}
            {error?.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal({ show: false, error: {} }));
                        }}
                    ></div>
                    <ErrorModal error={error.error} />
                </>
            )}
            {send && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleEmailSuccessModal({ send: false }));
                            window.location.href = "/";
                        }}
                    ></div>
                    <NotificationSendModal
                        h2={
                            rejectedStatus
                                ? "You have rejected verifying the actions"
                                : APP_CONSTANTS.ACTION_VERIFIER_Email.h2
                        }
                        h3={!rejectedStatus && APP_CONSTANTS.ACTION_VERIFIER_Email.h3}
                        p={APP_CONSTANTS.ACTION_VERIFIER_Email.p}
                    />
                </>
            )}
        </section>
    );
};

export default Verifier;
