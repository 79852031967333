import LoginGroup from "../../Assets/images/group-shot.png";
import Logo from "../../Assets/images/Group 83.png";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import request from "../../services/request";
import { USER } from "../../Routes/Api";
import { APP_CONSTANTS } from "../../constants";

const ResetPassword = () => {
    let navigate = useNavigate();

    const [credentials, setCredentials] = useState({});
    const [object, setObject] = useState({});
    const [isValid, setIsValid] = useState(true);

    const strongPasswordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()\-_+={}[\]|\\;:'"<>,./?]).{8,}$/;

    const isPasswordStrong = (password) => {
        return strongPasswordRegex.test(password);
    };

    const handleChangeInfields = (field, value) => {
        let credentialsCopy = { ...credentials };
        credentialsCopy[field] = value;
        if (field === "password") {
            setIsValid(isPasswordStrong(value));
        }
        setCredentials(credentialsCopy);
    };

    const forgotPassword = async () => {
        try {
            let result = await request.post(USER.FORGOT_PASSWORD, credentials);
            if (result.status) {
                setObject((old) => {
                    return {
                        ...old,
                        showOtp: true,
                        error: { show: false },
                        success: {
                            show: true,
                            message: APP_CONSTANTS.ACCESS_TOKEN_SUCCESS_MESSAGE,
                        },
                    };
                });
                setTimeout(() => {
                    setObject((old) => {
                        return {
                            ...old,
                            showOtp: true,
                            success: { show: false, message: "" },
                        };
                    });
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        } catch (e) {
            setObject((old) => {
                return {
                    ...old,
                    error: { show: true, message: e.message },
                };
            });
        }
    };

    const validateOtp = async () => {
        try {
            let result = await request.post(USER.VALIDATE_OTP, credentials);
            if (result.status)
                setObject((old) => {
                    return {
                        ...old,
                        showPassword: true,
                        error: { show: false },
                        showResend: false,
                    };
                });
        } catch (e) {
            setObject((old) => {
                return {
                    ...old,
                    error: { show: true, message: e.message },
                    showResend: true,
                };
            });
        }
    };

    const resetPassword = async () => {
        try {
            if (!validatePassword()) return false;
            let result = await request.post(USER.RESET_PASSWORD, credentials);
            if (result.status) navigate(`/`);
        } catch (e) {
            setObject((old) => {
                return {
                    ...old,
                    error: { show: true, message: e.message },
                };
            });
        }
    };

    const validatePassword = () => {
        if (credentials.password !== credentials.confirmPassword) {
            setObject((old) => {
                return {
                    ...old,
                    error: { show: true, message: "passwords don’t match" },
                };
            });
            return false;
        } else if (!isValid) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            <div className="login-box">
                <div className="container d-flex h-100">
                    <div className="row align-self-center justify-content-center mx-auto login-inner-box">
                        <div className="col-sm-6">
                            <img src={LoginGroup} width={326} alt="Login" />
                        </div>
                        <div className="col-sm-6">
                            <div className="card login-form">
                                <div className="card-body">
                                    <img src={Logo} alt="Logo" />
                                    <div className="card-inputs my-3">
                                        <h2 className="fs-18 fw-600 color-02 text-center mb-3">
                                            Reset your password
                                        </h2>
                                        <div className="form-group mb-0">
                                            <input
                                                type="email"
                                                className="form-control form-control-sm"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Email"
                                                onChange={(e) => {
                                                    handleChangeInfields("email", e.target.value);
                                                }}
                                            />
                                        </div>
                                        {object.showOtp && (
                                            <div className="form-group mb-0">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    id="otp"
                                                    value={object?.otp}
                                                    placeholder="Access Token"
                                                    onChange={(e) => {
                                                        handleChangeInfields("otp", e.target.value);
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {/*  OTP End  */}
                                        {object.showPassword && (
                                            <>
                                                <div className="form-group mb-0">
                                                    <input
                                                        type="password"
                                                        className="form-control form-control-sm"
                                                        id="new-password"
                                                        value={object?.password}
                                                        placeholder="New Password"
                                                        onChange={(e) => {
                                                            handleChangeInfields("password", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group mb-0">
                                                    <input
                                                        type="password"
                                                        className="form-control form-control-sm"
                                                        id="confirm-password"
                                                        value={object?.confirmPassword}
                                                        placeholder="Confirm Password"
                                                        onChange={(e) => {
                                                            handleChangeInfields(
                                                                "confirmPassword",
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {!isValid && (
                                            <small className="text-danger fs-11">
                                                Password should be strong. A strong password should
                                                consist of at least 8 characters, including a
                                                combination of uppercase and lowercase letters, at least
                                                one digit (0-9), and at least one special character.
                                            </small>
                                        )}
                                        {/*  New Password End  */}
                                    </div>
                                    {object?.error?.show && (
                                        <p className="warning-msg">
                                            <i className="fal fa-exclamation-triangle color-04" />{" "}
                                            Error: {object?.error?.message}
                                        </p>
                                    )}
                                    {object?.success?.show && (
                                        <p className="success-msg">
                                            <i className="fal fa-check color-04" />{" "}
                                            {object?.success?.message}
                                        </p>
                                    )}
                                    <div className="forgot-password float-right">
                                        <NavLink to={"/"}>Back to login page</NavLink> |
                                        <a
                                            href={"https://techni-k.co.uk/knowledge-base/audit-app/"}
                                            target="_blank"
                                        >
                                            {" "}
                                            Help
                                        </a>
                                    </div>
                                </div>
                                {!object?.showOtp && !object?.showPassword && (
                                    <div className="login-button">
                                        <button
                                            onClick={forgotPassword}
                                            className="btn-login float-right"
                                        >
                                            Reset
                                        </button>
                                    </div>
                                )}
                                {object?.showOtp && !object?.showPassword && (
                                    <div className="login-button">
                                        <button
                                            onClick={validateOtp}
                                            className="btn-login float-right"
                                        >
                                            Validate Access Token
                                        </button>
                                    </div>
                                )}
                                {object?.showPassword && (
                                    <div className="login-button">
                                        <button
                                            onClick={resetPassword}
                                            className="btn-login float-right"
                                        >
                                            Reset
                                        </button>
                                    </div>
                                )}
                                {object?.showOtp && !object?.showPassword && (
                                    <div className="access-token" onClick={forgotPassword}>
                                        <a className="cursor float-right">Resend Access Token</a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
