import React from "react";
import DataTable from "react-data-table-component";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const RepeatNonConformances = (props) => {
    const { data, setData } = props;

    const columns = [
        {
            name: "Topic",
            selector: (row) => row?.topic?.replace(/^\d+/, ""),
            cell: (row) => row?.topic?.replace(/^\d+/, ""),
            width: "250px",
        },
        {
            name: "Subject",
            selector: (row) => row?.subjectName?.replace(/^\d+/, ""),
            cell: (row) => row?.subjectName?.replace(/^\d+/, ""),
        },
        {
            name: "Subtitle",
            selector: (row) => row?.subtitleIdName?.replace(/^\d+/, ""),
            cell: (row) => row?.subtitleIdName?.replace(/^\d+/, ""),
        },
        {
            name: "NC count",
            selector: (row) => row?.count,
            cell: (row) => row?.count,
        },
        {
            name: "NC references",
            selector: (row) =>
                row?.data?.map((item, index) => `${item?.type}-${item?.id}`).join(", "),
            cell: (row) => (
                <div className="py-3">
                    {row?.data
                        ?.map((item, index) => `${item?.type}-${item?.id}`)
                        .join(", ")}
                </div>
            ),
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataRepeatNc = data?.map((item) => {
            if (row?.qid !== item?.qid) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setData(updatedDataRepeatNc);
    };

    const customUniqueID = (row) => {
        return row?.qid;
    };

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    return (
        <div className="mt-3">
            <DataTable
                columns={columns}
                data={data ? data : []}
                customStyles={customStyles}
                striped
                persistTableHead
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );
};

export default RepeatNonConformances;
