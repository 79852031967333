export const generateSequence = (id) => {
    if (id <= 99999) {
        return id.toString();
    }
    let newid;
    newid = id % 100000;
    let seq = id / 100000;
    const sequence = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
    ];
    const result = [];
    for (let i = 0; i < sequence.length; i++) {
        result.push(sequence[i]);
    }
    for (let i = 0; i < sequence.length; i++) {
        for (let j = 0; j < sequence.length; j++) {
            result.push(sequence[i] + sequence[j]);
        }
    }
    return newid.toString().padStart(5, "0") + result[parseInt(seq) - 1];
};
