import React from 'react';

const CompD = () => {
    return (
        <>
            <h2 id="d">D</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading92">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse92" aria-expanded="true"
                                    aria-controls="collapse92">
                                Danger zone
                            </button>
                        </h2>
                    </div>
                    <div id="collapse92" className="collapse show" aria-labelledby="heading92"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The temperature range from the 6°C to 62°C. It’s called the danger zone because this is the temperature range where pathogenic microbes can live and will grow to dangerous numbers, which can then result in food poisoning.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading93">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse93" aria-expanded="false"
                                    aria-controls="collapse93">
                                Dead-leg
                            </button>
                        </h2>
                    </div>
                    <div id="collapse93" className="collapse" aria-labelledby="heading93"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A piece of pipework that doesn't go anywhere - so it's a dead end. This typically happens when a section of the pipework has been capped off, due to changes to the system. A dead leg doesn't get cleaned effectively and therefore is a source of contamination.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading94">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse94" aria-expanded="false"
                                    aria-controls="collapse94">
                                Decontaminate
                            </button>
                        </h2>
                    </div>
                    <div id="collapse94" className="collapse" aria-labelledby="heading94"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where something has gone through a treatment to ensure that any pathogenic contamination is reduced to an acceptable level.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading95">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse95" aria-expanded="false"
                                    aria-controls="collapse95">
                                Defect(s)
                            </button>
                        </h2>
                    </div>
                    <div id="collapse95" className="collapse" aria-labelledby="heading95"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An attribute that doesn't meet a set standard.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading96">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse96" aria-expanded="false"
                                    aria-controls="collapse96">
                                Deputies
                            </button>
                        </h2>
                    </div>
                    <div id="collapse96" className="collapse" aria-labelledby="heading96"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A deputy is a person that takes over, when the person how does the role isn't available. The deputy should have sufficient knowledge and experience to be able to cover the role.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading97">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse97" aria-expanded="false"
                                    aria-controls="collapse97">
                                Designated
                            </button>
                        </h2>
                    </div>
                    <div id="collapse97" className="collapse" aria-labelledby="heading97"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that has been specifically allocated for a particular purpose. Such as a cleaning equipment or a smoking area.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading98">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse98" aria-expanded="false"
                                    aria-controls="collapse98">
                                Digital / Digitally
                            </button>
                        </h2>
                    </div>
                    <div id="collapse98" className="collapse" aria-labelledby="heading98"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Information that is provided on a computer. You can't physically touch it, as it's inside the computer. Digital information can be printed onto paper, when it then becomes hard-copy format.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading99">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse99" aria-expanded="false"
                                    aria-controls="collapse99">
                                Document
                            </button>
                        </h2>
                    </div>
                    <div id="collapse99" className="collapse" aria-labelledby="heading99"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Controlled information presented in hard-copy or digital format. Documents can be known as; policies, procedures, working methods, work instructions, guides, standard operating procedures, posters, record templates. Further guidance; document control, instructional document
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading100">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse100" aria-expanded="false"
                                    aria-controls="collapse100">
                                Document control
                            </button>
                        </h2>
                    </div>
                    <div id="collapse100" className="collapse" aria-labelledby="heading100"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Each time a controlled document is updated, you need to know which is the old and which is the new version of the document. This is done using 'document control'. It's called 'document control', as it controls the updates to the document. The 'document control' must as a minimum contain; the name of the document, the date when the document was updated, who updated it (sometimes known as the author) and who approved it. It may also contain a document reference for filing purposes and also a version or issue number. Each time a document is updated, the elements of the 'document control' are updated too. That way, you know which is the most up-to-date version of the document. When a document is updated, information may be added or taken away which changes the way in which the document is used, if the wrong version is used, then the procedure or record may be wrong. The 'document control' makes sure that you're using the most up-to-date version of the document.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading101">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse101" aria-expanded="false"
                                    aria-controls="collapse101">
                                Document security
                            </button>
                        </h2>
                    </div>
                    <div id="collapse101" className="collapse" aria-labelledby="heading101"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Documents must be secure so that they cannot be accessed and changed by those who are not authorised to do so. This is typically done by only allowing authorised personnel to the servers, drives or folders that hold documents. Or documents, folders or software that hold documents are password protected. When auditing document security, consideration should be given to sensitive information held in documents and whether the viewing of such documents should also be restricted.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompD;