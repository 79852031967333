import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { APP_CONSTANTS } from "../../constants";
import utils from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { emptyList, filterNcList, getNcListAPi } from "../../store/actions/nc";
import { updateNCObject } from "../../store/actions/nc";
import { updateProObject } from "../../store/actions/pro";
import { changeTabState } from "../../store/actions/tabState";
import { useNavigate } from "react-router-dom";
import Searchbar from "../Home/TodoLIst/Searchbar";
import DateField from "../Date/DateField";
import DeleteNcModal from "../Modals/DeleteNcModal";
import { generateSequence } from "../../utils/referenceId";
import EditNcPermission from "../Modals/EditNcPermission";
import FilteredInputField from "./FilteredInputField";
import FilteredInputDuration from "./FilteredInputDuration";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const NCs = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [deleteNc, setDeleteNc] = useState(false);
    const [data, setData] = useState("");

    const { list } = useSelector((state) => state.nc);
    const [filterDate, setFilterDate] = useState({ from: "", to: "" });
    const [filterStatus, setFilterStatus] = useState("none");
    const [filterStandards, setFilterStandards] = useState("none");
    const [search, setSearch] = useState("");
    const [permission, setPermission] = useState(false);
    const [filterDuration, setFilterDuration] = useState("none");
    const nc_loader = useSelector((state) => state?.nc?.loader);

    const logStandards = useSelector(
        (state) => state?.additionalStandards?.logsStandards
    );

    const getStartdardsList = () => {
        return [
            {
                label: "Choose a Standard",
                value: "none",
            },
            {
                label: "All",
                value: "all",
            },
            ...updatedStandarlist,
        ];
    };

    const mapStandards = () => {
        return logStandards.map((obj) => {
            return {
                label: obj.label,
                value: obj.value,
            };
        });
    };

    const updatedStandarlist = mapStandards().map((item) => {
        const updatedLabel = item.label
            .replace("Standard (Core)", "")
            .replace("Additional Standard", "")
            .trim();
        return { ...item, label: updatedLabel };
    });

    var user = JSON.parse(localStorage.getItem("user"));

    const redirectReference = (row) => {
        let state = { id: row._id, nc: row };
        if (
            (user?.permissions?.general?.system_manager && row?.status === "Draft") ||
            (user?._id === row?.raised_by?.value && row?.status === "Draft")
        ) {
            editSelectedObject(row);
        } else if (
            !user?.permissions?.general?.system_manager &&
            row?.status === "Draft"
        ) {
            setPermission(true);
        } else {
            navigate(`/private/reference/${row.org}/${row._id}/1`, { state });
        }
    };

    const toggleDeleteNc = (flag) => {
        setDeleteNc(flag);
    };

    const handleRowSelection = ({ selectedRows }) => {
        dispatch(filterNcList(selectedRows));
    };

    const statuses = [
        {
            label: "Choose a Status",
            value: "none",
        },
        {
            label: "All",
            value: "all",
        },
        {
            label: "Draft",
            value: "Draft",
        },
        {
            label: "Sent",
            value: "Sent",
        },
        {
            label: "Accepted",
            value: "Accepted",
        },
        {
            label: "Reassigned",
            value: "Reassigned",
        },
        {
            label: "In progress",
            value: "In progress",
        },
        {
            label: "Overdue",
            value: "Overdue",
        },
        {
            label: "Closed",
            value: "Closed",
        },
        {
            label: "Verified late",
            value: "Verified late",
        },
        {
            label: "Closed late",
            value: "Closed late",
        },
        {
            label: "Delegated",
            value: "Delegated",
        },
        {
            label: "Completed late and verified late",
            value: "Completed late and verified late",
        },
    ];

    const FilterDurationArray = [
        {
            label: "Choose a Status",
            value: "none",
        },
        {
            label: "All",
            value: "all",
        },
        {
            label: "Current",
            value: "current",
        },
        {
            label: "Past",
            value: "past",
        },
    ];

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                list?.filter((item) => {
                    if (
                        `${item?.type?.toLowerCase()}-${generateSequence(item?.id) || 0
                            }`.includes(searchValue?.toLowerCase()) ||
                        checkSourceType(searchValue, item) ||
                        item?.raised_by?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.accountable_manager?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.verifier?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.grade?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item?.non_conformance_notes
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        ("proactive"?.includes(searchValue?.toLowerCase()) &&
                            item?.isAutomatic) ||
                        ("reactive"?.includes(searchValue?.toLowerCase()) &&
                            !item?.isAutomatic)
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const checkSourceType = (search, item) => {
        let tempBool = false;
        if (item?.source?.label === "System audit") {
            if (item?.topic?.label?.toLowerCase().includes(search?.toLowerCase())) {
                tempBool = true;
            }
        } else if (
            item?.source?.label === "GMP inspection" &&
            item?.isAutomatic &&
            item?.gmpId
        ) {
            if (
                item?.gmpId?.parentGmp?.gmpName
                    ?.toLowerCase()
                    .includes(search?.toLowerCase())
            ) {
                tempBool = true;
            }
        } else if (item?.source?.label === "GMP inspection" && !item?.gmpId) {
            if (item?.gmp_text?.toLowerCase().includes(search?.toLowerCase())) {
                tempBool = true;
            }
        } else {
            if (item?.source?.label?.toLowerCase().includes(search?.toLowerCase())) {
                tempBool = true;
            }
        }
        return tempBool;
    };

    const dateFilter = (field, value) => {
        let to = null;
        let from = null;
        if (field === "from") {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        } else {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        }
    };

    const dateFilteredData = () => {
        let tempArr = list;
        let date = moment(new Date()).format(APP_CONSTANTS.DATE_FORMAT_1);

        if (filterDate.from && filterDate.from !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(
                        item?.date_raised ? item?.date_raised : item?.createdAt
                    ).format(APP_CONSTANTS.DATE_FORMAT_1) >=
                    moment(filterDate.from).format(APP_CONSTANTS.DATE_FORMAT_1)
                ) {
                    return item;
                }
            });
        }

        if (filterDate.to && filterDate.to !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(
                        item?.date_raised ? item?.date_raised : item?.createdAt
                    ).format(APP_CONSTANTS.DATE_FORMAT_1) <=
                    moment(filterDate.to).format(APP_CONSTANTS.DATE_FORMAT_1)
                ) {
                    return item;
                }
            });
        }

        if (filterStandards !== "none") {
            if (filterStandards === "all") {
                tempArr = tempArr;
            } else {
                tempArr = tempArr?.filter((item) => {
                    return item?.reference?.some(ref => ref?.id === filterStandards);
                    // if (item?.gmpId?.standards?.includes(parseInt(filterStandards))) {
                    //     return item;
                    // } else if (
                    //     item?.saId?.standards?.includes(parseInt(filterStandards))
                    // ) {
                    //     return item;
                    // }
                });
            }
        }

        if (filterStatus !== "none") {
            if (filterStatus === "all") {
                tempArr = tempArr;
            } else {
                tempArr = tempArr?.filter((item) => {
                    if (item?.status === filterStatus) {
                        return item;
                    }
                });
            }
        }

        if (filterDuration !== "none") {
            if (filterDuration === "all") {
                tempArr = tempArr;
            } else if (filterDuration === "current") {
                tempArr = tempArr?.filter((item) => {
                    if (
                        moment(item?.date_raised).format(APP_CONSTANTS.DATE_FORMAT_1) ===
                        date
                    ) {
                        return item;
                    }
                });
            } else if (filterDuration === "past") {
                tempArr = tempArr?.filter((item) => {
                    if (
                        moment(item?.date_raised).format(APP_CONSTANTS.DATE_FORMAT_1) < date
                    ) {
                        return item;
                    }
                });
            }
        }

        setFilteredList(tempArr);
        dispatch(filterNcList(tempArr));
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div style={{ width: "100%" }}>
                <DateField
                    filterData={(e) => dateFilter("from", e.target.value)}
                    raised={true}
                    dateValue={filterDate?.from}
                    label={"From:"}
                />
                <DateField
                    filterData={(e) => dateFilter("to", e.target.value)}
                    dateValue={filterDate?.to}
                    label={"To:"}
                />
                <FilteredInputField
                    list={getStartdardsList()}
                    value={filterStandards}
                    action={setFilterStandards}
                    type="nc"
                />
                <FilteredInputField
                    list={statuses}
                    value={filterStatus}
                    action={setFilterStatus}
                    type="nc"
                />
                <FilteredInputDuration
                    list={FilterDurationArray}
                    value={filterDuration}
                    action={setFilterDuration}
                />
                <Searchbar
                    onFilter={(e) => {
                        filterData(e.target.value);
                        setSearch(e.target.value);
                    }}
                    onClear={handleClear}
                    filterText={filterText}
                    type="nc"
                />
            </div>
        );
    }, [filterText, filteredList, filterDate]);

    useEffect(() => {
        // setFilteredList(list);
        dateFilteredData();
    }, [filterDate, filterStatus, filterDuration, filterStandards]);

    // useEffect(() => {
    //     if (!list.length) dispatch(getNcListAPi());
    // }, []);

    useEffect(() => {
        dispatch(filterNcList(filteredList));
    }, [filteredList]);

    useEffect(() => {
        setFilteredList(list);
    }, [list]);

    const editSelectedObject = (data) => {
        if (data.type === "NC") {
            dispatch(updateNCObject({ ...data }));
            dispatch(changeTabState(2));
        } else {
            dispatch(updateProObject({ ...data }));
            dispatch(changeTabState(3));
        }
    };

    const deleteRecord = async (data) => {
        setData(data);
        toggleDeleteNc(true);
    };

    // useEffect(() => {
    //     if (!list.length) dispatch(getNcListAPi());
    // }, []);

    // useEffect(() => {
    //     dispatch(getNcListAPi());
    // }, []);

    useEffect(() => {
        dispatch(emptyList([]));
        dispatch(getNcListAPi());
    }, [deleteNc]);

    const columns = [
        {
            name: "Reference",
            selector: (row) => `${row?.type}-${generateSequence(row?.id)}`,
            cell: (row) => (
                <a
                    className="color-05 user-name-link cursor"
                    onClick={() => redirectReference(row)}
                >
                    {`${row?.type}-${generateSequence(row?.id)}`}
                </a>
            ),
            sortable: true,
            width: "130px",
        },
        {
            name: "Source Type",
            selector: (row) =>
                row?.source?.label === "System audit"
                    ? row?.topic?.label
                    : row?.source?.label === "GMP inspection" &&
                        row?.isAutomatic &&
                        row?.gmpId
                        ? row?.gmpId?.parentGmp?.gmpName
                        : row?.source?.label === "GMP inspection" && !row?.gmpId
                            ? row?.gmp_text
                            : row?.source?.label,
            sortable: true,
            cell: (row) =>
                row?.source?.label === "System audit"
                    ? row?.topic?.label
                    : row?.source?.label === "GMP inspection" &&
                        row?.isAutomatic &&
                        row?.gmpId
                        ? row?.gmpId?.parentGmp?.gmpName
                        : row?.source?.label === "GMP inspection" && !row?.gmpId
                            ? row?.gmp_text
                            : row?.source?.label,
            width: "250px",
        },
        {
            name: "Raised By",
            selector: (row) => row?.raised_by?.label,
            sortable: true,
            cell: (row) => row?.raised_by?.label,
        },
        {
            name: "Date Raised",
            selector: (row) =>
                row?.date_raised
                    ? moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT)
                    : "",
            sortable: true,
            cell: (row) =>
                row?.date_raised
                    ? moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT)
                    : "",
        },
        {
            name: "Due By",
            selector: (row) =>
                row?.due_date
                    ? moment(row?.due_date).format(APP_CONSTANTS.DATE_FORMAT)
                    : "",
            sortable: true,
            cell: (row) =>
                row?.due_date
                    ? moment(row?.due_date).format(APP_CONSTANTS.DATE_FORMAT)
                    : "",
        },
        {
            name: "Accountable Manager",
            selector: (row) => row?.accountable_manager?.label,
            sortable: true,
            cell: (row) => row?.accountable_manager?.label,
        },

        {
            name: "Non-conformance type",
            selector: (row) => (row?.isAutomatic ? "Proactive" : "Reactive"),
            sortable: true,
            cell: (row) => (row?.isAutomatic ? "Proactive" : "Reactive"),
        },

        {
            name: "Verifier",
            selector: (row) => row?.verifier?.label,
            sortable: true,
            cell: (row) => row?.verifier?.label,
        },
        {
            name: "Grade",
            selector: (row) => row?.grade,
            sortable: true,
            cell: (row) => row?.grade,
        },
        {
            name: "Status",
            selector: (row) => row?.status,
            cell: (row) => (
                <div data-toggle="tooltip" title={row?.status} className="py-1">
                    {row?.status}
                </div>
            ),
            sortable: true,
        },
        {
            name: "Notes",
            selector: (row) => row?.non_conformance_notes || "",
            sortable: true,
            cell: (row) => (
                <div
                    className="py-2"
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {row?.non_conformance_notes || ""}
                </div>
            ),
            maxWidth: "300px",
        },
        {
            name: "Priority",
            selector: (row) => priorityColorSort(row?.status),
            cell: (row, index) => {
                if (
                    row?.status === "Draft" ||
                    row?.status === "Sent" ||
                    row?.status === "Accepted" ||
                    row?.status === "Reassigned" ||
                    row?.status === "Closed late" ||
                    row?.status === "Delegated" ||
                    row?.status === "Completed late and verified late"
                ) {
                    return <span className="dot dot-orange ml-3" />;
                } else if (
                    row?.status === "In progress" ||
                    row?.status === "Verified late"
                ) {
                    return <span className="dot dot-green ml-3" />;
                } else if (row?.status === "Overdue") {
                    return <span className="dot dot-red ml-3" />;
                } else if (row?.status === "Closed") {
                    return <span className="dot dot-blue ml-3" />;
                }
            },
            sortable: true,
        },
        {
            name: "Edit",
            width: "100px",
            cell: (row, index) => {
                return utils.checkSubscription() ? (
                    <div
                        onClick={() => {
                            editSelectedObject(row);
                        }}
                    >
                        {utils.checkPermissions("editNC") && row.status === "Draft" ? (
                            <a className="edit-link">Edit</a>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    ""
                );
            },
        },
        {
            name: "Delete",
            width: "100px",
            cell: (row, index) => {
                return utils.checkSubscription() && row.status === "Draft" ? (
                    <div
                        onClick={() => {
                            deleteRecord(row);
                        }}
                    >
                        <a className="edit-link">Delete</a>
                    </div>
                ) : (
                    ""
                );
            },
        },
    ];

    const priorityColorSort = (status) => {
        if (
            status === "Draft" ||
            status === "Sent" ||
            status === "Accepted" ||
            status === "Reassigned" ||
            status === "Closed late" ||
            status === "Delegated" ||
            status === "Completed late and verified late"
        ) {
            return "Orange";
        } else if (status === "In progress" || status === "Verified late") {
            return "Green";
        } else if (status === "Overdue") {
            return "Red";
        } else if (status === "Closed") {
            return "Blue";
        }
    };

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataNC = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataNC);
    };

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="table-responsive">
            {
                nc_loader &&
                <>
                    <div
                        className="modal_backdrop"
                    >
                    </div>

                    <div
                        className="spinner-border spinner-border-md"
                        role="status"
                        style={{
                            marginBottom: "2px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            zIndex: 2
                        }}
                    ></div>
                </>
            }
            <DataTable
                columns={columns}
                data={filteredList}
                selectableRows
                customStyles={customStyles}
                striped
                onSelectedRowsChange={handleRowSelection}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />

            {deleteNc && (
                <DeleteNcModal id="nc" data={data} toggleDeleteNc={toggleDeleteNc} />
            )}
            {permission && (
                <>
                    <div className="modal_backdrop"></div>
                    <EditNcPermission closeModal={setPermission} />
                </>
            )}
        </div>
    );
};

export default NCs;
