export const msalConfig = {
  auth: {
    clientId: "f4056fde-a940-4dae-aacc-ae11fab5b482",
    authority: `https://login.microsoftonline.com/organizations`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_BASE_URL
      ? process.env.REACT_APP_BASE_URL
      : "https://auditappstaging.techni-k.co.uk/",
  },
  cache: {
    //  , // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};
