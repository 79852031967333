import Logo from '../../Assets/images/Logo.png';
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import BackIcon from "../../Assets/images/back-arrow.png";
import React from "react";

const headStyle = {
    boxShadow: "0px 3px 22px #00000029",
    borderBottom: "1px solid #707070"
}

const HeaderSec = () => {
    const navigate = useNavigate();
    let location = useLocation();
    return (
        <div className="header" style={headStyle}>
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="d-flex flex-grow-1">
                        <span className="w-100 d-lg-none d-block"/>
                        <Link className="navbar-brand d-none d-lg-inline-block" to={'#'}>
                            <img src={Logo} alt="logo"/>
                        </Link>
                        <Link className="navbar-brand-two mx-auto d-lg-none d-inline-block" to={'#'}>
                            <img src={Logo} alt="logo"/>
                        </Link>
                        <div className="w-100 text-right">
                            <button className="navbar-toggler custom-toggler" type="button" data-toggle="collapse"
                                    data-target="#myNavbar">
                                <span className="navbar-toggler-icon"/>
                            </button>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar">
                        <ul className="navbar-nav ml-auto flex-nowrap">
                            <li className="nav-item">
                                {location?.pathname !== "stripe/thank-you" && <span  className="back-to-pre fs-14 fw-300 py-4 color-04 float-right cursor"  onClick={()=>navigate(-1)}><u>Back to previous page</u> <img src={BackIcon} width="14px" alt="Back to Pre"/></span>}
                                {location?.pathname === "stripe/thank-you" && <span  className="back-to-pre fs-14 fw-300 py-4 color-04 float-right cursor"  onClick={()=>navigate('/')}><u>Got to login</u> <img src={BackIcon} width="14px"
                                                                                                                                                                                                                       alt="Back to Pre"/></span>}
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default HeaderSec;