import session from "../../services/session";
import { APP_CONSTANTS } from "../../constants";
import moment from "moment";

export const USER_ACTION_CONSTANTS = {
    SIGN_IN: "SIGN_IN",
    SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
    SIGN_IN_ERROR: "SIGN_IN_ERROR",
    SIGN_OUT: "SIGN_OUT",
    SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
    SIGN_OUT_ERROR: "SIGN_OUT_ERROR",
    CREATE_USER: "CREATE_USER",
    EDIT_PROFILE: "EDIT_PROFILE",
    EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
    EDIT_PROFILE_ERROR: "EDIT_PROFILE_ERROR",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_ERROR: "CREATE_USER_ERROR",
    UPDATE_USER: "UPDATE_USER",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_ERROR: "UPDATE_USER_ERROR",
    EDIT_USER_OBJECT: "EDIT_USER_OBJECT",
    UPDATE_CREATE_USER_OBJECT: "UPDATE_CREATE_USER_OBJECT",
    GET_USER_BY_ID: "GET_USER_BY_ID",
    GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID",
    GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
    GET_ALL_USERS_FOR_DROPDOWN: "GET_ALL_USERS_FOR_DROPDOWN",
    GET_ALL_USERS_ERROR: "GET_ALL_USERS_ERROR",
    GET_USERS_BY_ROLE_SUCCESS: "GET_USERS_BY_ROLE_SUCCESS",
    GET_USERS_BY_ROLE_ERROR: "GET_USERS_BY_ROLE_ERROR",
    GET_USER_BY_ID_ERROR: "GET_USER_BY_ID",
    TOGGLE_USER_SUCCESS_MODAL: "TOGGLE_USER_SUCCESS_MODAL",
    TOGGLE_USER_ERROR_MODAL: "TOGGLE_USER_ERROR_MODAL",

    TOGGLE_RESEND_PASSWORD_SUCCESS_MODAL: "TOGGLE_RESEND_PASSWORD_SUCCESS_MODAL",
    TOGGLE_RESEND_PASSWORD_ERROR_MODAL: "TOGGLE_RESEND_PASSWORD_ERROR_MODAL",
    USER_UPGRADE: "USER_UPGRADE",
    SET_ORG_DATA: "SET_ORG_DATA",
    CREATE_OBJECT: () => {
        return {
            org: session.getOrgId(),
            userType: "regular",
            loginType: "normal",
            permissions: {
                default_user_roles: {
                    proactive_action: true,
                    corrective_action: true,
                },
                general: {
                    system_manager: false,
                    accounts: false,
                },
                actions: {
                    root_cause_analysis: false,
                    verifier: false,
                    identifier: false,
                },
                system_audits: {
                    account_manager: false,
                    auditor: false,
                },
                gmp_inspections: {
                    accountable_manager: false,
                    inspector: false,
                },
            },
        };
    },
    CREATE_MULTISITE_USER_OBJECT: (sites) => {
        return {
            multisite: session.getMultisiteId(),
            userType: "regular",
            loginType: "multi",
            multisite_permissions: {
                default_user_roles: {
                    proactive_action: true,
                    corrective_action: true,
                },
                general: {
                    system_manager: {
                        sites: JSON.parse(JSON.stringify(sites)),
                    },
                    accounts: {
                        sites: JSON.parse(JSON.stringify(sites)),
                    },
                },
                actions: {
                    root_cause_analysis: {
                        sites: JSON.parse(JSON.stringify(sites)),
                    },
                    verifier: {
                        sites: JSON.parse(JSON.stringify(sites)),
                    },
                    identifier: {
                        sites: JSON.parse(JSON.stringify(sites)),
                    },
                },
                system_audits: {
                    account_manager: {
                        sites: JSON.parse(JSON.stringify(sites)),
                    },
                    auditor: {
                        sites: JSON.parse(JSON.stringify(sites)),
                    },
                },
                gmp_inspections: {
                    accountable_manager: {
                        sites: JSON.parse(JSON.stringify(sites)),
                    },
                    inspector: {
                        sites: JSON.parse(JSON.stringify(sites)),
                    },
                },
            },
        };
    },
};

export const NC_CONSTANTS = {
    CREATE_NC: "CREATE_NC",
    CREATE_NC_SUCCESS: "CREATE_NC_SUCCESS",
    CREATE_NC_ERROR: "CREATE_NC_ERROR",
    UPDATE_NC_SUCCESS: "UPDATE_NC_SUCCESS",
    UPDATE_NC_ERROR: "UPDATE_NC_ERROR",
    UPDATE_NC_OBJECT: "UPDATE_NC_OBJECT",
    GET_NC_BY_ID: "GET_NC_BY_ID",
    GET_NC_LIST: "GET_NC_LIST",
    GET_NC_LIST_SUCCESS: "GET_NC_LIST_SUCCESS",
    ADD_ATTACHMENT: "NC_ADD_ATTACHMENT",
    TOGGLE_SUCCESS_MODAL: "TOGGLE_SUCCESS_MODAL",
    TOGGLE_NC_SUCCESS_MODAL: "TOGGLE_NC_SUCCESS_MODAL",
    TOGGLE_NC_ERROR_MODAL: "TOGGLE_NC_ERROR_MODAL",
    NC_RESPONSE_ERROR: "NC_RESPONSE_ERROR",
    TOGGLE_NC_SEND_MODAL: "TOGGLE_NC_SEND_MODAL",
    CLEAR_NC_OBJECT: "CLEAR_NC_OBJECT",
    FILTER_NC_LIST: "FILTER_NC_LIST",
    FILTERED_START_DATE: "FILTERED_START_DATE",
    FILTERED_END_DATE: "FILTERED_END_DATE",
    TOGGLE_NC_MEDIA_LOADER: "TOGGLE_NC_MEDIA_LOADER",
    NC_LOADER: "NC_LOADER",
    EMPTY_LIST: "EMPTY_LIST",
    GET_DEFAULT_NC_OBJ: () => {
        return {
            org: session.getOrgId(),
            date_raised: moment().format(APP_CONSTANTS.DATE_FORMAT_1),
        };
    },
};

export const NAVIGATE = {
    NAV_HOME: "NAV_HOME",
};

export const PRO_CONSTANTS = {
    CREATE_PRO: "CREATE_PRO",
    CREATE_PRO_SUCCESS: "CREATE_PRO_SUCCESS",
    CREATE_PRO_ERROR: "CREATE_PRO_ERROR",
    PRO_RESPONSE_ERROR: "PRO_RESPONSE_ERROR",
    UPDATE_PRO_SUCCESS: "UPDATE_PRO_SUCCESS",
    COMPLETE_PRO_SUCCESS: "COMPLETE_PRO_SUCCESS",
    UPDATE_PRO_ERROR: "UPDATE_PRO_ERROR",
    UPDATE_PRO_OBJECT: "UPDATE_PRO_OBJECT",
    UPDATE_COMPLETE_PRO_OBJECT: "UPDATE_COMPLETE_PRO_OBJECT",
    ADD_ATTACHMENT: "PRO_ADD_ATTACHMENT",
    ADD_COMPLETE_ATTACHMENT: "ADD_COMPLETE_ATTACHMENT",
    TOGGLE_PRO_SUCCESS_MODAL: "TOGGLE_PRO_SUCCESS_MODAL",
    TOGGLE_PRO_ERROR_MODAL: "TOGGLE_PRO_ERROR_MODAL",
    TOGGLE_PRO_SEND_MODAL: "TOGGLE_PRO_SEND_MODAL",
    CLEAR_PRO_OBJECT: "CLEAR_PRO_OBJECT",
    GET_DEFAULT_PRO_OBJ: () => {
        return {
            org: session.getOrgId(),
            date_raised: moment().format(APP_CONSTANTS.DATE_FORMAT_1),
        };
    },
};

export const TODO_CONSTANTS = {
    GET_TODO: "GET_TODO",
    GET_TODO_SUCCESS: "GET_TODO_SUCCESS",
    GET_TODO_ERROR: "GET_TODO_ERROR",
    FILTER_TODO_LIST: "FILTER_TODO_LIST",
};

export const TOPIC_CONSTANTS = {
    GET_TOPICS_LIST: "GET_TOPICS_LIST",
    GET_TOPICS_LIST_SUCCESS: "GET_TOPICS_LIST_SUCCESS",
    GET_TOPICS_LIST_ERROR: "GET_TOPICS_LIST_ERROR",
    GET_TOPICS_DROPDOWN: "GET_TOPICS_DROPDOWN",
    GET_TOPICS_DROPDOWN_SUCCESS: "GET_TOPICS_DROPDOWN_SUCCESS",
    GET_TOPICS_DROPDOWN_ERROR: "GET_TOPICS_DROPDOWN_ERROR",
    UPDATE_TOPIC_SUCCESS: "UPDATE_TOPIC_SUCCESS",
    UPDATE_TOPIC_ERROR: "UPDATE_TOPIC_ERROR",
    TOGGLE_TOPIC_SUCCESS_MESSAGE: "TOGGLE_TOPIC_SUCCESS_MESSAGE",
    TOGGLE_TOPIC_ERROR_MESSAGE: "TOGGLE_TOPIC_ERROR_MESSAGE",
    UPDATE_TOPIC_OBJECT: "UPDATE_TOPIC_OBJECT",
};

export const TAB_STATE = {
    CHANGE_TAB: "CHANGE_TAB",
    SETTINGS_CHANGE_TAB: "SETTINGS_CHANGE_TAB",
};

export const ACTION_CONSTANTS = {
    GET_ACTIONS_LIST_SUCCESS: "GET_ACTIONS_LIST_SUCCESS",
    ACTION_RESPONSE_ERROR: "ACTION_RESPONSE_ERROR",
    CREATE_CA_ACTION: "CREATE_CA_ACTION",
    UPDATE_CA_ACTION: "UPDATE_CA_ACTION",
    DELETE_CA_ACTION: "DELETE_CA_ACTION",
    UPDATE_PA_ACTION: "UPDATE_PA_ACTION",
    ADD_ACTIONER_EVIDENCE: "ADD_ACTIONER_EVIDENCE",
    ADD_VERIFIER_EVIDENCE: "ADD_VERIFIER_EVIDENCE",
    SELECTED_ACTION: "SELECTED_ACTION",
    CREATE_CA_ACTION_SUCCESS: "CREATE_CA_ACTION_SUCCESS",
    CREATE_PA_ACTION_SUCCESS: "CREATE_PA_ACTION_SUCCESS",
    TOGGLE_ACTION_SUCCESS_MODAL: "TOGGLE_ACTION_SUCCESS_MODAL",
    TOGGLE_ACTION_EMAIL_SUCCESS_MODAL: "TOGGLE_ACTION_EMAIL_SUCCESS_MODAL",
    TOGGLE_ACTION_ERROR_MODAL: "TOGGLE_ACTION_ERROR_MODAL",
    UPDATE_NC_PA_ACTION: "UPDATE_NC_PA_ACTION",
    UPDATE_NC_CA_ACTION: "UPDATE_NC_CA_ACTION",
    FILTERED_ACTION_LIST: "FILTERED_ACTION_LIST",
    GET_DEFAULT_CA_ACTION_OBJ: (nc = null, org = session.getOrgId()) => {
        return {
            type: "CA",
            status: "Draft",
            nc: nc,
            org: org,
        };
    },
    GET_DEFAULT_PA_ACTION_OBJ: (nc = null, org = session.getOrgId()) => {
        return {
            type: "PA",
            status: "Draft",
            nc: nc,
            org: org,
        };
    },
};

export const R_C_A = {
    CREATE_RCA: "CREATE_RCA",
    UPDATE_RCA: "UPDATE_RCA",
    CREATE_RCA_SUCCESS: "CREATE_RCA_SUCCESS",
    TOGGLE_RCA_SUCCESS_MODAL: "TOGGLE_RCA_SUCCESS_MODAL",
    TOGGLE_RCA_ERROR_MODAL: "TOGGLE_RCA_ERROR_MODAL",
    ADD_RCA_ATTACHMENT: "ADD_RCA_ATTACHMENT",
    GET_RCA_DEFAULT_OBJ: (days = 14) => {
        return {
            due_date: moment().add(days, "day").format("YYYY-MM-DD"),
            status: "",
            org: session.getOrgId(),
        };
    },
};

export const STRIPE_CONST = {
    SET_SUBS_PAN_OBJECT: "SET_SUBS_PAN_OBJECT",
};

export const GMP_TEMPLATE = {
    GET_GMP_TEMPLATE: "GET_GMP_TEMPLATE",
    GET_GMP_TEMPLATE_SUCCESS: "GET_GMP_TEMPLATE_SUCCESS",
    GET_GMP_TEMPLATE_ERROR: "GET_GMP_TEMPLATE_ERROR",
};

export const GMP_CONSTANTS = {
    CREATE_GMP: "CREATE_GMP",
    CREATE_GMP_SUCCESS: "CREATE_GMP_SUCCESS",
    CREATE_GMP_ERROR: "CREATE_GMP_ERROR",
    UPDATE_GMP_SUCCESS: "UPDATE_GMP_SUCCESS",
    UPDATE_GMP_ERROR: "UPDATE_GMP_ERROR",
    ADD_GMP_ROLE_SUCCESS: "ADD_GMP_ROLL_SUCCESS",
    ADD_GMP_ROLE_ERROR: "ADD_GMP_ROLL_ERROR",
    UPDATE_GMP_OBJECT: "UPDATE_GMP_OBJECT",
    GET_GMP_LIST: "GET_GMP_LIST",
    GET_GMP_LIST_SUCCESS: "GET_GMP_LIST_SUCCESS",
    GET_GMP_USER_LIST_SUCCESS: "GET_GMP_USER_LIST_SUCCESS",
    TOGGLE_GMP_SUCCESS_MODAL: "TOGGLE_GMP_SUCCESS_MODAL",
    TOGGLE_GMP_ERROR_MODAL: "TOGGLE_GMP_ERROR_MODAL",
    GMP_RESPONSE_ERROR: "GMP_RESPONSE_ERROR",
    CLEAR_GMP_OBJECT: "CLEAR_GMP_OBJECT",
    GMP_QUESTIONS_SUCCESS: "GMP_QUESTIONS_SUCCESS",
    UPDATE_GMP_ROLE_OBJECT: "UPDATE_GMP_ROLE_OBJECT",
    UPDATE_QUESTIONS: "UPDATE_QUESTIONS",
    PUSH_GMP_IN_LIST: "PUSH_GMP_IN_LIST",
    FILTER_GMP_LIST: "FILTER_GMP_LIST",
    FILTERED_START_DATE: "FILTERED_START_DATE",
    FILTERED_END_DATE: "FILTERED_END_DATE",
    EMPTY_FILTER_GMP_LIST: "EMPTY_FILTER_GMP_LIST",
    GMP_QUESTION_CURRENT_INDEX: "GMP_QUESTION_CURRENT_INDEX",
    GET_DEFAULT_GMP_OBJ: () => {
        return {
            isNotification: false,
            isPause: false,
        };
    },
    // GET_DEFAULT_GMP_ROLE_OBJ: () => {
    //     return {
    //         org: session.getOrgId(),
    //     }
    // }
};

export const REMINDER_CONSTANTS = {
    GET_REMINDER_LIST: "GET_REMINDER_LIST",
    GET_REMINDER_LIST_SUCCESS: "GET_REMINDER_LIST_SUCCESS",
    GET_REMINDER_LIST_ERROR: "GET_REMINDER_LIST_ERROR",
};

export const STANDARDS_CONSTANTS = {
    GET_STANDARDS_LIST: "GET_REMINDER_LIST",
    GET_STANDARDS_LIST_SUCCESS: "GET_REMINDER_LIST_SUCCESS",
    GET_STANDARDS_LIST_ERROR: "GET_REMINDER_LIST_ERROR",
    GET_LOG_STANDARDS_LIST_SUCCESS: "GET_LOG_STANDARDS_LIST_SUCCESS",
};

export const AUDIT_CONSTANTS = {
    CREATE_AUDIT_SUCCESS: "CREATE_AUDIT_SUCCESS",
    TOGGLE_AUDIT_SUCCESS_MODAL: "TOGGLE_AUDIT_SUCCESS_MODAL",
    TOGGLE_AUDIT_ERROR_MODAL: "TOGGLE_AUDIT_ERROR_MODAL",
    AUDIT_RESPONSE_ERROR: "AUDIT_RESPONSE_ERROR",
    GENERATE_AUDIT_SUCCESS: "GENERATE_AUDIT_SUCCESS",
    GET_AUDIT_USER_LIST_SUCCESS: "GET_AUDIT_USER_LIST_SUCCESS",
    PUSH_SA_IN_LIST: "PUSH_SA_IN_LIST",
    UPDATE_SA_QUESTIONS: "UPDATE_SA_QUESTIONS",
    SA_QUESTIONS_SUCCESS: "SA_QUESTIONS_SUCCESS",
    FILTER_SA_LIST: "FILTER_SA_LIST",
    FILTERED_START_DATE: "FILTERED_START_DATE",
    FILTERED_END_DATE: "FILTERED_END_DATE",
    EMPTY_FILTER_SA_LIST: "EMPTY_FILTER_SA_LIST",
    SA_QUESTION_CURRENT_INDEX: "SA_QUESTION_CURRENT_INDEX",
    UPDATE_SA_OBJECT: "UPDATE_SA_OBJECT",
    GET_DEFAULT_AUDIT_OBJ: () => {
        return {
            pauseAudit: false,
        };
    },
};

export const RISK_ASSESMENT_CONSTANT = {
    GET_RISK_ASSESMENT_SUCCESS: "GET_RISK_ASSESMENT_SUCCESS",
    FILTER_RISK_LIST: "FILTER_RISK_LIST",
};

export const SUBSCRIPTIONS_CONSTANT = {
    GET_SUBSCRIPTIONS: "GET_SUBSCRIPTIONS",
    GET_SUBSCRIPTIONS_SUCCESS: "GET_SUBSCRIPTIONS_SUCCESS",
    GET_SUBSCRIPTIONS_ERROR: "GET_SUBSCRIPTIONS_ERROR",
    FILTER_SUBSCRIPTIONS_LIST: "FILTER_SUBSCRIPTIONS_LIST",
};

export const SITES_CONSTANT = {
    GET_SITES_LIST_SUCCESS: "GET_SITES_LIST_SUCCESS",
    TOGGLE_MODAL: "TOGGLE_MODAL",
    SUCCESS_MODAL: "SUCCESS_MODAL",
    TOGGLE_ERROR_MODAL: "TOGGLE_ERROR_MODAL",
    GET_MULTI_SITE_SUCCESS: "GET_MULTI_SITE_SUCCESS",
    EDIT_MULTI_SITE: "EDIT_MULTI_SITE",
    GET_SITES_SUCCESS: "GET_SITES_SUCCESS",
    FILTER_MULTISITE_LIST: "FILTER_MULTISITE_LIST",
};

export const KPI_CONST = {
    CURRENT_KPI_LIST: "CURRENT_KPI_LIST",
    CURRENT_CHART_TYPE: "CURRENT_CHART_TYPE",
};

export const SCHEDULED_REPORTS_CONSTANTS = {
    GET_SCHEDULED_REPORTS_SUCCESS: "GET_SCHEDULED_REPORTS_SUCCESS",
    EDIT_SCHEDULED_REPORTS_OBJECT: "EDIT_SCHEDULED_REPORTS_OBJECT",
};
