import React from "react";
import errorIcon from "../../Assets/images/error 1x.png";

const RemoveAttachmentModal = (props) => {
    const { id, action, params, onCancel } = props;

    const clearItem = () => {
        action(params?.obj, params?.index);
    };

    return (
        <div className="save-modal custom_create_gmp">
            <div
                className="modal fade show"
                id={`${"clearModal"}-${id}`}
                tabIndex="-1"
                aria-labelledby="clearModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-4 text-center mb-4">
                                    <img
                                        src={errorIcon}
                                        alt="Save Notification Pro"
                                        width={"200px"}
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-center mb-4">
                                        {id === "removeAttachment"
                                            ? "Are you sure you want to remove the attachment?"
                                            : "Are you sure you want to remove the evidence?"}
                                    </h2>
                                    <div className="float-lg-right text-center mt-4">
                                        <button
                                            className="clear_item mr-2 py-2 px-4 fs-16"
                                            type="button"
                                            onClick={() => onCancel()}
                                        >
                                            No, Cancel
                                        </button>
                                        <button
                                            className="btn_clear_inspection py-2 px-4 fs-16"
                                            type="button"
                                            onClick={clearItem}
                                        >
                                            Yes, Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default RemoveAttachmentModal;
