import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Routes } from "react-router";
import ResetPassword from "../components/Authentication/ResetPassword";
import Login from "../components/Authentication/Login";
import Stripe from "../components/Stripe/Stripe";

const PublicRoute = () => {
    const { object } = useSelector((state) => state.user);
    const user = JSON.parse(localStorage.getItem("user"));

    return user?._id ? (
        user?.userType === "superAdmin" ? (
            <Navigate to="/private/super-admin" />
        ) : (
            <Navigate to="/private/home" />
        )
    ) : (
        <div>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/stripe/*" element={<Stripe />} />
            </Routes>
        </div>
    );
};

export default PublicRoute;
