import React from 'react';

const CompQ = () => {
    return (
        <>
            <h2 id="q">Q</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading268">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse268" aria-expanded="true"
                                    aria-controls="collapse268">
                                Quality
                            </button>
                        </h2>
                    </div>
                    <div id="collapse268" className="collapse show" aria-labelledby="heading268"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Product that is to the agreed quality. Quality doesn't have to be the best quality, to produce poor quality product is acceptable, as long as the quality is agreed with the customer. There are legal requirements for product, there are safety requirements for product and the rest of the requirements are quality ones. Product quality are aspects such as appearance, shape, size, weight, flavour. Service quality are aspects such as on time or order fulfilment.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading269">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse269" aria-expanded="false"
                                    aria-controls="collapse269">
                                Quality policy
                            </button>
                        </h2>
                    </div>
                    <div id="collapse269" className="collapse" aria-labelledby="heading269"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A documented commitment by the company, to meet defined intentions relating to product quality, safety, legality and authenticity.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompQ;