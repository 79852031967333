import { RISK_ASSESMENT_CONSTANT } from "../constants";

const reducer = (
    state = {
        userRiskAssesment: [],
        filteredRiskList: [],
    },
    { type, payload }
) => {
    switch (type) {
        case RISK_ASSESMENT_CONSTANT.GET_RISK_ASSESMENT_SUCCESS:
            return {
                ...state,
                userRiskAssesment: payload,
            };
        case RISK_ASSESMENT_CONSTANT.FILTER_RISK_LIST:
            return { ...state, filteredRiskList: payload };
        default:
            return state;
    }
};

export default reducer;
