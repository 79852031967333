import ThumbsUp from "../../Assets/images/thumbs-up.png";
import React from "react";

const SaveModalTwo = (props) => {
    const { id, h2, h3, p, type } = props;
    return (
        <div className="save-modal custom_modal_backdrop">
            <div
                className="modal fade show"
                id={`${"SaveModal"}-${id}`}
                tabIndex="-1"
                aria-labelledby="SaveModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-lg-4 pl-0">
                                    <img
                                        src={ThumbsUp}
                                        alt="Save Notification Pro"
                                        width={"200px"}
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center text-lg-center mb-4">
                                        {h2}
                                    </h2>
                                    <h3 className="color-05 fs-18 fw-600 mb-4">{h3}</h3>
                                    <p className="color-05 fs-12 fw-300">
                                        {type === "billing" ? (
                                            "Sit back, relax and we'll automatically close this message in 5 seconds."
                                        ) : (
                                            <>{p}</>
                                        )}{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SaveModalTwo;
