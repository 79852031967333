import React, { useEffect, useState } from "react";
import Searchbar from "../../Home/TodoLIst/Searchbar";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Modals/ErrorModal";
import { getGMPListAPi, updateGMPObject } from "../../../store/actions/gmp";
import GmpRolesModal from "./GmpRolesModal";
import utils from "../../../utils";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "25px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const GmpRoles = () => {
    const dispatch = useDispatch();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [toggleModal, setToggleModal] = useState(false);

    const { roleSuccess, error, list } = useSelector((state) => state?.gmp);

    const toggleGmpRoleModal = (flag) => {
        setToggleModal(flag);
    };

    const columns = [
        {
            name: "GMP inspection",
            selector: (row) => row?.gmpName,
            width: "250px",
            cell: (row) => row?.gmpName,
            sortable: true,
        },
        {
            name: "Inspectors",
            selector: (row) => (
                <div>
                    {row?.inspectors.map((data, ind) =>
                        ind !== row?.inspectors?.length - 1 ? (
                            <div key={ind} style={{ paddingBottom: "5px" }}>
                                {data?.label}
                            </div>
                        ) : (
                            <div key={ind}>{data?.label}</div>
                        )
                    )}
                </div>
            ),
            cell: (row) => (
                <div>
                    {row?.inspectors.map((data, ind) =>
                        ind !== row?.inspectors?.length - 1 ? (
                            <div key={ind} style={{ paddingBottom: "5px" }}>
                                {data?.label}
                            </div>
                        ) : (
                            <div key={ind}>{data?.label}</div>
                        )
                    )}
                </div>
            ),
        },
        {
            name: "Excluded inspectors",
            selector: (row) => (
                <div>
                    {row?.excluded_inspectors.map((data, ind) =>
                        ind !== row?.excluded_inspectors?.length - 1 ? (
                            <div key={ind} style={{ paddingBottom: "5px" }}>
                                {data?.label}
                            </div>
                        ) : (
                            <div key={ind}>{data?.label}</div>
                        )
                    )}
                </div>
            ),
            cell: (row) => (
                <div>
                    {row?.excluded_inspectors.map((data, ind) =>
                        ind !== row?.excluded_inspectors?.length - 1 ? (
                            <div key={ind} style={{ paddingBottom: "5px" }}>
                                {data?.label}
                            </div>
                        ) : (
                            <div key={ind}>{data?.label}</div>
                        )
                    )}
                </div>
            ),
        },
        {
            name: "Accountable manager",
            selector: (row) =>
                row?.accountable_manager?.label ? row?.accountable_manager?.label : "",
            sortable: true,
            cell: (row) =>
                row?.accountable_manager?.label ? row?.accountable_manager?.label : "",
        },
        {
            name: "Verifier",
            selector: (row) => (row?.verifier?.label ? row?.verifier?.label : ""),
            sortable: true,
            cell: (row) => (row?.verifier?.label ? row?.verifier?.label : ""),
        },
        {
            // name: "Edit",
            width: "100px",
            cell: (row) =>
                utils.checkSubStatus() && (
                    <a
                        className="color-05 user-name-link cursor"
                        onClick={() => editGmpRole(row)}
                    >
                        Edit
                    </a>
                ),
        },
    ];

    const editGmpRole = (data) => {
        toggleGmpRoleModal(true);
        dispatch(updateGMPObject(data));
    };

    const handleChange = ({ selectedRows }) => {
        // console.log("Selected Rows: ", selectedRows);
    };

    const checkInspectors = (search, data) => {
        let tempBool = false;
        data?.forEach((item) => {
            if (item?.label?.toLowerCase().includes(search?.toLowerCase())) {
                tempBool = true;
            }
        });
        return tempBool;
    };

    const checkExcludedInspectors = (search, data) => {
        let tempBool = false;
        data?.forEach((item) => {
            if (item?.label?.toLowerCase().includes(search?.toLowerCase())) {
                tempBool = true;
            }
        });
        return tempBool;
    };

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                list?.filter((item) => {
                    if (
                        item?.gmpName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        checkInspectors(searchValue, item?.inspectors) ||
                        checkExcludedInspectors(searchValue, item?.excluded_inspectors) ||
                        item?.accountable_manager?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.verifier?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase())
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div>
                <Searchbar
                    onFilter={(e) => filterData(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                    type="gmp_roles"
                />
            </div>
        );
    }, [filterText, filteredList]);

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataGmpRoles = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataGmpRoles);
    };

    useEffect(() => {
        dispatch(getGMPListAPi());
    }, [toggleModal]);

    useEffect(() => {
        dispatch(getGMPListAPi());
    }, [roleSuccess]);

    useEffect(() => {
        setFilteredList([...list]);
    }, [list]);

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="gmp_inspections_wrapper">
            <DataTable
                columns={columns}
                data={filteredList?.length > 0 ? filteredList : []}
                customStyles={customStyles}
                onSelectedRowsChange={handleChange}
                striped
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                selectableRows
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />
            {toggleModal && <GmpRolesModal toggleGmpRoleModal={toggleGmpRoleModal} />}
            {error?.show && (
                <div>
                    <div className="modal_backdrop"></div>
                    <ErrorModal error={error} />
                </div>
            )}
        </div>
    );
};

export default GmpRoles;
