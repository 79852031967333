import React from "react";

const CompletionSA = (props) => {
    const { data } = props;

    return (
        <div className="completion-sa-table px-2 pt-4">
            <div className="container">
                <table>
                    <tr>
                        <th className="fixed-td fixed-hd left-top-td"></th>
                        {data?.dates?.map((item, index) => {
                            return (
                                <th
                                    key={index}
                                    className="fs-14 color-05 fw-500 align-middle text-center scrollable-td fixed-hd"
                                >
                                    {item}
                                </th>
                            );
                        })}
                    </tr>
                    {data?.topics?.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className={
                                    index + 1 >= 2
                                        ? (index + 1) % 2 === 0
                                            ? "bg-striped"
                                            : ""
                                        : ""
                                }
                            >
                                <td
                                    className={`fixed-td pl-2 ${index + 1 >= 2
                                            ? (index + 1) % 2 === 0
                                                ? "first-col fs-14 color-05 fw-600 bg-striped"
                                                : "first-col fs-14 color-05 fw-600"
                                            : "first-col fs-14 color-05 fw-600"
                                        }`}
                                >
                                    {item?.name}
                                </td>
                                {item?.statuses?.map((status, index) => {
                                    return (
                                        <td
                                            key={index}
                                            className={
                                                status === "Draft" ||
                                                    status === "Due" ||
                                                    status === "Completed late"
                                                    ? "fs-14 text-center dot-orange text-white align-middle"
                                                    : status === "In progress" || status === "Completed"
                                                        ? "fs-14 text-center dot-green text-white align-middle"
                                                        : status === "Overdue" ||
                                                            status === "Incomplete late" ||
                                                            status === "Missed"
                                                            ? "fs-14 text-center dot-red text-white align-middle"
                                                            : "fs-14 text-center align-middle"
                                            }
                                        >
                                            {status === "Overdue" &&
                                                item?.statuses[index + 1] === "Due"
                                                ? "Missed"
                                                : status}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </table>
            </div>

            {/* // <div className="mt-3 completion-sa-table">
        //     <div className="table-wrapper">
        //         <table className="table table-borderless">
        //             <tbody>
        //                 <tr>
        //                     <th className="first-col invisible">#</th>
        //                     {data?.dates?.map((item, index) => {
        //                         return (
        //                             <td
        //                                 key={index}
        //                                 className="fs-14 color-05 fw-500 align-middle text-center"
        //                             >
        //                                 {item}
        //                             </td>
        //                         );
        //                     })}
        //                 </tr>
        //                 {data?.topics?.map((item, index) => {
        //                     return (
        //                         <tr
        //                             key={index}
        //                             className={
        //                                 index + 1 >= 2
        //                                     ? (index + 1) % 2 === 0
        //                                         ? "bg-striped"
        //                                         : ""
        //                                     : ""
        //                             }
        //                         >
        //                             <td
        //                                 className={
        //                                     index + 1 >= 2
        //                                         ? (index + 1) % 2 === 0
        //                                             ? "first-col fs-14 color-05 fw-600 bg-striped"
        //                                             : "first-col fs-14 color-05 fw-600"
        //                                         : "first-col fs-14 color-05 fw-600"
        //                                 }
        //                             >
        //                                 {item?.name}
        //                             </td>
        //                             {item?.statuses?.map((status, index) => {
        //                                 return (
        //                                     <td
        //                                         key={index}
        //                                         className={
        //                                             status === "Draft" ||
        //                                                 status === "Due" ||
        //                                                 status === "Completed late"
        //                                                 ? "fs-14 text-center dot-orange text-white align-middle"
        //                                                 : status === "In progress" || status === "Completed"
        //                                                     ? "fs-14 text-center dot-green text-white align-middle"
        //                                                     : status === "Overdue" ||
        //                                                         status === "Incomplete late" ||
        //                                                         status === "Missed"
        //                                                         ? "fs-14 text-center dot-red text-white align-middle"
        //                                                         : "fs-14 text-center align-middle"
        //                                         }
        //                                     >
        //                                         {status}
        //                                     </td>
        //                                 );
        //                             })}
        //                         </tr>
        //                     );
        //                 })}
        //             </tbody>
        //         </table>
        //     </div>
        // </div> */}
        </div>
    );
};

export default CompletionSA;
