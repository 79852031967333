import { PRO_CONSTANTS } from "../constants";
import request from "../../services/request";
import { PRO } from "../../Routes/Api";
import { changeTabState } from "../actions/tabState";
import { APP_CONSTANTS } from "../../constants";

export const createPro = (payload) => {
    return {
        type: PRO_CONSTANTS.CREATE_PRO,
        payload,
    };
};

export const createProSuccess = (payload) => {
    return {
        type: PRO_CONSTANTS.CREATE_PRO_SUCCESS,
        payload,
    };
};

export const updateProSuccess = (payload) => {
    return {
        type: PRO_CONSTANTS.CREATE_PRO_SUCCESS,
        payload,
    };
};

export const completeProSuccess = (payload) => {
    return {
        type: PRO_CONSTANTS.COMPLETE_PRO_SUCCESS,
        payload,
    };
};

export const updateProObject = (payload) => {
    return {
        type: PRO_CONSTANTS.UPDATE_PRO_OBJECT,
        payload,
    };
};

export const updateCompleteProObject = (payload) => {
    return {
        type: PRO_CONSTANTS.UPDATE_COMPLETE_PRO_OBJECT,
        payload,
    };
};

export const addAttachment = (payload) => {
    return {
        type: PRO_CONSTANTS.ADD_ATTACHMENT,
        payload,
    };
};

export const addCompleteAttachment = (payload) => {
    return {
        type: PRO_CONSTANTS.ADD_COMPLETE_ATTACHMENT,
        payload,
    };
};

export const toggleSuccessModal = (payload) => {
    return {
        type: PRO_CONSTANTS.TOGGLE_PRO_SUCCESS_MODAL,
        payload,
    };
};

export const ProResponseError = (payload) => {
    return {
        type: PRO_CONSTANTS.PRO_RESPONSE_ERROR,
        payload,
    };
};

export const toggleErrorModal = (payload) => {
    return {
        type: PRO_CONSTANTS.TOGGLE_PRO_ERROR_MODAL,
        payload,
    };
};

export const toggleSendModal = (payload) => {
    return {
        type: PRO_CONSTANTS.TOGGLE_PRO_SEND_MODAL,
        payload,
    };
};

export const clearProObject = (payload) => {
    return {
        type: PRO_CONSTANTS.CLEAR_PRO_OBJECT,
        payload,
    };
};

export const createProAPi = (data, from) => {
    return (dispatch) => {
        dispatch(createPro());
        request.post(PRO.CREATE_PRO, data, true, true).then(
            (res) => {
                dispatch(createProSuccess(res?.data));
                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    dispatch(changeTabState(1));
                    dispatch(clearProObject({ ...PRO_CONSTANTS.GET_DEFAULT_PRO_OBJ() }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
                /*history.push(from);*/
            },
            (error) => {
                dispatch(ProResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const updateProAPi = (data, from) => {
    return (dispatch) => {
        request.post(PRO.UPDATE_PRO, data, true, true).then(
            (res) => {
                dispatch(updateProSuccess(res?.data));
                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    dispatch(changeTabState(4));
                    dispatch(clearProObject({ ...PRO_CONSTANTS.GET_DEFAULT_PRO_OBJ() }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
                /*history.push(from);*/
            },
            (error) => {
                dispatch(ProResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const completeProAPi = (data, from) => {
    return (dispatch) => {
        request.post(PRO.COMPLETE_PRO, data, true, true).then(
            (res) => {
                dispatch(completeProSuccess(res?.data));
                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    window.location.href = "/";
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(ProResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const sendProApi = (data, from) => {
    return (dispatch) => {
        request.post(PRO.SEND_PRO, data, true).then(
            (res) => {
                dispatch(updateProObject(res?.data));
                if (from === "proSend") {
                    window.location.href = "/private/home";
                } else {
                    dispatch(toggleSendModal({ send: true }));
                    setTimeout(() => {
                        dispatch(toggleSendModal({ send: false }));
                        dispatch(changeTabState(1));
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            },
            (error) => {
                dispatch(toggleSendModal({ error: { sentError: true, ...error } }));
                setTimeout(() => {
                    dispatch(toggleSendModal({ error: { sentError: false } }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const createAndSendApi = (data) => {
    return (dispatch) => {
        request.post(PRO.CREATE_PRO, data, true, true).then(
            (res) => {
                dispatch(updateProObject(res?.data));
                dispatch(sendProApi({ _id: res?.data._id }));
            },
            (error) => {
                dispatch(ProResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const getProByIdAPi = (data, complete = false) => {
    return (dispatch) => {
        request.get(`${PRO.GET_PRO_BY_ID}${data}`, true).then(
            (res) => {
                if (!complete) dispatch(updateProObject(res?.data));
                else dispatch(updateCompleteProObject(res?.data));
            },
            (error) => {
                dispatch(ProResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};
