import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import session from "../../services/session";
import { getSites } from "../../store/actions/sites";
import request from "../../services/request";
import { USER } from "../../Routes/Api";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const Sites = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { multisite_permissions, isAzure, _id } = JSON.parse(localStorage.getItem("user"));
    const { sites } = useSelector((state) => isAzure ? state?.sites : state?.sites?.sites);

    const redirectToOrg = async (data, index) => {
        try {
            let permissions = {
                default_user_roles: {
                    proactive_action: true,
                    corrective_action: true,
                },
                general: {
                    system_manager: false,
                    accounts: false,
                },
                actions: {
                    root_cause_analysis: false,
                    verifier: false,
                    identifier: false,
                },
                system_audits: {
                    account_manager: false,
                    auditor: false,
                },
                gmp_inspections: {
                    accountable_manager: false,
                    inspector: false,
                },
            };
            if (multisite_permissions?.general?.system_manager?.sites[index]?.enable) {
                permissions.general.system_manager = true;
            }
            if (multisite_permissions?.general?.accounts?.sites[index]?.enable) {
                permissions.general.accounts = true;
            }
            if (
                multisite_permissions?.actions?.root_cause_analysis?.sites[index]?.enable
            ) {
                permissions.actions.root_cause_analysis = true;
            }
            if (multisite_permissions?.actions?.verifier?.sites[index]?.enable) {
                permissions.actions.verifier = true;
            }
            if (multisite_permissions?.actions?.identifier?.sites[index]?.enable) {
                permissions.actions.identifier = true;
            }
            if (
                multisite_permissions?.system_audits?.account_manager?.sites[index]?.enable
            ) {
                permissions.system_audits.account_manager = true;
            }
            if (multisite_permissions?.system_audits?.auditor?.sites[index]?.enable) {
                permissions.system_audits.auditor = true;
            }
            if (
                multisite_permissions?.gmp_inspections?.accountable_manager?.sites[index]?.enable
            ) {
                permissions.gmp_inspections.accountable_manager = true;
            }
            if (multisite_permissions?.gmp_inspections?.inspector?.sites[index]?.enable) {
                permissions.gmp_inspections.inspector = true;
            }

            session.setOrgId(data?.org?._id);
            session.setOrgPermissions(permissions);
            session.setOrg(data?.org);
            editUser(permissions);
        } catch (e) { }
    };

    const editUser = (permissions) => {
        request
            .put(
                USER.UPDATE_USER,
                { permissions: permissions, _id: _id, type: "multi" },
                true
            )
            .then(
                (res) => {
                    navigate("/private/home");
                },
                (error) => { }
            );
    };

    const columns = [
        {
            name: "Sites",
            selector: (row) => row?.label,
            sortable: true,
            cell: (row, index) => (
                <a
                    className="color-05 user-name-link cursor"
                    onClick={() => redirectToOrg(row, index)}
                >
                    {row.label}
                </a>
            ),
        },
    ];

    useEffect(() => {
        session.removeOrgId();
        session.removeOrg();
        dispatch(getSites());
    }, []);

    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-industry-alt" /> Sites
                        </h2>
                    </div>
                </div>
                <div className="sites-multisite">
                    <DataTable
                        columns={columns}
                        data={sites}
                        customStyles={customStyles}
                        striped
                        persistTableHead
                        pagination
                        fixedHeaderScrollHeight="calc(100vh - 400px)"
                        fixedHeader={true}
                    />
                </div>
            </div>
        </section>
    );
};

export default Sites;
