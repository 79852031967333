import React, { useState } from "react";
import ThumbsUp from "../../Assets/images/thumbs-up.png";
import request from "../../services/request";
import { AUDIT } from "../../Routes/Api";
import { APP_CONSTANTS } from "../../constants";

const SendSAModal = (props) => {
    const { toggleSendSAModal, id, saId } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        show: false,
        message: "",
    });

    const sendItem = () => {
        setLoading(true);
        request
            .post(`${AUDIT.SEND_SA_TO_LEAD_AUDITORS}/${saId}`, {}, true, false)
            .then(
                (res) => {
                    if (res) {
                        setLoading(false);
                        toggleSendSAModal(false);
                    }
                },
                (err) => {
                    setError({ show: true, message: err?.message });
                    setLoading(false);
                    setTimeout(() => {
                        setError({ show: false, message: "" });
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            );
    };

    return (
        <div className="save-modal send_gmp_to_inspectors">
            <div
                className="modal fade show"
                id={`${id}`}
                tabIndex="-1"
                aria-labelledby={`${id} + Label`}
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-lg-4 pl-0 mb-4">
                                    <img
                                        src={ThumbsUp}
                                        alt="Save Notification Pro"
                                        width={"200px"}
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center text-lg-center mb-5">
                                        Do you really want to send this audit to lead auditor?
                                    </h2>

                                    {error?.show && (
                                        <div
                                            className="alert alert-danger mt-0"
                                            role="alert"
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            {error?.message}
                                        </div>
                                    )}

                                    <div className="float-right mt-4">
                                        <button
                                            className="clear_item mr-2 py-2 px-4 fs-16"
                                            onClick={() => toggleSendSAModal(false)}
                                            disabled={loading}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn_clear_inspection py-2 px-4 fs-16"
                                            onClick={sendItem}
                                            disabled={loading}
                                        >
                                            Send{" "}
                                            {loading && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    style={{ marginBottom: "2px" }}
                                                ></div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default SendSAModal;
