import React, { useEffect, useState } from "react";
import LoginGroup from "../../Assets/images/group-shot.png";
import Select from "react-select";
import { updateTopicObject, updateTopicApi } from "../../store/actions/topic";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { APP_CONSTANTS } from "../../constants";

const AuditModal = (props) => {
    const { toggleEditTopicModal } = props;
    const dispatch = useDispatch();

    const { object, error, success } = useSelector((state) => state?.topic);
    const { auditor, accountable_manager, verifier } = useSelector(
        (state) => state?.user?.usersByRole
    );
    const [disableExcludedInspector, setDisableExcludedInspector] =
        useState(false);
    const [obj, setObj] = useState({ attachment: [{ custom_name: "" }] });
    const [aud, setAud] = useState([]);
    const [exAud, setExAud] = useState([]);
    const [auditErrors, setAuditError] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [validError, setValidError] = useState({
        show: false,
        message: "",
        id: 1,
    });
    const [auditShow, setAuditShow] = useState(false);
    const [validationError, setValidationError] = useState({});

    const org = JSON.parse(localStorage.getItem("org"));

    const month = [
        {
            label: "January",
            value: "1",
            id: "1",
        },
        {
            label: "February",
            value: "2",
            id: "2",
        },
        {
            label: "March",
            value: "3",
            id: "3",
        },
        {
            label: "April",
            value: "4",
            id: "4",
        },
        {
            label: "May",
            value: "5",
            id: "5",
        },
        {
            label: "June",
            value: "6",
            id: "6",
        },
        {
            label: "July",
            value: "7",
            id: "7",
        },
        {
            label: "August",
            value: "8",
            id: "8",
        },
        {
            label: "September",
            value: "9",
            id: "9",
        },
        {
            label: "October",
            value: "10",
            id: "10",
        },
        {
            label: "November",
            value: "11",
            id: "11",
        },
        {
            label: "December",
            value: "12",
            id: "12",
        },
    ];

    const handleFieldChanges = (field, value, custom, index) => {
        if (value && value !== "") {
            setValidationErrorObj(field, false);
        } else {
            setValidationErrorObj(field, true);
        }
        let topicObjCopy = { ...object };
        if (!custom) {
            topicObjCopy[field] = value;
            if (field === "month") {
                const d = new Date();
                let currMonth = d.getMonth() + 1;
                let currYear = d.getFullYear();
                let auditDate = new Date();
                if (parseInt(value?.value) <= currMonth) {
                    auditDate.setFullYear(currYear + 1, parseInt(value?.value) - 1, 1);
                    topicObjCopy["auditDate"] = moment(auditDate).format(
                        APP_CONSTANTS.DATE_FORMAT_1
                    );
                } else {
                    auditDate.setFullYear(currYear, parseInt(value?.value) - 1, 1);
                    topicObjCopy["auditDate"] = moment(auditDate).format(
                        APP_CONSTANTS.DATE_FORMAT_1
                    );
                }
            }
            dispatch(updateTopicObject(topicObjCopy));
            return;
        }

        if (field === "excluded_auditor") {
            let checkExc = topicObjCopy.excluded_auditor
                .map((item) => item?.value)
                .indexOf(value?.value);
            if (checkExc >= 0) {
                setAuditError(true);
            } else {
                setAuditError(false);
                topicObjCopy.excluded_auditor[index] = value;
            }
            dispatch(updateTopicObject(topicObjCopy));
            return;
        }
        topicObjCopy[field] = value;
        dispatch(updateTopicObject(topicObjCopy));
        switch (field) {
            case "lead_auditor":
                if (
                    handleFieldValidations(
                        "accountable_manager",
                        value,
                        5,
                        "Accountable manager and Lead Auditor can't be the same person.",
                        topicObjCopy
                    )
                ) {
                    return;
                }
                let ind = topicObjCopy.excluded_auditor
                    .map((item) => item?.value)
                    .indexOf(value?.value);
                if (ind >= 0) {
                    topicObjCopy.excluded_auditor.splice(ind, 1);
                }
                break;

            case "accountable_manager":
                topicObjCopy[field] = value;
                if (
                    handleFieldValidations(
                        "verifier",
                        value,
                        2,
                        "Accountable manager and Verifier can't be the same person.",
                        topicObjCopy,
                        "accountable_manager"
                    )
                ) {
                    return;
                }

                if (auditShow) {
                    if (
                        handleFieldValidations(
                            "lead_auditor",
                            value,
                            3,
                            "Accountable manager and Lead Auditor can't be the same person.",
                            topicObjCopy
                        )
                    ) {
                        return;
                    }
                }
                break;

            case "verifier":
                topicObjCopy[field] = value;
                if (
                    handleFieldValidations(
                        "accountable_manager",
                        value,
                        4,
                        "Accountable manager and verifier can't be the same person.",
                        topicObjCopy
                    )
                ) {
                    return;
                }
                break;

            default:
        }
    };

    // Validation for Empty Fields
    const validateObject = () => {
        let errorFlag = false;
        setValidationError({});
        if (!object?.lead_auditor && auditShow) {
            setValidationErrorObj("lead_auditor", true);
            errorFlag = true;
        }
        if (!object?.accountable_manager) {
            setValidationErrorObj("accountable_manager", true);
            errorFlag = true;
        }
        if (!object?.verifier) {
            setValidationErrorObj("verifier", true);
            errorFlag = true;
        }
        if (!object?.month && auditShow) {
            setValidationErrorObj("month", true);
            errorFlag = true;
        }
        return errorFlag;
    };

    const setValidationErrorObj = (key, flag) => {
        setValidationError((old) => {
            return {
                ...old,
                [key]: flag,
            };
        });
    };

    const checkLastValue = () => {
        if (!object?.lead_auditor) {
            setIsDisable(() => {
                return true;
            });
        } else if (!object?.lead_auditor[object?.audit?.length - 1]?.label) {
            setIsDisable(() => {
                return true;
            });
        } else {
            setIsDisable(() => {
                return false;
            });
        }

        if (!object?.excluded_auditor) {
            setDisableExcludedInspector(() => {
                return true;
            });
        } else if (
            !object?.excluded_auditor[object?.excluded_auditor?.length - 1]?.label
        ) {
            setDisableExcludedInspector(() => {
                return true;
            });
        } else {
            setDisableExcludedInspector(() => {
                return false;
            });
        }
    };

    const increaseFieldChange = (field, value, custom, index) => {
        let objCopy = { ...object };
        if (!custom) objCopy[field] = value;

        switch (field) {
            case "excludeAuditor":
                objCopy["excluded_auditor"].push({});
                break;
            default:
        }
        setObj(objCopy);
    };

    // remove selected Inspector and Excluded Inspector
    const removeauditor = (index, type) => {
        let topicObjCopy = { ...object };
        switch (type) {
            case "excluded_auditor":
                topicObjCopy?.excluded_auditor.splice(index, 1);
                break;
            default:
        }
        dispatch(updateTopicObject(topicObjCopy));
    };

    const handleSave = () => {
        if (validateObject()) return;
        else {
            dispatch(updateTopicApi(object));
        }
    };

    const closeModal = (flag) => {
        dispatch(updateTopicObject({}));
        toggleEditTopicModal(flag);
    };

    const handleFieldValidations = (field, value, id, message, object) => {
        if (object[field]?.value === value?.value) {
            setValidError({ show: true, message: message, id: id });
            return true;
        } else {
            setValidError({ show: false, message: "", id: id });
            return false;
        }
    };

    useEffect(() => {
        if (success)
            setTimeout(() => {
                closeModal(false);
            }, 1000);
    }, [success]);

    useEffect(() => {
        if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_3) {
            setAuditShow(true);
        } else if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_4) {
            setAuditShow(true);
        }
    }, []);

    useEffect(() => {
        let tempArr = auditor?.filter(function (cv) {
            return object?.lead_auditor?.value !== cv.value;
        });
        setExAud(tempArr);

        let tempArr1 = auditor?.filter(function (cv) {
            return !object?.excluded_auditor?.find(function (e) {
                return e.value === cv.value;
            });
        });
        setAud(tempArr1);
        checkLastValue();
        if (object?.lead_auditor?.length < 1) {
            object?.lead_auditor?.push({});
        }

        if (object?.excluded_auditor?.length < 1) {
            object?.excluded_auditor?.push({});
        }
    }, [object, obj]);

    return (
        <section className="audit-roles-modal gmp_inspection_modal">
            <div
                className="modal fade show"
                style={{ display: "block" }}
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center">
                                    <img
                                        src={LoginGroup}
                                        className={
                                            auditShow ? "gmp_role_modal_img" : "audit-modal-img"
                                        }
                                        width={230}
                                        alt="system audit"
                                    />
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="mb-4">
                                        <h2 className="fs-18 fw-600 color-04 mb-0">
                                            <span className="color-04">Editing:</span> {object?.name}
                                        </h2>
                                        <p className="mb-0 font-weight-light fs-14">
                                            Let's assign some of your awesome team to this audit.
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {auditShow && (
                                                <>
                                                    <div className="form-group row mb-4">
                                                        <label className="col-12 col-lg-5 col-form-label">
                                                            Lead auditor{" "}
                                                        </label>
                                                        <div className="col-12 col-lg-7">
                                                            <div className="d-flex">
                                                                <div className="mb-2 w-100">
                                                                    <Select
                                                                        className="fs-14"
                                                                        options={aud}
                                                                        value={object?.lead_auditor}
                                                                        onChange={(data) =>
                                                                            handleFieldChanges(
                                                                                "lead_auditor",
                                                                                data,
                                                                                true
                                                                            )
                                                                        }
                                                                    />
                                                                    {validationError.lead_auditor && (
                                                                        <small className="text-danger">
                                                                            please select required field.
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row mb-4 light-bg">
                                                        <label className="col-12 col-lg-5 col-form-label">
                                                            Excluded auditors
                                                        </label>
                                                        <div className="col-12 col-lg-7">
                                                            {object?.excluded_auditor?.map((obj, index) => (
                                                                <div className="d-flex">
                                                                    <div key={index} className="mb-2 w-100">
                                                                        <Select
                                                                            className="fs-14"
                                                                            options={exAud}
                                                                            value={obj ? obj : exAud}
                                                                            onChange={(data) =>
                                                                                handleFieldChanges(
                                                                                    "excluded_auditor",
                                                                                    data,
                                                                                    true,
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {obj?.value && (
                                                                        <div
                                                                            className="pt-2 pl-3"
                                                                            onClick={() =>
                                                                                removeauditor(index, "excluded_auditor")
                                                                            }
                                                                        >
                                                                            <i className="fal fa-minus-circle cursor"></i>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}

                                                            <button
                                                                disabled={disableExcludedInspector}
                                                                to={"#"}
                                                                className="color-05 fs-14 fw-300"
                                                                style={{ background: "none", border: "none" }}
                                                                onClick={() => {
                                                                    increaseFieldChange("excludeAuditor");
                                                                }}
                                                            >
                                                                <i className="fal fa-plus-circle"></i> Exclude
                                                                another auditor
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            <div className="form-group row">
                                                <label className="col-12 col-lg-5 col-form-label">
                                                    Accountable manager{" "}
                                                </label>
                                                <div className="col-12 col-lg-7">
                                                    <Select
                                                        className="fs-14"
                                                        options={accountable_manager}
                                                        value={object?.accountable_manager}
                                                        onChange={(data) => {
                                                            handleFieldChanges(
                                                                "accountable_manager",
                                                                data,
                                                                true
                                                            );
                                                        }}
                                                    />
                                                    {validationError.accountable_manager && (
                                                        <small className="text-danger">
                                                            please select required field.
                                                        </small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group row light-bg">
                                                <label className="col-12 col-lg-5 col-form-label">
                                                    Verifier
                                                </label>
                                                <div className="col-12 col-lg-7">
                                                    <Select
                                                        className="fs-14"
                                                        options={verifier}
                                                        value={object?.verifier}
                                                        onChange={(data) => {
                                                            handleFieldChanges("verifier", data, true);
                                                        }}
                                                    />
                                                    {validationError.verifier && (
                                                        <small className="text-danger">
                                                            please select required field.
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                            {auditShow && (
                                                <>
                                                    <div className="form-group row">
                                                        <label className="col-12 col-lg-5 col-form-label">
                                                            Month of the first audit
                                                        </label>
                                                        <div className="col-12 col-lg-7">
                                                            <Select
                                                                className="fs-14"
                                                                options={month}
                                                                value={object?.month}
                                                                onChange={(data) => {
                                                                    handleFieldChanges("month", data);
                                                                }}
                                                            />
                                                            {validationError.month && (
                                                                <small className="text-danger">
                                                                    please select required field.
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row mb-4 align-items-center light-bg">
                                                        <div className="col-10 col-sm-10 col-lg-7">
                                                            <label
                                                                className="col-form-label"
                                                                htmlFor="pauseAudit"
                                                            >
                                                                <p className="mb-0">Pause this audit</p>
                                                                <p className="mb-0 font-weight-light">
                                                                    You can restart it at any time.
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <div className="col-2 col-sm-2 col-lg-5">
                                                            <input
                                                                type="checkbox"
                                                                id="pauseAudit"
                                                                checked={object?.pauseAudit}
                                                                onChange={(e) =>
                                                                    handleFieldChanges(
                                                                        "pauseAudit",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {validError?.show && (
                                                <div
                                                    className="error-note fs-12 fw-400 mt-1 mb-3 float-right mr-lg-5"
                                                    style={{ color: "#FF0000" }}
                                                >
                                                    <i className="fal fa-exclamation-triangle" />{" "}
                                                    {validError.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="float-right">
                                        <button
                                            type="button"
                                            className="cancel mr-2"
                                            onClick={() => closeModal(false)}
                                        >
                                            <i className="far fa-ban" /> Close
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-send"
                                            disabled={validError.show}
                                            onClick={handleSave}
                                        >
                                            <i className="far fa-save" /> Save
                                        </button>
                                    </div>
                                    {success && (
                                        <div className="alert alert-success mt-0" role="alert">
                                            Topic successfully saved.
                                        </div>
                                    )}
                                    {error?.show && (
                                        <div className="alert alert-danger mt-0" role="alert">
                                            {error?.message ? error?.message : error?.error?.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </section>
    );
};

export default AuditModal;
