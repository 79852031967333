import React, { useEffect, useState } from "react";
import request from "../../services/request";
import { REPORTS } from "../../Routes/Api";
import { useSelector } from "react-redux";
import { APP_CONSTANTS } from "../../constants";
import session from "../../services/session";

const moment = require("moment");

const CreateGmpReportModal = (props) => {
    const { standards, org, closeModal } = props;

    let { filteredGmpList } = useSelector((state) => state?.gmp);

    const organization = session.getOrg();

    var gmpForReport = [];
    var gmpReport;
    var startDate;
    var endDate;

    const [data, setData] = useState({
        standard: [],
        org: org,
        gmpData: [],
        orgData: organization,
        onlyRef: false,
    });

    const [error, setError] = useState({
        show: false,
        message: "",
    });

    const [success, setSuccess] = useState({
        show: false,
        message: "",
    });

    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        gmpForReport = [];

        filteredGmpList?.map((item) => {
            if (isAnyIdMatchInArrays(item?.standards, data?.standard)) {
                gmpForReport.push({
                    ...item,
                    date_raised: moment(item?.date_raised).format(
                        APP_CONSTANTS.DATE_FORMAT
                    ),
                });
            }
        });
    }, [data, error]);

    function isAnyIdMatchInArrays(A, B) {
        for (const objA of A) {
            const idA = parseInt(objA);

            if (B?.includes(idA)) {
                return true;
            }
        }
        return false;
    }

    const handledFieldChanges = (field, checked, stn) => {
        switch (field) {
            case "standard":
                let addStandard = data.standard;
                if (checked) {
                    addStandard?.push(stn?.value);
                } else {
                    const index = addStandard?.indexOf(stn?.value);
                    if (index >= 0) {
                        addStandard?.splice(index, 1);
                    }
                }
                setData((old) => {
                    return { ...old, standard: addStandard };
                });
                break;
            case "onlyRef":
                if (checked) {
                    setData((old) => {
                        return { ...old, onlyRef: true };
                    });
                } else {
                    setData((old) => {
                        return { ...old, onlyRef: false };
                    });
                }
                break;
            default:
        }
    };

    const createReports = () => {
        gmpReport =
            gmpForReport?.length > 0 &&
            gmpForReport.sort((a, b) => {
                return a?.id - b?.id;
            });
        startDate = gmpReport[0]?.date_raised;
        endDate = gmpReport[gmpReport?.length - 1]?.date_raised;

        if (gmpReport?.length > 30) {
            setError({
                show: true,
                message: "Maximum records for pdf export is 30. Please reduce the number of records or use the excel download instead.",
            });
        } else if (!data?.standard?.length) {
            setError({
                show: true,
                message: "You must choose a standard for the GMP report to proceed.",
            });
        } else {
            setError({ show: false, message: "" });
            setLoading(true);
            setDisable(true);
            request
                .post(
                    REPORTS.CREATE_GMP_REPORTS,
                    { ...data, gmpData: gmpForReport, startDate, endDate },
                    true
                )
                .then(
                    (res) => {
                        setError({ show: false, message: "" });
                        setSuccess({ show: true, message: res?.message });
                        setLoading(false);
                        setTimeout(() => {
                            setSuccess({ show: false, message: "" });
                            closeModal(false);
                        }, APP_CONSTANTS.MODAL_SHOW_TIME);
                    },
                    (err) => {
                        setError({ show: true, message: err?.message });
                        setLoading(false);
                        setDisable(false);
                        setTimeout(() => {
                            setError({ show: false, message: "" });
                        }, APP_CONSTANTS.MODAL_SHOW_TIME);
                    }
                );
        }
    };

    return (
        <div className="create-report">
            <div
                className="modal fade show"
                id="createReportModal"
                tabIndex="-1"
                aria-labelledby="createReportModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <div className="row align-items-center">
                                <div className="col-sm-12">
                                    <h2 className="color-03 fs-24 fw-600 mb-4">
                                        We've got all your info ready...
                                    </h2>
                                    <h3 className="color-03 fs-18 fw-600 mb-4">
                                        Before we can create your report we need to know which
                                        standards you want to include:
                                    </h3>
                                    <div className="row">
                                        {standards?.length > 0 &&
                                            standards?.map((stn, ind) => (
                                                <div className="col-10 col-sm-10 col-lg-6" key={ind}>
                                                    <div className="custom-control custom-checkbox mb-2 mr-xl-2 mr-sm-5">
                                                        <input
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            id={`${stn?.value}`}
                                                            onChange={(e) =>
                                                                handledFieldChanges(
                                                                    "standard",
                                                                    e.target.checked,
                                                                    stn
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="custom-control-label cursor fs-14 fw-600 color-04"
                                                            htmlFor={`${stn?.value}`}
                                                        >
                                                            {stn?.label}
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6 col-sm-6 col-lg-4">
                                            <div className="custom-control custom-checkbox mr-2">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="onlyRef"
                                                    onChange={(e) =>
                                                        handledFieldChanges("onlyRef", e.target.checked)
                                                    }
                                                />
                                                <label
                                                    className="custom-control-label cursor fs-14 fw-600 color-04"
                                                    htmlFor="onlyRef"
                                                >
                                                    Only include NC references
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        Tick this box if you'd prefer to use NC references rather
                                        than have all the NC information in this report.
                                    </p>
                                    {error?.show && (
                                        <div
                                            className="alert alert-danger mt-0"
                                            role="alert"
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            {error?.message}
                                        </div>
                                    )}
                                    {success?.show && (
                                        <div
                                            className="alert alert-success mt-0"
                                            role="alert"
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            {success?.message}
                                        </div>
                                    )}
                                    <div className="mt-4 text-center">
                                        <button
                                            className="btn-no px-4 py-2 fs-16 mr-2"
                                            onClick={() => closeModal(false)}
                                            disabled={disable}
                                        >
                                            <i className="far fa-ban" /> Cancel
                                        </button>
                                        <button
                                            className="btn-yes px-4 py-2 fs-16"
                                            onClick={createReports}
                                            disabled={disable}
                                        >
                                            <i className="fal fa-file-pdf" /> Create report{" "}
                                            {loading && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    style={{ marginBottom: "2px" }}
                                                ></div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default CreateGmpReportModal;
