import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Searchbar from "../../Home/TodoLIst/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import {
    editMultiSite,
    filterMultisiteList,
    getMultiSitesListApi,
} from "../../../store/actions/sites";
import { changeTabState } from "../../../store/actions/tabState";
import session from "../../../services/session";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const MultiSite = (props) => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);

    const { multiSitesList } = useSelector((state) => state.sites);

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                multiSitesList?.filter((item) => {
                    if (
                        item?.multisiteName
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        checkSites(searchValue, item?.sites) ||
                        checkAdmins(searchValue, item?.admins)
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const checkSites = (search, sites) => {
        let tempBool = false;
        sites?.forEach((site) => {
            if (site?.label?.toLowerCase().includes(search?.toLowerCase())) {
                tempBool = true;
            }
        });
        return tempBool;
    };

    const checkAdmins = (search, admins) => {
        let tempBool = false;
        admins?.forEach((admin) => {
            if (
                admin?.email?.toLowerCase().includes(search?.toLowerCase()) ||
                admin?.user_name?.toLowerCase().includes(search?.toLowerCase())
            ) {
                tempBool = true;
            }
        });
        return tempBool;
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div>
                <Searchbar
                    onFilter={(e) => filterData(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            </div>
        );
    }, [filterText, filteredList]);

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataMultiSite = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataMultiSite);
    };

    const handleRowSelection = ({ selectedRows }) => {
        dispatch(filterMultisiteList(selectedRows));
    };

    const editMultisite = (row) => {
        dispatch(editMultiSite(row));
        dispatch(changeTabState(4));
    };
    const editMultisiteUsers = (row) => {
        session.setMultisite(row);
        dispatch(changeTabState(1));
        navigate(`/private/users`);
    };

    const columns = [
        {
            name: "Multi-site name",
            selector: (row) => row?.multisiteName,
            sortable: true,
            width: "250px",
            cell: (row) => (
                <a
                    className="color-05 user-name-link cursor"
                    onClick={() => editMultisite(row)}
                >
                    {row?.multisiteName}
                </a>
            ),
        },
        {
            name: "Multi-site users",
            width: "250px",
            cell: (row) => (
                <a
                    className="color-05 user-name-link cursor"
                    onClick={() => editMultisiteUsers(row)}
                >
                    Edit
                </a>
            ),
        },
        {
            name: "Sites",
            cell: (row) => (
                <div>
                    {row?.sites?.map((site, index) =>
                        index !== row?.sites?.length - 1 ? (
                            <div key={index} style={{ paddingBottom: "5px" }}>
                                {site?.label}
                            </div>
                        ) : row?.sites?.length > 1 ? (
                            <div key={index} style={{ paddingBottom: "5px" }}>
                                {site?.label}
                            </div>
                        ) : (
                            <div key={index}>{site?.label}</div>
                        )
                    )}
                </div>
            ),
        },
        {
            name: "Multi-site admin",
            cell: (row) => (
                <div>
                    {row?.admins?.map((admin, index) =>
                        index !== row?.admins?.length - 1 ? (
                            <div key={index} style={{ paddingBottom: "5px" }}>
                                {admin?.user_name} - {admin?.email}
                            </div>
                        ) : row?.sites?.length > 1 ? (
                            <div key={index} style={{ paddingBottom: "5px" }}>
                                {admin?.user_name} - {admin?.email}
                            </div>
                        ) : (
                            <div key={index}>
                                {admin?.user_name} - {admin?.email}
                            </div>
                        )
                    )}
                </div>
            ),
        },
        {
            width: "100px",
            cell: (row) => (
                <a
                    className="color-05 user-name-link cursor"
                    onClick={() => editMultisite(row)}
                >
                    Edit
                </a>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getMultiSitesListApi());
    }, []);

    useEffect(() => {
        setFilteredList(multiSitesList);
    }, [multiSitesList]);

    return (
        <div className="table-responsive">
            <DataTable
                columns={columns}
                data={filteredList}
                selectableRows
                customStyles={customStyles}
                striped
                onSelectedRowsChange={handleRowSelection}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />
        </div>
    );
};

export default MultiSite;
