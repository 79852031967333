import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { APP_CONSTANTS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Searchbar from "../../Home/TodoLIst/Searchbar";
import DateField from "../../Date/DateField";
import FilteredInputField from "./FilteredInputField";
import {
  emptyFilterGmpList,
  filterGmpList,
  getGMPInspectionsByUser,
  updateGMPObject,
} from "../../../store/actions/gmp";
import ReassignModal from "../../Modals/ReassignModal";
import SendGmpToInspectorModal from "../../Modals/SendGmpToInspectorModal";
import { generateSequence } from "../../../utils/referenceId";
import utils from "../../../utils";
import { changeTabState } from "../../../store/actions/tabState";

const customStyles = {
  rows: {
    style: {
      borderBottom: "none !important",
      color: "",
    },
  },
  headCells: {
    style: {
      background: "#F6F1F1",
      height: "30px",
      borderBottom: "none !important",
      fontSize: "14px",
      fontWeight: "600",
      color: "#686868",
    },
  },
  cells: {
    style: {},
  },
};

const GMPInspections = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { userGMPs } = useSelector((state) => state.gmp);

  const [filterText, setFilterText] = useState("");
  const [filterDate, setFilterDate] = useState({ from: "", to: "" });
  const [filterStatus, setFilterStatus] = useState("none");
  const [filteredList, setFilteredList] = useState(userGMPs);
  const [search, setSearch] = useState("");
  const [reassignToggle, setReassignToggle] = useState(false);
  const [sendGmpToggle, setSendGmpToggle] = useState(false);
  const [GMPId, setGMPId] = useState("");
  const [ids, setIds] = useState("");

  const org = JSON.parse(localStorage.getItem("org"));
  const user = JSON.parse(localStorage.getItem("user"));

  const toggleReassignModal = (flag) => {
    setReassignToggle(flag);
  };

  const toggleSendGmpModal = (flag) => {
    setSendGmpToggle(flag);
  };

  const handleRowSelection = ({ selectedRows }) => {
    dispatch(filterGmpList(selectedRows));
  };

  const statuses = [
    {
      label: "Choose a Status",
      value: "none",
    },
    {
      label: "All",
      value: "all",
    },
    {
      label: "Draft",
      value: "Draft",
    },
    {
      label: "Due",
      value: "Due",
    },
    {
      label: "In progress",
      value: "In progress",
    },
    {
      label: "Completed",
      value: "Completed",
    },
    {
      label: "Partly complete",
      value: "Partly complete",
    },
    {
      label: "Completed late",
      value: "Completed late",
    },
    {
      label: "Partly completed late",
      value: "Partly completed late",
    },
    {
      label: "Overdue",
      value: "Overdue",
    },
    {
      label: "Missed",
      value: "Missed",
    },
  ];

  const checkInspectors = (search, data) => {
    let tempBool = false;
    data?.forEach((item) => {
      if (item?.label?.toLowerCase().includes(search?.toLowerCase())) {
        tempBool = true;
      }
    });
    return tempBool;
  };

  const filterData = React.useCallback(
    (searchValue) => {
      setFilteredList(
        userGMPs?.filter((item) => {
          if (
            `${(
              item?.gmpInspection?.type || item?.type
            ).toLowerCase()}-${generateSequence(
              item?.gmpInspection?.id || item?.id
            )}`.includes(searchValue?.toLowerCase()) ||
            // `${item?.type?.toLowerCase()}-${item?.id || 0}`.includes(
            //   searchValue?.toLowerCase()
            // ) ||
            item?.parentGmp?.gmpName
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase()) ||
            item?.gmpName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            checkInspectors(
              searchValue,
              item?.gmpInspection?.inspectors || item?.inspectors
            ) ||
            item?.gmpInspection?.accountable_manager?.label
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase()) ||
            item?.accountable_manager?.label
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase()) ||
            item?.gmpInspection?.verifier?.label
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase()) ||
            item?.verifier?.label
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase()) ||
            item?.gmpInspection?.status
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase()) ||
            item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            ("automatic"?.includes(searchValue?.toLowerCase()) &&
              item?.isAutomatic) ||
            ("manual"?.includes(searchValue?.toLowerCase()) &&
              !item?.isAutomatic)
          ) {
            return item;
          }
        })
      );
    },
    [filteredList]
  );

  const dateFilter = (field, value) => {
    let to = null;
    let from = null;
    if (field === "from") {
      setFilterDate((old) => {
        return { ...old, [field]: value };
      });
    } else {
      setFilterDate((old) => {
        return { ...old, [field]: value };
      });
    }
  };

  const dateFilteredData = () => {
    let tempArr = userGMPs;

    if (filterDate?.from && filterDate?.from !== "") {
      tempArr = tempArr?.filter((item) => {
        if (
          moment(
            item?.date_raised ? item?.date_raised : item?.createdAt
          ).format(APP_CONSTANTS.DATE_FORMAT_1) >=
          moment(filterDate?.from).format(APP_CONSTANTS.DATE_FORMAT_1)
        ) {
          return item;
        }
      });
    }

    if (filterDate?.to && filterDate?.to !== "") {
      tempArr = tempArr?.filter((item) => {
        if (
          moment(
            item?.date_raised ? item?.date_raised : item?.createdAt
          ).format(APP_CONSTANTS.DATE_FORMAT_1) <=
          moment(filterDate?.to).format(APP_CONSTANTS.DATE_FORMAT_1)
        ) {
          return item;
        }
      });
    }

    if (filterStatus !== "none") {
      if (filterStatus === "all") {
        tempArr = tempArr;
      } else {
        tempArr = tempArr?.filter((item) => {
          if (item?.status === filterStatus) {
            return item;
          }
        });
      }
    }
    setFilteredList(tempArr);
    dispatch(filterGmpList(tempArr));
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };

    return (
      <>
        <div style={{ width: "100%" }}>
          <DateField
            filterData={(e) => dateFilter("from", e.target.value)}
            raised={true}
            dateValue={filterDate?.from}
            label={"From:"}
          />
          <DateField
            filterData={(e) => dateFilter("to", e.target.value)}
            dateValue={filterDate?.to}
            label={"To:"}
          />
          <FilteredInputField
            list={statuses}
            value={filterStatus}
            action={setFilterStatus}
          />
          <Searchbar
            onFilter={(e) => {
              filterData(e.target.value);
              setSearch(e.target.value);
            }}
            onClear={handleClear}
            filterText={filterText}
            type="gmp_inspection_info"
          />
        </div>
      </>
    );
  }, [filterText, filteredList, filterDate]);

  // useEffect(() => {
  //   if (!userGMPs?.length) dispatch(getGMPInspectionsByUser());
  // }, []);

  useEffect(() => {
    // setFilteredList(userGMPs);
    dateFilteredData();
  }, [filterDate, filterStatus]);

  useEffect(() => {
    dispatch(filterGmpList(filteredList));
  }, [filteredList]);

  useEffect(() => {
    setFilteredList(userGMPs);
  }, [userGMPs]);

  // useEffect(() => {
  //   dispatch(getGMPInspectionsByUser());
  // }, []);

  useEffect(() => {
    dispatch(getGMPInspectionsByUser());
  }, [reassignToggle, sendGmpToggle]);

  useEffect(() => {
    dispatch(emptyFilterGmpList());
  }, []);

  const redirectToQuestions = (data) => {
    if (
      (user?.permissions?.general?.system_manager ||
        user?._id === data?.raised_by?.value) &&
      data?.status === "Draft"
    ) {
      dispatch(updateGMPObject({ ...data }));
      dispatch(changeTabState(14));
    } else {
      navigate(`/private/gmp-inspection/${org?._id}/${data?._id}`);
    }
  };

  const reassignInspector = (data) => {
    if (!utils.checkSubStatus()) {
      return;
    } else {
      setGMPId(data?.parentGmp ? data?.parentGmp?._id : data?._id);
      setIds(data?._id);
      toggleReassignModal(true);
    }
  };

  const isInspector = (user, inspectors) => {
    let tempBool = false;
    inspectors?.forEach((item) => {
      if (item?.value === user) {
        tempBool = true;
      }
    });
    return tempBool;
  };

  const sendGmpToInspector = (data) => {
    if (!utils.checkSubStatus()) {
      return;
    } else {
      setGMPId(data?._id);
      toggleSendGmpModal(true);
    }
  };

  const calculateScore = (item) => {
    var score = 0;
    let answersCount = findCountofAnswers(item?.gmpQuestions);
    if (item?.status === "Missed") {
      score = score + 0;
    }
    if (item?.status === "Completed" || item?.status === "Completed late") {
      let cal =
        (answersCount?.yes / (item?.gmpQuestions?.length - answersCount?.na)) *
        100;
      score = score + cal ? cal : 0;
    }
    if (item?.status === "Partly complete") {
      let cal =
        answersCount?.yes +
        answersCount?.no +
        answersCount?.na -
        answersCount?.na;
      score = score + (answersCount?.yes / (cal ? cal : 0)) * 100;
    }
    let round = Math.round(score);
    return round || 0;
  };

  const findCountofAnswers = (questions) => {
    let count = {
      na: 0,
      yes: 0,
      no: 0,
    };
    questions?.map((item) => {
      if (item?.answers[0]?.na) {
        count.na = count.na + 1;
      }
      if (item?.answers[0]?.yes) {
        count.yes = count.yes + 1;
      }
      if (item?.answers[0]?.no) {
        count.no = count.no + 1;
      }
    });
    return count;
  };

  const columns = [
    {
      name: "Reference",
      selector: (row) =>
        `${row?.gmpInspection?.type ? row?.gmpInspection?.type : row?.type}-${row?.gmpInspection?.id
          ? generateSequence(row?.gmpInspection?.id)
          : generateSequence(row?.id)
        }`,
      sortable: true,
      cell: (row) => (
        <a
          className="edit-link user-name-link cursor"
          onClick={() => redirectToQuestions(row)}
        >
          {`${row?.gmpInspection?.type ? row?.gmpInspection?.type : row?.type
            }-${row?.gmpInspection?.id
              ? generateSequence(row?.gmpInspection?.id)
              : generateSequence(row?.id)
            }`}
        </a>
      ),
      width: "130px",
    },
    {
      name: "Inspection",
      selector: (row) =>
        `${row?.parentGmp?.gmpName ? row?.parentGmp?.gmpName : row?.gmpName}`,
      cell: (row) =>
        row?.parentGmp?.gmpName ? row?.parentGmp?.gmpName : row?.gmpName,
      sortable: true,
    },
    {
      name: "Inspector",
      selector: (row) =>
        row?.gmpInspection?.inspectors
          ? row?.gmpInspection?.inspectors
          : row?.inspectors,
      cell: (row) => (
        <div>
          {(row?.gmpInspection?.inspectors
            ? row?.gmpInspection?.inspectors
            : row?.inspectors
          ).map((data, index) => (
            <div key={index}>{data?.label}</div>
          ))}
        </div>
      ),
      width: "200px",
    },
    {
      name: "Created Date",
      selector: (row) =>
        row?.date_raised &&
        moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT),
      sortable: true,
      cell: (row) =>
        row?.date_raised &&
        moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT),
    },
    {
      name: "Due By",
      selector: (row) =>
        row?.due_date
          ? moment(row?.due_date).format(APP_CONSTANTS.DATE_FORMAT)
          : moment(row?.inspectDate).format(APP_CONSTANTS.DATE_FORMAT),
      sortable: true,
      cell: (row) =>
        row?.due_date
          ? moment(row?.due_date).format(APP_CONSTANTS.DATE_FORMAT)
          : moment(row?.inspectDate).format(APP_CONSTANTS.DATE_FORMAT),
    },
    {
      name: "Accountable Manager",
      selector: (row) =>
        row?.gmpInspection?.accountable_manager?.label
          ? row?.gmpInspection?.accountable_manager?.label
          : row?.accountable_manager?.label,
      sortable: true,
      cell: (row) =>
        row?.gmpInspection?.accountable_manager?.label
          ? row?.gmpInspection?.accountable_manager?.label
          : row?.accountable_manager?.label,
    },
    {
      name: "Verifier",
      selector: (row) =>
        row?.gmpInspection?.verifier?.label
          ? row?.gmpInspection?.verifier?.label
          : row?.verifier?.label,
      sortable: true,
      cell: (row) =>
        row?.gmpInspection?.verifier?.label
          ? row?.gmpInspection?.verifier?.label
          : row?.verifier?.label,
    },
    {
      name: "Status",
      selector: (row) =>
        row?.gmpInspection?.status ? row?.gmpInspection?.status : row?.status,
      sortable: true,
      cell: (row) =>
        row?.gmpInspection?.status ? (
          <div data-toggle="tooltip" title={row?.status} className="py-1">
            {row?.gmpInspection?.status}
          </div>
        ) : (
          <div data-toggle="tooltip" title={row?.status} className="py-1">
            {row?.status}
          </div>
        ),
    },
    {
      name: "Score",
      selector: (row) => calculateScore(row),
      sortable: true,
      cell: (row) => `${calculateScore(row)}%`,
    },
    {
      name: "GMP Type",
      selector: (row) => (row?.isAutomatic ? "Automatic" : "Manual"),
      sortable: true,
      cell: (row) => (row?.isAutomatic ? "Automatic" : "Manual"),
    },
    {
      name: "Priority",
      selector: (row) => priorityColorSort(row?.status),
      cell: (row, index) => {
        if (
          row?.status === "Draft" ||
          row?.status === "Due" ||
          row?.status === "Completed late" ||
          row?.status === "Partly complete"
        ) {
          return <span className="dot dot-orange ml-3" />;
        } else if (
          row?.status === "In progress" ||
          row?.status === "Completed"
        ) {
          return <span className="dot dot-green ml-3" />;
        } else if (
          row?.status === "Overdue" ||
          row?.status === "Partly completed late" ||
          row?.status === "Missed"
        ) {
          return <span className="dot dot-red ml-3" />;
        }
      },
      sortable: true,
    },
    {
      name: "Reassign",
      width: "100px",
      cell: (row) =>
        isInspector(user?._id, row?.inspectors) ||
          user?.permissions?.general?.system_manager ? (
          row?.status === "Due" ? (
            <a
              className="edit-link user-name-link cursor"
              onClick={() => reassignInspector(row)}
            >
              Reassign
            </a>
          ) : null
        ) : null,
    },
    {
      name: "Send",
      width: "100px",
      cell: (row) =>
        row?.type === "GMP" ? (
          row?.status === "Draft" ? (
            <a
              className="edit-link user-name-link cursor"
              onClick={() => sendGmpToInspector(row)}
            >
              Send
            </a>
          ) : null
        ) : null,
    },
  ];

  const priorityColorSort = (status) => {
    if (
      status === "Draft" ||
      status === "Due" ||
      status === "Completed late" ||
      status === "Partly complete"
    ) {
      return "Orange";
    } else if (status === "In progress" || status === "Completed") {
      return "Green";
    } else if (
      status === "Overdue" ||
      status === "Partly completed late" ||
      status === "Missed"
    ) {
      return "Red";
    }
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: "#cbc3c3",
        userSelect: "none",
      },
    },
  ];

  const handleRowClicked = (row) => {
    const updatedDataGMP = filteredList?.map((item) => {
      if (row?._id !== item?._id) {
        return item;
      }

      return {
        ...item,
        toggleSelected: !item?.toggleSelected,
      };
    });

    setFilteredList(updatedDataGMP);
  };

  const customUniqueID = (row) => {
    return row?._id;
  };

  return (
    <div className="table-responsive prog_info">
      <DataTable
        columns={columns}
        data={filteredList?.length > 0 ? filteredList : []}
        selectableRows
        customStyles={customStyles}
        striped
        onSelectedRowsChange={handleRowSelection}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        keyField={customUniqueID}
        pagination
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
        fixedHeaderScrollHeight="calc(100vh - 400px)"
        fixedHeader={true}
      />

      {reassignToggle && (
        <ReassignModal
          toggleReassignModal={toggleReassignModal}
          gmpId={GMPId}
          id={ids}
        />
      )}

      {sendGmpToggle && (
        <SendGmpToInspectorModal
          toggleSendGmpModal={toggleSendGmpModal}
          id={"send_gmp_to_inspectors"}
          gmpId={GMPId}
        />
      )}
    </div>
  );
};

export default GMPInspections;
