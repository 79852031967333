import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getGmpTemplateApi } from "../../store/actions/gmpTemplate";
import {
    clearGMPObject,
    createGMPAPi,
    updateGMPAPi,
    updateGMPObject,
} from "../../store/actions/gmp";
import moment from "moment";
import { APP_CONSTANTS } from "../../constants";

const GmpInspectionModal = (props) => {
    const { toggleEditGmpModal, toggleAddGmpModal, type, toggleFrequencyModal } =
        props;
    const dispatch = useDispatch();

    const [validationError, setValidationError] = useState({});
    const [negativeFrequency, setNegativeFrequency] = useState(false);
    const [lock, setLock] = useState(false);
    const [nextInspectionDate, setNextInspectionDate] = useState(false);

    const { list } = useSelector((state) => state?.gmpTemplate);
    const { object, success, error } = useSelector((state) => state?.gmp);

    const user = JSON.parse(localStorage.getItem("user"));
    const org = JSON.parse(localStorage.getItem("org"));

    function isFloat(value) {
        if (value.includes(".")) {
            return true;
        }
        return false;
    }

    // onChange Fields
    const handledFieldChanges = (field, value, custom, index) => {
        let gmpObjCopy = { ...object };
        let currentDate = new Date().toISOString();
        if (value && value !== "") {
            setValidationErrorObj(field, false);
        } else {
            setValidationErrorObj(field, true);
        }

        // frequency check
        if (field === "frequency" && (value <= 0 || value > 365)) {
            setNegativeFrequency(true);
        }

        // float value check
        else if (field === "frequency" && isFloat(value)) {
            setNegativeFrequency(true);
        } else if (
            field === "frequency" &&
            !isFloat(value) &&
            (value > 0 || value <= 365)
        ) {
            if (object?.nextInspection <= currentDate) {
                gmpObjCopy["lastCreated"] = moment(new Date()).format(
                    APP_CONSTANTS.DATE_FORMAT_1
                );
            }
            setNegativeFrequency(false);
        }

        if (
            field === "isPause" &&
            value === false &&
            object?.nextInspection <= currentDate
        ) {
            gmpObjCopy["lastCreated"] = moment(new Date()).format(
                APP_CONSTANTS.DATE_FORMAT_1
            );
            // setNextInspectionDate(true);
        }
        gmpObjCopy[field] = value;
        dispatch(updateGMPObject(gmpObjCopy));
    };

    // onClick Save
    const handleSave = () => {
        if (type === "add") {
            if (validateObject()) return;
            if (!lock) {
                let gmpObjCopy = { ...object };
                gmpObjCopy["org"] = org?._id;
                gmpObjCopy["frequency"] = JSON.parse(gmpObjCopy.frequency);
                gmpObjCopy["isCreate"] = true;
                gmpObjCopy["isUpdate"] = false;
                gmpObjCopy["standard"] = org?.core_standards[0]?.value;
                gmpObjCopy["raised_by"] = {
                    label: user?.user_name,
                    value: user?._id,
                    user: user?._id,
                };
                dispatch(createGMPAPi(gmpObjCopy));
                setLock(true);
            }
            setTimeout(() => {
                setLock(false);
            }, 2000);
        } else if (type === "edit") {
            if (validateObject()) return;
            if (!lock) {
                let gmpObjCopy = { ...object };
                gmpObjCopy["frequency"] = JSON.parse(gmpObjCopy.frequency);
                gmpObjCopy["isCreate"] = false;
                gmpObjCopy["isUpdate"] = true;
                gmpObjCopy["gmpId"] = object?._id;
                // gmpObjCopy["nextInspection"] = object?.nextInspection;
                dispatch(updateGMPAPi(gmpObjCopy));
                setLock(true);
            }
            setTimeout(() => {
                setLock(false);
            }, 2000);
        }
    };

    // Validation for Empty Fields
    const validateObject = () => {
        let errorFlag = false;
        setValidationError({});
        if (!object?.template) {
            setValidationErrorObj("template", true);
            errorFlag = true;
        }
        if (!object?.gmpName) {
            setValidationErrorObj("gmpName", true);
            errorFlag = true;
        }
        if (!object?.frequency) {
            setValidationErrorObj("frequency", true);
            errorFlag = true;
        }
        if (!object?.inspectDate) {
            setValidationErrorObj("inspectDate", true);
            errorFlag = true;
        }
        if (negativeFrequency) {
            errorFlag = true;
        }
        // if (nextInspectionDate && !object?.nextInspection) {
        //     setValidationErrorObj("nextInspection", true);
        //     errorFlag = true;
        // }
        return errorFlag;
    };

    const setValidationErrorObj = (key, flag) => {
        setValidationError((old) => {
            return {
                ...old,
                [key]: flag,
            };
        });
    };

    const closeModal = (flag) => {
        dispatch(clearGMPObject({}));
        if (type === "edit") {
            toggleEditGmpModal(flag);
        } else if (type === "add") {
            toggleAddGmpModal(flag);
        }
    };

    const frequencytoggle = () => {
        toggleFrequencyModal(true);
    };

    const openInNewTab = (url) => {
        if (!url) return false;
        let local = window.location.protocol + url;
        const newWindow = window.open(local, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        if (success)
            setTimeout(() => {
                closeModal(false);
            });
    }, [success]);

    useEffect(() => {
        dispatch(getGmpTemplateApi());
    }, []);

    return (
        <section className="audit-roles-modal gmp_inspection_modal">
            <div
                className="modal fade show"
                style={{ display: "block" }}
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    {type === "edit" ? (
                        <div className="modal-content">
                            <div className="modal-body py-5 px-4">
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <h2 className="fs-18 fw-600 color-06 custom_mb_heading">
                                            <span className="color-04">Now editing:</span>{" "}
                                            {object?.gmpName}
                                        </h2>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row mb-4">
                                                    <label className="col-12 col-lg-7 col-form-label">
                                                        <p className="mb-0">Select the master inspection</p>
                                                        <p className="mb-0 font-weight-light">
                                                            Here's a recap of{" "}
                                                            <span
                                                                style={{
                                                                    textDecoration: "underline",
                                                                    textDecorationColor: "#686868",
                                                                    cursor: "pointer",
                                                                    color: "#686868",
                                                                }}
                                                                onClick={() =>
                                                                    openInNewTab(
                                                                        "/private/system-settings/manage-gmp"
                                                                    )
                                                                }
                                                            >
                                                                what's covered in each inspection
                                                            </span>
                                                            .
                                                        </p>
                                                    </label>
                                                    <div
                                                        className="col-12 col-lg-5"
                                                        style={{ borderRadius: "0px" }}
                                                    >
                                                        <Select
                                                            className="fs-14"
                                                            options={list}
                                                            defaultValue={{
                                                                label: object?.template,
                                                            }}
                                                            onChange={(data) => {
                                                                handledFieldChanges("template", data.value);
                                                            }}
                                                        />
                                                        {validationError.template && (
                                                            <small className="text-danger">
                                                                please select required field.
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-4 light-bg">
                                                    <label className="col-12 col-lg-7 col-form-label">
                                                        <p className="mb-0">GMP inspection name</p>
                                                        <p className="mb-0 font-weight-light">
                                                            This is the name that your inspectors will know it
                                                            by.
                                                        </p>
                                                    </label>
                                                    <div className="col-12 col-lg-5">
                                                        <input
                                                            type="text"
                                                            className="form-control fs-14"
                                                            id=""
                                                            placeholder="e.g. My GMP inspection"
                                                            value={object?.gmpName}
                                                            maxlength="40"
                                                            onChange={(e) =>
                                                                handledFieldChanges("gmpName", e.target.value)
                                                            }
                                                        />
                                                        {validationError.gmpName && (
                                                            <small className="text-danger">
                                                                please select required field.
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-4">
                                                    <label className="col-12 col-lg-7 col-form-label">
                                                        <p className="mb-0">Set your frequency</p>
                                                        <p
                                                            className="mb-0 font-weight-light"
                                                            style={{
                                                                textDecoration: "underline",
                                                                textDecorationColor: "#686868",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={frequencytoggle}
                                                        >
                                                            Click here to view the days calculator.
                                                        </p>
                                                    </label>
                                                    <div className="col-12 col-lg-3">
                                                        <div className="d-flex align-items-center">
                                                            <input
                                                                type="number"
                                                                className="form-control fs-14"
                                                                id=""
                                                                placeholder="e.g. 30"
                                                                min="1"
                                                                value={object?.frequency}
                                                                disabled={
                                                                    !user?.permissions?.general?.system_manager
                                                                }
                                                                onKeyDown={(e) =>
                                                                    (e.keyCode === 69 ||
                                                                        e.keyCode === 190 ||
                                                                        e.keyCode === 107 ||
                                                                        e.keyCode === 189 ||
                                                                        e.keyCode === 110 ||
                                                                        e.keyCode === 109) &&
                                                                    e.preventDefault()
                                                                }
                                                                onChange={(e) =>
                                                                    handledFieldChanges(
                                                                        "frequency",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                            <p className="mb-0 pl-2">days</p>
                                                        </div>
                                                        {validationError.frequency && (
                                                            <small className="text-danger">
                                                                please select required field.
                                                            </small>
                                                        )}
                                                        <br />
                                                        {negativeFrequency && (
                                                            <small className="text-danger">
                                                                value should be greater than 1 and less than
                                                                365, and there should be no decimal number
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-4 light-bg">
                                                    <label className="col-12 col-lg-7 col-form-label">
                                                        Date of the first inspection
                                                    </label>
                                                    <div className="col-12 col-lg-3">
                                                        <input
                                                            type="date"
                                                            className="date-selection fs-14"
                                                            min={moment().format(APP_CONSTANTS.DATE_FORMAT_1)}
                                                            value={moment(object?.inspectDate).format(
                                                                APP_CONSTANTS.DATE_FORMAT_1
                                                            )}
                                                            onChange={(e) =>
                                                                handledFieldChanges(
                                                                    "inspectDate",
                                                                    e.target.value
                                                                )
                                                            }
                                                            disabled={object?.isFirstCreated}
                                                        />
                                                        {object?.isFirstCreated && (
                                                            <p className="fs-12 mb-0 fw-400 color-03 mt-2">
                                                                First GMP is already created
                                                            </p>
                                                        )}
                                                        {validationError.inspectDate && (
                                                            <small className="text-danger">
                                                                please select required field.
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-4 align-items-center">
                                                    <div className="col-10 col-sm-10 col-lg-7">
                                                        <label
                                                            className="col-form-label"
                                                            htmlFor="pauseGmp"
                                                        >
                                                            <p className="mb-0">Pause this GMP inspection</p>
                                                            <p className="mb-0 font-weight-light">
                                                                You can restart it at any time.
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className="col-2 col-sm-2 col-lg-5">
                                                        <input
                                                            type="checkbox"
                                                            id="pauseGmp"
                                                            checked={object?.isPause}
                                                            onChange={(e) =>
                                                                handledFieldChanges("isPause", e.target.checked)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* {nextInspectionDate && (
                                                    <div className="form-group row mb-4 light-bg">
                                                        <label className="col-12 col-lg-7 col-form-label">
                                                            Set next inspection date
                                                        </label>
                                                        <div className="col-12 col-lg-3">
                                                            <input
                                                                type="date"
                                                                className="date-selection fs-14"
                                                                min={moment().format(
                                                                    APP_CONSTANTS.DATE_FORMAT_1
                                                                )}
                                                                value={moment(object?.nextInspection).format(
                                                                    APP_CONSTANTS.DATE_FORMAT_1
                                                                )}
                                                                onChange={(e) =>
                                                                    handledFieldChanges(
                                                                        "nextInspection",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            // disabled={object?.isFirstCreated}
                                                            />
                                                            {validationError.nextInspection && (
                                                                <small className="text-danger">
                                                                    please select required field.
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                )} */}

                                                <div className="form-group row mb-4 align-items-center light-bg">
                                                    <div className="col-10 col-sm-10 col-lg-7">
                                                        <label
                                                            className="col-form-label"
                                                            htmlFor="editNotifcationCheck"
                                                        >
                                                            <p className="mb-0">Enable notifications</p>
                                                            <p className="mb-0 font-weight-light">
                                                                These are sent when the GMP inspection is due.
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className="col-2 col-sm-2 col-lg-5">
                                                        <input
                                                            type="checkbox"
                                                            id="editNotifcationCheck"
                                                            checked={object?.isNotification}
                                                            onChange={(e) =>
                                                                handledFieldChanges(
                                                                    "isNotification",
                                                                    e.target.checked
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="float-right">
                                            <button
                                                type="button"
                                                className="cancel mr-2"
                                                onClick={() => closeModal(false)}
                                            >
                                                <i className="far fa-ban" /> Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="btn-send"
                                                onClick={handleSave}
                                            >
                                                <i className="far fa-save" /> Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="modal-content">
                            <div className="modal-body py-5 px-4">
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <h2 className="fs-18 fw-600 color-06 custom_mb_heading">
                                            Add a new GMP inspection
                                        </h2>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row mb-4">
                                                    <label className="col-12 col-lg-7 col-form-label">
                                                        <p className="mb-0">Select the master inspection</p>
                                                        <p className="mb-0 font-weight-light">
                                                            Here's a recap of{" "}
                                                            <span
                                                                style={{
                                                                    textDecoration: "underline",
                                                                    textDecorationColor: "#686868",
                                                                    cursor: "pointer",
                                                                    color: "#686868",
                                                                }}
                                                                onClick={() =>
                                                                    openInNewTab(
                                                                        "/private/system-settings/manage-gmp"
                                                                    )
                                                                }
                                                            >
                                                                what's covered in each inspection
                                                            </span>
                                                            .
                                                        </p>
                                                    </label>
                                                    <div
                                                        className="col-12 col-lg-5"
                                                        style={{ borderRadius: "0px" }}
                                                    >
                                                        <Select
                                                            options={list}
                                                            className="fs-14"
                                                            onChange={(data) => {
                                                                handledFieldChanges("template", data.value);
                                                            }}
                                                        />
                                                        {validationError.template && (
                                                            <small className="text-danger">
                                                                please select required field.
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-4 light-bg">
                                                    <label className="col-12 col-lg-7 col-form-label">
                                                        <p className="mb-0">GMP inspection name</p>
                                                        <p className="mb-0 font-weight-light">
                                                            This is the name that your inspectors will know it
                                                            by.
                                                        </p>
                                                    </label>
                                                    <div className="col-12 col-lg-5">
                                                        <input
                                                            type="text"
                                                            className="form-control fs-14"
                                                            id=""
                                                            maxlength="40"
                                                            placeholder="e.g. My GMP inspection"
                                                            onChange={(e) =>
                                                                handledFieldChanges("gmpName", e.target.value)
                                                            }
                                                        />
                                                        {validationError.gmpName && (
                                                            <small className="text-danger">
                                                                please select required field.
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-4">
                                                    <label className="col-12 col-lg-7 col-form-label">
                                                        <p className="mb-0">Set your frequency</p>
                                                        <p
                                                            className="mb-0 font-weight-light"
                                                            style={{
                                                                textDecoration: "underline",
                                                                textDecorationColor: "#686868",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={frequencytoggle}
                                                        >
                                                            Click here to view the days calculator.
                                                        </p>
                                                    </label>
                                                    <div className="col-12 col-lg-3">
                                                        <div className="d-flex align-items-center">
                                                            <input
                                                                type="number"
                                                                className="form-control fs-14"
                                                                id=""
                                                                placeholder="e.g. 30"
                                                                min="1"
                                                                onKeyDown={(e) =>
                                                                    (e.keyCode === 69 ||
                                                                        e.keyCode === 190 ||
                                                                        e.keyCode === 107 ||
                                                                        e.keyCode === 189 ||
                                                                        e.keyCode === 110 ||
                                                                        e.keyCode === 109) &&
                                                                    e.preventDefault()
                                                                }
                                                                onChange={(e) =>
                                                                    handledFieldChanges(
                                                                        "frequency",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                            <p className="mb-0 pl-2">days</p>
                                                        </div>
                                                        {validationError.frequency && (
                                                            <small className="text-danger">
                                                                please select required field.
                                                            </small>
                                                        )}
                                                        <br />
                                                        {negativeFrequency && (
                                                            <small className="text-danger">
                                                                value should be greater than 1 and less than
                                                                365, and there should be no decimal number
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-4 light-bg">
                                                    <label className="col-12 col-lg-7 col-form-label">
                                                        Date of the first inspection
                                                    </label>
                                                    <div className="col-12 col-lg-3">
                                                        <input
                                                            type="date"
                                                            className="date-selection fs-14"
                                                            min={moment().format(APP_CONSTANTS.DATE_FORMAT_1)}
                                                            onChange={(e) =>
                                                                handledFieldChanges(
                                                                    "inspectDate",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        {validationError.inspectDate && (
                                                            <small className="text-danger">
                                                                please select required field.
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-4 align-items-center">
                                                    <div className="col-10 col-sm-10 col-lg-7">
                                                        <label
                                                            className="col-form-label"
                                                            htmlFor="notifcationCheck"
                                                        >
                                                            <p className="mb-0">Enable notifications</p>
                                                            <p className="mb-0 font-weight-light">
                                                                These are sent when the GMP inspection is due.
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className="col-2 col-sm-2 col-lg-5">
                                                        <input
                                                            type="checkbox"
                                                            id="notifcationCheck"
                                                            onChange={(e) =>
                                                                handledFieldChanges(
                                                                    "isNotification",
                                                                    e.target.checked
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="float-right">
                                            <button
                                                type="button"
                                                className="cancel mr-2"
                                                onClick={() => closeModal(false)}
                                            >
                                                <i className="far fa-ban" /> Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="btn-send"
                                                onClick={handleSave}
                                            >
                                                <i className="far fa-save" /> Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </section>
    );
};

export default GmpInspectionModal;
