import { USER_ACTION_CONSTANTS } from "../constants";
import session from "../../services/session";
// REDUCER
const reducer = (
    state = {
        object: {},
        createUser: JSON.parse(
            JSON.stringify(USER_ACTION_CONSTANTS.CREATE_OBJECT())
        ),
        editUser: {},
        error: {},
        list: [],
        userDropdown: [],
        usersByRole: {},
        success: false,
        upgrade: {},
        org: {},
    },
    { type, payload }
) => {
    if (
        (!state || !state?._id) &&
        session.getUser() &&
        session.getUser() !== "undefined"
    ) {
        state = { ...state, object: session.getUser() };
    }
    switch (type) {
        case USER_ACTION_CONSTANTS.SIGN_IN:
            return { ...state, object: payload };
        case USER_ACTION_CONSTANTS.SIGN_IN_SUCCESS:
            return { ...state, object: payload, error: {} };
        case USER_ACTION_CONSTANTS.SIGN_IN_ERROR:
            return { ...state, error: payload };
        case USER_ACTION_CONSTANTS.SIGN_OUT:
            return { ...state, object: payload };
        case USER_ACTION_CONSTANTS.SIGN_OUT_SUCCESS:
            return { ...state, object: payload, error: {} };
        case USER_ACTION_CONSTANTS.SIGN_OUT_ERROR:
            return { ...state, error: payload };
        case USER_ACTION_CONSTANTS.UPDATE_USER:
            return { ...state, editUser: payload };
        case USER_ACTION_CONSTANTS.EDIT_PROFILE:
            return { ...state, object: payload };
        case USER_ACTION_CONSTANTS.EDIT_PROFILE_SUCCESS:
            return { ...state, success: true };
        case USER_ACTION_CONSTANTS.EDIT_PROFILE_ERROR:
            return { ...state, error: { ...payload, show: true } };
        case USER_ACTION_CONSTANTS.UPDATE_USER_SUCCESS:
            return { ...state, editUser: payload, success: true };
        case USER_ACTION_CONSTANTS.UPDATE_USER_ERROR:
            return { ...state, error: payload };
        case USER_ACTION_CONSTANTS.EDIT_USER_OBJECT:
            return { ...state, editUser: payload };
        case USER_ACTION_CONSTANTS.UPDATE_CREATE_USER_OBJECT:
            return { ...state, createUser: payload };
        case USER_ACTION_CONSTANTS.CREATE_USER:
            return { ...state, createUser: payload };
        case USER_ACTION_CONSTANTS.CREATE_USER_SUCCESS:
            return { ...state, createUser: payload, success: true };
        case USER_ACTION_CONSTANTS.CREATE_USER_ERROR:
            return { ...state, error: { ...payload, show: true } };
        case USER_ACTION_CONSTANTS.GET_USER_BY_ID:
            return {
                ...state,
                payload,
            };
        case USER_ACTION_CONSTANTS.GET_USER_BY_ID_SUCCESS:
            return {
                ...state,
                payload,
            };
        case USER_ACTION_CONSTANTS.GET_USER_BY_ID_ERROR:
            return {
                ...state,
                error: payload,
            };
        case USER_ACTION_CONSTANTS.GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                list: payload,
            };
        case USER_ACTION_CONSTANTS.GET_ALL_USERS_FOR_DROPDOWN:
            return {
                ...state,
                userDropdown: payload,
            };
        case USER_ACTION_CONSTANTS.GET_ALL_USERS_ERROR:
            return {
                ...state,
                error: payload,
            };
        case USER_ACTION_CONSTANTS.GET_USERS_BY_ROLE_SUCCESS:
            return {
                ...state,
                usersByRole: payload,
            };
        case USER_ACTION_CONSTANTS.GET_USERS_BY_ROLE_ERROR:
            return {
                ...state,
                error: payload,
            };
        case USER_ACTION_CONSTANTS.TOGGLE_USER_SUCCESS_MODAL:
            return {
                ...state,
                success: payload,
            };
        case USER_ACTION_CONSTANTS.TOGGLE_RESEND_PASSWORD_SUCCESS_MODAL:
            return {
                ...state,
                resendPassword: payload,
            };
        case USER_ACTION_CONSTANTS.TOGGLE_USER_ERROR_MODAL:
            return {
                ...state,
                error: { show: false },
            };
        case USER_ACTION_CONSTANTS.TOGGLE_USER_ERROR_MODAL:
            return {
                ...state,
                resendPasswordError: { show: false },
            };
        case USER_ACTION_CONSTANTS.USER_UPGRADE:
            return {
                ...state,
                upgrade: payload,
            };
        case USER_ACTION_CONSTANTS.SET_ORG_DATA:
            return {
                ...state,
                org: payload,
            };
        default:
            return state;
    }
};

export default reducer;
