import React from "react";
import BackIcon from "../../Assets/images/back-arrow.png";
import { useNavigate } from "react-router-dom";
import CompA from "./CompA";
import CompB from "./CompB";
import CompC from "./CompC";
import CompD from "./CompD";
import CompE from "./CompE";
import CompF from "./CompF";
import CompG from "./CompG";
import CompH from "./CompH";
import CompI from "./CompI";
import CompJ from "./CompJ";
import CompK from "./CompK";
import CompL from "./CompL";
import CompM from "./CompM";
import CompN from "./CompN";
import CompO from "./CompO";
import CompP from "./CompP";
import CompQ from "./CompQ";
import CompR from "./CompR";
import CompS from "./CompS";
import CompT from "./CompT";
import CompU from "./CompU";
import CompV from "./CompV";
import CompW from "./CompW";
import CompZ from "./CompZ";
import ScrollButton from "./ScrollButton";

const Glossary = () => {
    const alphabets = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];
    let navigate = useNavigate();
    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-file-alt" /> Glossary
                        </h2>
                    </div>
                    <div className="col-12 col-lg-2">
                        <span
                            onClick={() => navigate(-1)}
                            className="back-to-pre fs-14 fw-300 py-4 color-04 float-right cursor"
                        >
                            <u>Back to previous page</u>
                            <img src={BackIcon} width="14px" alt="Back to Pre" />
                        </span>
                    </div>
                </div>
                <div className="glossary">
                    <nav className="table-responsive mb-2">
                        <ul className="pagination justify-content-center mt-3">
                            {alphabets.map((elem) => (
                                <li className="page-item">
                                    <a className="page-link" href={"#" + elem.toLowerCase()}>
                                        {elem}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <CompA />
                    <CompB />
                    <CompC />
                    <CompD />
                    <CompE />
                    <CompF />
                    <CompG />
                    <CompH />
                    <CompI />
                    <CompJ />
                    <CompK />
                    <CompL />
                    <CompM />
                    <CompN />
                    <CompO />
                    <CompP />
                    <CompQ />
                    <CompR />
                    <CompS />
                    <CompT />
                    <CompU />
                    <CompV />
                    <CompW />
                    <CompZ />
                    <ScrollButton />
                </div>
            </div>
        </section>
    );
};

export default Glossary;
