import React from 'react';

const CompN = () => {
    return (
        <>
            <h2 id="n">N</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading199">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse199" aria-expanded="true"
                                    aria-controls="collapse199">
                                NDA
                            </button>
                        </h2>
                    </div>
                    <div id="collapse199" className="collapse show" aria-labelledby="heading199"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Non-disclosure agreement
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading200">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse200" aria-expanded="false"
                                    aria-controls="collapse200">
                                Net weight
                            </button>
                        </h2>
                    </div>
                    <div id="collapse200" className="collapse" aria-labelledby="heading200"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The weight without anything added. Typically, this is the weight of the product before it's packed.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading201">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse201" aria-expanded="false"
                                    aria-controls="collapse201">
                                Non-compliance
                            </button>
                        </h2>
                    </div>
                    <div id="collapse201" className="collapse" aria-labelledby="heading201"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that doesn't meet the required standard. Also known as; non-conformance, non-conformity
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading202">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse202" aria-expanded="false"
                                    aria-controls="collapse202">
                                Non-conformance
                            </button>
                        </h2>
                    </div>
                    <div id="collapse202" className="collapse" aria-labelledby="heading202"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that doesn't meet the required standard. Also known as; non-compliance, non-conformity
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading203">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse203" aria-expanded="false"
                                    aria-controls="collapse203">
                                Non-conforming materials
                            </button>
                        </h2>
                    </div>
                    <div id="collapse203" className="collapse" aria-labelledby="heading203"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Non-conforming materials are those that are out-of-specification. Out-of-specification and non-conforming mean the same thing.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading204">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse204" aria-expanded="false"
                                    aria-controls="collapse204">
                                Non-conformity
                            </button>
                        </h2>
                    </div>
                    <div id="collapse204" className="collapse" aria-labelledby="heading204"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that doesn't meet the required standard. Also known as; non-conformance, non-compliance
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading205">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse205" aria-expanded="false"
                                    aria-controls="collapse205">
                                Non-product area
                            </button>
                        </h2>
                    </div>
                    <div id="collapse205" className="collapse" aria-labelledby="heading205"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An area where materials are not stored or handled, such as offices or other facility areas.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading206">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse206" aria-expanded="false"
                                    aria-controls="collapse206">
                                NPD
                            </button>
                        </h2>
                    </div>
                    <div id="collapse206" className="collapse" aria-labelledby="heading206"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            New product development
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
            </div>
        </>
    );
};

export default CompN;