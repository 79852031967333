import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import moneyImg from "../../Assets/images/Money.png";
import BackIcon from "../../Assets/images/back-arrow.png";
import request from "../../services/request";
import { STRIPE } from "../../Routes/Api";
import utils from "../../utils";
import { useStripe } from "@stripe/react-stripe-js";

const SwitchNewPlan = () => {
    const { state } = useLocation();
    let navigate = useNavigate();
    const stripe = useStripe();

    const [object, setObject] = useState({ ...state });
    const [loading, setLoading] = useState(false);

    const switchSubs = async () => {
        setLoading(true);
        try {
            let result = await request.post(STRIPE.SWITCH_SUBS_PLAN, state, true);
            if (result?.status) {
                utils.resetOrgDataInSession(result?.data);
                setLoading(false);
                navigate("/private/accounts-summary");
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const SubscribeSubscription = async () => {
        setLoading(true);
        try {
            let result = await request.post(
                STRIPE.SUBSCRIBE_SUBS_PLAN,
                { ...object },
                true
            );
            if (result?.status) {
                if (
                    result?.data?.subs?.latest_invoice?.payment_intent?.status ===
                    "requires_action"
                ) {
                    const { paymentIntent, error } = await stripe?.confirmCardPayment(
                        result?.data?.subs?.latest_invoice?.payment_intent?.client_secret,
                        {
                            payment_method:
                                result?.data?.subs?.latest_invoice?.payment_intent
                                    ?.payment_method,
                        }
                    );
                    if (paymentIntent?.status === "succeeded") {
                        utils.resetOrgDataInSession(result?.data?.orgData);
                        setLoading(false);
                        navigate("/private/accounts-summary");
                    } else if (error) {
                        setLoading(false);
                    }
                } else {
                    utils.resetOrgDataInSession(result?.data?.orgData);
                    setLoading(false);
                    navigate("/private/accounts-summary");
                }
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const getTax = async () => {
        try {
            let result = await request.get(STRIPE.GET_TAX_OBJECTS, true);
            if (result?.status && result?.data?.length) {
                var tax = result?.data?.find(
                    (data) => data?.country === "GB" && data?.active
                );
                if (tax) {
                    var { payAbleAmount, taxAbleAmount } =
                        utils.calculateTotalAmountWithTax(
                            object?.plan?.amount,
                            tax,
                            tax?.percentage
                        );
                    tax.taxAbleAmount = taxAbleAmount;
                } else {
                    var payAbleAmount = object?.plan?.amount;
                }
            }
            setObject((old) => {
                return {
                    ...old,
                    appliedTax: tax || null,
                    payAbleAmount: payAbleAmount || object?.plan?.amount,
                };
            });
        } catch (e) { }
    };

    useEffect(() => {
        setObject({ ...state });
        getTax();
    }, [state]);

    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-credit-card" /> Accounts summary
                        </h2>
                    </div>
                    <div className="col-12 col-lg-2" onClick={() => navigate(-1)}>
                        <NavLink
                            to={"#"}
                            className="back-to-pre fs-14 fw-300 py-4 color-04 float-right"
                        >
                            <u>Back to previous page</u>
                            <img src={BackIcon} width="14px" alt="Back to Pre" />
                        </NavLink>
                    </div>
                </div>
                <div className="current-plan">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <h2 className="fs-18 fw-600 color-06">Confirm your new plan</h2>
                            <div className="plans">
                                <h2>Changing to</h2>
                                <div className="plan-divider">
                                    <dl className="row align-items-center">
                                        <dt className="col-sm-9">
                                            <h2>{object?.plan?.product?.name}</h2>
                                            <p>What you'll pay monthly</p>
                                        </dt>
                                        <dd className="col-sm-3">
                                            £{utils.getFormattedValue(object?.plan?.amount)}
                                        </dd>
                                    </dl>
                                </div>
                                <div className="plan-divider">
                                    <dl className="row align-items-center">
                                        <dt className="col-sm-9">
                                            {!!object?.appliedTax && (
                                                <p>
                                                    {object?.appliedTax?.display_name}{" "}
                                                    {object?.appliedTax?.percentage}%
                                                </p>
                                            )}
                                        </dt>
                                        {!!object?.appliedTax && (
                                            <dd className="col-sm-3">
                                                £
                                                {utils.getTwoDecimalValue(
                                                    object?.appliedTax?.taxAbleAmount
                                                )}
                                            </dd>
                                        )}
                                        <dt className="col-sm-9">
                                            <h2>Amount due today</h2>
                                            <p>Payment method</p>
                                        </dt>
                                        <dd className="col-sm-3">
                                            {!!object?.payAbleAmount && (
                                                <p>£{utils.getFormattedValue(object?.payAbleAmount)}</p>
                                            )}
                                            {object?.payment_method?.type === "card" && (
                                                <p>
                                                    {`${object?.payment_method?.card?.brand}`}****{" "}
                                                    {`${object?.payment_method?.card?.last4}`}
                                                </p>
                                            )}
                                            {object?.payment_method?.type === "bacs_debit" && (
                                                <p>
                                                    {`${object?.payment_method?.type}`}****
                                                    {`${object?.payment_method?.bacs_debit?.last4}`}
                                                </p>
                                            )}
                                        </dd>
                                    </dl>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mt-4">
                                    {!utils.checkCanceledSubscription() && (
                                        <button
                                            className="btn-update-plan d-lg-block mb-2 mr-2"
                                            onClick={switchSubs}
                                            disabled={loading}
                                        >
                                            Confirm{" "}
                                            {loading && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    style={{ marginLeft: "2px" }}
                                                ></div>
                                            )}
                                        </button>
                                    )}
                                    {utils.checkCanceledSubscription() && (
                                        <button
                                            className="btn-update-plan d-lg-block mb-2 mr-2"
                                            onClick={SubscribeSubscription}
                                            disabled={loading}
                                        >
                                            Confirm{" "}
                                            {loading && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    style={{ marginLeft: "2px" }}
                                                ></div>
                                            )}
                                        </button>
                                    )}
                                    <button
                                        className="btn-cancel-plan"
                                        onClick={() => navigate(-1)}
                                        disabled={loading}
                                    >
                                        Go back
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-8">
                            <img src={moneyImg} className="img-fluid" alt="Money Icon" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SwitchNewPlan;
