import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import BackIcon from "../../../Assets/images/back-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { changeTabState } from "../../../store/actions/tabState";
import ProComplete from "./ProComplete";
import { useParams } from "react-router";
import {
    getProByIdAPi,
    updateCompleteProObject,
} from "../../../store/actions/pro";
import { generateSequence } from "../../../utils/referenceId";

const ProRef = (props) => {
    let params = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const tabState = useSelector((state) => state?.tabState);
    const { comp_pro_obj } = useSelector((state) => state?.pro);

    const handleChangeTab = (tabId) => {
        dispatch(changeTabState(tabId));
    };

    useEffect(() => {
        dispatch(changeTabState(0));
        if (state?.pro && params.proId === state?.pro?._id)
            dispatch(updateCompleteProObject(state?.pro));
        else if (params?.proId) {
            dispatch(getProByIdAPi(params.proId, true));
        }
        setTimeout(() => {
            dispatch(changeTabState(1));
        }, 0);
    }, []);

    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-bullhorn" /> Reference:{" "}
                            {`${comp_pro_obj?.type}-${generateSequence(comp_pro_obj?.id)}`}
                        </h2>
                    </div>
                    <div className="col-12 col-lg-2">
                        <NavLink
                            to={"#"}
                            className="back-to-pre fs-14 fw-300 py-4 color-04 float-right"
                            onClick={() => navigate(-1)}
                        >
                            <u>Back to previous page</u>{" "}
                            <img src={BackIcon} width="14px" alt="Back to Pre" />
                        </NavLink>
                    </div>
                </div>
                <div className="tab-content">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" onClick={() => handleChangeTab(1)}>
                            <a
                                className={`nav-link ${tabState?.tab === 1 ? "active" : ""}`}
                                role="tab"
                            >
                                Complete
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content tab-box">
                        {tabState?.tab === 1 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 1 ? "active" : ""
                                    }`}
                                id="tabs-1"
                                role="tabpanel"
                            >
                                <ProComplete />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProRef;
