import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import BackIcon from "../../Assets/images/back-arrow.png";
import Overview from "./Overview";
import ExportModal from "../Home/TodoLIst/ExportModal";
import { useDispatch, useSelector } from "react-redux";
import { changeTabState } from "../../store/actions/tabState";
import NCs from "./NCs";
import Actions from "./Actions";
import CreateNc from "../Home/ CreateNc";
import CreatePro from "../Home/CreatePro";
import { APP_CONSTANTS } from "../../constants";
import { CSVLink } from "react-csv";
import utils from "../../utils";
import GMPInspections from "./GMPInspections/GMPInspections";
import SystemAudits from "./SystemAudits/SystemAudits";
import RiskAssesment from "./RiskAssesment/RiskAssesment";
import CreateReportModal from "../Modals/CreateReportModal";
import CreateGmpReportModal from "../Modals/CreateGmpReportModal";
import CreateSaReportModal from "../Modals/CreateSaReportModal";
import Kpi from "./KPI/Kpi";
import CreateKpiReportModal from "../Modals/CreateKpiReportModal";
import Trending from "./Trending/Trending";
import ScheduledReports from "./ScheduledReports/ScheduledReports";
import EditReports from "./ScheduledReports/EditReports";
import CreateAudit from "../Home/Audit/CreateAudit";
import CreateGMP from "../Home/GMP/CreateGMP";

const ProgrammeInformation = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tabState = useSelector((state) => state?.tabState);
    const { list, filteredList } = useSelector((state) => state?.nc);
    const { userGMPs, filteredGmpList } = useSelector((state) => state?.gmp);
    const { userSA, filteredSaList } = useSelector((state) => state?.audit);
    const { userRiskAssesment, filteredRiskList } = useSelector(
        (state) => state?.riskAssesment
    );
    const { filteredListActions } = useSelector((state) => state.action);
    const listActions = useSelector((state) => state?.action?.list);
    const logsStandards = useSelector(
        (state) => state?.additionalStandards?.logsStandards
    );

    const [csvExportHeader, setCsvExportHeader] = useState();
    const [fileName, setFileName] = useState();
    const [csvReport, setCsvReport] = useState([]);
    const [planTwo, setPlanTwo] = useState(false);
    const [planThree, setPlanThree] = useState(false);
    const [reportModal, setReportModal] = useState(false);
    const [gmpReportModal, setGmpReportModal] = useState(false);
    const [saReportModal, setSaReportModal] = useState(false);
    const [kpiReportModal, setKpiReportModal] = useState(false);

    const org = JSON.parse(localStorage.getItem("org"));

    const arrangeDataForExport = () => {
        switch (tabState?.tab) {
            case 3:
                let listCSvExportNC = [];
                if (filteredList?.length)
                    listCSvExportNC = JSON.parse(JSON.stringify(filteredList));
                else if (list?.length) {
                    listCSvExportNC = JSON.parse(JSON.stringify(list));
                }
                listCSvExportNC = listCSvExportNC.map((obj) => {
                    return APP_CONSTANTS.NC_EXPORT_DATA_SET(obj);
                });
                setCsvReport(listCSvExportNC);
                break;
            case 5:
                let listCSvExportGMP = [];
                if (filteredGmpList?.length)
                    listCSvExportGMP = JSON.parse(JSON.stringify(filteredGmpList));
                else if (userGMPs?.length) {
                    listCSvExportGMP = JSON.parse(JSON.stringify(userGMPs));
                }
                listCSvExportGMP = listCSvExportGMP.map((obj) => {
                    return APP_CONSTANTS.GMP_EXPORT_DATA_SET(obj);
                });
                setCsvReport(listCSvExportGMP);
                break;
            case 7:
                let listCSvExportSA = [];
                if (filteredSaList?.length)
                    listCSvExportSA = JSON.parse(JSON.stringify(filteredSaList));
                else if (userSA?.length) {
                    listCSvExportSA = JSON.parse(JSON.stringify(userSA));
                }
                listCSvExportSA = listCSvExportSA.map((obj) => {
                    return APP_CONSTANTS.SA_EXPORT_DATA_SET(obj);
                });
                setCsvReport(listCSvExportSA);
                break;
            case 8:
                let listCSvExportRisk = [];
                if (filteredRiskList?.length)
                    listCSvExportRisk = JSON.parse(JSON.stringify(filteredRiskList));
                else if (userRiskAssesment?.length) {
                    listCSvExportRisk = JSON.parse(JSON.stringify(userRiskAssesment));
                }
                listCSvExportRisk = listCSvExportRisk.map((obj) => {
                    return APP_CONSTANTS.RISK_EXPORT_DATA_SET(obj);
                });
                setCsvReport(listCSvExportRisk);
                break;
            case 1:
                let listCSvExportLogStandard = [];
                listCSvExportLogStandard = JSON.parse(JSON.stringify(logsStandards));
                listCSvExportLogStandard = listCSvExportLogStandard.map((obj) => {
                    return APP_CONSTANTS.LOG_STANDARD_EXPORT_DATA_SET(obj);
                });
                setCsvReport(listCSvExportLogStandard);
                break;
            case 4:
                let listCSvExportActions = [];
                if (filteredListActions?.length)
                    listCSvExportActions = JSON.parse(
                        JSON.stringify(filteredListActions)
                    );
                else if (listActions?.length) {
                    listCSvExportActions = JSON.parse(JSON.stringify(listActions));
                }
                listCSvExportActions = listCSvExportActions.map((obj) => {
                    return APP_CONSTANTS.ACTIONS_EXPORT_DATA_SET(obj);
                });
                setCsvReport(listCSvExportActions);
                break;
            default:
        }
    };

    const showReportModal = () => {
        if (tabState?.tab === 3) {
            setReportModal(true);
        } else if (tabState?.tab === 5) {
            setGmpReportModal(true);
        } else if (tabState?.tab === 7) {
            setSaReportModal(true);
        } else if (tabState?.tab === 9) {
            setKpiReportModal(true);
        }
    };

    const handleChangeTab = (tabId) => {
        dispatch(changeTabState(tabId));
    };

    useEffect(() => {
        dispatch(changeTabState(1));
    }, []);

    useEffect(() => {
        if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_2) {
            setPlanTwo(true);
        } else if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_3) {
            setPlanTwo(true);
            setPlanThree(true);
        } else if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_4) {
            setPlanTwo(true);
            setPlanThree(true);
        }
    }, []);

    useEffect(() => {
        dispatch(changeTabState(params?.tabId ? parseInt(params?.tabId) : 1));
    }, []);

    useEffect(() => {
        switch (tabState.tab) {
            case 3:
                setFileName(() => {
                    return "nc.csv";
                });
                setCsvExportHeader(() => {
                    return APP_CONSTANTS.NCS_EXPORT_HEADERS;
                });
                break;
            case 5:
                setFileName(() => {
                    return "gmp_inspection.csv";
                });
                setCsvExportHeader(() => {
                    return APP_CONSTANTS.GMP_INSPECTIONS_HEADER;
                });
                break;
            case 7:
                setFileName(() => {
                    return "system_audit.csv";
                });
                setCsvExportHeader(() => {
                    return APP_CONSTANTS.SA_HEADER;
                });
                break;
            case 8:
                setFileName(() => {
                    return "risk-assesment.csv";
                });
                setCsvExportHeader(() => {
                    return APP_CONSTANTS.RISK_ASSESMENT_HEADER;
                });
                break;
            case 1:
                setFileName(() => {
                    return "log_standard.csv";
                });
                setCsvExportHeader(() => {
                    return APP_CONSTANTS.LOG_STANDARD_HEADER;
                });
                break;
            case 4:
                setFileName(() => {
                    return "actions.csv";
                });
                setCsvExportHeader(() => {
                    return APP_CONSTANTS.ACTIONS_EXPORT_HEADER;
                });
                break;
            default:
        }
    }, [tabState]);

    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-info" /> Programme information
                        </h2>
                    </div>
                    <div className="col-12 col-lg-2">
                        <NavLink
                            to={"#"}
                            className="back-to-pre fs-14 fw-300 py-4 color-04 float-right"
                            onClick={() => navigate(-1)}
                        >
                            <u>Back to previous page</u>{" "}
                            <img src={BackIcon} width="14px" alt="Back to Pre" />
                        </NavLink>
                    </div>
                </div>
                <div className="tab-content">
                    {tabState?.tab === 9 && (
                        <button
                            className="btn btn-export float-right mt-0"
                            onClick={showReportModal}
                        >
                            <i className="far fa-file-export" /> Export
                        </button>
                    )}
                    {(tabState?.tab === 1 ||
                        tabState?.tab === 3 ||
                        tabState?.tab === 4 ||
                        tabState?.tab === 5 ||
                        tabState?.tab === 7 ||
                        tabState?.tab === 8) && (
                            <CSVLink
                                data={csvReport}
                                headers={csvExportHeader}
                                filename={fileName}
                                className={"btn btn-export float-right mt-0"}
                                asyncOnClick={true}
                                onClick={(event, done) => {
                                    arrangeDataForExport();
                                    done();
                                }}
                            >
                                <i className="far fa-file-export" /> Export
                            </CSVLink>
                        )}

                    {(tabState?.tab === 3 ||
                        tabState?.tab === 5 ||
                        tabState?.tab === 7) && (
                            <button
                                className="btn btn-export float-right mt-0 mr-1"
                                onClick={showReportModal}
                            >
                                <i className="far fa-chart-pie"></i> Create report
                            </button>
                        )}
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" onClick={() => handleChangeTab(1)}>
                            <a
                                className={`nav-link ${tabState?.tab === 1 ? "active" : ""}`}
                                role="tab"
                            >
                                Overview
                            </a>
                        </li>

                        {
                            <li className="nav-item" onClick={() => handleChangeTab(3)}>
                                <a
                                    className={`nav-link ${tabState?.tab === 3 ? "active" : ""}`}
                                    role="tab"
                                >
                                    NCs
                                </a>
                            </li>
                        }
                        {utils.checkPermissions(APP_CONSTANTS.PERMISSION_CONST.CREATE_NC) &&
                            tabState?.tab === 2 && (
                                <li className="nav-item" onClick={() => handleChangeTab(2)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 2 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Edit NC
                                    </a>
                                </li>
                            )}
                        <li className="nav-item" onClick={() => handleChangeTab(4)}>
                            <a
                                className={`nav-link ${tabState?.tab === 4 ? "active" : ""}`}
                                role="tab"
                            >
                                Actions
                            </a>
                        </li>
                        {utils.checkPermissions(
                            APP_CONSTANTS.PERMISSION_CONST.CREATE_PRO
                        ) &&
                            tabState?.tab === 6 && (
                                <li className="nav-item" onClick={() => handleChangeTab(6)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 6 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Edit Action
                                    </a>
                                </li>
                            )}
                        {planTwo && (
                            <li className="nav-item" onClick={() => handleChangeTab(5)}>
                                <a
                                    className={`nav-link ${tabState?.tab === 5 ? "active" : ""}`}
                                    role="tab"
                                >
                                    GMP Inspections
                                </a>
                            </li>
                        )}
                        {planTwo && tabState?.tab === 14 && (
                            <li className="nav-item" onClick={() => handleChangeTab(14)}>
                                <a
                                    className={`nav-link ${tabState?.tab === 14 ? "active" : ""}`}
                                    role="tab"
                                >
                                    Edit GMP
                                </a>
                            </li>
                        )}
                        {planThree && (
                            <li className="nav-item" onClick={() => handleChangeTab(7)}>
                                <a
                                    className={`nav-link ${tabState?.tab === 7 ? "active" : ""}`}
                                    role="tab"
                                >
                                    System Audits
                                </a>
                            </li>
                        )}
                        {planThree && tabState?.tab === 13 && (
                            <li className="nav-item" onClick={() => handleChangeTab(13)}>
                                <a
                                    className={`nav-link ${tabState?.tab === 13 ? "active" : ""}`}
                                    role="tab"
                                >
                                    Edit Audit
                                </a>
                            </li>
                        )}
                        {planThree && (
                            <li className="nav-item" onClick={() => handleChangeTab(8)}>
                                <a
                                    className={`nav-link ${tabState?.tab === 8 ? "active" : ""}`}
                                    role="tab"
                                >
                                    Risk Assessment
                                </a>
                            </li>
                        )}
                        <li className="nav-item" onClick={() => handleChangeTab(9)}>
                            <a
                                className={`nav-link ${tabState?.tab === 9 ? "active" : ""}`}
                                role="tab"
                            >
                                KPI
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => handleChangeTab(11)}>
                            <a
                                className={`nav-link ${tabState?.tab === 11 ? "active" : ""}`}
                                role="tab"
                            >
                                Scheduled reports
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => handleChangeTab(10)}>
                            <a
                                className={`nav-link ${tabState?.tab === 10 ? "active" : ""}`}
                                role="tab"
                            >
                                Trending
                            </a>
                        </li>
                        {tabState?.tab === 12 && (
                            <li className="nav-item" onClick={() => handleChangeTab(12)}>
                                <a
                                    className={`nav-link ${tabState?.tab === 12 ? "active" : ""}`}
                                    role="tab"
                                >
                                    Edit reports
                                </a>
                            </li>
                        )}
                    </ul>

                    <div className="tab-content tab-box">
                        {tabState?.tab === 1 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 1 ? "active" : ""
                                    }`}
                                id="tabs-1"
                                role="tabpanel"
                            >
                                <Overview />
                            </div>
                        )}
                        {tabState?.tab === 3 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 3 ? "active" : ""
                                    }`}
                                id="tabs-3"
                                role="tabpanel"
                            >
                                <NCs />
                            </div>
                        )}
                        {tabState?.tab === 2 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 2 ? "active" : ""
                                    }`}
                                id="tabs-2"
                                role="tabpanel"
                            >
                                <CreateNc />
                            </div>
                        )}
                        {tabState?.tab === 4 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 4 ? "active" : ""
                                    }`}
                                id="tabs-4"
                                role="tabpanel"
                            >
                                <Actions />
                            </div>
                        )}
                        {tabState?.tab === 6 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 6 ? "active" : ""
                                    }`}
                                id="tabs-6"
                                role="tabpanel"
                            >
                                <CreatePro />
                            </div>
                        )}
                        {tabState?.tab === 5 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 5 ? "active" : ""
                                    }`}
                                id="tabs-5"
                                role="tabpanel"
                            >
                                <GMPInspections />
                            </div>
                        )}
                        {tabState?.tab === 14 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 14 ? "active" : ""
                                    }`}
                                id="tabs-14"
                                role="tabpanel"
                            >
                                <CreateGMP />
                            </div>
                        )}
                        {tabState?.tab === 7 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 7 ? "active" : ""
                                    }`}
                                id="tabs-7"
                                role="tabpanel"
                            >
                                <SystemAudits />
                            </div>
                        )}
                        {tabState?.tab === 13 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 13 ? "active" : ""
                                    }`}
                                id="tabs-13"
                                role="tabpanel"
                            >
                                <CreateAudit />
                            </div>
                        )}
                        {tabState?.tab === 8 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 8 ? "active" : ""
                                    }`}
                                id="tabs-8"
                                role="tabpanel"
                            >
                                <RiskAssesment />
                            </div>
                        )}
                        {tabState?.tab === 9 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 9 ? "active" : ""
                                    }`}
                                id="tabs-9"
                                role="tabpanel"
                            >
                                <Kpi />
                            </div>
                        )}
                        {tabState?.tab === 11 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 11 ? "active" : ""
                                    }`}
                                id="tabs-11"
                                role="tabpanel"
                            >
                                <ScheduledReports />
                            </div>
                        )}
                        {tabState?.tab === 10 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 10 ? "active" : ""
                                    }`}
                                id="tabs-10"
                                role="tabpanel"
                            >
                                <Trending />
                            </div>
                        )}
                        {tabState?.tab === 12 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 12 ? "active" : ""
                                    }`}
                                id="tabs-12"
                                role="tabpanel"
                            >
                                <EditReports />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {reportModal && (
                <CreateReportModal
                    standards={logsStandards}
                    org={org?._id}
                    closeModal={setReportModal}
                />
            )}
            {gmpReportModal && (
                <CreateGmpReportModal
                    standards={logsStandards}
                    org={org?._id}
                    closeModal={setGmpReportModal}
                />
            )}

            {saReportModal && (
                <CreateSaReportModal
                    standards={logsStandards}
                    org={org?._id}
                    closeModal={setSaReportModal}
                />
            )}
            <ExportModal />

            {kpiReportModal && (
                <CreateKpiReportModal closeModal={setKpiReportModal} org={org} />
            )}
        </section>
    );
};

export default ProgrammeInformation;
