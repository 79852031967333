import React from 'react';

const CompO = () => {
    return (
        <>
            <h2 id="o">O</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading207">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse207" aria-expanded="true"
                                    aria-controls="collapse207">
                                Objective
                            </button>
                        </h2>
                    </div>
                    <div id="collapse207" className="collapse show" aria-labelledby="heading207"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where something isn't open to interpretation, so is based on facts. Objective assessment means that the result doesn't need to be interpreted, as the facts define the end the result.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading208">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse208" aria-expanded="false"
                                    aria-controls="collapse208">
                                Objective*
                            </button>
                        </h2>
                    </div>
                    <div id="collapse208" className="collapse" aria-labelledby="heading208"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When something is objective, it's based on facts. It's not based on opinion. Variations; subjective
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading209">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse209" aria-expanded="false"
                                    aria-controls="collapse209">
                                Objectives
                            </button>
                        </h2>
                    </div>
                    <div id="collapse209" className="collapse" aria-labelledby="heading209"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Objectives are targets that need to be achieved. Objectives set by management are targets that the business want to achieve. Objectives include the target and also the actions that need to be completed, so that the target is achieved. The targets are sometimes called KPIs (key performance indicators).
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading210">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse210" aria-expanded="false"
                                    aria-controls="collapse210">
                                Objectives
                            </button>
                        </h2>
                    </div>
                    <div id="collapse210" className="collapse" aria-labelledby="heading210"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An objective is a planned goal. The objective should have SMART actions associated with it, so ensure that it is achieved.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading211">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse211" aria-expanded="false"
                                    aria-controls="collapse211">
                                Obsolete
                            </button>
                        </h2>
                    </div>
                    <div id="collapse211" className="collapse" aria-labelledby="heading211"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that is no longer needed.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading212">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse212" aria-expanded="false"
                                    aria-controls="collapse212">
                                Off-line measurement
                            </button>
                        </h2>
                    </div>
                    <div id="collapse212" className="collapse" aria-labelledby="heading212"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where measurement is taken manually and therefore the frequency of the check is less than in-line or on-line measurements. The material is taken off the line and tested away from the line. Off-line measurement is used where the equipment is kept elsewhere, or where specialist competent staff are needed to carry out the measurement. This means that there is a delay in the results being available, and therefore corrective action can't be taken quickly. For example, microbial analysis.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading213">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse213" aria-expanded="false"
                                    aria-controls="collapse213">
                                One-to-one meetings
                            </button>
                        </h2>
                    </div>
                    <div id="collapse213" className="collapse" aria-labelledby="heading213"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Meetings between a staff member and their manager, to assess the individual's performance against their personal objectives.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading214">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse214" aria-expanded="false"
                                    aria-controls="collapse214">
                                On-line measurement
                            </button>
                        </h2>
                    </div>
                    <div id="collapse214" className="collapse" aria-labelledby="heading214"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where measurement is taken automatically. The frequency of the check may be continuous or at a high frequency, which couldn't be achieved manually. The equipment that takes the measurements is fitted within the processing line, but doesn't come into direct contact with the material. As the measurement is automated the results can be used instantly and automatically, for corrective action. For example, vision systems or sensors that count.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading215">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse215" aria-expanded="false"
                                    aria-controls="collapse215">
                                Open food area
                            </button>
                        </h2>
                    </div>
                    <div id="collapse215" className="collapse" aria-labelledby="heading215"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An area on site where food is open to the environment. Food can be in any open form; ingredients, work-in-progress food or finished product.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading216">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse216" aria-expanded="false"
                                    aria-controls="collapse216">
                                Open food-contact packaging area
                            </button>
                        </h2>
                    </div>
                    <div id="collapse216" className="collapse" aria-labelledby="heading216"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An area on site where food contact packaging is open to the environment. Food-contact packaging can be in any open form; raw materials, work-in-progress product or finished product. See also; food-contact.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading217">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse217" aria-expanded="false"
                                    aria-controls="collapse217">
                                Open non-food contact packaging area
                            </button>
                        </h2>
                    </div>
                    <div id="collapse217" className="collapse" aria-labelledby="heading217"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An area on site where non-food contact packaging is open to the environment. Non-food-contact packaging can be in any open form; raw materials, work-in-progress product or finished product. See also; non-food-contact.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading218">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse218" aria-expanded="false"
                                    aria-controls="collapse218">
                                Open product area
                            </button>
                        </h2>
                    </div>
                    <div id="collapse218" className="collapse" aria-labelledby="heading218"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A collective term for areas where materials are open to the environment. The materials can be in any open form; ingredients, raw materials, work-in-progress materials or finished product.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading219">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse219" aria-expanded="false"
                                    aria-controls="collapse219">
                                OPRP
                            </button>
                        </h2>
                    </div>
                    <div id="collapse219" className="collapse" aria-labelledby="heading219"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Operational PreRequisite Programme. A control that's designed to prevent product contamination or prevent contaminated product from reaching the customer or consumer. Typically used where the process is accredited to FSSC 22000. Also known as a PC (Preventive Control).
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading220">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse220" aria-expanded="false"
                                    aria-controls="collapse220">
                                Organoleptic
                            </button>
                        </h2>
                    </div>
                    <div id="collapse220" className="collapse" aria-labelledby="heading220"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where the attributes of the product are assessed through sensory testing, such as taste, smell, texture etc. Typically, carried out at taste panels.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading221">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse221" aria-expanded="false"
                                    aria-controls="collapse221">
                                Out-of-specification
                            </button>
                        </h2>
                    </div>
                    <div id="collapse221" className="collapse" aria-labelledby="heading221"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            All product or services must meet an agreed specification. Where the product or service doesn't meet this agreed specification, this is known as 'out-of-specification'. Further guidance; out-of-specification results
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading222">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse222" aria-expanded="false"
                                    aria-controls="collapse222">
                                Out-of-specification
                            </button>
                        </h2>
                    </div>
                    <div id="collapse222" className="collapse" aria-labelledby="heading222"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A term used to describe product when it doesn't meet the requirements of the specification.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading223">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse223" aria-expanded="false"
                                    aria-controls="collapse223">
                                Out-of-specification results
                            </button>
                        </h2>
                    </div>
                    <div id="collapse223" className="collapse" aria-labelledby="heading223"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When checks or testing results do not meet the acceptable parameters set in the specification, they are known as 'out-of-specification results'.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading224">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse224" aria-expanded="false"
                                    aria-controls="collapse224">
                                Own brand
                            </button>
                        </h2>
                    </div>
                    <div id="collapse224" className="collapse" aria-labelledby="heading224"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Products which are packed under a brand which is owned by the company.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading225">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse225" aria-expanded="false"
                                    aria-controls="collapse225">
                                Ownership
                            </button>
                        </h2>
                    </div>
                    <div id="collapse225" className="collapse" aria-labelledby="heading225"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To take ownership of something, means that you look after it, because you believe you own it. Ownership is an important element to the standard. This is because, lack of ownership means that things don't get managed properly. For example, where group provide a document to implement at site, if the site doesn't take ownership of it, it won't get implemented properly and it won't work. Therefore, the right attitude is required, one which ensures that the site, team or individual person takes ownership.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}


            </div>
        </>
    );
};

export default CompO;