import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import BackIcon from "../../Assets/images/back-arrow.png";
import moneyImg from "../../Assets/images/Money.png";
import moment from "moment";
import request from "../../services/request";
import { STRIPE } from "../../Routes/Api";
import utils from "../../utils";

const SwitchPlan = () => {
    const { state } = useLocation();
    let navigate = useNavigate();

    const [object, setObject] = useState({ ...state, plans: [] });

    const getAllActivePlans = async () => {
        try {
            let result = await request.get(STRIPE.GET_ACTIVE_PANS, true);
            if (result.status)
                setObject((old) => {
                    return {
                        ...old,
                        plans: result.data,
                    };
                });
        } catch (e) { }
    };

    const confirmSwitchPlan = (plan) => {
        let state = {
            plan,
            orgId: object?.orgId,
            org: object?.orgId,
            subscription: object?.subscription,
            subs: object?.subs,
            payment_method: object.payment_method,
            stripe: object?.stripe,
        };
        navigate(`/private/confirm-switch-plan`, { state });
    };

    const reactivatePlan = async (obj, plan) => {
        if (obj?.org?.isCancelled && obj?.org?.subscription?.status === "Active") {
            try {
                let result = await request.post(
                    STRIPE.REACTIVATE_PLAN,
                    { subscription: state?.subscription, org: state?.orgId },
                    true
                );
                if (result?.status) {
                    utils.resetOrgDataInSession(result?.data);
                    navigate("/private/accounts-summary");
                }
            } catch (e) { }
        } else if (obj?.subs?.status === "canceled") {
            confirmSwitchPlan(plan);
        }
    };

    useEffect(() => {
        getAllActivePlans();
    }, []);

    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-credit-card" /> Accounts summary
                        </h2>
                    </div>
                    <div className="col-12 col-lg-2" onClick={() => navigate(-1)}>
                        <NavLink
                            to={"#"}
                            className="back-to-pre fs-14 fw-300 py-4 color-04 float-right"
                        >
                            <u>Back to previous page</u>
                            <img src={BackIcon} width="14px" alt="Back to Pre" />
                        </NavLink>
                    </div>
                </div>
                <div className="current-plan">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <h2 className="fs-18 fw-600 color-06">
                                {object?.org?.subscription?.status === "canceled" ||
                                    object?.org?.subscription?.status === "Canceled"
                                    ? "Add Plan"
                                    : "Current Plan"}
                            </h2>
                            {object?.org?.subscription?.status !== "canceled" ||
                                (object?.org?.subscription?.status !== "Canceled" && (
                                    <>
                                        <h3>{object?.product?.name}</h3>
                                        <p>
                                            {`£${utils.getFormattedValue(
                                                object?.subscription?.unit_amount
                                            ) || 0
                                                }` + " per month"}
                                        </p>
                                        <p>
                                            {object?.subs?.status === "canceled" ||
                                                object?.subs?.status === "incomplete_expired" ? (
                                                "Your subscription has been Cancelled"
                                            ) : object?.org?.isCancelled &&
                                                object?.org?.subscription?.status === "Active" ? (
                                                object?.subs?.cancel_at &&
                                                `Your subscription will be cancelled on ${utils?.convertStripeDate(
                                                    object?.subs?.cancel_at
                                                )}`
                                            ) : (
                                                <>
                                                    Your plan renews on{" "}
                                                    {object?.subs?.current_period_end
                                                        ? `${utils.convertStripeDate(
                                                            object?.subs?.current_period_end
                                                        )}`
                                                        : 0}
                                                </>
                                            )}
                                        </p>
                                    </>
                                ))}

                            <div
                                className={`plans ${(object?.org?.subscription?.status === "canceled" ||
                                        object?.org?.subscription?.status === "Canceled") &&
                                    "mt-5"
                                    }`}
                            >
                                <h2>Plans</h2>
                                {object?.plans?.data
                                    ?.filter(
                                        (item) => item?.id !== "price_1LIoJ3Fjc48HW4InxgVqheUm"
                                    )
                                    .map((plan, index) => (
                                        <dl className="row align-items-center" key={index}>
                                            <dt className="col-sm-6">
                                                <h2>{plan.product.name}</h2>
                                                <p>
                                                    {`£${utils.getFormattedValue(plan.amount) || 0}`} per
                                                    month
                                                </p>
                                            </dt>
                                            <dd className="col-sm-6">
                                                {((!object?.org?.isCancelled &&
                                                    object?.org?.subscription?.status === "Active") ||
                                                    (object?.org?.isCancelled &&
                                                        object?.org?.subscription?.status !== "Active")) &&
                                                    plan?.id !== object?.subscription?.price_id && (
                                                        <button
                                                            className="switch-plan-btn"
                                                            onClick={() => confirmSwitchPlan(plan)}
                                                        >
                                                            {object?.org?.subscription?.status ===
                                                                "canceled" ||
                                                                object?.org?.subscription?.status === "Canceled"
                                                                ? "Choose plan"
                                                                : "Switch plan"}
                                                        </button>
                                                    )}
                                                {plan?.id === object?.subscription?.price_id &&
                                                    !utils.checkCanceledSubscription() && (
                                                        <button
                                                            className="current-plan-btn"
                                                            onClick={() => {
                                                                reactivatePlan(object, plan);
                                                            }}
                                                        >
                                                            {object?.org?.isCancelled &&
                                                                object?.org?.subscription?.status === "Active"
                                                                ? "Re-Activate plan"
                                                                : object?.subs?.status === "canceled"
                                                                    ? "Resubscribe plan"
                                                                    : "Current plan"}
                                                        </button>
                                                    )}
                                                {plan?.id === object?.subscription?.price_id &&
                                                    utils.checkCanceledSubscription() && (
                                                        <button
                                                            className="current-plan-btn"
                                                            onClick={() => confirmSwitchPlan(plan)}
                                                        >
                                                            Resubscribe
                                                        </button>
                                                    )}
                                            </dd>
                                        </dl>
                                    ))}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <img src={moneyImg} className="img-fluid" alt="Money Icon" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SwitchPlan;
