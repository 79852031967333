import { TAB_STATE } from "../constants";

export const changeTabState = (payload) => {
    return {
        type: TAB_STATE.CHANGE_TAB,
        payload
    };
};

export const settingsChangeTabState = (payload) => {

    return {
        type: TAB_STATE.SETTINGS_CHANGE_TAB,
        payload
    };
};