import React, { useEffect, useState } from "react";
import moment from "moment";
import { APP_CONSTANTS } from "../../constants";
import { generateSequence } from "../../utils/referenceId";

const initialState = {
    logs: [],
};

const HistoryLog = (props) => {
    const { logArray } = props;
    const [properties, setProperties] = useState({ ...initialState });

    useEffect(() => {
        updateState("logs", logArray);
    }, [props]);

    const updateState = (param, value) => {
        setProperties((prevState) => ({
            ...prevState,
            [param]: value,
        }));
    };

    return (
        <div className="history-logs-modal">
            <div
                className="modal fade"
                id="historyLogsModal"
                tabIndex="-1"
                aria-labelledby="historyLogsModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <h2 className="fs-20 fw-600 color-01">Logs</h2>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <table className="table table-striped table-sm text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">Ref</th>
                                            <th scope="col">Action</th>
                                            <th scope="col">By/From</th>
                                            <th scope="col">To</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {properties?.logs?.map((obj, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {obj?.ref.replace(/[^a-zA-Z]/g, "")}-
                                                    {generateSequence(
                                                        parseInt(obj?.ref.replace(/\D/g, ""))
                                                    )}
                                                </td>
                                                <td>{obj?.log}</td>
                                                <td>{obj?.by?.user_name}</td>
                                                <td>{obj?.to?.user_name}</td>
                                                <td>
                                                    {moment(obj?.date).format(APP_CONSTANTS.DATE_FORMAT)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn-cancel"
                                data-dismiss="modal"
                                data-target="#browseModal"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HistoryLog;
