import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import AnswerEvidenceModal from "../../Modals/AnswerEvidenceModal";
import { getExtension } from "../../../utils/icons";
import {
    gmpQuestionCurrentIndex,
    updateQuestions,
} from "../../../store/actions/gmp";
import ClouseOutAnswerModal from "../../Modals/ClouseOutAnswerModal";
import { APP_CONSTANTS } from "../../../constants";
import SaveModal from "../../Modals/SaveModal";
import ErrorModalGmpAnswer from "../../Modals/ErrorModalGmpAnswer";
import { deleteMediaFromS3Bucket } from "../../../utils/s3";
import RemoveAttachmentModal from "../../Modals/RemoveAttachmentModal";
import request from "../../../services/request";
import { GMP } from "../../../Routes/Api";
import { useNavigate } from "react-router-dom";
import LogsModal from "../../Modals/LogsModal";
import videoIcon from "../../../Assets/images/file-video-regular.svg";
import utils from "../../../utils";

const GmpQuestionsDetail = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        data,
        index,
        gmpId,
        actionPrev,
        showButton,
        inspectors,
        status,
        refresh,
    } = props;
    const user = JSON.parse(localStorage.getItem("user"));

    const [questionData, setQuestionsData] = useState(data);
    const [currIndex, setCurrIndex] = useState(index);
    const [currentData, setCurrentData] = useState(data[currIndex]);
    const [closeOut, setCloseOut] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [notesError, setNotesError] = useState(false);
    const [closeoutNotesError, setCloseoutNotesError] = useState(false);
    const [disable, setDisable] = useState(false);
    const [emptyAns, setEmptyAns] = useState(true);
    const [removeAttachmentModal, setRemoveAttachmentModal] = useState(false);
    const [removeCloseOutAttachmentModal, setRemoveCloseOutAttachmentModal] =
        useState(false);
    const [evidenceData, setEvidenceData] = useState({
        obj: {},
        index: null,
    });
    const [closeOutEvidenceData, setCloseOutEvidenceData] = useState({
        obj: {},
        index: null,
    });
    const [splitQuestion, setSplitQuestion] = useState([]);
    const [loader, setLoader] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    const prevQuestion = () => {
        if (
            currentData?.answers[0]?.no &&
            (!currentData?.notes ||
                currentData?.notes === "" ||
                (currentData?.isCloseOut &&
                    (!currentData?.closeOutNotes || currentData?.closeOutNotes === "")))
        ) {
            if (!currentData?.notes || currentData?.notes === "") {
                setNotesError(true);
            }

            if (
                currentData?.isCloseOut &&
                (!currentData?.closeOutNotes || currentData?.closeOutNotes === "")
            ) {
                setCloseoutNotesError(true);
            }
        } else if (!currentData?.isUpdate) {
            setNotesError(false);
            setCloseoutNotesError(false);
            if (currIndex !== 0) {
                setCurrentData(questionData[currIndex - 1]);
                setCurrIndex(currIndex - 1);
            }
        } else {
            setNotesError(false);
            setCloseoutNotesError(false);
            setLoader(true);
            if (currIndex !== 0) {
                let obj = {
                    gmpId: gmpId,
                    questions: questionData,
                };
                setCurrentData(questionData[currIndex - 1]);
                request.post(GMP.ANSWER_GMP_QUESTION, obj, true, false).then(
                    (res) => {
                        if (res?.status) {
                            setLoader(false);
                            dispatch(
                                updateQuestions(
                                    arrangeData({
                                        gmpId: res?.data?._id,
                                        questions: res?.data?.gmpQuestions,
                                    })
                                )
                            );
                            setQuestionsData(res?.data?.gmpQuestions);
                            setCurrIndex(currIndex - 1);
                        }
                    },
                    (err) => {
                        if (!err?.status) {
                            setError(true);
                            setLoader(false);
                            setTimeout(() => {
                                setError(false);
                            }, 3000);
                        }
                    }
                );
            }
        }
    };

    const nextQuestion = () => {
        if (
            currentData?.answers[0]?.no &&
            (!currentData?.notes ||
                currentData?.notes === "" ||
                (currentData?.isCloseOut &&
                    (!currentData?.closeOutNotes || currentData?.closeOutNotes === "")))
        ) {
            if (!currentData?.notes || currentData?.notes === "") {
                setNotesError(true);
            }

            if (
                currentData?.isCloseOut &&
                (!currentData?.closeOutNotes || currentData?.closeOutNotes === "")
            ) {
                setCloseoutNotesError(true);
            }
        } else if (!currentData?.isUpdate) {
            setNotesError(false);
            setCloseoutNotesError(false);
            if (currIndex !== questionData?.length - 1) {
                setCurrentData(questionData[currIndex + 1]);
                setCurrIndex(currIndex + 1);
            }
        } else {
            setNotesError(false);
            setCloseoutNotesError(false);
            setLoader(true);
            if (currIndex !== questionData?.length - 1) {
                let obj = {
                    gmpId: gmpId,
                    questions: questionData,
                };
                setCurrentData(questionData[currIndex + 1]);
                request.post(GMP.ANSWER_GMP_QUESTION, obj, true, false).then(
                    (res) => {
                        if (res?.status) {
                            setLoader(false);
                            dispatch(
                                updateQuestions(
                                    arrangeData({
                                        gmpId: res?.data?._id,
                                        questions: res?.data?.gmpQuestions,
                                    })
                                )
                            );
                            setQuestionsData(res?.data?.gmpQuestions);
                            setCurrIndex(currIndex + 1);
                        }
                    },
                    (err) => {
                        if (!err?.status) {
                            setError(true);
                            setLoader(false);
                            setTimeout(() => {
                                setError(false);
                            }, 3000);
                        }
                    }
                );
            }
        }
    };

    const handleYes = () => {
        setCloseOut(false);
        setCurrentData((old) => {
            return {
                ...old,
                isUpdate: true,
                ["answers"]: [
                    {
                        yes: true,
                        no: false,
                        na: false,
                    },
                ],
            };
        });
        setNotesError(false);
        setCloseoutNotesError(false);
    };

    const handleNo = () => {
        setCloseOut(true);
        setCurrentData((old) => {
            return {
                ...old,
                isUpdate: true,
                ["answers"]: [
                    {
                        yes: false,
                        no: true,
                        na: false,
                    },
                ],
            };
        });
    };

    const handleNA = () => {
        setCloseOut(false);
        setCurrentData((old) => {
            return {
                ...old,
                isUpdate: true,
                ["answers"]: [
                    {
                        yes: false,
                        no: false,
                        na: true,
                    },
                ],
            };
        });
    };

    const addAttachment = (data) => {
        let tempEvi = currentData?.evidence.concat(data.attachment);
        setCurrentData((old) => {
            return {
                ...old,
                isUpdate: true,
                ["evidence"]: tempEvi,
            };
        });
    };

    const addCloseOutAttachment = (data) => {
        let tempEvi = currentData?.closeOutEvidence.concat(data.attachment);
        setCurrentData((old) => {
            return {
                ...old,
                isUpdate: true,
                ["closeOutEvidence"]: tempEvi,
            };
        });
    };

    const handleNotes = (key, value) => {
        switch (key) {
            case "notes":
                if (value !== undefined || value !== "") {
                    setNotesError(false);
                }
                setCurrentData((old) => {
                    return {
                        ...old,
                        isUpdate: true,
                        ["notes"]: value,
                    };
                });
                break;
            case "closeOut":
                setCurrentData((old) => {
                    return {
                        ...old,
                        isUpdate: true,
                        ["isCloseOut"]: value,
                    };
                });
                break;
            case "closeOutNotes":
                if (value !== undefined || value !== "") {
                    setCloseoutNotesError(false);
                }
                setCurrentData((old) => {
                    return {
                        ...old,
                        isUpdate: true,
                        ["closeOutNotes"]: value,
                    };
                });
                break;
            default:
        }
    };

    const arrangeData = (data) => {
        data?.questions?.map((item) => {
            if (item?.answers?.length) {
                item.isAnswered = true;
            }
        });
        return data;
    };

    const checkAnswer = (data) => {
        let tempBool = false;
        data?.map((item) => {
            if (item?.answers?.length) {
                tempBool = true;
            }
        });
        return tempBool;
    };

    const handleSave = () => {
        setButtonDisable(true);
        if (
            currentData?.answers[0]?.no &&
            (!currentData?.notes ||
                currentData?.notes === "" ||
                (currentData?.isCloseOut &&
                    (!currentData?.closeOutNotes || currentData?.closeOutNotes === "")))
        ) {
            if (!currentData?.notes || currentData?.notes === "") {
                setNotesError(true);
                setButtonDisable(false);
            }

            if (
                currentData?.isCloseOut &&
                (!currentData?.closeOutNotes || currentData?.closeOutNotes === "")
            ) {
                setCloseoutNotesError(true);
                setButtonDisable(false);
            }
        } else {
            let obj = {
                gmpId: gmpId,
                questions: questionData,
            };

            request.post(GMP.ANSWER_GMP_QUESTION, obj, true, false).then(
                (res) => {
                    if (res?.status) {
                        setSuccess(true);
                        showButton(true);
                        setTimeout(() => {
                            setSuccess(false);
                            dispatch(
                                updateQuestions(
                                    arrangeData({
                                        gmpId: res?.data?._id,
                                        questions: res?.data?.gmpQuestions,
                                    })
                                )
                            );
                            setQuestionsData(res?.data?.gmpQuestions);
                            refresh(true);
                            actionPrev();
                        }, 3000);
                    }
                },
                (error) => {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                        setButtonDisable(false);
                    }, 3000);
                }
            );
        }
    };

    const handledFieldChanges = (field, value, custom, index) => {
        switch (field) {
            case "custom_title_evidence":
                currentData.evidence[index].title = value;
                setCurrentData((old) => {
                    return {
                        ...old,
                        isUpdate: true,
                        ["evidence"]: currentData.evidence,
                    };
                });
                break;
            case "custom_title_close_evidence":
                currentData.closeOutEvidence[index].title = value;
                setCurrentData((old) => {
                    return {
                        ...old,
                        isUpdate: true,
                        ["closeOutEvidence"]: currentData.closeOutEvidence,
                    };
                });
                break;
            default:
        }
    };

    // remove evidence
    const removeEvidence = async (obj, index) => {
        if (obj.key) await deleteMediaFromS3Bucket(obj.key);
        let tempArr = currentData?.evidence;
        tempArr.splice(index, 1);
        setCurrentData((prevState) => ({
            ...prevState,
            ["evidence"]: tempArr,
        }));
        setRemoveAttachmentModal(false);
    };

    // remove close out evidence
    const removeCloseOutEvidence = async (obj, index) => {
        if (obj.key) await deleteMediaFromS3Bucket(obj.key);
        let tempArr = currentData?.closeOutEvidence;
        tempArr.splice(index, 1);
        setCurrentData((prevState) => ({
            ...prevState,
            ["closeOutEvidence"]: tempArr,
        }));
        setRemoveCloseOutAttachmentModal(false);
    };

    // display modal before removing evidence
    const toggleRemoveAttachment = (obj, index) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setEvidenceData((old) => {
                return { ...old, ["index"]: index, ["obj"]: obj };
            });
            setRemoveAttachmentModal(true);
        }
    };

    // close remove evidence modal
    const closeModal = () => {
        setRemoveAttachmentModal(false);
    };

    // display modal before removing closeOut evidence
    const toggleRemoveCloseOutAttachment = (obj, index) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setCloseOutEvidenceData((old) => {
                return { ...old, ["index"]: index, ["obj"]: obj };
            });
            setRemoveCloseOutAttachmentModal(true);
        }
    };

    // close remove evidence modal closeOut
    const closeModalCloseOut = () => {
        setRemoveCloseOutAttachmentModal(false);
    };

    const openLinkInNewTab = (link) => {
        window.open(link);
    };

    const isInspector = (user, inspectors, status) => {
        let check = inspectors?.find((item) => item?.value === user?._id);

        if (
            status === "Completed" ||
            status === "Missed" ||
            status === "Partly complete" ||
            status === "Draft" ||
            status === "Partly completed late" ||
            status === "Completed late"
        ) {
            setDisable(() => {
                return true;
            });
            return;
        } else if (user?.permissions?.general?.system_manager) {
            setDisable(() => {
                return false;
            });
            return;
        } else if (check?.value) {
            setDisable(() => {
                return false;
            });
            return;
        } else {
            setDisable(() => {
                return true;
            });
            return;
        }
    };

    const redirectToNc = () => {
        dispatch(gmpQuestionCurrentIndex(currIndex));
        navigate(
            `/private/reference/${currentData?.nc?.org}/${currentData?.nc?._id}/1`
        );
    };

    const anchorString = (string) => {
        const startIndex = string.indexOf("<a");
        if (startIndex !== -1) {
            const endIndex = string.indexOf(">", startIndex);
            const anchorTag = string.substring(startIndex, endIndex + 1);
            const modifiedAnchorTag = anchorTag.replace(
                ">",
                ' class="color-01 mb-2" target="_blank">'
            );
            const modifiedString = string.replace(anchorTag, modifiedAnchorTag);
            return modifiedString;
        }
    };

    useEffect(() => {
        setCurrentData(questionData[currIndex]);
    }, []);

    useEffect(() => {
        let tempArr = questionData;
        tempArr[currIndex] = { ...currentData, lastUpdate: new Date() };
        setQuestionsData(tempArr);
    }, [currentData]);

    useEffect(() => {
        if (currentData?.answers[0]?.no) {
            setCloseOut(true);
        } else {
            setCloseOut(false);
        }
    }, [currIndex, currentData]);

    useEffect(() => {
        isInspector(user, inspectors, status);
    }, []);

    useEffect(() => {
        if (checkAnswer(questionData)) {
            setEmptyAns(false);
        } else {
            setEmptyAns(true);
        }
    }, [questionData, currentData]);

    useEffect(() => {
        let tempCurrData = [];
        tempCurrData = currentData?.question
            ?.split("\n")
            .filter((item) => item !== "");
        setSplitQuestion(tempCurrData);
    }, [currentData]);

    return (
        <>
            <section
                className="todo-list gmp_ref_question"
                style={{ paddingTop: "25px" }}
            >
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="fs-18 fw-600 pt-2">
                            {currentData?.subjectName?.map((item, index) => (
                                <span key={index}>
                                    {(index ? ", " : "") + item.replace(/^\d+/, "")},
                                </span>
                            ))}
                            {currentData?.subtitleIdName?.map((item, index) => (
                                <span key={index}>
                                    {(index ? ", " : "") + item.replace(/^\d+/, "")}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="fs-18 fw-600 pt-2">
                            <button
                                className="btn btn-logs float-right mt-0 mr-1"
                                data-toggle="modal"
                                data-target="#logsModal"
                            >
                                <i className="far fa-history" /> Logs
                            </button>
                        </div>
                    </div>
                </div>

                <div className="gmp_questions gmp_questions_details py-5 px-4">
                    {loader ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status"></div>
                        </div>
                    ) : (
                        <>
                            <div className="row justify-content-between">
                                <div className="col-12 col-lg-6 col-md-5 mb-5">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <h3 className="color-03 fs-18 fw-600">Question</h3>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <h3 className="color-03 fs-18 fw-600">
                                                Clause reference:{" "}
                                                {currentData?.standardRef?.map((item, index) => (
                                                    <span key={index}>{(index ? ", " : "") + item}</span>
                                                ))}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        {splitQuestion?.map((data, index) =>
                                            !data.includes("<a") ? (
                                                <p key={index} className="mb-2">
                                                    {data}
                                                </p>
                                            ) : (
                                                <p
                                                    key={index}
                                                    dangerouslySetInnerHTML={{
                                                        __html: anchorString(data),
                                                    }}
                                                />
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 col-md-5">
                                    <h3 className="color-03 fs-18 fw-600">Conforming</h3>
                                    <div className="btn-group w-100">
                                        <button
                                            disabled={
                                                !utils.checkSubStatus()
                                                    ? !utils.checkSubStatus()
                                                    : disable
                                            }
                                            type="button"
                                            className={
                                                currentData?.answers[0]?.no ||
                                                    currentData?.answers[0]?.na
                                                    ? "btn btn-success btn_success mt-0"
                                                    : "btn btn-success mt-0"
                                            }
                                            onClick={handleYes}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            disabled={
                                                !utils.checkSubStatus()
                                                    ? !utils.checkSubStatus()
                                                    : disable
                                            }
                                            type="button"
                                            className={
                                                currentData?.answers[0]?.yes ||
                                                    currentData?.answers[0]?.no
                                                    ? "btn btn-warning btn_warning mt-0"
                                                    : "btn btn-warning mt-0"
                                            }
                                            onClick={handleNA}
                                        >
                                            n/a
                                        </button>
                                        <button
                                            disabled={
                                                !utils.checkSubStatus()
                                                    ? !utils.checkSubStatus()
                                                    : disable
                                            }
                                            type="button"
                                            className={
                                                currentData?.answers[0]?.yes ||
                                                    currentData?.answers[0]?.na
                                                    ? "btn btn-danger btn_danger mt-0"
                                                    : "btn btn-danger mt-0"
                                            }
                                            onClick={handleNo}
                                        >
                                            No
                                        </button>
                                    </div>
                                    {currentData?.nc && (
                                        <div className="mt-3">
                                            <p className="color-03 fw-500">
                                                Related non-conformance:{" "}
                                                <span
                                                    style={{
                                                        fontWeight: 400,
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={redirectToNc}
                                                >
                                                    {currentData?.nc?.type}-{currentData?.nc?.id}
                                                </span>
                                            </p>
                                        </div>
                                    )}
                                    <div className="mt-4">
                                        <div className="form-group">
                                            <label className="color-03 fs-18 fw-500">Notes</label>
                                            <textarea
                                                disabled={
                                                    !utils.checkSubStatus()
                                                        ? !utils.checkSubStatus()
                                                        : disable
                                                }
                                                className="form-control custom_text_area"
                                                rows="5"
                                                onChange={(e) => handleNotes("notes", e.target.value)}
                                                value={currentData?.notes ? currentData?.notes : ""}
                                            />
                                        </div>
                                        {notesError && (
                                            <small className="text-danger">
                                                You must enter non-conformance notes before moving on to
                                                the next question
                                            </small>
                                        )}
                                        <button
                                            disabled={
                                                !utils.checkSubStatus()
                                                    ? !utils.checkSubStatus()
                                                    : disable
                                            }
                                            className="btn btn-block"
                                            data-toggle="modal"
                                            data-target="#answermodal"
                                        >
                                            Add evidence
                                        </button>
                                    </div>
                                    <div className="row my-4 evidence-scroll align-items-center">
                                        {currentData?.evidence?.map((obj, index) => (
                                            <div className="col-sm-6 mt-4" key={index}>
                                                <div className="d-flex align-items-center">
                                                    {disable ? (
                                                        <i
                                                            className="far fa-minus-circle"
                                                            style={{ cursor: "default" }}
                                                        />
                                                    ) : (
                                                        <i
                                                            className="far fa-minus-circle"
                                                            onClick={() => toggleRemoveAttachment(obj, index)}
                                                        />
                                                    )}

                                                    {obj.extension === "images" && obj?.path && (
                                                        <img
                                                            loading="lazy"
                                                            src={`${obj?.path}`}
                                                            alt="Evidence"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            className="cursor"
                                                        />
                                                    )}
                                                    {obj.extension === "videos" && obj?.path && (
                                                        // <video
                                                        //     className="cursor"
                                                        //     onClick={() => {
                                                        //         openLinkInNewTab(obj?.path);
                                                        //     }}
                                                        //     width="49"
                                                        //     src={`${obj?.path}#t=0.1`}
                                                        //     alt="Evidence"
                                                        // />
                                                        <img
                                                            className="cursor"
                                                            loading="lazy"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            src={videoIcon}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    {obj.extension === "files" && obj?.path && (
                                                        <img
                                                            loading="lazy"
                                                            className="cursor"
                                                            onClick={() => {
                                                                openLinkInNewTab(obj?.path);
                                                            }}
                                                            src={getExtension(obj?.path)}
                                                            alt="Evidence"
                                                        />
                                                    )}
                                                    {(obj?.title || obj?.title === "") && (
                                                        <input
                                                            className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                            placeholder={"optional title"}
                                                            value={obj?.title}
                                                            onChange={(e) =>
                                                                handledFieldChanges(
                                                                    "custom_title_evidence",
                                                                    e.target.value,
                                                                    true,
                                                                    index
                                                                )
                                                            }
                                                            disabled={!utils.checkSubStatus()}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {closeOut && (
                                        <>
                                            <div className="mt-4">
                                                <div className="my-5 d-flex">
                                                    <label
                                                        htmlFor="closeOutNotes"
                                                        className="ml-5 order-2 color-03 fs-18 fw-500"
                                                    >
                                                        I want to close this out
                                                    </label>
                                                    <input
                                                        disabled={
                                                            !utils.checkSubStatus()
                                                                ? !utils.checkSubStatus()
                                                                : disable
                                                        }
                                                        type="checkbox"
                                                        id="closeOutNotes"
                                                        checked={currentData?.isCloseOut}
                                                        onChange={(e) =>
                                                            handleNotes("closeOut", e.target.checked)
                                                        }
                                                    />
                                                </div>
                                                {currentData?.isCloseOut && (
                                                    <>
                                                        <div className="form-group">
                                                            <label className="color-03 fs-18 fw-500">
                                                                Notes for close out
                                                            </label>
                                                            <textarea
                                                                disabled={
                                                                    !utils.checkSubStatus()
                                                                        ? !utils.checkSubStatus()
                                                                        : disable
                                                                }
                                                                className="form-control custom_text_area"
                                                                rows="5"
                                                                value={
                                                                    currentData?.closeOutNotes
                                                                        ? currentData?.closeOutNotes
                                                                        : ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleNotes("closeOutNotes", e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                        {closeoutNotesError && (
                                                            <small className="text-danger">
                                                                You must enter close out notes before moving on
                                                                to the next question
                                                            </small>
                                                        )}
                                                        <button
                                                            disabled={
                                                                !utils.checkSubStatus()
                                                                    ? !utils.checkSubStatus()
                                                                    : disable
                                                            }
                                                            className="btn btn-block"
                                                            data-toggle="modal"
                                                            data-target="#closeOutEvidence"
                                                        >
                                                            Add close out evidence
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                            <div className="row my-4 evidence-scroll align-items-center">
                                                {currentData?.isCloseOut &&
                                                    currentData?.closeOutEvidence?.map((obj, index) => (
                                                        <div className="col-sm-6 mt-4" key={index}>
                                                            <div className="d-flex align-items-center">
                                                                {disable ? (
                                                                    <i
                                                                        className="far fa-minus-circle"
                                                                        style={{ cursor: "default" }}
                                                                    />
                                                                ) : (
                                                                    <i
                                                                        className="far fa-minus-circle"
                                                                        onClick={() =>
                                                                            toggleRemoveCloseOutAttachment(obj, index)
                                                                        }
                                                                    />
                                                                )}

                                                                {obj.extension === "images" && obj?.path && (
                                                                    <img
                                                                        loading="lazy"
                                                                        src={`${obj?.path}`}
                                                                        alt="Evidence"
                                                                        onClick={() => {
                                                                            openLinkInNewTab(obj?.path);
                                                                        }}
                                                                        className="cursor"
                                                                    />
                                                                )}
                                                                {obj.extension === "videos" && obj?.path && (
                                                                    // <video
                                                                    //     className="cursor"
                                                                    //     onClick={() => {
                                                                    //         openLinkInNewTab(obj?.path);
                                                                    //     }}
                                                                    //     width="49"
                                                                    //     src={`${obj?.path}#t=0.1`}
                                                                    //     alt="Evidence"
                                                                    // />
                                                                    <img
                                                                        className="cursor"
                                                                        loading="lazy"
                                                                        onClick={() => {
                                                                            openLinkInNewTab(obj?.path);
                                                                        }}
                                                                        src={videoIcon}
                                                                        alt="Evidence"
                                                                    />
                                                                )}
                                                                {obj.extension === "files" && obj?.path && (
                                                                    <img
                                                                        loading="lazy"
                                                                        className="cursor"
                                                                        onClick={() => {
                                                                            openLinkInNewTab(obj?.path);
                                                                        }}
                                                                        src={getExtension(obj?.path)}
                                                                        alt="Evidence"
                                                                    />
                                                                )}
                                                                {(obj?.title || obj?.title === "") && (
                                                                    <input
                                                                        className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                                        placeholder={"optional title"}
                                                                        value={obj?.title}
                                                                        onChange={(e) =>
                                                                            handledFieldChanges(
                                                                                "custom_title_close_evidence",
                                                                                e.target.value,
                                                                                true,
                                                                                index
                                                                            )
                                                                        }
                                                                        disabled={!utils.checkSubStatus()}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="mt-5 prev_next">
                                {currIndex !== 0 && (
                                    <button
                                        className="float-left color-03 fs-16 fw-500"
                                        onClick={prevQuestion}
                                    >
                                        <i className="fal fa-chevron-square-left"></i> Previous
                                        question
                                    </button>
                                )}
                                {currIndex !== questionData.length - 1 && (
                                    <button
                                        className="float-right color-03 fs-16 fw-500"
                                        onClick={nextQuestion}
                                    >
                                        Next question{" "}
                                        <i className="fal fa-chevron-square-right"></i>
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
                {
                    utils.checkSubStatus() && (
                        <div className="float-right my-2">
                            <button
                                className="btn_clear_inspection"
                                disabled={disable || emptyAns || buttonDisable}
                                onClick={() => {
                                    handleSave();
                                }}
                            >
                                <i className="far fa-save mr-1" /> Save
                            </button>
                        </div>
                    )
                }


                <LogsModal logs={currentData?.gmpLogs} type="gmp" />
            </section>

            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setSuccess(false);
                            refresh(true);
                            actionPrev();
                        }}
                    ></div>
                    <SaveModal
                        id={"question"}
                        h2={APP_CONSTANTS.CREATE_ANSWER.h2}
                        h3={APP_CONSTANTS.CREATE_ANSWER.h3}
                        p={APP_CONSTANTS.CREATE_ANSWER.p}
                    />
                </>
            )}

            {error && (
                <>
                    <div className="modal_backdrop"></div>
                    <ErrorModalGmpAnswer
                        id={"errorGmpAnswer"}
                        h3={APP_CONSTANTS.GMP_ANSWER_ERROR.h3}
                    />
                </>
            )}

            {removeAttachmentModal && (
                <RemoveAttachmentModal
                    params={evidenceData}
                    action={removeEvidence}
                    onCancel={closeModal}
                    id={"removeEvidence"}
                />
            )}

            {removeCloseOutAttachmentModal && (
                <RemoveAttachmentModal
                    params={closeOutEvidenceData}
                    action={removeCloseOutEvidence}
                    onCancel={closeModalCloseOut}
                    id={"removeEvidenceCloseOut"}
                />
            )}

            <AnswerEvidenceModal
                current={currentData?.evidence}
                action={addAttachment}
            />

            <ClouseOutAnswerModal
                current={currentData?.closeOutEvidence}
                action={addCloseOutAttachment}
            />
        </>
    );
};

export default GmpQuestionsDetail;
