import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Error from "../../Assets/images/error 1x.png";
import { createMultiSite, toggleModal, upgradeMultiSite } from "../../store/actions/sites";

const CreateMultiSiteModal = (props) => {
    const { id, message, data, type } = props;
    const dispatch = useDispatch();

    const [disable, setDisable] = useState(false);

    const handleSave = () => {
        setDisable(true);
        if (type === "add") {
            dispatch(createMultiSite(data));
        }
        else {
            dispatch(upgradeMultiSite(data));
        }
    };

    const handleClose = () => {
        dispatch(toggleModal({ show: false, data: {} }));
    }

    return (
        <div className="save-modal custom_modal_backdrop">
            <div
                className="modal fade show"
                id={`${id}`}
                tabIndex="-1"
                aria-labelledby={`${id} + Label`}
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center mb-4">
                                    <img
                                        src={Error}
                                        alt="Save Notification Pro"
                                        width={"200px"}
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 mb-4">
                                        Account is already in use...
                                    </h2>
                                    <div className="color-05 fs-12 fw-400 mb-4">
                                        {
                                            message.map((user, index) => (
                                                <p key={index} className="mb-1">{user}</p>
                                            ))
                                        }
                                    </div>
                                    <h3 className="color-03 fs-18 fw-600 mb-4">
                                        Do you want to upgrade them to a multi-site user?
                                    </h3>

                                    <div className="float-right mt-2">
                                        <button
                                            className="btn-no mr-2 py-2 px-4 fs-16"
                                            onClick={handleClose}
                                        >
                                            No, Cancel
                                        </button>
                                        <button
                                            className="btn-yes py-2 px-4 fs-16"
                                            onClick={handleSave}
                                            disabled={disable}
                                        >
                                            Yes, Upgrade
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateMultiSiteModal;
