import React from "react";
import moment from "moment";
import { APP_CONSTANTS } from "../../constants";

const LogsModal = (props) => {
    const { logs, type } = props;

    return (
        <div className="history-logs-modal">
            <div
                className="modal fade"
                id="logsModal"
                tabIndex="-1"
                aria-labelledby="logsModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <h2 className="fs-20 fw-600 color-01">Logs</h2>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <table className="table table-striped table-sm text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">
                                                {type === "gmp" ? "Inspector" : "Auditor"}
                                            </th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {type === "gmp" ? (
                                            <>
                                                {logs?.map((obj, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {moment(obj?.date).format(
                                                                APP_CONSTANTS.DATE_FORMAT
                                                            )}
                                                        </td>
                                                        <td>{obj?.inspector?.label}</td>
                                                        <td>
                                                            {obj?.action?.yes
                                                                ? "Conformance"
                                                                : obj?.action?.na
                                                                    ? "N/A"
                                                                    : obj?.action?.no
                                                                        ? "Non-Conformance"
                                                                        : null}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {logs?.map((obj, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {moment(obj?.date).format(
                                                                APP_CONSTANTS.DATE_FORMAT
                                                            )}
                                                        </td>
                                                        <td>{obj?.auditor?.label}</td>
                                                        <td>
                                                            {obj?.action?.yes
                                                                ? "Conformance"
                                                                : obj?.action?.na
                                                                    ? "N/A"
                                                                    : obj?.action?.no
                                                                        ? "Non-Conformance"
                                                                        : null}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn-cancel"
                                data-dismiss="modal"
                                data-target="#browseModal"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogsModal;
