import React from "react";
import { useSelector } from "react-redux";
import utils from "../../utils";

const StripeWidget = (props) => {
    const { object, discountObj } = useSelector((state) => state?.plan);

    let discount = 0;
    if (discountObj && discountObj.status) {
        discount = (object?.plan?.amount * discountObj.data.percent_off) / 100;
    }
    let tax = 0;
    let subTotal;

    if (object?.appliedTax) {
        subTotal = object?.plan?.amount - discount;
        tax = (subTotal * object?.appliedTax?.percentage) / 100;
    }
    return (
        <>
            <h3>{object?.product?.name}</h3>
            {object?.plan?.trial_period_days > 0 && (
                <h2>{object?.plan?.trial_period_days || 0} days free</h2>
            )}
            {object?.plan?.trial_period_days > 0 && (
                <h3>
                    Then £{utils.getFormattedValue(object?.plan?.amount || 0)} per month
                </h3>
            )}
            <dl className="row">
                {object?.plan?.trial_period_days > 0 && (
                    <dt className="col-sm-4">{object?.product?.name}</dt>
                )}
                {object?.plan?.trial_period_days > 0 && (
                    <dd className="col-sm-8">
                        {object?.plan?.trial_period_days || 0} days free
                    </dd>
                )}

                <dt className="col-sm-4">Qty 1</dt>
                {!object?.plan?.trial_period_days && (
                    <dd className="col-sm-8">
                        £
                        {`${utils.getFormattedValue(object?.plan?.amount || 0)} ${object?.plan?.interval ? `/${object?.plan?.interval}` : ""
                            } `}
                    </dd>
                )}
                {object?.plan?.trial_period_days > 0 && (
                    <dd className="col-sm-8">
                        £
                        {`${utils.getFormattedValue(object?.plan?.amount || 0)}/${object?.plan?.interval
                            } after`}
                    </dd>
                )}

                {discountObj && discountObj.status && (
                    <dt className="col-sm-4">Discount</dt>
                )}
                {discountObj && discountObj.status && (
                    <dd className="col-sm-8">
                        £{`${utils.getFormattedValue(discount || 0)}  `}
                    </dd>
                )}

                <dt className="col-sm-4">Subtotal</dt>
                <dd className="col-sm-8">
                    £{utils.getFormattedValue(object?.plan?.amount - discount || 0)}
                </dd>

                {!!object?.appliedTax && (
                    <dt className="col-sm-4 text-truncate">
                        {object?.appliedTax?.display_name} {object?.appliedTax?.percentage}%
                    </dt>
                )}
                {!!object?.appliedTax && (
                    <dd className="col-sm-8">
                        £{utils.getTwoDecimalValue(utils.getFormattedValue(tax))}
                    </dd>
                )}

                {object?.plan?.trial_period_days > 0 && (
                    <dt className="col-sm-4">Total after trial</dt>
                )}
                {object?.plan?.trial_period_days > 0 && (
                    <dd className="col-sm-8">
                        £{utils.getFormattedValue(subTotal + tax || 0)}
                    </dd>
                )}

                <dt className="col-sm-4">Total due today</dt>
                {object?.plan?.trial_period_days > 0 && (
                    <dd className="col-sm-8">£0.00</dd>
                )}
                {!object?.plan?.trial_period_days && (
                    <dd className="col-sm-8">
                        £{utils.getFormattedValue(subTotal + tax || 0)}
                    </dd>
                )}
            </dl>
        </>
    );
};

export default React.memo(StripeWidget);
