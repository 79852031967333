import React from 'react';

const CompF = () => {
    return (
        <>
            <h2 id="f">F</h2>
            <div className="accordion" id="accordionGlossary">
            <div className="card">
                <div className="card-header" id="heading111">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse111" aria-expanded="true"
                                aria-controls="collapse111">
                            Fail-safe
                        </button>
                    </h2>
                </div>
                <div id="collapse111" className="collapse show" aria-labelledby="heading111"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        A design feature that ensures that where a fundamental aspect of a piece of equipment fails, the equipment stops or alarms. Therefore, ensuring that it fails to a safe state.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading112">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse112" aria-expanded="false"
                                aria-controls="collapse112">
                            False positive
                        </button>
                    </h2>
                </div>
                <div id="collapse112" className="collapse" aria-labelledby="heading112"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        A false positive is a mistake. It's something that looks like it's mets a set of criteria, but it doesn't. For example, a test result that comes back positive but it's not. Or a material that's been rejected, but it's not really a reject. False positives indicate a lack of control or accuracy.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading113">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse113" aria-expanded="false"
                                aria-controls="collapse113">
                            Feedback
                        </button>
                    </h2>
                </div>
                <div id="collapse113" className="collapse" aria-labelledby="heading113"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        When information is provided about the performance of something. Normally, this is based on an opinion and not necessarily against a set standard. Further guidance; customer feedback
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading114">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse114" aria-expanded="false"
                                aria-controls="collapse114">
                            Filed
                        </button>
                    </h2>
                </div>
                <div id="collapse114" className="collapse" aria-labelledby="heading114"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        Putting documents and records away in an organised manner. See also; filed correctly, filled in properly
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading115">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse115" aria-expanded="false"
                                aria-controls="collapse115">
                            Filed correctly
                        </button>
                    </h2>
                </div>
                <div id="collapse115" className="collapse" aria-labelledby="heading115"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        There must be a system for how documents are filed. This is to make sure that they can be retrieved easily when they're needed.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading116">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse116" aria-expanded="false"
                                aria-controls="collapse116">
                            Filled in properly
                        </button>
                    </h2>
                </div>
                <div id="collapse116" className="collapse" aria-labelledby="heading116"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        Before records are filed, they must be checked to make sure they are accurate and also filled in properly. Many mistakes can be picked up and corrected at this stage, if the records are checked in a timely manner. Records should be checked to make sure that supervisors sign offs have been completed, all CCPs have been completed and passed as they should or corrective action has been applied correctly, etc.  Where there are gaps, it's easier to find out what went wrong, if it's investigated at the time, rather than filing it and finding out at a later date.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading117">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse117" aria-expanded="false"
                                aria-controls="collapse117">
                            Final mile
                        </button>
                    </h2>
                </div>
                <div id="collapse117" className="collapse" aria-labelledby="heading117"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        Delivery of product from the site to the final destination. The term is typically used where small quantities of products are being delivered, and therefore this process is carried out by couriers or mailing systems rather than hauliers.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading118">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse118" aria-expanded="false"
                                aria-controls="collapse118">
                            Finished product
                        </button>
                    </h2>
                </div>
                <div id="collapse118" className="collapse" aria-labelledby="heading118"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        A material that's ready for sale.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading119">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse119" aria-expanded="false"
                                aria-controls="collapse119">
                            Firefighting
                        </button>
                    </h2>
                </div>
                <div id="collapse119" className="collapse" aria-labelledby="heading119"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        Where staff have to react to problems. Typically, this is caused by a chaotic working environment where there is a lack of management control.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading120">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse120" aria-expanded="false"
                                aria-controls="collapse120">
                            Formulation
                        </button>
                    </h2>
                </div>
                <div id="collapse120" className="collapse" aria-labelledby="heading120"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        Product formulation is the recipe or the make up of the product. Typically provided on either a processing specification or a BOM.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading121">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse121" aria-expanded="false"
                                aria-controls="collapse121">
                            Fraud
                        </button>
                    </h2>
                </div>
                <div id="collapse121" className="collapse" aria-labelledby="heading121"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        The deliberate adulteration or misrepresentation of a product for financial gain.
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            <div className="card">
                <div className="card-header" id="heading122">
                    <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed mt-0" type="button"
                                data-toggle="collapse" data-target="#collapse122" aria-expanded="false"
                                aria-controls="collapse122">
                            Frequency
                        </button>
                    </h2>
                </div>
                <div id="collapse122" className="collapse" aria-labelledby="heading122"
                     data-parent="#accordionGlossary">
                    <div className="card-body">
                        Frequency is how often something happens, for example, hourly, weekly, monthly,yearly. Frequencies must be written down in hard-copy format or digitally. Once they are written down this is the 'set frequency' and it must happen at the 'frequency set' to be compliant. See also; Retraining frequency, Competency frequency
                    </div>
                </div>
            </div>
            {/*  Card End  */}
            </div>
        </>
    );
};

export default CompF;