import React, { useEffect, useState } from "react";
import Searchbar from "../Home/TodoLIst/Searchbar";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { editUserObject, getMultisiteUsersApi } from "../../store/actions/user";
import utils from "../../utils";
import { APP_CONSTANTS } from "../../constants";
import { changeTabState } from "../../store/actions/tabState";
import EditUserPermissionModal from "../Modals/EditUserPermissionModal";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "50px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const Overview = (props) => {
    const { setListData, handleFilterListData } = props;
    const dispatch = useDispatch();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [permissionModal, setPermissionModal] = useState(false);
    const { list } = useSelector((state) => state.user);

    const editUser = (row) => {
        if (row?.userType === "admin") {
            setPermissionModal(true);
        } else {
            if (utils.checkPermissions(APP_CONSTANTS.PERMISSION_CONST.CREATE_USER)) {
                dispatch(editUserObject(row));
                dispatch(changeTabState(3));
            }
        }
    };

    const columns = [
        {
            name: "User",
            selector: (row) => row.user_name,
            cell: (row) =>
                utils.checkSubscription() ? (
                    <div onClick={() => editUser(row)} className="cursor">
                        <a className="color-05 user-name-link text-decoration-none">
                            {row?.user_name}
                        </a>
                        {row?.userType === "admin" ? (
                            <p className="mb-0">(multi-site admin)</p>
                        ) : row?.userType === "regular" ? (
                            <p className="mb-0">(multi-site user)</p>
                        ) : null}
                    </div>
                ) : (
                    <a className="color-05 user-name-link">{row?.user_name}</a>
                ),
            width: "250px",
            sortable: true,
        },
        {
            name: "Sites",
            width: "300px",
            cell: (row) => (
                <div>
                    {row?.multisite_permissions?.general?.system_manager?.sites?.map(
                        (site) => (
                            <div className="my-2">{site?.label}</div>
                        )
                    )}
                </div>
            ),
        },
        {
            name: "System manager",
            cell: (row) => (
                <div>
                    {row?.multisite_permissions?.general?.system_manager?.sites?.map(
                        (site) =>
                            site?.enable ? (
                                <div className="my-2">
                                    <i className="fas fa-check green-check-mark text-center" />
                                </div>
                            ) : (
                                <div className="my-2 invisible">
                                    <i className="fas fa-times red-cross-mark text-center" />{" "}
                                </div>
                            )
                    )}
                </div>
            ),
        },
        {
            name: "Accounts",
            cell: (row) => (
                <div>
                    {row?.multisite_permissions?.general?.accounts?.sites?.map((site) =>
                        site?.enable ? (
                            <div className="my-2">
                                <i className="fas fa-check green-check-mark text-center" />
                            </div>
                        ) : (
                            <div className="my-2 invisible">
                                <i className="fas fa-times red-cross-mark text-center" />{" "}
                            </div>
                        )
                    )}
                </div>
            ),
        },
        {
            name: "RCA",
            cell: (row) => (
                <div>
                    {row?.multisite_permissions?.actions?.root_cause_analysis?.sites?.map(
                        (site) =>
                            site?.enable ? (
                                <div className="my-2">
                                    <i className="fas fa-check green-check-mark text-center" />
                                </div>
                            ) : (
                                <div className="my-2 invisible">
                                    <i className="fas fa-times red-cross-mark text-center" />{" "}
                                </div>
                            )
                    )}
                </div>
            ),
        },
        {
            name: "Verifier",
            cell: (row) => (
                <div>
                    {row?.multisite_permissions?.actions?.verifier?.sites?.map((site) =>
                        site?.enable ? (
                            <div className="my-2">
                                <i className="fas fa-check green-check-mark text-center" />
                            </div>
                        ) : (
                            <div className="my-2 invisible">
                                <i className="fas fa-times red-cross-mark text-center" />{" "}
                            </div>
                        )
                    )}
                </div>
            ),
        },
        {
            name: "Identifier",
            cell: (row) => (
                <div>
                    {row?.multisite_permissions?.actions?.identifier?.sites?.map((site) =>
                        site?.enable ? (
                            <div className="my-2">
                                <i className="fas fa-check green-check-mark text-center" />
                            </div>
                        ) : (
                            <div className="my-2 invisible">
                                <i className="fas fa-times red-cross-mark text-center" />{" "}
                            </div>
                        )
                    )}
                </div>
            ),
        },
        {
            name: "System audit accountable manager",
            cell: (row) => (
                <div>
                    {row?.multisite_permissions?.system_audits?.account_manager?.sites?.map(
                        (site) =>
                            site?.enable ? (
                                <div className="my-2">
                                    <i className="fas fa-check green-check-mark text-center" />
                                </div>
                            ) : (
                                <div className="my-2 invisible">
                                    <i className="fas fa-times red-cross-mark text-center" />{" "}
                                </div>
                            )
                    )}
                </div>
            ),
        },
        {
            name: "Auditor",
            cell: (row) => (
                <div>
                    {row?.multisite_permissions?.system_audits?.auditor?.sites?.map(
                        (site) =>
                            site?.enable ? (
                                <div className="my-2">
                                    <i className="fas fa-check green-check-mark text-center" />
                                </div>
                            ) : (
                                <div className="my-2 invisible">
                                    <i className="fas fa-times red-cross-mark text-center" />{" "}
                                </div>
                            )
                    )}
                </div>
            ),
        },
        {
            name: "GMP inspection accountable manager",
            cell: (row) => (
                <div>
                    {row?.multisite_permissions?.gmp_inspections?.accountable_manager?.sites?.map(
                        (site) =>
                            site?.enable ? (
                                <div className="my-2">
                                    <i className="fas fa-check green-check-mark text-center" />
                                </div>
                            ) : (
                                <div className="my-2 invisible">
                                    <i className="fas fa-times red-cross-mark text-center" />{" "}
                                </div>
                            )
                    )}
                </div>
            ),
        },
        {
            name: "Inspector",
            cell: (row) => (
                <div>
                    {row?.multisite_permissions?.gmp_inspections?.inspector?.sites?.map(
                        (site) =>
                            site?.enable ? (
                                <div className="my-2">
                                    <i className="fas fa-check green-check-mark text-center" />
                                </div>
                            ) : (
                                <div className="my-2 invisible">
                                    <i className="fas fa-times red-cross-mark text-center" />{" "}
                                </div>
                            )
                    )}
                </div>
            ),
        },
        {
            name: "Disabled",
            selector: (row) => row?.disable_user,
            cell: (row) => {
                return (
                    row?.disable_user && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
    ];

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataUsers = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataUsers);
    };

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                list?.filter((item) => {
                    if (
                        item?.user_name
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        checkSites(searchValue, item)
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const checkSites = (search, item) => {
        let tempBool = false;
        item?.multisite_permissions?.general?.system_manager?.sites?.forEach(
            (site) => {
                if (site?.label?.toLowerCase().includes(search?.toLowerCase())) {
                    tempBool = true;
                }
            }
        );
        return tempBool;
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div>
                <Searchbar
                    onFilter={(e) => filterData(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                    type="users"
                />
            </div>
        );
    }, [filterText, filteredList]);

    const handleRowSelection = ({ selectedRows }) => {
        handleFilterListData(selectedRows);
    };

    useEffect(() => {
        dispatch(getMultisiteUsersApi());
    }, []);

    useEffect(() => {
        setFilteredList([...list]);
        setListData([...list]);
    }, [list]);

    useEffect(() => {
        handleFilterListData(filteredList);
    }, [filteredList]);

    return (
        <div>
            <DataTable
                columns={columns}
                data={filteredList}
                selectableRows
                customStyles={customStyles}
                onSelectedRowsChange={handleRowSelection}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />

            {permissionModal && (
                <>
                    <div className="modal_backdrop"></div>
                    <EditUserPermissionModal
                        h2={APP_CONSTANTS.EDIT_USER_PERMISSION_MODAL.h2}
                        h3={APP_CONSTANTS.EDIT_USER_PERMISSION_MODAL.h3}
                        closeModal={setPermissionModal}
                        type="multiSiteAdmin"
                    />
                </>
            )}
        </div>
    );
};

export default Overview;
