import React, { useEffect, useState } from "react";
import utils from "../../../../utils";

const FilterAction = (props) => {
    const {
        options,
        menu,
        data,
        onChange,
        label,
        deSelectAll,
        setCurrentMenu,
        currentMenu,
        selectAll,
    } = props;

    const [parentWidth, setParentWidth] = useState(0);

    const toggle = () => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            if (currentMenu === menu) {
                setCurrentMenu(null);
            } else {
                setCurrentMenu(menu);
            }
        }
    };

    var counter = 0;
    options.map((item) => (counter = counter + 1 + item?.child?.length));
    const reportName = (menu) => {
        switch (menu) {
            case "type":
                if (data[0] === "NC") {
                    return "Non-conformances";
                } else if (data[0] === "GMP") {
                    return "GMP inspections";
                } else if (data[0] === "SA") {
                    return "System audits";
                } else if (data?.includes("AC")) {
                    return "Actions";
                } else if (
                    data?.includes("CA") ||
                    data?.includes("PA") ||
                    data?.includes("PRO") ||
                    data?.includes("RCA")
                ) {
                    const res = data?.map((item) => {
                        if (item === "CA") {
                            return "Corrective actions";
                        } else if (item === "PA") {
                            return "Preventive actions";
                        } else if (item === "PRO") {
                            return "Proactive actions";
                        } else if (item === "RCA") {
                            return "Root cause analysis";
                        }
                    });
                    return res.join(", ");
                } else {
                    return "Select...";
                }
                break;
            case "area":
                if (data?.length === options?.length) {
                    return "All";
                } else if (data?.length) {
                    return data.join(", ");
                } else {
                    return "Select...";
                }
                break;
            case "source":
                if (data?.length === options?.length) {
                    return "All";
                } else if (data?.length) {
                    return data.join(", ");
                } else {
                    return "Select...";
                }
                break;
            case "grade":
                if (data?.length === options?.length) {
                    return "All";
                } else if (data?.length) {
                    return data.join(", ");
                } else {
                    return "Select...";
                }
                break;
            case "topic":
                if (data?.length === options?.length) {
                    return "All";
                } else if (data?.length) {
                    return data.join(", ");
                } else {
                    return "Select...";
                }
                break;
            case "roles":
                let count = 0;
                options.map((item) => (count = count + 1 + item?.child?.length));
                if (data?.length === count) {
                    return "All";
                } else if (data?.length) {
                    let allOptions = [];
                    for (const obj of options) {
                        allOptions?.push(obj);
                        if (obj?.child) {
                            obj?.child?.forEach((childObj) => {
                                allOptions?.push(childObj);
                            });
                        }
                    }
                    const result = data?.map((value1) => {
                        const foundElement = allOptions?.find(
                            (item) => item?.value === value1
                        );
                        return foundElement ? foundElement?.label : null;
                    });
                    return result?.join(", ");
                } else {
                    return "Select...";
                }
                break;
            default:
        }
    };

    useEffect(() => {
        const parentDiv = document.getElementById("parent");
        setParentWidth(parentDiv.clientWidth);

        const handleResize = () => {
            setParentWidth(parentDiv.clientWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const childStyle = {
        width: `${parentWidth - 50}px`,
    };

    return (
        <div className="filter-action">
            <label className="color-04 fs-14 mb-1">{label}</label>
            <div className="dropdown">
                <button
                    className="report-dropdown"
                    onClick={() => toggle()}
                    id="parent"
                >
                    {reportName(menu)?.length >= 7 ? (
                        <div
                            id="child"
                            style={{
                                ...childStyle,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={reportName(menu)}
                        >
                            {reportName(menu).split(",")[0]}
                        </div>
                    ) : (
                        <>{reportName(menu)}</>
                    )}
                </button>
                <ul
                    className={
                        menu === currentMenu
                            ? "dropdown-menu d-block mt-0 w-100 pl-2"
                            : "dropdown-menu d-none"
                    }
                    style={{ maxHeight: "250px", overflow: "auto", minWidth: "auto" }}
                >
                    {menu !== "type" &&
                        (data?.length === counter || data?.length === options?.length) && (
                            <p
                                className="fs-10 color-05 text-right pr-2 mb-1"
                                style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => {
                                    if (!utils.checkSubStatus()) {
                                        return;
                                    } else {
                                        deSelectAll(menu);
                                    }
                                }}
                            >
                                Deselect all
                            </p>
                        )}
                    {menu !== "type" &&
                        data?.length !== counter &&
                        data?.length !== options?.length && (
                            <p
                                className="fs-10 color-05 text-right pr-2 mb-1"
                                style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => {
                                    if (!utils.checkSubStatus()) {
                                        return;
                                    } else {
                                        selectAll(menu);
                                    }
                                }}
                            >
                                Select all
                            </p>
                        )}
                    {menu === currentMenu && (
                        <>
                            {options?.length > 0 &&
                                options?.map((item, index) => (
                                    <li key={index}>
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={item?.value}
                                                checked={data?.includes(item?.value)}
                                                onChange={(e) =>
                                                    onChange({
                                                        ...item,
                                                        menu: menu,
                                                        checked: e.target.checked,
                                                    })
                                                }
                                                disabled={!utils.checkSubStatus()}
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04"
                                                htmlFor={item?.value}
                                            >
                                                {item?.label}
                                            </label>
                                        </div>
                                        {item?.child?.length > 0 && (
                                            <ul className="list-unstyled pl-3">
                                                {item?.child &&
                                                    item?.child?.map((itm, ind) => (
                                                        <li key={ind}>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    className="custom-control-input"
                                                                    type="checkbox"
                                                                    id={itm?.value}
                                                                    checked={data?.includes(itm?.value)}
                                                                    onChange={(e) =>
                                                                        onChange({
                                                                            ...itm,
                                                                            menu: menu,
                                                                            checked: e.target.checked,
                                                                        })
                                                                    }
                                                                    disabled={!utils.checkSubStatus()}
                                                                />
                                                                <label
                                                                    className="custom-control-label cursor fs-14 fw-400 color-04"
                                                                    htmlFor={itm?.value}
                                                                >
                                                                    {itm?.label}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                        </>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default FilterAction;
