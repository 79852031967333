import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = (props) => {
    const { data } = props;

    const options = {
        series: [
            {
                data: data?.data,
            },
        ],
        chart: {
            id: "line-chart",
            toolbar: {
                show: false,
                tools: {
                    zoom: false,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            type: "datetime",
            min: data?.startDate,
            tooltip: {
                enabled: false,
            },
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const xCategories = moment(data?.data[dataPointIndex][0]).format(
                    "DD MMM YYYY"
                );
                const value = series[seriesIndex][dataPointIndex];
                return (
                    '<div class="arrow_box">' +
                    "<div>" +
                    xCategories +
                    "</div>" +
                    "<div>" +
                    "Score" +
                    ": " +
                    value +
                    "%" +
                    "</div>" +
                    "</div>"
                );
            },
        },
        markers: {
            size: 0,
            hover: {
                size: 0,
            },
        },
        stroke: {
            width: 2,
        },
        colors: ["#40AE73"],
    };

    return (
        <div id="chart" className="line-chart">
            <ReactApexChart
                options={options}
                series={options?.series}
                type="line"
                height={450}
            />
        </div>
    );
};

export default LineChart;
