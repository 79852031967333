import { USER_ACTION_CONSTANTS } from "../constants";
import request from "../../services/request";
import session from "../../services/session";
import { USER } from "../../Routes/Api";
import { APP_CONSTANTS } from "../../constants";
import { changeTabState } from "./tabState";

var user = JSON.parse(localStorage.getItem("user"));

export const signIn = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.SIGN_IN,
        payload,
    };
};

export const signInSuccess = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.SIGN_IN_SUCCESS,
        payload,
    };
};

export const signInError = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.SIGN_IN_ERROR,
        payload,
    };
};

export const signOut = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.SIGN_OUT,
        payload,
    };
};

export const signOutSuccess = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.SIGN_OUT_SUCCESS,
        payload,
    };
};

export const signOutError = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.SIGN_OUT_ERROR,
        payload,
    };
};

export const createUser = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.CREATE_USER,
        payload,
    };
};

export const createUserSuccess = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.CREATE_USER_SUCCESS,
        payload,
    };
};

export const createUserError = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.CREATE_USER_ERROR,
        payload,
    };
};

export const updateUser = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.UPDATE_USER,
        payload,
    };
};

export const editPRofile = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.EDIT_PROFILE,
        payload,
    };
};

export const editProfileSuccess = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.EDIT_PROFILE_SUCCESS,
        payload,
    };
};

export const editProfileError = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.EDIT_PROFILE_ERROR,
        payload,
    };
};

export const updateUserSuccess = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.UPDATE_USER_SUCCESS,
        payload,
    };
};

export const updateUserError = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.UPDATE_USER_ERROR,
        payload,
    };
};

export const updateCreateUserObject = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.UPDATE_CREATE_USER_OBJECT,
        payload,
    };
};

export const editUserObject = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.EDIT_USER_OBJECT,
        payload,
    };
};

export const getUserById = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.GET_USER_BY_ID,
        payload,
    };
};

export const getUserByIdSuccess = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.GET_USER_BY_ID_SUCCESS,
        payload,
    };
};

export const getUserByIdError = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.GET_USER_BY_ID_ERROR,
        payload,
    };
};

export const getAllUsersSuccess = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.GET_ALL_USERS_SUCCESS,
        payload,
    };
};

export const getAllUsersForDropdownSuccess = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.GET_ALL_USERS_FOR_DROPDOWN,
        payload,
    };
};

export const getAllUsersError = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.GET_ALL_USERS_ERROR,
        payload,
    };
};

export const getUsersByRoleSuccess = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.GET_USERS_BY_ROLE_SUCCESS,
        payload,
    };
};

export const getUsersByRoleError = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.GET_USERS_BY_ROLE_ERROR,
        payload,
    };
};

export const toggleSuccessModal = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.TOGGLE_USER_SUCCESS_MODAL,
        payload,
    };
};

export const resendPasswordSuccessModal = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.TOGGLE_RESEND_PASSWORD_SUCCESS_MODAL,
        payload,
    };
};

export const toggleErrorModal = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.TOGGLE_USER_ERROR_MODAL,
        payload,
    };
};

export const resendPasswordErrorModal = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.TOGGLE_RESEND_PASSWORD_ERROR_MODAL,
        payload,
    };
};

export const upgradeUser = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.USER_UPGRADE,
        payload,
    };
};

export const setOrgData = (payload) => {
    return {
        type: USER_ACTION_CONSTANTS.SET_ORG_DATA,
        payload,
    };
};

export const logIn = (email, password, from) => {
    return (dispatch) => {
        request.post(USER.SIGN_IN, { email, password }, false).then(
            (res) => {
                session.setToken(res?.data?.token);
                session.setUser(res?.data?.user);
                if (res?.data?.org) {
                    session.setOrg(res?.data?.org);
                }
                if (res?.data?.multisite) {
                    session.setMultisite(res?.data?.multisite);
                }
                // dispatch(signInSuccess(res?.data?.user));
                // /*history.push(from);*/
                window.location.reload();
            },
            (error) => {
                dispatch(signInError(error));
            }
        );
    };
};

export const azureLogIn = (username) => {
    return (dispatch) => {
        request.post(USER.AZURE_SIGN_IN, username, false).then(
            (res) => {
                session.setToken(res?.data?.token);
                session.setUser(res?.data?.user);
                if (res?.data?.sites) {
                    session.setSites(res?.data?.sites);
                }
                if (res?.data?.org) session.setOrg(res?.data?.org);
                if (res?.data?.multisite) {
                    session.setMultisite(res?.data?.multisite);
                }
                // dispatch(signInSuccess(res?.data?.user));
                /*history.push(from);*/
                window.location.reload();
            },
            (error) => {
                dispatch(signInError(error));
            }
        );
    };
};

export const logOut = (id = session.getUserId()) => {
    return (dispatch) => {
        request.post(USER.SIGN_OUT, { id }, true).then(
            (res) => {
                session.removeToken();
                session.removeUser();
                session.removeOrg();
                session.removeMultisite();
                session.removrPersist();
                dispatch(signOutSuccess({}));
                window.location.href = "/";

                /*history.push(from);*/
            },
            (error) => {
                session.removeToken();
                session.removeUser();
                session.removeOrg();
                session.removeMultisite();
                session.removrPersist();
                dispatch(signOutSuccess({}));
                window.location.href = "/";
            }
        );
    };
};
export const createUserAPi = (data, from) => {
    return (dispatch) => {
        request.post(USER.CREATE_USER, data, true).then(
            (res) => {
                dispatch(createUserSuccess(res?.data));
                if (res?.data?.upgrade) {
                    dispatch(upgradeUser(res));
                }
                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    dispatch(changeTabState(1));
                    dispatch(upgradeUser({}));
                    dispatch(
                        updateCreateUserObject(
                            JSON.parse(JSON.stringify(USER_ACTION_CONSTANTS.CREATE_OBJECT()))
                        )
                    );
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(createUserError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const editProfileApi = (data, from) => {
    return (dispatch) => {
        request.put(USER.EDIT_PROFILE, data, true).then(
            (res) => {
                session.setUser(res?.data);
                dispatch(editProfileSuccess(res?.data));

                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    if (res?.data?.isPassword) {
                        dispatch(logOut());
                    }
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(editProfileError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const updateUserAPi = (data, from) => {
    return (dispatch) => {
        request.put(USER.UPDATE_USER, data, true).then(
            (res) => {
                if (res?.data?._id === user?._id) {
                    session.setUser(res?.data);
                }
                dispatch(updateUserSuccess(res?.data));
                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    dispatch(changeTabState(1));
                    dispatch(editUserObject({}));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(updateUserError(error));
            }
        );
    };
};

export const resendPasswordAPI = (id, type) => {
    return (dispatch) => {
        request.post(`${USER.RESEND_PASSWORD}${id}`, {}, true).then(
            (res) => {
                dispatch(resendPasswordSuccessModal(true));
                if (type === "multisite") {
                    setTimeout(() => {
                        dispatch(resendPasswordSuccessModal(false));
                        // dispatch(changeTabState(1));
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                } else {
                    setTimeout(() => {
                        dispatch(resendPasswordSuccessModal(false));
                        dispatch(changeTabState(1));
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            },
            (error) => {
                dispatch(resendPasswordErrorModal(error));
            }
        );
    };
};

export const getAllUsersApi = (org = session.getOrgId()) => {
    return (dispatch) => {
        request.get(`${USER.GET_ALL_USERS}${org}`, true).then(
            (res) => {
                dispatch(getAllUsersSuccess(res?.data));
                /*history.push(from);*/
            },
            (error) => {
                dispatch(getAllUsersError(error));
            }
        );
    };
};

// get multi-site users
export const getMultisiteUsersApi = (multi = session.getMultisiteId()) => {
    return (dispatch) => {
        request.get(`${USER.GET_MULTISITE_USERS}${multi}`, true).then(
            (res) => {
                dispatch(getAllUsersSuccess(res?.data));
                /*history.push(from);*/
            },
            (error) => {
                dispatch(getAllUsersError(error));
            }
        );
    };
};

export const getAllUsersForDropDownApi = (
    org = session.getOrgId(),
    orgData = session.getOrg()
) => {
    return (dispatch) => {
        request
            .get(
                `${USER.GET_ALL_USERS_FOR_DROPDOWN}${org ? org : orgData?._id}`,
                true
            )
            .then(
                (res) => {
                    dispatch(getAllUsersForDropdownSuccess(res?.data));
                    /*history.push(from);*/
                },
                (error) => {
                    /*dispatch(getAllUsersError(error));*/
                }
            );
    };
};

export const getUsersByRoleApi = (
    orgId,
    org = session.getOrgId(),
    orgData = session.getOrg()
) => {
    return (dispatch) => {
        request
            .get(
                `${USER.GET_USERS_BY_ROLE}${orgId ? orgId : org ? org : orgData?._id}`,
                true
            )
            .then(
                (res) => {
                    dispatch(getUsersByRoleSuccess(res?.data));
                    /*history.push(from);*/
                },
                (error) => {
                    dispatch(getUsersByRoleError(error));
                }
            );
    };
};
