import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./Routes/privateRoute";
import PublicRoute from "./Routes/publicRoute";
import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import request from "./services/request";
import session from "./services/session";
import { ORG } from "./Routes/Api";
import { useDispatch } from "react-redux";
import { setOrgData } from "./store/actions/user";
import utils from "./utils";

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        if (session?.getOrgId()) {
            request.get(`${ORG.GET_ORG_BY_ID}${session.getOrgId()}`, true).then(
                (res) => {
                    if (res?.status) {
                        utils.resetOrgDataInSession(res?.data);
                        dispatch(setOrgData(res?.data));
                    }
                },
                (error) => { }
            );
        }
    }, []);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);
    return (
        <Elements stripe={stripePromise}>
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<PublicRoute></PublicRoute>} />
                    <Route path="/private/*" element={<PrivateRoute></PrivateRoute>} />
                </Routes>
            </BrowserRouter>
        </Elements>
    );
}

export default App;
