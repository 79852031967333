import React from "react";
import errorIcon from "../../Assets/images/error 1x.png";

const EditNcPermission = (props) => {
    const { closeModal } = props;

    return (
        <div className="error-modal custom_modal_backdrop">
            <div
                className="modal fade show "
                id="editUserPermissionModal"
                tabIndex="-1"
                aria-labelledby="editUserPermissionModal"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div
                                className="row align-items-center">
                                <div className="col-12 col-lg-4 text-center pb-4 pb-lg-0">
                                    <img src={errorIcon} alt="error" />
                                </div>
                                <div className="col-12 col-lg-8">
                                    <h2 className="color-03 fs-24 fw-600 text-center mb-4">
                                        You don't have permission to edit this NC
                                    </h2>
                                    <div className="float-right mt-4">
                                        <button
                                            className="btn-yes px-4 fs-16"
                                            onClick={() => {
                                                closeModal(false);
                                            }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditNcPermission;
