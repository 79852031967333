import React from 'react';
import CheckoutForm from "./CheckoutForm";
import StripeConfirm from "./StripeConfirm";
import HeaderSec from "./HeaderSec";
import Footer from "../Layout/Footer";
import {Route} from "react-router-dom";
import Thanks from "./Thanks";
import {Routes} from "react-router";

const Stripe = () => {
    return (
        <>
            <HeaderSec/>
            <Routes>
                <Route path="/check-out/:planId" element={<CheckoutForm />} />
                <Route path="/thank-you" element={<Thanks />} />
                <Route path="/stripe-confirm" element={<StripeConfirm />} />
            </Routes>
            <Footer/>
        </>

    );
};

export default Stripe;