import React from "react";
import Reassign from "../../Assets/images/group-shot.png";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import request from "../../services/request";
import { GMP } from "../../Routes/Api";
import { APP_CONSTANTS } from "../../constants";

const ReassignModal = (props) => {
    const { toggleReassignModal, gmpId, id } = props;

    const user = JSON.parse(localStorage.getItem("user"));
    const [gmpInspectors, setGmpInspectors] = useState([]);
    const [error, setError] = useState({
        show: false,
        message: "",
    });
    const [sendData, setSendData] = useState({});
    const [emptyCheck, setEmptyCheck] = useState(true);

    // close modal
    const closeModal = () => {
        toggleReassignModal(false);
    };

    const handledFieldChanges = (field, value, index) => {
        let inspectorsList = [];
        let sendDataa = {};
        delete value?._id;
        switch (field) {
            case "reassign_inspector":
                inspectorsList.push(value);
                sendDataa = {
                    gmpId: id,
                    inspectors: inspectorsList,
                };
                setSendData(sendDataa);
                setEmptyCheck(false);
                break;
            default:
        }
    };

    // Reassign inspector API call
    const handleSave = () => {
        request.post(GMP.REASSIGN_INSPECTOR, sendData, true, false).then(
            (res) => {
                if (res?.status) {
                    toggleReassignModal(false);
                }
            },
            (err) => {
                setError({ show: true, message: err?.message });
                setTimeout(() => {
                    setError({ show: false, message: "" });
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };

    // get inspectors list API call
    useEffect(() => {
        request.get(`${GMP.GMP_INSPECTORS_LIST}/${gmpId}`, true, false).then(
            (res) => {
                const arr = res?.data?.filter((item) => {
                    return item?.value !== user?._id;
                });
                setGmpInspectors(arr);
            },
            (error) => { }
        );
    }, []);

    return (
        <section className="audit-roles-modal gmp_inspection_modal">
            <div
                className="modal fade show"
                style={{ display: "block" }}
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-wd modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body px-4 pt-5 pb-sm-4 pb-lg-1 pb-4">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center">
                                    <div>
                                        <img
                                            src={Reassign}
                                            // className="gmp_role_modal_img"
                                            width={230}
                                            alt="avatar"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="mb-4">
                                        <h2 className="fs-18 fw-600 color-04 mb-0">
                                            Ok, let's reassign this inspection
                                        </h2>
                                        <p className="mb-0 font-weight-light color-04 fs-14">
                                            Just select the member of your team you'd like it to be
                                            reassigned to.
                                        </p>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group row mb-4 ml-lg-2">
                                                <label className="col-12 col-lg-5 col-form-label">
                                                    Reassign to
                                                </label>
                                                <div className="col-12 col-lg-7">
                                                    <Select
                                                        className="fs-14"
                                                        options={gmpInspectors}
                                                        value={gmpInspectors?.value}
                                                        onChange={(data) =>
                                                            handledFieldChanges(
                                                                "reassign_inspector",
                                                                data
                                                                // index
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {error?.show && (
                                        <div
                                            className="alert alert-danger mt-0"
                                            role="alert"
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            {error?.message}
                                        </div>
                                    )}
                                    <div className="float-right mt-5">
                                        <button
                                            type="button"
                                            className="cancel mr-2"
                                            onClick={closeModal}
                                        >
                                            <i className="far fa-ban" /> Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-send"
                                            disabled={emptyCheck}
                                            onClick={handleSave}
                                        >
                                            <i className="far fa-save" /> Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </section>
    );
};

export default ReassignModal;
