import React from 'react';

const CompH = () => {
    return (
        <>
            <h2 id="h">H</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading133">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse133" aria-expanded="true"
                                    aria-controls="collapse133">
                                HACCP
                            </button>
                        </h2>
                    </div>
                    <div id="collapse133" className="collapse show" aria-labelledby="heading133"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Hazard analysis and critical control point. A hazard analysis system used to determine which parts of the process require specific control for safety purposes.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading134">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse134" aria-expanded="false"
                                    aria-controls="collapse134">
                                Harbour
                            </button>
                        </h2>
                    </div>
                    <div id="collapse134" className="collapse" aria-labelledby="heading134"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The term harbour in the food industry is used when talking about pests. Harbour means to shelter or hide.  Therefore, something which harbours pests or provides harbourage - gives pests a place to shelter or hide.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading135">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse135" aria-expanded="false"
                                    aria-controls="collapse135">
                                Hard-copy
                            </button>
                        </h2>
                    </div>
                    <div id="collapse135" className="collapse" aria-labelledby="heading135"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Information that is provided in physical format (so that you can touch it), typically printed onto paper. Hard-copy is different from information that's provided digitally.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading136">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse136" aria-expanded="false"
                                    aria-controls="collapse136">
                                Hardstanding
                            </button>
                        </h2>
                    </div>
                    <div id="collapse136" className="collapse" aria-labelledby="heading136"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A ground surface designed for vehicles to park and move on.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading137">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse137" aria-expanded="false"
                                    aria-controls="collapse137">
                                Hazard
                            </button>
                        </h2>
                    </div>
                    <div id="collapse137" className="collapse" aria-labelledby="heading137"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something which can cause harm. The scope of the hazard analysis sets the context of the hazard. For example, the scope of a Product Safety Plan would be to ensure safety to the consumer - therefore the hazard could cause physical harm. Whereas the scope for hazard analysis for quality control purposes would be to ensure that the customer receives a product that meets the agreed specification - therefore the hazard could cause psychological harm in the form of upset or repulsion.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading138">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse138" aria-expanded="false"
                                    aria-controls="collapse138">
                                Hazard analysis
                            </button>
                        </h2>
                    </div>
                    <div id="collapse138" className="collapse" aria-labelledby="heading138"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A process which assesses the risks associated with a defined hazard, to establish the controls required to mitigate those risks. Also known as; hazard and risk-based management, HARA, hazard and risk assessment, hazard and risk analysis,
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading139">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse139" aria-expanded="false"
                                    aria-controls="collapse139">
                                High-care product
                            </button>
                        </h2>
                    </div>
                    <div id="collapse139" className="collapse" aria-labelledby="heading139"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A high-care product is a chilled or frozen food which is ready-to-eat or ready-to-heat, supports pathogenic growth and hasn't undergone at least a pasteurisation cook.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading140">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse140" aria-expanded="false"
                                    aria-controls="collapse140">
                                High-care product area
                            </button>
                        </h2>
                    </div>
                    <div id="collapse140" className="collapse" aria-labelledby="heading140"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An area on site where high-care materials are open to the environment. High-care materials can be in any open form; ingredients, work-in-progress materials or finished product. See also; high-care product.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading141">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse141" aria-expanded="false"
                                    aria-controls="collapse141">
                                High-risk product
                            </button>
                        </h2>
                    </div>
                    <div id="collapse141" className="collapse" aria-labelledby="heading141"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A high-risk materials are a chilled or frozen foods which are ready-to-eat or ready-to-heat, support pathogenic growth and have undergone at least a pasteurisation cook.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading142">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse142" aria-expanded="false"
                                    aria-controls="collapse142">
                                High-risk product area
                            </button>
                        </h2>
                    </div>
                    <div id="collapse142" className="collapse" aria-labelledby="heading142"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An area on site where high-risk material is open to the environment. High-risk materials can be in any open form; ingredients, work-in-progress materials or finished product. See also; high-risk product.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading143">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse143" aria-expanded="false"
                                    aria-controls="collapse143">
                                Honesty
                            </button>
                        </h2>
                    </div>
                    <div id="collapse143" className="collapse" aria-labelledby="heading143"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To be truthful.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompH;