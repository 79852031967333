import { GMP_TEMPLATE } from "../constants";
import request from "../../services/request";
import { GMP } from "../../Routes/Api";

export const getGmpTemplate = (payload) => {
  return {
    type: GMP_TEMPLATE.GET_GMP_TEMPLATE,
    payload,
  };
};

export const getGmpTemplateSuccess = (payload) => {
  return {
    type: GMP_TEMPLATE.GET_GMP_TEMPLATE_SUCCESS,
    payload,
  };
};

export const getGmpTemplateError = (payload) => {
  return {
    type: GMP_TEMPLATE.GET_GMP_TEMPLATE_ERROR,
    payload,
  };
};

export const getGmpTemplateApi = () => {
  return (dispatch) => {
    request.get(`${GMP.GET_GMP_TEMPLATES}`, true).then(
      (res) => {
        dispatch(getGmpTemplateSuccess(res?.data));
      },
      (error) => {
        dispatch(getGmpTemplateError(error));
      }
    );
  };
};
