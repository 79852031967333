import React from 'react';

const CompT = () => {
    return (
        <>
            <h2 id="t">T</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading334">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse334" aria-expanded="true"
                                    aria-controls="collapse334">
                                Tact
                            </button>
                        </h2>
                    </div>
                    <div id="collapse334" className="collapse show" aria-labelledby="heading334"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To behave with sensitivity to protect someone elses feelings.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading335">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse335" aria-expanded="false"
                                    aria-controls="collapse335">
                                Tare weight
                            </button>
                        </h2>
                    </div>
                    <div id="collapse335" className="collapse" aria-labelledby="heading335"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The weight of an empty container, which is subtracted from the packed weight to calculate the product weight.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading336">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse336" aria-expanded="false"
                                    aria-controls="collapse336">
                                Tempering
                            </button>
                        </h2>
                    </div>
                    <div id="collapse336" className="collapse" aria-labelledby="heading336"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To change and stabilise the temperature of a material.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading337">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse337" aria-expanded="false"
                                    aria-controls="collapse337">
                                Tertiary packaging
                            </button>
                        </h2>
                    </div>
                    <div id="collapse337" className="collapse" aria-labelledby="heading337"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Tertiary packaging is the third layer of packaging, that the product is packed onto or inside of. Such as outer cartons, pallet, shrink wrap etc.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading338">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse338" aria-expanded="false"
                                    aria-controls="collapse338">
                                Timely manner
                            </button>
                        </h2>
                    </div>
                    <div id="collapse338" className="collapse" aria-labelledby="heading338"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An action that is carried out without delay and can be completed in a practical timescale, which is appropriate to the situation. For example, in a recall situation this would need to be within 4 hours are least.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading339">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse339" aria-expanded="false"
                                    aria-controls="collapse339">
                                Trace code
                            </button>
                        </h2>
                    </div>
                    <div id="collapse339" className="collapse" aria-labelledby="heading339"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A label given to a material to identify it.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading340">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse340" aria-expanded="false"
                                    aria-controls="collapse340">
                                Traceability
                            </button>
                        </h2>
                    </div>
                    <div id="collapse340" className="collapse" aria-labelledby="heading340"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The ability to track a material at all times, throughout a process.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading341">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse341" aria-expanded="false"
                                    aria-controls="collapse341">
                                Traded product
                            </button>
                        </h2>
                    </div>
                    <div id="collapse341" className="collapse" aria-labelledby="heading341"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Finished products which are not manufactured by the business, but are bought to sell on.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading342">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse342" aria-expanded="false"
                                    aria-controls="collapse342">
                                Training
                            </button>
                        </h2>
                    </div>
                    <div id="collapse342" className="collapse" aria-labelledby="heading342"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To teach a subject or skill, so that a task or role can be completed competently. See also; competency, awareness training, subject matter training
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading343">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse343" aria-expanded="false"
                                    aria-controls="collapse343">
                                Transformation (transform)
                            </button>
                        </h2>
                    </div>
                    <div id="collapse343" className="collapse" aria-labelledby="heading343"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When a product or its primary packaging is altered or changed in some way. For example, making butter from milk is a transformation, as the product has been changed. Taking bulk butter and packing into portions is a transformation, as the primary packaging has changed. Taking outer cases holding 10 units of 250g packs of butter and repacking them into cases holding 5 units - is not a transformation, as the product and primary packaging hasn't changed.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading344">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse344" aria-expanded="false"
                                    aria-controls="collapse344">
                                Trial
                            </button>
                        </h2>
                    </div>
                    <div id="collapse344" className="collapse" aria-labelledby="heading344"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A practice run to check if a product or process can be performed as required. Sometimes called product trials, production trials or process trials.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompT;