import React from 'react';

const CompM = () => {
    return (
        <>
            <h2 id="m">M</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading181">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse181" aria-expanded="true"
                                    aria-controls="collapse181">
                                Management
                            </button>
                        </h2>
                    </div>
                    <div id="collapse181" className="collapse show" aria-labelledby="heading181"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Accountable for the control of something. See also: management meetings, management review, management system
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading182">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse182" aria-expanded="false"
                                    aria-controls="collapse182">
                                Management action plan
                            </button>
                        </h2>
                    </div>
                    <div id="collapse182" className="collapse" aria-labelledby="heading182"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An action plan which is created to ensure that the objectives set by management are achieved.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading183">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse183" aria-expanded="false"
                                    aria-controls="collapse183">
                                Management commitment
                            </button>
                        </h2>
                    </div>
                    <div id="collapse183" className="collapse" aria-labelledby="heading183"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A term used to describe the business' commitment to something. Typically, it's commitment to ensuring that  overall the material or service supplied is safe, legal, authentic and of the quality agreed. A lack of management commitment would be indicated where the required resources are not provided to meet the requirements of the Standard or customer codes of practice.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading184">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse184" aria-expanded="false"
                                    aria-controls="collapse184">
                                Management meetings
                            </button>
                        </h2>
                    </div>
                    <div id="collapse184" className="collapse" aria-labelledby="heading184"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The management team are responsible for making sure that their teams complete the actions that they have been set. To make sure action plans are being completed as agreed, the management team must review progress and where there are issues they must intervene.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading185">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse185" aria-expanded="false"
                                    aria-controls="collapse185">
                                Management meetings
                            </button>
                        </h2>
                    </div>
                    <div id="collapse185" className="collapse" aria-labelledby="heading185"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Meetings held by management routinely throughout the year to assess performance of the departmental performance reviews, against the business objectives.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading186">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse186" aria-expanded="false"
                                    aria-controls="collapse186">
                                Management review
                            </button>
                        </h2>
                    </div>
                    <div id="collapse186" className="collapse" aria-labelledby="heading186"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            It is management's responsibility to ensure that the site is continually meeting the requirements of the standard. This includes making sure that action plans are met and that all aspects of the management system are effective.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading187">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse187" aria-expanded="false"
                                    aria-controls="collapse187">
                                Management system
                            </button>
                        </h2>
                    </div>
                    <div id="collapse187" className="collapse" aria-labelledby="heading187"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A documented process which ensures compliance to the standard. Documents can be in hard copy (paper) format or digital.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading188">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse188" aria-expanded="false"
                                    aria-controls="collapse188">
                                Materials
                            </button>
                        </h2>
                    </div>
                    <div id="collapse188" className="collapse" aria-labelledby="heading188"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A term to describe a physical thing. In the food industry this is a collective term which includes finished product, ingredients, packaging, raw materials, work-in-progress materials, rework.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading189">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse189" aria-expanded="false"
                                    aria-controls="collapse189">
                                Media
                            </button>
                        </h2>
                    </div>
                    <div id="collapse189" className="collapse" aria-labelledby="heading189"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The materials that are used to produce or do something. See also; Printing or reproduction media, Plate media, Communication media
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading190">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse190" aria-expanded="false"
                                    aria-controls="collapse190">
                                Meeting
                            </button>
                        </h2>
                    </div>
                    <div id="collapse190" className="collapse" aria-labelledby="heading190"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A way of communicating.  Used to share information, update other personnel on progress or agree actions. See also; meeting records, management meetings
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading191">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse191" aria-expanded="false"
                                    aria-controls="collapse191">
                                Meeting records
                            </button>
                        </h2>
                    </div>
                    <div id="collapse191" className="collapse" aria-labelledby="heading191"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Meeting records may be minutes, or a note of what was generally discussed, with a list of agreed actions. The main point is to make sure that anything that was agreed in the meeting, will be remembered, actioned and also followed up to ensure completion.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading192">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse192" aria-expanded="false"
                                    aria-controls="collapse192">
                                Methods of communication
                            </button>
                        </h2>
                    </div>
                    <div id="collapse192" className="collapse" aria-labelledby="heading192"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The ways in which information is communicated, for example; tool box talks, staff briefings, on notice boards, in written form like letters or emails, meetings, training sessions or posters on notice boards.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading193">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse193" aria-expanded="false"
                                    aria-controls="collapse193">
                                Micrometre (μm)
                            </button>
                        </h2>
                    </div>
                    <div id="collapse193" className="collapse" aria-labelledby="heading193"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A form of measurement. Micrometre is the international spelling. Micrometer is the American spelling. A micrometre is commonly known as a micron. 1000 micrometres is 1 millimetre (mm).
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading194">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse194" aria-expanded="false"
                                    aria-controls="collapse194">
                                Misrepresentation
                            </button>
                        </h2>
                    </div>
                    <div id="collapse194" className="collapse" aria-labelledby="heading194"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To mispresent a product, is to pretend it's something it's not. This would include fake products. This is a form of fraud.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading195">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse195" aria-expanded="false"
                                    aria-controls="collapse195">
                                Mock-up
                            </button>
                        </h2>
                    </div>
                    <div id="collapse195" className="collapse" aria-labelledby="heading195"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A physical example of what something should look like. Used in packaging development. Sometimes also called a prototype.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading196">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse196" aria-expanded="false"
                                    aria-controls="collapse196">
                                Monitor
                            </button>
                        </h2>
                    </div>
                    <div id="collapse196" className="collapse" aria-labelledby="heading196"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Monitoring is a term used for checking that something is working.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading197">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse197" aria-expanded="false"
                                    aria-controls="collapse197">
                                Monitored
                            </button>
                        </h2>
                    </div>
                    <div id="collapse197" className="collapse" aria-labelledby="heading197"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Monitoring the results of objectives, is to check that the objectives have been successful and where they are not on track, adding in additional actions to make sure they are achieved.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading198">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse198" aria-expanded="false"
                                    aria-controls="collapse198">
                                Monitoring
                            </button>
                        </h2>
                    </div>
                    <div id="collapse198" className="collapse" aria-labelledby="heading198"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Monitoring is a term used for checking that something is working.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompM;