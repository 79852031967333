import { RISK_ASSESMENT } from "../../Routes/Api";
import request from "../../services/request";
import { RISK_ASSESMENT_CONSTANT } from "../constants";
import session from "../../services/session";

const getUserAssesmentListSuccess = (payload) => {
    return {
        type: RISK_ASSESMENT_CONSTANT.GET_RISK_ASSESMENT_SUCCESS,
        payload,
    };
};

export const filterRiskList = (payload) => {
    return {
        type: RISK_ASSESMENT_CONSTANT.FILTER_RISK_LIST,
        payload,
    };
};

// Get Audit by User
export const getAssesmentByUser = (orgId = session.getOrgId()) => {
    return (dispatch) => {
        request
            .get(`${RISK_ASSESMENT.GET_RISK_ASSESMENT}/${orgId}`, true, false)
            .then(
                (res) => {
                    dispatch(getUserAssesmentListSuccess(res?.data));
                },
                (error) => { }
            );
    };
};
