import { KPI_CONST } from "../constants";

const reducer = (
    state = {
        kpiList: [],
        chartType: "bar",
    },
    { type, payload }
) => {
    switch (type) {
        case KPI_CONST.CURRENT_KPI_LIST:
            return {
                ...state,
                kpiList: payload,
            };
        case KPI_CONST.CURRENT_CHART_TYPE:
            return {
                ...state,
                chartType: payload,
            };
        default:
            return state;
    }
};

export default reducer;
