import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = (props) => {
  const { statusData } = props;

  const getColors = (statuses) => {
    let colors = [];
    for (const status of statuses) {
      if (
        status === "Draft" ||
        status === "Sent" ||
        status === "Accepted" ||
        status === "Reassigned" ||
        status === "Closed late" ||
        status === "Delegated" ||
        status === "Completed late and verified late" ||
        status === "Due" ||
        status === "Completed late" ||
        status === "Partly complete" ||
        status === "Completed and verified late"
      ) {
        colors.push("#f6921e");
      } else if (
        status === "In progress" ||
        status === "Verified late" ||
        status === "Completed" ||
        status === "Verified"
      ) {
        colors.push("#009345");
      } else if (
        status === "Overdue" ||
        status === "Partly completed late" ||
        status === "Missed" ||
        status === "Incomplete late" ||
        status === "Completed late" ||
        status === "Completed late and verified"
      ) {
        colors.push("#ff0000");
      } else if (status === "Closed") {
        colors.push("#00b0f0");
      }
    }
    return colors;
  };

  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: statusData?.statusKeys,
    colors: getColors(statusData?.statusKeys),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, w }) {
        var dataPointId = w.config.series[seriesIndex];
        var label = w.config.labels[seriesIndex];
        return (
          '<div class="arrow_box">' +
          "<div>" +
          label +
          "</div>" +
          "<div>" +
          "Count" +
          ": " +
          dataPointId  +
          "</div>" +
          "</div>"
        );
      },
    },
  };

  return (
    <div id="chart" style={{ width: "480px", margin: "auto" }}>
      <ReactApexChart options={options} series={statusData?.statusValues} type="pie" />
    </div>
  );
};

export default PieChart;
