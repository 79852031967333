import { AUDIT_CONSTANTS } from "../constants";

const reducer = (
    state = {
        object: { ...AUDIT_CONSTANTS.GET_DEFAULT_AUDIT_OBJ() },
        list: [],
        filteredSaList: [],
        error: {},
        questions: [],
        userSA: [],
        success: false,
        send: false,
        current_index: null,
        modalToggle: true,
    },
    { type, payload }
) => {
    switch (type) {
        case AUDIT_CONSTANTS.CREATE_AUDIT_SUCCESS:
            return {
                ...state,
                object: payload,
                disabled: false,
                success: true,
                modalToggle: false,
                send: false,
            };

        case AUDIT_CONSTANTS.TOGGLE_AUDIT_SUCCESS_MODAL:
            return {
                ...state,
                success: payload,
                send: payload,
                modalToggle: true,
            };

        case AUDIT_CONSTANTS.TOGGLE_AUDIT_ERROR_MODAL:
            return {
                ...state,
                disabled: false,
                error: { show: payload },
            };

        case AUDIT_CONSTANTS.AUDIT_RESPONSE_ERROR:
            return {
                ...state,
                disabled: false,
                error: { ...payload, show: true },
            };

        case AUDIT_CONSTANTS.GET_AUDIT_USER_LIST_SUCCESS:
            return {
                ...state,
                userSA: payload,
                roleSuccess: false,
            };

        case AUDIT_CONSTANTS.PUSH_SA_IN_LIST:
            let saArr = [payload];
            return {
                ...state,
                userSA: saArr,
            };

        case AUDIT_CONSTANTS.UPDATE_SA_QUESTIONS:
            const findIndex = state?.userSA.findIndex((item) => {
                return item._id === payload.saId;
            });
            let tempArr = state?.userSA;
            tempArr[findIndex].saQuestions = payload?.questions
                ? payload?.questions
                : payload?.questionData;
            return {
                ...state,
                userSA: tempArr,
            };

        case AUDIT_CONSTANTS.SA_QUESTIONS_SUCCESS:
            const Index = state?.userSA.findIndex((item) => {
                return item._id === payload._id;
            });
            let tempSA = state?.userSA;
            tempSA[Index] = payload;
            return {
                ...state,
                userSA: tempSA,
            };

        case AUDIT_CONSTANTS.FILTER_SA_LIST:
            return { ...state, filteredSaList: payload };

        case AUDIT_CONSTANTS.EMPTY_FILTER_SA_LIST:
            return {
                ...state,
                filteredSaList: [],
            };

        case AUDIT_CONSTANTS.SA_QUESTION_CURRENT_INDEX:
            return { ...state, current_index: payload };

        case AUDIT_CONSTANTS.UPDATE_SA_OBJECT:
            return {
                ...state,
                object: payload,
            };

        default:
            return state;
    }
};

export default reducer;
