import { STANDARDS_CONSTANTS } from "../constants";

const reducer = (
    state = { list: [], logsStandards: [], updateError: {}, success: false },
    { type, payload }
) => {
    switch (type) {
        case STANDARDS_CONSTANTS.GET_STANDARDS_LIST:
            return { ...state, list: payload };
        case STANDARDS_CONSTANTS.GET_STANDARDS_LIST_SUCCESS:
            return { ...state, list: payload };
        case STANDARDS_CONSTANTS.GET_STANDARDS_LIST_ERROR:
            return payload;
        case STANDARDS_CONSTANTS.GET_LOG_STANDARDS_LIST_SUCCESS:
            return { ...state, logsStandards: payload };
        default:
            return state;
    }
};

export default reducer;
