import React from "react";
import errorIcon from "../../Assets/images/error 1x.png";

const RemoveMultisiteModal = (props) => {
    const { id, removeMultisite, siteIndex, closeModal } = props;

    return (
        <div className="save-modal">
            <div
                className="modal fade show"
                id={`${"clearModal"}-${id}`}
                tabIndex="-1"
                aria-labelledby="clearModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center mb-4">
                                    <img
                                        src={errorIcon}
                                        alt="Warning"
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 mb-4">
                                        Do you want to disconnect this site?
                                    </h2>
                                    <h3 className="color-05 fs-18 fw-600 mb-4">
                                        Are you sure the site you are disconnecting has a single
                                        site system admin?
                                    </h3>
                                    <div className="float-right mt-4">
                                        <button
                                            className="btn-no mr-2 p-2 fs-16"
                                            onClick={() => closeModal(false)}
                                        >
                                            No, Cancel
                                        </button>
                                        <button
                                            className="btn-yes p-2 fs-16"
                                            onClick={() => removeMultisite(siteIndex)}
                                        >
                                            Yes, Disconnect
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default RemoveMultisiteModal;
