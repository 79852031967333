import React, { useEffect, useState } from "react";
import Searchbar from "../Home/TodoLIst/Searchbar";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersApi, editUserObject } from "../../store/actions/user";
import { changeTabState } from "../../store/actions/tabState";
import utils from "../../utils";
import { APP_CONSTANTS } from "../../constants";
import EditUserPermissionModal from "../Modals/EditUserPermissionModal";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "50px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const Overview = (props) => {
    const { setListData, handleFilterListData } = props;
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem("user"));

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [editPermissions, setEditPermissions] = useState(false);
    const [azureModal, setAzureModal] = useState(false);

    const { list } = useSelector((state) => state.user);

    const editUser = (row) => {
        if (row?.isAzure) {
            setAzureModal(true);
            return;
        }
        if (row?.loginType === "multi") {
            setEditPermissions(true);
            return;
        }
        if (
            (utils.checkPermissions(APP_CONSTANTS.PERMISSION_CONST.CREATE_USER) &&
                // user?.loginType === row?.loginType
                user?.permissions?.general?.system_manager) ||
            user?.userType === "superAdmin"
        ) {
            dispatch(editUserObject(row));
            dispatch(changeTabState(3));
        } else {
            setEditPermissions(true);
        }
    };

    const columns = [
        {
            name: "User",
            selector: (row) => row?.user_name,
            cell: (row) => (
                // utils.checkSubscription() && (
                <div onClick={() => editUser(row)} className="cursor">
                    <a className="color-05 user-name-link ">{row?.user_name}</a>
                    {row?.loginType === "multi" && row?.userType === "admin" ? (
                        <p className="mb-0">(multi-site admin)</p>
                    ) : row?.loginType === "multi" && row?.userType === "regular" ? (
                        <p className="mb-0">(multi-site user)</p>
                    ) : null}
                </div>
            ),
            // ),
            width: "250px",
            sortable: true,
        },
        {
            name: "System manager",
            selector: (row) => row?.permissions?.general?.system_manager,
            sortable: true,
            cell: (row) => {
                return (
                    row?.permissions?.general?.system_manager && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
        {
            name: "Accounts",
            selector: (row) => row?.permissions?.general?.accounts,
            sortable: true,
            cell: (row) => {
                return (
                    row?.permissions?.general?.accounts && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
        {
            name: "RCA",
            selector: (row) => row?.permissions?.actions?.root_cause_analysis,
            sortable: true,
            cell: (row) => {
                return (
                    row?.permissions?.actions?.root_cause_analysis && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
        {
            name: "Verifier",
            selector: (row) => row?.permissions?.actions?.verifier,
            sortable: true,
            cell: (row) => {
                return (
                    row?.permissions?.actions?.verifier && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
        {
            name: "Identifier",
            selector: (row) => row?.permissions?.actions?.identifier,
            sortable: true,
            cell: (row) => {
                return (
                    row?.permissions?.actions?.identifier && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
        {
            name: "System audit accountable manager",
            selector: (row) => row?.permissions?.system_audits?.account_manager,
            sortable: true,
            cell: (row) => {
                return (
                    row?.permissions?.system_audits?.account_manager && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
        {
            name: "Auditor",
            selector: (row) => row?.permissions?.system_audits?.auditor,
            sortable: true,
            cell: (row) => {
                return (
                    row?.permissions?.system_audits?.auditor && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
        {
            name: "GMP inspection accountable manager",
            selector: (row) => row?.permissions?.gmp_inspections?.accountable_manager,
            sortable: true,
            cell: (row) => {
                return (
                    row?.permissions?.gmp_inspections?.accountable_manager && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
        {
            name: "Inspector",
            selector: (row) => row?.permissions?.gmp_inspections?.inspector,
            sortable: true,
            cell: (row) => {
                return (
                    row?.permissions?.gmp_inspections?.inspector && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
        {
            name: "Disabled",
            selector: (row) => row?.disable_user,
            sortable: true,
            cell: (row) => {
                return (
                    row?.disable_user && (
                        <i className="fas fa-check green-check-mark text-center" />
                    )
                );
            },
        },
    ];

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataUsers = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataUsers);
    };

    // const handleChange = ({ selectedRows }) => {
    //     // You can set state or dispatch with something like Redux so we can use the retrieved data
    //     // console.log("Selected Rows: ", selectedRows);
    // };

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                list?.filter((item) => {
                    if (
                        item?.user_name
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item.email?.toLowerCase().includes(searchValue?.toLowerCase())
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div>
                <Searchbar
                    onFilter={(e) => filterData(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                    type="users"
                />
            </div>
        );
    }, [filterText, filteredList]);

    const handleRowSelection = ({ selectedRows }) => {
        handleFilterListData(selectedRows);
    };

    useEffect(() => {
        dispatch(getAllUsersApi());
    }, []);

    useEffect(() => {
        setFilteredList([...list]);
        setListData([...list]);
    }, [list]);

    useEffect(() => {
        handleFilterListData(filteredList);
    }, [filteredList]);

    return (
        <>
            <div>
                <DataTable
                    columns={columns}
                    data={filteredList}
                    selectableRows
                    customStyles={customStyles}
                    // onSelectedRowsChange={handleChange}
                    onSelectedRowsChange={handleRowSelection}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                    pagination
                    onRowClicked={handleRowClicked}
                    conditionalRowStyles={conditionalRowStyles}
                    fixedHeaderScrollHeight="calc(100vh - 400px)"
                    fixedHeader={true}
                />
            </div>

            {editPermissions && (
                <>
                    <div
                        className="modal_backdrop"
                    // onClick={() => {
                    //     setEditPermissions(false);
                    // }}
                    ></div>
                    <EditUserPermissionModal
                        h2={APP_CONSTANTS.EDIT_USER_PERMISSION_MODAL.h2}
                        h3={APP_CONSTANTS.EDIT_USER_PERMISSION_MODAL.h3}
                        closeModal={setEditPermissions}
                        type="editUser"
                    />
                </>
            )}
            {azureModal && (
                <>
                    <div
                        className="modal_backdrop"
                    // onClick={() => {
                    //     setEditPermissions(false);
                    // }}
                    ></div>
                    <EditUserPermissionModal
                        h2={APP_CONSTANTS.EDIT_USER_PERMISSION_MODAL.h2}
                        h3="Please contact Azure admin to make changes to this user."
                        closeModal={setAzureModal}
                        type="azureModal"
                    />
                </>
            )}
        </>
    );
};

export default Overview;
