import { R_C_A } from "../constants";
import request from "../../services/request";
import { RCA } from "../../Routes/Api";
import { APP_CONSTANTS } from "../../constants";

export const createRcaSuccess = (payload) => {
    return {
        type: R_C_A.CREATE_RCA_SUCCESS,
        payload,
    };
};

export const updateRca = (payload) => {
    return {
        type: R_C_A.UPDATE_RCA,
        payload,
    };
};

export const addAttachment = (payload) => {
    return {
        type: R_C_A.ADD_RCA_ATTACHMENT,
        payload,
    };
};

export const toggleSuccessModal = (payload) => {
    return {
        type: R_C_A.TOGGLE_RCA_SUCCESS_MODAL,
        payload,
    };
};

export const toggleErrorModal = (payload) => {
    return {
        type: R_C_A.TOGGLE_RCA_ERROR_MODAL,
        payload,
    };
};

export const createRcaAPi = (data, from) => {
    return (dispatch) => {
        request.post(RCA.CREATE_RCA, data, true, true).then(
            (res) => {
                dispatch(createRcaSuccess(res?.data));
                setTimeout(() => {
                    dispatch(toggleSuccessModal({ success: false }));
                    window.location.href = "/";
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: error }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const createAndSendRcaAPi = (data, from) => {
    return (dispatch) => {
        request.post(RCA.CREATE_RCA, data, true, true).then(
            (res) => {
                dispatch(sendRcaAPi({ _id: res?.data?._id, nc: res?.data?.nc }));
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: error }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const getRcaByIdAPi = (nc, org) => {
    return (dispatch) => {
        request.get(`${RCA.GET_RCA_ID}${org}/${nc}`, true).then(
            (res) => {
                if (res?.data) dispatch(updateRca(res?.data));
            },
            (error) => {
                /*dispatch(toggleErrorModal({show:true,error:error}));
                                    setTimeout(()=>{
                                        dispatch(toggleErrorModal({show:false,error:error}))
                                    },APP_CONSTANTS.MODAL_SHOW_TIME)*/
            }
        );
    };
};

export const updateRcaAPi = (data, from) => {
    return (dispatch) => {
        request.post(RCA.UPDATE_RCA, data, true, true).then(
            (res) => {
                dispatch(updateRca(res?.data));
                dispatch(toggleSuccessModal({ success: true }));
                setTimeout(() => {
                    dispatch(toggleSuccessModal({ success: false }));
                    window.location.href = "/";
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: error }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const sendRcaAPi = (data, from) => {
    return (dispatch) => {
        request.post(RCA.SEND_RCA, data, true, false).then(
            (res) => {
                dispatch(updateRca(res?.data));
                dispatch(toggleSuccessModal({ send: true }));
                setTimeout(() => {
                    dispatch(toggleSuccessModal({ send: false }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: error }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};
