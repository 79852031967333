import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    getTodoListApi,
    filterTodoList,
    getMultisiteTodoListApi,
} from "../../../store/actions/todoList";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { APP_CONSTANTS } from "../../../constants";
import Searchbar from "./Searchbar";
import DateField from "../../Date/DateField";
import ReassignModal from "../../Modals/ReassignModal";
import SendGmpToInspectorModal from "../../Modals/SendGmpToInspectorModal";
import SendSAModal from "../../Modals/SendSAModal";
import ReassignSAModal from "../../Modals/ReassignSAModal";
import session from "../../../services/session";
import { generateSequence } from "../../../utils/referenceId";
import { updateNCObject } from "../../../store/actions/nc";
import { changeTabState } from "../../../store/actions/tabState";
import EditNcPermission from "../../Modals/EditNcPermission";
import { updateProObject } from "../../../store/actions/pro";
import utils from "../../../utils";
import SendNcModal from "../../Modals/SendNcModal";
import SendProModal from "../../Modals/SendProModal";
import { updateSAObject } from "../../../store/actions/audit";
import { updateGMPObject } from "../../../store/actions/gmp";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "30px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const TodoListTlb = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [filterText, setFilterText] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [filterDate, setFilterDate] = useState({ from: "", to: "" });
    const [reassignToggle, setReassignToggle] = useState(false);
    const [sendGmpToggle, setSendGmpToggle] = useState(false);
    const [GMPId, setGMPId] = useState("");
    const [ids, setIds] = useState("");
    const [reassignSAToggle, setReassignSAToggle] = useState(false);
    const [sendSAToggle, setSendSAToggle] = useState(false);
    const [saId, setSAId] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [reassignSAData, setReassignSAData] = useState({});
    const [permission, setPermission] = useState(false);
    const [sendNcToggle, setSendNcToggle] = useState(false);
    const [sendProToggle, setSendProToggle] = useState(false);
    const [ncSendObject, setNcSendObject] = useState({});
    const [proSendObject, setProSendObject] = useState({});

    const { list } = useSelector((state) => state.todoList);

    const org = JSON.parse(localStorage.getItem("org"));
    const user = JSON.parse(localStorage.getItem("user"));
    const { multisite_permissions } = JSON.parse(localStorage.getItem("user"));

    const toggleReassignModal = (flag) => {
        setReassignToggle(flag);
    };

    const toggleSendGmpModal = (flag) => {
        setSendGmpToggle(flag);
    };

    const toggleReassignSAModal = (flag) => {
        setReassignSAToggle(flag);
    };

    const toggleSendSAModal = (flag) => {
        setSendSAToggle(flag);
    };

    const getTodoList = () => {
        if (!org?._id) {
            dispatch(getMultisiteTodoListApi());
        } else {
            dispatch(getTodoListApi());
        }
    };

    const handleRowSelection = ({ selectedRows }) => {
        dispatch(filterTodoList(selectedRows));
    };

    const editSelectedObject = (data) => {
        if (data.type === "NC") {
            dispatch(updateNCObject({ ...data }));
            dispatch(changeTabState(6));
        } else if (data?.type === "PRO") {
            dispatch(updateProObject({ ...data }));
            dispatch(changeTabState(7));
        } else if (data?.type === "SA") {
            dispatch(updateSAObject(data));
            dispatch(changeTabState(8));
        } else if (data?.type === "GMP") {
            dispatch(updateGMPObject(data));
            dispatch(changeTabState(9));
        }
    };

    const redirectReference = (row) => {
        if (user?.loginType === "multi" && !org?._id) {
            const index = multisite_permissions?.general?.accounts?.sites?.findIndex(
                (item) => item.value === row?.org?._id
            );

            let permissions = {
                default_user_roles: {
                    proactive_action: true,
                    corrective_action: true,
                },
                general: {
                    system_manager: false,
                    accounts: false,
                },
                actions: {
                    root_cause_analysis: false,
                    verifier: false,
                    identifier: false,
                },
                system_audits: {
                    account_manager: false,
                    auditor: false,
                },
                gmp_inspections: {
                    accountable_manager: false,
                    inspector: false,
                },
            };
            if (multisite_permissions.general.system_manager.sites[index].enable) {
                permissions.general.system_manager = true;
            }
            if (multisite_permissions.general.accounts.sites[index].enable) {
                permissions.general.accounts = true;
            }
            if (
                multisite_permissions.actions.root_cause_analysis.sites[index].enable
            ) {
                permissions.actions.root_cause_analysis = true;
            }
            if (multisite_permissions.actions.verifier.sites[index].enable) {
                permissions.actions.verifier = true;
            }
            if (multisite_permissions.actions.identifier.sites[index].enable) {
                permissions.actions.identifier = true;
            }
            if (
                multisite_permissions.system_audits.account_manager.sites[index].enable
            ) {
                permissions.system_audits.account_manager = true;
            }
            if (multisite_permissions.system_audits.auditor.sites[index].enable) {
                permissions.system_audits.auditor = true;
            }
            if (
                multisite_permissions.gmp_inspections.accountable_manager.sites[index]
                    .enable
            ) {
                permissions.gmp_inspections.accountable_manager = true;
            }
            if (multisite_permissions.gmp_inspections.inspector.sites[index].enable) {
                permissions.gmp_inspections.inspector = true;
            }

            session.setOrgId(row?.org?._id);
            session.setOrgPermissions(permissions);
            session.setOrg(row?.org);
        }
        if (row.type === "NC") {
            let state = { id: row?._id, nc: row };
            if (
                (user?.permissions?.general?.system_manager &&
                    row?.status === "Draft") ||
                (user?._id === row?.raised_by?.value && row?.status === "Draft")
            ) {
                editSelectedObject(row);
            } else if (
                !user?.permissions?.general?.system_manager &&
                row?.status === "Draft"
            ) {
                setPermission(true);
            } else {
                navigate(
                    `/private/reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?._id
                    }/1`,
                    { state }
                );
            }
        } else if (row?.type === "CA") {
            let state = { id: row?.nc, nc: row };
            if (
                row?.actioner?.value === user?._id &&
                (row?.status === "In progress" ||
                    row?.status === "Overdue" ||
                    row?.status === "Rejected")
            ) {
                navigate(
                    `/private/reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?.nc?._id
                    }/5`,
                    { state }
                );
            } else if (
                row?.verifier?.value === user?._id &&
                (row?.status === "Completed" || row?.status === "Completed late")
            ) {
                navigate(
                    `/private/reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?.nc?._id
                    }/6`,
                    { state }
                );
            } else if (
                row?.status === "Draft" &&
                row?.nc?.status !== "Closed" &&
                row?.nc?.status !== "Closed late"
            ) {
                navigate(
                    `/private/reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?.nc?._id
                    }/2`,
                    { state }
                );
            } else {
                navigate(
                    `/private/reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?.nc?._id
                    }/1`,
                    { state }
                );
            }
        } else if (row?.type === "PA") {
            let state = { id: row?.nc, nc: row };
            if (
                row?.actioner?.value === user?._id &&
                (row?.status === "In progress" ||
                    row?.status === "Overdue" ||
                    row?.status === "Rejected")
            ) {
                navigate(
                    `/private/reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?.nc?._id
                    }/5`,
                    { state }
                );
            } else if (
                row?.verifier?.value === user?._id &&
                (row?.status === "Completed" || row?.status === "Completed late")
            ) {
                navigate(
                    `/private/reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?.nc?._id
                    }/6`,
                    { state }
                );
            } else if (
                row?.status === "Draft" &&
                row?.nc?.status !== "Closed" &&
                row?.nc?.status !== "Closed late"
            ) {
                navigate(
                    `/private/reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?.nc?._id
                    }/4`,
                    { state }
                );
            } else {
                navigate(
                    `/private/reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?.nc?._id
                    }/1`,
                    { state }
                );
            }
        } else if (row?.type === "RCA") {
            let state = { id: row?.nc, nc: row };
            navigate(
                `/private/reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?.nc?._id
                }/3`,
                { state }
            );
        } else if (row?.type === "PRO") {
            let state = { id: row?._id, pro: row };
            if (
                (user?.permissions?.general?.system_manager &&
                    row?.status === "Draft") ||
                (user?._id === row?.raised_by?.value && row?.status === "Draft")
            ) {
                editSelectedObject(row);
            } else {
                navigate(
                    `/private/pro-reference/${row?.org?._id ? row?.org?._id : row?.org}/${row?._id
                    }`,
                    { state }
                );
                navigate(0);
            }
        } else if (row?.type === "GMP") {
            let state = { gmp: row };
            if (
                (user?.permissions?.general?.system_manager &&
                    row?.status === "Draft") ||
                (user?._id === row?.raised_by?.value && row?.status === "Draft")
            ) {
                editSelectedObject(row);
            } else {
                navigate(
                    `/private/gmp-inspection/${row?.org?._id ? row?.org?._id : row?.org
                    }/${row?._id}`,
                    { state }
                );
                navigate(0);
            }
        } else if (row?.type === "SA") {
            let state = { sa: row };
            if (
                (user?.permissions?.general?.system_manager &&
                    row?.status === "Draft") ||
                (user?._id === row?.raised_by?.value && row?.status === "Draft")
            ) {
                editSelectedObject(row);
            } else {
                navigate(
                    `/private/system-audit/${row?.org?._id ? row?.org?._id : row?.org}/${row?._id
                    }`,
                    { state }
                );
                navigate(0);
            }
        }
    };

    const filterData = React.useCallback(
        (searchValue) => {
            setFilteredList(
                list?.filter((item) => {
                    if (
                        item?.type?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item?.parentGmp?.gmpName
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.parentSA?.name
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.nc?.source?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.source?.label
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        `${item.type.toLowerCase()}-${generateSequence(item.id) || 0
                            }`.includes(searchValue?.toLowerCase()) ||
                        item?.non_conformance_notes
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.action_notes
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.corrective_action_notes
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.preventive_action_notes
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.notes?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item?.root_cause_analysis_notes
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                        item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        item?.grade?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        `${item?.org?.business_name?.toLowerCase()} - ${item?.org?.site?.toLowerCase()}`.includes(
                            searchValue?.toLowerCase()
                        )
                    ) {
                        return item;
                    }
                })
            );
        },
        [filteredList]
    );

    const dateFilter = (field, value) => {
        let to = null;
        let from = null;
        if (field === "from") {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        } else {
            setFilterDate((old) => {
                return { ...old, [field]: value };
            });
        }
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                /*setResetPaginationToggle(!resetPaginationToggle);*/
                setFilterText("");
            }
        };
        return (
            <div style={{ width: "100%" }} className="mt-2 mt-md-0 mb-2 mb-md-0">
                <div className="row">
                    <div className="col-md-8">
                        <DateField
                            filterData={(e) => dateFilter("from", e.target.value)}
                            raised={true}
                            dateValue={filterDate?.from}
                            label={"From:"}
                        />
                        <DateField
                            filterData={(e) => dateFilter("to", e.target.value)}
                            dateValue={filterDate?.to}
                            label={"To:"}
                        />
                    </div>
                    <div className="col-md-4">
                        <Searchbar
                            onFilter={(e) => filterData(e.target.value)}
                            onClear={handleClear}
                            filterText={filterText}
                        />
                    </div>
                </div>
            </div>
        );
    }, [filterText, filteredList, filterDate]);

    const dateFilteredData = () => {
        let tempArr = list;
        if (filterDate.from && filterDate.from !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(
                        item?.date_raised ? item?.date_raised : item?.createdAt
                    ).format(APP_CONSTANTS.DATE_FORMAT_1) >=
                    moment(filterDate.from).format(APP_CONSTANTS.DATE_FORMAT_1)
                ) {
                    return item;
                }
            });
        }

        if (filterDate.to && filterDate.to !== "") {
            tempArr = tempArr?.filter((item) => {
                if (
                    moment(
                        item?.date_raised ? item?.date_raised : item?.createdAt
                    ).format(APP_CONSTANTS.DATE_FORMAT_1) <=
                    moment(filterDate.to).format(APP_CONSTANTS.DATE_FORMAT_1)
                ) {
                    return item;
                }
            });
        }
        setFilteredList(tempArr);
        dispatch(filterTodoList(tempArr));
    };

    // reassign inepector in GMP
    const reassignInspector = (data) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setGMPId(data?.parentGmp ? data?.parentGmp?._id : data?._id);
            setIds(data?._id);
            toggleReassignModal(true);
        }
    };

    // reassign auditor in Audit
    const reassignAuditor = (data) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setSAId(data?._id);
            setOrganizationId(data?.org?._id);
            setReassignSAData(data);
            toggleReassignSAModal(true);
        }
    };

    // check user is inspector or lead auditor
    const isInspectorAndLeadAuditor = (user, data) => {
        if (data?.type === "GMP") {
            let tempBool = false;
            data?.inspectors?.forEach((item) => {
                if (item?.value === user) {
                    tempBool = true;
                }
            });
            return tempBool;
        } else {
            let tempBool = false;
            if (user === data?.lead_auditor?.value) {
                tempBool = true;
            }
            return tempBool;
        }
    };

    // send gmp to inspector
    const sendGmpToInspector = (data) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setGMPId(data?._id);
            toggleSendGmpModal(true);
        }
    };

    // send SA to auditor
    const sendSAToAuditor = (data) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setSAId(data?._id);
            toggleSendSAModal(true);
        }
    };

    // send NC
    const sendNC = (data) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setSendNcToggle(true);
            setNcSendObject(data);
        }
    };

    // send PRO
    const sendPro = (data) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setSendProToggle(true);
            setProSendObject(data);
        }
    };

    // useEffect(() => {
    //     getTodoList();
    // }, []);

    // useEffect(() => {
    //     if (!list.length) {
    //         getTodoList();
    //     }
    // }, []);

    useEffect(() => {
        dispatch(filterTodoList(filteredList));
    }, [filteredList]);

    useEffect(() => {
        setFilteredList(list);
    }, [list]);

    useEffect(() => {
        dateFilteredData();
    }, [filterDate]);

    useEffect(() => {
        getTodoList();
    }, [reassignToggle, sendGmpToggle, reassignSAToggle, sendSAToggle]);

    const columns = [
        {
            name: "Item",
            selector: (row) => row?.type,
            cell: (row) => (
                <a
                    className="edit-link user-name-link cursor"
                    onClick={() => redirectReference(row)}
                >
                    {row?.type}
                </a>
            ),
            sortable: true,
            width: "130px",
        },

        {
            name: "Source type",
            selector: (row) =>
                row?.parentGmp?.gmpName ||
                row?.parentSA?.name ||
                row?.source?.label ||
                row?.nc?.source?.label,
            cell: (row) => (
                <a
                    className="edit-link user-name-link cursor"
                    onClick={() => redirectReference(row)}
                >
                    {row?.parentGmp?.gmpName ||
                        row?.parentSA?.name ||
                        row?.source?.label ||
                        row?.nc?.source?.label}
                </a>
            ),
            sortable: true,
            width: "250px",
        },
        {
            name: "Reference",
            selector: (row) => row?.type + "-" + generateSequence(row?.id),
            cell: (row, index) => (
                <a
                    key={index}
                    className="edit-link user-name-link cursor"
                    onClick={() => redirectReference(row)}
                >
                    {row?.type + "-" + generateSequence(row?.id)}
                </a>
            ),
            sortable: true,
            width: "130px",
        },
        {
            name: "Date raised",
            selector: (row) =>
                row?.date_raised
                    ? moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT)
                    : moment(row?.createdAt).format(APP_CONSTANTS.DATE_FORMAT),
            sortable: true,
            cell: (row) =>
                row?.date_raised
                    ? moment(row?.date_raised).format(APP_CONSTANTS.DATE_FORMAT)
                    : moment(row?.createdAt).format(APP_CONSTANTS.DATE_FORMAT),
        },
        {
            name: "Due by",
            selector: (row) =>
                row?.due_date
                    ? moment(row?.due_date).format(APP_CONSTANTS.DATE_FORMAT)
                    : "",
            sortable: true,
            cell: (row) =>
                row?.due_date
                    ? moment(row?.due_date).format(APP_CONSTANTS.DATE_FORMAT)
                    : "",
        },
        {
            name: "Notes",
            selector: (row) =>
                row?.non_conformance_notes ||
                row?.action_notes ||
                row?.corrective_action_notes ||
                row?.preventive_action_notes ||
                row?.notes ||
                row?.root_cause_analysis_notes ||
                "",
            sortable: true,
            cell: (row) => (
                <div
                    className="py-2"
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {row?.non_conformance_notes ||
                        row?.action_notes ||
                        row?.corrective_action_notes ||
                        row?.preventive_action_notes ||
                        row?.notes ||
                        row?.root_cause_analysis_notes ||
                        ""}
                </div>
            ),
            maxWidth: "300px",
        },
        {
            name: "Status",
            selector: (row) => row?.status,
            cell: (row) => (
                <div data-toggle="tooltip" title={row?.status} className="py-1">
                    {row?.status}
                </div>
            ),
            sortable: true,
        },
        {
            name: "Grade",
            selector: (row) => row?.grade,
            sortable: true,
            cell: (row) => row?.grade,
        },
        {
            name: "Priority",
            selector: (row) => priorityColorSort(row?.status, row?.type),
            cell: (row, index) => {
                switch (row?.type) {
                    case "GMP":
                        if (row?.status === "Draft" || row?.status === "Due") {
                            return <span className="dot dot-orange ml-3" />;
                        } else if (row?.status === "In progress") {
                            return <span className="dot dot-green ml-3" />;
                        } else if (row?.status === "Overdue") {
                            return <span className="dot dot-red ml-3" />;
                        }
                        break;
                    case "SA":
                        if (row?.status === "Draft" || row?.status === "Due") {
                            return <span className="dot dot-orange ml-3" />;
                        } else if (row?.status === "In progress") {
                            return <span className="dot dot-green ml-3" />;
                        } else if (row?.status === "Overdue") {
                            return <span className="dot dot-red ml-3" />;
                        }
                        break;
                    case "CA":
                        if (row?.status === "Draft") {
                            return <span className="dot dot-orange ml-3" />;
                        } else if (
                            row?.status === "In progress" ||
                            row?.status === "Completed"
                        ) {
                            return <span className="dot dot-green ml-3" />;
                        } else if (
                            row?.status === "Overdue" ||
                            row?.status === "Completed late"
                        ) {
                            return <span className="dot dot-red ml-3" />;
                        }
                        break;
                    case "PA":
                        if (row?.status === "Draft") {
                            return <span className="dot dot-orange ml-3" />;
                        } else if (
                            row?.status === "In progress" ||
                            row?.status === "Completed"
                        ) {
                            return <span className="dot dot-green ml-3" />;
                        } else if (
                            row?.status === "Overdue" ||
                            row?.status === "Completed late"
                        ) {
                            return <span className="dot dot-red ml-3" />;
                        }
                        break;
                    case "PRO":
                        if (row?.status === "Draft") {
                            return <span className="dot dot-orange ml-3" />;
                        } else if (row?.status === "In progress") {
                            return <span className="dot dot-green ml-3" />;
                        } else if (row?.status === "Overdue") {
                            return <span className="dot dot-red ml-3" />;
                        }
                        break;
                    case "NC":
                        if (
                            row?.status === "Draft" ||
                            row?.status === "Sent" ||
                            row?.status === "Accepted" ||
                            row?.status === "Reassigned" ||
                            row?.status === "Delegated"
                        ) {
                            return <span className="dot dot-orange ml-3" />;
                        } else if (row?.status === "In progress") {
                            return <span className="dot dot-green ml-3" />;
                        } else if (row?.status === "Overdue") {
                            return <span className="dot dot-red ml-3" />;
                        }
                        break;
                    case "RCA":
                        if (row?.status === "In progress") {
                            return <span className="dot dot-green ml-3" />;
                        } else if (row?.status === "Overdue") {
                            return <span className="dot dot-red ml-3" />;
                        }
                        break;
                    default:
                        break;
                }
            },
            sortable: true,
        },
        {
            name: "Reassign",
            width: "100px",
            cell: (row) =>
                row?.type === "GMP" || row?.type === "SA" ? (
                    isInspectorAndLeadAuditor(user?._id, row) ? (
                        row?.status === "Due" ? (
                            <a
                                className="edit-link user-name-link cursor"
                                onClick={
                                    row?.type === "GMP"
                                        ? () => reassignInspector(row)
                                        : row?.type === "SA"
                                            ? () => reassignAuditor(row)
                                            : null
                                }
                            >
                                Reassign
                            </a>
                        ) : null
                    ) : null
                ) : null,
        },
        {
            name: "Send",
            width: "100px",
            cell: (row) =>
                (row?.type === "GMP" ||
                    row?.type === "SA" ||
                    row?.type === "NC" ||
                    row?.type === "PRO") &&
                    row?.status === "Draft" ? (
                    <a
                        className="edit-link user-name-link cursor"
                        onClick={
                            row?.type === "GMP"
                                ? () => sendGmpToInspector(row)
                                : row?.type === "NC"
                                    ? () => sendNC(row)
                                    : row?.type === "SA"
                                        ? () => sendSAToAuditor(row)
                                        : row?.type === "PRO"
                                            ? () => sendPro(row)
                                            : null
                        }
                    >
                        Send
                    </a>
                ) : null,
        },
    ];

    props?.type === "multisite" &&
        columns.splice(0, 0, {
            name: "Site",
            selector: (row) => `${row?.org?.business_name} - ${row?.org?.site}`,
            cell: (row) => (
                <a
                    className="edit-link user-name-link cursor"
                    onClick={() => redirectReference(row)}
                >
                    {row?.org?.business_name} - {row?.org?.site}
                </a>
            ),
            sortable: true,
            width: "250px",
        });

    const priorityColorSort = (status, type) => {
        switch (type) {
            case "GMP":
                if (status === "Draft" || status === "Due") {
                    return "Orange";
                } else if (status === "In progress") {
                    return "Green";
                } else if (status === "Overdue") {
                    return "Red";
                }
                break;
            case "SA":
                if (status === "Draft" || status === "Due") {
                    return "Orange";
                } else if (status === "In progress") {
                    return "Green";
                } else if (status === "Overdue") {
                    return "Red";
                }
                break;
            case "CA":
                if (status === "Draft") {
                    return "Orange";
                } else if (status === "In progress" || status === "Completed") {
                    return "Green";
                } else if (status === "Overdue" || status === "Completed late") {
                    return "Red";
                }
                break;
            case "PA":
                if (status === "Draft") {
                    return "Orange";
                } else if (status === "In progress" || status === "Completed") {
                    return "Green";
                } else if (status === "Overdue" || status === "Completed late") {
                    return "Red";
                }
                break;
            case "PRO":
                if (status === "Draft") {
                    return "Orange";
                } else if (status === "In progress") {
                    return "Green";
                } else if (status === "Overdue") {
                    return "Red";
                }
                break;
            case "NC":
                if (
                    status === "Draft" ||
                    status === "Sent" ||
                    status === "Accepted" ||
                    status === "Reassigned" ||
                    status === "Delegated"
                ) {
                    return "Orange";
                } else if (status === "In progress") {
                    return "Green";
                } else if (status === "Overdue") {
                    return "Red";
                }
                break;
            case "RCA":
                if (status === "In progress") {
                    return "Green";
                } else if (status === "Overdue") {
                    return "Red";
                }
                break;
            default:
                break;
        }
    };

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#cbc3c3",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row) => {
        const updatedDataTodoList = filteredList?.map((item) => {
            if (row?._id !== item?._id) {
                return item;
            }

            return {
                ...item,
                toggleSelected: !item?.toggleSelected,
            };
        });

        setFilteredList(updatedDataTodoList);
    };

    const customUniqueID = (row) => {
        return row?._id;
    };

    return (
        <div className="table-responsive">
            <DataTable
                columns={columns}
                data={filteredList}
                selectableRows
                customStyles={customStyles}
                striped
                onSelectedRowsChange={handleRowSelection}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                keyField={customUniqueID}
                pagination
                onRowClicked={handleRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
            />

            {reassignToggle && (
                <ReassignModal
                    toggleReassignModal={toggleReassignModal}
                    gmpId={GMPId}
                    id={ids}
                />
            )}

            {sendGmpToggle && (
                <SendGmpToInspectorModal
                    toggleSendGmpModal={toggleSendGmpModal}
                    id={"send_gmp_to_inspectors"}
                    gmpId={GMPId}
                />
            )}

            {reassignSAToggle && (
                <ReassignSAModal
                    toggleReassignModal={toggleReassignSAModal}
                    saId={saId}
                    orgId={organizationId}
                    reassignSAData={reassignSAData}
                />
            )}

            {sendSAToggle && (
                <SendSAModal
                    toggleSendSAModal={toggleSendSAModal}
                    id={"send_SA_to_leadAuditor"}
                    saId={saId}
                />
            )}

            {permission && (
                <>
                    <div className="modal_backdrop"></div>
                    <EditNcPermission closeModal={setPermission} />
                </>
            )}

            {sendNcToggle && (
                <SendNcModal
                    toggle={setSendNcToggle}
                    id={"send_Nc"}
                    ncObj={ncSendObject}
                />
            )}

            {sendProToggle && (
                <SendProModal
                    toggle={setSendProToggle}
                    id={"send_Pro"}
                    proObj={proSendObject}
                />
            )}
        </div>
    );
};

export default TodoListTlb;
