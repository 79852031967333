import { NC_CONSTANTS } from "../constants";
import { APP_CONSTANTS } from "../../constants";
import request from "../../services/request";
import { NC } from "../../Routes/Api";
import { changeTabState } from "./tabState";
import session from "../../services/session";

export const createNC = (payload) => {
    return {
        type: NC_CONSTANTS.CREATE_NC,
        payload,
    };
};

export const createNcSuccess = (payload) => {
    return {
        type: NC_CONSTANTS.CREATE_NC_SUCCESS,
        payload,
    };
};

export const updateNcSuccess = (payload) => {
    return {
        type: NC_CONSTANTS.UPDATE_NC_SUCCESS,
        payload,
    };
};

export const updateNCObject = (payload) => {
    return {
        type: NC_CONSTANTS.UPDATE_NC_OBJECT,
        payload,
    };
};

export const addAttachment = (payload) => {
    return {
        type: NC_CONSTANTS.ADD_ATTACHMENT,
        payload,
    };
};

export const toggleSuccessModal = (payload) => {
    return {
        type: NC_CONSTANTS.TOGGLE_NC_SUCCESS_MODAL,
        payload,
    };
};

export const toggleSendModal = (payload) => {
    return {
        type: NC_CONSTANTS.TOGGLE_NC_SEND_MODAL,
        payload,
    };
};

export const NcResponseError = (payload) => {
    return {
        type: NC_CONSTANTS.NC_RESPONSE_ERROR,
        payload,
    };
};

export const NcSentResponseError = (payload) => {
    return {
        type: NC_CONSTANTS.NC_RESPONSE_ERROR,
        payload,
    };
};

export const toggleNcSendErrorModal = (payload) => {
    return {
        type: NC_CONSTANTS.TOGGLE_NC_ERROR_MODAL,
        payload,
    };
};

export const toggleErrorModal = (payload) => {
    return {
        type: NC_CONSTANTS.TOGGLE_NC_ERROR_MODAL,
        payload,
    };
};

const getNcById = (payload) => {
    return {
        type: NC_CONSTANTS.GET_NC_BY_ID,
        payload,
    };
};

const getNcList = (payload) => {
    return {
        type: NC_CONSTANTS.GET_NC_LIST,
        payload,
    };
};

const getNcListSuccess = (payload) => {
    return {
        type: NC_CONSTANTS.GET_NC_LIST_SUCCESS,
        payload,
    };
};

export const clearNcObject = (payload) => {
    return {
        type: NC_CONSTANTS.CLEAR_NC_OBJECT,
        payload,
    };
};

export const filterNcList = (payload) => {
    return {
        type: NC_CONSTANTS.FILTER_NC_LIST,
        payload,
    };
};

export const loader = (payload) => {
    return {
        type: NC_CONSTANTS.NC_LOADER,
        payload,
    };
};

export const emptyList = (payload) => {
    return {
        type: NC_CONSTANTS.EMPTY_LIST,
        payload,
    };
};

export const createAndSendApi = (data) => {
    return (dispatch) => {
        request.post(NC.CREATE_NC, data, true, true).then(
            (res) => {
                dispatch(updateNCObject(res?.data));
                dispatch(sendNcApi({ _id: res?.data._id, update: true }));
            },
            (error) => {
                dispatch(NcResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const createNcAPi = (data, from) => {
    return (dispatch) => {
        dispatch(createNC());
        request.post(NC.CREATE_NC, data, true, true).then(
            (res) => {
                dispatch(createNcSuccess(res?.data));
                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    dispatch(changeTabState(1));
                    dispatch(clearNcObject({ ...NC_CONSTANTS.GET_DEFAULT_NC_OBJ() }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);

                /*history.push(from);*/
            },
            (error) => {
                dispatch(NcResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const updateNcAPi = (data, from) => {
    return (dispatch) => {
        request.post(NC.UPDATE_NC, data, true, true).then(
            (res) => {
                dispatch(updateNcSuccess(res?.data));
                setTimeout(() => {
                    dispatch(toggleSuccessModal(false));
                    if (from === "createNc") {
                        window.location.href = "/private/home";
                    } else if (from === "editNcFromProgrammeInfo") {
                        dispatch(changeTabState(3));
                    }
                    /*dispatch(clearNcObject({...NC_CONSTANTS.GET_DEFAULT_NC_OBJ()}))*/
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
                // window.location.href = `/private/reference/${res?.data?.org}/${res?.data?._id}/1`
                /*history.push(from);*/
            },
            (error) => {
                dispatch(NcResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const updateAndSendNcAPi = (data, rawData, from) => {
    const { delegate } = rawData;

    return (dispatch) => {
        request.post(NC.UPDATE_NC, data, true, true).then(
            (res) => {
                if (res?.data) {
                    if (delegate) {
                        dispatch(sendNcApi({ _id: res.data._id, delegate: delegate }));
                        // window.location.href = "/private/home"
                    } else {
                        dispatch(sendNcApi({ _id: res.data._id }));
                        // window.location.href = "/private/home"
                    }
                } else {
                    // console.log("response is here not");
                }
            },
            (error) => {
                dispatch(NcResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const sendNcApi = (data, from) => {
    return (dispatch) => {
        request.post(NC.SEND_NC, data, true).then(
            (res) => {
                dispatch(updateNCObject(res?.data));
                if (from === "ncSend") {
                    window.location.href = "/private/home";
                } else {
                    dispatch(toggleSendModal({ send: true }));
                    setTimeout(() => {
                        dispatch(toggleSendModal({ send: false }));
                        window.location.href = "/private/home";
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            },
            (error) => {
                dispatch(toggleSendModal({ error: { sentError: true, ...error } }));
                setTimeout(() => {
                    dispatch(toggleSendModal({ error: { sentError: false } }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const getNcyIdAPi = (ncId, orgId) => {
    return (dispatch) => {
        request.get(`${NC.GET_NC_BY_ID}${orgId}/${ncId}`, true).then(
            (res) => {
                dispatch(updateNCObject(res?.data));
            },
            (error) => {
                dispatch(NcResponseError(error));
                setTimeout(() => {
                    dispatch(toggleErrorModal(false));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

export const getNcListAPi = (
    userId = session.getUserId(),
    orgId = session.getOrgId()
) => {
    return (dispatch) => {
        dispatch(loader(true));
        request.get(`${NC.GET_NC_LIST_BY_USER}${orgId}/${userId}`, true).then(
            (res) => {
                dispatch(getNcListSuccess(res?.data));
                dispatch(loader(false));
            },
            (error) => {
                dispatch(loader(false));
            }
        );
    };
};
