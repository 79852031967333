import request from "../../services/request";
import { SCHEDULED_REPORTS } from "../../Routes/Api";
import session from "../../services/session";
import { SCHEDULED_REPORTS_CONSTANTS } from "../constants";

export const getScheduledReportsSuccess = (payload) => {
    return {
        type: SCHEDULED_REPORTS_CONSTANTS.GET_SCHEDULED_REPORTS_SUCCESS,
        payload,
    };
};

export const editScheduledReportsObject = (payload) => {
    return {
        type: SCHEDULED_REPORTS_CONSTANTS.EDIT_SCHEDULED_REPORTS_OBJECT,
        payload,
    };
};

export const getScheduledReportsApi = (org = session.getOrgId()) => {
    return (dispatch) => {
        request.get(`${SCHEDULED_REPORTS.SCHEDULED_REPORTS_API}/${org}`, true).then(
            (res) => {
                dispatch(getScheduledReportsSuccess(res?.data));
            },
            (error) => { }
        );
    };
};
