import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const TextField = styled.input`
  height: 32px;
  /*width: 200px;*/
  /*border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;*/
  border: 1px solid #707070;
  padding: 0 0px 0 10px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  /*border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;*/
  height: 32px;
  //width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Searchbar = ({ filterText, onFilter, onClear, type }) => {
	const [align, setAlign] = useState("");

	const styleCheck = () => {
		if (
			type === "users" ||
			type === "topicRoles" ||
			type === "gmp_roles" ||
			type === "reminder" ||
			type === "gmp_inspection_info" ||
			type === "nc"
		) {
			setAlign("");
		} else if (type === "gmp_inspections") {
			setAlign(
				"d-flex flex-row-reverse justify-content-end justify-content-sm-start mt-md-0"
			);
		} else {
			setAlign(
				"d-flex flex-row-reverse justify-content-end justify-content-md-start mt-3 mt-md-0 ml-2 ml-md-0"
			);
		}
	};

	useEffect(() => {
		styleCheck();
	}, []);

	return (
		<div className={align}>
			<ClearButton
				type="button"
				className="btn-search float-right ml-1"
				onClick={onClear}
			>
				<i className="far fa-search float-right" /> Search
			</ClearButton>
			<TextField
				className="float-right"
				id="search"
				type="text"
				placeholder=""
				aria-label="Search Input"
				/*value={filterText}*/
				onChange={onFilter}
			/>
		</div>
	);
};

export default Searchbar;
