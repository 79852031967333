import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import BackIcon from "../../../Assets/images/back-arrow.png";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { APP_CONSTANTS } from "../../../constants";
import CustomLoader from "../../CustomLoader/CustomLoader";
import GmpQuestionsDetail from "./GmpQuestionsDetail";
import statusGreen from "../../../Assets/images/status-green.png";
import statusOrange from "../../../Assets/images/status-orange.png";
import statusRed from "../../../Assets/images/status-red.png";
import CompleteItemModal from "../../Modals/CompleteItemModal";
import ClearItemModal from "../../Modals/ClearItemModal";
import {
    gmpQuestionCurrentIndex,
    pushGmpToList,
} from "../../../store/actions/gmp";
import ErrorModal from "../../Modals/ErrorModal";
import { generateSequence } from "../../../utils/referenceId";
import request from "../../../services/request";
import { GMP } from "../../../Routes/Api";
import utils from "../../../utils";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "25px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {
            textAlign: "center !important",
        },
    },
};

const GmpQuestions = () => {
    let params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    let gmp = state?.gmp;

    const { userGMPs, current_index } = useSelector((state) => state.gmp);

    const [loading, setLoading] = useState(false);
    const [csvExportHeader, setCsvExportHeader] = useState(
        APP_CONSTANTS.GMP_QUESTIONS_HEADERS
    );
    const [singleQuestion, setSingleQuestion] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentGMP, setCurrentGMP] = useState({});
    const [complete, setComplete] = useState(false);
    const [clear, setClear] = useState(false);
    const [show, setShow] = useState(false);
    const [sortedQuestions, setSortedQuestions] = useState([]);
    const [notesError, setNotesError] = useState(false);
    const [alwaysCheck, setAlwaysCheck] = useState(true);

    const user = JSON.parse(localStorage.getItem("user"));

    const handleModal = (key, value) => {
        switch (key) {
            case "complete":
                if (checkEmptyNotes()) {
                    setNotesError(true);
                    setTimeout(() => {
                        setNotesError(false);
                    }, 2000);
                } else {
                    setNotesError(false);
                    setComplete(value);
                }
                break;
            case "clear":
                setClear(value);
                break;
            default:
        }
    };

    function sortByKey(array, key) {
        return array?.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    const redirectToQuestionsDetail = async (gmpQ) => {
        const gmpidx = sortedQuestions.findIndex((obj) => obj._id === gmpQ._id);
        setSingleQuestion(true);
        setCurrentIndex(gmpidx);
    };

    const handleStatus = (data) => {
        if (data[0]?.yes) {
            return statusGreen;
        } else if (data[0]?.na) {
            return statusOrange;
        } else if (data[0]?.no) {
            return statusRed;
        }
    };

    const checkStatusOfGMP = (data) => {
        let available = true;
        data?.map((item) => {
            if (!item?.isAnswered) {
                available = false;
            }
        });
        return available;
    };

    const backToPrev = () => {
        setSingleQuestion(false);
        setAlwaysCheck(true);
    };

    const columns = [
        {
            name: "Question",
            selector: (row) => row?.subjectName[0].replace(/^\d+/, ""),
            maxWidth: "90%",
            minWidth: "100px",
            cell: (row, index) => (
                <a
                    className="redirect_detail fs-14"
                    onClick={() => redirectToQuestionsDetail(row)}
                >
                    {row?.subjectName[0]
                        .replace(/^\d+/, "")
                        .concat(", ", row?.subtitleIdName[0].replace(/^\d+/, ""))}
                </a>
            ),
        },
        {
            name: "Status",
            maxWidth: "10%",
            minWidth: "100px",
            cell: (row, index) => (
                <>
                    <img src={handleStatus(row?.answers)} />
                </>
            ),
        },
    ];

    let todoListCsvExportData = [];
    const arrangeDataForExport = (data) => {
        if (data?.length) {
            todoListCsvExportData = JSON.parse(JSON.stringify(data));
        }
        todoListCsvExportData = todoListCsvExportData?.map((obj, index) => {
            return APP_CONSTANTS.GMP_QUESTIONS_EXPORT_DATA_SET(obj);
        });
        return todoListCsvExportData;
    };

    const checkCurrentGmpInspectors = () => {
        let tempBool = false;
        currentGMP?.inspectors?.forEach((item) => {
            if (item?.value === user._id) {
                tempBool = true;
            }
        });
        return tempBool;
    };

    useEffect(() => {
        setLoading(true);
        const findIndex = userGMPs.findIndex((item) => {
            return item?._id === params?.gmpId;
        });
        if (userGMPs[findIndex]?._id) {
            if (userGMPs[findIndex]?.updatedAt < gmp?.updatedAt) {
                setCurrentGMP(gmp);
                dispatch(pushGmpToList(gmp));
            } else if (userGMPs[findIndex]?.updateAt >= gmp?.updatedAt) {
                setCurrentGMP(userGMPs[findIndex]);
            } else {
                setCurrentGMP(userGMPs[findIndex]);
            }
        } else if (gmp) {
            dispatch(pushGmpToList(gmp));
        } else {
            request.get(`${GMP.GMP_QUESTIONS}/${params?.gmpId}`, true, false).then(
                (res) => {
                    if (res?.status) {
                        const findIndex = userGMPs.findIndex((item) => {
                            return item?._id === params?.gmpId;
                        });
                        if (userGMPs[findIndex]?._id) {
                            if (userGMPs[findIndex]?.updatedAt < res?.data?.updatedAt) {
                                setCurrentGMP(res?.data);
                                dispatch(pushGmpToList(res?.data));
                            } else if (
                                userGMPs[findIndex]?.updateAt >= res?.data?.updatedAt
                            ) {
                                setCurrentGMP(res?.data);
                            } else {
                                setCurrentGMP(res?.data);
                            }
                        } else {
                            dispatch(pushGmpToList(res?.data));
                        }
                        setSortedQuestions(sortByKey(res?.data?.gmpQuestions, "gmQID"));
                        setLoading(false);
                    }
                },
                (error) => {
                    setLoading(false);
                }
            );
        }

        setSortedQuestions(
            sortByKey(
                userGMPs[findIndex]?._id
                    ? userGMPs[findIndex]?.gmpQuestions
                    : gmp?.gmpQuestions,
                "gmQID"
            )
        );

        setLoading(false);
    }, [handleModal]);

    // useEffect(() => {
    //     setLoading(true);

    // }, []);

    useEffect(() => {
        if (current_index) {
            setCurrentIndex(current_index);
            setSingleQuestion(true);
            dispatch(gmpQuestionCurrentIndex(null));
        }
    }, []);

    const checkEmptyNotes = () => {
        let count = 0;
        currentGMP.gmpQuestions?.map((item) => {
            if (item?.answers[0]?.no && (!item?.notes || item?.notes === "")) {
                count = count + 1;
            }
        });

        if (count > 0) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            {loading ? (
                <div className="pt-5">
                    <CustomLoader type="gmp_questions" />
                </div>
            ) : (
                <section
                    className="todo-list gmp_ref_question"
                    style={{ paddingTop: "15px" }}
                >
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-6 col-lg-10">
                                <h2 className="fs-18 fw-600 pt-2">
                                    <i className="far fa-bullhorn"></i>{" "}
                                    {currentGMP?.id && (
                                        <>
                                            {`Reference: ${currentGMP?.type && currentGMP?.type
                                                }-${generateSequence(currentGMP?.id)} ${currentGMP?.gmpName
                                                    ? currentGMP?.gmpName
                                                    : currentGMP?.parentGmp?.gmpName
                                                }`}
                                        </>
                                    )}
                                </h2>
                            </div>
                            {!singleQuestion ? (
                                <div className="col-6 col-lg-2">
                                    <NavLink
                                        to={"#"}
                                        className="back-to-pre fs-14 fw-300 pt-2 color-04 float-right"
                                        onClick={() => navigate(-1)}
                                    >
                                        <u>Back to previous page</u>{" "}
                                        <img src={BackIcon} width="14px" alt="Back to Pre" />
                                    </NavLink>
                                </div>
                            ) : (
                                <div className="col-6 col-lg-2">
                                    <NavLink
                                        to={"#"}
                                        className="back-to-pre fs-14 fw-300 pt-2 color-04 float-right"
                                        onClick={backToPrev}
                                    >
                                        <u>Back to previous page</u>{" "}
                                        <img src={BackIcon} width="14px" alt="Back to Pre" />
                                    </NavLink>
                                </div>
                            )}

                            {!singleQuestion && (
                                <div className="col-12 gmp_questions_export_btn">
                                    <CSVLink
                                        data={arrangeDataForExport(sortedQuestions)}
                                        headers={csvExportHeader}
                                        filename={"gmp-questions.csv"}
                                        className={"btn btn-export float-right mt-4"}
                                        asyncOnClick={true}
                                        onClick={(event, done) => {
                                            arrangeDataForExport(sortedQuestions);
                                            done();
                                        }}
                                    >
                                        <i className="far fa-file-export" /> Export
                                    </CSVLink>
                                </div>
                            )}
                        </div>

                        {singleQuestion ? (
                            <GmpQuestionsDetail
                                data={sortedQuestions}
                                index={currentIndex}
                                gmpId={params?.gmpId}
                                actionPrev={backToPrev}
                                showButton={setShow}
                                inspectors={currentGMP?.inspectors}
                                status={currentGMP?.status}
                                refresh={setAlwaysCheck}
                            />
                        ) : (
                            <div className="gmp_questions">
                                <DataTable
                                    columns={columns}
                                    data={sortedQuestions}
                                    customStyles={customStyles}
                                    pagination
                                    fixedHeaderScrollHeight="calc(100vh - 400px)"
                                    fixedHeader={true}
                                />
                            </div>
                        )}

                        {utils.checkSubStatus() &&
                            (checkCurrentGmpInspectors() ||
                                user?.permissions?.general?.system_manager) && (
                                <>
                                    {!singleQuestion && (
                                        <div className="float-right my-2">
                                            {currentGMP?.status !== "Completed" &&
                                                currentGMP?.status !== "Partly complete" &&
                                                currentGMP?.status !== "Missed" &&
                                                currentGMP?.status !== "Completed late" &&
                                                currentGMP?.status !== "Partly completed late" ? (
                                                <button
                                                    className="clear_item mr-2"
                                                    onClick={() => handleModal("clear", true)}
                                                    data-toggle="modal"
                                                    data-target="#clearModal"
                                                >
                                                    <i className="fal fa-backspace"></i> Clear item
                                                </button>
                                            ) : null}
                                            {checkStatusOfGMP(sortedQuestions) && (
                                                <>
                                                    {currentGMP?.status !== "Completed" &&
                                                        currentGMP?.status !== "Partly complete" &&
                                                        currentGMP?.status !== "Missed" &&
                                                        currentGMP?.status !== "Completed late" &&
                                                        currentGMP?.status !== "Partly completed late" ? (
                                                        <button
                                                            className="btn_clear_inspection"
                                                            onClick={() => handleModal("complete", true)}
                                                            data-toggle="modal"
                                                            data-target="#completeModal"
                                                        >
                                                            <i className="fal fa-check-double"></i> Complete
                                                            item
                                                        </button>
                                                    ) : null}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                    </div>

                    {clear && (
                        <ClearItemModal
                            id={"clear_item"}
                            h2={APP_CONSTANTS.CLEAR_ITEM.h2}
                            h3={APP_CONSTANTS.CLEAR_ITEM.h3}
                            p={APP_CONSTANTS.CLEAR_ITEM.p}
                            action={handleModal}
                            gmpId={params?.gmpId}
                        />
                    )}

                    {complete && (
                        <CompleteItemModal
                            id={"complete_item"}
                            h2={APP_CONSTANTS.COMPLETE_ITEM.h2}
                            h3={APP_CONSTANTS.COMPLETE_ITEM.h3}
                            action={handleModal}
                            gmpId={params?.gmpId}
                        />
                    )}

                    {notesError && (
                        <>
                            <div
                                className="modal_backdrop"
                                onClick={() => {
                                    setNotesError(false);
                                }}
                            ></div>{" "}
                            <ErrorModal type="gmpQuestionComplete" />
                        </>
                    )}
                </section>
            )}
        </>
    );
};

export default GmpQuestions;
