import React from 'react';

const CompG = () => {
    return (
        <>
            <h2 id="g">G</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading123">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse123" aria-expanded="true"
                                    aria-controls="collapse123">
                                General waste
                            </button>
                        </h2>
                    </div>
                    <div id="collapse123" className="collapse show" aria-labelledby="heading123"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Materials which are typically sent to landfill.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading124">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse124" aria-expanded="false"
                                    aria-controls="collapse124">
                                GFSI
                            </button>
                        </h2>
                    </div>
                    <div id="collapse124" className="collapse" aria-labelledby="heading124"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The Global Food Safety Initiative. The GFSI have a Benchmark Standard, which they use to assess certification standards. If the certification standard meets the Benchmark Standard, they're known as a 'GFSI recognised standard'.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading125">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse125" aria-expanded="false"
                                    aria-controls="collapse125">
                                GFSI
                            </button>
                        </h2>
                    </div>
                    <div id="collapse125" className="collapse" aria-labelledby="heading125"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Global Food Safety Initiative. A group led by the Consumer Group Forum. The GFSI benchmark standards to allow them to be compared in quality. Most customers will accept a GFSI recognised certification standard, which can be found at mygfsi.com
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading126">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse126" aria-expanded="false"
                                    aria-controls="collapse126">
                                GMP
                            </button>
                        </h2>
                    </div>
                    <div id="collapse126" className="collapse" aria-labelledby="heading126"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Good Manufacturing Practice. A set of site-wide controls which ensure that the product processed is safe, legal and to the required quality.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading127">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse127" aria-expanded="false"
                                    aria-controls="collapse127">
                                Good allergen practices
                            </button>
                        </h2>
                    </div>
                    <div id="collapse127" className="collapse" aria-labelledby="heading127"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Practices applied to minimise the risk of allergen cross-contamination.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading128">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse128" aria-expanded="false"
                                    aria-controls="collapse128">
                                Governance
                            </button>
                        </h2>
                    </div>
                    <div id="collapse128" className="collapse" aria-labelledby="heading128"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The management of business to ensure that it's conducted with integrity.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading129">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse129" aria-expanded="false"
                                    aria-controls="collapse129">
                                Governance
                            </button>
                        </h2>
                    </div>
                    <div id="collapse129" className="collapse" aria-labelledby="heading129"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Governance, governing, governed. A form of management. The term is typically used when referring to a large group, bodies or organisations that controls something beneath them.  For example, a head office would have governance over the sites within the group that report into them.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading130">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse130" aria-expanded="false"
                                    aria-controls="collapse130">
                                Grievance
                            </button>
                        </h2>
                    </div>
                    <div id="collapse130" className="collapse" aria-labelledby="heading130"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A grievance is where a someone feels that they have not personally been treated fairly. A grievance is usually between two people, or a person and a company. This is different from a wrongdoing where the issue affects the general public.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading131">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse131" aria-expanded="false"
                                    aria-controls="collapse131">
                                Gross weight
                            </button>
                        </h2>
                    </div>
                    <div id="collapse131" className="collapse" aria-labelledby="heading131"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The total weight. Typically, this is the weight of the product and the packaging.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading132">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse132" aria-expanded="false"
                                    aria-controls="collapse132">
                                Group
                            </button>
                        </h2>
                    </div>
                    <div id="collapse132" className="collapse" aria-labelledby="heading132"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Some sites are part of a larger group, which means the larger group has an impact on how the site is run. This is sometimes also referred to as the 'central function' or 'head office'. A group function can also be applied where there are a number of small 'hubs' which are managed by a central office.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}


            </div>
        </>
    );
};

export default CompG;