import LoginGroup from "../../Assets/images/group-shot.png";
import Logo from "../../Assets/images/Group 83.png";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logIn, azureLogIn } from "../../store/actions/user";
import { NavLink } from "react-router-dom";
import microsoftIcon from "../../Assets/images/microsoft-icon.png";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../azureConfig";

const Login = () => {
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const [credentials, setCredentials] = useState({});

    const { error } = useSelector((state) => state.user);

    const handleLogin = () => {
        dispatch(logIn(credentials.email, credentials.password));
    };

    const handleAzureLogin = (loginType) => {
        if (loginType === "popup") {
            instance
                .loginPopup(loginRequest)
                .then((res) => {
                    dispatch(azureLogIn(res.account));
                })
                .catch((e) => {
                    console.log("error", e);
                });
        }
    };

    const handleChangeInfields = (field, value) => {
        let credentialsCopy = { ...credentials };
        credentialsCopy[field] = value;
        setCredentials(credentialsCopy);
    };

    return (
        <>
            <div className="login-box">
                <div className="container d-flex h-100">
                    <div className="row align-self-center justify-content-center mx-auto login-inner-box">
                        <div className="col-sm-6">
                            <img src={LoginGroup} width={326} alt="Login" />
                        </div>
                        <div className="col-sm-6">
                            <div className="card login-form">
                                <div className="card-body">
                                    <img src={Logo} alt="Logo" />
                                    <div className="card-inputs mt-3">
                                        <div className="form-group mb-0">
                                            <input
                                                type="email"
                                                className="form-control form-control-sm"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Email"
                                                onChange={(e) => {
                                                    handleChangeInfields("email", e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className="form-control form-control-sm"
                                                id="exampleInputPassword1"
                                                placeholder="Password"
                                                onChange={(e) => {
                                                    handleChangeInfields("password", e.target.value);
                                                }}
                                            />
                                        </div>
                                        {error?.status === false && (
                                            <p className="warning-msg">
                                                <i className="fal fa-exclamation-triangle color-04" />{" "}
                                                Error: {error?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="login-button p-3">
                                    <button
                                        type="submit"
                                        className="btn-signin-ms"
                                        onClick={() => {
                                            handleAzureLogin("popup");
                                        }}
                                    >
                                        <img
                                            src={microsoftIcon}
                                            alt="microsoftIcon"
                                            width="12"
                                            className="mr-2"
                                        />
                                        Sign in with Microsoft
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn-login float-right"
                                        onClick={handleLogin}
                                    >
                                        Login
                                    </button>
                                </div>
                                <div className="forgot-password">
                                    <NavLink to={"reset-password"}>Forgot password?</NavLink> |{" "}
                                    <a
                                        href={"https://techni-k.co.uk/audit-app-plans/"}
                                        target="_blank"
                                        className="pl-1"
                                        rel="noreferrer"
                                    >
                                        Don't have an account? Sign up |{" "}
                                    </a>
                                    <a
                                        href={"https://techni-k.co.uk/knowledge-base/audit-app/"}
                                        target="_blank"
                                        className="pl-1"
                                        rel="noreferrer"
                                    >
                                        Help
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
