import { REMINDER_CONSTANTS } from "../constants";

const reducer = (
    state = { object: {}, list: [], dropdown: [], error: {}, success: false },
    { type, payload }
) => {
    switch (type) {
        case REMINDER_CONSTANTS.GET_REMINDER_LIST:
            return { ...state, list: payload };
        case REMINDER_CONSTANTS.GET_REMINDER_LIST_SUCCESS:
            return { ...state, list: payload };
        case REMINDER_CONSTANTS.GET_REMINDER_LIST_ERROR:
            return payload;
        default:
            return state;
    }
};

export default reducer;
