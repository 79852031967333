import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = (props) => {
  const { statusData } = props;

  const getColors = (statuses) => {
    let colors = [];
    for (const status of statuses) {
      if (
        status === "Draft" ||
        status === "Sent" ||
        status === "Accepted" ||
        status === "Reassigned" ||
        status === "Closed late" ||
        status === "Delegated" ||
        status === "Completed late and verified late" ||
        status === "Due" ||
        status === "Completed late" ||
        status === "Partly complete" ||
        status === "Completed and verified late"
      ) {
        colors.push("#f6921e");
      } else if (
        status === "In progress" ||
        status === "Verified late" ||
        status === "Completed" ||
        status === "Verified"
      ) {
        colors.push("#009345");
      } else if (
        status === "Overdue" ||
        status === "Partly completed late" ||
        status === "Missed" ||
        status === "Incomplete late" ||
        status === "Completed late" ||
        status === "Completed late and verified"
      ) {
        colors.push("#ff0000");
      } else if (status === "Closed") {
        colors.push("#00b0f0");
      }
    }
    return colors;
  };

  const options = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: getColors(statusData?.statusKeys),
    plotOptions: {
      bar: {
        columnWidth: "40%",
        distributed: true,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          "<div>" +
          w.globals.labels[dataPointIndex] +
          "</div>" +
          "<div>" +
          "Count" +
          ": " +
          series[seriesIndex][dataPointIndex] +
          "</div>" +
          "</div>"
        );
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    menu: {
      show: false,
    },
    xaxis: {
      categories: statusData?.statusKeys,
      crosshairs: {
        show: false,
      },
      labels: {
        rotate: 0,
        style: {
          colors: "rgba(128, 128, 128, 1)",
          fontSize: "10px",
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={[
          {
            data: statusData?.statusValues,
          },
        ]}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default BarChart;
