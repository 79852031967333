import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import AnswerEvidenceModal from "../../Modals/AnswerEvidenceModal";
import { getExtension } from "../../../utils/icons";
import {
    saQuestionCurrentIndex,
    updateQuestions,
} from "../../../store/actions/audit";
import { APP_CONSTANTS } from "../../../constants";
import SaveModal from "../../Modals/SaveModal";
import ErrorModalGmpAnswer from "../../Modals/ErrorModalGmpAnswer";
import { deleteMediaFromS3Bucket } from "../../../utils/s3";
import RemoveAttachmentModal from "../../Modals/RemoveAttachmentModal";
import request from "../../../services/request";
import { AUDIT } from "../../../Routes/Api";
import { useNavigate } from "react-router-dom";
import LogsModal from "../../Modals/LogsModal";
import videoIcon from "../../../Assets/images/file-video-regular.svg";
import utils from "../../../utils";

const SAQuestionDetail = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        data,
        index,
        saId,
        action,
        showButton,
        leadAuditor,
        status,
        refresh,
    } = props;
    const user = JSON.parse(localStorage.getItem("user"));

    const [questionData, setQuestionsData] = useState(data);
    const [currIndex, setCurrIndex] = useState(index);
    const [currentData, setCurrentData] = useState(data[currIndex]);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [cNotesError, setCNotesError] = useState(false);
    const [ncNotesError, setNcNotesError] = useState(false);
    const [ncGrade, setNcGrade] = useState(false);
    const [disable, setDisable] = useState(false);
    const [emptyAns, setEmptyAns] = useState(true);
    const [removeAttachmentModal, setRemoveAttachmentModal] = useState(false);
    const [evidenceData, setEvidenceData] = useState({
        obj: {},
        index: null,
    });
    const [splitQuestion, setSplitQuestion] = useState([]);
    const [loader, setLoader] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    const prevQuestion = () => {
        if (
            (currentData?.answers[0]?.no &&
                (!currentData?.ncNotes || currentData?.ncNotes === "")) ||
            (currentData?.answers[0]?.no && !currentData?.grade)
        ) {
            setNcNotesError(
                !currentData?.ncNotes || currentData?.ncNotes === "" ? true : false
            );
            setNcGrade(!currentData?.grade ? true : false);
        } else if (
            currentData?.answers[0]?.yes &&
            (!currentData?.cNotes || currentData?.cNotes === "")
        ) {
            setCNotesError(true);
        } else if (!currentData?.isUpdate) {
            setNcNotesError(false);
            setCNotesError(false);
            setNcGrade(false);
            if (currIndex !== 0) {
                setCurrentData(questionData[currIndex - 1]);
                setCurrIndex(currIndex - 1);
            }
        } else {
            setNcNotesError(false);
            setCNotesError(false);
            setNcGrade(false);
            setLoader(true);
            if (currIndex !== 0) {
                let obj = {
                    saId: saId,
                    questions: questionData,
                };
                setCurrentData(questionData[currIndex - 1]);

                request.post(AUDIT.ANSWER_SA_QUESTION, obj, true, false).then(
                    (res) => {
                        if (res?.status) {
                            setLoader(false);
                            dispatch(
                                updateQuestions(
                                    arrangeData({
                                        saId: res?.data?._id,
                                        questions: res?.data?.saQuestions,
                                    })
                                )
                            );
                            setQuestionsData(res?.data?.saQuestions);
                            setCurrIndex(currIndex - 1);
                        }
                    },
                    (err) => {
                        if (!err?.status) {
                            setError(true);
                            setLoader(false);
                            setTimeout(() => {
                                setError(false);
                            }, 3000);
                        }
                    }
                );
            }
        }
    };

    const nextQuestion = () => {
        if (
            (currentData?.answers[0]?.no &&
                (!currentData?.ncNotes || currentData?.ncNotes === "")) ||
            (currentData?.answers[0]?.no && !currentData?.grade)
        ) {
            setNcNotesError(
                !currentData?.ncNotes || currentData?.ncNotes === "" ? true : false
            );
            setNcGrade(!currentData?.grade ? true : false);
        } else if (
            currentData?.answers[0]?.yes &&
            (!currentData?.cNotes || currentData?.cNotes === "")
        ) {
            setCNotesError(true);
        } else if (!currentData?.isUpdate) {
            setNcNotesError(false);
            setCNotesError(false);
            setNcGrade(false);
            if (currIndex !== questionData?.length - 1) {
                setCurrentData(questionData[currIndex + 1]);
                setCurrIndex(currIndex + 1);
            }
        } else {
            setNcNotesError(false);
            setCNotesError(false);
            setNcGrade(false);
            setLoader(true);
            if (currIndex !== questionData?.length - 1) {
                let obj = {
                    saId: saId,
                    questions: questionData,
                };
                setCurrentData(questionData[currIndex + 1]);
                request.post(AUDIT.ANSWER_SA_QUESTION, obj, true, false).then(
                    (res) => {
                        if (res?.status) {
                            setLoader(false);
                            dispatch(
                                updateQuestions(
                                    arrangeData({
                                        saId: res?.data?._id,
                                        questions: res?.data?.saQuestions,
                                    })
                                )
                            );
                            setQuestionsData(res?.data?.saQuestions);
                            setCurrIndex(currIndex + 1);
                        }
                    },
                    (err) => {
                        if (!err?.status) {
                            setError(true);
                            setLoader(false);
                            setTimeout(() => {
                                setError(false);
                            }, 3000);
                        }
                    }
                );
            }
        }
    };

    const handleYes = () => {
        setCurrentData((old) => {
            return {
                ...old,
                isUpdate: true,
                ["answers"]: [
                    {
                        yes: true,
                        no: false,
                        na: false,
                    },
                ],
            };
        });
    };

    const handleNo = () => {
        setCurrentData((old) => {
            return {
                ...old,
                isUpdate: true,
                ["answers"]: [
                    {
                        yes: false,
                        no: true,
                        na: false,
                    },
                ],
            };
        });
        if (!currentData?.ncQuestions || !currentData?.ncQuestions.length) {
            setCurrentData((old) => {
                return {
                    ...old,
                    isUpdate: true,
                    ["ncQuestions"]: APP_CONSTANTS.GRADING_QUESTIONS_ARRAY,
                };
            });
        }
    };

    const handleNA = () => {
        setCurrentData((old) => {
            return {
                ...old,
                isUpdate: true,
                ["answers"]: [
                    {
                        yes: false,
                        no: false,
                        na: true,
                    },
                ],
            };
        });
    };

    const addAttachment = (data) => {
        let tempEvi = currentData?.evidence.concat(data.attachment);
        setCurrentData((old) => {
            return {
                ...old,
                isUpdate: true,
                ["evidence"]: tempEvi,
            };
        });
    };

    const handleNotes = (key, value) => {
        switch (key) {
            case "cNotes":
                if (value !== undefined || value !== "") {
                    setCNotesError(false);
                }
                setCurrentData((old) => {
                    return {
                        ...old,
                        isUpdate: true,
                        ["cNotes"]: value,
                    };
                });
                break;
            case "ncNotes":
                if (value !== undefined || value !== "") {
                    setNcNotesError(false);
                }
                setCurrentData((old) => {
                    return {
                        ...old,
                        isUpdate: true,
                        ["ncNotes"]: value,
                    };
                });
                break;
            default:
        }
    };

    const arrangeData = (data) => {
        data?.questions?.map((item) => {
            if (item?.answers?.length) {
                item.isAnswered = true;
            }
        });
        return data;
    };

    const checkAnswer = (data) => {
        let tempBool = false;
        data?.map((item) => {
            if (item?.answers?.length) {
                tempBool = true;
            }
        });
        return tempBool;
    };

    const handleSave = () => {
        setButtonDisable(true);
        if (
            (currentData?.answers[0]?.no &&
                (!currentData?.ncNotes || currentData?.ncNotes === "")) ||
            (currentData?.answers[0]?.no && !currentData?.grade)
        ) {
            setNcNotesError(
                !currentData?.ncNotes || currentData?.ncNotes === "" ? true : false
            );
            setNcGrade(!currentData?.grade ? true : false);
            setButtonDisable(false);
        } else if (
            currentData?.answers[0]?.yes &&
            (!currentData?.cNotes || currentData?.cNotes === "")
        ) {
            setCNotesError(true);
            setButtonDisable(false);
        } else {
            let obj = {
                saId: saId,
                questions: questionData,
            };

            request.post(AUDIT.ANSWER_SA_QUESTION, obj, true, false).then(
                (res) => {
                    if (res?.status) {
                        setSuccess(true);
                        showButton(true);
                        setTimeout(() => {
                            setSuccess(false);
                            dispatch(
                                updateQuestions(
                                    arrangeData({
                                        saId: res?.data?._id,
                                        questions: res?.data?.saQuestions,
                                    })
                                )
                            );
                            setQuestionsData(res?.data?.saQuestions);
                            refresh(true);
                            action();
                        }, 3000);
                    }
                },
                (error) => {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                        setButtonDisable(false);
                    }, 3000);
                }
            );
        }
    };

    const handledFieldChanges = (value, custom, index) => {
        currentData.evidence[index].title = value;
        setCurrentData((old) => {
            return {
                ...old,
                isUpdate: true,
                ["evidence"]: currentData.evidence,
            };
        });
    };

    // remove evidence
    const removeEvidence = async (obj, index) => {
        if (obj.key) await deleteMediaFromS3Bucket(obj.key);
        let tempArr = currentData?.evidence;
        tempArr.splice(index, 1);
        setCurrentData((prevState) => ({
            ...prevState,
            isUpdate: true,
            ["evidence"]: tempArr,
        }));
        setRemoveAttachmentModal(false);
    };
    // display modal before removing evidence
    const toggleRemoveAttachment = (obj, index) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            setEvidenceData((old) => {
                return { ...old, ["index"]: index, ["obj"]: obj };
            });
            setRemoveAttachmentModal(true);
        }
    };

    // close remove evidence modal
    const closeModal = () => {
        setRemoveAttachmentModal(false);
    };

    const openLinkInNewTab = (link) => {
        window.open(link);
    };

    const isLeadAuditor = (user, leadAuditor, status) => {
        let check = false;
        if (leadAuditor?.value === user?._id) {
            check = true;
        }

        if (
            status === "Completed" ||
            status === "Missed" ||
            status === "Partly complete" ||
            status === "Draft" ||
            status === "Completed late" ||
            status ===  "Incomplete late"
        ) {
            setDisable(() => {
                return true;
            });
            return;
        } else if (user?.permissions?.general?.system_manager) {
            setDisable(() => {
                return false;
            });
            return;
        } else if (check) {
            setDisable(() => {
                return false;
            });
            return;
        } else {
            setDisable(() => {
                return true;
            });
            return;
        }
    };
    const handleQuestionArrayChanges = (level, ans) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            let arrayQuesCopy = JSON.parse(JSON.stringify(currentData?.ncQuestions));
            if (level === "firQ") {
                arrayQuesCopy.forEach((obj, index) => {
                    obj?.options?.forEach((op) => {
                        if (op?.parentId === ans?.parentId && op?.id === ans?.id) {
                            op.checked = true;
                            if (op.A) {
                                setCurrentData((prevState) => ({
                                    ...prevState,
                                    isUpdate: true,
                                    ["grade"]: op?.A,
                                }));
                                setNcGrade(false);
                            }
                        } else {
                            op.checked = false;
                            setCurrentData((prevState) => ({
                                ...prevState,
                                isUpdate: true,
                                ["grade"]: undefined,
                            }));
                        }
                    });
                });
            }

            if (level === "secQ")
                arrayQuesCopy.forEach((obj, index) => {
                    obj?.options?.forEach((op) => {
                        if (op?.checked && op?.child?.length) {
                            op?.child.forEach((ch) => {
                                if (ch?.parentId === ans?.parentId) {
                                    ch.checked = true;
                                    if (ch?.checked && ch?.options?.length)
                                        ch?.options?.forEach((gc) => {
                                            if (gc.id === ans.id) {
                                                gc.checked = true;
                                                if (gc.A) {
                                                    setCurrentData((prevState) => ({
                                                        ...prevState,
                                                        isUpdate: true,
                                                        ["grade"]: gc.A,
                                                    }));
                                                    setNcGrade(false);
                                                }
                                            } else {
                                                gc.checked = false;
                                            }
                                        });
                                    else {
                                        ch.checked = true;
                                        if (ch.A) {
                                            setCurrentData((prevState) => ({
                                                ...prevState,
                                                isUpdate: true,
                                                ["grade"]: ch.A,
                                            }));
                                            setNcGrade(false);
                                        }
                                    }
                                } else ch.checked = false;
                            });
                        }
                    });
                });
            setCurrentData((prevState) => ({
                ...prevState,
                isUpdate: true,
                ["ncQuestions"]: arrayQuesCopy,
            }));
        }
    };

    const redirectToNc = () => {
        dispatch(saQuestionCurrentIndex(currIndex));
        navigate(
            `/private/reference/${currentData?.nc?.org}/${currentData?.nc?._id}/1`
        );
    };

    const anchorString = (string) => {
        const startIndex = string.indexOf("<a");
        if (startIndex !== -1) {
            const endIndex = string.indexOf(">", startIndex);
            const anchorTag = string.substring(startIndex, endIndex + 1);
            const modifiedAnchorTag = anchorTag.replace(
                ">",
                ' class="color-01 mb-2" target="_blank">'
            );
            const modifiedString = string.replace(anchorTag, modifiedAnchorTag);
            return modifiedString;
        }
    };

    useEffect(() => {
        setCurrentData(questionData[currIndex]);
    }, []);

    useEffect(() => {
        let tempArr = questionData;
        tempArr[currIndex] = { ...currentData, lastUpdate: new Date() };
        setQuestionsData(tempArr);
    }, [currentData]);

    useEffect(() => {
        isLeadAuditor(user, leadAuditor, status);
    }, []);

    useEffect(() => {
        if (checkAnswer(questionData)) {
            setEmptyAns(false);
        } else {
            setEmptyAns(true);
        }
    }, [questionData, currentData]);

    useEffect(() => {
        let tempCurrData = [];
        tempCurrData = currentData?.question
            ?.split("\n")
            .filter((item) => item !== "");
        setSplitQuestion(tempCurrData);
    }, [currentData]);

    return (
        <>
            <section
                className="todo-list gmp_ref_question"
                style={{ paddingTop: "25px" }}
            >
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="fs-18 fw-600 pt-2">
                            {currentData?.subjectName?.map((item, index) => (
                                <span key={index}>
                                    {(index ? ", " : "") + item.replace(/^\d+/, "")},
                                </span>
                            ))}
                            {currentData?.subtitleIdName?.map((item, index) => (
                                <span key={index}>
                                    {(index ? ", " : "") + item.replace(/^\d+/, "")}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="fs-18 fw-600 pt-2">
                            <button
                                className="btn btn-logs float-right mt-0 mr-1"
                                data-toggle="modal"
                                data-target="#logsModal"
                            >
                                <i className="far fa-history" /> Logs
                            </button>
                        </div>
                    </div>
                </div>

                <div className="gmp_questions gmp_questions_details py-5 px-4">
                    {loader ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status"></div>
                        </div>
                    ) : (
                        <>
                            <div className="row justify-content-between">
                                <div className="col-12 col-lg-6 col-md-5 mb-5">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <h3 className="color-03 fs-18 fw-600">Question</h3>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <h3 className="color-03 fs-18 fw-600">
                                                Clause reference:{" "}
                                                {currentData?.standardRef?.map((item, index) => (
                                                    <span key={index}>{(index ? ", " : "") + item}</span>
                                                ))}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        {splitQuestion?.map((data, index) =>
                                            !data.includes("<a") ? (
                                                <p key={index} className="mb-2">
                                                    {data}
                                                </p>
                                            ) : (
                                                <p
                                                    key={index}
                                                    dangerouslySetInnerHTML={{
                                                        __html: anchorString(data),
                                                    }}
                                                />
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 col-md-5">
                                    <h3 className="color-03 fs-18 fw-600">Conforming</h3>
                                    <div className="btn-group w-100">
                                        <button
                                            disabled={
                                                !utils.checkSubStatus()
                                                    ? !utils.checkSubStatus()
                                                    : disable
                                            }
                                            type="button"
                                            className={
                                                currentData?.answers[0]?.no ||
                                                    currentData?.answers[0]?.na
                                                    ? "btn btn-success btn_success mt-0"
                                                    : "btn btn-success mt-0"
                                            }
                                            onClick={handleYes}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            disabled={
                                                !utils.checkSubStatus()
                                                    ? !utils.checkSubStatus()
                                                    : disable
                                            }
                                            type="button"
                                            className={
                                                currentData?.answers[0]?.yes ||
                                                    currentData?.answers[0]?.no
                                                    ? "btn btn-warning btn_warning mt-0"
                                                    : "btn btn-warning mt-0"
                                            }
                                            onClick={handleNA}
                                        >
                                            n/a
                                        </button>
                                        <button
                                            disabled={
                                                !utils.checkSubStatus()
                                                    ? !utils.checkSubStatus()
                                                    : disable
                                            }
                                            type="button"
                                            className={
                                                currentData?.answers[0]?.yes ||
                                                    currentData?.answers[0]?.na
                                                    ? "btn btn-danger btn_danger mt-0"
                                                    : "btn btn-danger mt-0"
                                            }
                                            onClick={handleNo}
                                        >
                                            No
                                        </button>
                                    </div>
                                    {currentData?.nc && (
                                        <div className="mt-3">
                                            <p className="color-03 fw-500">
                                                Related non-conformance:{" "}
                                                <span
                                                    style={{
                                                        fontWeight: 400,
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={redirectToNc}
                                                >
                                                    {currentData?.nc?.type}-{currentData?.nc?.id}
                                                </span>
                                            </p>
                                        </div>
                                    )}
                                    {currentData?.answers[0]?.yes && (
                                        <>
                                            <div className="mt-4">
                                                <div className="form-group">
                                                    <label className="color-03 fs-18 fw-500">
                                                        Conformance notes
                                                    </label>
                                                    <textarea
                                                        disabled={
                                                            !utils.checkSubStatus()
                                                                ? !utils.checkSubStatus()
                                                                : disable
                                                        }
                                                        className="form-control custom_text_area"
                                                        rows="5"
                                                        onChange={(e) =>
                                                            handleNotes("cNotes", e.target.value)
                                                        }
                                                        value={
                                                            currentData?.cNotes ? currentData?.cNotes : ""
                                                        }
                                                    />
                                                </div>
                                                {cNotesError && (
                                                    <small className="text-danger">
                                                        You must enter conformance notes before moving on to
                                                        the next question
                                                    </small>
                                                )}
                                                <button
                                                    disabled={
                                                        !utils.checkSubStatus()
                                                            ? !utils.checkSubStatus()
                                                            : disable
                                                    }
                                                    className="btn btn-block"
                                                    data-toggle="modal"
                                                    data-target="#answermodal"
                                                >
                                                    Add evidence
                                                </button>
                                            </div>
                                            <div className="row my-4 evidence-scroll align-items-center">
                                                {currentData?.evidence?.map((obj, index) => (
                                                    <div className="col-sm-6 mt-4" key={index}>
                                                        <div className="d-flex align-items-center">
                                                            {disable ? (
                                                                <i
                                                                    className="far fa-minus-circle"
                                                                    style={{ cursor: "default" }}
                                                                />
                                                            ) : (
                                                                <i
                                                                    className="far fa-minus-circle"
                                                                    onClick={() =>
                                                                        toggleRemoveAttachment(obj, index)
                                                                    }
                                                                />
                                                            )}

                                                            {obj.extension === "images" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    src={`${obj?.path}`}
                                                                    alt="Evidence"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    className="cursor"
                                                                />
                                                            )}
                                                            {obj.extension === "videos" && obj?.path && (
                                                                // <video
                                                                //     className="cursor"
                                                                //     onClick={() => {
                                                                //         openLinkInNewTab(obj?.path);
                                                                //     }}
                                                                //     width="49"
                                                                //     src={`${obj?.path}#t=0.1`}
                                                                //     alt="Evidence"
                                                                // />
                                                                <img
                                                                    className="cursor"
                                                                    loading="lazy"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={videoIcon}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "files" && obj?.path && (
                                                                <img
                                                                    className="cursor"
                                                                    loading="lazy"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={getExtension(obj?.path)}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {(obj?.title || obj?.title === "") && (
                                                                <input
                                                                    className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                                    placeholder={"optional title"}
                                                                    value={obj?.title}
                                                                    onChange={(e) =>
                                                                        handledFieldChanges(
                                                                            e.target.value,
                                                                            true,
                                                                            index
                                                                        )
                                                                    }
                                                                    disabled={!utils.checkSubStatus()}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}

                                    {currentData?.answers[0]?.na && (
                                        <>
                                            <div>
                                                <button
                                                    disabled={
                                                        !utils.checkSubStatus()
                                                            ? !utils.checkSubStatus()
                                                            : disable
                                                    }
                                                    className="btn btn-block"
                                                    data-toggle="modal"
                                                    data-target="#answermodal"
                                                >
                                                    Add evidence
                                                </button>
                                            </div>
                                            <div className="row my-4 evidence-scroll align-items-center">
                                                {currentData?.evidence?.map((obj, index) => (
                                                    <div className="col-sm-6 mt-4" key={index}>
                                                        <div className="d-flex align-items-center">
                                                            {disable ? (
                                                                <i
                                                                    className="far fa-minus-circle"
                                                                    style={{ cursor: "default" }}
                                                                />
                                                            ) : (
                                                                <i
                                                                    className="far fa-minus-circle"
                                                                    onClick={() =>
                                                                        toggleRemoveAttachment(obj, index)
                                                                    }
                                                                />
                                                            )}

                                                            {obj.extension === "images" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    src={`${obj?.path}`}
                                                                    alt="Evidence"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    className="cursor"
                                                                />
                                                            )}
                                                            {obj.extension === "videos" && obj?.path && (
                                                                // <video
                                                                //     className="cursor"
                                                                //     onClick={() => {
                                                                //         openLinkInNewTab(obj?.path);
                                                                //     }}
                                                                //     width="49"
                                                                //     src={`${obj?.path}#t=0.1`}
                                                                //     alt="Evidence"
                                                                // />
                                                                <img
                                                                    className="cursor"
                                                                    loading="lazy"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={videoIcon}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "files" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    className="cursor"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={getExtension(obj?.path)}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {(obj?.title || obj?.title === "") && (
                                                                <input
                                                                    className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                                    placeholder={"optional title"}
                                                                    value={obj?.title}
                                                                    onChange={(e) =>
                                                                        handledFieldChanges(
                                                                            e.target.value,
                                                                            true,
                                                                            index
                                                                        )
                                                                    }
                                                                    disabled={!utils.checkSubStatus()}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}

                                    {currentData?.answers[0]?.no && (
                                        <>
                                            <div className="mt-4">
                                                <>
                                                    <div className="form-group">
                                                        <label className="color-03 fs-18 fw-500">
                                                            Non-conformance notes
                                                        </label>
                                                        <textarea
                                                            disabled={
                                                                !utils.checkSubStatus()
                                                                    ? !utils.checkSubStatus()
                                                                    : disable
                                                            }
                                                            className="form-control custom_text_area"
                                                            rows="5"
                                                            value={
                                                                currentData?.ncNotes ? currentData?.ncNotes : ""
                                                            }
                                                            onChange={(e) =>
                                                                handleNotes("ncNotes", e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    {ncNotesError && (
                                                        <small className="text-danger">
                                                            You must enter non-conformance notes before moving
                                                            on to the next question
                                                        </small>
                                                    )}
                                                    <button
                                                        disabled={
                                                            !utils.checkSubStatus()
                                                                ? !utils.checkSubStatus()
                                                                : disable
                                                        }
                                                        className="btn btn-block"
                                                        data-toggle="modal"
                                                        data-target="#answermodal"
                                                    >
                                                        Add evidence
                                                    </button>
                                                </>
                                            </div>
                                            <div className="row my-4 evidence-scroll align-items-center">
                                                {currentData?.evidence?.map((obj, index) => (
                                                    <div className="col-sm-6 mt-4" key={index}>
                                                        <div className="d-flex align-items-center">
                                                            {disable ? (
                                                                <i
                                                                    className="far fa-minus-circle"
                                                                    style={{ cursor: "default" }}
                                                                />
                                                            ) : (
                                                                <i
                                                                    className="far fa-minus-circle"
                                                                    onClick={() =>
                                                                        toggleRemoveAttachment(obj, index)
                                                                    }
                                                                />
                                                            )}

                                                            {obj.extension === "images" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    src={`${obj?.path}`}
                                                                    alt="Evidence"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    className="cursor"
                                                                />
                                                            )}
                                                            {obj.extension === "videos" && obj?.path && (
                                                                // <video
                                                                //     className="cursor"
                                                                //     onClick={() => {
                                                                //         openLinkInNewTab(obj?.path);
                                                                //     }}
                                                                //     width="49"
                                                                //     src={`${obj?.path}#t=0.1`}
                                                                //     alt="Evidence"
                                                                // />
                                                                <img
                                                                    className="cursor"
                                                                    loading="lazy"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={videoIcon}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {obj.extension === "files" && obj?.path && (
                                                                <img
                                                                    loading="lazy"
                                                                    className="cursor"
                                                                    onClick={() => {
                                                                        openLinkInNewTab(obj?.path);
                                                                    }}
                                                                    src={getExtension(obj?.path)}
                                                                    alt="Evidence"
                                                                />
                                                            )}
                                                            {(obj?.title || obj?.title === "") && (
                                                                <input
                                                                    className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                                    placeholder={"optional title"}
                                                                    value={obj?.title}
                                                                    onChange={(e) =>
                                                                        handledFieldChanges(
                                                                            e.target.value,
                                                                            true,
                                                                            index
                                                                        )
                                                                    }
                                                                    disabled={!utils.checkSubStatus()}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            {currentData?.ncQuestions?.map((obj, index) => (
                                                <>
                                                    {
                                                        <h4 className="fs-14 fw-600 color-04 mb-3">
                                                            {obj.Q}
                                                        </h4>
                                                    }
                                                    {obj?.options?.map((op) => (
                                                        <div className="custom-control custom-radio">
                                                            <input
                                                                type="radio"
                                                                id={op.Q}
                                                                name="customRadio0"
                                                                className="custom-control-input"
                                                                defaultChecked={op?.checked}
                                                                disabled={
                                                                    !utils.checkSubStatus()
                                                                        ? !utils.checkSubStatus()
                                                                        : disable
                                                                }
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={op.Q}
                                                                onClick={() =>
                                                                    !disable &&
                                                                    handleQuestionArrayChanges("firQ", op)
                                                                }
                                                            >
                                                                {op.Q}
                                                            </label>
                                                        </div>
                                                    ))}

                                                    {obj?.options?.map((op, index) => (
                                                        <>
                                                            {op?.checked &&
                                                                op?.child?.map((ch) => (
                                                                    <>
                                                                        {
                                                                            <h4 className="fs-14 fw-600 color-04 mb-3 mt-3">
                                                                                {ch?.Q}
                                                                            </h4>
                                                                        }
                                                                        {ch?.options?.map((op2) => (
                                                                            <div className="custom-control custom-radio">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={op2.Q}
                                                                                    name="customRadio1"
                                                                                    className="custom-control-input"
                                                                                    defaultChecked={op2?.checked}
                                                                                    disabled={
                                                                                        !utils.checkSubStatus()
                                                                                            ? !utils.checkSubStatus()
                                                                                            : disable
                                                                                    }
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor={op2.Q}
                                                                                    onClick={() =>
                                                                                        !disable &&
                                                                                        handleQuestionArrayChanges(
                                                                                            "secQ",
                                                                                            op2
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {op2.Q}
                                                                                </label>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                ))}
                                                        </>
                                                    ))}
                                                </>
                                            ))}

                                            {ncGrade && (
                                                <small className="text-danger">
                                                    please select required field.
                                                </small>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="mt-5 prev_next">
                                {currIndex !== 0 && (
                                    <button
                                        className="float-left color-03 fs-16 fw-500"
                                        onClick={prevQuestion}
                                    >
                                        <i className="fal fa-chevron-square-left"></i> Previous
                                        question
                                    </button>
                                )}
                                {currIndex !== questionData.length - 1 && (
                                    <button
                                        className="float-right color-03 fs-16 fw-500"
                                        onClick={nextQuestion}
                                    >
                                        Next question{" "}
                                        <i className="fal fa-chevron-square-right"></i>
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
                {
                    utils.checkSubStatus() && (
                        <div className="float-right my-2">
                            <button
                                className="btn_clear_inspection"
                                disabled={disable || emptyAns || buttonDisable}
                                onClick={() => {
                                    handleSave();
                                }}
                            >
                                <i className="far fa-save mr-1" /> Save
                            </button>
                        </div>
                    )
                }

                <LogsModal logs={currentData?.saLogs} type="sa" />
            </section>

            {success && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            setSuccess(false);
                            refresh(true);
                            action();
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.CREATE_SA_ANSWER.h2}
                        h3={APP_CONSTANTS.CREATE_SA_ANSWER.h3}
                        p={APP_CONSTANTS.CREATE_SA_ANSWER.p}
                    />
                </>
            )}

            {error && (
                <>
                    <div className="modal_backdrop"></div>
                    <ErrorModalGmpAnswer
                        id={"errorGmpAnswer"}
                        h3={APP_CONSTANTS.SA_ANSWER_ERROR.h3}
                    />
                </>
            )}

            {removeAttachmentModal && (
                <RemoveAttachmentModal
                    params={evidenceData}
                    action={removeEvidence}
                    onCancel={closeModal}
                    id={"removeEvidence"}
                />
            )}

            <AnswerEvidenceModal
                current={currentData?.evidence}
                action={addAttachment}
            />
        </>
    );
};

export default SAQuestionDetail;
