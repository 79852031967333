import { SITES_CONSTANT } from "../constants";
import request from "../../services/request";
import { SITES_LIST } from "../../Routes/Api";
import { APP_CONSTANTS } from "../../constants";
import { changeTabState } from "./tabState";
import session from "../../services/session";

export const getSitesListSuccess = (payload) => {
    return {
        type: SITES_CONSTANT.GET_SITES_LIST_SUCCESS,
        payload,
    };
};

export const toggleModal = (payload) => {
    return {
        type: SITES_CONSTANT.TOGGLE_MODAL,
        payload,
    };
};

export const SuccessModal = (payload) => {
    return {
        type: SITES_CONSTANT.SUCCESS_MODAL,
        payload,
    };
};

export const toggleErrorModal = (payload) => {
    return {
        type: SITES_CONSTANT.TOGGLE_ERROR_MODAL,
        payload,
    };
};

export const getMultiSitesListSuccess = (payload) => {
    return {
        type: SITES_CONSTANT.GET_MULTI_SITE_SUCCESS,
        payload,
    };
};

export const editMultiSite = (payload) => {
    return {
        type: SITES_CONSTANT.EDIT_MULTI_SITE,
        payload,
    };
};

export const getSitesSuccess = (payload) => {
    return {
        type: SITES_CONSTANT.GET_SITES_SUCCESS,
        payload,
    };
};

export const filterMultisiteList = (payload) => {
    return {
        type: SITES_CONSTANT.FILTER_MULTISITE_LIST,
        payload,
    };
};

// get sites list for dropdown
export const getSitesListApi = () => {
    return (dispatch) => {
        request.get(SITES_LIST.GET_SITES_LIST, true).then(
            (res) => {
                dispatch(getSitesListSuccess(res?.data));
            },
            (error) => { }
        );
    };
};

// multi-site list post
export const multiSitePost = (data) => {
    return (dispatch) => {
        request.post(SITES_LIST.POST_MULTI_SITE, data, true).then(
            (res) => {
                if (res?.data?.exist) {
                    dispatch(toggleModal({ show: true, data: res?.data?.message }));
                }
                if (!res?.data?.exist) {
                    dispatch(SuccessModal({ show: true, data: res?.data }));
                    setTimeout(() => {
                        dispatch(SuccessModal({ show: false, data: {} }));
                        dispatch(changeTabState(2));
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: {} }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

// create multi-site API
export const createMultiSite = (data) => {
    return (dispatch) => {
        request.post(SITES_LIST.CREATE_MULTI_SITE, data, true).then(
            (res) => {
                if (res?.data) {
                    dispatch(toggleModal({ show: false, data: {} }));
                    dispatch(SuccessModal({ show: true, data: res?.data }));
                    setTimeout(() => {
                        dispatch(SuccessModal({ show: false, data: {} }));
                        dispatch(changeTabState(2));
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: {} }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

// multi-site list API
export const getMultiSitesListApi = () => {
    return (dispatch) => {
        request.get(SITES_LIST.GET_MULTI_SITE_LIST, true).then(
            (res) => {
                dispatch(getMultiSitesListSuccess(res?.data));
            },
            (error) => { }
        );
    };
};

// edit multi-site
export const updateMultiSite = (data) => {
    return (dispatch) => {
        request.post(SITES_LIST.CHECK_TO_UPGRADE, data, true).then(
            (res) => {
                if (res?.data?.exist) {
                    dispatch(toggleModal({ show: true, data: res?.data?.message }));
                }
                if (!res?.data?.exist) {
                    dispatch(upgradeMultiSite(data));
                }
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: {} }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

// upgrade multi-site
export const upgradeMultiSite = (data) => {
    return (dispatch) => {
        request.put(SITES_LIST.UPDATE_MULTI_SITE, data, true).then(
            (res) => {
                if (res?.data) {
                    dispatch(toggleModal({ show: false, data: {} }));
                    dispatch(SuccessModal({ show: true, data: res?.data }));
                    setTimeout(() => {
                        dispatch(SuccessModal({ show: false, data: {} }));
                        dispatch(changeTabState(2));
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            },
            (error) => {
                dispatch(toggleErrorModal({ show: true, error: error }));
                setTimeout(() => {
                    dispatch(toggleErrorModal({ show: false, error: {} }));
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };
};

// get sites
export const getSites = (id = session.getUserId()) => {
    return (dispatch) => {
        request
            .get(
                `${SITES_LIST.GET_SITES}/${session.getUser()?.userType === "superAdmin"
                    ? session.getMultisiteId()
                    : id
                }?q=${session.getUser()?.userType === "superAdmin" ? "super" : "user"}`,
                true
            )
            .then(
                (res) => {
                    dispatch(getSitesSuccess(res?.data));
                },
                (error) => { }
            );
    };
};
