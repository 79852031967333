import React, { useState } from "react";
import request from "../../../services/request";
import { ORG } from "../../../Routes/Api";
import session from "../../../services/session";
import utils from "../../../utils";

const AzureSettings = () => {
    const org = JSON.parse(localStorage.getItem("org"));

    const [tenantId, setTenantId] = useState(null);
    const [tenantError, setTenantError] = useState({
        show: false,
        message: "",
    });
    const [loading, setLoading] = useState(false);
    const [tenant, setTenant] = useState(org?.tenantId);

    const handleTenantId = (value) => {
        setTenantId(value.trim());
    };

    const saveTenantId = () => {
        setLoading(true);
        if (!tenantId) {
            setTenantError({ show: true, message: "Please enter tenant id" });
            setLoading(false);
            return;
        }
        let obj = {
            _id: org?._id,
            tenantId: tenantId,
            type: "tenant",
        };
        request.put(ORG.UPDATE_ORG, obj, true, false).then(
            (res) => {
                if (res?.status) {
                    setLoading(false);
                    setTenantError({ show: false, message: "" });
                    session.setOrg(res?.data);
                    setTenant(res?.data?.tenantId);
                    setTenantId(null);
                }
            },
            (error) => {
                setLoading(false);
                setTenantError({ show: true, message: error?.message });
            }
        );
    };

    return (
        <section className="azure-settings">
            <div className="container-fluid">
                <h6 className="fw-500 color-07">
                    This information should only be completed by a member of your IT team,
                    as they will need to follow our{" "}
                    <a
                        href="https://techni-k.co.uk/knowledge-base/azure-ad-set-up-guide/"
                        target="_blank"
                        rel="noreferrer"
                        className="setup-guide color-07"
                    >
                        Azure AD set-up guide.
                    </a>
                </h6>
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-4">
                        <div className="mt-4">
                            <h4 className="fs-18 fw-400 color-06 mb-3">Azure Credentials</h4>
                            {tenant && (
                                <p className="fs-16 fw-600 color-05 mb-1">
                                    Tenant Id:{" "}
                                    <span className="fs-14 fw-600 color-04">{tenant}</span>
                                </p>
                            )}
                            {org?.siteId && (
                                <p className="fs-16 fw-600 color-05">
                                    Site Id:{" "}
                                    <span className="fs-14 fw-600 color-04">{org?.siteId}</span>
                                </p>
                            )}
                            <div className="mt-3">
                                <div className="form-group">
                                    <label>Tenant Id</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handleTenantId(e.target.value)}
                                        value={tenantId ? tenantId : ""}
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    {tenantError?.show && (
                                        <small className="text-danger">
                                            {tenantError?.message}
                                        </small>
                                    )}
                                </div>

                                <div className="mt-3">
                                    <button
                                        className="btn-update-plan"
                                        onClick={saveTenantId}
                                        disabled={!utils.checkSubStatus() ? !utils.checkSubStatus() : loading}
                                    >
                                        Add{" "}
                                        {loading && (
                                            <div
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                style={{ marginLeft: "2px" }}
                                            ></div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AzureSettings;
