import React from 'react';

const CompP = () => {
    return (
        <>
            <h2 id="p">P</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading226">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse226" aria-expanded="true"
                                    aria-controls="collapse226">
                                Pack copy
                            </button>
                        </h2>
                    </div>
                    <div id="collapse226" className="collapse show" aria-labelledby="heading226"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A document which provides all the text and design content needed to generate artwork for printed packaging. The pack copy information is typically generated from the finished product specification.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading227">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse227" aria-expanded="false"
                                    aria-controls="collapse227">
                                Parameters
                            </button>
                        </h2>
                    </div>
                    <div id="collapse227" className="collapse" aria-labelledby="heading227"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Parameters are the boundaries that we must work within. There are acceptable parameters and unacceptable parameters. Parameters are used for anything that can be measured. For example, a product must weigh between 50g and 100g. These are the acceptable parameters. The unacceptable parameters would be 49g or less and 101g or more.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading228">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse228" aria-expanded="false"
                                    aria-controls="collapse228">
                                PC
                            </button>
                        </h2>
                    </div>
                    <div id="collapse228" className="collapse" aria-labelledby="heading228"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A preventive control. A control that's designed to prevent product contamination or prevent contaminated product from reaching the customer or consumer.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading229">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse229" aria-expanded="false"
                                    aria-controls="collapse229">
                                Pencil
                            </button>
                        </h2>
                    </div>
                    <div id="collapse229" className="collapse" aria-labelledby="heading229"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Pencils should not be used to complete records as they can be rubbed out, which means you can't prove that they haven't been altered.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading230">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse230" aria-expanded="false"
                                    aria-controls="collapse230">
                                Performance of the site
                            </button>
                        </h2>
                    </div>
                    <div id="collapse230" className="collapse" aria-labelledby="heading230"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            How well the site has completed set tasks and if the targets have been met.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading231">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse231" aria-expanded="false"
                                    aria-controls="collapse231">
                                Performance review meetings
                            </button>
                        </h2>
                    </div>
                    <div id="collapse231" className="collapse" aria-labelledby="heading231"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A structure of meetings which happen at all levels throughout the year, to check performance to the departmental objectives.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading232">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse232" aria-expanded="false"
                                    aria-controls="collapse232">
                                Person with overall responsibility for the site
                            </button>
                        </h2>
                    </div>
                    <div id="collapse232" className="collapse" aria-labelledby="heading232"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The person with overall responsibility for the site, is the most senior manager. The senior manager must be the person who runs the site day-to-day, typically this is someone with a role such as Site Director or Site Manager. If the site is owned by a group, the group is responsible for the site, but they do not take responsibility for the day-to-day decisions made on site. It is the most senior person on site who has the final say regarding day-to-day decisions, and this is the person who has overall responsibility for the site.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading233">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse233" aria-expanded="false"
                                    aria-controls="collapse233">
                                Personnel
                            </button>
                        </h2>
                    </div>
                    <div id="collapse233" className="collapse" aria-labelledby="heading233"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The term 'personnel' is used to describe any person on site. Personnel can be full time or part time staff, agency staff, visitors, contractors, third-party engineers.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading234">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse234" aria-expanded="false"
                                    aria-controls="collapse234">
                                Pest control protocol
                            </button>
                        </h2>
                    </div>
                    <div id="collapse234" className="collapse" aria-labelledby="heading234"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A set of rules that are applied when there are pest sightings or an infestation.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading235">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse235" aria-expanded="false"
                                    aria-controls="collapse235">
                                Pest ingress
                            </button>
                        </h2>
                    </div>
                    <div id="collapse235" className="collapse" aria-labelledby="heading235"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To allow pests to get inside. Or where there is evidence of pests gaining access inside buildings.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading236">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse236" aria-expanded="false"
                                    aria-controls="collapse236">
                                Pest inspection
                            </button>
                        </h2>
                    </div>
                    <div id="collapse236" className="collapse" aria-labelledby="heading236"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An check of the pest monitoring devices on site, to establish if there is any signs of pest activity.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading237">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse237" aria-expanded="false"
                                    aria-controls="collapse237">
                                Pest survey
                            </button>
                        </h2>
                    </div>
                    <div id="collapse237" className="collapse" aria-labelledby="heading237"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A thorough inspection that assesses effectiveness of the overall pest programme, carried out by a highly qualified pest specialist. Typically carried out while the area is not in use, so that all areas and equipment can be accessed.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading238">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse238" aria-expanded="false"
                                    aria-controls="collapse238">
                                Plain
                            </button>
                        </h2>
                    </div>
                    <div id="collapse238" className="collapse" aria-labelledby="heading238"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A plain wedding ring or band means that it's smooth, without engraving or stones.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading239">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse239" aria-expanded="false"
                                    aria-controls="collapse239">
                                Plan
                            </button>
                        </h2>
                    </div>
                    <div id="collapse239" className="collapse" aria-labelledby="heading239"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The purpose of an action plan is to detail what needs to be done, to solve a problem, or improve something. The plan must detail what actions are to be taken and these actions must be SMART. A plan on it's own isn't sufficient, it must be implemented and then reviewed to make sure that it's working. Examples of action plans: culture action plan, management review action plan .
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading240">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse240" aria-expanded="false"
                                    aria-controls="collapse240">
                                Plate media
                            </button>
                        </h2>
                    </div>
                    <div id="collapse240" className="collapse" aria-labelledby="heading240"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The term media when used in a laboratory context, is the media that's used to put into a plate. So it's the material that is put into a petri dish that the microorganisms grow on.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading241">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse241" aria-expanded="false"
                                    aria-controls="collapse241">
                                Point-of-use
                            </button>
                        </h2>
                    </div>
                    <div id="collapse241" className="collapse" aria-labelledby="heading241"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Documents and equipment must be available where they will be used. If not, as an auditor you should question if it's practical for the person to use them. If it's not pratical, then it's unlikely that they'll use them, which may result in non-compliance.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading242">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse242" aria-expanded="false"
                                    aria-controls="collapse242">
                                Policy
                            </button>
                        </h2>
                    </div>
                    <div id="collapse242" className="collapse" aria-labelledby="heading242"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Documents the management's commitment to a topic and outlines the key headlines of what will happen. A policy is statement of intent and is set by senior management. A policy can be a document in itself, or it can be incorporated as a statement into a procedure.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading243">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse243" aria-expanded="false"
                                    aria-controls="collapse243">
                                Position statement
                            </button>
                        </h2>
                    </div>
                    <div id="collapse243" className="collapse" aria-labelledby="heading243"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            When additional information or requirements need to be added to the standard, before a new issue is published - a position statement is used.  The position statement will include a date from which certified businesses must comply with the requirements. This means that from that date, the requirements of the position statement must be met - or a non-conformance will be given at audit. Typically, when the standard is next updated – the requirements of any relevant position statements will be included in the update.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading244">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse244" aria-expanded="false"
                                    aria-controls="collapse244">
                                PPC
                            </button>
                        </h2>
                    </div>
                    <div id="collapse244" className="collapse" aria-labelledby="heading244"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Personal protective clothing. Typically used for food safety purposes, to protect the product from the person wearing it.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading245">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse245" aria-expanded="false"
                                    aria-controls="collapse245">
                                PPE
                            </button>
                        </h2>
                    </div>
                    <div id="collapse245" className="collapse" aria-labelledby="heading245"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Personal protective equipment. Typically used for H&S purposes, to protect the person from their environment.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading246">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse246" aria-expanded="false"
                                    aria-controls="collapse246">
                                PPM
                            </button>
                        </h2>
                    </div>
                    <div id="collapse246" className="collapse" aria-labelledby="heading246"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Planned preventative maintenance. A PPM system is where maintenance planned in advance, on a set schedule to a set frequency.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading247">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse247" aria-expanded="false"
                                    aria-controls="collapse247">
                                Primary packaging
                            </button>
                        </h2>
                    </div>
                    <div id="collapse247" className="collapse" aria-labelledby="heading247"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Primary packaging is the first layer of packaging, which comes into direct contact with the product.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading248">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse248" aria-expanded="false"
                                    aria-controls="collapse248">
                                Printing and reproduction media
                            </button>
                        </h2>
                    </div>
                    <div id="collapse248" className="collapse" aria-labelledby="heading248"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The term media when used in a packaging context, are the materials that are used to create a piece of packaging. Such as, artwork, printing equipment or reproduction equipment such as moulds.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading249">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse249" aria-expanded="false"
                                    aria-controls="collapse249">
                                Procedure
                            </button>
                        </h2>
                    </div>
                    <div id="collapse249" className="collapse" aria-labelledby="heading249"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Documents the theoretical details of how a topic must be managed. Typically, defined by middle management, to ensure compliance to the policy.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading250">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse250" aria-expanded="false"
                                    aria-controls="collapse250">
                                Process specification
                            </button>
                        </h2>
                    </div>
                    <div id="collapse250" className="collapse" aria-labelledby="heading250"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A formulation for the product and a detailed set of instructions, settings and parameters which provide all the information needed to process a material, to the standard agreed with the customer.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading251">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse251" aria-expanded="false"
                                    aria-controls="collapse251">
                                Processed
                            </button>
                        </h2>
                    </div>
                    <div id="collapse251" className="collapse" aria-labelledby="heading251"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            By law the definition of 'processed' or 'processing' is "action that substantially alters the initial product, including heating, smoking, curing, maturing, drying, marinating, extraction, extrusion or a combination" of those.  Therefore, this would mean a manufacturing site, or a packing site.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading252">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse252" aria-expanded="false"
                                    aria-controls="collapse252">
                                Processing Country
                            </button>
                        </h2>
                    </div>
                    <div id="collapse252" className="collapse" aria-labelledby="heading252"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The processing country is the country where the product was last processed.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading253">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse253" aria-expanded="false"
                                    aria-controls="collapse253">
                                Product
                            </button>
                        </h2>
                    </div>
                    <div id="collapse253" className="collapse" aria-labelledby="heading253"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            See also; traded product
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading254">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse254" aria-expanded="false"
                                    aria-controls="collapse254">
                                Product safety
                            </button>
                        </h2>
                    </div>
                    <div id="collapse254" className="collapse" aria-labelledby="heading254"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The practice of ensuring that materials are handled in a manner which ensures that they're safe.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading255">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse255" aria-expanded="false"
                                    aria-controls="collapse255">
                                Product Safety Control Point
                            </button>
                        </h2>
                    </div>
                    <div id="collapse255" className="collapse" aria-labelledby="heading255"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A collective term for points in the process which are critical to ensuring that the material processed is safe. Also referred to as CCPs, PCs, OPRPs.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading256">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse256" aria-expanded="false"
                                    aria-controls="collapse256">
                                Product safety hurdle
                            </button>
                        </h2>
                    </div>
                    <div id="collapse256" className="collapse" aria-labelledby="heading256"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An element that promotes microbial safety, because it makes the environment less favourable for pathogenic growth.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading257">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse257" aria-expanded="false"
                                    aria-controls="collapse257">
                                Product Safety Plan
                            </button>
                        </h2>
                    </div>
                    <div id="collapse257" className="collapse" aria-labelledby="heading257"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A product safety system that uses hazard analysis to determine which points in the process need to be controlled to ensure that the product is safe. Sometimes referred to as a HACCP Plan, HARPC Plan.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading258">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse258" aria-expanded="false"
                                    aria-controls="collapse258">
                                Product Safety Review
                            </button>
                        </h2>
                    </div>
                    <div id="collapse258" className="collapse" aria-labelledby="heading258"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Sometimes referred to as a HACCP review, HARA review, HARM review.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading259">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse259" aria-expanded="false"
                                    aria-controls="collapse259">
                                Product Safety Team
                            </button>
                        </h2>
                    </div>
                    <div id="collapse259" className="collapse" aria-labelledby="heading259"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Sometimes referred to as a Food Safety Team, HACCP Team, HARPC Team, HARA Team, HARM Team.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading260">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse260" aria-expanded="false"
                                    aria-controls="collapse260">
                                Product Safety Team Leader
                            </button>
                        </h2>
                    </div>
                    <div id="collapse260" className="collapse" aria-labelledby="heading260"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Sometimes referred to as a Food Safety Team Leader, HACCP Team Leader, HARPC Team Leader, HARA Team Leader, HARM Team Leader.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading261">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse261" aria-expanded="false"
                                    aria-controls="collapse261">
                                Product safety zone
                            </button>
                        </h2>
                    </div>
                    <div id="collapse261" className="collapse" aria-labelledby="heading261"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A distinct area in which specific product safety controls are applied. For example a high-risk area is a product safety zone.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading262">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse262" aria-expanded="false"
                                    aria-controls="collapse262">
                                Progress updates
                            </button>
                        </h2>
                    </div>
                    <div id="collapse262" className="collapse" aria-labelledby="heading262"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            In order to make sure that the culture plan is on track, the management team must be kept up-to-date with the actions that have or have not been completed. This is to ensure that management team can take the necessary action, to bring the plan back on track, if the timescales are not being met.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading263">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse263" aria-expanded="false"
                                    aria-controls="collapse263">
                                Proof
                            </button>
                        </h2>
                    </div>
                    <div id="collapse263" className="collapse" aria-labelledby="heading263"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An artwork proof provides an accurate visual representation of how the design will look when printed. It's typically provided in printed and pdf copy for review.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading264">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse264" aria-expanded="false"
                                    aria-controls="collapse264">
                                Protocol
                            </button>
                        </h2>
                    </div>
                    <div id="collapse264" className="collapse" aria-labelledby="heading264"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A set of rules that must be adhered to. Tyoically used for shelf life testing or pest control. See also; Pest control protocol, Shelf life protocol
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading265">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse265" aria-expanded="false"
                                    aria-controls="collapse265">
                                PRP
                            </button>
                        </h2>
                    </div>
                    <div id="collapse265" className="collapse" aria-labelledby="heading265"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Pre-Requisite Programme. A pre-requisite programme is a group of site-wide controls, grouped under one overall topic. Pre-requisites control the overall site facility including the equipment, the people that work in it and the indirect processes that are needed to produce the materials or service.  PRPs are not direct product processes, they don't control specific parts of the process, or specific pieces of equipment. See also; PRP controls
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading266">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse266" aria-expanded="false"
                                    aria-controls="collapse266">
                                PRP controls
                            </button>
                        </h2>
                    </div>
                    <div id="collapse266" className="collapse" aria-labelledby="heading266"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A control which is managed by a PRP.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading267">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse267" aria-expanded="false"
                                    aria-controls="collapse267">
                                Putaway
                            </button>
                        </h2>
                    </div>
                    <div id="collapse267" className="collapse" aria-labelledby="heading267"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A term used for the process of putting materials into their storage location, following receipt.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompP;