import React from 'react';

const CompI = () => {
    return (
        <>
            <h2 id="i">I</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading144">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse144" aria-expanded="true"
                                    aria-controls="collapse144">
                                Illegal
                            </button>
                        </h2>
                    </div>
                    <div id="collapse144" className="collapse show" aria-labelledby="heading144"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Product which does not meet the applicable legislation.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading145">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse145" aria-expanded="false"
                                    aria-controls="collapse145">
                                Impact
                            </button>
                        </h2>
                    </div>
                    <div id="collapse145" className="collapse" aria-labelledby="heading145"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Impact looks at the consequences of an event or set of circumstances. In hazard analysis this is typically the internal effect on the business from external factors such customers, enforcement bodies or the media because of what has happened to the consumer.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading146">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse146" aria-expanded="false"
                                    aria-controls="collapse146">
                                Impartial
                            </button>
                        </h2>
                    </div>
                    <div id="collapse146" className="collapse" aria-labelledby="heading146"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A person who doesn't have a conflict of interest and who acts without bias. This means that the results that they provide are factual and not affected by their opinion.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading147">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse147" aria-expanded="false"
                                    aria-controls="collapse147">
                                Independent
                            </button>
                        </h2>
                    </div>
                    <div id="collapse147" className="collapse" aria-labelledby="heading147"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            It's important that the person reviewing an action is different to the person that completed the action. The person reviewing the action must also be impartial, so that there is no bias when deciding if the action has been completed correctly and also thoroughly enough.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading148">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse148" aria-expanded="false"
                                    aria-controls="collapse148">
                                Indicator
                            </button>
                        </h2>
                    </div>
                    <div id="collapse148" className="collapse" aria-labelledby="heading148"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A metric that signals that something is about to happen, or has happened. See also; leading indicator, lagging indicator
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading149">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse149" aria-expanded="false"
                                    aria-controls="collapse149">
                                Induction
                            </button>
                        </h2>
                    </div>
                    <div id="collapse149" className="collapse" aria-labelledby="heading149"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An induction is a way of introducing a person to the site and provides them with a basic level of training. The induction includes the minimum information that the person needs to keep themselves and the product safe while on site.  An induction is usually delivered through a classroom based training session, but can also be done by the person watching a video or eLearning. There is no set timeframe for when an induction must be completed by, but the general rule is that it must be done within 6 weeks of the person starting work. Where a new starter doesn't have their induction immediately, there should be evidence to show that the new starter is being suitably supervised until they have completed the induction, otherwise they could be putting themselves or the product at risk of harm.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading150">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse150" aria-expanded="false"
                                    aria-controls="collapse150">
                                Infestation
                            </button>
                        </h2>
                    </div>
                    <div id="collapse150" className="collapse" aria-labelledby="heading150"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Evidence of pests breeding inside, or pests breeding on site over a long period of time.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading151">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse151" aria-expanded="false"
                                    aria-controls="collapse151">
                                Ingress
                            </button>
                        </h2>
                    </div>
                    <div id="collapse151" className="collapse" aria-labelledby="heading151"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Ingress is when something enters or goes inside. Typically used when talking about pests or environmental elements (such as rain). See also; pest ingress.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading152">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse152" aria-expanded="false"
                                    aria-controls="collapse152">
                                In-house
                            </button>
                        </h2>
                    </div>
                    <div id="collapse152" className="collapse" aria-labelledby="heading152"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that's done or provided internally by the business.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading153">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse153" aria-expanded="false"
                                    aria-controls="collapse153">
                                In-line measurement
                            </button>
                        </h2>
                    </div>
                    <div id="collapse153" className="collapse" aria-labelledby="heading153"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Where measurement is taken automatically, and at a frequency which makes it seem continuous - as it's faster than could be achieved manually. The equipment that takes the measurements is fitted within the processing line so that comes into contact with the material. As the measurement is automated the results can be used instantly and automatically, for corrective action. For example, probes which measure material temperature or pH.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading154">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse154" aria-expanded="false"
                                    aria-controls="collapse154">
                                Instigate / Instigated
                            </button>
                        </h2>
                    </div>
                    <div id="collapse154" className="collapse" aria-labelledby="heading154"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To prompt something to happen. For example, to instigate a review.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading155">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse155" aria-expanded="false"
                                    aria-controls="collapse155">
                                Instructional
                            </button>
                        </h2>
                    </div>
                    <div id="collapse155" className="collapse" aria-labelledby="heading155"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A set of instructions that tell you how to do something. See also: instructional document
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading156">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse156" aria-expanded="false"
                                    aria-controls="collapse156">
                                Instructional document
                            </button>
                        </h2>
                    </div>
                    <div id="collapse156" className="collapse" aria-labelledby="heading156"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A document which provides instructions on how to complete an activity. Instructional documents are typically in the form of procedures, work instructions or standard operating procedures. However, what they're called doesn't really matter - as long as everyone who uses them knows that they're called and what they're for.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading157">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse157" aria-expanded="false"
                                    aria-controls="collapse157">
                                Integrity
                            </button>
                        </h2>
                    </div>
                    <div id="collapse157" className="collapse" aria-labelledby="heading157"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            To act honestly, with strong moral principles.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading158">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse158" aria-expanded="false"
                                    aria-controls="collapse158">
                                Interaction
                            </button>
                        </h2>
                    </div>
                    <div id="collapse158" className="collapse" aria-labelledby="heading158"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The agreed relationship between two parties. For example, the relationship between group procurement and the site team.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading159">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse159" aria-expanded="false"
                                    aria-controls="collapse159">
                                Internal
                            </button>
                        </h2>
                    </div>
                    <div id="collapse159" className="collapse" aria-labelledby="heading159"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Something that is carried out within your company. Examples; internal audit
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading160">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse160" aria-expanded="false"
                                    aria-controls="collapse160">
                                Internal audit
                            </button>
                        </h2>
                    </div>
                    <div id="collapse160" className="collapse" aria-labelledby="heading160"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            An audit that verifies that systems are working within your company.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading161">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse161" aria-expanded="false"
                                    aria-controls="collapse161">
                                Intrinsics
                            </button>
                        </h2>
                    </div>
                    <div id="collapse161" className="collapse" aria-labelledby="heading161"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            The chemical composition of the product, such as aw or pH.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompI;