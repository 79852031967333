import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { APP_CONSTANTS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Modals/ErrorModal";
import SaveModal from "../../Modals/SaveModal";
import moment from "moment";
import { getAllUsersForDropDownApi } from "../../../store/actions/user";
import utils from "../../../utils";
import { getExtension } from "../../../utils/icons";
import { deleteMediaFromS3Bucket } from "../../../utils/s3";
import RemoveAttachmentModal from "../../Modals/RemoveAttachmentModal";
import { getTopicDropdownApi } from "../../../store/actions/topic";
import { getUsersByRoleApi } from "../../../store/actions/user";
import {
    generateAudit,
    toggleErrorModal,
    toggleSuccessModal,
    updateSAObject,
} from "../../../store/actions/audit";
import AuditBrowseModal from "../../Modals/AuditBrowseModal";
import { changeTabState } from "../../../store/actions/tabState";
import videoIcon from "../../../Assets/images/file-video-regular.svg";
import { AUDIT_CONSTANTS } from "../../../store/constants";

const CreateAudit = () => {
    const dispatch = useDispatch();
    const btnRef = useRef(null);
    const saveRef = useRef(null);

    const [validationError, setValidationError] = useState({});
    const [send, setSend] = useState(false);
    const [auditorsList, setAuditorsList] = useState([]);
    const [object, setObject] = useState({
        parentSA: "",
        notes: "",
        date_raised: moment(new Date()).format(APP_CONSTANTS.DATE_FORMAT_1),
        due_date: "",
        raised_by: {},
        lead_auditor: [],
        attachments: [],
    });
    const [removeAttachmentModal, setRemoveAttachmentModal] = useState(false);
    const [data, setData] = useState({
        obj: {},
        index: null,
    });
    const { auditor } = useSelector((state) => state?.user?.usersByRole);
    const { success, error } = useSelector((state) => state?.audit);
    const saObj = useSelector((state) => state?.audit?.object);
    const usersForDropDown = useSelector((state) => state?.user?.userDropdown);
    const topicOptions = useSelector((state) => state?.topic?.dropdown);
    const { tab } = useSelector((state) => state?.tabState);

    const handledFieldChanges = (field, value, custom, index) => {
        if (value && value !== "") {
            setValidationErrorObj(field, false);
        } else {
            setValidationErrorObj(field, true);
        }

        let createAuditObjCopy = { ...object };
        if (!custom) {
            createAuditObjCopy[field] = value;
            return;
        }
        switch (field) {
            case "custom_title":
                if (createAuditObjCopy.attachments)
                    createAuditObjCopy.attachments[index]["custom_name"] = value;
                break;
            case "due_date":
                setObject((prevState) => ({
                    ...prevState,
                    [field]: value,
                }));
                break;
            case "date_raised":
                setObject((prevState) => ({
                    ...prevState,
                    [field]: value,
                }));
                break;
            case "parentSA":
                setObject((prevState) => ({
                    ...prevState,
                    [field]: value?.value,
                }));
                let tempArr = auditor?.filter(function (cv) {
                    return !value?.excluded_auditor?.find(function (e) {
                        return e.value === cv.value;
                    });
                });
                setAuditorsList(tempArr);
                break;
            case "lead_auditor":
                setObject((prevState) => ({
                    ...prevState,
                    [field]: {
                        label: value?.label,
                        value: value?.value,
                        user: value?.user,
                    },
                }));
                break;
            case "raised_by":
                setObject((prevState) => ({
                    ...prevState,
                    [field]: {
                        label: value?.label,
                        value: value?.value,
                        user: value?.user,
                    },
                }));
                break;
            case "notes":
                setObject((prevState) => ({
                    ...prevState,
                    [field]: value,
                }));
                break;
            default:
        }
    };

    const handleSave = () => {
        saveRef.current.disabled = true;
        if (validateObject()) return;
        else {
            dispatch(generateAudit({ ...object, mode: "save" }));
        }
    };

    const handleSend = () => {
        btnRef.current.disabled = true;
        if (validateObject()) return;
        else {
            dispatch(generateAudit({ ...object, mode: "send" }));
        }
    };

    // const extractCustomTitles = (data) => {
    //     data.custom_names = [];
    //     data?.attachments.filter((obj, index) => {
    //         if (Boolean(obj.custom_name) && !obj.destination)
    //             data.custom_names.push({ custom_name: obj.custom_name, index });
    //     });
    // };

    // const setFormData = (data) => {
    //     let formData = new FormData();
    //     // if (data?.attachments?.length) {
    //     //     extractCustomTitles(data);
    //     //     data?.attachments?.map((file) => {
    //     //         formData.append("media", file);
    //     //     });
    //     // }
    //     formData.append("data", JSON.stringify(data));
    //     return formData;
    // };

    const removeEvidence = async (obj, index) => {
        if (obj.key) await deleteMediaFromS3Bucket(obj.key);
        let tempArr = object?.attachments;
        tempArr.splice(index, 1);
        setObject((prevState) => ({
            ...prevState,
            ["attachments"]: tempArr,
        }));
        setRemoveAttachmentModal(false);
    };

    const toggleRemoveAttachment = (obj, index) => {
        setData((old) => {
            return { ...old, ["index"]: index, ["obj"]: obj };
        });
        setRemoveAttachmentModal(true);
    };

    const closeModal = () => {
        setRemoveAttachmentModal(false);
    };

    const addAttachment = (data) => {
        let tempEvi = object?.attachments?.concat(data?.attachment);
        setObject((old) => {
            return {
                ...old,
                ["attachments"]: tempEvi,
            };
        });
    };

    useEffect(() => {
        if (!usersForDropDown?.length) dispatch(getAllUsersForDropDownApi());
        else utils.getCurrentUserForDropDown(null, setObject, object, "raised_by");
    }, [usersForDropDown]);

    const validateObject = () => {
        let errorFlag = false;

        if (!object?.parentSA) {
            setValidationErrorObj("parentSA", true);
            errorFlag = true;
            saveRef.current.disabled = false;
            btnRef.current.disabled = false;
        }
        if (!object?.raised_by?.value) {
            setValidationErrorObj("raised_by", true);
            errorFlag = true;
            saveRef.current.disabled = false;
            btnRef.current.disabled = false;
        }
        if (!object?.date_raised) {
            setValidationErrorObj("date_raised", true);
            errorFlag = true;
            saveRef.current.disabled = false;
            btnRef.current.disabled = false;
        }
        if (!object?.lead_auditor?.value) {
            setValidationErrorObj("lead_auditor", true);
            errorFlag = true;
            saveRef.current.disabled = false;
            btnRef.current.disabled = false;
        }
        if (!object.due_date) {
            setValidationErrorObj("due_date", true);
            errorFlag = true;
            saveRef.current.disabled = false;
            btnRef.current.disabled = false;
        }
        return errorFlag;
    };

    useEffect(() => {
        if (tab === 5) {
            dispatch(updateSAObject({ ...AUDIT_CONSTANTS.GET_DEFAULT_AUDIT_OBJ() }));
            setObject({
                parentSA: "",
                notes: "",
                date_raised: moment(new Date()).format(APP_CONSTANTS.DATE_FORMAT_1),
                due_date: "",
                raised_by: {},
                lead_auditor: [],
                attachments: [],
            });
        }
    }, []);

    useEffect(() => {
        if (
            saObj?._id &&
            topicOptions?.length &&
            auditor?.length &&
            (tab === 8 || tab === 13)
        ) {
            let tempObj = JSON.parse(JSON.stringify(saObj));
            tempObj["parentSA"] = {
                label: tempObj["parentSA"]?.name,
                value: tempObj["parentSA"]?._id,
            };
            setObject(tempObj);

            let topicIndex = topicOptions
                ?.map((topic) => topic?._id)
                .indexOf(tempObj["parentSA"]?.value);
            let tempArr = auditor?.filter(function (cv) {
                return !topicOptions[topicIndex]?.excluded_auditor?.find(function (e) {
                    return e.value === cv.value;
                });
            });
            setAuditorsList(tempArr);
        }
    }, [saObj, topicOptions, auditor]);

    const setValidationErrorObj = (key, flag) => {
        setValidationError((old) => {
            return {
                ...old,
                [key]: flag,
            };
        });
    };

    const openLinkInNewTab = (link) => {
        window.open(link);
    };

    useEffect(() => {
        if (error && btnRef?.current) {
            btnRef.current.disabled = false;
        }
        if (error && saveRef?.current) {
            saveRef.current.disabled = false;
        }
    }, [error]);

    useEffect(() => {
        dispatch(getTopicDropdownApi());
        dispatch(getUsersByRoleApi());
    }, []);

    return (
        <>
            <section className="home-nc">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6 pr-lg-5 mt-4">
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">System Audit</label>
                                <div className="col-sm-6">
                                    <Select
                                        options={topicOptions}
                                        value={
                                            object?.parentSA?.value
                                                ? object?.parentSA
                                                : topicOptions?.value
                                        }
                                        onChange={(data) => {
                                            handledFieldChanges("parentSA", data, true);
                                        }}
                                    />
                                    {validationError.parentSA && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row light-bg">
                                <label className="col-sm-3 col-form-label">Raised by</label>
                                <div className="col-sm-6">
                                    <Select
                                        options={usersForDropDown}
                                        value={object?.raised_by}
                                        onChange={(data) => {
                                            handledFieldChanges("raised_by", data, true);
                                        }}
                                    />
                                    {validationError.raised_by && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Date raised</label>
                                <div className="col-sm-6">
                                    <input
                                        type="date"
                                        className="date-selection"
                                        min={moment().format(APP_CONSTANTS.DATE_FORMAT_1)}
                                        value={moment(object?.date_raised).format(
                                            APP_CONSTANTS.DATE_FORMAT_1
                                        )}
                                        onChange={(e) => {
                                            handledFieldChanges("date_raised", e.target.value, true);
                                        }}
                                    />
                                    {validationError.date_raised && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row light-bg">
                                <label className="col-sm-3 col-form-label">Assign to</label>
                                <div className="col-sm-6">
                                    <Select
                                        options={auditorsList}
                                        value={
                                            object?.lead_auditor?.value
                                                ? object?.lead_auditor
                                                : object?.auditors
                                        }
                                        onChange={(data) => {
                                            handledFieldChanges("lead_auditor", data, true);
                                        }}
                                    />
                                    {validationError.lead_auditor && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Due Date</label>
                                <div className="col-sm-6">
                                    <input
                                        type="date"
                                        className="date-selection"
                                        min={moment().format(APP_CONSTANTS.DATE_FORMAT_1)}
                                        value={
                                            object.due_date
                                                ? moment(object.due_date).format(
                                                    APP_CONSTANTS.DATE_FORMAT_1
                                                )
                                                : ""
                                        }
                                        onChange={(e) => {
                                            handledFieldChanges("due_date", e.target.value, true);
                                        }}
                                    />
                                    {validationError.due_date && (
                                        <small className="text-danger">
                                            please select required field.
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mt-4">
                            <div className="form-group">
                                <label>Notes</label>
                                <textarea
                                    className="form-control"
                                    id=""
                                    rows="4"
                                    value={object?.notes || ""}
                                    onChange={(e) => {
                                        handledFieldChanges("notes", e.target.value, true);
                                    }}
                                />
                            </div>
                            <div className="form-group row light-bg add-evidence mb-0">
                                <label className="col-sm-9">Add attachment</label>
                                <div className="col-sm-3">
                                    <button
                                        type="button"
                                        className="btn-browse color-03 fs-12 fw-300 float-lg-right"
                                        data-toggle="modal"
                                        data-target="#auditSABrowseModal"
                                    >
                                        <i className="far fa-folder-open" /> Browse
                                    </button>
                                </div>
                            </div>
                            <div className="row my-4 evidence-scroll">
                                {object?.attachments?.map((obj, index) => (
                                    <div className="col-sm-6 pl-0 mt-4" key={index}>
                                        <div className="d-flex align-items-center">
                                            <i
                                                className="far fa-minus-circle"
                                                onClick={() => toggleRemoveAttachment(obj, index)}
                                            />
                                            {obj.extension === "images" && obj?.path && (
                                                <img
                                                    loading="lazy"
                                                    src={`${obj?.path}`}
                                                    alt="Evidence"
                                                />
                                            )}
                                            {obj.extension === "videos" && obj?.path && (
                                                // <video
                                                //     className="cursor"
                                                //     onClick={() => {
                                                //         openLinkInNewTab(obj?.path);
                                                //     }}
                                                //     width="49"
                                                //     src={`${obj?.path}#t=0.1`}
                                                //     alt="Evidence"
                                                // />
                                                <img
                                                    className="cursor"
                                                    loading="lazy"
                                                    onClick={() => {
                                                        openLinkInNewTab(obj?.path);
                                                    }}
                                                    src={videoIcon}
                                                    alt="Evidence"
                                                />
                                            )}
                                            {obj.extension === "files" && obj?.path && (
                                                <img
                                                    loading="lazy"
                                                    className="cursor"
                                                    onClick={() => {
                                                        openLinkInNewTab(obj?.path);
                                                    }}
                                                    src={getExtension(obj?.path)}
                                                    alt="Evidence"
                                                />
                                            )}
                                            {obj?.custom_name && (
                                                <input
                                                    className="thumb-text fs-12 fw-300 color-04 ml-3"
                                                    placeholder={"optional title"}
                                                    value={obj?.custom_name}
                                                    // onChange={(e) =>
                                                    //     handledFieldChanges(
                                                    //         "custom_title",
                                                    //         e.target.value,
                                                    //         true,
                                                    //         index
                                                    //     )
                                                    // }
                                                    disabled={true}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {utils.checkSubscription() && (
                <div>
                    <div className="float-right my-2">
                        <button
                            className="updated-save-btn mr-1"
                            onClick={handleSave}
                            ref={saveRef}
                            disabled={saveRef?.current?.disabled}
                        >
                            <i className="far fa-save" /> Save
                        </button>

                        <button
                            className="btn-send"
                            onClick={handleSend}
                            ref={btnRef}
                            disabled={btnRef?.current?.disabled}
                        >
                            <i className="far fa-share" /> Send
                        </button>
                    </div>
                </div>
            )}
            {success && !send && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                            window.location.href = "/";
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.CREATE_AUDIT_MESSAGE.h2}
                        h3={APP_CONSTANTS.CREATE_AUDIT_MESSAGE.h3}
                        p={APP_CONSTANTS.CREATE_AUDIT_MESSAGE.p}
                    />
                </>
            )}
            {error.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleErrorModal(false));
                        }}
                    ></div>
                    <ErrorModal error={error} />
                </>
            )}
            {success && send && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                            window.location.href = "/";
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.SEND_AUDIT_MESSAGE.h2}
                        h3={APP_CONSTANTS.SEND_AUDIT_MESSAGE.h3}
                        p={APP_CONSTANTS.SEND_AUDIT_MESSAGE.p}
                    />
                </>
            )}
            {removeAttachmentModal && (
                <RemoveAttachmentModal
                    params={data}
                    action={removeEvidence}
                    onCancel={closeModal}
                    id={"removeAttachment"}
                />
            )}
            <AuditBrowseModal current={object?.attachments} action={addAttachment} />
        </>
    );
};

export default CreateAudit;
