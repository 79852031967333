import React, { useState } from "react";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import { useDispatch, useSelector } from "react-redux";
import { currentChartType } from "../../../../store/actions/kpi";

const Charts = (props) => {
    const { data } = props;
    const dispatch = useDispatch();

    const { chartType } = useSelector((state) => state.kpi);

    const [showBarChart, setShowBarChart] = useState(
        chartType === "bar" ? true : false
    );
    const [showPieChart, setShowPieChart] = useState(
        chartType === "pie" ? true : false
    );

    const handleBar = () => {
        setShowPieChart(false);
        setShowBarChart(true);
        dispatch(currentChartType("bar"));
    };

    const handlePie = () => {
        setShowBarChart(false);
        setShowPieChart(true);
        dispatch(currentChartType("pie"));
    };

    const getStatusArrays = (objectsArray) => {
        const statusCounts = {};
        const uniqueStatuses = [];
        for (const obj of objectsArray) {
            const status = obj?.status;
            if (!statusCounts[status]) {
                statusCounts[status] = 1;
                uniqueStatuses.push(status);
            } else {
                statusCounts[status]++;
            }
        }
        const statusKeys = Object.keys(statusCounts);
        const statusValues = Object.values(statusCounts);
        return {
            statusKeys,
            statusValues,
        };
    };

    return (
        <div
            className="charts mt-3 p-2"
            style={{ border: "1px solid rgba(128, 128, 128, 30%)" }}
        >
            <div className="text-right">
                <div className="btn-group" role="group">
                    <button
                        type="button"
                        className={
                            showBarChart && !showPieChart
                                ? "btn btn-transparent mt-0"
                                : "btn btn-bar mt-0"
                        }
                        onClick={handleBar}
                    >
                        <i className="far fa-chart-bar"></i>
                    </button>
                    <button
                        type="button"
                        className={
                            !showBarChart && showPieChart
                                ? "btn btn-transparent mt-0"
                                : "btn btn-pie mt-0"
                        }
                        onClick={handlePie}
                    >
                        <i className="far fa-chart-pie"></i>
                    </button>
                </div>
            </div>
            {showBarChart && <BarChart statusData={getStatusArrays(data)} />}
            {showPieChart && <PieChart statusData={getStatusArrays(data)} />}
        </div>
    );
};

export default Charts;
