import React from "react";
import SaveModal from "../Modals/SaveModal";
import { APP_CONSTANTS } from "../../constants";
import ErrorModal from "../Modals/ErrorModal";
import { useDispatch, useSelector } from "react-redux";
import {
    updateUserAPi,
    editUserObject,
    resendPasswordAPI,
    toggleSuccessModal,
    resendPasswordSuccessModal,
    resendPasswordErrorModal,
    updateUserError,
} from "../../store/actions/user";
import { changeTabState } from "../../store/actions/tabState";
import utils from "../../utils";
import { useState } from "react";

const EditUser = () => {
    const dispatch = useDispatch();

    const { editUser, error } = useSelector((state) => state?.user);
    const showSuccessModal = useSelector((state) => state?.user?.success);
    const showResendPasswordSuccessModal = useSelector(
        (state) => state?.user?.resendPassword
    );
    const [validationError, setValidationError] = useState({});

    const handledFieldChanges = (field, value, custom) => {
        if (value && value !== "") {
            setValidationErrorObj(field, false);
        } else {
            setValidationErrorObj(field, true);
        }
        let userObjCopy = { ...editUser };
        if (!custom) {
            userObjCopy[field] = value;
            dispatch(editUserObject(userObjCopy));
            return;
        } else {
            userObjCopy["excludedPerm"] = userObjCopy["excludedPerm"]
                ? userObjCopy["excludedPerm"]
                : [];
        }

        switch (field) {
            case "disable_user":
                if (!userObjCopy[field] || userObjCopy[field] === "on")
                    userObjCopy[field] = true;
                else userObjCopy[field] = !userObjCopy[field];
                break;
            case "proactive_action":
                userObjCopy["permissions"]["default_user_roles"][field] =
                    !userObjCopy["permissions"]["default_user_roles"];
                break;
            case "corrective_action":
                userObjCopy["permissions"]["default_user_roles"][field] =
                    !userObjCopy["permissions"]["default_user_roles"][field];
                break;
            case "system_manager":
                handleRoleChangeImpact(userObjCopy, "general", field);
                userObjCopy["permissions"]["general"][field] =
                    !userObjCopy["permissions"]["general"][field];
                break;
            case "accounts":
                handleRoleChangeImpact(userObjCopy, "general", field);
                userObjCopy["permissions"]["general"][field] =
                    !userObjCopy["permissions"]["general"][field];
                break;
            case "root_cause_analysis":
                handleRoleChangeImpact(userObjCopy, "actions", field);
                userObjCopy["permissions"]["actions"][field] =
                    !userObjCopy["permissions"]["actions"][field];
                break;
            case "identifier":
                handleRoleChangeImpact(userObjCopy, "actions", field);
                userObjCopy["permissions"]["actions"][field] =
                    !userObjCopy["permissions"]["actions"][field];
                break;
            case "verifier":
                handleRoleChangeImpact(userObjCopy, "actions", field);
                userObjCopy["permissions"]["actions"][field] =
                    !userObjCopy["permissions"]["actions"][field];
                break;
            case "account_manager":
                handleRoleChangeImpact(userObjCopy, "system_audits", field);
                userObjCopy["permissions"]["system_audits"][field] =
                    !userObjCopy["permissions"]["system_audits"][field];
                break;
            case "auditor":
                handleRoleChangeImpact(userObjCopy, "system_audits", field);
                userObjCopy["permissions"]["system_audits"][field] =
                    !userObjCopy["permissions"]["system_audits"][field];
                break;
            case "accountable_manager":
                handleRoleChangeImpact(userObjCopy, "gmp_inspections", field);
                userObjCopy["permissions"]["gmp_inspections"][field] =
                    !userObjCopy["permissions"]["gmp_inspections"][field];
                break;
            case "inspector":
                handleRoleChangeImpact(userObjCopy, "gmp_inspections", field);
                userObjCopy["permissions"]["gmp_inspections"][field] =
                    !userObjCopy["permissions"]["gmp_inspections"][field];
                break;
            default:
        }
        dispatch(editUserObject(userObjCopy));
    };

    const handleRoleChangeImpact = (userObjCopy, obj, pro) => {
        if (userObjCopy["permissions"][obj][pro])
            userObjCopy.excludedPerm.push(pro);
        else {
            let index = userObjCopy.excludedPerm.findIndex((key) => key === pro);
            if (index !== -1) {
                userObjCopy.excludedPerm.splice(index, 1);
            }
        }
    };

    const validateObject = () => {
        let errorFlag = false;
        setValidationError({});
        if (!editUser?.user_name) {
            setValidationErrorObj("user_name", true);
            errorFlag = true;
        }
        if (!editUser?.email) {
            setValidationErrorObj("email", true);
            errorFlag = true;
        }
        return errorFlag;
    };

    const setValidationErrorObj = (key, flag) => {
        setValidationError((old) => {
            return {
                ...old,
                [key]: flag,
            };
        });
    };

    const handleSave = () => {
        if (validateObject()) return;
        else {
            dispatch(updateUserAPi(editUser));
        }
    };

    const resendPassword = () => {
        dispatch(resendPasswordAPI(editUser._id));
    };

    return (
        <section className="ce-user">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 mt-4">
                        <div className="form-group row">
                            <label className="col-2 col-form-label fs-14 fw-600 color-05">
                                Name
                            </label>
                            <div className="col-8">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id=""
                                    placeholder="Name"
                                    value={editUser?.user_name}
                                    onChange={(e) =>
                                        handledFieldChanges("user_name", e.target.value)
                                    }
                                    disabled={!utils.checkSubStatus()}
                                />
                                {validationError.user_name && (
                                    <small className="text-danger">
                                        please fill required field.
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="form-group row light-bg pr-0 mr-0">
                            <label className="col-2 col-form-label fs-14 fw-600 color-05">
                                Email
                            </label>
                            <div className="col-8">
                                <input
                                    type="email"
                                    className="form-control form-control-sm"
                                    id=""
                                    placeholder="steph.longname@techni-k.co.uk"
                                    value={editUser?.email}
                                    onChange={(e) =>
                                        handledFieldChanges(
                                            "email",
                                            e.target.value.toLocaleLowerCase()
                                        )
                                    }
                                    disabled={!utils.checkSubStatus()}
                                />
                                {validationError.email && (
                                    <small className="text-danger">
                                        please fill required field.
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-12 col-lg-6 mt-4 light-bg"
                        style={{ padding: "10px 15px" }}
                    >
                        <h2 className="fs-14 fw-600 color-05">Default user roles</h2>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck1"
                                        disabled={
                                            !utils.checkSubStatus() ? !utils.checkSubStatus() : true
                                        }
                                        defaultChecked={
                                            editUser?.permissions?.default_user_roles.proactive_action
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck1"
                                    >
                                        Proactive actions
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="custom-control custom-checkbox mr-3 mr-lg-0">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck2"
                                        disabled={
                                            !utils.checkSubStatus() ? !utils.checkSubStatus() : true
                                        }
                                        defaultChecked={
                                            editUser?.permissions?.default_user_roles
                                                .corrective_action
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck2"
                                    >
                                        Non-conformance actions
                                    </label>
                                </div>
                            </div>
                        </div>
                        <small className="float-right mt-3 color-04 fs-11 fw-400 mr-3 mr-lg-0">
                            FYI - Everyone gets these default roles, so they can't be changed.
                        </small>
                    </div>
                </div>
                {/*  Row End  */}
                <div className="row mt-5 ce-select-options">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 className="fs-14 fw-600 color-04">General</h2>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck3"
                                        defaultChecked={
                                            editUser?.permissions?.general.system_manager
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges(
                                                "system_manager",
                                                e.target.value,
                                                true
                                            )
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck3"
                                    >
                                        System manager
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck4"
                                        defaultChecked={editUser?.permissions?.general.accounts}
                                        onChange={(e) =>
                                            handledFieldChanges("accounts", e.target.value, true)
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck4"
                                    >
                                        Accounts
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-4 mt-lg-0">
                                <h2 className="fs-14 fw-600 color-04">
                                    Non-conformance actions
                                </h2>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck12"
                                        defaultChecked={editUser?.permissions?.actions.identifier}
                                        onChange={(e) =>
                                            handledFieldChanges("identifier", e.target.value, true)
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck12"
                                    >
                                        Identifier
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck5"
                                        defaultChecked={
                                            editUser?.permissions?.actions.root_cause_analysis
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges(
                                                "root_cause_analysis",
                                                e.target.value,
                                                true
                                            )
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck5"
                                    >
                                        Root cause analysis
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck6"
                                        defaultChecked={editUser?.permissions?.actions.verifier}
                                        onChange={(e) =>
                                            handledFieldChanges("verifier", e.target.value, true)
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck6"
                                    >
                                        Verifier
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4 mt-lg-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 className="fs-14 fw-600 color-04">System audits</h2>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck7"
                                        defaultChecked={
                                            editUser?.permissions?.system_audits.account_manager
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges(
                                                "account_manager",
                                                e.target.value,
                                                true
                                            )
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck7"
                                    >
                                        Accountable manager
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck8"
                                        defaultChecked={
                                            editUser?.permissions?.system_audits.auditor
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges("auditor", e.target.value, true)
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck8"
                                    >
                                        Auditor
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-4 mt-lg-0">
                                <h2 className="fs-14 fw-600 color-04">GMP inspections</h2>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck9"
                                        defaultChecked={
                                            editUser?.permissions?.gmp_inspections.accountable_manager
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges(
                                                "accountable_manager",
                                                e.target.value,
                                                true
                                            )
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck9"
                                    >
                                        Accountable manager
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck10"
                                        defaultChecked={
                                            editUser?.permissions?.gmp_inspections.inspector
                                        }
                                        onChange={(e) =>
                                            handledFieldChanges("inspector", e.target.value, true)
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck10"
                                    >
                                        Inspector
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Row End  */}
                <div className="row">
                    <div
                        className="col-12 col-lg-6 offset-lg-6 mt-4 light-bg"
                        style={{ padding: "10px 15px" }}
                    >
                        <h2 className="fs-14 fw-600 color-05">Disable this user</h2>
                        <div className="row">
                            <div className="col-6">
                                <p className="fs-11 fw-400 color-04">
                                    If you disable this user, they will no longer be able to login
                                    to audIT.app, receive notifications or be allocated actions.
                                </p>
                            </div>
                            <div className="col-12 col-lg-3 offset-lg-3">
                                <div className="custom-control custom-checkbox mr-3 mr-lg-0">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck11"
                                        defaultChecked={editUser?.disable_user}
                                        onChange={(e) =>
                                            handledFieldChanges("disable_user", e.target.value, true)
                                        }
                                        disabled={!utils.checkSubStatus()}
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck11"
                                    >
                                        Disable user{" "}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {utils.checkSubscription() && (
                <div className="float-right mt-4">
                    <button
                        className="btn-send mr-2"
                        onClick={() => dispatch(changeTabState(1))}
                    >
                        <i className="fal fa-window-close" /> Cancel
                    </button>
                    {utils.checkPermissions("resendPassword") && (
                        <button
                            className="btn-save mr-1"
                            style={{ width: "60%" }}
                            onClick={resendPassword}
                        >
                            <i className="far fa-lock" /> Resend Password
                        </button>
                    )}
                    <button className="btn-save mr-1" onClick={handleSave}>
                        <i className="far fa-save" /> Save
                    </button>
                </div>
            )}
            {showSuccessModal && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                            window.location.href = "/private/users";
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.UPDATE_USER_MESSAGE.h2}
                        h3={APP_CONSTANTS.UPDATE_USER_MESSAGE.h3}
                        p={APP_CONSTANTS.UPDATE_USER_MESSAGE.p}
                    ></SaveModal>
                </>
            )}
            {showResendPasswordSuccessModal && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(resendPasswordSuccessModal(false));
                            dispatch(changeTabState(1));
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.RESEND_PASSWORD_MESSAGE.h2}
                        h3={APP_CONSTANTS.RESEND_PASSWORD_MESSAGE.h3}
                        p={APP_CONSTANTS.RESEND_PASSWORD_MESSAGE.p}
                    ></SaveModal>
                </>
            )}
            {error.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(resendPasswordErrorModal(error));
                            dispatch(updateUserError(error));
                        }}
                    ></div>{" "}
                    <ErrorModal error={error}></ErrorModal>
                </>
            )}
        </section>
    );
};

export default EditUser;
