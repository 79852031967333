import React, { useState } from "react";
import Larryclock from "../../Assets/images/Larry-clock.png";
import request from "../../services/request";
import { REMINDER } from "../../Routes/Api";

const EditReminderModal = (props) => {
    const { toggleReminderModal, reminderData } = props;

    const [reminderObj, setReminderObj] = useState(reminderData);
    const [minValue, setMinValue] = useState(false);
    const [minValueMessage, setMinValueMessage] = useState(null);
    const [success, setSuccess] = useState({
        bool: false,
        message: "",
    });
    const [error, setError] = useState({
        bool: false,
        message: "",
    });

    const handledFieldChange = (type, value) => {
        let remObj = {};
        switch (type) {
            case "days":
                if (value < 1) {
                    setMinValue(true);
                    setMinValueMessage(
                        "Please enter a value greater than 1, and there should be no decimal number."
                    );
                } else {
                    setMinValue(false);
                    setMinValueMessage(null);
                }

                remObj = {
                    ...reminderObj,
                    days: parseInt(value),
                };
                setReminderObj(remObj);
                break;

            case "enabled":
                remObj = {
                    ...reminderObj,
                    enabled: value,
                };
                setReminderObj(remObj);
                break;

            default:
        }
    };

    const handleSave = () => {
        let tempObj = { ...reminderObj };
        delete tempObj.createdAt;
        delete tempObj.if;
        delete tempObj.org;
        delete tempObj.reminder;
        delete tempObj.to;
        delete tempObj.trigger;
        delete tempObj.updatedAt;
        delete tempObj.when;
        delete tempObj.__v;
        setReminderObj(tempObj);

        request.post(REMINDER.EDIT_REMINDER, reminderObj, true, false).then(
            (res) => {
                if (res?.status) {
                    setSuccess({ bool: true, message: "Reminder successfully saved." });
                    setTimeout(() => {
                        toggleReminderModal(false);
                    }, 2000);
                }
            },
            (error) => {
                setError({ bool: true, message: error?.message });
                setTimeout(() => {
                    toggleReminderModal(false);
                }, 2000);
            }
        );
    };

    const closeModal = (flag) => {
        toggleReminderModal(false);
    };

    return (
        <section className="audit-roles-modal edit_reminder ce-user">
            <div
                className="modal fade show"
                style={{ display: "block" }}
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog modal-wd modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body px-sm-4 pt-5 pb-4">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center">
                                    <img src={Larryclock} width={250} alt="edit reminder modal" />
                                </div>
                                <div className="col-12 col-lg-8">
                                    <h2 className="fs-18 fw-600 color-05 mb-1">
                                        Ok, let's edit this reminder
                                    </h2>
                                    <p className="fw-400 color-05" style={{ fontSize: "13px" }}>
                                        Just change the number below to edit the timing of this
                                        reminder.
                                    </p>
                                    <div className="mt-5 ml-lg-3">
                                        <p className="fs-14 fw-400 color-05 mb-2">
                                            When {reminderData?.if} {reminderData?.when.toLowerCase()}
                                            ,
                                        </p>
                                        <p className="fs-14 fw-400 color-05">
                                            I would like to send a reminder
                                        </p>
                                        <div className="fs-14 fw-400 color-05 mb-3 d-flex align-items-center">
                                            <input
                                                type="number"
                                                value={reminderObj?.days}
                                                className="form-control fs-14"
                                                min="1"
                                                style={{ width: "20%" }}
                                                placeholder="e.g. 30"
                                                onKeyDown={(e) =>
                                                    (e.keyCode === 69 ||
                                                        e.keyCode === 190 ||
                                                        e.keyCode === 107 ||
                                                        e.keyCode === 189 ||
                                                        e.keyCode === 110 ||
                                                        e.keyCode === 109) &&
                                                    e.preventDefault()
                                                }
                                                onChange={(e) =>
                                                    handledFieldChange("days", e.target.value)
                                                }
                                            />
                                            <p className="mb-0 pl-2">days</p>
                                        </div>
                                        <div className="fs-14 fw-400 color-05 mb-3 row">
                                            <div className="col-lg-4">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        id="reminder"
                                                        checked={reminderObj?.enabled}
                                                        onChange={(e) =>
                                                            handledFieldChange("enabled", e.target.checked)
                                                        }
                                                    />
                                                    <label
                                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                                        htmlFor="reminder"
                                                    >
                                                        {reminderObj?.enabled ? "enabled" : "disabled"}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="fs-14 fw-400 color-05">
                                            {reminderData?.trigger}
                                        </p>

                                        {minValue && (
                                            <small className="text-danger">
                                                {minValueMessage && minValueMessage}
                                            </small>
                                        )}
                                    </div>

                                    {success?.bool && (
                                        <div className="alert alert-success mt-0 mb-0" role="alert">
                                            {success?.message && success?.message}
                                        </div>
                                    )}
                                    {error?.bool && (
                                        <div className="alert alert-danger mt-0 mb-0" role="alert">
                                            {error?.message && error?.message}
                                        </div>
                                    )}

                                    <div className="float-right mr-lg-5 mt-4">
                                        <button
                                            type="button"
                                            className="cancel mr-2"
                                            onClick={closeModal}
                                        >
                                            <i className="far fa-ban" /> Close
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-send"
                                            disabled={minValue}
                                            onClick={handleSave}
                                        >
                                            <i className="far fa-save" /> Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </section>
    );
};

export default EditReminderModal;
