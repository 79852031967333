const setToken = (userToken) => {
    return localStorage.setItem("token", userToken);
};

const removeToken = (userToken) => {
    return localStorage.removeItem("token");
};

const removeUser = () => {
    return localStorage.removeItem("user");
};

const getToken = () => {
    return localStorage.getItem("token") !== "undefined" ||
        localStorage.getItem("token") !== null
        ? localStorage.getItem("token")
        : null;
};

const setUser = (userData) => {
    return localStorage.setItem("user", JSON.stringify(userData));
};

const setSites = (sitesData) => {
    return localStorage.setItem("sites", JSON.stringify(sitesData));
};

const getUser = () => {
    return localStorage.getItem("user") !== "undefined" &&
        localStorage.getItem("user") !== null
        ? JSON.parse(localStorage.getItem("user"))
        : null;
};

const setOrg = (orgData) => {
    return localStorage.setItem("org", JSON.stringify(orgData));
};

const setMultisite = (multisiteData) => {
    return localStorage.setItem("multisite", JSON.stringify(multisiteData));
};

const removeMultisite = () => {
    return localStorage.removeItem("multisite");
};

const getMultisiteId = () => {
    return localStorage.getItem("multisite") !== "undefined" &&
        localStorage.getItem("multisite") !== null
        ? JSON.parse(localStorage.getItem("multisite"))._id
        : null;
};

const getOrg = () => {
    return localStorage.getItem("org") !== "undefined" &&
        localStorage.getItem("org") !== null
        ? JSON.parse(localStorage.getItem("org"))
        : null;
};

const removeOrg = () => {
    return localStorage.removeItem("org");
};

const removeOrgId = (id) => {
    return localStorage.getItem("user") !== "undefined" &&
        localStorage.getItem("user") !== null
        ? localStorage.setItem(
            "user",
            JSON.stringify({
                ...JSON.parse(localStorage.getItem("user")),
                org: null,
            })
        )
        : null;
};

const removrPersist = () => {
    return localStorage.removeItem("persist:root");
};

const getOrgId = () => {
    return localStorage.getItem("user") !== "undefined" &&
        localStorage.getItem("user") !== null
        ? JSON.parse(localStorage.getItem("user")).org
        : null;
};

const setOrgId = (id) => {
    return localStorage.getItem("user") !== "undefined" &&
        localStorage.getItem("user") !== null
        ? localStorage.setItem(
            "user",
            JSON.stringify({ ...JSON.parse(localStorage.getItem("user")), org: id })
        )
        : null;
};

const setOrgPermissions = (permissions) => {
    return localStorage.getItem("user") !== "undefined" &&
        localStorage.getItem("user") !== null
        ? localStorage.setItem(
            "user",
            JSON.stringify({
                ...JSON.parse(localStorage.getItem("user")),
                permissions: permissions,
            })
        )
        : null;
};

const getUserId = () => {
    return localStorage.getItem("user") !== "undefined" &&
        localStorage.getItem("user") !== null
        ? JSON.parse(localStorage.getItem("user"))._id
        : null;
};

export default {
    setToken,
    getToken,
    removeToken,
    setUser,
    getUser,
    removeUser,
    getOrgId,
    getUserId,
    setOrg,
    setMultisite,
    getMultisiteId,
    getOrg,
    removeOrg,
    removrPersist,
    setOrgId,
    removeMultisite,
    removeOrgId,
    setOrgPermissions,
    setSites,
};
