import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGmpTemplateApi } from "../../store/actions/gmpTemplate";

const GmpGuide = () => {
    const dispatch = useDispatch();
    const { list } = useSelector((state) => state?.gmpTemplate);

    useEffect(() => {
        dispatch(getGmpTemplateApi());
    }, []);

    return (
        <>
            <div className="content-box pb-5">
                <section className="todo-list" style={{ paddingTop: "15px" }}>
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-10">
                                <h2 className="fs-18 fw-600 pt-2">
                                    <i className="far fa-cog" /> Guide to GMP inspection
                                    references
                                </h2>
                            </div>
                        </div>
                        <div className="gmp_guide">
                            <div>
                                {list?.map((data, index) => (
                                    <div className="mb-5" key={index}>
                                        <h3 className="color-05 fs-16">
                                            {data?.position > 9
                                                ? `${data?.label}`
                                                : `${data?.label}`.replace(/(\d+)/, '0$1')}{" "}
                                            - {data?.name}
                                        </h3>
                                        <p className="color-05 fs-14">{data?.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default GmpGuide;
