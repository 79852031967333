import { NAVIGATE } from "../constants";

const reducer = (state = {}, { type, payload }) => {
    switch (type) {
        case NAVIGATE.NAV_HOME:
            return { nav: payload };
            break;
        default:
            return state;
    }
};

export default reducer;
