import { R_C_A } from "../constants";

const reducer = (
    state = {
        object: { ...R_C_A.GET_RCA_DEFAULT_OBJ() },
        error: {},
        success: false,
    },
    { type, payload }
) => {
    switch (type) {
        case R_C_A.CREATE_RCA_SUCCESS:
            return { ...state, object: payload, success: true };
        case R_C_A.UPDATE_RCA:
            return { ...state, object: payload };
        case R_C_A.ADD_RCA_ATTACHMENT:
            if (state.object?.evidence?.length && payload?.attachment?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        evidence: [...state.object?.evidence, ...payload?.attachment],
                    },
                };
            else if (payload?.attachment?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        evidence: payload?.attachment,
                    },
                };
            if (state.object?.custom_names?.length && payload?.custom_names?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        custom_names: [
                            ...state.object?.custom_names,
                            ...payload?.custom_names,
                        ],
                    },
                };
            else if (payload?.custom_names?.length)
                state = {
                    ...state,
                    object: {
                        ...state.object,
                        custom_names: payload?.custom_names,
                    },
                };
            return state;
        case R_C_A.TOGGLE_ACTION_ERROR_MODAL:
            return { ...state, ...payload };
        case R_C_A.TOGGLE_RCA_SUCCESS_MODAL:
            return { ...state, ...payload };

        default:
            return state;
    }
};

export default reducer;
