import React from 'react';

const CompW = () => {
    return (
        <>
            <h2 id="w">W</h2>
            <div className="accordion" id="accordionGlossary">
                <div className="card">
                    <div className="card-header" id="heading355">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse355" aria-expanded="true"
                                    aria-controls="collapse355">
                                Watercourse
                            </button>
                        </h2>
                    </div>
                    <div id="collapse355" className="collapse show" aria-labelledby="heading355"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A channel of flowing water, e.g. stream, brook, river or canal. Such areas are known to harbour pests. See also; standing water, harbour.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading356">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse356" aria-expanded="false"
                                    aria-controls="collapse356">
                                Whistleblower
                            </button>
                        </h2>
                    </div>
                    <div id="collapse356" className="collapse" aria-labelledby="heading356"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A person who 'blows the whistle' and reports a wrongdoing.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading357">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse357" aria-expanded="false"
                                    aria-controls="collapse357">
                                Whistleblowing
                            </button>
                        </h2>
                    </div>
                    <div id="collapse357" className="collapse" aria-labelledby="heading357"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Whistleblowing, or 'blowing the whistle' is telling someone about a wrongdoing which normally happens at work. This is sometimes also known as 'making a disclosure'. The law states, that the person blowing the whistle, by law, must believe that they are acting in the best interests of the general public. They must not be blowing the whistle for their own personal interests only.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading358">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse358" aria-expanded="false"
                                    aria-controls="collapse358">
                                Withdrawal
                            </button>
                        </h2>
                    </div>
                    <div id="collapse358" className="collapse" aria-labelledby="heading358"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A withdrawal is when substandard product has not yet been made available for sale to the consumer, but the product is within the supply chain. The withdrawal process involves stopping the product from going on sale and also bringing it back to the site. Further guidance; if a product has been made available for sale, a withdrawal would not be suitable and a recall would be carried out instead.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading359">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse359" aria-expanded="false"
                                    aria-controls="collapse359">
                                Work instruction
                            </button>
                        </h2>
                    </div>
                    <div id="collapse359" className="collapse" aria-labelledby="heading359"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            Provides the practical instructions of how a task must be completed, step-by-step.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading360">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse360" aria-expanded="false"
                                    aria-controls="collapse360">
                                Work-in-progress materials
                            </button>
                        </h2>
                    </div>
                    <div id="collapse360" className="collapse" aria-labelledby="heading360"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A component part of a finished product or a material that's been processed but is not finished yet.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}
                <div className="card">
                    <div className="card-header" id="heading361">
                        <h2 className="mb-0">
                            <button className="btn btn-block text-left collapsed mt-0" type="button"
                                    data-toggle="collapse" data-target="#collapse361" aria-expanded="false"
                                    aria-controls="collapse361">
                                Wrongdoing
                            </button>
                        </h2>
                    </div>
                    <div id="collapse361" className="collapse" aria-labelledby="heading361"
                         data-parent="#accordionGlossary">
                        <div className="card-body">
                            A wrongdoing is a bad or illegal action, that affects the general public. It's not a grievance.
                        </div>
                    </div>
                </div>
                {/*  Card End  */}

            </div>
        </>
    );
};

export default CompW;