import request from "../../services/request";
import { REMINDER } from "../../Routes/Api";
import session from "../../services/session";
import { REMINDER_CONSTANTS } from "../constants";

export const getReminderList = (payload) => {
    return {
        type: REMINDER_CONSTANTS.GET_REMINDER_LIST,
        payload,
    };
};

export const getReminderListSuccess = (payload) => {
    return {
        type: REMINDER_CONSTANTS.GET_REMINDER_LIST_SUCCESS,
        payload,
    };
};

export const getReminderListError = (payload) => {
    return {
        type: REMINDER_CONSTANTS.GET_REMINDER_LIST_ERROR,
        payload,
    };
};

export const getReminderListApi = (org = session.getOrgId()) => {
    return (dispatch) => {
        request.get(`${REMINDER.GET_REMINDER_LIST}/${org}`, true).then(
            (res) => {
                dispatch(getReminderListSuccess(res?.data));
            },
            (error) => { }
        );
    };
};
