import { TOPIC_CONSTANTS } from "../constants";

const reducer = (
    state = { object: {}, list: [], dropdown: [], error: {}, success: false },
    { type, payload }
) => {
    switch (type) {
        case TOPIC_CONSTANTS.GET_TOPICS_LIST:
            return { ...state, list: payload };
        case TOPIC_CONSTANTS.GET_TOPICS_LIST_SUCCESS:
            return { ...state, list: payload };
        case TOPIC_CONSTANTS.GET_TOPICS_LIST_ERROR:
            return payload;
        case TOPIC_CONSTANTS.GET_TOPICS_DROPDOWN:
            return { ...state, dropdown: payload };
        case TOPIC_CONSTANTS.GET_TOPICS_DROPDOWN_SUCCESS:
            return { ...state, dropdown: payload };
        case TOPIC_CONSTANTS.UPDATE_TOPIC_SUCCESS:
            return { ...state, object: payload, success: true };
        case TOPIC_CONSTANTS.UPDATE_TOPIC_ERROR:
            return { ...state, error: { ...payload, show: true } };
        case TOPIC_CONSTANTS.GET_TOPICS_DROPDOWN_ERROR:
            return { ...state, error: { ...payload, show: true } };
        case TOPIC_CONSTANTS.UPDATE_TOPIC_OBJECT:
            return { ...state, object: payload, success: false };
        case TOPIC_CONSTANTS.TOGGLE_TOPIC_SUCCESS_MESSAGE:
            return { ...state, success: payload };
        case TOPIC_CONSTANTS.TOGGLE_TOPIC_ERROR_MESSAGE:
            return { ...state, error: { show: false } };
        default:
            return state;
    }
};

export default reducer;
