import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Routes } from "react-router";
import SettingBar from "../components/Layout/SettingBar";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Home from "../components/Home/Home";
import ProgrammeInformation from "../components/ProgrammeInfo/ProgrammeInformation";
import Users from "../components/Users/Users";
import Settings from "../components/System-Settings/Settings";
import Reference from "../components/Home/Reference/Reference";
import Accounts from "../components/Accounts/Accounts";
import Profile from "../components/Profile/Profile";
import ProRef from "../components/Home/Reference/ProRef";
import Billing from "../components/Accounts/Billing";
import SwitchPlan from "../components/Accounts/SwitchPlan";
import SwitchNewPlan from "../components/Accounts/SwitchNewPlan";
import CancelPlan from "../components/Accounts/CancelPlan";
import Glossary from "../components/Glossary/Glossary";
import GmpGuide from "../components/System-Settings/GmpGuide";
import GmpQuestions from "../components/ProgrammeInfo/GMPInspections/GmpQuestions";
import SAQuestions from "../components/ProgrammeInfo/SystemAudits/SAQuestions";
import SuperAdmin from "../components/SuperAdmin/SuperAdmin";
import MultisiteHeader from "../components/Layout/MultisiteHeader";
import MultisiteHome from "../components/MultisiteHome/MultisiteHome";
import MultisiteUsers from "../components/MultisiteUsers/MultisiteUsers";
import Sites from "../components/Sites/Sites";
import DownloadReport from "../components/DownloadReport/DownloadReport";

const PrivateRoute = () => {
    const { object } = useSelector((state) => state.user);
    const user = JSON.parse(localStorage.getItem("user"));
    const organization = JSON.parse(localStorage.getItem("org"));

    return user?._id ? (
        <div>
            <SettingBar />
            {user?.loginType === "multi" && !organization?._id ? (
                <MultisiteHeader />
            ) : (
                <Header />
            )}
            <div className="content-box pb-5">
                <Routes>
                    <Route
                        exact
                        path="/home"
                        element={
                            user?.loginType === "multi" && !organization?._id ? (
                                <MultisiteHome />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/reference/:orgId/:ncId/:tabId"
                        element={<Reference />}
                    />
                    <Route
                        exact
                        path="/program-info/:tabId"
                        element={<ProgrammeInformation />}
                    />
                    <Route
                        exact
                        path="/users"
                        element={
                            (user?.userType === "superAdmin" ||
                                user?.loginType === "multi") &&
                                !organization?._id ? (
                                <MultisiteUsers />
                            ) : (
                                <Users />
                            )
                        }
                    />
                    <Route exact path="/system-settings" element={<Settings />} />
                    <Route exact path="/accounts-summary" element={<Accounts />} />
                    <Route exact path="/my-profile" element={<Profile />} />
                    <Route exact path="/accounts-billing" element={<Billing />} />
                    <Route exact path="/switch-plan" element={<SwitchPlan />} />
                    <Route
                        exact
                        path="/confirm-switch-plan"
                        element={<SwitchNewPlan />}
                    />
                    <Route exact path="/cancel-plan" element={<CancelPlan />} />
                    <Route
                        exact
                        path="/pro-reference/:orgId/:proId"
                        element={<ProRef />}
                    />
                    <Route exact path="/glossary" element={<Glossary />} />
                    <Route
                        exact
                        path="/system-settings/manage-gmp"
                        element={<GmpGuide />}
                    />
                    <Route
                        exact
                        path="/gmp-inspection/:orgId/:gmpId"
                        element={<GmpQuestions />}
                    />
                    <Route
                        exact
                        path="/system-audit/:orgId/:saId"
                        element={<SAQuestions />}
                    />
                    <Route path="*" element={<Navigate to="/home" replace />} />
                    {user?.userType === "superAdmin" && (
                        <Route exact path="/super-admin" element={<SuperAdmin />} />
                    )}
                    <Route exact path="/sites" element={<Sites />} />
                    <Route exact path="/downloads" element={<DownloadReport />} />
                </Routes>
            </div>
            <Footer />
        </div>
    ) : (
        <Navigate to="/" />
    );
    /*!object?._id ?  :
                        <div className="content-box pb-5">
                            <SettingBar/>
                            <Routes>
                                <Route exact path="/accounts-summary" element={<Accounts/>}/>
                                <Route exact path="/accounts-billing" element={<Billing/>}/>
                                <Route exact path="/switch-plan" element={<SwitchPlan/>}/>
                                <Route exact path="/confirm-switch-plan" element={<SwitchNewPlan/>}/>
                                <Route exact path="/cancel-plan" element={<CancelPlan/>}/>
                            </Routes>
                        </div>*/
};

export default PrivateRoute;
