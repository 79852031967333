import React, { useEffect, useState } from "react";
import TodoListTlb from "./TodoLIst/TodoListTlb";
import { CSVLink } from "react-csv";
import CreateNc from "./ CreateNc";
import CreatePro from "./CreatePro";
import ExportModal from "./TodoLIst/ExportModal";
import { useDispatch, useSelector } from "react-redux";
import { changeTabState } from "../../store/actions/tabState";
import { NavLink, useNavigate } from "react-router-dom";
import utils from "../../utils";
import { APP_CONSTANTS } from "../../constants";
import BackIcon from "../../Assets/images/back-arrow.png";
import { updateNCObject } from "../../store/actions/nc";
import { updateProObject } from "../../store/actions/pro";
import { NC_CONSTANTS, PRO_CONSTANTS } from "../../store/constants";
import CreateGMP from "./GMP/CreateGMP";
import CreateAudit from "./Audit/CreateAudit";

const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tabState = useSelector((state) => state?.tabState);
    const { object } = useSelector((state) => state.user);
    const { list, filteredList } = useSelector((state) => state.todoList);

    const [csvExportHeader, setCsvExportHeader] = useState(
        APP_CONSTANTS.NC_EXPORT_HEADERS
    );
    const [csvReport, setCsvReport] = useState([]);
    const [planTwo, setPlanTwo] = useState(false);
    const [planThree, setPlanThree] = useState(false);

    const org = JSON.parse(localStorage.getItem("org"));
    const user = JSON.parse(localStorage.getItem("user"));

    const arrangeDataForExport = () => {
        let todoListCsvExportData = [];
        if (filteredList?.length)
            todoListCsvExportData = JSON.parse(JSON.stringify(filteredList));
        else if (list?.length) {
            todoListCsvExportData = JSON.parse(JSON.stringify(list));
        }
        todoListCsvExportData = todoListCsvExportData.map((obj) => {
            return APP_CONSTANTS.NC_EXPORT_DATA_SET(obj);
        });
        setCsvReport(todoListCsvExportData);
    };

    const handleChangeTab = (tabId) => {
        dispatch(changeTabState(tabId));
    };

    useEffect(() => {
        dispatch(changeTabState(1));
    }, []);

    useEffect(() => {
        if (tabState.tab === 1) {
            dispatch(updateNCObject({ ...NC_CONSTANTS.GET_DEFAULT_NC_OBJ() }));
            dispatch(updateProObject({ ...PRO_CONSTANTS.GET_DEFAULT_PRO_OBJ() }));
        }
    }, [tabState.tab]);

    useEffect(() => {
        if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_2) {
            setPlanTwo(true);
        } else if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_3) {
            setPlanTwo(true);
            setPlanThree(true);
        } else if (org?.subscription?.price_id === process.env.REACT_APP_PLAN_4) {
            setPlanTwo(true);
            setPlanThree(true);
        }
    }, []);

    return (
        <section className="todo-list">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-6 col-lg-10">
                        <h2 className="fs-18 fw-600 py-4">
                            <i className="far fa-home" /> {` Welcome ${object?.user_name}`}
                        </h2>
                    </div>
                    <div className="col-6 col-lg-2">
                        <NavLink
                            to={"#"}
                            className="back-to-pre fs-14 fw-300 py-4 color-04 float-right"
                            onClick={() => navigate(-1)}
                        >
                            <u>Back to previous page</u>{" "}
                            <img src={BackIcon} width="14px" alt="Back to Pre" />
                        </NavLink>
                    </div>
                </div>
                <div className="tab-content">
                    {tabState?.tab === 1 && (
                        <CSVLink
                            data={csvReport}
                            headers={csvExportHeader}
                            filename={"todoList.csv"}
                            className={"btn btn-export float-right mt-0"}
                            asyncOnClick={true}
                            onClick={(event, done) => {
                                arrangeDataForExport();
                                done();
                            }}
                        >
                            <i className="far fa-file-export" /> Export
                        </CSVLink>
                    )}

                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" onClick={() => handleChangeTab(1)}>
                            <a
                                className={`nav-link ${tabState?.tab === 1 ? "active" : ""}`}
                                role="tab"
                            >
                                My to-do list
                            </a>
                        </li>
                        {utils.checkPermissions(APP_CONSTANTS.PERMISSION_CONST.CREATE_NC) &&
                            tabState?.tab === 6 && (
                                <li className="nav-item" onClick={() => handleChangeTab(6)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 6 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Edit NC
                                    </a>
                                </li>
                            )}
                        {utils.checkPermissions(
                            APP_CONSTANTS.PERMISSION_CONST.CREATE_PRO
                        ) &&
                            tabState?.tab === 7 && (
                                <li className="nav-item" onClick={() => handleChangeTab(7)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 7 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Edit Action
                                    </a>
                                </li>
                            )}
                        {planThree &&
                            utils.checkSubStatus() &&
                            (user?.permissions?.general?.system_manager ||
                                user?.permissions?.system_audits?.auditor ||
                                user?.permissions?.system_audits?.account_manager ||
                                user?.userType === "superAdmin") &&
                            tabState?.tab === 8 && (
                                <li className="nav-item" onClick={() => handleChangeTab(8)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 8 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Edit Audit
                                    </a>
                                </li>
                            )}
                        {planTwo &&
                            utils.checkSubStatus() &&
                            (user?.permissions?.general?.system_manager ||
                                user?.permissions?.gmp_inspections?.inspector ||
                                user?.permissions?.gmp_inspections?.accountable_manager ||
                                user?.userType === "superAdmin") &&
                            tabState?.tab === 9 && (
                                <li className="nav-item" onClick={() => handleChangeTab(9)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 9 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Edit GMP
                                    </a>
                                </li>
                            )}
                        {utils.checkPermissions(APP_CONSTANTS.PERMISSION_CONST.CREATE_NC) &&
                            utils.checkSubStatus() && (
                                <li className="nav-item" onClick={() => handleChangeTab(2)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 2 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Create NC
                                    </a>
                                </li>
                            )}
                        {utils.checkPermissions(
                            APP_CONSTANTS.PERMISSION_CONST.CREATE_PRO
                        ) &&
                            utils.checkSubStatus() && (
                                <li className="nav-item" onClick={() => handleChangeTab(3)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 3 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Create PRO
                                    </a>
                                </li>
                            )}
                        {planTwo &&
                            utils.checkSubStatus() &&
                            (user?.permissions?.general?.system_manager ||
                                user?.permissions?.gmp_inspections?.inspector ||
                                user?.permissions?.gmp_inspections?.accountable_manager ||
                                user?.userType === "superAdmin") && (
                                <li className="nav-item" onClick={() => handleChangeTab(4)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 4 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Create GMP
                                    </a>
                                </li>
                            )}
                        {planThree &&
                            utils.checkSubStatus() &&
                            (user?.permissions?.general?.system_manager ||
                                user?.permissions?.system_audits?.auditor ||
                                user?.permissions?.system_audits?.account_manager ||
                                user?.userType === "superAdmin") && (
                                <li className="nav-item" onClick={() => handleChangeTab(5)}>
                                    <a
                                        className={`nav-link ${tabState?.tab === 5 ? "active" : ""
                                            }`}
                                        role="tab"
                                    >
                                        Create Audit
                                    </a>
                                </li>
                            )}
                    </ul>

                    <div className="tab-content tab-box">
                        {tabState?.tab === 1 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 1 ? "active" : ""
                                    }`}
                                id="tabs-1"
                                role="tabpanel"
                            >
                                <TodoListTlb />
                            </div>
                        )}
                        {tabState?.tab === 6 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 6 ? "active" : ""
                                    }`}
                                id="tabs-6"
                                role="tabpanel"
                            >
                                <CreateNc />
                            </div>
                        )}
                        {tabState?.tab === 7 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 7 ? "active" : ""
                                    }`}
                                id="tabs-7"
                                role="tabpanel"
                            >
                                <CreatePro />
                            </div>
                        )}
                        {tabState?.tab === 8 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 8 ? "active" : ""
                                    }`}
                                id="tabs-8"
                                role="tabpanel"
                            >
                                <CreateAudit />
                            </div>
                        )}
                        {tabState?.tab === 9 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 9 ? "active" : ""
                                    }`}
                                id="tabs-9"
                                role="tabpanel"
                            >
                                <CreateGMP />
                            </div>
                        )}
                        {tabState?.tab === 2 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 2 ? "active" : ""
                                    }`}
                                id="tabs-2"
                                role="tabpanel"
                            >
                                <CreateNc />
                            </div>
                        )}
                        {tabState?.tab === 3 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 3 ? "active" : ""
                                    }`}
                                id="tabs-3"
                                role="tabpanel"
                            >
                                <CreatePro />
                            </div>
                        )}
                        {tabState?.tab === 4 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 4 ? "active" : ""
                                    }`}
                                id="tabs-4"
                                role="tabpanel"
                            >
                                <CreateGMP />
                            </div>
                        )}
                        {tabState?.tab === 5 && (
                            <div
                                className={`tab-pane todo-section ${tabState?.tab === 5 ? "active" : ""
                                    }`}
                                id="tabs-5"
                                role="tabpanel"
                            >
                                <CreateAudit />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ExportModal />
        </section>
    );
};

export default Home;
